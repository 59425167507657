const defaultTheme = {
  logoTitle: {
    themeName: "headline2"
  },
  menuLeftLabel: {
    themeName: "headline4"
  },
  searchLogoText: {
    themeName: "headline6"
  },
  headerIconText: {
    themeName: "headline6"
  }
};

export default defaultTheme;
