// STYLED IMPORTS //
import styled from "styled-components";

// CONSTANT IMPORTS //
import { Breakpoints } from "@serenaandlily/constants/constants";

// Components //
export const FabricAttributesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px 16px;

  @media screen and (min-width: ${Breakpoints.SMTabletMin}) {
    gap: 12px 24px;
  }
`;

export const FabricAttribute = styled.div`
  width: 45%;

  display: flex;
  align-items: center;
  gap: 8px;
`;
