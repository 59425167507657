import styled from "styled-components";
import { Breakpoints } from "../../constants/constants";

export const HeaderIconWrapper = styled.div`
  align-self: center;
  display: none;

  @media screen and (min-width: ${Breakpoints.IPad}) and (max-width: ${Breakpoints.ScreenWidth}) {
    display: ${({ hideTablet }) => (hideTablet ? "none" : "block")};
  }

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    display: ${({ hideDesktop }) => (hideDesktop ? "none" : "block")};
  }

  @media screen and (min-width: ${Breakpoints.IPad}) {
    margin-right: 25px;
  }
`;

export const HeaderImage = styled.img`
  height: 22px;
  width: 22px;
  margin: 4px;
  cursor: pointer;
`;

export const HeaderLink = styled.a`
  text-decoration: none;
  display: ${({ hasText }) => (hasText ? "flex" : "")};
  flex-direction: ${({ hasText }) => (hasText ? "row" : "")};
  align-items: ${({ hasText }) => (hasText ? "center" : "")};
  flex-direction: ${({ iconTextPosition }) =>
    iconTextPosition == "Right" ? "row" : "row-reverse"};

  justify-content: space-between;

  &:hover {
    opacity: 50%;
  }
`;

export const TextWrapper = styled.div`
  cursor: pointer;
  max-width: 225px;
  align-items: center;
  text-align: center;
  margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
  display: none;

  @media screen and (min-width: ${Breakpoints.IPad}) {
    display: block;
  }
`;

export const ImageWrapper = styled.div`
  max-width: 225px;
  text-align: center;
`;

export const ImageTextWrapper = styled.div`
  display: none;
  margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
  white-space: nowrap;
  margin: ${({ iconTextPosition }) =>
    iconTextPosition == "Left" ? `0 6px 0 0` : `0 4px 0 6px`};

  @media screen and (min-width: ${Breakpoints.IPad}) {
    display: block;
  }
`;
