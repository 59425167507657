import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  MenuButton,
  ModalWrapper,
  ModalTitle
} from "./LeftNavigationStyled";
import LeftNavigationSection from "../LeftNavigationSection";
import { useBreakpoint } from "../Breakpoints";
import Text from "../Text";
import Icons from "../Icons";
import { useGlobalPopupUpdate } from "../GlobalPopup/GlobalPopupContext";
import useUser from "@lib/useUser";

const LeftNavigation = ({ content, menuButton, modalTitle }) => {
  const breakpoints = useBreakpoint();
  const isTablet = breakpoints["IPad"];
  const isMobile = breakpoints["SmallMobile"];
  const { openPopup } = useGlobalPopupUpdate();
  const mobileStyle = { width: "100%", height: "100%" };
  const tabletStyle = { width: "70%", left: "35%", height: "100%" };
  const { user } = useUser();
  const isDSO = user?.data?.isDSO;

  const modalStyle = isMobile ? mobileStyle : tabletStyle;

  const NavContentPopUp = () => (
    <ModalWrapper>
      <ModalTitle>
        <Text {...modalTitle?.text} />
      </ModalTitle>
      {content?.map((section, idx) => {
        const key = `left-navigation-section-${idx}`;
        return <LeftNavigationSection {...section} key={key} />;
      })}
    </ModalWrapper>
  );

  const handleOnClick = () => openPopup(NavContentPopUp, false, {}, modalStyle);

  return (
    <React.Fragment>
      {isTablet ? (
        <MenuButton onClick={handleOnClick}>
          <Text {...menuButton?.text} />
          <Icons type="Menu" />
        </MenuButton>
      ) : (
        <Container>
          {content?.map((section, idx) => {
            const key = `left-navigation-section-${idx}`;
            return (
              <LeftNavigationSection {...section} isDSO={isDSO} key={key} />
            );
          })}
        </Container>
      )}
    </React.Fragment>
  );
};

export default LeftNavigation;

LeftNavigation.propTypes = {
  content: PropTypes.array,
  menuButton: PropTypes.object,
  modalTitle: PropTypes.object
};
