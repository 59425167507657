import apolloClient from "@serenaandlily/gql/client";
import buildGetCategoryQuery from "../queries/buildGetCategoryQuery";

const fetchCategoryResults = async ({
  categorySlug,
  maxPromoMessages,
  searchParameters,
  userParameters,
  customerEmail,
  guestToken
}) => {
  if (!apolloClient) return;
  try {
    const GET_CATEGORY_RESULTS = buildGetCategoryQuery();

    const response = await apolloClient.query({
      query: GET_CATEGORY_RESULTS,
      variables: {
        categorySlug,
        maxPromoMessages,
        searchParameters,
        userParameters,
        customerEmail,
        guestToken
      },
      context: { clientName: "endpoint2" }
    });

    return response;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return { data: null, errors: error };
  }
};

export default fetchCategoryResults;
