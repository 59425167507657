import { useRouter } from "next/router";

export const useGAData = () => {
  const router = useRouter();

  const { utm_source, utm_medium, utm_campaign, utm_term, utm_content, gclid } =
    router.query;

  return {
    utm_source: gclid && gclid !== "" ? "google" : utm_source,
    utm_medium: gclid && gclid !== "" ? "cpc" : utm_medium,
    utm_campaign,
    utm_term,
    utm_content
  };
};
