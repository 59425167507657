import React from "react";
import PropTypes from "prop-types";
import { Colors } from "../../../constants/constants";
import { Bag, BagLink } from "./UtilityMenuStyled";
import { BAG_URL } from "../../../constants/checkout";
import { useBreakpoint } from "./../../Breakpoints";
import { useSidePanelContext } from "../../../contexts/SidePanelContext";

const BagLogo = ({ itemCount = 0 }) => {
  const maxCount = 10;
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints["SmallMobile"];
  const { isOpen } = useSidePanelContext();

  return (
    <BagLink
      href={isMobile ? "#" : BAG_URL}
      aria-label="Display Shopping Cart"
      id="header-shoppingbag"
    >
      <Bag
        isOpen={isOpen}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title id="iconTitle">Display Shopping Cart</title>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-987.000000, -72.000000)" stroke="none">
            <g transform="translate(987.000000, 73.000000)">
              <g>
                <path d="M0 0H24V24H0V0Z" fill="none"></path>
                <path
                  stroke={Colors["Dark Primary"]}
                  strokeLinecap="round"
                  d="M19 6.08838C13.4857 7.43362 10.5255 7.45939 4.99997 6.08838M12.0879 3.90755L18.9778 6.07608M11.912 3.90755L5.0221 6.07608M19.4714 9.96896L22.0197 7.92312M4.52855 9.96896L1.98022 7.92312M19.2305 8.34375L19.4023 15.2202C19.4665 17.7871 19.0636 20.721 16.6933 21.7084C15.5734 22.1749 14.0112 22.5 11.8054 22.5M4.76943 8.34375L4.5976 15.2202C4.53345 17.7871 4.93631 20.721 7.30662 21.7084C8.42648 22.1749 9.9887 22.5 12.1946 22.5M11.9922 3.88281C11.3536 3.88281 10.8359 3.36514 10.8359 2.72656V1.87444C10.8359 1.66764 11.0035 1.5 11.2103 1.5H12.774C12.9808 1.5 13.1484 1.66764 13.1484 1.87444V2.72656C13.1484 3.36514 12.6307 3.88281 11.9922 3.88281Z"
                ></path>
                {itemCount < maxCount ? (
                  <text x="12" y="19" textAnchor="middle" fill="#383838">
                    {itemCount === 0 ? "" : itemCount}
                  </text>
                ) : (
                  <circle fill="#383838" cx="12" cy="14" r="3"></circle>
                )}
              </g>
            </g>
          </g>
        </g>
      </Bag>
    </BagLink>
  );
};

export default BagLogo;

BagLogo.propTypes = {
  itemCount: PropTypes.number
};
