import styled from "styled-components";
import { Breakpoints } from "../../constants/constants";
import { AddToCartWrapper } from "../AddToCart/AddToCartStyled";
import { CallToAction } from "../CTA/CTAStyled";
import { StyledSpinner } from "../Icons/IconsStyled";
import {
  ProductCarousel,
  ProductDisplayContainer
} from "../ProductDisplay/ProductDisplayStyled";
import {
  EmblaCarousel,
  EmblaSlideThumb,
  ThumbButtonNext,
  ThumbButtonPrev
} from "../ProductImageCarousel/ProductImageCarouselStyled";
import {
  AddToCartContainer,
  ProductOptionBottomContainer,
  QtyAndAddContainer,
  PdpQuantitySpinnerContainer
} from "../ProductOptions/ProductOptionsStyled";
import { ProductNameWrapper } from "../ProductName/ProductNameStyled";

export const ProductDisplayLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 480px;
  height: 90vh;
  max-width: 100%;
  max-height: 100%;

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    width: 1030px;
  }

  ${StyledSpinner} {
    margin: 0;
  }
`;

export const ProductDisplayWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  padding-top: 75px;
  width: 480px;
  max-width: 100%;
  position: relative;
  padding-left: 24px;
  padding-right: 24px;

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    width: 1030px;
    padding-bottom: 83px;
    padding-left: 0;
    padding-right: 0;
  }

  & > ${ProductDisplayContainer} {
    height: auto;
    overflow: visible;
    width: 100%;
    flex-direction: column;

    > div:first-of-type {
      max-width: 550px;
    }

    > div:last-of-type {
      max-width: 100%;
      width: auto;
      padding: 0 30px;
    }

    @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
      flex-direction: row;

      > div:last-of-type {
        max-width: 100%;
        width: 480px;
        padding: 0;
      }
    }

    & ${ProductOptionBottomContainer} {
      padding: 24px 0;
      bottom: 0;
      width: auto;
      position: sticky;

      @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 1030px;
        transform: translateX(0%);
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        > div:first-of-type {
          width: 530px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    & ${QtyAndAddContainer} {
      width: 100%;
      padding: 0;

      @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
        width: 464px;
        margin: 0;
        margin-right: 16px;
      }
      & ${PdpQuantitySpinnerContainer} {
        margin-bottom: 0px;
      }
    }

    & ${AddToCartContainer} {
      display: flex;
      gap: 4%;

      & > ${CallToAction}, > ${AddToCartWrapper} {
        max-width: 100%;

        @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
          width: 255px;
        }
      }

      & > ${CallToAction} {
        padding: 0;
        text-align: center;
      }
    }

    & ${ProductCarousel} {
      width: 100%;
      padding: 0;
      border: none;
      margin-bottom: 0;

      img {
        max-height: 548px;
      }
    }

    & ${EmblaCarousel} {
      padding: 0 24px 24px 24px;
    }

    & ${EmblaSlideThumb} {
      min-width: 40%;
    }

    & ${ThumbButtonNext}, ${ThumbButtonPrev} {
      height: 12px;
    }

    & ${ProductNameWrapper} {
      padding-right: 19px;
    }
  }
`;
