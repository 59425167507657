import React from "react";

import CarouselImage from "../../CarouselImage";
import ImageAndCaptionTile from "../../ImageAndCaptionTile";
import InspirationTile from "../../InspirationTile";
import ProductTile from "../../ProductTile";
import CTA from "../../CTA";
import CUFilterCTA from "../../CUFilterCTA";
import Banner from "../../Banner";

export const ALLOWED_CAROUSEL_CONTENT = {
  CarouselImage: {
    type: "CarouselImage",
    component: CarouselImage
  },
  CTA: {
    type: "CTA",
    component: CTA
  },
  CUFilterCTA: {
    type: "CUFilterCTA",
    component: CUFilterCTA
  },
  ImageAndCaptionTile: {
    type: "ImageAndCaptionTile",
    component: ImageAndCaptionTile
  },
  InspirationTile: {
    type: "InspirationTile",
    component: InspirationTile
  },
  ModularGrid: {
    type: "ModularGrid",
    component: React.lazy(() => import("../../ModularGrid"))
  },
  ProductTile: {
    type: "ProductTile",
    component: ProductTile
  },
  Banner: {
    type: "Banner",
    component: Banner
  }
};

export const getCarouselContent = (contentType) => {
  return ALLOWED_CAROUSEL_CONTENT[contentType]?.component;
};

export default getCarouselContent;
