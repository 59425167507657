// prod, staging, develop and local should have BASE_URL set in env variables
// preview deployments should use VERCEL_URL.
function getBaseUrl() {
  let BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;
  if (!BASE_URL) {
    BASE_URL = process.env.NEXT_PUBLIC_VERCEL_URL
      ? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
      : "";
  }
  return BASE_URL;
}

export default getBaseUrl;
