import { Breakpoints } from "../../../constants/constants";
import { css } from "styled-components";

const MOBILE_MARGINS = {
  "Wide Margin": css`
    @media screen and (max-width: ${Breakpoints.SmallMobile}) {
      margin-left: 16px;
      margin-right: 0;
    }
  `,
  "Full Width with Margin": css`
    @media screen and (max-width: ${Breakpoints.SmallMobile}) {
      margin-left: 12px;
      margin-right: 0;
    }
  `
};

const DEFAULT_MARGINS = css`
  margin-left: 0;
  margin-right: 0;
`;

export const getMobileCarouselMargins = (size) => {
  return MOBILE_MARGINS[size] || DEFAULT_MARGINS;
};
