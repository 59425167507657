import { Breakpoints } from "@serenaandlily/constants/constants";
import styled from "styled-components";

export const ImageContainer = styled.div`
  position: relative;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  @media screen and (max-width: ${Breakpoints.ScreenWidth}) {
    height: auto;
  }
  & * div {
    touch-action: manipulation;
  }
`;
