import React from "react";
import PropTypes from "prop-types";
import { ProductErrorMessageContainer } from "./ProductErrorMessageStyled";
import Text from "../Text";
import useComponentTheme from "../Theme/useComponentTheme";
import defaultTheme from "./ProductErrorMessageTheme";

const ProductErrorMessage = ({ errorMessage, theme = defaultTheme }) => {
  const productErrorMessageTheme = useComponentTheme({ theme });
  return (
    errorMessage && (
      <ProductErrorMessageContainer>
        <Text
          copy={errorMessage}
          theme={productErrorMessageTheme?.productErrorMessage}
        />
      </ProductErrorMessageContainer>
    )
  );
};

export default ProductErrorMessage;

ProductErrorMessage.propTypes = {
  errorMessage: PropTypes.string,
  theme: PropTypes.object
};
