import styled from "styled-components";
import {
  Colors,
  FontSizes,
  Typography
} from "@serenaandlily/constants/constants";

export const StyledDropdownMenu = styled.div`
  position: absolute;
  top: 25px;
  right: 8px;
  width: 131px;
  padding: 16px;
  background: ${Colors["Gray Dropdown Bg"]};
  box-sizing: border-box;
  z-index: 1000;
`;

export const DropdownList = styled.div`
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: right;

  > li:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const DropdownAction = styled.button`
  font-family: ${Typography.Beatrice};
  background: transparent;
  color: ${Colors.Midnight};
  font-size: ${FontSizes.Large};
  font-weight: 300;
  padding: 0;
  border: 0;

  &:hover {
    font-family: ${Typography.BeatriceRegular};
    cursor: pointer;
  }
`;
