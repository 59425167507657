import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  FabricSelectorWrapper,
  FabricSelectorContainer,
  FabricSelectorTitle,
  SelectedFabric,
  SwatchesContainer,
  SwatchesContainerTitle,
  ImageContainer,
  RemainingOrder,
  Swatches,
  SelectorCTAContainer,
  TileContainer
} from "./FabricSelectorStyled";
import Text from "../Text";
import SwatchImage from "../SwatchImage";
import { useProductContext } from "../../contexts/ProductContext";
import { useSidePanelContext } from "../../contexts/SidePanelContext";
import FabricPanel from "../FabricPanel";
import CTA from "../CTA";
import OrderSwatchesPanel from "../OrderSwatchesPanel";
import { useBreakpoint } from "../Breakpoints";
import { useProductDataContext } from "../../contexts/ProductDataContext";
import useComponentTheme from "../Theme/useComponentTheme";
import defaultTheme from "./FabricSelectorTheme";
import SlideIn from "../SlideIn";
import { SLIDE_IN_POSITION } from "../SlideIn/constants";
import FabricsLoadingPlaceholders from "../FabricsLoadingPlaceholders";

export const FabricSelector = ({
  madeToOrderFabrics,
  quickshipFabrics,
  swatchHeadings,
  imgUrl,
  topLineVariation,
  cuOptions,
  currentVariations,
  productId,
  filters,
  eddData,
  showEdd,
  price,
  isEdit,
  isLoading,
  handleAddSwatches,
  setSelectedSwatchList,
  cartPopupHeader,
  fabricGuideCta,
  orderSwatchesMessage,
  theme = defaultTheme
}) => {
  const breakpoints = useBreakpoint();
  const { handleLoadAllFabricImages } = useProductDataContext();
  const [isLoadingQuickshipImages, setIsLoadingQuickshipImages] =
    useState(true);
  const [isLoadingMadeToFabricImages, setIsLoadingMadeToFabricImages] =
    useState(true);
  const fabricPanelRef = useRef(null);
  const isMobile = breakpoints["DesktopMobile"];
  const isTablet = breakpoints["LgTablet"];
  const isDesktop = !breakpoints["LgTablet"];
  const { isFilterOpen, setIsFilterOpen } = useSidePanelContext();
  const fabricSelectorTheme = useComponentTheme({ theme });

  const visibleFabrics = isMobile || isTablet ? 4 : 6;

  useEffect(() => {
    const imageQuickshipLoadPromises = visibleQuickShip.map((fabric) => {
      const image = new Image();
      image.src = fabric?.imageSet?.items[0]?.src;
      return new Promise((resolve) => {
        image.onload = resolve;
        image.onerror = resolve;
      });
    });
    const imageMadeToOrderLoadPromises = visibleMadeToOrder.map((fabric) => {
      const image = new Image();
      image.src = fabric?.imageSet?.items[0]?.src;
      return new Promise((resolve) => {
        image.onload = resolve;
        image.onerror = resolve;
      });
    });

    Promise.all(imageQuickshipLoadPromises).then(() =>
      setIsLoadingQuickshipImages(false)
    );
    Promise.all(imageMadeToOrderLoadPromises).then(() =>
      setIsLoadingMadeToFabricImages(false)
    );
  }, [visibleQuickShip, visibleMadeToOrder]);

  const [visibleQuickShip, setVisibleQuickShip] = useState(
    quickshipFabrics.slice(0, visibleFabrics)
  );

  const [visibleMadeToOrder, setVisibleMadeToOrder] = useState(
    madeToOrderFabrics.slice(0, visibleFabrics)
  );

  const remainingQuickShip =
    quickshipFabrics.length > visibleFabrics
      ? quickshipFabrics.length - visibleFabrics
      : "";
  const remainingMadeToOrder =
    madeToOrderFabrics.length > visibleFabrics
      ? madeToOrderFabrics.length - visibleFabrics
      : "";

  const { setSelectedSwatch, selectedSwatch } = useProductContext() || {};

  useEffect(() => {
    if (isMobile) {
      setVisibleQuickShip(visibleQuickShip.slice(0, 4));
      setVisibleMadeToOrder(visibleMadeToOrder.slice(0, 4));
    } else {
      if (visibleQuickShip.length === 4)
        visibleQuickShip.push(quickshipFabrics[4]);
      if (visibleMadeToOrder.length === 4)
        visibleMadeToOrder.push(madeToOrderFabrics[4]);

      setVisibleQuickShip(visibleQuickShip.slice(0, 5));
      setVisibleMadeToOrder(visibleMadeToOrder.slice(0, 5));
    }
  }, [isMobile]);

  useEffect(() => {
    setVisibleQuickShip(quickshipFabrics.slice(0, visibleFabrics));
    setVisibleMadeToOrder(madeToOrderFabrics.slice(0, visibleFabrics));
  }, [productId, visibleFabrics, madeToOrderFabrics, quickshipFabrics]);

  const handleOnClick = (fabric) => {
    setSelectedSwatch(fabric);
  };

  // State for FabricPanel SlideIn
  const [isSlideInOpen, setIsSlideInOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  // State for OrderSwatchesPanel SlideIn
  const [isSwatchesSlideInOpen, setIsSwatchesSlideInOpen] = useState(false);
  const [isSwatchesClosing, setIsSwatchesClosing] = useState(false);

  const openSlideIn = () => setIsSlideInOpen(true);
  const closeSlideIn = () => {
    if (fabricPanelRef?.current) {
      fabricPanelRef.current.onParentWillClose();
    }
    setIsFilterOpen(false);
    setIsSlideInOpen(false);
    setIsClosing(true);

    setTimeout(() => {
      setIsClosing(false);
    }, 300);
  };

  // Open/Close functions for OrderSwatchesPanel SlideIn
  const openSwatchesSlideIn = () => setIsSwatchesSlideInOpen(true);
  const closeSwatchesSlideIn = () => {
    setIsSwatchesSlideInOpen(false);
    setIsSwatchesClosing(true);

    setTimeout(() => {
      setIsSwatchesClosing(false);
    }, 300);
  };

  // Trigger for opening OrderSwatchesPanel
  const handleOpenSwatches = (e) => {
    e.preventDefault();
    handleLoadAllFabricImages();
    openSwatchesSlideIn();
  };

  const handleOpen = (e) => {
    e.preventDefault();
    handleLoadAllFabricImages();
    openSlideIn();
  };

  useEffect(() => {
    if (!selectedSwatch) {
      setSelectedSwatch(visibleQuickShip[0]);
    }
  }, []);

  return (
    <FabricSelectorWrapper>
      <FabricSelectorContainer>
        <FabricSelectorTitle>
          <Text copy="Fabric" themeName="headline5Medium" />
        </FabricSelectorTitle>
        <SelectedFabric>
          <Text
            copy={selectedSwatch?.name}
            theme={fabricSelectorTheme?.selectedFabricName}
          />
        </SelectedFabric>
      </FabricSelectorContainer>
      {visibleQuickShip.length > 0 && (
        <SwatchesContainer>
          <SwatchesContainerTitle>
            <Text
              copy={swatchHeadings?.priorityArrival}
              themeName="textSmall"
              fontColor={"#667487"}
              fontFamily="Beatrice"
            />
          </SwatchesContainerTitle>
          <TileContainer>
            {isLoadingQuickshipImages ? (
              <FabricsLoadingPlaceholders count={visibleQuickShip?.length} />
            ) : (
              <Swatches>
                {visibleQuickShip?.map((fabric, idx) => {
                  const { name, swatchProduct, imageSet } = fabric;
                  const imageId = swatchProduct?.id;
                  return (
                    <ImageContainer
                      title={name}
                      key={idx}
                      onClick={() => handleOnClick(fabric)}
                    >
                      <SwatchImage
                        imageId={imageId}
                        ImageTitle={name}
                        imageSet={imageSet}
                        initialSwatch={selectedSwatch}
                      />
                    </ImageContainer>
                  );
                })}
              </Swatches>
            )}
            {remainingQuickShip && (
              <RemainingOrder>
                <Text
                  copy={`+${remainingQuickShip} Fabrics`}
                  themeName="textSmall"
                  fontFamily="Beatrice"
                />
              </RemainingOrder>
            )}
          </TileContainer>
        </SwatchesContainer>
      )}

      {visibleMadeToOrder.length > 0 && (
        <SwatchesContainer>
          <SwatchesContainerTitle>
            <Text
              copy={swatchHeadings?.regularArrival}
              themeName="textSmall"
              fontColor={"#667487"}
              fontFamily="Beatrice"
            />
          </SwatchesContainerTitle>
          <TileContainer>
            {isLoadingMadeToFabricImages ? (
              <FabricsLoadingPlaceholders count={visibleMadeToOrder?.length} />
            ) : (
              <Swatches>
                {visibleMadeToOrder?.map((fabric, idx) => {
                  const { name, swatchProduct, imageSet } = fabric;
                  const imageId = swatchProduct?.id;
                  return (
                    <ImageContainer
                      key={idx}
                      onClick={() => handleOnClick(fabric)}
                    >
                      <SwatchImage
                        imageId={imageId}
                        ImageTitle={name}
                        imageSet={imageSet}
                        initialSwatch={selectedSwatch}
                      />
                    </ImageContainer>
                  );
                })}
              </Swatches>
            )}
            {remainingMadeToOrder && (
              <RemainingOrder>
                <CTA
                  cta={{
                    ctaText: `+${remainingMadeToOrder} Fabrics`,
                    onCtaClick: handleOpen
                  }}
                  caretRight={true}
                />
              </RemainingOrder>
            )}
          </TileContainer>
        </SwatchesContainer>
      )}

      <SelectorCTAContainer>
        <CTA
          cta={{ ctaText: "ORDER SWATCHES", onCtaClick: handleOpenSwatches }}
          themeName="CTA Underlined"
          color="#243953"
          fontFamily="BeatriceRegular"
        />
        <CTA
          cta={{
            ...fabricGuideCta?.cta?.cta
          }}
          fontFamily="BeatriceRegular"
          color="#243953"
          themeName="CTA Underlined"
          theme={fabricGuideCta?.cta?.theme}
        />
      </SelectorCTAContainer>
      <SlideIn
        isOpen={isSlideInOpen}
        onClose={closeSlideIn}
        position={isMobile ? SLIDE_IN_POSITION.BOTTOM : SLIDE_IN_POSITION.RIGHT}
        backgroundColor="#fff"
        key="fabric-panel-slide-in"
        visibleBackdrop={!isDesktop}
        closeButtonAriaLabel="Close fabric panel"
      >
        {(isSlideInOpen || isClosing) && (
          <FabricPanel
            ref={fabricPanelRef}
            imgUrl={imgUrl}
            swatchHeadings={swatchHeadings}
            selectedFabric={selectedSwatch}
            topLineVariation={topLineVariation}
            cuOptions={cuOptions}
            currentVariations={currentVariations}
            productId={productId}
            setSelectedSwatch={setSelectedSwatch}
            cartPopupHeader={cartPopupHeader}
            visibleQuickShip={visibleQuickShip}
            setVisibleQuickShip={setVisibleQuickShip}
            visibleMadeToOrder={visibleMadeToOrder}
            setVisibleMadeToOrder={setVisibleMadeToOrder}
            filters={filters}
            eddData={eddData}
            showEdd={showEdd}
          />
        )}
      </SlideIn>
      <SlideIn
        isOpen={isSwatchesSlideInOpen}
        onClose={closeSwatchesSlideIn}
        position={isMobile ? SLIDE_IN_POSITION.BOTTOM : SLIDE_IN_POSITION.RIGHT}
        backgroundColor="#fff"
        className="order-swatches-slide"
        hideCloseButton={isFilterOpen}
        key="swatches-slide-in"
        visibleBackdrop={!isDesktop}
        closeButtonAriaLabel="Close order swatches panel"
      >
        {(isSwatchesSlideInOpen || isSwatchesClosing) && (
          <OrderSwatchesPanel
            filters={filters}
            price={price}
            isEdit={isEdit}
            isLoading={isLoading}
            productId={productId}
            handleAddSwatches={handleAddSwatches}
            quickshipFabrics={quickshipFabrics}
            madeToOrderFabrics={madeToOrderFabrics}
            swatchHeadings={swatchHeadings}
            setSelectedSwatchList={setSelectedSwatchList}
            cartPopupHeader={cartPopupHeader}
            orderSwatchesMessage={orderSwatchesMessage}
          />
        )}
      </SlideIn>
    </FabricSelectorWrapper>
  );
};

export default FabricSelector;

FabricSelector.propTypes = {
  madeToOrderFabrics: PropTypes.array,
  quickshipFabrics: PropTypes.array,
  swatchHeadings: PropTypes.object,
  imgUrl: PropTypes.string,
  topLineVariation: PropTypes.string,
  cuOptions: PropTypes.array,
  currentVariations: PropTypes.object,
  productId: PropTypes.string,
  filters: PropTypes.object,
  eddData: PropTypes.object,
  showEdd: PropTypes.bool,
  price: PropTypes.string,
  isEdit: PropTypes.bool,
  isLoading: PropTypes.bool,
  handleAddSwatches: PropTypes.func,
  setSelectedSwatchList: PropTypes.func,
  cartPopupHeader: PropTypes.object,
  fabricGuideCta: PropTypes.object,
  orderSwatchesMessage: PropTypes.object
};
