import React from "react";
import CTA from "../CTA";

const SharedAddedToBagCTAs = ({
  isDSO,
  isMobile,
  handleClosePanel,
  onCheckoutClick,
  onPayAndCarryClick,
  hasRetailExclusiveItems,
  isPayAndCarryEligible,
  storeId,
  isAddedBagPanel,
  takeActionWarning
}) => {
  if (isDSO) {
    return (
      <React.Fragment>
        <CTA
          cta={{ ctaText: "VIEW BAG", url: "/shoppingbag" }}
          themeName="Secondary Button"
        />
        {!takeActionWarning && !hasRetailExclusiveItems && !isAddedBagPanel && (
          <CTA
            cta={{ ctaText: "CHECKOUT", onCtaClick: onCheckoutClick }}
            themeName="Primary Button"
          />
        )}
        {isPayAndCarryEligible &&
          hasRetailExclusiveItems &&
          storeId !== "5" && (
            <CTA
              cta={{
                ctaText: "IN-STORE PURCHASE",
                onCtaClick: onPayAndCarryClick
              }}
              themeName="Primary Button"
              buttonType="button"
              disabled={takeActionWarning}
              elementName="cta-pay-and-carry"
            />
          )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {!isMobile ? (
        <React.Fragment>
          <CTA
            cta={{
              ctaText: "KEEP SHOPPING",
              onCtaClick: handleClosePanel
            }}
            useButtonTag={true}
            themeName="Secondary Button"
          />
          <CTA
            cta={{ ctaText: "VIEW BAG", url: "/shoppingbag" }}
            themeName="Primary Button"
          />
        </React.Fragment>
      ) : (
        <CTA
          cta={{
            ctaText: "VIEW BAG & CHECKOUT",
            url: "/shoppingbag"
          }}
          themeName="Primary Button"
        />
      )}
    </React.Fragment>
  );
};

export default SharedAddedToBagCTAs;
