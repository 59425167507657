import { ContentClient } from "dc-delivery-sdk-js";

const fetchByIds = async (ids) => {
  const hubName = process.env.HUB_NAME || "";
  const vseUrl = process.env.VSE_URL || "";

  let client;

  if (hubName && !vseUrl) {
    client = new ContentClient({
      hubName: hubName
    });
  } else if (hubName && vseUrl) {
    client = new ContentClient({
      hubName: hubName,
      stagingEnvironment: vseUrl
    });
  }

  const res = await client.getContentItemsById(ids);
  const content = res?.responses?.map((contentItem) => contentItem?.content);
  return content;
};

export default fetchByIds;
