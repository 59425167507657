/* eslint-disable max-lines-per-function */
import { useState } from "react";
import PropTypes from "prop-types";
import { useBreakpoint } from "../Breakpoints";
import { useContent } from "../../contexts/ContentContext";
import {
  EmailSubscriptionWrapper,
  ContentContainer,
  EmailSubscriptionForm,
  EmailInputContainer,
  SubmitEmail,
  SubmittedMessageContainer,
  SquaredEmailInputContainer,
  SubscriptionTitle,
  SubscriptionSubText
} from "./EmailSubscriptionStyled";
import getAssetUrl from "../../utils/getAssetUrl";
import Text from "../Text";
import { TextInput } from "../FormComponents";
import RichText from "../RichText";
import Icons from "../Icons";
import RadioButtons from "./RadioButtons";
import CTA from "../CTA";
import getAlignmentCSS from "./utils/getAlignmentCSS";
import DEFAULT_ERROR_MESSAGES from "../../constants/formErrorMessages/subscriptionErrors";
import { useForm } from "react-hook-form";
import { validateEmail } from "../../utils/validateEmail";
import SnlImage from "../SnlImage";
import BreakpointImage from "../BreakpointImage";
import useEmailSubscription from "@serenaandlily/hooks/emails/useEmailSubscription";
import useEmailValidation from "@serenaandlily/hooks/useEmailValidation";
import sendNonBuyersData from "@utils/nonBuyersData/sendNonBuyersData";

const EmailSubscription = ({
  bannerImage,
  breakpointImage,
  altText,
  backgroundColor,
  tabletBackgroundColor,
  mobileBackgroundColor,
  subscriptionBannerTitle,
  subscriptionBannerSubtext,
  emailInput,
  layout,
  termsAndCondition,
  subscriptionErrors = DEFAULT_ERROR_MESSAGES,
  submittedMessage,
  radioButtonType,
  radioButtonPlacement,
  marginBottom,
  disableContentPadding,
  source = "Site Banner"
}) => {
  const { siteMessages } = useContent();
  const { isMutating, submitEmail } = useEmailSubscription();
  const { validateFormattedEmail } = useEmailValidation();

  const bannerImageSrc = bannerImage && getAssetUrl(bannerImage?.src);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const breakpoints = useBreakpoint();
  const isDesktop = breakpoints["ScreenWidth"];
  const [emailFreq, setEmailFreq] = useState("daily");

  const isDaily = emailFreq === "daily";

  const { placeholder, inputStyle } = emailInput || {};

  const isRoundedInput = inputStyle === "Rounded";

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    mode: "onBlur",
    defaultValues: { email: "" }
  });

  const handleTab = (e, tab) => {
    if (e) e.preventDefault();
    setEmailFreq(tab);
  };

  const validCallback = (data, e) => {
    e.preventDefault();
    sendNonBuyersData({...data, isDaily} , source === 'Site Banner'?  'site-banner' : source)
    submitEmail(data.email, { source, isDaily }, () => setIsSubmitted(true));
  };

  const alignmentCSS = getAlignmentCSS(layout);

  const emailRegister = register("email", {
    required: subscriptionErrors?.email?.emptyError,
    validate: {
      validEmail: (value) =>
        validateEmail(value) || subscriptionErrors?.email?.invalidError,
      validSendgridEmail: (value) => validateFormattedEmail(value, source)
    }
  });

  return (
    <EmailSubscriptionWrapper marginBottom={marginBottom}>
      {bannerImageSrc && !breakpointImage && (
        <SnlImage
          src={bannerImageSrc}
          customWidth={isDesktop ? 477 : 100}
          layout="responsive"
          alt={altText}
        />
      )}
      {breakpointImage && (
        <BreakpointImage {...breakpointImage} altText={altText} />
      )}
      <ContentContainer
        backgroundColor={backgroundColor}
        tabletBackgroundColor={tabletBackgroundColor}
        mobileBackgroundColor={mobileBackgroundColor}
        disableContentPadding={disableContentPadding}
        bannerImageSrc={bannerImageSrc}
        {...alignmentCSS}
      >
        {subscriptionBannerTitle && (
          <SubscriptionTitle>
            <Text {...subscriptionBannerTitle?.text} lineHeight="120%" />
          </SubscriptionTitle>
        )}
        {subscriptionBannerSubtext && (
          <SubscriptionSubText>
            <RichText {...subscriptionBannerSubtext?.richText} />
          </SubscriptionSubText>
        )}
        {!isSubmitted && (
          <EmailSubscriptionForm
            onSubmit={handleSubmit(validCallback)}
            siteMessages={siteMessages}
          >
            {radioButtonType && (
              <RadioButtons
                radioButtonType={radioButtonType}
                handleTab={handleTab}
                isDaily={isDaily}
                radioButtonPlacement={radioButtonPlacement}
                emailFreq={emailFreq}
              />
            )}
            {isRoundedInput && (
              <EmailInputContainer>
                <TextInput
                  {...emailRegister}
                  id="subscription-email"
                  type="email"
                  label={placeholder}
                  error={errors?.email?.message}
                  onBlur={(e) => {
                    emailRegister.onBlur(e);
                  }}
                />
                <SubmitEmail
                  type="submit"
                  hasError={errors?.email?.message}
                  aria-label="Submit email to join the email list"
                >
                  <Icons type="ArrowRight" fill="#243953" />
                </SubmitEmail>
              </EmailInputContainer>
            )}

            {!isRoundedInput && (
              <SquaredEmailInputContainer>
                <TextInput
                  {...register("email", {
                    required: subscriptionErrors?.email?.emptyError,
                    validate: {
                      validEmail: (value) =>
                        validateEmail(value) ||
                        subscriptionErrors?.email?.invalidError,
                      validSendgridEmail: (value) =>
                        validateFormattedEmail(value, source)
                    }
                  })}
                  id="subscription-email"
                  placeholder={emailInput?.placeholder}
                  type="email"
                  error={errors?.email?.message}
                />
                <CTA
                  cta={{
                    ctaText: "Submit",
                    onCtaClick: handleSubmit(validCallback)
                  }}
                  isMutating={isMutating}
                  themeName="Button B"
                />
              </SquaredEmailInputContainer>
            )}

            <RichText {...termsAndCondition?.richText} />
          </EmailSubscriptionForm>
        )}
        {isSubmitted && (
          <SubmittedMessageContainer
            svgFill={submittedMessage?.richText?.fontColor}
          >
            <Icons type="CheckCircleOutline" />
            <RichText {...submittedMessage.richText} />
          </SubmittedMessageContainer>
        )}
      </ContentContainer>
    </EmailSubscriptionWrapper>
  );
};

export default EmailSubscription;

EmailSubscription.propTypes = {
  bannerImage: PropTypes.object,
  backgroundColor: PropTypes.string,
  subscriptionBannerTitle: PropTypes.object,
  breakpointImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  altText: PropTypes.object,
  subscriptionBannerSubtext: PropTypes.object,
  subscriptionErrors: PropTypes.object,
  submittedMessage: PropTypes.object,
  radioButtonType: PropTypes.string,
  radioButtonPlacement: PropTypes.string,
  emailInputPlaceholder: PropTypes.string,
  termsAndCondition: PropTypes.object,
  tabletBackgroundColor: PropTypes.string,
  mobileBackgroundColor: PropTypes.string,
  submitButtonTheme: PropTypes.object,
  emailInput: PropTypes.object,
  layout: PropTypes.object,
  marginBottom: PropTypes.number,
  disableContentPadding: PropTypes.bool,
  source: PropTypes.string
};
