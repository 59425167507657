import styled, { css } from "styled-components";
import { SLIDE_IN_POSITION } from "./constants";
import { Breakpoints } from "@serenaandlily/constants/constants";

export const SlideInWrapper = styled.div`
  position: fixed;
  box-shadow: ${({ boxShadow }) => boxShadow || "none"};
  transform: none;
  ${({ position, top }) =>
    position === SLIDE_IN_POSITION.TOP
      ? css`
          top: 0;
          left: 0;
          right: 0;
        `
      : css`
          top: ${top};
        `}
  ${({ position }) =>
    position === SLIDE_IN_POSITION.BOTTOM &&
    css`
      bottom: 0;
      left: 0;
      right: 0;
    `}
  ${({ position, top }) =>
    position === SLIDE_IN_POSITION.LEFT &&
    css`
      top: ${top};
      bottom: auto;
      left: 0;
    `}
  ${({ position, top }) =>
    position === SLIDE_IN_POSITION.RIGHT &&
    css`
      top: ${top};
      bottom: auto;
      right: 0;
    `}
  max-width: ${({ maxWidth, position }) =>
    position === SLIDE_IN_POSITION.LEFT || position === SLIDE_IN_POSITION.RIGHT
      ? maxWidth || "100vw"
      : "100%"};
  height: ${({ top, position }) =>
    position === SLIDE_IN_POSITION.TOP || position === SLIDE_IN_POSITION.BOTTOM
      ? "100%"
      : `calc(100vh - ${top})`};
  background-color: ${({ backgroundColor }) => backgroundColor || "#fff"};
  transition: transform 0.3s ease-in-out;
  ${({ position, isVisible }) =>
    position === SLIDE_IN_POSITION.LEFT &&
    css`
      transform: ${isVisible ? "none" : "translateX(-100%)"};
    `}
  ${({ position, isVisible }) =>
    position === SLIDE_IN_POSITION.RIGHT &&
    css`
      transform: ${isVisible ? "none" : "translateX(100%)"};
    `}
  ${({ position, isVisible }) =>
    position === SLIDE_IN_POSITION.TOP &&
    css`
      transform: ${isVisible ? "translateY(0)" : "translateY(-100%)"};
    `}
  ${({ position, isVisible }) =>
    position === SLIDE_IN_POSITION.BOTTOM &&
    css`
      transform: ${isVisible ? "translateY(0)" : "translateY(100%)"};
    `}
  z-index: 9999;
`;

export const Backdrop = styled.div`
  position: fixed;
  top: ${({ top }) => top};
  left: 0;
  width: 100%;
  height: ${({ height }) => height};
  background: ${({ isVisible }) =>
    isVisible ? "rgba(0, 0, 0, 0.5)" : "transparent"};
  z-index: 9998;
`;

export const CloseButton = styled.button`
  background-color: #fff;
  border: 1px solid #a7b0ba;
  border-radius: 100%;
  padding: 8px;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 24px;
  top: 28px;
  cursor: pointer;
  z-index: 9999;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${Breakpoints.IPad}) {
    top: 21px;
  }
  @media screen and (min-width: ${Breakpoints.IPad}) and (max-width: ${Breakpoints.ScreenWidth}) {
    top: 22px;
  }

`;
