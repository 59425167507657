import React, { useEffect, useRef, useState } from "react";
import Text from "../Text";
import { motion, AnimatePresence } from "framer-motion";
import {
  AddNoteContainer,
  AddNoteTitle,
  CenterDiv,
  Image,
  NoteFormWrapper,
  ProductImageWrapper,
  ProductInfoRow,
  ProductTitleWrapper,
  SubmittedMessageWrapper
} from "./AddNoteModalContentStyled";
import defaultTheme from "./AddNoteModalContentTheme";
import useComponentTheme from "../Theme/useComponentTheme";
import { FormProvider, useForm } from "react-hook-form";
import TextBox from "../TextBox";
import { Colors, Typography } from "@serenaandlily/constants/constants";
import CTA from "../CTA";
import NoteAddedIcon from "../NoteAddedIcon";
import useAddToWishlistMutation from "@serenaandlily/hooks/useAddToWishlistMutation";

const AddNoteModalContent = ({
  theme = defaultTheme,
  imageUrl,
  productName,
  netsuiteId,
  maxNoteLength,
  customUpholsteryOptions,
  note,
  ga4Props
}) => {
  const addNoteModalTheme = useComponentTheme({ theme });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const methods = useForm({
    mode: "onBlur",
    defaultValues: { note }
  });
  const inputRef = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        const length = inputRef.current.value.length;
        inputRef.current.setSelectionRange(length, length);
      }
    }, 100);

    return () => clearTimeout(timeout);
  }, [inputRef?.current]);

  const { isMutatingNote, triggerNoteUpdate } = useAddToWishlistMutation({
    itemData: [
      {
        customUpholsteryOptions,
        netsuiteId,
        quantity: 1
      }
    ],
    ga4Props
  });

  const handleOnSubmit = async () => {
    try {
      const noteValue = methods.getValues("note");
      const isAdding = noteValue && !note;
      const isEditing = noteValue && noteValue !== note;
      const isRemoving = !noteValue && note;
      await triggerNoteUpdate({ noteValue, isAdding, isEditing, isRemoving });
      setIsSubmitted(true);
    } catch {
      methods.setError("note", {
        type: "note",
        message: "Failed to save your note. Please try again."
      });
    }
  };

  const actionMessage = note?.trim() ? "added" : "removed";

  return (
    <AddNoteContainer>
      <motion.div layout>
        <AnimatePresence mode="wait" exitBeforeEnter>
          {!isSubmitted ? (
            <motion.div
              key="form"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              layout
            >
              <AddNoteTitle>
                <Text
                  theme={addNoteModalTheme.addNoteTitle}
                  copy="Add a Note"
                />
              </AddNoteTitle>
              <ProductInfoRow>
                <ProductImageWrapper>
                  <Image src={imageUrl} />
                </ProductImageWrapper>
                <ProductTitleWrapper>
                  <Text
                    theme={addNoteModalTheme.productTitle}
                    copy={productName}
                  />
                </ProductTitleWrapper>
              </ProductInfoRow>
              <NoteFormWrapper>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(handleOnSubmit)}>
                    <TextBox
                      {...methods.register("note")}
                      id="note"
                      ref={inputRef}
                      formFieldName="note"
                      control={methods.control}
                      setValue={methods.setValue}
                      showCount={false}
                      type="textarea"
                      placeholderText="Add a note"
                      rows={5}
                      height={131}
                      marginBottom={0}
                      textBoxWidth="100"
                      fontFamily={Typography.BeatriceLight}
                      fontSize={16}
                      resizeType="none"
                      padding="16px"
                      borderRadius={0}
                      borderColor={Colors["Light Secondary"]}
                      fontColor={Colors.Midnight}
                      backgroundColor={Colors.White}
                      countLimit={maxNoteLength}
                      fullWidth={true}
                    />
                    {methods?.formState?.errors?.note && (
                      <Text
                        theme={addNoteModalTheme.errorText}
                        copy={methods?.formState?.errors?.note?.message}
                      />
                    )}
                    <CTA
                      cta={{
                        ctaText: "SAVE"
                      }}
                      buttonType="submit"
                      disabled={isMutatingNote}
                      useButtonTag={true}
                      themeName="Primary Button"
                      elementName="cta-next"
                    />
                  </form>
                </FormProvider>
              </NoteFormWrapper>
            </motion.div>
          ) : (
            <motion.div
              key="success"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              layout
            >
              <SubmittedMessageWrapper>
                <CenterDiv>
                  <NoteAddedIcon />
                </CenterDiv>
                <Text
                  theme={addNoteModalTheme.noteAddedTitle}
                  copy={`Your note has been ${actionMessage}`}
                />
              </SubmittedMessageWrapper>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </AddNoteContainer>
  );
};

export default AddNoteModalContent;
