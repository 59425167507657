import { Colors, FontSizes, Typography } from "@serenaandlily/constants/constants";

const defaultTheme = {
  addNoteTitle: {
    themeName: "addNoteTitle",
    fontSizeDesktop: 24,
    fontSizeTablet: 24,
    fontSize: 24,
    fontFamily: Typography.PPFragment,
    marginBottom: 32
  },
  noteAddedTitle: {
    themeName: "noteAddedTitle",
    fontSizeDesktop: 24,
    fontSizeTablet: 24,
    fontSize: 24,
    fontFamily: Typography.PPFragment,
    marginBottom: 16
  },
  productTitle: {
    themeName: "productTitle",
    fontSizeDesktop: 16,
    fontSizeTablet: 16,
    fontSize: 16,
    fontFamily: Typography.BeatriceRegular
  },
  errorText: {
    tag: "p",
    fontColor: Colors.Crimson,
    fontSize: FontSizes.Base,
    fontSizeTablet: FontSizes.Base,
    fontSizeDesktop: FontSizes.Base,
    marginBottom: 20,
    fontFamily: Typography.BeatriceMedium
  }
};

export default defaultTheme;
