import fetchImageSet from "../fetchImageSets/fetchImageSets";

const addCustomUpholsteryImageData = async (customUpholstery, maxLoad) => {
  const newCustomUpholstery = JSON.parse(JSON.stringify(customUpholstery));
  const { madeToOrderFabrics, quickshipFabrics } =
    newCustomUpholstery.fabrics || [];
  const loopLimit = maxLoad ? maxLoad : madeToOrderFabrics.length;

  const promises = [];

  for (const fabric of madeToOrderFabrics.slice(0, loopLimit)) {
    if (!fabric.imageSet && fabric?.swatchProduct?.netsuiteId) {
      promises.push(
        fetchImageSet(
          fabric?.swatchProduct?.netsuiteId,
          fabric?.swatchProduct?.overRideMediaSet
        ).then((imageSet) => {
          fabric.imageSet = imageSet?.data;
        })
      );
    }
  }

  for (const fabric of quickshipFabrics) {
    promises.push(
      fetchImageSet(
        fabric?.swatchProduct?.netsuiteId,
        fabric?.swatchProduct?.overRideMediaSet
      ).then((imageSet) => {
        fabric.imageSet = imageSet?.data;
      })
    );
  }

  await Promise.all(promises);

  return newCustomUpholstery;
};

export default addCustomUpholsteryImageData;
