import React from "react";
import PropTypes from "prop-types";
import defaultTheme from "./ProductNameTheme";
import Text from "../Text";
import useComponentTheme from "../Theme/useComponentTheme";
import { ProductNameWrapper, ProductNameContainer } from "./ProductNameStyled";

const STL_STR = "stl";
const ProductName = ({
  name,
  theme = defaultTheme,
  source = "",
  tag = "h1",
  wishlistComponent,
  ...productNameProps
}) => {
  const isSTL = source === STL_STR;
  const productNameTheme = useComponentTheme({ theme });

  return name ? (
    <ProductNameWrapper>
      <ProductNameContainer>
        <Text
          tag={tag}
          {...productNameProps}
          copy={name}
          themeName={productNameTheme?.productName?.themeName}
          elementName={`${source}productname`}
          {...productNameTheme?.productName?.overrides}
          theme={isSTL ? productNameTheme?.productNameStlTheme : null}
        />
      </ProductNameContainer>
      {/* post launch content */}
      {/* <Icons type="Heart" /> */}
      {wishlistComponent}
    </ProductNameWrapper>
  ) : null;
};

export default ProductName;

ProductName.propTypes = {
  name: PropTypes.string,
  tag: PropTypes.string,
  theme: PropTypes.object,
  source: PropTypes.string
};
