import React from "react";
import PropTypes from "prop-types";
import {
  CarouselContainer,
  ScrollableContainer
} from "./CUFilterCTACarouselStyled";
import CUFilterCTA from "../CUFilterCTA";

const CUFilterCTACarousel = ({ filters, ...props }) => {
  return (
    <CarouselContainer>
      <ScrollableContainer>
        {filters.map((filter, index) => (
          <div className="carousel__slide" key={index}>
            <CUFilterCTA {...filter} {...props} />
          </div>
        ))}
      </ScrollableContainer>
    </CarouselContainer>
  );
};

export default CUFilterCTACarousel;

CUFilterCTACarousel.propTypes = {
  filters: PropTypes.array
};
