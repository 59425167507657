import { Breakpoints } from "@serenaandlily/constants/constants";
import styled from "styled-components";

export const PromoMessagesWrapper = styled.div`
  display: ${({ displayBlock }) => (displayBlock ? "block" : "inline-block")};
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: ${({ backgroundColor }) => (backgroundColor ? "8px" : "0")};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  border-radius: 4px;
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  box-sizing: border-box;
  @media (max-width: ${Breakpoints.XSMobileMax}) {
    margin-bottom: 0px;
  }
`;
