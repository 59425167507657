import React from "react";
import PropTypes from "prop-types";
import { ContactInfoWrapper, CTAContainer } from "./ContactInfoStyled";
import Image from "../Image";
import CTA from "../CTA";
import defaultTheme from "./ContactInfoTheme";
import useComponentTheme from "../Theme/useComponentTheme";

const ContactInfo = ({ image, contactCtas, theme = defaultTheme }) => {
  const contactInfoTheme = useComponentTheme({ theme });

  return (
    <ContactInfoWrapper>
      <Image {...image} />
      <CTAContainer>
        {contactCtas?.ctas?.map((contactCta, idx) => {
          const key = `Contact-CTA-${idx}`;
          return (
            <CTA
              key={key}
              themeName={contactInfoTheme?.ctas?.themeName}
              {...contactCta}
            />
          );
        })}
      </CTAContainer>
    </ContactInfoWrapper>
  );
};

export default ContactInfo;

ContactInfo.propTypes = {
  image: PropTypes.object,
  contactCtas: PropTypes.array,
  theme: PropTypes.object
};
