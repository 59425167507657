import styled from "styled-components";
import { Breakpoints, Colors } from "../../constants/constants";

const defaultSlideHeight = 600;

export const EmblaCarousel = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`;

export const EmblaViewport = styled.div`
  overflow: hidden;
  width: 100%;

  .is-draggable {
    cursor: move;
    cursor: grab;
  }

  .is-dragging {
    cursor: grabbing;
  }
`;

export const EmblaContainer = styled.div`
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

export const EmblaDotNavContainer = styled.div`
  display: flex;
  list-style: none;
  justify-content: center;
  padding-top: 10px;

  @media (max-width: ${Breakpoints.XSMobileMax}) {
    padding-top: 8px;
  }
`;
export const EmblaProgress = styled.div`
  border-radius: 1.8rem;
  box-shadow: inset 0 0 0 0.2rem gray;
  background-color: gray;
  position: relative;
  height: 0.6rem;
  justify-self: flex-end;
  align-self: center;
  width: 13rem;
  max-width: 90%;
  overflow: hidden;
`;
export const EmblaProgressBar = styled.div`
  .embla__progress__bar {
    background-color: red;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: -100%;
  }
`;

export const EmblaSlide = styled.div`
  position: relative;
  min-width: ${({ width }) => (width ? width : "100%")};
  margin-left: 10px;

  ${({ widthPercentage }) =>
    widthPercentage
      ? `
flex: 0 0 ${widthPercentage} !important;
max-width: ${widthPercentage}!important;
`
      : null}
`;

export const EmblaSlideInner = styled.div`
  position: relative;
  overflow: hidden;
  height: ${({ height }) => `${height || defaultSlideHeight}px`};
`;

export const EmblaButton = styled.button`
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  fill: #000000;
  padding: 0;
  display: flex;
  justify-content: center;

  :disabled {
    cursor: default;
    opacity: 0.3;
  }

  :focus {
    outline: 3px solid #ffbf47;
    outline-offset: 0;
  }
`;

export const DotButtonElement = styled.button`
  background-color: transparent;
  cursor: pointer;
  position: relative;
  padding: 0;
  width: 3rem;
  height: 3rem;
  margin: 0;
  border: 0;
  display: flex;
  align-items: center;

  &::after {
    background-color: ${Colors["Einsten Carousel Grey"]};
    border-radius: 50%;
    width: 15px;
    height: 15px;
    content: "";

    ${({ selected }) =>
      selected &&
      `
        background-color:  ${Colors["Light Primary"]};
        opacity: 1;
      `}
  }
`;

export const EmblaButtonSVG = styled.svg`
  width: 100%;
  height: 100%;
`;

export const PrevButtonElement = styled(EmblaButton)`
  left: 27px;
`;

export const NextButtonElement = styled(EmblaButton)`
  right: 27px;
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  justify-content: ${({ position }) => position};
  margin-top: 32px;
  @media screen and (min-width: ${Breakpoints.SMTabletMin}) and (max-width: ${Breakpoints.SMTabletMax}) {
    margin-top: 20px;
  }
`;
