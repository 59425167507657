import styled from "styled-components";
import { Colors } from "@serenaandlily/constants/constants";
import { motion } from "framer-motion";

export const WishlistIconContainer = styled(motion.button)`
  border: none;
  background: transparent;
  width: fit-content;
  cursor: pointer;
  padding: 0;
  align-self: start;
  > svg {
    stroke: ${Colors["Midnight"]};
    fill: ${({ isWishlisted }) =>
      isWishlisted ? Colors["Midnight"] : Colors["Wishlist Transparent White"]};
  }
`;
