import { Breakpoints, Spacing } from "@serenaandlily/constants/constants";
import styled from "styled-components";

export const ColorTilesContainer = styled.div`
  margin-bottom: ${({ pdpHasErrorMessage }) =>
    pdpHasErrorMessage ? `${Spacing.Small}px` : `${Spacing.Base}px`};

  width: 100%;
`;

export const ColorTitle = styled.legend`
  font-weight: 600;
  padding: 0;
  display: flex;
  margin-bottom: 8px;

  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    margin-bottom: ${Spacing.MediumSmall}px;
  }
`;

export const Color = styled.span`
  font-weight: 400;
  padding-left: 8px;
  text-transform: capitalize;
`;

export const ColorTilesFieldset = styled.fieldset`
  display: flex;
  border: none;
  margin: 0;
  padding: 0;
`;

export const ColorTilesWrapper = styled.div`
  display: flex;
  padding: 0px;
  gap: 2px;
  flex-wrap: wrap;
`;

export const TileImage = styled.div`
  ${({ imageSrc }) => (imageSrc ? `background: url(${imageSrc});` : "")}
  background-color: ${({ rgbColor }) =>
    `rgb(${rgbColor?.replace("{", "").replace("}", "")})`};
  background-position: center;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const DoubleTileContainer = styled.div`
  height: 100%;
  > ${TileImage} {
    height: 50%;
  }
`;
