import styled from "styled-components";

export const PDPPrintContainer = styled.div`
  display: none;

  @media print {
    display: block;
  }

  padding: 0 36px;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.39cm;
`;

export const Image = styled.img`
  width: 200px;
  margin: 0 auto;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.3cm 0;
`;

export const Logo = styled.img`
  margin: 0 auto;
  max-width: 250px;
`;

export const ProductName = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.39cm;
`;

export const ItemInfo = styled.div`
  margin-bottom: 0.3cm;
`;

export const AlsoAvailable = styled.div`
  margin-bottom: 0.3cm;
`;

export const DimensionAndCare = styled.div`
  margin-bottom: 0.3cm;
`;

export const TextContainer = styled.div``;
