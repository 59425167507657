import styled from "styled-components";
import {
  Typography,
  Colors,
  Breakpoints,
  FontSizes
} from "../../constants/constants";

const SEPARATION_FROM_BUTTON = 20;
const SEPARATION_FROM_BUTTON_SMALL_DESKTOP = 10;

export const SlideOutContainer = styled.nav`
  color: ${Colors["Dark Primary"]};
  ${({ useSlideoutOnDesktop }) =>
    !useSlideoutOnDesktop ? "display: none" : "display: flex"};
  flex-direction: row;
  font-family: ${Typography.ProximaNova};
  font-size: ${FontSizes.Large};
  flex-direction: column
  padding-left: 8px;
  padding-right: 8px;
  transform: translateX(-300px);
  height: ${({ slideOutMenuHeightMobile }) => `${slideOutMenuHeightMobile}px`};
  left: 0;
  background-color: ${Colors.White};
  overflow-x: hidden;
  overflow-y: hidden;

  position: ${({ showFixedSlide }) => (showFixedSlide ? "fixed" : "")};
  transition: 1s ease-in-out;

  &.menu-open-mobile {

    transition: 1s ease-in-out;
    transform: translateX(0px);
  }

  div::-webkit-scrollbar {
    display:none;
  }

  @media screen and (min-width: ${Breakpoints.IPad}) {
    ul::-webkit-scrollbar {
      display:none;
    }
    
    height: ${({ slideOutMenuHeight, showFixedSlide }) =>
      showFixedSlide ? `100vh` : `${slideOutMenuHeight}px`};


    top: ${({ showFixedSlide, headerHeight, stickyHeight }) =>
      showFixedSlide
        ? `${stickyHeight + SEPARATION_FROM_BUTTON}px`
        : `${headerHeight}px`};
    overflow-y: auto;
    &.menu-open-mobile {
      width: ${({ isOpen }) => (isOpen ? `auto` : "0px")};
    }
  }

  @media screen  and (min-width: ${Breakpoints.IPad}) and (max-width: ${
  Breakpoints.ScreenWidth
}) {
    display: flex;
    top: ${({ showFixedSlide, headerHeight, stickyHeight }) =>
      showFixedSlide
        ? `${stickyHeight + SEPARATION_FROM_BUTTON_SMALL_DESKTOP}px`
        : `${headerHeight}px`};

  }

  @media screen and (max-width: ${Breakpoints.IPad}) {
    &.menu-open-mobile {
      width: 100%;

    }
`;

export const SlideOutNav = styled.ul`
  list-style: none;
  padding: 0;
  width: 100%;
  max-width: 250px;
  margin-left: 8px;
  margin-right: 8px;
  @media screen and (min-width: ${Breakpoints.IPad}) {
    min-width: 250px;
    transition: 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: ${Breakpoints.IPad}) and (max-height: 720px) {
    height: fit-content;
    padding-bottom: 100px;
  }
`;

export const SlideOutSubNav = styled.ul`
  list-style: none;
  padding: 0;
  min-width: 250px;
  transition: 0.5s ease-in-out;

  li {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    a {
      color: ${Colors["Dark Primary"]};
      text-decoration: none;
      text-transform: capitalize;
      width: 100%;
    }
  }
`;

export const MobileNavOptions = styled.ul`
  padding: 0;
  li {
    a {
      width: 100%;
    }
    &:first-child {
      font-weight: 600;
    }
  }
`;

export const BackArrowWrapper = styled.div`
  margin-left: 5px;
  width: 17px;
  opacity: 0;
  text-align: center;
  & svg {
    transform: rotate(90deg);
  }
  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    height: 10px;

    & svg {
      transform: rotate(180deg);
    }
  }
`;

export const SubNavItemButton = styled.button`
  border: none;
  background-color: transparent;
  text-align: end;
  width: 100%;

  & > a {
    width: 100%;
    & > span {
      width: 100%;
      display: flex;
      & > div {
        display: flex;
        align-self: center;
      }
    }
  }
`;

export const SubNavTitle = styled.h2`
  font-size: ${FontSizes.Base};
  margin: 0;
  text-transform: uppercase;
  width: 100%;
`;

export const SubNavLinkTitle = styled.h3`
  background: #f1f1f1;
  border: none;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  margin: 0;
  padding: 16px;
`;

export const SlideOutListItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 24px;

  background-color: ${({ hoverColor, isSelected }) =>
    isSelected ? `${hoverColor}` : "transparent"};

  &.toggle {
    background-color: ${({ hoverColor }) => `${hoverColor}`};
  }

  & > button {
    pointer-events: ${({ hoverEnabled }) => (hoverEnabled ? "auto" : "none")};
  }

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    margin-left: 10px;
  }

  @media screen and (min-width: ${Breakpoints.DesktopXL}) {
    margin-left: 20px;
  }

  @media screen and (min-width: ${Breakpoints.IPad}) {
    &:hover {
      background-color: ${({ hoverColor, hoverEnabled }) => hoverEnabled ? `${hoverColor}` : "transparent"};
      ${SlideOutSubNav} {
        display: block;
      }
    }
  }
`;

export const ItemLink = styled.a`
  color: ${Colors["Dark Primary"]};
  text-decoration: none;
  text-transform: uppercase;
  height: 100%;
  width: 100%;
`;

export const SlideOutItemTitle = styled.div`
  align-items: center;
  display: flex;
  margin: 0 10px;

  &:hover {
    background-color: ${({ hoverColor }) => `${hoverColor}`};
    border-radius: 24px;

    ${BackArrowWrapper} {
      opacity: 1;
    }
  }
`;

export const SlideOutSubNavWrapper = styled.div``;

export const FlyoutWrapper = styled.div``;

export const FlyoutImage = styled.img`
  width: 100%;
  max-width: 198px;
`;

export const ImageWrapper = styled.div`
  padding: 0 24px;
  margin-bottom: 24px;
`;

export const ItemsWrapper = styled.div``;

export const BlankSpace = styled.div`
  height: -webkit-fill-available;
`;
export const MobileMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  overflow-y: auto;
`;

export const SlideOutMenuTabsWrapper = styled.div`
  height: 64px;
  background-color: #f5f3ee;
  width: 100%;
  display: none;

  @media screen and (max-width: ${Breakpoints.IPad}) {
    position: absolute;
    display: flex;
    bottom: 0;
    top: ${({ menuTabsTop }) => `${menuTabsTop}px`};
  }
`;

export const SlideOutMenuTabsContainer = styled.div`
  padding: 0 16px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`;

export const Tab = styled.div`
  border-bottom: ${({ isActive }) =>
    isActive ? "2px solid #243953" : "1px solid #a7b0ba"};
  width: 100%;
  text-align: center;
  padding-bottom: 16px;
`;
