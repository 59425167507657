import styled from "styled-components";
import { Breakpoints } from "../../constants/constants";

export const BagPanelWrapper = styled.div`
  // 38x = padding top & bottom, 65px = header height, 40px from smb height
  /* height: calc(100vh - 38px - 65px - 40px); */
  /* padding: 22px 32px 16px; */
  padding: 22px 16px 16px;
  display: flex;
  flex-direction: column;
  height: calc(100dvh - 20px - 45px - 22px - 16px);
  width: calc(100vw - 32px);
  @media screen and (min-width: ${Breakpoints.SmallMobile}) {
    width: calc(480px - 32px);
    height: ${({ isInView, isDSO, isDOO }) => {
      const extraMargin = isDSO || isDOO ? "124px" : "84px";
      return isInView
        ? `calc(100vh - 45px - 22px - ${extraMargin})`
        : `calc(100vh - 45px - 22px - 16px - 10px)`;
    }};
  }

  @media screen and (min-width: ${Breakpoints.IPad}) {
    height: ${({ isInView, isDSO, isDOO }) => {
      const extraMargin = isDSO || isDOO ? "42px" : "0px";
      return isInView
        ? `calc(100vh - 38px - 56px - 40px - ${extraMargin})`
        : `calc(100vh - 38px - 56px)`;
    }};
    padding: 22px 32px 16px;
    width: calc(480px - 64px);
  }

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    height: ${({ isInView, isDSO, isDOO }) => {
      const extraMargin = isDSO || isDOO ? "40px" : "0px";
      return isInView
        ? `calc(100vh - 38px - 65px - 40px - ${extraMargin})`
        : `calc(100vh - 38px - 65px)`;
    }};
  }
`;

export const BagPanelTitle = styled.div``;

export const EmptyBagText = styled.div`
  margin-top: 32px;
`;

export const BagPanelTileWrapper = styled.a`
  display: flex;
  text-decoration: none;
  margin-bottom: 16px;
`;

export const BagPanelImageContainer = styled.div`
  margin-right: 8px;
`;

export const DiscountMessageWrapper = styled.div`
  margin-top: 1em;
`;

export const BagPanelInfo = styled.div``;

export const WarningContainer = styled.div`
  margin-bottom: 30px;
`;

export const InventoryErrorMessage = styled.div`
  margin-top: 1em;
`;

export const GeneralWarningList = styled.ul`
  padding-left: 14px;
`;
