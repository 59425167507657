import React from "react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { ProductEDDWrapper } from "./ProductEDDStyled";
import useComponentTheme from "../Theme/useComponentTheme";
import defaultTheme from "./ProductEddTheme";
import Text from "../Text";
import Icons from "../Icons";
import ICON_TYPES from "../Icons/constants";

const slideInVariants = {
  hidden: { y: -2, opacity: 0, maxHeight: 0 },
  visible: { y: 0, opacity: 1, maxHeight: 1000 }
};

const defaultIconSizes = {
  width: 24,
  height: 24
};

const ProductEDD = ({
  eddData,
  showEdd,
  theme = defaultTheme,
  iconWidth = defaultIconSizes.width,
  iconHeight = defaultIconSizes.height,
  eddFontOverride
}) => {
  const iconType = ICON_TYPES["Edd"];
  const productEddTheme = useComponentTheme({ theme });

  if (!showEdd || !eddData) return null;

  return (
    <ProductEDDWrapper>
      <Icons type={iconType} width={iconWidth} height={iconHeight} />
      <Text
        themeName={productEddTheme?.eddText?.themeName}
        {...productEddTheme?.eddText?.overrides}
        copy={`${eddData?.prependMessage || ""} ${eddData?.eddDate || ""}`}
        {...eddFontOverride}
      />
    </ProductEDDWrapper>
  );
};

export default ProductEDD;

ProductEDD.propTypes = {
  eddData: PropTypes.object,
  showEdd: PropTypes.bool,
  theme: PropTypes.object,
  iconWidth: PropTypes.number,
  iconHeight: PropTypes.number,
  eddFontOverride: PropTypes.object
};
