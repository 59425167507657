import { Breakpoints } from "@serenaandlily/constants/constants";
import styled from "styled-components";

export const CUFilterDropdownsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CUFilterDropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  position: relative;
`;

export const CUFilterCheckboxWrapper = styled.div`
  cursor: pointer;
  div {
    margin: 0;
    cursor: pointer;
  }

  label {
    font-size: 12px;
    cursor: pointer;
  }

  input {
    cursor: pointer;
  }
`;

export const CUFilterDropdownTitle = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const CUFilterDropdownIcon = styled.div`
  svg {
    width: 6px;
    height: 11px;
  }
  margin-left: 10.5px;
`;

export const FilterDropdownContent = styled.div`
  padding: 24px;
  position: absolute;
  background: #fff;
  top: 100%;
  z-index: 1;
  box-shadow: 0px 170px 68px rgba(36, 57, 83, 0.01),
    0px 95px 57px rgba(36, 57, 83, 0.02), 0px 42px 42px rgba(36, 57, 83, 0.03),
    0px 11px 23px rgba(36, 57, 83, 0.04), 0px 0px 0px rgba(36, 57, 83, 0.04);
  border-radius: 8px;
`;

export const ColorDropdownContent = styled(FilterDropdownContent)`
  width: 310px;
`;

export const ColorFilterInputContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const ColorFilterInput = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  width: fit-content;
  cursor: pointer;
`;

export const ColorFilterCheckbox = styled.div`
  margin-right: 8px;
  padding: ${({ isSelected }) => (isSelected ? "3px" : "4px")};
  border: ${({ isSelected }) => (isSelected ? `1px solid #000` : "none")};
  @media (pointer: fine) {
    :hover {
      border: 1px solid #000;
      padding: 3px;
    }
  }
`;

export const ColorFilterCheckboxName = styled.div``;

export const FilterColor = styled.div`
  width: 40px;
  height: 40px;
  background: ${({ color }) => color};
  border: ${({ isWhite }) => (isWhite ? "1px solid #D7D7D7" : "none")};
  box-sizing: border-box;
`;
