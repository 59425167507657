import styled from "styled-components";
import {
  Breakpoints,
  Colors,
  HiddenLabelStyles
} from "../../constants/constants";
import zIndex from "@serenaandlily/constants/zIndex";

export const Container = styled.header`
  background-color: ${Colors["White"]};
  position: ${({ isOpen, isSticky }) =>
    isOpen ? "sticky" : isSticky ? "sticky" : ""};
  top: ${({ isSMBSticky }) => (isSMBSticky ? "72px" : "0")};
  z-index: ${({ isSticky }) => (isSticky ? zIndex.GLOBAL_HEADER_MOBILE : "0")};
  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    top: ${({ isSMBSticky }) => (isSMBSticky ? "40px" : "0")};
    z-index: ${({ isSticky }) => (isSticky ? zIndex.GLOBAL_HEADER : "0")};
  }
`;

export const StickyHeaderContainer = styled.div``;

export const GlobalHeaderContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  z-index: 3001;
  animation: ${({ animate }) => (animate ? "logoScale 3s ease-in-out" : "")};
  height: 45px;

  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    overflow: hidden;
  }

  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    height: 56px;
  }

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    height: 65px;
  }

  @keyframes logoScale {
    0% {
      transform: scale(100%, 400%);
      background-color: ${Colors["White"]};
      margin-top: 40px;
    }
    100% {
      transform: scale(100%);
    }
  }
`;

export const HeaderContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: 0 8px;
  width: 100%;
  height: 100%;

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    margin: 0 18px;
  }

  @media screen and (min-width: ${Breakpoints.DesktopXL}) {
    margin: 0 28px;
  }

  @media screen and (max-width: ${Breakpoints.IPad}) {
    margin: 0 13px 0 4px;
  }
`;

export const TabletMenuLeftContainer = styled.div`
  display: flex;
  align-items: center;
  height: 21px;
  cursor: pointer;
  padding: 9.5px;
  padding-left: 2px;
  padding-right: 2px;

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    ${({ useSlideoutOnDesktop }) =>
      !useSlideoutOnDesktop ? "display: none" : ""};
  }

  @media screen and (min-width: ${Breakpoints.SmallMobile}) {
    margin-right: 14px;
  }

  &:hover {
    background-color: ${({ hoverColor }) => `${hoverColor}`};
    border-radius: 30px;
  }
`;

export const LogoLink = styled.a`
  text-decoration: none;
  cursor: pointer;
`;

export const InspirationCTAWrapper = styled.div`
  animation: ${({ animate }) => (animate ? "iconWrapper 4s ease-in-out" : "")};
  z-index: 999;
  margin: 0 8px 0 0;

  @keyframes iconWrapper {
    0% {
      opacity: 0;

      transform: scale(100%, 300%);
      background-color: ${Colors["White"]};
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: scale(100%);
    }
  }
`;

export const LogoContainer = styled.div`
  @media screen and (min-width: ${Breakpoints.SmallMobile}) {
    top: 23px;
  }
  transform-origin: 0 0;
  max-height: 100%;
`;

export const LogoLabel = styled.label`
  ${HiddenLabelStyles}
`;

export const Logo = styled.div`
  animation: ${({ animate }) => (animate ? "letters 2s steps(60, end)" : "")};

  @keyframes letters {
    from {
      width: 0;
    }
  }
`;

export const LogoWrapper = styled.div`
  width: fit-content;
  animation: ${({ animate }) => (animate ? "3s popOutText ease-in-out" : "")};

  @keyframes popOutText {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    25% {
      transform: scale(2);
      opacity: 1;
    }
    50% {
      color: black;
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

export const MenuButton = styled.button`
  height: ${({ isOpen }) => (isOpen ? "inherit" : "17px")};
  background: none;
  padding: 0 8px 0 12px;
  border: none;
  cursor: pointer;
  ${({ isOpen }) => isOpen && `margin-bottom: 2px;`}

  @media screen and (max-width: ${Breakpoints.IPad}) {
    padding: 0 12px;
  }
`;

export const ShopLabel = styled.span`
  line-height: 15px;
  letter-spacing: 0.02em;
  text-align: left;
  margin: 0 14px 0 0;
  display: none;

  @media screen and (min-width: ${Breakpoints.SmallMobile}) {
    display: block;
  }
`;

export const MenuButtonLabel = styled.label`
  ${HiddenLabelStyles}
`;

export const MenuButtonContent = styled.div`
  position: relative;
  width: 17px;
  height: 100%;
  position: relative;
  transform: rotate(0deg);
  span {
    transition: 0.7s ease-in-out;
  }
`;

export const MenuButtonPart = styled.span`
  height: 1px;
  background: ${Colors["Dark Primary"]};
  display: block;
  position: absolute;
  transform: ${({ transform }) => `rotate(${transform}deg)`};
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  transform-origin: left center;
  width: ${({ isOpen }) => (isOpen ? 0 : `100%`)};
`;

export const StickySearchWrapper = styled(StickyHeaderContainer)`
  top: 66px;
  background: ${Colors["White"]};
  overflow: hidden;
  color: ${Colors.Black};
  line-height: 50px;
  transition: all 0.2s ease-in-out;
  z-index: 2000;
  margin: ${({ isLargeFlyout }) => (isLargeFlyout ? "30px" : "20px auto")};
  height: ${({ showSearchBar, isLargeFlyout }) =>
    showSearchBar ? (isLargeFlyout ? "69px" : "59px") : "0"};
  max-width: ${({ isLargeFlyout }) => (isLargeFlyout ? "" : "1024px")};

  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    top: 96px;
    height: ${({ showSearchBar, isLargeFlyout }) =>
      showSearchBar ? (isLargeFlyout ? "50px" : "39px") : "0"};
    margin: ${({ showSearchBar }) => (showSearchBar ? "15px auto" : "0")};
    padding: 0 15px;
  }
`;

export const FlyoutWrapper = styled.div`
  display: ${({ showSearchBar }) => (showSearchBar ? "" : "none")};
  position: sticky;
  margin: 0 auto;
  z-index: 3000;
  top: 51px;

  @media screen and (min-width: ${Breakpoints.SmallMobile}) {
    top: 135px;
  }

  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    top: 150px;
  }
`;

export const SearchBarClickWrapper = styled.div`
  z-index: 2000;
  background-color: ${Colors["White"]};
  height: 100px;
`;

export const FlyoutArrow = styled.div`
  position: absolute;
  width: 17.56px;
  height: 11px;
  left: 3.5px;
  top: 6.5px;
`;

export const LocationWrapper = styled.div`
  display: none;
  margin: 0 6px 0 0px;

  @media screen and (min-width: ${Breakpoints.IPad}) and (max-width: ${Breakpoints.LgTablet}) {
    display: block;
    margin-right: 25px;
  }
`;

export const FlyoutBackground = styled.div`
  height: 100vh;
  width: 100%;
  top: ${({ wrapperHeight }) => `${wrapperHeight}px`};
  background-color: ${Colors["Transparent Gray"]};
  position: fixed;
  z-index: 1000;
`;

export const BackgroundWrapper = styled.div`
  margin: 0 auto;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: ${Colors["White"]};
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-basis: 0;
`;

export const RightContent = styled.div`
  flex-grow: 1;
  flex-basis: 0;
`;
