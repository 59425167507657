import React from "react";
import PropTypes from "prop-types";
import { Container } from "./ContentWrapperStyled";
import ModularColumn from "../ModularColumn";

const ContentWrapper = ({
  content,
  marginTop,
  marginRight,
  marginLeft,
  marginBottom,
  contentItemIndex
}) => {
  return (
    <Container
      marginTop={marginTop}
      marginRight={marginRight}
      marginLeft={marginLeft}
      marginBottom={marginBottom}
    >
      <ModularColumn {...content} contentItemIndex={contentItemIndex} />
    </Container>
  );
};

export default ContentWrapper;

ContentWrapper.propTypes = {
  content: PropTypes.any,
  marginTop: PropTypes.number,
  marginRight: PropTypes.number,
  marginLeft: PropTypes.number,
  marginBottom: PropTypes.number,
  contentItemIndex: PropTypes.number
};
