export default function hexToRgba(hexCode, a = 0.2) {
  if (!hexCode) {
    return;
  }

  var hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  var r = parseInt(hex.substring(0, 2), 16),
    g = parseInt(hex.substring(2, 4), 16),
    b = parseInt(hex.substring(4, 6), 16);

  if (a > 1 && a <= 100) {
    a = a / 100;
  }

  return `rgba(${r}, ${g}, ${b}, ${a})`;
}
