import styled from "styled-components";

export const Container = styled.div``;

export const ContentBox = styled.div`
  width: 208px;
`;

export const BoxTitle = styled.div`
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-bottom: 20px;
`;

export const SectionTitle = styled.div`
  margin-bottom: 12px;
`;

export const MenuButton = styled.div`
  text-transform: uppercase;
  display: flex;
  align-items: center;

  & p {
    padding-right: 8px;
  }
`;

export const ModalWrapper = styled.div`
  padding: 20px 16px 20px 16px;
`;

export const ModalTitle = styled.div`
  padding-bottom: 30px;
`;
