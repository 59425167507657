import React from "react";
import PropTypes from "prop-types";
import { SectionTitle } from "./LeftNavigationSectionStyled";
import Text from "../Text";
import getContentFromSchema from "../../utils/getContentFromSchema/getContentFromSchema";
import getLeftNavigationContent from "./constants/AllowedLeftNavigationContent";

const LeftNavigationSection = ({ sectionTitle, contentItems, isDSO }) => {
  return (
    <React.Fragment>
      <SectionTitle>
        <Text {...sectionTitle?.text} />
      </SectionTitle>

      {contentItems?.map((item) => {
        if (item?.leftNavigationDsoOnly && !isDSO) return;
        const { _meta: meta } = item;
        const { schema } = meta;
        const contentType = getContentFromSchema(schema);

        const Component = getLeftNavigationContent(contentType);

        if (Component) return <Component {...item} />;
      })}
    </React.Fragment>
  );
};

export default LeftNavigationSection;

LeftNavigationSection.propTypes = {
  sectionTitle: PropTypes.string,
  contentItems: PropTypes.array
};
