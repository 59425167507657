const defaultTheme = {
  headlineTexts: {
    themeName: "headline3"
  },
  headlineRichText: {
    themeName: "text"
  },
  headlineCta: {
    themeName: "Primary Button"
  }
};

export default defaultTheme;
