import apolloClient from "../client";
import buildGetInventoriesQuery from "../../gql/queries/buildGetInventories";

const fetchInventoriesById = async (productId) => {
  if (!apolloClient) return;
  const GET_INVENTORIES = buildGetInventoriesQuery({ productId });
  if (!GET_INVENTORIES) return false;
  const { data } = await apolloClient.query({
    query: GET_INVENTORIES,
    variables: {
      productId
    },
    errorPolicy: "none",
    context: { clientName: "endpoint2" }
  });
  return data;
};

export default fetchInventoriesById;
