import React from "react";
import PropTypes from "prop-types";
import Carousel from "../Carousel";
import { ThumbSwipeContainer } from "./ModularColumnStyled";

const ThumbSwipe = ({ contentItems, thumbswipeHeight, progressBar }) => {
  return (
    <ThumbSwipeContainer>
      <Carousel
        slides={contentItems}
        carouselHeight={thumbswipeHeight}
        progressBar={progressBar}
      />
    </ThumbSwipeContainer>
  );
};

export default ThumbSwipe;

ThumbSwipe.propTypes = {
  contentItems: PropTypes.array,
  thumbswipeHeight: PropTypes.number,
  progressBar: PropTypes.object
};
