import { Spacing } from "@serenaandlily/constants/constants";
import styled from "styled-components";

export const ProductEDDWrapper = styled.div`
  margin-top: 0px;
  margin-bottom: ${Spacing.Base}px;
  min-height: 22px;
  display: flex;
  align-items: center;

  & label {
    margin-left: 8px;
  }
`;
