const defaultTheme = {
  themeName: "CTA Slideout",
  color: "rgb(56, 56, 56)",
  borderRadius: "24px",
  padding: "0 0 0 5px",
  tabs: {
    themeName: "text"
  }
};

export default defaultTheme;
