import React from "react";
import PropTypes from "prop-types";
import {
  IconLinksWrapper,
  LinksContainer,
  LinkContainer,
  Icon
} from "./IconLinksStyled";
import Text from "../Text";
import CTA from "../CTA";
import getAssetUrl from "../../utils/getAssetUrl";

const IconLinks = ({ heading, ctas, spacing, marginBottom }) => {
  const { horizontalSpacing, iconSpacing } = spacing || {};
  return (
    <IconLinksWrapper marginBottom={marginBottom}>
      {heading && <Text {...heading?.text} />}
      <LinksContainer>
        {ctas?.map((cta, idx) => {
          const imageSrc = getAssetUrl(cta?.image);
          const key = `IconLink-${idx}`;
          return (
            <LinkContainer
              orientation={cta?.orientation}
              horizontalSpacing={horizontalSpacing}
              key={key}
            >
              <CTA
                {...cta?.cta}
                orientation={cta?.orientation}
                icon={
                  <Icon
                    iconSpacing={iconSpacing}
                    orientation={cta?.orientation}
                    src={imageSrc}
                  />
                }
              />
            </LinkContainer>
          );
        })}
      </LinksContainer>
    </IconLinksWrapper>
  );
};

export default IconLinks;

IconLinks.propTypes = {
  heading: PropTypes.object,
  ctas: PropTypes.array,
  spacing: PropTypes.object,
  marginBottom: PropTypes.number
};
