import React from "react";
import PropTypes from "prop-types";
import { PDPSpecialMessageRoot } from "./PDPSpecialMessageStyled";
import Text from "../Text";
import useComponentTheme from "../Theme/useComponentTheme";
import defaultTheme from "./PDPSpecialMessageTheme";

const PDPSpecialMessage = ({ specialProductMessage, theme = defaultTheme }) => {
  const pdpSpecialMessageTheme = useComponentTheme({
    theme,
    themeOverrides: {
      fontSizeDesktop: theme.specialMessage.fontSizeDesktop,
      fontSize: theme.specialMessage.fontSize,
      fontSizeTablet: theme.specialMessage.fontSizeTablet
    }
  });

  return (
    <PDPSpecialMessageRoot>
      <Text
        copy={specialProductMessage}
        themeName={pdpSpecialMessageTheme?.specialMessage?.themeName}
        {...pdpSpecialMessageTheme?.specialMessage}
      />
    </PDPSpecialMessageRoot>
  );
};

export default PDPSpecialMessage;

PDPSpecialMessage.propTypes = {
  specialProductMessage: PropTypes.string,
  theme: PropTypes.object
};
