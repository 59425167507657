import React from "react";
import PropType from "prop-types";
import {
  BagPanelWrapper,
  BagPanelTitle,
  EmptyBagText,
  WarningContainer,
  GeneralWarningList
} from "./BagPanelStyled";
import Text from "../Text";
import Headline from "../Headline";
import { useContent } from "../../contexts/ContentContext";
import {
  AddedBagPanelBottomContent,
  SubtotalContainer,
  SubtotalLabel,
  Subtotal,
  AddedBagPanelCTAContainer,
  PanelContent
} from "../AddedBagPanel/AddedBagPanelStyled";
import CTA from "../CTA";
import { useCart, useCartUpdate } from "../../contexts/CartContext";
import formatAsCurrency from "../../utils/formatAsCurrency";
import BagPanelTile from "./BagPanelTile";
import useUser from "../../hooks/useUser";
import Typography from "../Typography";
import ClearCartPopup from "../ClearCartPopup";
import { useGlobalPopupUpdate } from "../GlobalPopup/GlobalPopupContext";
import {
  CART_WARNINGS,
  CART_WARNINGS_MESSAGES
} from "../../contexts/CartContext/constants";
import { MiniCartErrorMessage } from "../MiniCart/MiniCartStyled";
import AnalyticsData from "@serenaandlily/components/AnalyticsData";
import { useSidePanelContext } from "@serenaandlily/contexts/SidePanelContext";
import { useBreakpoint } from "../Breakpoints";
import SharedAddedToBagCTAs from "../SharedAddedToBagCTAs";

const BagPanel = ({ isInView, itemCount }) => {
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints["XSMobileMax"];
  const isEmpty = itemCount === 0;
  const {
    cartItems,
    priceSummary,
    warningsDictionary,
    hasRetailExclusiveItems,
    isPayAndCarryEligible,
    isPayAndCarry
  } = useCart();
  const { dsoShoppingBagPayAndCarryOptionsHeadline = {} } = useContent();

  const totalPrice = formatAsCurrency(priceSummary?.subtotal);
  const { user: { data: { isDSO, isDOO, storeId } = {} } = {} } = useUser();
  const {
    cartClearError,
    handleClearCart,
    emptyBagMessage,
    generalWarning,
    handleUpdateCart,
    takeActionWarning
  } = useCartUpdate();
  const { openPopup, closePopup } = useGlobalPopupUpdate();

  const { handleClosePanel } = useSidePanelContext();

  const onClearCartClick = (e) => {
    e.preventDefault();
    openPopup(
      <ClearCartPopup
        closePopup={closePopup}
        handleClearCart={handleClearCart}
      />,
      false
    );
  };
  const onPayAndCarryClick = (e) => {
    e.preventDefault();
    handleUpdateCart({ isPayAndCarry: true }, () => {
      window.location.href = "/checkout";
    });
  };
  const onCheckoutClick = () => {
    globalThis?.document?.body?.dispatchEvent(
      new CustomEvent("a-begin-checkout", {
        detail: { fromBag: true, cartValue: priceSummary?.totalPrice }
      })
    );
    if (isPayAndCarry) {
      handleUpdateCart({ isPayAndCarry: false });
    } else window.location.href = "/checkout";
  };
  return (
    <BagPanelWrapper isInView={isInView} isDSO={isDSO} isDOO={isDOO}>
      <AnalyticsData
        id="a-begin-checkout"
        data={{ fromBag: true, cartValue: priceSummary?.totalPrice }}
      />
      <BagPanelTitle>
        <Text
          copy="My Bag"
          themeName="headline3"
          marginBottom={takeActionWarning ? 15 : 34}
        />
      </BagPanelTitle>
      {isEmpty && emptyBagMessage && (
        <EmptyBagText>
          <Text {...emptyBagMessage} />
        </EmptyBagText>
      )}
      {!isEmpty && (
        <React.Fragment>
          <GeneralWarningList>
            {generalWarning.map((warning, idx) => (
              <li key={"bag-panel-" + idx}>
                <Typography themeName="textRedSmall">
                  {warning?.message}
                </Typography>
              </li>
            ))}
          </GeneralWarningList>
          {takeActionWarning &&
            warningsDictionary?.[CART_WARNINGS.CART_ERROR_INVENTORY] && (
              <WarningContainer>
                <Typography themeName="textRedSmall">
                  {CART_WARNINGS_MESSAGES.TAKE_ACTION_WARNING()}
                </Typography>
              </WarningContainer>
            )}
          {!isPayAndCarryEligible && hasRetailExclusiveItems && isDSO && (
            <WarningContainer>
              <GeneralWarningList>
                <Headline {...dsoShoppingBagPayAndCarryOptionsHeadline} />
              </GeneralWarningList>
            </WarningContainer>
          )}
          <PanelContent>
            {cartItems.map((cartItem) => {
              return (
                <BagPanelTile
                  key={cartItem.id}
                  item={cartItem}
                  isDSO={isDSO}
                  isDOO={isDOO}
                />
              );
            })}
          </PanelContent>
          <AddedBagPanelBottomContent>
            <SubtotalContainer>
              <SubtotalLabel>
                <Text copy="Subtotal" themeName="headline5" />
              </SubtotalLabel>
              <Subtotal>
                <Text copy={totalPrice} />
              </Subtotal>
            </SubtotalContainer>
            <AddedBagPanelCTAContainer
              alignment={"center"}
              fullWidth={!isDSO && isMobile}
            >
              <SharedAddedToBagCTAs
                isDSO={isDSO}
                isMobile={isMobile}
                handleClosePanel={handleClosePanel}
                onCheckoutClick={onCheckoutClick}
                onPayAndCarryClick={onPayAndCarryClick}
                hasRetailExclusiveItems={hasRetailExclusiveItems}
                isPayAndCarryEligible={isPayAndCarryEligible}
                storeId={storeId}
                takeActionWarning={takeActionWarning}
              />
            </AddedBagPanelCTAContainer>
            {isDSO && (
              <React.Fragment>
                <AddedBagPanelCTAContainer
                  alignment={"flex-start"}
                  hasMarginTop={true}
                >
                  <CTA
                    cta={{
                      ctaText: "CLEAR BAG",
                      onCtaClick: onClearCartClick
                    }}
                    themeName="Secondary Button"
                    elementName="cta-clear-cart"
                  />
                  {isPayAndCarryEligible &&
                    !hasRetailExclusiveItems &&
                    storeId !== "5" && (
                      <CTA
                        cta={{
                          ctaText: "IN-STORE PURCHASE",
                          onCtaClick: onPayAndCarryClick
                        }}
                        themeName="Primary Button"
                        buttonType="button"
                        disabled={takeActionWarning}
                        elementName="cta-pay-and-carry"
                      />
                    )}
                </AddedBagPanelCTAContainer>
                {cartClearError && (
                  <MiniCartErrorMessage>
                    <Typography themeName="errorSmall">
                      {cartClearError}
                    </Typography>
                  </MiniCartErrorMessage>
                )}
                {}
              </React.Fragment>
            )}
          </AddedBagPanelBottomContent>
        </React.Fragment>
      )}
    </BagPanelWrapper>
  );
};

export default BagPanel;

BagPanel.propTypes = {
  isInView: PropType.bool,
  itemCount: PropType.number
};
