import styled from "styled-components";
import { Colors } from "../../constants/constants";

export const ImageBorder = styled.div`
  text-align: left;
  box-sizing: border-box;
  display: block;
  width: 48px;
  height: 48px;
  cursor: pointer;
  padding: 4px;
  overflow: hidden;
  position: relative;
  border-color: ${({ isSelected }) =>
    isSelected ? `${Colors.Midnight}` : "transparent"};
  border-width: 1px;
  border-style: solid;
`;

export const ImageWrapper = styled.div`
  position: relative;
  ${({ isMultipleSelection, isSelected }) =>
    isMultipleSelection &&
    isSelected &&
    `
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 16px; /* Adjust the size as per your design */
    height: 16px; /* Adjust the size as per your design */
    background-color: ${Colors.Midnight};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px; /* Adjust the size of the inner circle */
    height: 8px;
    background-color: ${Colors.White};
    border-radius: 50%;
    z-index: 1;
  }
`}
`;

export const Image = styled.img`
  aspect-ratio: 1 / 1;
  width: 100%;
  box-sizing: border-box;
`;
