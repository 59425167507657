import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Container } from "./ImageZoomStyled";
import getPlaceholderUrl from "../../utils/getPlaceholderUrl";
import Image from "next/image";
import { useRouter } from "next/router";
import StlCta from "../StlCta";
import getShouldShowStlLink from "@serenaandlily/utils/shopthelook";

const placeholderWidth = 600;
const placeholderHeight = 750;

const ImageZoom = ({
  imageUrl,
  imageWidth,
  altText,
  inView,
  isScene7Url,
  stlLink,
  isQuickview
}) => {
  const router = useRouter();

  const isShopTheLook = useMemo(() => getShouldShowStlLink(stlLink), [stlLink]);

  const PLACEHOLDER_SRC = getPlaceholderUrl(
    placeholderWidth,
    placeholderHeight
  );
  const [zoomPosition, setImagePosition] = useState("0% 0%");
  const [imagePointerEvents, setImagePointerEvents] = useState("none");
  const [scrollTop, setScrollTop] = useState(0);

  let zoomImageUrl = null;
  let formattedImageUrl = null;

  if (imageUrl) {
    zoomImageUrl = isScene7Url
      ? `${imageUrl}&wid=1600&hei=2000`
      : `${imageUrl}?fmt=auto&qlt=default&fmt.jp2.qlt=60&fmt.webp.qlt=70&img404=noimagemedium&unsharp=0,0.3,10,0&w=1600&h=2000`;
    formattedImageUrl = isScene7Url
      ? `${imageUrl}?wid=${placeholderWidth}&hei=${placeholderHeight}`
      : `${imageUrl}?fmt=auto&qlt=default&fmt.jp2.qlt=60&fmt.webp.qlt=70&w=${placeholderWidth}&h=${placeholderHeight}&img404=noimagemedium&unsharp=0,0.3,10,0`;
  }

  const handleScroll = () => {
    const scrollSize =
      typeof window !== "undefined" && window.pageYOffset !== undefined
        ? window.pageYOffset
        : (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollTop;
    setScrollTop(scrollSize);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const resetPointerEvents = () => {
      setImagePointerEvents("none");
    };
    router?.events?.on?.("routeChangeComplete", resetPointerEvents);
    return () => {
      router?.events?.off?.("routeChangeComplete", resetPointerEvents);
    };
  }, [router?.events, setImagePointerEvents]);

  const fullPercentage = 100;

  const ZoomIntoImage = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * fullPercentage;
    const y = ((e.pageY - scrollTop - top) / height) * fullPercentage;
    const position = `${x}% ${y}%`;
    setImagePosition(position);
    setImagePointerEvents("auto");
  };

  if (isQuickview) {
    return (
      <Image
        src={inView ? formattedImageUrl : PLACEHOLDER_SRC}
        alt={altText}
        width={placeholderWidth}
        height={placeholderHeight}
        blurDataURL={getPlaceholderUrl(placeholderWidth, placeholderHeight)}
        placeholder="blur"
        layout={"fixed"}
        unoptimized={true}
      />
    );
  }

  return (
    <Container
      imageSrc={zoomImageUrl}
      onMouseMove={(e) => (!isShopTheLook ? ZoomIntoImage(e) : null)}
      style={{ backgroundPosition: zoomPosition }}
      imageWidth={imageWidth}
      className={"PDPImageContainer"}
    >
      <Image
        src={zoomImageUrl}
        alt={altText}
        style={{ pointerEvents: imagePointerEvents }}
        width={placeholderWidth}
        height={placeholderHeight}
        blurDataURL={getPlaceholderUrl(placeholderWidth, placeholderHeight)}
        placeholder="blur"
        layout={"fixed"}
        unoptimized={true}
      />
      {isShopTheLook && stlLink && <StlCta stlLink={stlLink} />}
    </Container>
  );
};

export default ImageZoom;

ImageZoom.propTypes = {
  imageUrl: PropTypes.string,
  imageWidth: PropTypes.string,
  altText: PropTypes.string,
  isScene7Url: PropTypes.bool,
  inView: PropTypes.bool,
  isQuickview: PropTypes.bool,
  theme: PropTypes.object,
  stlLink: PropTypes.string
};
