import React from "react";
import PropTypes from "prop-types";
import { MobileSlideOutCtasWrapper } from "./MobileSlideOutCTAsStyled";
import CTA from "../CTA";
import defaultTheme from "./MobileSlideOutCTAsTheme";
import { useAccount } from "../../contexts/AccountContext";
import useComponentTheme from "../Theme/useComponentTheme";
import { useContent } from "@serenaandlily/contexts/ContentContext";
import { useWishlistEnabled } from "@serenaandlily/hooks/useWishlistEnabled";

const MobileSlideOutCTAs = ({
  mobileCtas,
  backgroundColor,
  loggedIn,
  logoutCta,
  showFixedSlide
}) => {
  const theme = defaultTheme;
  const { logoutCustomer } = useAccount();
  const slideOutMenuTheme = useComponentTheme({ theme });

  const { wishlistConfigs } = useContent();
  const { isWishlistEnabled } = useWishlistEnabled(wishlistConfigs);
  return (
    <MobileSlideOutCtasWrapper
      backgroundColor={backgroundColor}
      showFixedSlide={showFixedSlide}
    >
      {mobileCtas &&
        mobileCtas.map((cta, idx) => {
          if (
            (loggedIn && cta.hideWhenLoggedIn) ||
            (cta.isWishlist && !isWishlistEnabled)
          )
            return;

          const key = `mobileSlideOutCta-${idx}`;
          return (
            <CTA
              key={key}
              cta={cta}
              themeName={slideOutMenuTheme?.slideOutCtas?.themeName}
              ctaIconPlacement="left"
            />
          );
        })}
      {loggedIn && (
        <CTA
          cta={{ ...logoutCta, onCtaClick: () => logoutCustomer() }}
          themeName={slideOutMenuTheme?.slideOutCtas?.themeName}
          ctaIconPlacement="left"
          useButtonTag={true}
        />
      )}
    </MobileSlideOutCtasWrapper>
  );
};

export default MobileSlideOutCTAs;

MobileSlideOutCTAs.propTypes = {
  mobileCtas: PropTypes.array,
  backgroundColor: PropTypes.string,
  loggedIn: PropTypes.bool,
  logoutCta: PropTypes.object,
  showFixedSlide: PropTypes.bool
};
