import styled from "styled-components";
import { Colors } from "../../constants/constants";

export const MiniCartWrapper = styled.div`
  > a {
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin-top: 12px;
  }
`;

export const MiniCartProducts = styled.div`
  max-height: 250px;
  overflow: auto;
`;

export const MiniCartHeader = styled.div`
  border-bottom: 1px solid ${Colors["Light Primary"]};
  padding-bottom: 6px;
  font-size: 20px;
`;

export const MiniCartItem = styled.a`
  display: flex;
  padding: 10px 0 18px;
  border-bottom: 1px solid ${Colors["Light Primary"]};
  cursor: pointer;
  text-decoration: none;
  color: unset;
`;

export const MiniCartItemImgContainer = styled.div`
  align-self: baseline;
  display: flex;
  width: 60px;

  > img {
    width: 100%;
  }
`;

export const MiniCartItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;

  > p {
    margin: 0;
  }
`;

export const MiniCartItemQty = styled.div`
  display: flex;

  > span:first-child {
    margin-right: 2px;
    font-weight: 600;
  }

  > span {
    margin: 0;
    text-transform: capitalize;
    font-style: normal;
    font-size: 12px;
    letter-spacing: 0.025em;
  }
`;

export const MiniCartItemVariations = styled.div`
  align-items: center;
  display: flex;

  > span:first-child {
    margin-right: 2px;
    text-transform: capitalize;
  }

  > p {
    margin: 0;
    text-transform: capitalize;
  }
`;

export const VariationWrapper = styled.span`
  font-weight: 600;
`;

export const MiniCartItemName = styled.div`
  font-weight: 600;
  font-style: normal;
  font-size: 12px;
  line-height: 19px;
  margin-bottom: 3px;
`;

export const MiniCartItemPrice = styled.div`
  padding: 6px 0 0;
  margin-top: 3px;
  ${({ hasDesignerPrice }) => hasDesignerPrice && "padding: 0;"}

  > span {
    font-family: proxima-nova, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 11px;
    ${({ hasDesignerPrice }) =>
      hasDesignerPrice && `color: ${Colors["Dark Sky"]};`}
  }
`;

export const MiniCartSubTotal = styled.div`
  text-align: left;
  text-transform: uppercase;
  border-bottom: 1px solid ${Colors["Light Primary"]};
  padding-bottom: 10px;
  margin-top: 12px;
  margin-bottom: 24px;
  display: flex;

  > p:nth-child(2) {
    font-weight: 600;
    margin-left: 13px;
  }
`;

export const MiniCartErrorMessage = styled.div`
  margin: 16px 0;
`;
