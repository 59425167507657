import { mobileMargin, desktopMargin } from "../constants/constants";

export const getDesktopMarginValue = (sizeDesktop) => {
  switch (sizeDesktop) {
    case "Full Width with Margin":
      return desktopMargin.fullWidthwithMargin;
    case "Wide Margin":
      return desktopMargin.wideMargin;
    default:
      return "none";
  }
};

export const getMobileMarginValue = (sizeMobile) => {
  switch (sizeMobile) {
    case "Full Width with Margin":
      return mobileMargin.fullWidthwithMargin;
    case "Wide Margin":
      return mobileMargin.wideMargin;
    default:
      return "none";
  }
};
