import React from "react";
import PropTypes from "prop-types";
import LocationCTA from "../../GlobalHeader/LocationCTA";
import ProfileLogo from "./ProfileLogo";
import UtilityDropdown from "./UtilityDropdown";
import { LocationContainer, WishlistContainer } from "./UtilityMenuStyled";
import ProfileItems from "./ProfileItems";
import Bag from "../../Bag";
import WishlistCTA from "@serenaandlily/components/GlobalHeader/WishlistCTA/WishlistCTA";

export const LocationIcon = () => (
  <LocationContainer hide={true}>
    <LocationCTA />
  </LocationContainer>
);

export const WishlistIcon = ({ user }) => (
  <WishlistContainer>
    <WishlistCTA {...{ user }} />
  </WishlistContainer>
);

export const ProfileIcon = () => (
  <UtilityDropdown
    ariaLabel="show menu for profile"
    logo={<ProfileLogo />}
    position="center"
    dropdownClass="profile"
  >
    <ProfileItems />
  </UtilityDropdown>
);

export const BagIcon = ({ itemCount }) => {
  return (
    <React.Fragment>
      <Bag itemCount={itemCount} />
    </React.Fragment>
  );
};

BagIcon.propTypes = {
  itemCount: PropTypes.number
};
