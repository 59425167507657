import PropTypes from "prop-types";
import { DiscountContainer } from "./ProductTileStyled";
import Text from "../Text";

const ProductTileDiscounts = ({
  discountFinalText,
  currentDiscount,
  priceConfigs,
  colorDiscountText,
  theme,
  largeFlyout,
  colorDiscountValue
}) => {
  return (
    <DiscountContainer>
      <Text
        copy={`(${discountFinalText}`}
        {...theme?.priceRangeText}
        {...priceConfigs}
        fontColor={colorDiscountText}
        largeFlyout={largeFlyout}
      />
      <Text
        copy={`${currentDiscount?.value}%`}
        {...theme?.priceRangeText}
        {...priceConfigs}
        fontColor={colorDiscountValue}
        largeFlyout={largeFlyout}
      />
      <Text
        copy={`)`}
        {...theme?.priceRangeText}
        {...priceConfigs}
        fontColor={colorDiscountText}
        largeFlyout={largeFlyout}
      />
    </DiscountContainer>
  );
};
ProductTileDiscounts.propTypes = {
  noMarginLeft: PropTypes.bool,
  discountFinalText: PropTypes.string,
  currentDiscount: PropTypes.object,
  priceConfigs: PropTypes.object,
  colorDiscountText: PropTypes.string,
  theme: PropTypes.object,
  largeFlyout: PropTypes.bool,
  colorDiscountValue: PropTypes.string
};

export default ProductTileDiscounts;
