// COMPONENT IMPORTS //
import Text from "../../../Text";

// STYLED IMPORTS //
import { FlexWrapper } from "../../../../../components/LayoutUtils/LayoutUtilsStyled";

// THEME IMPORTS //
import { contentTitle, contentText } from "../../typography/themes";

const FabricContent = ({ fabricContent = [] }) => {
  // Variables //
  const hasFabricContent = fabricContent?.length > 0;
  const fabricConentList = fabricContent[0];

  if (!hasFabricContent) {
    return null;
  }

  return (
    <FlexWrapper flexDirection="column" gap="4px">
      <Text copy="Fabric Content" theme={contentTitle} />
      <Text copy={fabricConentList} theme={contentText} />
    </FlexWrapper>
  );
};

export default FabricContent;
