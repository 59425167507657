import { gql } from "@apollo/client";

const buildGetInventoriesQuery = (variables) => {
  const slugDef = variables?.slug ? "$slug: String," : "";
  const slug = variables?.slug ? "slug: $slug," : "";
  const pidDef = variables?.productId ? "$productId: String," : "";
  const pid = variables?.productId ? "id: $productId," : "";
  const skuDef = variables?.sku ? "$sku: String," : "";
  const sku = variables?.sku ? "sku: $sku," : "";

  if (!slugDef && !pidDef && !skuDef) return false;
  return gql`
    query GetInventories(${skuDef} ${slugDef} ${pidDef}) {
      getInventories(${sku} ${slug} ${pid}) {
        productId
        total
        available
        inStock
        perpetual
      }
    }
  `;
};

export default buildGetInventoriesQuery;
