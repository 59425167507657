import { useEffect, useState } from "react";

const usePageTotalHeight = () => {
  const [totalHeight, setTotalHeight] = useState(0);
  useEffect(() => {
    const { document } = window;
    const pageHeight = document.body.offsetHeight;
    setTotalHeight(pageHeight);
  });
  return totalHeight;
};

export default usePageTotalHeight;
