import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  ScrollerProgressContainer,
  ScrollerProgressBar
} from "./ScrollerProgressStyled";
import { motion, useMotionValue } from "framer-motion";
import defaultTheme from "./defaultTheme";
import { Breakpoints } from "@serenaandlily/constants/constants";

const scrollBarIndicatorWidth = {
  tablet: 255,
  mobile: 115
};

const calculateDynamicBarWidth = () => {
  if (window.innerWidth <= parseFloat(Breakpoints.XSMobileSmallMax)) {
    return scrollBarIndicatorWidth.mobile;
  } else if (window.innerWidth >= parseFloat(Breakpoints.SMTabletMin)) {
    return scrollBarIndicatorWidth.tablet;
  } else {
    const minBreakpoint = parseFloat(Breakpoints.XSMobileMidMax);
    const maxBreakpoint = parseFloat(Breakpoints.XSMobileMax);

    const ratio =
      (window.innerWidth - minBreakpoint) / (maxBreakpoint - minBreakpoint);

    return (
      scrollBarIndicatorWidth.mobile +
      (scrollBarIndicatorWidth.tablet - scrollBarIndicatorWidth.mobile) * ratio
    );
  }
};

const ScrollerProgress = ({
  carouselProgress,
  emblaObject,
  scrollerProgressTheme,
  stopSlide,
  startSlide
}) => {
  const theme = scrollerProgressTheme || defaultTheme || {};
  const containerRef = useRef(null);
  const [scrollContainerWidth, setScrollContainerWidth] = useState(0);
  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        if (entries[0]) {
          const { width } = entries[0].contentRect;
          setScrollContainerWidth(width);
        }
      });
      resizeObserver.observe(containerRef.current);

      return () => {
        if (containerRef.current) {
          resizeObserver.unobserve(containerRef.current);
        }
      };
    }
  }, []);

  const scrollBarWidth = calculateDynamicBarWidth();

  const scrollValue = useMotionValue(0);

  const updateScrollValue = useCallback(() => {
    if (scrollContainerWidth === 0) return;

    let progress = 0;
    if (emblaObject) {
      progress = emblaObject.scrollProgress();
    } else if (carouselProgress !== undefined) {
      progress = carouselProgress;
    }

    const maxX = scrollContainerWidth - scrollBarWidth;
    const x = progress * maxX;
    scrollValue.set(x);
  }, [
    emblaObject,
    scrollValue,
    scrollContainerWidth,
    scrollBarWidth,
    carouselProgress
  ]);

  useEffect(() => {
    const animate = () => {
      updateScrollValue();
      requestAnimationFrame(animate);
    };
    animate();
  }, [updateScrollValue]);

  return (
    <ScrollerProgressContainer
      ref={containerRef}
      height={theme?.scrollBarHeight}
      bgColor={theme?.containerColor}
    >
      <ScrollerProgressBar
        as={motion.div}
        style={{
          x: scrollValue
        }}
        scrollBarWidth={scrollBarWidth}
        height={theme?.scrollBarHeight}
        bgColor={theme?.scrollBarColor}
        onMouseEnter={() => stopSlide && stopSlide()}
        onMouseLeave={() => startSlide && startSlide()}
      />
    </ScrollerProgressContainer>
  );
};

ScrollerProgress.propTypes = {
  carouselProgress: PropTypes.number,
  emblaObject: PropTypes.object,
  scrollerProgressTheme: PropTypes.object,
  stopSlide: PropTypes.func,
  startSlide: PropTypes.func
};

export default ScrollerProgress;
