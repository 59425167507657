import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import {
  TopNavInner,
  TopNavMenu,
  TopNavItem,
  TopNavContainer,
  TopNavItemContainer,
  ArrowButton
} from "./TopNavStyled";
import ArrowIcon from "./icons/ArrowIcon";
import SubNav from "../SubNav";
import defaultTheme from "./TopNavTheme";
import useComponentTheme from "../Theme/useComponentTheme";
import CTA from "../CTA";

const TopNav = ({ topNavContent, theme = defaultTheme }) => {
  const [rotate, setRotate] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [subNavItem, setSubNavItem] = useState("");
  const [previousElement, setPreviousElement] = useState(null);
  const [selected, setSelected] = useState(null);
  const TopNavTheme = useComponentTheme({ theme });

  const handleHover = useCallback(
    (item, id) => {
      const newPreviousElement = document.activeElement;
      setPreviousElement(newPreviousElement);
      setIsVisible(true);
      setSubNavItem(item);
      setRotate(!rotate);

      setSelected(id);

      if (rotate === false) {
        setIsVisible(false);
      }
    },
    [rotate]
  );

  const SubNavContainer = () => {
    return subNavItem ? (
      <SubNav
        {...subNavItem}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        rotate={rotate}
        setRotate={setRotate}
        previousElement={previousElement}
      />
    ) : (
      <div></div>
    );
  };

  return (
    <TopNavContainer>
      <TopNavInner onMouseLeave={() => setIsVisible(false)}>
        <TopNavMenu>
          {topNavContent?.map((item, idx) => {
            const arrowClass =
              idx === selected && isVisible === true ? "subnav-active" : "";
            return (
              <TopNavItem key={idx} onMouseOver={() => handleHover(item)}>
                <TopNavItemContainer>
                  <CTA
                    cta={{
                      ctaText: item.SectionTitle.ctaText,
                      url: item.SectionTitle.url
                    }}
                    themeName={TopNavTheme?.itemTitle?.themeName}
                  />
                  <ArrowButton
                    arrowClass={arrowClass}
                    aria-expanded={isVisible ? true : false}
                    aria-label={`show menu for ${item.SectionTitle.ctaText} category`}
                    rotate={rotate ? 1 : 0}
                    type="button"
                    onClick={() => handleHover(item, idx)}
                  >
                    <ArrowIcon />
                  </ArrowButton>
                </TopNavItemContainer>
              </TopNavItem>
            );
          })}
        </TopNavMenu>
        {isVisible && <SubNavContainer />}
      </TopNavInner>
    </TopNavContainer>
  );
};

export default TopNav;

TopNav.propTypes = {
  topNavContent: PropTypes.array,
  theme: PropTypes.object
};
