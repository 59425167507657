import React from "react";
import PropTypes from "prop-types";
import { BagWrapper } from "./BagStyled";
import { useSidePanelContext } from "../../contexts/SidePanelContext";
import BagPanel from "../BagPanel";
import BagLogo from "../GlobalHeaderIcons/UtilityMenu/BagLogo";
import { useCartUpdate } from "../../contexts/CartContext";
import { useBreakpoint } from "../Breakpoints";
import { useSearch } from "../../contexts/SearchContext";
import { useSlideOutMenuContext } from "../../contexts/SlideOutMenuContext";
import { GLOBAL_HEADER_ID } from "../GlobalHeader";

const Bag = ({ itemCount }) => {
  const {
    setContent,
    setCloseOnHover,
    handleOpenPanel,
    isOpen,
    handleClosePanel
  } = useSidePanelContext();
  const { showAddToBag } = useCartUpdate();
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints["SmallMobile"];
  const { showSearchBar, setShowSearchBar } = useSearch();
  const { isOpen : slideOutIsOpen , setIsOpen : setSlideOutIsOpen } = useSlideOutMenuContext();

  const handleOpenBag = () => {
    if (!isOpen) handleOpenPanel(800);
    const header = document.getElementById(GLOBAL_HEADER_ID);
    const headerValues = header.getBoundingClientRect();
    const isInView = headerValues.top !== 0;
    setContent(<BagPanel isInView={isInView} itemCount={itemCount} />);
    setTimeout(() => {
      setCloseOnHover(true);
    }, 1000);
  };

  const handleMouseEnter = () => {
    showSearchBar && setShowSearchBar(false);
    if (isMobile) return;
    return showAddToBag && handleOpenBag();
  };

  const handleMobileClick = () => {
    if (!isMobile || !showAddToBag) return;
    slideOutIsOpen && setSlideOutIsOpen(false);
    return isOpen ? handleClosePanel() : handleOpenBag();
  };

  return (
    <BagWrapper onMouseEnter={handleMouseEnter} onClick={handleMobileClick}>
      <BagLogo itemCount={itemCount} />
    </BagWrapper>
  );
};

export default Bag;

Bag.propTypes = {
  itemCount: PropTypes.number
};

