import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo
} from "react";
import PropTypes from "prop-types";
import { ImageContainer } from "./ImageZoomMobileStyled";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import getPlaceholderUrl from "../../utils/getPlaceholderUrl";
import Image from "next/image";
import StlCta from "../StlCta";
import getShouldShowStlLink from "@serenaandlily/utils/shopthelook";

const ImageZoomMobile = ({
  imageUrl,
  altText,
  selectedIndex,
  inView,
  isScene7Url,
  stlLink,
  isDesktopMobile,
  isQuickview
}) => {
  const isShopTheLook = useMemo(() => getShouldShowStlLink(stlLink), [stlLink]);

  const placeholderWidth = isDesktopMobile ? 360 : 400;
  const placeholderHeight = isDesktopMobile ? 450 : 503;
  const PLACEHOLDER_SRC = getPlaceholderUrl(
    placeholderWidth,
    placeholderHeight
  );

  const mobileImageUrl = isScene7Url
    ? `${imageUrl}&wid=1600&hei=2000`
    : `${imageUrl}?fmt=auto&qlt=default&fmt.jp2.qlt=60&fmt.webp.qlt=70&img404=noimagemedium&unsharp=0,0.3,10,0&w=900&h=1200`;

  const [transform, setTransform] = useState("");
  const [currentValues, setCurrentValues] = useState({ x: 0, y: 0, scale: 1 });
  const imageRef = useRef();
  const pinchZoomRef = useRef();

  useEffect(() => {
    pinchZoomRef.current?.scaleTo({ x: 0, y: 0, scale: 1 });
  }, [selectedIndex]);

  const onDoubleTap = () => {
    if (currentValues.scale > 1) {
      pinchZoomRef.current?.scaleTo({ x: 0, y: 0, scale: 1 });
    }
  };

  const onUpdate = useCallback(({ x, y, scale }) => {
    if (isQuickview) return;
    const { current: img } = imageRef;
    setCurrentValues({ x, y, scale });

    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      setTransform(value);
    }
  }, []);

  return (
    <ImageContainer height={placeholderHeight}>
      <QuickPinchZoom
        onUpdate={onUpdate}
        inertia={false}
        maxZoom={2}
        minZoom={0.99}
        tapZoomFactor={1}
        zoomOutFactor={1.1}
        draggableUnZoomed={false}
        onDoubleTap={onDoubleTap}
        ref={pinchZoomRef}
        width={placeholderWidth}
        height={placeholderHeight}
      >
        <>
          <Image
            src={inView ? mobileImageUrl : PLACEHOLDER_SRC}
            alt={altText}
            width={placeholderWidth}
            height={placeholderHeight}
            blurDataURL={getPlaceholderUrl(placeholderWidth, placeholderHeight)}
            placeholder="blur"
            layout={"fixed"}
            unoptimized={true}
            ref={imageRef}
            style={{
              transform: transform,
              height: `${placeholderHeight}px`
            }}
          ></Image>
          {isShopTheLook && stlLink && <StlCta stlLink={stlLink} />}
        </>
      </QuickPinchZoom>
    </ImageContainer>
  );
};

export default ImageZoomMobile;

ImageZoomMobile.propTypes = {
  imageUrl: PropTypes.string,
  altText: PropTypes.string,
  selectedIndex: PropTypes.number,
  inView: PropTypes.bool,
  stlLink: PropTypes.bool,
  isDesktopMobile: PropTypes.bool,
  isQuickview: PropTypes.bool
};
