import styled from "styled-components";
import { Typography, Colors, Breakpoints } from "../../constants/constants";

export const TopNavContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid ${Colors["Light Primary"]};
  padding-bottom: 1px;
  height: 39px;
`;

export const TopNavMenu = styled.ul`
  display: flex;
  list-style: none;
  justify-content: space-evenly;
  color: ${Colors["Dark Primary"]};
  font-family: ${Typography.ProximaNova};
  font-weight: 400;
  font-size: 12px;
  font-style: normal;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  padding-top: 13px;
`;

export const TopNavItemContainer = styled.div`
  display: flex;
  height: 27px;
  justify-content: center;
  position: relative;
`;

export const ArrowButton = styled.button`
  position: absolute;
  background-color: transparent;
  border: none;
  bottom: 0;
  width: 100%;
  opacity: ${({ arrowClass }) => (arrowClass ? "1" : "0")};

  &:focus {
    opacity: 1;
  }

  ${(props) => {
    switch (props.rotate) {
      case 0:
        return `
        transform: rotate(0deg);
            `;
      case 1:
        return `
        transform: rotate(180deg);
            `;
      default:
        return `
        transform: rotate(0deg);
            `;
    }
  }}
`;

export const TopNavItem = styled.li`
  a {
    color: ${Colors["Dark Primary"]};
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:hover {
      border-bottom: 2px solid ${Colors.Black};
    }

    &:focus ~ ${ArrowButton} {
      opacity: 1;
    }
  }
`;

export const TopNavInner = styled.nav`
  @media screen and (min-width: ${Breakpoints.DesktopLarge}) {
    max-width: 1440px;
    margin: 0 auto;
  }
`;
