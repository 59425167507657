import getSwatchUrl from "../getSwatchUrl";
import parseToUSD from "../parseToUSD";

const groupVariationsByColor = (
  product,
  masterId,
  isCustomUpholstery,
  variationId
) => {
  const productColor = product?.data?.facets?.find(
    (item) => item?.name?.toLowerCase() === "color"
  )?.values?.[0];
  if (
    !product.variations ||
    product.variations.length === 0 ||
    isCustomUpholstery
  ) {
    return [
      {
        id: product.data.variation_id,
        color: productColor,
        sku: `${masterId}x${productColor}`,
        imageUrl: product?.data?.image_url || "",
        masterImageUrl: product?.data?.masterImageUrl || null
      }
    ];
  }
  return Array.from(
    product.variations
      .reduce((r, o) => {
        const color =
          o?.data?.facets?.find((item) => item?.name?.toLowerCase() === "color")
            ?.values?.[0] || "";
        const existing = r.get(color);
        const variationData = {
          id: o.data.variation_id,
          color: color,
          sku: `${masterId}x${color}`,
          imageUrl: o.data.image_url || "",
          swatchUrl: getSwatchUrl(masterId, color)
        };
        // If the variationId is included in the colors and the existing entry has the same variationId
        if (color === productColor && existing?.id === variationId) {
          variationData.id = variationId;
        }
        return r.set(color, variationData);
      }, new Map())
      .values()
  );
};

const putIdFirst = (array, variationId) => {
  return array.sort((a, b) => {
    if (a?.id === variationId) {
      return -1;
    }
    if (b?.id === variationId) {
      return 1;
    }
    return 0;
  });
};

const putVariationIdFirst = (array, targetVariationId) => {
  return array.sort((a, b) => {
    if (a?.data?.variation_id === targetVariationId) {
      return -1;
    }
    if (b?.data?.variation_id === targetVariationId) {
      return 1;
    }
    return 0;
  });
};

const checkOnSale = (facets) => {
  const showWasNow =
    facets?.nowMaxPrice !== facets?.wasMaxPrice ||
    (!!facets?.salePrice && facets?.salePrice !== facets?.price);
  return showWasNow;
};

// eslint-disable-next-line max-lines-per-function
const searchToProductTile = ({
  results,
  showColorBuckets = true,
  tileConfigs,
  hideCta
}) => {
  const products = results;
  const selectedFacets = products?.facets
    ?.map((f) => ({
      name: f.display_name,
      options: (f.options || []).filter((o) => o.status === "selected")
    }))
    ?.filter((f) => f.options.length > 0)
    ?.flatMap((f) => f);

  if (!products?.results) return [];

  // eslint-disable-next-line max-lines-per-function, complexity
  const productsMapped = products.results.map((product) => {
    if (product?.variations_map) {
      product.variations = product?.variations_map?.map?.((variation) => {
        const { image_url, variation_id, color, size } = variation || {};
        return {
          data: {
            facets: [
              { name: "color", values: color },
              { name: "size", values: size }
            ],
            image_url,
            variation_id
          }
        };
      });
    }
    const sizeFacets = selectedFacets?.filter(
      (f) => f.name.toLowerCase() === "size"
    );
    if (sizeFacets?.length > 0 && product.variations?.length > 0) {
      const sizes = sizeFacets.flatMap((f) => f.options).map((o) => o.value);
      product.variations = (product?.variations || []).filter((pv) => {
        const sizeValues = (pv?.data?.facets || []).find(
          (f) => f.name.toLowerCase() === "size"
        ).values;
        return !!(sizeValues || []).find((s) => sizes.includes(s));
      });
    }

    const facets = product?.data?.facets?.reduce(
      (a, v) => ({ ...a, [v.name]: v.values[0] }),
      {}
    );

    const isCustomUpholstery =
      facets?.isCustomUpholstery === true ||
      facets?.isCustomUpholstery === "True";
    const tileData = putIdFirst(
      groupVariationsByColor(
        product,
        product?.data.id,
        isCustomUpholstery,
        product?.data?.variation_id
      ),
      product?.data?.variation_id
    );
    const td = tileData?.find((td) => td);
    td.imageUrl = product?.data?.image_url || td?.imageUrl;
    const variantAttributes = product?.data?.variantAttributes ?? "";
    const isColor = variantAttributes?.includes("color") || isCustomUpholstery;

    const hasWishlistStatus =
      product?.data?.wishlistHasDifferentVariation !== undefined ||
      product?.data?.wishlistHasProduct !== undefined;

    const items = {
      productDetails: {
        showColorBuckets:
          showColorBuckets &&
          !!product.variations &&
          product.variations.length > 1 &&
          tileData.length > 1 &&
          isColor
      },
      _meta: {
        schema:
          "https://raw.githubusercontent.com/newelevation/sl-ui-toolkit/main/packages/sl-ui/src/components/ProductTile/schema/ProductTile.json"
      },
      productSpecification: {
        product: {
          marketingName: product?.value || "",
          productCallout: product?.data?.newFlags || "",
          productId: product?.data?.id || "",
          variationSize: facets?.size,
          variationColor: facets?.color,
          variationId: product?.data?.variation_id || "",
          collection: facets?.masterCollection || "",
          category: facets?.subDepartment || "",
          department: facets?.department || "",
          edd: facets?.edd || "",
          fabricType: facets?.fabric_type || ""
        },
        ...(hasWishlistStatus && {
          wishlistStatus: {
            wishlistHasDifferentVariation:
              product?.data?.wishlistHasDifferentVariation,
            wishlistHasProduct: product?.data?.wishlistHasProduct
          }
        }),
        showRolloverImage: product.data.showRolloverImage,
        promoMessages: product.data.promoMessages,
        disableRollover: !product.data.showRolloverImage || !facets?.altImage,
        productName: product.value
      },
      variations: putVariationIdFirst(
        product?.variations || [],
        product?.data?.variation_id
      ),
      productTileData: {
        seoUrl: product?.data?.url,
        altImage: facets?.altImage,
        showWasNow: checkOnSale(facets),
        priceRange: {
          min: facets?.wasMinPrice
            ? parseToUSD.format(facets?.wasMinPrice)
            : facets?.price ?? parseToUSD.format(facets?.price),
          max:
            facets?.wasMaxPrice !== facets?.wasMinPrice && facets?.wasMaxPrice
              ? parseToUSD.format(facets?.wasMaxPrice)
              : null
        },
        salePriceRange: { min: parseToUSD.format(facets?.salePrice) },
        designerPriceRange: {
          min:
            facets?.designerMinPrice || facets?.designerMinPrice === 0
              ? parseToUSD.format(facets?.designerMinPrice)
              : parseToUSD.format(facets?.designerPrice),
          max:
            facets?.designerMaxPrice !== facets?.designerMinPrice ??
            facets?.designerMaxPrice
              ? parseToUSD.format(facets?.designerMaxPrice)
              : null
        },
        nowPriceRange: {
          min: facets?.nowMinPrice
            ? parseToUSD.format(facets?.nowMinPrice)
            : null,
          max:
            facets?.nowMaxPrice !== facets?.nowMinPrice && facets?.nowMaxPrice
              ? parseToUSD.format(facets?.nowMaxPrice)
              : null
        },
        wasPriceRange: {
          min: facets?.wasMinPrice
            ? parseToUSD.format(facets?.wasMinPrice)
            : null,
          max:
            facets?.wasMaxPrice !== facets?.wasMinPrice && facets?.wasMaxPrice
              ? parseToUSD.format(facets?.wasMaxPrice)
              : null
        },
        tileData,
        isCustomUpholstery
      },
      tileConfigs,
      hideCta
    };
    return items;
  });
  return productsMapped;
};

export default searchToProductTile;
