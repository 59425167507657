function getPossibleProductId(childProducts, currentVariations, isCuProduct) {
  const possibleProductId = [];
  if (!isCuProduct && childProducts?.length > 0) {
    for (const childProduct of childProducts) {
      const numToMatch = getNumToMatch(currentVariations, childProduct);
      if (numToMatch === 0) {
        possibleProductId.push(childProduct?.id);
      }
    }
  }
  return possibleProductId;
}

function getNumToMatch(currentVariations, childProduct) {
  let numToMatch = Object?.keys(currentVariations)?.length - 1;
  for (const variant of childProduct.variants) {
    for (const key in currentVariations) {
      if (key === "Color") continue;
      const name = key;
      const description = currentVariations[key]?.description;
      if (variant?.name === name && variant?.value === description) {
        numToMatch--;
      }
    }
  }
  return numToMatch;
}

export default getPossibleProductId;
