import { constructorHelpers } from "../../utils";
import { useState } from "react";
import { useWishlistUserCreds } from "@serenaandlily/hooks/useWishlistUserCreds";

export const useSearchAutocomplete = () => {
  const [searchAutocomplete, setSearchAutocomplete] = useState({});
  const [isLoadingAutocomplete, setIsLoadingAutocomplete] = useState(true);
  const { wishlistUserCreds } = useWishlistUserCreds();

  const fetchAutocompleteResults = async ({
    phrase,
    showRetailProducts = false
  }) => {
    setIsLoadingAutocomplete(true);
    const autocompleteResults =
      await constructorHelpers.getSearchRecommendations({
        phrase,
        showRetailProducts,
        ...wishlistUserCreds
      });
    setSearchAutocomplete(autocompleteResults);
    setIsLoadingAutocomplete(false);
  };

  return {
    searchAutocomplete,
    isLoadingAutocomplete,
    setSearchAutocomplete,
    setIsLoadingAutocomplete,
    fetchAutocompleteResults
  };
};
