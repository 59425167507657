import React, { useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { ResultsFlyoutContainer, PaddingWrapper } from "./ResultsFlyoutStyled";
import searchToProductTile from "../../utils/searchToProductTile/searchToProductTile";
import { useSearch } from "../../contexts/SearchContext";
import DefaultFlyout from "./DefaultFlyout/DefaultFlyout";
import SmallFlyout from "./SmallFlyout/SmallFlyout";
import LargeFlyout from "./LargeFlyout/LargeFlyout";
import useDeepCompareEffect from "use-deep-compare-effect";
import { pushToDataLayer } from "@serenaandlily/hooks/analytics/GA4Helpers";

const RTB_TOTAL = 5;
const RTB_EVENT_NAME = "RTB_Search_Result";

const ResultsFlyout = ({
  searchResults,
  popularQueries,
  searchDefault,
  showResults,
  flyoutType = "Small",
  getWrapperHeight,
  handleHeight
}) => {
  const { searchBarValue } = useSearch();
  const tiles = searchToProductTile({ results: { results: searchResults } });
  const wrapperRef = useRef(null);

  const handleResize = useCallback(() => {
    const height = wrapperRef?.current?.offsetHeight;
    getWrapperHeight?.(height);
    handleHeight?.();
  }, [handleHeight, getWrapperHeight]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useDeepCompareEffect(() => {
    if (tiles?.length > 0) {
      pushToDataLayer({
        event: RTB_EVENT_NAME,
        rtb_search_products: tiles
          ?.slice(0, RTB_TOTAL)
          .map(
            ({ productSpecification } = {}) =>
              productSpecification?.product?.productId
          )
          .filter((productId) => productId)
      });
    }
  }, [tiles]);

  if (showResults && flyoutType === "Small") {
    return (
      <SmallFlyout
        popularQueries={popularQueries}
        searchBarValue={searchBarValue}
        tiles={tiles}
      />
    );
  } else if (showResults && flyoutType === "Large") {
    return (
      <LargeFlyout
        popularQueries={popularQueries}
        searchBarValue={searchBarValue}
        tiles={tiles}
      />
    );
  } else if (searchDefault?.useSearchDefault) {
    return (
      <ResultsFlyoutContainer ref={wrapperRef}>
        <PaddingWrapper>
          <DefaultFlyout {...searchDefault} />
        </PaddingWrapper>
      </ResultsFlyoutContainer>
    );
  }

  return null;
};

export default ResultsFlyout;

ResultsFlyout.propTypes = {
  searchResults: PropTypes.array,
  popularQueries: PropTypes.array,
  searchDefault: PropTypes.object,
  showResults: PropTypes.bool,
  flyoutType: PropTypes.string,
  getWrapperHeight: PropTypes.func,
  handleHeight: PropTypes.func
};
