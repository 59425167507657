import React, { forwardRef, useCallback } from "react";
import PropTypes from "prop-types";
import {
  ErrorMessage,
  InfoMessage,
  Input,
  Label,
  TextInputWrapper,
  RequiredIndicator,
  EndIconWrapper
} from "./TextInputStyled";
import restrictCharacterInput from "../utils/restrictCharacterInput";
const TextInput = forwardRef(
  (
    {
      allowRestrictedCharacters = false,
      value,
      handleChange,
      handleFocus,
      handleBlur,
      maxLength,
      minLength,
      onError,
      onChange,
      name,
      id,
      label,
      placeholder,
      infoMessage,
      readOnly,
      validators,
      error,
      marginBottom,
      type = "text",
      valueMatch,
      requiredIndicator,
      endIcon,
      disabled = false,
      className,
      fullWidth = false,
      ...props
    },
    ref
  ) => {
    const validate = () => {
      if (handleBlur && !value) handleBlur();
      if (readOnly) return;
      if (!validators) {
        return true;
      }

      const errors = [];

      validators.forEach((validator) => {
        if (valueMatch) {
          if (validator(value, valueMatch))
            errors.push(validator(value, valueMatch));
        } else if (validator(value)) {
          errors.push(validator(value));
        }
      });

      if (!errors.length) {
        return;
      }

      onError(name, errors[0]);
    };

    const onChangeCallback = useCallback(
      (e) => {
        if (!allowRestrictedCharacters) restrictCharacterInput(e);
        if (typeof onChange === "function") {
          onChange(e);
        } else if (typeof handleChange === "function") {
          handleChange(e);
        }
      },
      [handleChange, onChange, allowRestrictedCharacters]
    );

    return (
      <TextInputWrapper marginBottom={marginBottom} fullWidth={fullWidth}>
        <Input
          type={type}
          hasError={error && !readOnly && !disabled}
          onBlur={validate}
          onFocus={handleFocus}
          disabled={disabled}
          value={value}
          onChange={onChangeCallback}
          maxLength={maxLength}
          minLength={minLength}
          name={name}
          id={id || name}
          placeholder={placeholder || label}
          readOnly={readOnly}
          ref={ref}
          className={className}
          showPlaceholder={placeholder && !label}
          {...props}
        />
        {endIcon && <EndIconWrapper>{endIcon}</EndIconWrapper>}
        {label && (
          <Label htmlFor={id || name}>
            {requiredIndicator && <RequiredIndicator />}
            {label && <span>{label}</span>}
          </Label>
        )}
        {error && !readOnly && !disabled && (
          <ErrorMessage className="errorTextInput">{error}</ErrorMessage>
        )}
        {infoMessage && <InfoMessage>{infoMessage}</InfoMessage>}
      </TextInputWrapper>
    );
  }
);

export default TextInput;

TextInput.propTypes = {
  allowRestrictedCharacters: PropTypes.bool,
  className: PropTypes.string,
  endIcon: PropTypes.node,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleFocus: PropTypes.func,
  id: PropTypes.string,
  infoMessage: PropTypes.string,
  label: PropTypes.string,
  marginBottom: PropTypes.number,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.string,
  requiredIndicator: PropTypes.bool,
  type: PropTypes.string,
  validators: PropTypes.arrayOf(PropTypes.func),
  value: PropTypes.string,
  valueMatch: PropTypes.string
};
TextInput.displayName = "TextInput";
