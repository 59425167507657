import React, { Suspense } from "react";
import getContentFromSchema from "../../utils/getContentFromSchema";
import getColumnContent from "./constants/AllowedColumnContent";
import {
  getColumnContentData,
  getColumnWrapper
} from "./utils/getColumnDetails";

const ModularColumnContent = (content) => {
  const { _meta: meta, contentItemIndex } = content;
  const { schema } = meta;
  const contentType = getContentFromSchema(schema);
  const Component = getColumnContent(contentType);
  const { amplienceData } = content?.src?.diImage?.image || {};
  const imageData = {
    width: amplienceData?.width,
    height: amplienceData?.height
  };
  const Container = getColumnWrapper(contentType);
  const columnContent = getColumnContentData(contentType, content, imageData);
  const componentContent =
    contentType === "Image"
      ? { ...content, ...imageData, contentItemIndex }
      : { ...content, contentItemIndex };
  if (Component)
    return (
      <Container {...columnContent}>
        <Suspense fallback={<div></div>}>
          <Component {...componentContent} />
        </Suspense>
      </Container>
    );

  return null;
};

export default ModularColumnContent;
