import styled from "styled-components";
import { motion } from "framer-motion";
import {
  Colors,
  Breakpoints,
  Shadows,
  Typography
} from "../../constants/constants";
import { ProductEDDWrapper } from "../ProductEDD/ProductEDDStyled";
import SnlImage from "../SnlImage";

export const DesktopContainer = styled(motion.div)`
  box-shadow: ${Shadows.FabricSlideIn};
  border-right: 1px solid ${Colors["Alternate Silver"]};
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 100%;
  background-color: ${Colors.White};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    width: 400px;
    height: 100%;
  }

  @media screen and (max-width: ${Breakpoints.IPad}) {
    z-index: 999999;
    width: 100%;
    max-height: 80%;
  }

  @media screen and (min-width: ${Breakpoints.IPad}) and (max-width: ${Breakpoints.ScreenWidth}) {
    width: 480px;
    z-index: 9999;
    height: 100%;
    max-height: 100%;
  }

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    z-index: unset;
    width: 402px;
  }

  @media screen and (min-width: ${Breakpoints.DesktopLarge}) {
    width: 402px;
  }
`;

export const DesktopContainerWrapper = styled.div`
  @media screen and (max-width: ${Breakpoints.IPad}) {
    max-height: calc(100% - 20px);
    height: calc(100% - 20px);
    margin-bottom: 20px;
    width: 100%;
    overflow: auto;
  }

  @media screen and (min-width: ${Breakpoints.IPad}) and (max-width: ${Breakpoints.ScreenWidth}) {
    max-height: calc(100% - 185px);
    height: calc(100% - 185px);
    overflow: hidden;
  }
  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    max-height: 100%;
    height: 100%;
    width: 100%;
    overflow: auto;
    margin: 24px 0 0;
  }
`;

export const DesktopImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media screen and (min-width: ${Breakpoints.IPad}) and (max-width: ${Breakpoints.ScreenWidth}) {
    max-height: 50%;
    height: 50%;
  }
  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    max-height: 100%;
    height: auto;
  }
`;

export const FabricContentContainer = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-self: flex-end;
  gap: 24px;

  width: 100%;
  max-width: 432px;
  height: fit-content;
  min-height: 190px;

  padding: 16px;

  ${ProductEDDWrapper} {
    margin-top: auto;
    margin-bottom: 8px;
  }
  @media screen and (min-width: ${Breakpoints.IPad}) and (max-width: ${Breakpoints.ScreenWidth}) {
    gap: 16px;

    max-height: 50%;
    max-width: 100%;

    margin-top: 10px;
    padding: 24px;

    overflow: auto;
  }
  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    max-width: 100%;

    padding: 24px;
  }

  @media screen and (max-width: ${Breakpoints.IPad}) {
    padding-bottom: 0;
  }
`;

export const FabricName = styled.div``;

export const FabricDescription = styled.div``;

export const LoadingWrapper = styled.div`
  height: "16px";
  padding-left: "2px";
  margin-bottom: "8px";
`;

export const CloseButton = styled.div`
  background-color: #fff;
  border: 1px solid #a7b0ba;
  border-radius: 100%;
  padding: 8px;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 24px;
  top: 31px;
  cursor: pointer;
  z-index: 9999;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    display: none;
  }
`;

export const RoundedFabricImage = styled(SnlImage)`
  color: transparent;
  position: absolute;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  border: 1px solid ${Colors["Midnight 40%"]};
  left: 16px;
  bottom: 0;
  width: 110px !important;
  height: 110px !important;
`;

export const SwipeUpContainer = styled.div`
  position: absolute;
  bottom: 0;
  padding: 13px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  @media screen and (min-width: ${Breakpoints.IPad}) {
    display: none;
  }
`;

export const SwipeUp = styled.div`
  width: 100px;
  height: 4px;
  background-color: ${Colors.Midnight};
`;

export const MobileImageContainer = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: center;
  padding: 0 32px;
  max-height: 100%;

  > div {
    width: fit-content;
  }

  img {
    width: auto;
  }
  @media screen and (max-width: ${Breakpoints.ScreenWidth}) {
    > div {
      height: 100%;
    }
  }
  @media screen and (max-width: ${Breakpoints.IPhoneSE}) {
    img {
      height: 250px !important;
    }
  }
  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    display: none;
  }
  @media screen and (max-width: ${Breakpoints.IPad}) {
    img {
      width: 100%;
      height: 300px;
      object-fit: contain;
    }
  }
  @media screen and (min-width: ${Breakpoints.IPad}) and (max-width: ${Breakpoints.ScreenWidth}) {
    img {
      height: auto;
      object-fit: contain;
      width: 100%;
    }
  }
`;

export const PriceAndApplyPreview = styled.div`
  margin-top: auto;
  box-shadow: 0 -1px 8px rgba(36, 57, 83, 0.3);

  @media screen and (max-width: ${Breakpoints.LgTablet}) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding: 18px 30px;
    box-sizing: border-box;
    box-shadow: 0 -1px 8px rgba(36, 57, 83, 0.3);
  }
  a {
    width: 100%;
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  @media screen and (min-width: ${Breakpoints.SmallMobile}) {
    margin-bottom: 14px;
  }
`;

export const PriceTitle = styled.div``;

export const Price = styled.div`
  h5 {
    font-family: ${Typography.BeatriceLight};
    font-weight: 300;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  > button {
    width: 100%;
  }
`;
