import apolloClient from "@serenaandlily/gql/client";
import buildGetSearchQuery from "../queries/buildGetSearchQuery";

const fetchSearchResult = async ({
  searchPhrase,
  maxPromoMessages,
  searchParameters,
  customerEmail,
  guestToken,
  userParameters
}) => {
  if (!apolloClient) return;
  try {
    const GET_SEARCH_RESULTS = buildGetSearchQuery();

    const response = await apolloClient.query({
      query: GET_SEARCH_RESULTS,
      variables: {
        searchPhrase,
        maxPromoMessages,
        searchParameters,
        ...(customerEmail && { customerEmail }),
        ...(guestToken && { guestToken }),
        userParameters
      },
      context: { clientName: "endpoint2" }
    });

    return response;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return { data: null, errors: error };
  }
};

export default fetchSearchResult;
