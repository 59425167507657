const groupFabricsBySubType = (fabrics, subTypeOrder) => {
  const grouped = [];

  for (const subType of subTypeOrder) {
    const fabricsOfSubType = fabrics.filter(
      (fabric) => fabric.subType?.toLowerCase() === subType
    );

    if (fabricsOfSubType.length > 0) {
      grouped.push({
        subType,
        fabrics: fabricsOfSubType
      });
    }
  }

  return grouped;
};

export default groupFabricsBySubType;
