const checkAddToCartEnabled = ({
  totalAvailable,
  currentCount,
  hasCustomUpholstery,
  listedPrice
}) => {
  const isEnabled =
    (currentCount <= totalAvailable && totalAvailable !== 0) ||
    hasCustomUpholstery;

  const hasPrice = !!listedPrice;

  return isEnabled && hasPrice;
};

export default checkAddToCartEnabled;
