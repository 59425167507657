import { gql } from "@apollo/client";

const buildGetCategoryQuery = () => {
  return gql`
    query SearchGetCategoryResults(
      $categorySlug: String!
      $maxPromoMessages: Int!
      $searchParameters: String
      $userParameters: String
      $customerEmail: String
      $guestToken: String
    ) {
      searchGetCategoryResults(
        categorySlug: $categorySlug
        maxPromoMessages: $maxPromoMessages
        searchParameters: $searchParameters
        userParameters: $userParameters
        customerEmail: $customerEmail
        guestToken: $guestToken
      ) {
        results
      }
    }
  `;
};

export default buildGetCategoryQuery;
