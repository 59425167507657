import React from "react";
import PropTypes from "prop-types";
import LinksSection from "../LinksSection";
import { LinksColumnWrapper } from "./LinksColumnStyled";

const LinksColumn = ({ linksSections }) => {
  return (
    <LinksColumnWrapper>
      {linksSections?.map((linksSection, idx) => {
        const key = `${linksSection?.linksSectionTitle}-${idx}`;
        return <LinksSection key={key} {...linksSection} />;
      })}
    </LinksColumnWrapper>
  );
};

export default LinksColumn;

LinksColumn.propTypes = {
  linksSections: PropTypes.array
};
