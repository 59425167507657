import React from "react";
import PropTypes from "prop-types";
import Text from "../Text";
import { MenuItem, MenuItemWrapper } from "./SlideMenuItemStyled";

const SlideMenuItem = ({ items, backgroundColor, textTheme }) => {
  return (
    <MenuItemWrapper backgroundColor={backgroundColor}>
      {items?.map((item, idx) => {
        const key = `${item?.text}-${idx}`;
        return (
          <MenuItem href={item?.url} key={key}>
            <Text {...textTheme} copy={item?.text} />
          </MenuItem>
        );
      })}
    </MenuItemWrapper>
  );
};

export default SlideMenuItem;
SlideMenuItem.propTypes = {
  items: PropTypes.array,
  backgroundColor: PropTypes.string,
  textTheme: PropTypes.object
};
