import React, { useCallback } from "react";
import Text from "../Text";
import useComponentTheme from "../Theme/useComponentTheme";
import defaultTheme from "./QuantitySpinnerTheme";
import PropTypes from "prop-types";
import {
  QuanitySpinnerContainer,
  QuantityButton,
  QuantityContainer,
  QuantityInput,
  QuantityLabelContainer,
  QuantitySpin
} from "./QuantitySpinnerStyled";
import validateSpinnerAmount from "./utils/validateSpinnerAmount";
import { useCartUpdate } from "../../contexts/CartContext/index";
import Icons from "../Icons";

const QuantitySpinner = ({
  decrementCart,
  incrementCart,
  isCart,
  setValue,
  isDisabledPlus,
  spinner,
  submitCart,
  theme = defaultTheme,
  value
}) => {
  const quantitySpinnerTheme = useComponentTheme({ theme });
  const { spinnerID, min, max } = spinner;

  const { isLoading } = useCartUpdate();

  // amount to increment the quantity spinner by with the buttons
  const one = 1;
  const enterKeyCode = 13;

  const updateQuantity = useCallback(
    (e) => {
      const newValue = Number(e.target.value);
      if (validateSpinnerAmount(newValue, min, max)) setValue(newValue);
    },
    [min, max, setValue]
  );

  const incrementQuantity = useCallback(
    (e) => {
      if (value < max) {
        setValue(parseInt(value) + one);
      }
      if (incrementCart) {
        incrementCart(e);
      }
    },
    [value, max, setValue, incrementCart]
  );

  const decrementQuantity = useCallback(
    (e) => {
      if (value > min) {
        setValue(parseInt(value) - one);
      }
      if (decrementCart) decrementCart(e);
    },
    [value, min, setValue, decrementCart]
  );

  const setEmptyInputToZero = useCallback(
    (e) => {
      if (e.target.value === "") setValue(0);
    },
    [setValue]
  );

  const handleSubmit = (e) => {
    if (e.keyCode === enterKeyCode) {
      e.preventDefault();
      submitCart(e, value);
    }
  };

  return (
    <QuanitySpinnerContainer data-element="quantity-spinner">
      <QuantityContainer>
        {!isCart && (
          <QuantityLabelContainer>
            <Text
              copy="QTY:"
              htmlFor={spinnerID}
              tag="label"
              themeName={quantitySpinnerTheme?.spinerLabel?.themeName}
            />
          </QuantityLabelContainer>
        )}
        <QuantitySpin>
          <QuantityButton
            aria-label="decrease quantity"
            disabled={isLoading}
            onClick={decrementQuantity}
            tabIndex={"-1"}
            data-element="quantity-spinner-decrement"
          >
            <Icons type="MinusLight" />
          </QuantityButton>
          <QuantityInput
            id={spinnerID}
            max={max}
            min={min}
            onBlur={setEmptyInputToZero}
            onChange={updateQuantity}
            onKeyDown={handleSubmit}
            type="text"
            value={value}
            data-element="quantity-spinner-input"
            aria-label="Enter a quantity"
          ></QuantityInput>
          <QuantityButton
            aria-label="increase quantity"
            disabled={isLoading || isDisabledPlus}
            onClick={incrementQuantity}
            tabIndex={"-1"}
            data-element="quantity-spinner-increment"
          >
            <Icons type="PlusLight" />
          </QuantityButton>
        </QuantitySpin>
      </QuantityContainer>
    </QuanitySpinnerContainer>
  );
};

export default QuantitySpinner;

QuantitySpinner.propTypes = {
  decrementCart: PropTypes.func,
  incrementCart: PropTypes.func,
  isCart: PropTypes.bool,
  isDisabledPlus: PropTypes.bool,
  setValue: PropTypes.func,
  spinner: PropTypes.object,
  submitCart: PropTypes.func,
  theme: PropTypes.object,
  totalAvailable: PropTypes.number,
  value: PropTypes.number
};
