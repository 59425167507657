import styled from "styled-components";
import { PictureContainer } from "../ProductImage/ProductImageStyled";
import {
  Colors,
  Breakpoints,
  Shadows,
  Typography
} from "../../constants/constants";
import { ImageBorder } from "../SwatchImage/SwatchImageStyled";
import { Container } from "../ImageZoom/ImageZoomStyled";
import { ProductEDDWrapper } from "../ProductEDD/ProductEDDStyled";
import { motion } from "framer-motion";
import SnlImage from "../SnlImage";
import {
  AppliedFilters,
  ClearAll,
  FilterTitleSection
} from "../CUFilters/CUFiltersStyled";
import { IconContainer } from "../CTA/CTAStyled";
import { SlideInWrapper } from "../SlideIn/SlideInStyled";
import { BagPanelWrapper } from "../BagPanel/BagPanelStyled";

export const FabricPanelWrapper = styled.div`
  height: 100%;
  background-color: transparent;
  position: relative;

  ${FilterTitleSection} {
    margin-bottom: 16px;
  }
  ${AppliedFilters} {
    margin-bottom: 15px;
    ${IconContainer} {
      svg {
        width: 10px;
        height: 10px;
      }
    }
    ${ClearAll} {
      margin-left: 0;
      min-width: auto;
      @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
        margin-right: 0;
      }
      p {
        text-transform: capitalize;
      }
    }
  }

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    ${SlideInWrapper} {
      ${BagPanelWrapper} {
        height: 100%;
        box-sizing: border-box;
        width: 480px;
      }
    }
  }
  @media screen and (max-width: ${Breakpoints.IPad}) {
    ${SlideInWrapper} {
      ${BagPanelWrapper} {
        margin-top: 30px;
      }
    }
  }
  @media screen and (min-width: ${Breakpoints.IPad}) and (max-width: ${Breakpoints.ScreenWidth}) {
    ${SlideInWrapper} {
      ${BagPanelWrapper} {
        margin-top: 10px;
      }
    }
  }

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    height: 100%;
    display: flex;
  }
`;

export const DesktopContainer = styled(motion.div)`
  box-shadow: ${Shadows.FabricSlideIn};
  border-right: 1px solid ${Colors["Alternate Silver"]};
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 100%;
  background-color: ${Colors.White};
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    width: 400px;
    height: 100%;

    ${Container} {
      height: 100%;
      width: auto;
      margin-top: auto;
    }

    span {
      align-self: center;
      justify-self: center;
      position: absolute;
    }
  }

  @media screen and (max-width: ${Breakpoints.IPad}) {
    z-index: 999999;
    width: 100%;
    max-height: 80%;
  }

  @media screen and (min-width: ${Breakpoints.IPad}) and (max-width: ${Breakpoints.ScreenWidth}) {
    width: 480px;
    z-index: 9999;
    height: 100%;
    max-height: 100%;
  }

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    z-index: unset;
    width: 402px;
  }

  @media screen and (min-width: ${Breakpoints.DesktopLarge}) {
    width: 402px;
  }
`;

export const Backdrop = styled.div`
  @media screen and (max-width: ${Breakpoints.IPad}) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99999;
  }
`;

export const SwipeUpContainer = styled.div`
  position: absolute;
  bottom: 0;
  padding: 13px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  @media screen and (min-width: ${Breakpoints.IPad}) {
    display: none;
  }
`;

export const SwipeUp = styled.div`
  width: 100px;
  height: 4px;
  background-color: ${Colors.Midnight};
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    position: absolute;
    top: 8%;
    width: 432px;
    height: 432px;
  }

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    width: 518px;
    height: 518px;
    position: absolute;
    top: 8%;
  }
`;

export const DesktopContainerWrapper = styled.div`
  @media screen and (max-width: ${Breakpoints.IPad}) {
    max-height: calc(100% - 20px);
    height: calc(100% - 20px);
    margin-bottom: 20px;
    overflow: auto;
  }
  @media screen and (min-width: ${Breakpoints.IPad}) and (max-width: ${Breakpoints.ScreenWidth}) {
    max-height: calc(100% - 185px);
    height: calc(100% - 185px);
    overflow: hidden;
  }
  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    max-height: 100%;
    height: 100%;
    width: 100%;
    overflow: auto;
    margin: 24px 0 0;
  }
`;

export const DesktopImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media screen and (min-width: ${Breakpoints.IPad}) and (max-width: ${Breakpoints.ScreenWidth}) {
    max-height: 50%;
    height: 50%;
  }
  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    max-height: 100%;
    height: auto;
  }
`;

export const CurrentFabricDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 24px 10px;
  height: fit-content;
  align-self: flex-end;
  max-width: 432px;
  width: 100%;
  overflow: auto;
  min-height: 190px;
  box-sizing: border-box;

  ${ProductEDDWrapper} {
    margin-top: auto;
  }
  @media screen and (min-width: ${Breakpoints.IPad}) and (max-width: ${Breakpoints.ScreenWidth}) {
    margin-top: 10px;
    max-height: 50%;
    max-width: 100%;
  }
  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    max-width: 100%;
    max-height: 100%;
  }
  @media screen and (max-width: ${Breakpoints.IPad}) {
    padding-bottom: 0;
  }
`;

export const CurrentFabricName = styled.div`
  margin-bottom: 7px;
`;

export const CurrentFabricDescription = styled.div`
  margin-bottom: 30px;

  @media screen and (min-width: ${Breakpoints.IPad}) and (max-width: ${Breakpoints.ScreenWidth}) {
    margin-bottom: 35px;
  }
`;

export const TabletContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding: 16px;
  box-sizing: border-box;
  background-color: ${Colors.White};
  box-shadow: ${({ showShadow }) => (showShadow ? Shadows.FabricSlideIn : "0")};
  ${PictureContainer} {
    margin: 0;
    width: auto;
    height: 100%;
    max-height: 50%;
    picture {
      display: flex;
      justify-content: center;
    }
    img {
      width: 250px;
      height: 300px;
      margin: 0;
    }

    @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
      display: none;
    }
  }

  @media screen and (min-width: ${Breakpoints.IPad}) and (max-width: ${Breakpoints.ScreenWidth}) {
    max-width: 480px;
    width: 480px;
    padding: 24px;
  }

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    max-width: 480px;
    width: 480px;
    padding: 24px;
  }

  @media screen and (min-width: ${Breakpoints.DesktopLarge}) {
    max-width: 584px;
    min-width: 584px;
  }
`;

export const TabletIconContainer = styled.div`
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    display: none;
  }
`;

export const FabricPanelTitle = styled.div`
  margin-top: 7px;
  margin-bottom: 30px;
  @media screen and (min-width: ${Breakpoints.SmallMobile}) {
    display: block;
  }
  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    margin-bottom: 17px;
  }
`;

export const CurrentFabric = styled.div`
  margin-bottom: 8px;

  @media screen and (min-width: ${Breakpoints.SmallMobile}) {
    margin-bottom: 15px;
  }

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    display: none;
  }
`;

export const FilteredSwatchesContainer = styled.div`
  overflow-y: auto;
  margin-bottom: 8px;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.Fog};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors["Midnight 70%"]};
    border-radius: 5px;
    border: 1px solid ${Colors.White};
  }
  @media screen and (min-width: ${Breakpoints.SmallMobile}) {
    margin-bottom: 175px;
  }
  @media screen and (max-width: ${Breakpoints.ScreenWidth}) {
    margin-bottom: 100px;
  }
  @media screen and (max-width: ${Breakpoints.ScreenWidth}) {
    margin-bottom: 100px;
  }
`;

export const SwatchSection = styled.div`
  margin-bottom: 50px;

  > * {
    &:last-child {
      margin-bottom: 60px;
    }
  }

  @media screen and (min-width: ${Breakpoints.IPad}) and (max-width: ${Breakpoints.DesktopLarge}) {
    margin-bottom: 80px;

    > * {
      &:last-child {
        margin-bottom: 40px;
      }
    }
  }
  @media screen and (min-width: ${Breakpoints.DesktopLarge}) {
    margin-bottom: 20px;
  }
`;

export const OldSwatchesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

export const OldImageContainer = styled.button`
  cursor: pointer;
  color: none;
  border: none;
  background-color: ${Colors.White};
  padding: 0;

  ${ImageBorder} {
    width: 60px;
    height: 60px;
    padding: ${({ isSelected }) => (isSelected ? "5px" : "0px")};
  }

  :hover {
    ${ImageBorder} {
      padding: 5px;
    }
  }

  @media screen and (min-width: ${Breakpoints.SmallMobile}) {
    ${ImageBorder} {
      width: 74px;
      height: 74px;
      padding: ${({ isSelected }) => (isSelected ? "5px" : "0px")};
    }
  }
`;

export const SwatchesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 12px;
  padding-right: 6px;
  margin-bottom: 24px;

  @media screen and (max-width: ${Breakpoints.IPad}) {
    gap: 8px;
    padding-right: 6px;
  }

  @media screen and (max-width: ${Breakpoints.IPhone11}) {
    gap: 3px;
  }

  @media screen and (min-width: ${Breakpoints.IPad}) and (max-width: ${Breakpoints.ScreenWidth}) {
    gap: 16px;
  }

  @media screen and (max-width: ${Breakpoints.XSMobileMax}) {
    margin-bottom: 18px;
  }
`;

export const ImageContainer = styled.button`
  cursor: pointer;
  color: none;
  border: none;
  background-color: ${Colors.White};
  padding: 0;

  &:hover {
    img {
      padding: 3px;
      border: 1px solid ${Colors["Midnight 40%"]};
    }
  }

  img {
    &.selected {
      padding: 3px;
      border: 1px solid ${Colors.Midnight};
    }
  }

  ${ImageBorder} {
    width: 79px;
    height: auto;
    padding: 0;
    border: 0;
  }

  @media screen and (max-width: ${Breakpoints.IPad}) {
    flex: 1 1 calc(25% - 6px);
    max-width: calc(25% - 6px);
    ${ImageBorder} {
      width: 100%;
      height: auto;

      img {
        height: auto;
      }

      &:hover {
        img {
          padding: 3px;
        }
      }
    }
  }

  @media screen and (min-width: ${Breakpoints.IPad}) and (max-width: ${Breakpoints.ScreenWidth}) {
    flex: 1 1 calc(25% - 16px);
    max-width: calc(25% - 16px);
    ${ImageBorder} {
      width: 96px;
      height: auto;

      img {
        height: 96px;
      }

      &:hover {
        img {
          padding: 3px;
        }
      }
    }
  }

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    flex: 1 1 calc(25% - 12px);
    max-width: calc(25% - 12px);
    ${ImageBorder} {
      width: auto;
      height: auto;

      img {
        height: auto;
      }

      &:hover {
        img {
          padding: 3px;
        }
      }
    }
  }
`;

export const SwatchSetionTitle = styled.div`
  margin-bottom: 12px;
`;

export const PriceAndApply = styled.div`
  margin-top: auto;
  box-shadow: 0 -1px 8px rgba(36, 57, 83, 0.3);
  background-color: ${Colors.White};

  @media screen and (min-width: ${Breakpoints.LgTablet}) {
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0;
    padding: 16px;
    box-sizing: border-box;
    z-index: 9999999;
  }

  @media screen and (max-width: ${Breakpoints.LgTablet}) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    box-sizing: border-box;
  }
  @media screen and (max-width: ${Breakpoints.IPad}) {
    padding: 12px 16px;
    box-shadow: none;
  }
  @media screen and (min-width: ${Breakpoints.IPad}) {
    padding: 18px 30px;
    box-shadow: 0 -1px 8px rgba(36, 57, 83, 0.3);
  }
  a {
    width: 100%;
  }
`;

export const PriceAndApplyPreview = styled.div`
  margin-top: auto;
  box-shadow: 0 -1px 8px rgba(36, 57, 83, 0.3);

  @media screen and (max-width: ${Breakpoints.LgTablet}) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding: 18px 30px;
    box-sizing: border-box;
    box-shadow: 0 -1px 8px rgba(36, 57, 83, 0.3);
  }
  a {
    width: 100%;
  }
`;

export const MobileProductImage = styled(SnlImage)``;

export const RoundedFabricImage = styled(SnlImage)`
  color: transparent;
  position: absolute;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  border: 1px solid ${Colors["Midnight 40%"]};
  left: 16px;
  bottom: 0;
  width: 110px !important;
  height: 110px !important;
`;

export const MobilePriceAndApply = styled.div`
  display: block;
  margin-bottom: 16px;
  a {
    width: 100%;
  }
  @media screen and (min-width: ${Breakpoints.SmallMobile}) {
    display: none;
  }
`;

export const ApplyCTAContainer = styled.div`
  a {
    width: 100%;
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  @media screen and (min-width: ${Breakpoints.SmallMobile}) {
    margin-bottom: 14px;
  }
`;

export const PriceTitle = styled.div``;

export const Price = styled.div`
  h5 {
    font-family: ${Typography.BeatriceLight};
    font-weight: 300;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  > button {
    width: 100%;
  }
`;

export const CloseButton = styled.div`
  background-color: #fff;
  border: 1px solid #a7b0ba;
  border-radius: 100%;
  padding: 8px;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 24px;
  top: 31px;
  cursor: pointer;
  z-index: 9999;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    display: none;
  }
`;

export const FabricTypeWrapper = styled.div`
  margin-bottom: 12px;

  @media screen and (max-width: ${Breakpoints.XSMobileMax}) {
    margin-bottom: 8px;
  }
`;
