import React from "react";
import PropTypes from "prop-types";
import { RadioButtonsWrapper } from "../EmailSubscriptionStyled";
import * as Variants from "./Variants";

const RadioButtons = ({
  radioButtonType,
  radioButtonPlacement,
  handleTab,
  isDaily
}) => {
  const RadioComponent = Variants[radioButtonType];

  return (
    <RadioButtonsWrapper radioButtonPlacement={radioButtonPlacement}>
      <RadioComponent handleTab={handleTab} isDaily={isDaily} />
    </RadioButtonsWrapper>
  );
};

export default RadioButtons;

RadioButtons.propTypes = {
  radioButtonType: PropTypes.string,
  radioButtonPlacement: PropTypes.string,
  handleTab: PropTypes.func,
  isDaily: PropTypes.bool
};
