/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  CrossSellTileWrapper,
  ImageContainer,
  InfoContainer,
  ProductTileName,
  ProductTileFlag,
  VariationAttribute,
  VariationLabel,
  VariationValue,
  Price,
  QTYContainer,
  SpinnerContainer,
  AddToCartContainer,
  ProductDescriptions,
  AddWrapper,
  CrossSellPromoMessages
} from "./CrossSellStyled";
import getTileVariations from "./utils/getTileVariations";
import getProductForTile from "./utils/getProductForTile";
import QuantitySpinner from "../QuantitySpinner";
import AddToCart from "../AddToCart";
import { useCartUpdate } from "../../contexts/CartContext";
import Text from "../Text";
import defaultTheme from "./CrossSellTheme";
import { useUserData } from "../../contexts/UserContext";
import useComponentTheme from "../Theme/useComponentTheme";
import { useProductPrice } from "../../hooks/useProductPrice";
import Image from "next/image";
import { useProductInventories } from "../../hooks/useProductInventories";
import useProductImageSet from "../../hooks/useProductImageSet";
import currency from "currency.js";
import PromoMessages from "../../../ui-toolkit/components/Promos/PromoMessages";
import { PAGE_TYPE } from "@serenaandlily/constants/analytics/pageType";
import AnalyticsData from "@serenaandlily/components/AnalyticsData";
import { trackPinterestEvent } from "@utils/pinterest";
import ProductPrice from "../ProductPrice";

const CrossSellTile = ({
  product,
  isFirst,
  parentVariations,
  theme = defaultTheme,
  isInBagPanel = false,
  setCrossellsPrice
}) => {
  const {
    productCallout,
    marketingName,
    variations,
    netsuiteId,
    overRideMediaSet,
    productId: id
  } = product || {};

  const crossSellTheme = useComponentTheme({ theme });

  const userData = useUserData();

  const { getInventoryTotalAvailable, availableInventories } =
    useProductInventories(id);

  const productName = marketingName;
  const tileVariations = useMemo(
    () => getTileVariations(variations, parentVariations),
    [parentVariations, variations]
  );
  const tileProduct = useMemo(
    () => getProductForTile({ product, tileVariations, availableInventories }),
    [product, tileVariations, availableInventories]
  );

  const { productImageUrl } = useProductImageSet({
    parentNetsuiteId: netsuiteId,
    parentOverRideMediaSet: overRideMediaSet,
    childNetsuiteId: tileProduct?.netsuiteId,
    childOverRideMediaSet: tileProduct?.overRideMediaSet,
    params:
      "?fmt=auto&qlt=default&fmt.jp2.qlt=60&fmt.webp.qlt=70&w=477&h=600&img404=noimagemedium&unsharp=0,0.3,10,0"
  });

  const totalAvailable = getInventoryTotalAvailable(tileProduct?.id);
  const { data: productPriceData } = useProductPrice({
    productId: tileProduct?.id,
    context: "carousel"
  });
  const price = productPriceData?.formattedListPrice;
  const designerPrice = productPriceData?.formattedDsoDesigner;

  const quantitySpinnerProps = {
    spinnerID: `${marketingName}QuantitySpinner`,
    min: 0,
    max: 999,
    start: 1
  };

  const [currentCount, setCurrentCount] = useState(quantitySpinnerProps.start);
  const [hasBeenAdded, setHasBeenAdded] = useState(false);

  const isAddToCartEnabled =
    currentCount <= totalAvailable && currentCount > 0 && totalAvailable !== 0;

  const { handleCartAdd, isLoading, updatingId } = useCartUpdate();

  const handleAddToCart = async (e) => {
    e.preventDefault();
    await handleCartAdd({
      quantity: currentCount,
      productId: tileProduct?.id,
      closePopup: null,
      customerEmail: userData?.user?.email,
      price,
      productName,
      netsuiteId,
      source: PAGE_TYPE.xsells
    });

    let totalPrice = currency?.(price)?.multiply(currentCount).format();

    trackPinterestEvent("pinterestAddToCartID", "AddToCart", {
      value: price,
      currency: "USD",
      userEmail: userData?.user?.email,
      contents: [
        {
          item_name: marketingName,
          id: netsuiteId,
          item_price: price,
          quantity: currentCount
        }
      ]
    });

    setHasBeenAdded(true);
    isInBagPanel && setCrossellsPrice(totalPrice);
  };

  const disableAdd = hasBeenAdded && !isLoading;

  const showAdd = !hasBeenAdded;

  return (
    <CrossSellTileWrapper isFirst={isFirst}>
      <AnalyticsData
        id={`a-product-${netsuiteId}`}
        data={{
          ...product,
          displayPrice: productPriceData,
          quantity: currentCount
        }}
      />
      <ImageContainer>
        <Image
          src={productImageUrl}
          height={600}
          width={477}
          style={{
            maxWidth: "100%",
            height: "auto"
          }}
        />
      </ImageContainer>
      <InfoContainer>
        <ProductDescriptions>
          {productName && (
            <ProductTileName>
              {productCallout && (
                <ProductTileFlag>
                  <Text
                    copy={productCallout}
                    themeName={crossSellTheme?.productTileFlag?.themeName}
                  />
                </ProductTileFlag>
              )}
              <Text
                copy={productName}
                themeName="textSmall"
                fontFamily="BeatriceRegular"
                fontSizeDesktop={16}
                fontSizeTablet={14}
                fontSize={14}
              />
            </ProductTileName>
          )}
          {tileProduct?.variants?.map((variation, idx) => {
            const key = variation.name + idx;
            return (
              <VariationAttribute key={key}>
                <VariationLabel>
                  <Text
                    copy={`${variation?.name}:`}
                    themeName="textSmall"
                    fontColor="#667487"
                  />
                </VariationLabel>
                &nbsp;
                <VariationValue>
                  <Text
                    copy={variation?.value}
                    themeName="textSmall"
                    fontColor="#667487"
                  />
                </VariationValue>
              </VariationAttribute>
            );
          })}
          {price && (
            <Price>
              <ProductPrice
                formattedListPrice={price}
                formattedSalePrice={productPriceData?.formattedSalePrice}
                formattedDsoDesigner={designerPrice}
                theme={theme?.productPriceTheme}
                isLoading={isLoading}
              />
            </Price>
          )}
        </ProductDescriptions>
        {!!productPriceData?.promoMessages && (
          <CrossSellPromoMessages>
            {productPriceData.promoMessages.map((promoMessage, id) => (
              <PromoMessages key={id} {...promoMessage} />
            ))}
          </CrossSellPromoMessages>
        )}
        <AddWrapper>
          {showAdd || !isInBagPanel ? (
            <QTYContainer>
              <SpinnerContainer>
                <QuantitySpinner
                  spinner={quantitySpinnerProps}
                  totalAvailable={totalAvailable}
                  value={currentCount}
                  setValue={setCurrentCount}
                  submitCart={() => {}}
                />
              </SpinnerContainer>
            </QTYContainer>
          ) : (
            <VariationAttribute>
              <VariationLabel>
                <Text
                  copy={`Quantity:`}
                  themeName={crossSellTheme?.variationLabel?.themeName}
                />
              </VariationLabel>
              &nbsp;
              <VariationValue>
                <Text
                  copy={currentCount}
                  themeName={crossSellTheme?.variationValue?.themeName}
                />
              </VariationValue>
            </VariationAttribute>
          )}
          <AddToCartContainer>
            <AddToCart
              available={isAddToCartEnabled}
              isLoading={updatingId === tileProduct?.id}
              addToCart={handleAddToCart}
              hasBeenAdded={disableAdd}
              theme={{ cta: { themeName: "CTA Underlined" } }}
            />
          </AddToCartContainer>
        </AddWrapper>
      </InfoContainer>
    </CrossSellTileWrapper>
  );
};

export default CrossSellTile;

CrossSellTile.propTypes = {
  product: PropTypes.object,
  isFirst: PropTypes.bool,
  parentVariations: PropTypes.object,
  theme: PropTypes.object
};
