import styled from "styled-components";

export const ProductPriceWrapper = styled.div`
  display: flex;
  ${({ hasDesignerPrice }) => hasDesignerPrice && "flex-direction: column;"}
`;

export const SalesPrice = styled.div`
  color: #970013;
  display: flex;
`;

export const ListedPrice = styled.div`
  display: ${({isDiscount}) => !isDiscount ? 'block' : 'flex'};
  margin-right: 10px;
  color: #667487;
  text-decoration: ${({ hasSalesPrice }) =>
    hasSalesPrice ? `line-through` : ``};
`;

export const PriceLoaderWrapper = styled.div`
  span {
    margin: 4px 38px;
  }
`;

export const DiscountContainer = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-left: 4px;
  }
`;
