import { useEffect, useState } from "react";
import useSWRImmutable from "swr/immutable";

const fetcher = (url) => fetch(url).then((res) => res.json());

function getImageUrl(name, params = "") {
  return `https://cdn.media.amplience.net/s/serenaandlily/${name}_SET${params}`;
}

const useProductImageSet = ({
  parentNetsuiteId,
  childNetsuiteId,
  childOverRideMediaSet,
  parentOverRideMediaSet,
  params = ""
}) => {
  const { data } = useSWRImmutable(
    `https://cdn.media.amplience.net/s/serenaandlily/${
      childOverRideMediaSet || childNetsuiteId
    }_SET.json`,
    fetcher
  );

  const [productImageUrl, setProductImageUrl] = useState(
    getImageUrl(childNetsuiteId, params)
  );

  useEffect(() => {
    if (data?.items?.length) {
      setProductImageUrl(
        getImageUrl(childOverRideMediaSet || childNetsuiteId, params)
      );
    } else if (parentNetsuiteId) {
      setProductImageUrl(
        getImageUrl(parentOverRideMediaSet || parentNetsuiteId, params)
      );
    }
  }, [
    data,
    childNetsuiteId,
    parentNetsuiteId,
    childOverRideMediaSet,
    parentOverRideMediaSet,
    setProductImageUrl,
    params
  ]);

  return { productImageUrl };
};

export default useProductImageSet;
