import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  RemoveFromWishlistContainer,
  SuccessWrapper,
  ConfirmationWrapper
} from "./RemoveFromWishlistModalContentStyled";
import useAddToWishlistMutation from "@serenaandlily/hooks/useAddToWishlistMutation";
import { useWishlistContext } from "@serenaandlily/contexts/WishlistContext";
import Headline from "../Headline";
import Image from "../Image";

const RemoveFromWishlistModalContent = ({
  netsuiteId,
  customUpholsteryOptions,
  ga4Props
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { wishlistContent } = useWishlistContext();
  const { removeItemHeadline } = wishlistContent || {};

  const { fabricCode, topLineVariation, otherOptions } =
    customUpholsteryOptions || {};

  const { isMutating, trigger } = useAddToWishlistMutation({
    itemData: {
      customUpholsteryOptions: {
        ...(fabricCode && { fabricCode }),
        ...(topLineVariation && { topLineVariation }),
        ...(otherOptions && { otherOptions })
      },
      netsuiteId,
      quantity: 0
    },
    ga4Props
  });

  const handleRemove = async () => {
    try {
      await trigger();
      setIsSubmitted(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Failed to remove item from wishlist:", error);
    }
  };

  return (
    <RemoveFromWishlistContainer>
      <motion.div layout>
        <AnimatePresence mode="wait" exitBeforeEnter>
          {!isSubmitted ? (
            <motion.div
              key="confirm"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              layout
            >
              <ConfirmationWrapper>
                <Headline
                  {...{
                    ...removeItemHeadline.confirmDeleteContent,
                    headlineCta: {
                      ...removeItemHeadline.confirmDeleteContent.headlineCta,
                      useButtonTag: true,
                      disabled: isMutating,
                      cta: {
                        ...removeItemHeadline.confirmDeleteContent.headlineCta
                          ?.cta,
                        onCtaClick: handleRemove
                      }
                    },
                    DOOHeadlineCta: {
                      ...(removeItemHeadline.confirmDeleteContent
                        ?.DOOHeadlineCta?.content || {}),
                      useButtonTag: true,
                      disabled: isMutating,
                      cta: {
                        ...removeItemHeadline.confirmDeleteContent
                          ?.DOOHeadlineCta?.content?.cta,
                        onCtaClick: handleRemove
                      }
                    }
                  }}
                />
              </ConfirmationWrapper>
            </motion.div>
          ) : (
            <motion.div
              key="success"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              layout
            >
              <SuccessWrapper>
                <Image {...removeItemHeadline.afterDeleteImage} />
                <Headline {...removeItemHeadline.afterDeleteContent} />
              </SuccessWrapper>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </RemoveFromWishlistContainer>
  );
};

export default RemoveFromWishlistModalContent;
