const sortFn = (a, b) => {
  return a?.name > b?.name ? 1 : -1;
};

const otherOptionsCompare = (opts1, opts2) => {
  if (!opts1?.length && !opts2?.length) return true;
  const sorted1 = opts1?.sort?.(sortFn) || null;
  const sorted2 = opts2?.sort?.(sortFn) || null;
  return JSON.stringify(sorted1) === JSON.stringify(sorted2);
};

const cuCompare = (clientCuObj, mwCuObj) => {
  for (const key of Object.keys(clientCuObj)) {
    if (
      key === "otherOptions" &&
      !otherOptionsCompare(clientCuObj?.[key], mwCuObj?.[key])
    )
      return false;
    if (key === "otherOptions") continue;
    if (clientCuObj?.[key] !== mwCuObj?.[key]) return false;
  }
  return true;
};

export const isItemInWishlist = ({ item, wishlistItems }) => {
  const matchingNetsuiteId =
    wishlistItems?.filter?.(
      (wishlistItem) =>
        wishlistItem?.netsuiteId === item?.netsuiteId &&
        wishlistItem?.netsuiteId !== undefined
    ) || [];

  const matchingProductId =
    wishlistItems?.filter?.(
      (wishlistItem) =>
        wishlistItem?.id === item?.id && wishlistItem?.id !== undefined
    ) || [];

  if (!matchingNetsuiteId?.length && !matchingProductId?.length) return false;

  if (!item?.customUpholsteryOptions) return true;

  const matchingCuObjects = [
    ...matchingNetsuiteId,
    ...matchingProductId
  ]?.filter?.((wishlistItem) =>
    cuCompare(
      item?.customUpholsteryOptions,
      wishlistItem?.customUpholsteryOptions
    )
  );

  return Boolean(matchingCuObjects?.length);
};
