const defaultTheme = {
  productPrice: {
    themeName: "headline5"
  },
  productDescription: {
    themeName: "text"
  },
  productCallout: {
    themeName: "headline6",
    borderColor: "black"
  },
  productEdd: {
    themeName: "label"
  }
};

export default defaultTheme;
