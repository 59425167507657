import React, { forwardRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Container, InputBox } from "./TextBoxStyled";
import Text from "../Text";
import { Controller, useFormContext } from "react-hook-form";
import emojiStrip from "emoji-strip";

const TextBoxWithController = forwardRef(
  (
    {
      height,
      tabletHeight,
      desktopHeight,
      fontFamily,
      countLimit = 500,
      placeholderText,
      resizeType,
      backgroundColor,
      textBoxWidth,
      fontColor,
      fontSize,
      fontSizeTablet,
      fontSizeDesktop,
      lineHeight,
      textBoxFocusOutline,
      boxHelperTextTheme,
      disabled = false,
      showCount = true,
      borderRadius,
      borderColor,
      padding,
      formFieldName,
      rules,
      control
    },
    ref
  ) => {
    const { watch, setValue } = useFormContext();
    const textValue = watch(formFieldName);
    const remainingCharacters = countLimit - textValue?.length || countLimit;
    const ALLOWED_CHARACTERS_REGEX = /[^\x20-\x7E]|\\/g;

    useEffect(() => {
      const cleanValue =
        textValue &&
        emojiStrip(textValue).replace(ALLOWED_CHARACTERS_REGEX, "");
      textValue && setValue(formFieldName, cleanValue);
    }, [textValue]);

    return (
      <Container>
        <Controller
          control={control}
          name={formFieldName}
          rules={rules}
          render={({
            field: { ref: fieldRef, ...rest },
            fieldState: { error }
          }) => (
            <InputBox
              ref={(el) => {
                if (typeof ref === "function") {
                  ref(el);
                } else if (ref) {
                  ref.current = el;
                }
                fieldRef(el);
              }}
              placeholder={placeholderText}
              height={height || tabletHeight || desktopHeight}
              tabletHeight={tabletHeight || desktopHeight}
              desktopHeight={desktopHeight}
              fontFamily={fontFamily}
              resize={resizeType !== "Both" && resizeType}
              textBoxWidth={textBoxWidth}
              fontColor={fontColor}
              backgroundColor={backgroundColor}
              fontSize={fontSize || fontSizeTablet || fontSizeDesktop}
              fontSizeTablet={fontSizeTablet || fontSizeDesktop}
              fontSizeDesktop={fontSizeDesktop}
              lineHeight={lineHeight}
              textBoxFocusOutline={textBoxFocusOutline}
              disabled={disabled}
              borderRadius={borderRadius}
              borderColor={borderColor}
              padding={padding}
              hasError={error}
              maxLength={countLimit}
              {...rest}
            />
          )}
        />
        {showCount && (
          <Text
            copy={`${remainingCharacters} characters left out of ${countLimit}`}
            theme={boxHelperTextTheme}
          />
        )}
      </Container>
    );
  }
);

TextBoxWithController.displayName = "TextBoxWithController";

export default TextBoxWithController;

TextBoxWithController.propTypes = {
  height: PropTypes.number,
  tabletHeight: PropTypes.number,
  desktopHeight: PropTypes.number,
  fontFamily: PropTypes.string,
  countLimit: PropTypes.number,
  placeholderText: PropTypes.string,
  resizeType: PropTypes.string,
  backgroundColor: PropTypes.string,
  textBoxWidth: PropTypes.number,
  fontColor: PropTypes.string,
  fontSize: PropTypes.string,
  fontSizeTablet: PropTypes.string,
  fontSizeDesktop: PropTypes.string,
  lineHeight: PropTypes.string,
  textBoxFocusOutline: PropTypes.string,
  boxHelperTextTheme: PropTypes.object,
  value: PropTypes.string,
  setValue: PropTypes.func,
  disabled: PropTypes.bool,
  borderRadius: PropTypes.string,
  borderColor: PropTypes.string,
  padding: PropTypes.string,
  formFieldName: PropTypes.string,
  rules: PropTypes.object,
  control: PropTypes.func
};
