import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
  useEffect
} from "react";
import Icons from "../Icons";
import { WishlistIconContainer } from "./WishlistHeartStyled";
import useAddToWishlistMutation from "@serenaandlily/hooks/useAddToWishlistMutation";
import { useWishlistContext } from "@serenaandlily/contexts/WishlistContext";
import { isItemInWishlist } from "./utils/isItemInWishlist";

const WishlistHeartPlaceholder = () => {
  return <div style={{ width: "24px", height: "24px" }} />;
};

const WishlistHeart = forwardRef(
  (
    {
      itemData,
      isLoading,
      wishlistStatus,
      ga4Props,
      onClickOverride,
      isDisabled = false
    },
    ref
  ) => {
    const [hasBeenClicked, setHasBeenClicked] = useState(false);

    useEffect(() => {
      const resetClicked = setTimeout(() => {
        setHasBeenClicked(false);
      }, 200);
      return () => clearTimeout(resetClicked);
    }, [hasBeenClicked]);

    const { optimisticAddedItems, optimisticRemovedItems } =
      useWishlistContext();

    const { wishlistHasProduct, wishlistHasDifferentVariation } =
      wishlistStatus || {};

    const hasOptimisticAddedItem = useMemo(() => {
      return isItemInWishlist({
        item: itemData,
        wishlistItems: optimisticAddedItems
      });
    }, [itemData, optimisticAddedItems]);

    const hasOptimisticRemovedItem = useMemo(() => {
      return isItemInWishlist({
        item: itemData,
        wishlistItems: optimisticRemovedItems
      });
    }, [itemData, optimisticRemovedItems]);

    const isWishlisted =
      (wishlistHasDifferentVariation ||
        wishlistHasProduct ||
        hasOptimisticAddedItem) &&
      !hasOptimisticRemovedItem;

    const { trigger, isMutating } = useAddToWishlistMutation({
      itemData: { ...itemData, quantity: isWishlisted ? 0 : 1 },
      ga4Props
    });

    useImperativeHandle(ref, () => ({
      triggerWishlistAction: () => {
        trigger();
      },
      isMutating
    }));

    return isDisabled ? null : isLoading ? (
      <WishlistHeartPlaceholder />
    ) : (
      <WishlistIconContainer
        // eslint-disable-next-line react/jsx-no-bind
        animate={
          isWishlisted && hasBeenClicked
            ? {
                scale: [1, 1.2, 1]
              }
            : {}
        }
        transition={{
          duration: 0.2,
          ease: "easeInOut"
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setHasBeenClicked(true);
          if (isMutating) return;
          onClickOverride ? onClickOverride?.() : trigger();
        }}
        isWishlisted={isWishlisted}
        aria-label="wishlist button"
      >
        <Icons type={"WishlistHeart"} width={24} height={24} />
      </WishlistIconContainer>
    );
  }
);

WishlistHeart.displayName = "WishlistHeart";

export default WishlistHeart;
