import { Fragment } from "react";
import { ColumnContentContainer, ImageWrapper } from "./../ModularColumnStyled";

export const getColumnWrapper = (contentType) => {
    switch (contentType) {
      case "Headline":
        return ColumnContentContainer;
      case "Image":
        return  ImageWrapper;
      default:
        return Fragment;
    }
  };

export const getColumnContentData = (contentType, content, imageData) => {
    switch (contentType) {
      case "Headline":
        return {...content};
      case "Image":
        return {...imageData};
      default:
        return null;
    }
  };
