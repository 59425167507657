import React from "react";
import PropTypes from "prop-types";
import { DEFAULT_MARGIN_BOTTOM, DEFAULT_FONT_COLOR } from "./constants";

import { Typography } from "@serenaandlily/constants/constants";

import { PromoMessagesWrapper } from "./PromoMessagesStyled";
import Text from "../../Text";

const PromoMessages = ({
  message,
  backgroundColor,
  isBold,
  theme = "textMedium",
  fontSize = "14px",
  fontColor = DEFAULT_FONT_COLOR,
  marginBottom = DEFAULT_MARGIN_BOTTOM,
  displayBlock = false,
  width = "100%",
  maxWidth = "100%",
  letterSpacing = "0.32px",
  lineHeight = "20.8px"
}) => {
  return (
    <PromoMessagesWrapper
      displayBlock={displayBlock}
      marginBottom={marginBottom}
      backgroundColor={backgroundColor}
      width={width}
      maxWidth={maxWidth}
    >
      <Text
        copy={message}
        themeName={theme}
        fontFamily={
          isBold ? Typography.BeatriceBold : Typography.BeatriceRegular
        }
        fontSize={fontSize}
        fontColor={fontColor}
        backgroundColor={backgroundColor}
        letterSpacing={letterSpacing}
        lineHeight={lineHeight}
      />
    </PromoMessagesWrapper>
  );
};

export default PromoMessages;

PromoMessages.propTypes = {
  message: PropTypes.string,
  theme: PropTypes.object,
  color: PropTypes.string,
  marginBottom: PropTypes.number,
  fontFamily: PropTypes.string
};
