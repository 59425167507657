import { Colors } from "../../constants/constants";
const defaultTheme = {
  containterWidthDesktop: 370,
  containterWidthTablet: 244,
  containterWidthMobile: 163,
  scrollBarWidthDesketop: 95,
  scrollBarWidthTablet: 103,
  scrollBarWidthMobile: 55,
  scrollBarHeight: 2,
  containerColor: Colors["Midnight 40%"],
  scrollBarColor: Colors["Midnight"]
};

export default defaultTheme;
