import React from "react";
import PropTypes from "prop-types";
import {
  ModularColumnContainer,
  ModularColumnRow,
  ModularContentContainer
} from "./ModularColumnStyled";
import ModularColumnContent from "./ModularColumnContent";
import { useBreakpoint } from "../Breakpoints";
import getColumnWidth from "./utils/getColumnWidth";
import ThumbSwipe from "./ThumbSwipe";
import GetViewportColumn from "./utils/GetViewportColumn";

const ModularColumn = ({
  alignment,
  gutters,
  contentItems,
  contentItemIndex,
  thumbSwipe,
  thumbswipeHeight,
  progressBar
}) => {
  const breakpoints = useBreakpoint();
  const showCarousel = breakpoints["ScreenWidth"] && thumbSwipe;

  if (showCarousel) {
    return (
      <ThumbSwipe
        thumbswipeHeight={thumbswipeHeight}
        contentItems={contentItems}
        progressBar={progressBar}
      />
    );
  }

  return (
    <ModularColumnContainer>
      <ModularColumnRow {...alignment} {...gutters}>
        {contentItems.map((columnData, idx) => {
          const key = `modular-column-content-item-${idx}`;
          const {
            column,
            columnTablet,
            columnMobile,
            content,
            mobilePosition,
            removeHorizontalPadding
          } = columnData || {};
          const width = GetViewportColumn(column, columnTablet, columnMobile);
          if (width === "0") return null;

          const widthPercentage = getColumnWidth(width);

          return (
            <ModularContentContainer
              key={key}
              widthPercentage={widthPercentage}
              {...alignment}
              {...gutters}
              removeHorizontalPadding={removeHorizontalPadding}
              mobilePosition={mobilePosition}
            >
              <ModularColumnContent
                contentItemIndex={contentItemIndex}
                {...content}
                {...gutters}
                {...alignment}
              />
            </ModularContentContainer>
          );
        })}
      </ModularColumnRow>
    </ModularColumnContainer>
  );
};

export default ModularColumn;

ModularColumn.propTypes = {
  alignment: PropTypes.object,
  gutters: PropTypes.object,
  contentItems: PropTypes.array,
  thumbSwipe: PropTypes.bool,
  thumbswipeHeight: PropTypes.number,
  progressBar: PropTypes.object,
  contentItemIndex: PropTypes.number
};
