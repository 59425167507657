import styled from "styled-components";
import {
  Horizontal,
  Breakpoints,
  ModularVertical,
  ModularOrder
} from "../../constants/constants";

export const ModularColumnContainer = styled.div`
  @media screen and (min-width: ${Breakpoints.IPhone11}) {
    overflow: hidden;
  }
`;

export const ColumnContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ mobileHorizontalAlignment }) =>
    Horizontal[mobileHorizontalAlignment] || "center"};
  height: 100%;
  padding: 0 24px;
`;

export const ModularColumnRow = styled.div`
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  margin-left: ${({ removeMobile }) => (removeMobile ? 0 : "-12px")};
  margin-right: ${({ removeMobile }) => (removeMobile ? 0 : "-12px")};
  justify-content: ${({ mobileHorizontalAlignment }) =>
    Horizontal[mobileHorizontalAlignment] || "center"};
  align-items: ${({ verticalAlignment }) =>
    ModularVertical[verticalAlignment] || "center"};
  @media screen and (min-width: ${Breakpoints.IPad}) {
    justify-content: ${({ desktopHorizontalAlignment }) =>
      Horizontal[desktopHorizontalAlignment] || "center"};
    margin-left: ${({ removeDesktopAndTablet }) =>
      removeDesktopAndTablet ? 0 : "-12px"};
    margin-right: ${({ removeDesktopAndTablet }) =>
      removeDesktopAndTablet ? 0 : "-12px"};
  }
`;

export const ModularContentContainer = styled.div`
  padding: ${({ removeMobile }) => (removeMobile ? 0 : "0 12px")};
  width: 100%;
  min-height: fit-content;
  order: ${({ mobilePosition }) => ModularOrder[mobilePosition]};
  flex: ${({ widthPercentage, removeMobile }) =>
    removeMobile
      ? `0 0 ${widthPercentage}`
      : `0 0 calc(${widthPercentage} - 24px)`};
  max-width: ${({ widthPercentage, removeMobile }) =>
    removeMobile ? `${widthPercentage}` : `calc(${widthPercentage} - 24px)`};
  ${ColumnContentContainer} {
    ${({ removeHorizontalPadding }) =>
      removeHorizontalPadding && `padding-left: 0; padding-right: 0;`}
  }

  @media screen and (min-width: ${Breakpoints.IPad}) {
    order: 0;
    padding: ${({ removeDesktopAndTablet }) =>
      removeDesktopAndTablet ? 0 : "0 12px"};
    flex: ${({ widthPercentage, removeDesktopAndTablet }) =>
      removeDesktopAndTablet
        ? `0 0 ${widthPercentage}`
        : `0 0 calc(${widthPercentage} - 24px)`};
    max-width: ${({ widthPercentage, removeDesktopAndTablet }) =>
      removeDesktopAndTablet
        ? `${widthPercentage}`
        : `calc(${widthPercentage} - 24px)`};
  }
`;

export const ThumbSwipeContainer = styled.div`
  margin-left: 16px;

  > div > div > div > div > div {
    @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
      max-width: 450px;
    }
  }
`;

export const ImageWrapper = styled.div`
  aspect-ratio: ${({ width, height }) => `${width} / ${height}`};
`;
