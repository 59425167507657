import styled from "styled-components";
import { Breakpoints, Colors, ModularOrder } from "../../constants/constants";

export const SecondaryFooterContainer = styled.div`
  ${({ mobileCSS }) => mobileCSS}

  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    ${({ tabletCSS }) => tabletCSS}
  }

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    ${({ desktopCSS }) => desktopCSS}
  }
`;

export const SecondaryFooterLinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  order: ${({ order }) => ModularOrder[order]};
`;

export const SpacingContainer = styled.div`
  padding: ${({ horizontalSpacing }) =>
    `0 ${horizontalSpacing ? `${horizontalSpacing}px` : "0"}`};
  order: ${({ order }) => ModularOrder[order]};
`;

export const DividerContainer = styled.div`
  color: ${Colors["Dark Primary"]};
`;
