import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Content,
  ContentContainer,
  CtaContainer,
  Heading,
  HeadingWrapper
} from "./ClearCartPopupStyled";
import CTA from "../CTA";
import Text from "../Text";
import defaultTheme from "./ClearCartPopupTheme";
import useComponentTheme from "../Theme/useComponentTheme";

const ClearCartPopup = ({
  closePopup,
  handleClearCart,
  theme = defaultTheme
}) => {
  const popupTheme = useComponentTheme({ theme });
  return (
    <Container>
      <HeadingWrapper>
        <Heading>
          <Text
            copy={"Would you like to clear all items in your bag?"}
            themeName={popupTheme?.heading?.themeName}
            theme={popupTheme?.heading.overrides}
          />
        </Heading>
      </HeadingWrapper>
      <ContentContainer>
        <Content>
          <Text
            copy={`When selecting “Clear Bag” all items in your bag will be removed.`}
            theme={popupTheme?.content}
            tag="span"
          />
          <Text
            copy={` Or select "Continue Shopping" to cancel this action.`}
            theme={popupTheme?.continueShopping}
            tag="span"
          />
        </Content>
        <CtaContainer>
          <CTA
            cta={{
              ctaText: "CLEAR BAG",
              onCtaClick: () => handleClearCart({ closePopup })
            }}
            themeName="Primary Button"
            useButtonTag={true}
          />
          <CTA
            cta={{
              ctaText: "CONTINUE SHOPPING",
              onCtaClick: () => closePopup()
            }}
            themeName="CTA Modal Text"
            useButtonTag={true}
          />
        </CtaContainer>
      </ContentContainer>
    </Container>
  );
};

export default ClearCartPopup;

ClearCartPopup.propTypes = {
  popupTheme: PropTypes.object,
  closePopup: PropTypes.func,
  theme: PropTypes.object,
  handleClearCart: PropTypes.func
};
