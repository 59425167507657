import React from "react";
import PropTypes from "prop-types";
import { ImageWrapper, FlyoutImage } from "./FlyoutMenuImageStyled";
import getAssetUrl from "../../utils/getAssetUrl";

const FlyoutMenuImage = ({ imageSrc }) => {
  return (
    <ImageWrapper>
      {imageSrc && <FlyoutImage src={getAssetUrl(imageSrc)} />}
    </ImageWrapper>
  );
};

export default FlyoutMenuImage;

FlyoutMenuImage.propTypes = {
  imageSrc: PropTypes.object
};
