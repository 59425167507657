import styled from "styled-components";
import { Colors, Breakpoints } from "../../constants/constants";

export const ResultsFlyoutContainer = styled.div`
  width: 100%;
  background-color: ${Colors.White};
  font-weight: 400;
  position: absolute;
  z-index: 3000;
`;

export const PaddingWrapper = styled.div`
  padding: 0 54px 32px;
  display: flex;
  justify-content: center;
  @media screen and (max-width: ${Breakpoints.ScreenWidth}) {
    flex-direction: column;
    padding: 27px 32px;
  }
  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    padding: 24px 8px;
  }
`;
