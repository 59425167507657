import styled from "styled-components";
import {
  Colors,
  FontSizes,
  Typography,
  LetterSpacing,
  Breakpoints
} from "../../../constants/constants";

export const LargeFlyoutContainer = styled.div`
  width: 100%;
  background-color: ${Colors.White};
  font-weight: 400;
  max-height: ${({ flyoutOffset }) => `calc(100dvh - ${flyoutOffset}px)`};
  overflow: auto;
  position: absolute;
  z-index: 3000;
`;

export const PaddingWrapper = styled.div`
  padding: 32px 54px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: ${Breakpoints.ScreenWidth}) {
    flex-direction: column;
  }
  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    padding: 8px 4px;
  }
`;

export const AllResultsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
export const AllResultsWrapper = styled.div`
  max-width: 950px;
`;

export const QueryText = styled.span`
  color: ${Colors["Midnight"]};
  font-family: ${Typography.PPFragment};
  font-size: ${FontSizes.Header};
  letter-spacing: ${LetterSpacing.XSmall};
  margin-bottom: 28px;
`;

export const ProductResultsWrapper = styled.div`
  display: flex;
  margin-bottom: 2em;
`;

export const PopularSearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${Breakpoints.ScreenWidth}) {
    flex-direction: row;
  }
  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const QueryWrapper = styled.div`
  height: min-content;
  display: flex;
  margin-bottom: 20px;
  @media screen and (max-width: ${Breakpoints.ScreenWidth}) {
    margin-right: 24px;
  }
`;

export const Query = styled.a`
  color: ${Colors["Midnight 70%"]};
  font-family: ${Typography.BeatriceLight};
  font-size: ${FontSizes.Xxlarge};
  cursor: pointer;
  text-decoration: none;
  & > b {
    color: ${Colors["Midnight"]};
  }
  @media screen and (max-width: ${Breakpoints.ScreenWidth}) {
    font-size: ${FontSizes.Xlarge};
  }
  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    white-space: nowrap;
  }
`;

export const ResultItem = styled.div`
  margin-right: 2em;
  @media screen and (max-width: ${Breakpoints.ScreenWidth}) {
    margin-right: 16px;
    &:last-of-type {
      margin-right: 0;
    }
  }
  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    margin: 0;
    max-width: calc(50% - 8px);
    &:nth-of-type(odd) {
      margin-right: 16px;
    }
  }
  width: -webkit-fill-available;
`;

export const ProductsHeadingWrapper = styled.div`
  font-family: ${Typography.BeatriceLight};
`;
export const Heading = styled.h2`
  font-size: ${FontSizes.Xlarge};
  color: ${Colors["Midnight"]};
  & > sup {
    font-size: ${FontSizes.Base};
    color: ${Colors["Midnight 70%"]};
  }
`;

export const DividerContainer = styled.div`
  position: relative;
  height: 3px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
`;

export const DividerInner = styled.div`
  position: absolute;
  left: 0;
  top: 1px;
  height: 1px;
  display: flex;
  width: 100%;
  background: ${Colors["Midnight 40%"]};
`;

export const Divider = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 3px;
  width: 115px;
  background: ${Colors["Primary Midnight"]};
`;

export const CTAWrapper = styled.div`
  & * a {
    width: 290px;
  }
`;
