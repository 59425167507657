import React from "react";
import PropTypes from "prop-types";
import {
  SlideOutMenuTabsWrapper,
  SlideOutMenuTabsContainer,
  Tab
} from "./SlideOutMenuStyled";
import Text from "../Text";
import { useSlideOutMenuContext } from "../../contexts/SlideOutMenuContext";

const SlideOutMenuTabs = ({
  slideOutMenus,
  isOpen,
  setSelectedCategories,
  activeMobileSlideOutIdx,
  setActiveMobileSlideOutIdx,
  slideOutMenuTheme,
  menuTabsTop
}) => {
  const { fixedElemententsHeight } = useSlideOutMenuContext();

  const handleTabSelect = (e, idx) => {
    e.preventDefault();
    setSelectedCategories({});
    setActiveMobileSlideOutIdx(idx);
  };

  const numTabs = slideOutMenus?.length;
  if (numTabs <= 1) return null;

  const menuTabsHeight = 64;

  const fixedHeights = fixedElemententsHeight
    ? menuTabsHeight + fixedElemententsHeight
    : menuTabsHeight;

  return (
    <SlideOutMenuTabsWrapper
      isOpen={isOpen}
      menuTabsTop={menuTabsTop - fixedHeights}
    >
      <SlideOutMenuTabsContainer>
        {slideOutMenus?.map((menuItem, idx) => {
          const key = `slideOutMenuTab-${idx}`;
          const { categoriesSectionName } = menuItem || {};
          const isActive = activeMobileSlideOutIdx === idx;
          return (
            <Tab
              key={key}
              isActive={isActive}
              onClick={(e) => handleTabSelect(e, idx)}
            >
              <Text
                copy={categoriesSectionName}
                themeName={slideOutMenuTheme?.tabs?.themeName}
              />
            </Tab>
          );
        })}
      </SlideOutMenuTabsContainer>
    </SlideOutMenuTabsWrapper>
  );
};

export default SlideOutMenuTabs;

SlideOutMenuTabs.propTypes = {
  slideOutMenus: PropTypes.object,
  isOpen: PropTypes.bool,
  setSelectedCategories: PropTypes.func,
  activeMobileSlideOutIdx: PropTypes.number,
  setActiveMobileSlideOutIdx: PropTypes.func,
  slideOutMenuTheme: PropTypes.object,
  menuTabsTop: PropTypes.number
};
