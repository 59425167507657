import {
  AccordionItemContainer,
  AccordionWrapper
} from "../Accordion/AccordionStyled";
import {
  CheckboxContainer,
  CheckboxInputWrapper,
  Label
} from "../FormComponents/CheckboxInput/CheckboxInputStyled";
import {
  Breakpoints,
  Colors,
  FontSizes,
  Typography
} from "../../constants/constants";
import Modal from "react-modal";
import styled, { keyframes } from "styled-components";

export const FilterClear = styled.button`
    font-size: ${FontSizes.Xxsmall};
    line-height: 18px;
    font-family: ${Typography.ProximaNova}
    font-weight: 400;
    color: ${Colors["Medium Primary"]};
    text-transform: uppercase;
    letter-spacing: 0.08em;
    border: none;
    padding: 0;
    background-color: unset;
    cursor: pointer;
    display: ${({ isHidden }) => (isHidden ? "none" : "block")};
  `;

export const FilterByWrapper = styled.div`
  width: 100%;
  margin-bottom: 30px;
  list-style: none;

  ${AccordionItemContainer} {
    border: none;
    @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
      border-bottom: 1px solid ${Colors["Border Gray"]};
    }
  }

  ${AccordionWrapper} {
    border: none;
    box-sizing: border-box;
    // padding: 0px 10px 0;
  }
`;

export const FilterListContainer = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;
`;

export const FilterListItem = styled.li`
  margin-bottom: 16px;

  ${CheckboxContainer} {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 3px;
  }

  ${CheckboxInputWrapper} {
    margin: 0;
  }

  ${Label} {
    font-weight: 300;
    font-style: normal;
    font-size: ${FontSizes.Large};
    font-family: ${Typography.BeatriceLight};
    line-height: 1.2;
    color: ${Colors.Midnight};
    box-sizing: border-box;
    margin: 0px 0px -2px 6px;
  }
`;

export const FilterListBottomBorder = styled.div`
  display: ${({ isLast }) => (isLast ? "none" : "block")};
  width: 100%;
  height: 1px;
  background: ${Colors["Midnight 40%"]};
  margin: 24px 0px 8px 0px;
`;

export const ShowOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const fadeInUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const fadeOutDown = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;

const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOutToLeft = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

export const StyledModal = styled(Modal)`
  position: absolute;
  right: auto;
  bottom: auto;
  outline: none;
  border: none;
  opacity: 0;
  transform: translate(0);
  top: 0;
  height: 100%;
  width: 100%;
  &.ReactModal__Content--after-open {
    animation: ${fadeInUp} 0.6s forwards;
    transform: translateY(0);
    opacity: 1;
  }
  &.ReactModal__Content--before-close {
    animation: ${fadeOutDown} 0.6s forwards;
  }
  @media screen and (min-width: ${Breakpoints.IPad}) {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
    max-width: 480px;
    &.ReactModal__Content--after-open {
      animation: ${slideInFromLeft} 0.6s forwards;
      transform: translateX(0);
      opacity: 1;
    }
    &.ReactModal__Content--before-close {
      animation: ${slideOutToLeft} 0.6s forwards;
    }
  }
`;
export const ModalContent = styled.div`
  padding: 20px 16px;
  margin-top: 20px;
  background: #fff;
  border: none;
  height: 100%;
  @media screen and (min-width: ${Breakpoints.IPad}) {
    margin-top: 0px;
  }
  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    margin-top: 0px;

    height: 100vh;
    padding: 24px 24px 0 32px;
  }
`;

export const ModalFooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: fill-available;
  z-index: 100;
`;

export const MobileFooterContainerShadow = styled.div`
  width: 100%;
  height: 15px;
  flex-shrink: 0;
  background: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0) 0%,
    rgba(217, 217, 217, 0.3) 100%
  );
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
  line-height: 120%;
`;

export const CloseBtn = styled.button`
  background: transparent;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1px solid #a7b0ba;
`;

export const CopyContainer = styled.div`
  padding-top: 7.5px;
  text-align: center;
  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    display: none;
  }
`;

export const CtaMobileContainer = styled.div`
  padding: 17px 16px 15px 16px;
  background-color: ${Colors["Cloud"]};
  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    background: #fff;
    padding: 16px 32px;
  }
`;

export const AccordionMobileContainer = styled.div`
  height: calc(100vh - 143px);
  overflow-y: auto;
  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    height: calc(100vh - 85px - 100px);
    scrollbar-width: none;
  }
`;
export const AccordionMobileWrapper = styled.div`
  padding-bottom: calc(100vh / 5);
  overflow-y: auto;
  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    padding-bottom: 20px;
  }
`;
