// STYLED IMPORTS //
import styled from "styled-components";

// UTIL IMPORTS //
import { generateGap, generateMargin, generatePadding } from "./utils";

// CONSTANT IMPORTS //
import { Breakpoints } from "@serenaandlily/constants/constants";

// Components //
export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection ?? "row"};
  justify-content: ${({ justifyContent }) => justifyContent ?? "flex-start"};
  align-items: ${({ alignItems }) => alignItems ?? "normal"};
  gap: ${({ gap }) => `${generateGap({ gap })}`};

  margin: ${({ margin }) => `${generateMargin({ margin })}`};
  padding: ${({ padding }) => `${generatePadding({ padding })}`};

  @media screen and (min-width: ${Breakpoints.SMTabletMin}) and (max-width: ${Breakpoints.MDLaptopMin}) {
    gap: ${({ gap, gapTablet }) => `${generateGap({ gap, gapTablet })}`};

    margin: ${({ margin, marginTablet }) =>
      `${generateMargin({ margin, marginTablet })}`};
    padding: ${({ padding, paddingTablet }) =>
      `${generatePadding({ padding, paddingTablet })}`};
  }

  @media screen and (min-width: ${Breakpoints.MDLaptopMin}) {
    gap: ${({ gap, gapDesktop }) => `${generateGap({ gap, gapDesktop })}`};

    margin: ${({ margin, marginDesktop }) =>
      `${generateMargin({ margin, marginDesktop })}`};
    padding: ${({ padding, paddingDesktop }) =>
      `${generatePadding({ padding, paddingDesktop })}`};
  }
`;
