const getAlignmentCSS = (layout) => {
  const { alignment, tabletAlignment, mobileAlignment } = layout || {};

  let desktopCSS;
  let tabletCSS;
  let mobileCSS;

  const Alignments = {
    Left: "flex-start",
    Center: "center",
    Right: "flex-end"
  };

  desktopCSS = `
    display: flex;
    flex-direction: column;
    align-items: ${Alignments[alignment]};
    text-align: ${alignment?.toLowerCase()};
    
  `;
  tabletCSS = `
    display: flex;
    flex-direction: column;
    align-items: ${Alignments[tabletAlignment]};
    text-align: ${tabletAlignment?.toLowerCase()};
  `;
  mobileCSS = `
    display: flex;
    flex-direction: column;
    align-items: ${Alignments[mobileAlignment]};
    text-align: ${mobileAlignment?.toLowerCase()};
  `;

  return { desktopCSS, tabletCSS, mobileCSS };
};

export default getAlignmentCSS;
