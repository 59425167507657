import styled from "styled-components";
import {
  Colors,
  FontSizes,
  Typography,
  LetterSpacing
} from "../../constants/constants";

export const SkipToMainAccessibilityButton = styled.button`
  opacity: 0;
  position: fixed;
  top: 68px;
  pointer-events: none;
  &:focus {
    cursor: pointer;
    opacity: 100;
    background-color: Colors["Border Gray"];
    border: 1px solid ${Colors["Border Gray"]};
    border-radius: 6px;
    display: flex;
    padding: 10px 16px;
    z-index: 9999;
    outline: 1;
  }
`;

export const SkipToMainAccessibilityMessage = styled.span`
  color: ${Colors["Dark Primary"]};
  font-family: ${Typography.ProximaNova};
  letter-spacing: ${LetterSpacing.XSmall};
  font-size: ${FontSizes.Base};
  text-align: center;
`;
