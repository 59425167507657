import React from "react";
import PropTypes from "prop-types";
import {
  EmailIconLinksWrapper,
  IconLinksContainer
} from "./EmailIconLinksStyled";
import EmailSubscription from "../EmailSubscription";
import IconLinks from "../IconLinks";

const EmailIconLinks = ({ emailSubscription, iconLinks, disableIconLinks }) => {
  return (
    <EmailIconLinksWrapper>
      {emailSubscription && (
        <EmailSubscription {...emailSubscription} source="Footer" />
      )}
      <IconLinksContainer disableIconLinks={disableIconLinks}>
        {iconLinks?.map((iconLink, idx) => {
          const key = `Icon-Links-${idx}`;
          return <IconLinks key={key} {...iconLink} />;
        })}
      </IconLinksContainer>
    </EmailIconLinksWrapper>
  );
};

export default EmailIconLinks;

EmailIconLinks.propTypes = {
  emailSubscription: PropTypes.object,
  iconLinks: PropTypes.array,
  disableIconLinks: PropTypes.bool
};
