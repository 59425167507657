import styled from "styled-components";
import { Breakpoints } from "../../constants/constants";

export const SidePanelWrapper = styled.div`
  position: ${({ isOpen }) => (isOpen ? "absolute" : "relative")};
  height: 100vh;
  width: 100%;
  background: transparent;
`;

export const SidePanelBackdrop = styled.div`
  background: #243953;
  opacity: 0.5;
  width: 100vw;
  height: 100vh;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
`;

export const SidePanelContainer = styled.div`
  background: #fff;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  width: auto;
  transform: ${({ isVisible }) =>
    isVisible ? "translateX(0)" : `translateX(100%)`};
  transition: transform 1s ease;
  min-width: 100vw;

  @media screen and (min-width: ${Breakpoints.SmallMobile}) {
    max-width: 480px;
    min-width: 480px;
    height: auto;
    top: 0;
  }

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    max-width: 880px;
  }

  @media screen and (min-width: ${Breakpoints.DesktopLarge}) {
    max-width: 1030px;
  }
`;

export const CloseButton = styled.button`
  background: #fff;
  border: 1px solid #a7b0ba;
  border-radius: 100%;
  padding: 10px;
  width: 35px;
  height: 35px;
  position: absolute;
  right: 15px;
  top: 22px;
  cursor: pointer;
`;
