import React from "react";

const EditProductTileIcon = () => {
  return (
    <svg
      width="24"
      height="8"
      viewBox="0 0 24 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Edit">
        <circle id="Ellipse 1797" cx="3" cy="4" r="3" fill="#243953" />
        <circle id="Ellipse 1798" cx="12" cy="4" r="3" fill="#243953" />
        <circle id="Ellipse 1799" cx="21" cy="4" r="3" fill="#243953" />
      </g>
    </svg>
  );
};

export default EditProductTileIcon;
