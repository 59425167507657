const defaultTheme = {
  themeName: "Primary",
  suggestionTitle: {
    fontFamily: "PP Fragment",
    fontColor: "#243953",
    fontWeight: "normal",
    fontSize: 24,
    fontSizeDesktop: 24,
    fontSizeTablet: 24,
    marginBottom: 0
  },
  imageLinkTitle: {
    fontFamily: "PP Fragment",
    fontColor: "#243953",
    fontWeight: "normal",
    fontSize: 24,
    fontSizeDesktop: 24,
    fontSizeTablet: 24,
    marginBottom: 0
  },
  textLinkTitle: {
    fontFamily: "PP Fragment",
    fontColor: "#243953",
    fontWeight: "normal",
    fontSize: 24,
    fontSizeDesktop: 24,
    fontSizeTablet: 24,
    marginBottom: 0
  },
  flyoutBorderOptions: {
    borderThickness: 1,
    borderColor: "black"
  }
};

export default defaultTheme;
