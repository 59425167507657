import React, { useState, useCallback, useEffect } from "react";
import {
  SwatchesContainer,
  SwatchThumbnail,
  SwatchThumbnailWrapper
} from "./TileSwatchesStyled";
import PropTypes from "prop-types";
import CTA from "../../CTA";
import { useRouter } from "next/router";

const TileSwatches = ({
  swatchData = [],
  setCurrentImageIdx,
  url,
  visibleSwatchLimit,
  productName,
  tileWidth,
  tileId,
  moreTextConfigs,
  initialSelectedSwatch,
  swatchType,
  onSwatchClick
}) => {
  const [selectedSwatch, setSelectedSwatch] = useState(0);
  const router = useRouter();

  useEffect(() => {
    if (initialSelectedSwatch) {
      setSelectedSwatch(initialSelectedSwatch);
      setCurrentImageIdx(initialSelectedSwatch);
    }
  }, [initialSelectedSwatch]);

  const { moreText, useCount, themeName } = moreTextConfigs || {};

  let showMoreCta = swatchData?.length > visibleSwatchLimit;

  let moreCount =
    showMoreCta && useCount ? swatchData?.length - visibleSwatchLimit : "";

  const ctaProps = {
    ctaText: moreText ? `+${moreCount} ${moreText}` : "+ more",
    ctaStyle: themeName || "CTA Black",
    url: url,
    ctaAlignment: "center"
  };

  const handleClick = useCallback(
    (swatchIdx, swatch) => {
      if (swatch?.pdpUri && swatch?.openSwatchPdp) {
        router.push(swatch.pdpUri);
        return;
      }
      setSelectedSwatch(swatchIdx);
      setCurrentImageIdx(swatchIdx);
      if (onSwatchClick) {
        onSwatchClick({ ...swatch, swatchIdx });
      }
    },
    [setSelectedSwatch, setCurrentImageIdx, onSwatchClick]
  );

  const handleMouseEnter = useCallback(
    (swatchIdx) => {
      setCurrentImageIdx(swatchIdx);
    },
    [setCurrentImageIdx]
  );

  const handleMouseLeave = useCallback(() => {
    setCurrentImageIdx(selectedSwatch);
  }, [setCurrentImageIdx, selectedSwatch]);

  return (
    <SwatchesContainer tileWidth={tileWidth}>
      {swatchData?.map((swatch, idx) => {
        if (idx >= visibleSwatchLimit || !swatch?.swatchUrl) return null;
        const { swatchUrl, color } = swatch;
        if (!color) return;
        return (
          <SwatchThumbnailWrapper
            key={`${productName}-${color}-${idx}-${tileId}`}
            onClick={() => handleClick(idx, swatch)}
            onMouseEnter={() => handleMouseEnter(idx)}
            onMouseLeave={handleMouseLeave}
          >
            <SwatchThumbnail
              src={swatchUrl}
              alt={`${productName}-${color}-thumbnail`}
              isSelected={idx === selectedSwatch}
              swatchType={swatchType}
            />
          </SwatchThumbnailWrapper>
        );
      })}
      {showMoreCta && <CTA cta={ctaProps} themeName="CTA Midnight" />}
    </SwatchesContainer>
  );
};

export default React.memo(TileSwatches);

TileSwatches.propTypes = {
  swatchData: PropTypes.array,
  setCurrentImageIdx: PropTypes.func,
  productName: PropTypes.string,
  url: PropTypes.string,
  visibleSwatchLimit: PropTypes.number,
  initialSelectedSwatch: PropTypes.number,
  tileWidth: PropTypes.string,
  tileId: PropTypes.string,
  swatchType: PropTypes.string,
  onSwatchClick: PropTypes.func,
  moreTextConfigs: PropTypes.shape({
    moreText: PropTypes.string,
    useCount: PropTypes.bool,
    themeName: PropTypes.string
  })
};
