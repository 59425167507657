import styled, { css } from "styled-components";
import { Colors, FontSizes, Breakpoints } from "../../constants/constants";
import { StyledSpinner } from "../Icons/IconsStyled";

export const EmblaCarousel = styled.div`
  position: relative;
  max-width: 600px;
  display: flex;
  justify-content: center;
  width: -webkit-fill-available;
  @media screen and (min-width: ${Breakpoints.IPad}) {
    margin: 0 24px;
  }
  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    margin: 0 24px;
  }
  @media screen and (min-width: ${Breakpoints.DesktopMedium}) {
    margin: 0 38px 0 56px;
  }
`;

export const EmblaContainer = styled.div`
  display: flex;
  user-select: none;
  margin-left: -10px;
`;

export const EmblaSlide = styled.div`
  padding-left: 10px;
  min-width: 100%;
  position: relative;
`;

export const EmblaInnerSlide = styled.div`
  overflow: hidden;
`;

export const EmblaSlideImg = styled.img`
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  width: auto;
  min-height: 100%;
  min-width: 100%;
  max-width: none;
  transform: translate(-50%, -50%);
`;

export const EmblaViewport = styled.div`
  overflow: hidden;
  width: 100%;
`;

export const EmblaThumb = styled.div`
  width: 100%;
  overflow: hidden;
  max-height: 750px;
`;

export const EmblaContainerThumb = styled.div`
  cursor: default;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const EmblaSlideThumb = styled.div`
  min-width: 20%;
`;

export const EmblaSlideInnerThumb = styled.button`
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid transparent;
  outline: 0;
  margin: 0;
  padding: 0;
  width: 76px;
  height: 95px;
  background-color: transparent;
  position: relative;
  display: block;
  overflow: hidden;

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
    outline: -moz-focusring auto 1px;
    outline: focus-ring auto 1px;
  }
  ${({ isVideo }) =>
    isVideo &&
    css`
      aspect-ratio: 64 / 90;
      position: relative;
      display: inline-block;
      & svg {
        color: black;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `}

  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 1px solid ${Colors["Midnight 40%"]};
    `}
`;

export const EmblaSlideThumbnail = styled.img`
  inset: 0px -10000%;
  margin: auto;
  max-width: none;
  max-height: 95px;
  width: 76px;
`;

export const CarouselContainer = styled.div`
  text-align: left;
  overflow: hidden;
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
`;

export const ImageGallery = styled.div`
  width: -webkit-fill-available;
  width: -moz-available;
  display: flex;
  justify-content: center;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-inline-size: 100%;
  block-size: auto;
  object-fit: contain;

  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    max-width: fit-content;
    height: auto;
    margin: auto;
  }

  @media (min-width: ${Breakpoints.DesktopLarge}) {
    max-width: 600px;
    height: ${({ isQuickview }) => (isQuickview ? "auto" : "750px")};
    padding-bottom: 60px;
  }
`;

export const ArrowButtonNext = styled.div`
  font-size: 0;
  line-height: 0;
  display: block;
  padding: 0;
  cursor: pointer;
  color: transparent;
  transform: rotate(45deg);
  border: 1px solid ${Colors["Black"]};
  border-bottom: none;
  border-left: none;
  height: 20px;
  width: 20px;
  :focus {
    border: 2px solid ${Colors["Black"]};
    border-bottom: none;
    border-left: none;
  }
  align-self: center;
  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    display: ${({ isQuickview }) => (isQuickview ? "block" : "none")};
  }
`;

export const ArrowButtonPrev = styled(ArrowButtonNext)`
  left: -13px;
  transform: rotate(-135deg);
  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    display: ${({ isQuickview }) => (isQuickview ? "block" : "none")};
  }
`;

export const EmblaThumbViewport = styled(EmblaViewport)``;

export const ThumbButtonPrev = styled(ArrowButtonNext)`
  transform: rotate(-45deg);
  border: 2px solid ${Colors["Black"]};
  margin-top: 10px;
  border-bottom: none;
  border-left: none;
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;

export const ThumbButtonNext = styled(ArrowButtonNext)`
  border: 2px solid ${Colors["Black"]};
  border-bottom: none;
  border-left: none;
  transform: rotate(135deg);
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;

export const ThumbCarousel = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const DotWrapper = styled.button`
  background-color: transparent;
  cursor: pointer;
  position: relative;
  padding: 0;
  width: 12px;
  height: 12px;
  margin: 0px 5px;
  border: 0;
  display: flex;
  align-items: center;

  @media (max-width: ${Breakpoints.XSMobileMax}) {
    margin: 0px;
  }
`;

export const DotButton = styled.div`
  width: 12px;
  height: 12px;

  &::before {
    color: ${Colors.Black};
    opacity: 0.25;
    font-size: ${FontSizes.Header};
    line-height: 12px;
    content: "•";
    text-align: center;
  }

  ${({ isSelected }) =>
    isSelected &&
    `
    &::before {
      opacity: 0.75;
    }
  `}
`;

export const EmblaDotNavContainer = styled.div`
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-top: 10px;
  @media (max-width: ${Breakpoints.XSMobileMax}) {
    padding-top: 8px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ isMobile }) => (isMobile ? "330px" : "477px")};
  height: ${({ isMobile }) => (isMobile ? "480px" : "596px")};

  ${StyledSpinner} {
    margin: 0;
  }
`;

export const ArrowWrapper = styled.div`
  visibility: ${({ visibility }) => (visibility ? "visible" : "hidden")};
  cursor: pointer;
  & svg {
    height: 20px;
    width: 20px;
  }
`;

export const ThumbWrapper = styled.div`
  margin-top: 10px;
`;

export const VideoWrapper = styled.div`
  width: 600px;
  height: 750px;

  @media screen and (max-width: ${Breakpoints.DesktopMedium}) {
    width: fit-content;
    height: 100%;
  }

  @media screen and (max-width: ${Breakpoints.IPad}) {
    display: flex;
    max-height: 600px !important;
    height: 100%;
    justify-content: center !important;
  }

  @media screen and (max-width: ${Breakpoints.IPhone11}) {
    padding-top: 5px;
  }
`;

export const VideoEmblaInnerSlide = styled.div`
  place-items: center;
  display: flex;
  justify-content: center;

  @media screen and (max-width: ${Breakpoints.IPad}) {
    height: 100%;
  }
  @media screen and (max-width: ${Breakpoints.IPad}) {
    justify-content: center;
  }

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    justify-content: center;
  }
`;
