import styled from "styled-components";
import { Colors } from "../../constants/constants";

export const PromoContainer = styled.a`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  width: max-content;
  margin-top: 40px;
  color: ${Colors["Dark Primary"]};
`;

export const ImageContainer = styled.div`
  margin-bottom: 16px;
`;

export const Image = styled.img`
  max-width: 313px;
  max-height: 246px;
`;

export const ImageCaption = styled.div``;
