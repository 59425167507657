import styled from "styled-components";
import { Breakpoints, Spacing } from "../../constants/constants";
import { WishlistIconContainer } from "../WishlistHeart/WishlistHeartStyled";

export const ProductNameWrapper = styled.div`
  margin-bottom: ${Spacing.Small}px;
  display: flex;
  justify-content: space-between;
  gap: 24px;

  @media (max-width: ${Breakpoints.XSMobileMax}) {
    margin-bottom: 12px;
  }
  ${WishlistIconContainer} {
    @media (min-width: ${Breakpoints.MDLaptopMin}) {
      > svg {
        width: 34px;
        height: 34px;
      }
    }
  }
`;

export const ProductNameContainer = styled.div`
  max-width: none;

  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    max-width: 420px;
  }
`;
