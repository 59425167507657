import React from "react";
import PropTypes from "prop-types";
import {
  EmblaButtonSVG,
  PrevButtonElement,
  NextButtonElement,
  DotButtonElement
} from "./GenericCarouselStyled";

export const DotButton = ({ selected, onClick }) => (
  <DotButtonElement selected={selected} onClick={onClick} />
);

export const PrevButton = ({ enabled, onClick, arrowType }) => {
  switch (arrowType) {
    case "carrot":
      return (
        <PrevButtonElement
          onClick={onClick}
          disabled={!enabled}
          aria-label="previous slide"
        >
          <EmblaButtonSVG viewBox="137.718 -1.001 366.563 644">
            <path d="M428.36 12.5c16.67-16.67 43.76-16.67 60.42 0 16.67 16.67 16.67 43.76 0 60.42L241.7 320c148.25 148.24 230.61 230.6 247.08 247.08 16.67 16.66 16.67 43.75 0 60.42-16.67 16.66-43.76 16.67-60.42 0-27.72-27.71-249.45-249.37-277.16-277.08a42.308 42.308 0 0 1-12.48-30.34c0-11.1 4.1-22.05 12.48-30.42C206.63 234.23 400.64 40.21 428.36 12.5z" />
          </EmblaButtonSVG>
        </PrevButtonElement>
      );
    case "arrow":
      return (
        <PrevButtonElement
          onClick={onClick}
          disabled={!enabled}
          aria-label="previous slide"
          style={{
            height: "40px",
            width: "40px",
            border: "1px solid #A7B0BA",
            borderRadius: "20px",
            left: "-50px"
          }}
        >
          <div
            style={{
              height: "24px",
              width: "24px"
            }}
          >
            <EmblaButtonSVG
              width="19"
              height="12"
              viewBox="0 0 19 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.64645 0.646447C5.84171 0.451184 6.15829 0.451184 6.35355 0.646447C6.54882 0.841709 6.54882 1.15829 6.35355 1.35355L2.20711 5.5H17.5557C17.8318 5.5 18.0557 5.72386 18.0557 6C18.0557 6.27614 17.8318 6.5 17.5557 6.5H2.20711L6.35355 10.6464C6.54881 10.8417 6.54881 11.1583 6.35355 11.3536C6.15829 11.5488 5.84171 11.5488 5.64645 11.3536L0.646446 6.35355C0.451185 6.15829 0.451185 5.84171 0.646446 5.64645L5.64645 0.646447Z"
                fill="#243953"
              />
            </EmblaButtonSVG>
          </div>
        </PrevButtonElement>
      );
  }
};

export const NextButton = ({ enabled, onClick, arrowType }) => {
  switch (arrowType) {
    case "carrot":
      return (
        <NextButtonElement
          onClick={onClick}
          disabled={!enabled}
          aria-label="next slide"
        >
          <EmblaButtonSVG viewBox="0 0 238.003 238.003">
            <path d="M181.776 107.719L78.705 4.648c-6.198-6.198-16.273-6.198-22.47 0s-6.198 16.273 0 22.47l91.883 91.883-91.883 91.883c-6.198 6.198-6.198 16.273 0 22.47s16.273 6.198 22.47 0l103.071-103.039a15.741 15.741 0 0 0 4.64-11.283c0-4.13-1.526-8.199-4.64-11.313z" />
          </EmblaButtonSVG>
        </NextButtonElement>
      );
    case "arrow":
      return (
        <NextButtonElement
          onClick={onClick}
          disabled={!enabled}
          aria-label="previous slide"
          style={{
            height: "40px",
            width: "40px",
            border: "1px solid #A7B0BA",
            borderRadius: "20px",
            right: "-50px"
          }}
        >
          <div
            style={{
              height: "24px",
              width: "24px"
            }}
          >
            <EmblaButtonSVG
              width="19"
              height="12"
              viewBox="0 0 19 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.9092 0.646447C12.714 0.451184 12.3974 0.451184 12.2021 0.646447C12.0068 0.841709 12.0068 1.15829 12.2021 1.35355L16.3486 5.5H1C0.723858 5.5 0.5 5.72386 0.5 6C0.5 6.27614 0.723858 6.5 1 6.5H16.3486L12.2021 10.6464C12.0068 10.8417 12.0068 11.1583 12.2021 11.3536C12.3974 11.5488 12.714 11.5488 12.9092 11.3536L17.9092 6.35355C18.1045 6.15829 18.1045 5.84171 17.9092 5.64645L12.9092 0.646447Z"
                fill="#243953"
              />
            </EmblaButtonSVG>
          </div>
        </NextButtonElement>
      );
  }
};

PrevButton.propTypes = {
  enabled: PropTypes.bool,
  onClick: PropTypes.func,
  arrowType: PropTypes.string
};

NextButton.propTypes = {
  enabled: PropTypes.bool,
  onClick: PropTypes.func,
  arrowType: PropTypes.string
};

DotButton.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func
};
