import { useBreakpoint } from "../../Breakpoints";

const GetPosition = (position) => {
  const breakpoints = useBreakpoint();
  const { desktopPosition, tabletPosition, mobilePosition } = position || {};
  const isMobile = breakpoints["DesktopMobile"];
  const isTablet = breakpoints["ScreenWidth"];

  if (isMobile) {
    return mobilePosition;
  } else if (isTablet) {
    return tabletPosition;
  } else {
    return desktopPosition;
  }
};

export default GetPosition;
