import React, { useState } from "react";
import PropTypes from "prop-types";
import useComponentTheme from "../Theme/useComponentTheme";
import CTA from "../CTA";
import Text from "../Text";
import {
  ContentContainer,
  HeadlinesContainer,
  BannerImageWrapper,
  BannerImage,
  ContentWrapper,
  BannerLink,
  BannerContainer,
  CtaContainer,
  OverlayImage,
  HeadlineTextContainer,
  BreakpointImageWrapper
} from "./BannerStyled";
import BannerTheme from "./BannerTheme";
import { useBreakpoint } from "../Breakpoints";
import getAssetUrl from "../../utils/getAssetUrl";
import getTextAlign from "../../utils/getTextAlign";
import BreakpointImage from "../BreakpointImage";
import useUser from "../../hooks/useUser";
import RichText from "../RichText";

const Banner = ({
  internalLinkID,
  addBorder,
  bannerBackgroundImage,
  bannerHeadlinePositioning,
  HeadlinePosition,
  headingList,
  DOOHeadingList,
  ctaList,
  DOOCtaList,
  bannerHeightSize,
  bannerBackgroundColor,
  backgroundImageAltText,
  bannerURL,
  overlayBannerImages,
  breakpointImage,
  theme = BannerTheme,
  richTextHeadingList,
  richTextDOOHeadingList,
  mobileBannerHeightSize,
  contentBoxStyle,
  contentItemIndex
}) => {
  const imageSrc = bannerBackgroundImage && getAssetUrl(bannerBackgroundImage);
  const [imageRatio, setImageRatio] = useState();
  const {
    breakTextOnMobile,
    xCoordinate,
    xCoordinateMobile,
    yCoordinate,
    yCoordinateMobile,
    centerXDesktop,
    centerXTablet,
    centerXMobile,
    centerYDesktop,
    centerYTablet,
    centerYMobile,
    textWidth,
    textWidthForMobile,
    textWidthForTablet
  } = bannerHeadlinePositioning || {};
  const {
    desktopOrientation,
    mobileOrientation,
    desktopHorizontalAlignment,
    mobileHorizontalAlignment
  } = HeadlinePosition || {};
  const { user: { data: { isDOO } = {} } = {} } = useUser();

  const bannerTheme = useComponentTheme({ theme });
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints["DesktopMobile"];

  const bannerImagesList =
    isMobile && overlayBannerImages
      ? [overlayBannerImages[0]]
      : overlayBannerImages;

  const mobileTextAlign = getTextAlign(mobileHorizontalAlignment);
  const desktopTextAlign = getTextAlign(desktopHorizontalAlignment);

  let headings =
    isDOO && DOOHeadingList?.content[0]?.copy
      ? DOOHeadingList?.content
      : headingList;

  let richTextHeadings =
    isDOO && richTextDOOHeadingList?.content[0]?.richText
      ? richTextDOOHeadingList?.content
      : richTextHeadingList;

  let ctas =
    isDOO && DOOCtaList?.content[0]?.ctaText ? DOOCtaList?.content : ctaList;

  return (
    <BannerContainer
      bannerHeightSize={bannerHeightSize}
      mobileBannerHeightSize={mobileBannerHeightSize}
      addBorder={addBorder}
      id={internalLinkID ? internalLinkID : "defaultBanner"}
      bannerBackgroundColor={bannerBackgroundColor}
    >
      <BannerLink href={bannerURL} as={`${bannerURL ? "a" : "div"}`}>
        <ContentWrapper
          addBorder={addBorder}
          bannerBackgroundColor={bannerBackgroundColor}
          bannerHeightSize={bannerHeightSize}
          mobileBannerHeightSize={mobileBannerHeightSize}
          centerXDesktop={centerXDesktop}
          centerXTablet={centerXTablet}
          centerXMobile={centerXMobile}
          centerYDesktop={centerYDesktop}
          centerYTablet={centerYTablet}
          centerYMobile={centerYMobile}
        >
          {bannerBackgroundImage && (
            <BannerImageWrapper
              bannerHeightSize={bannerHeightSize}
              mobileBannerHeightSize={mobileBannerHeightSize}
            >
              {imageSrc && (
                <BannerImage
                  src={imageSrc}
                  alt={backgroundImageAltText}
                  layout={bannerHeightSize ? "fill" : "responsive"}
                  imageData={bannerBackgroundImage}
                />
              )}
              {breakpointImage && (
                <BreakpointImageWrapper
                  aspectRatio={
                    breakpointImage &&
                    imageRatio &&
                    `${imageRatio?.w} / ${imageRatio?.h}`
                  }
                >
                  <BreakpointImage
                    {...breakpointImage}
                    setImageRatio={setImageRatio}
                    altText={backgroundImageAltText}
                    isBanner={true}
                    contentItemIndex={contentItemIndex}
                  />
                </BreakpointImageWrapper>
              )}
              {bannerImagesList?.map((overlayImage, idx) => {
                const {
                  yPosition,
                  xPosition,
                  height,
                  mobileHeight,
                  image,
                  mirrorImage
                } = overlayImage;
                const imageSrc = getAssetUrl(image);
                const key = `overlay-image-${idx}`;
                return (
                  <OverlayImage
                    key={key}
                    src={imageSrc}
                    bottom={yPosition}
                    right={xPosition}
                    height={height}
                    mobileHeight={mobileHeight}
                    position={idx == 0 ? "relative" : "absolute"}
                    mirrorImage={mirrorImage}
                  />
                );
              })}
            </BannerImageWrapper>
          )}
          <ContentContainer
            xCoordinate={xCoordinate}
            xCoordinateMobile={xCoordinateMobile}
            yCoordinate={yCoordinate}
            yCoordinateMobile={yCoordinateMobile}
            centerXDesktop={centerXDesktop}
            centerXTablet={centerXTablet}
            centerXMobile={centerXMobile}
            centerYDesktop={centerYDesktop}
            centerYTablet={centerYTablet}
            centerYMobile={centerYMobile}
            backgroundColor={contentBoxStyle?.backgroundColor}
            paddingDesktop={contentBoxStyle?.paddingDesktop}
            paddingMobile={contentBoxStyle?.paddingMobile}
            paddingTablet={contentBoxStyle?.paddingTablet}
          >
            <HeadlinesContainer
              desktopOrientation={desktopOrientation}
              mobileOrientation={mobileOrientation}
              breakTextOnMobile={breakTextOnMobile}
              mobileHorizontalAlignment={mobileTextAlign}
              desktopHorizontalAlignment={desktopTextAlign}
            >
              {headings?.map((heading, idx) => {
                const key = `banner-headline-${idx}`;
                return (
                  <HeadlineTextContainer
                    textWidth={textWidth}
                    textWidthForMobile={textWidthForMobile}
                    textWidthForTablet={textWidthForTablet}
                    fontSize={heading?.fontSize}
                    scaleFontSize={heading?.scaleFontSize}
                    fontSizeTablet={heading?.fontSizeTablet}
                    fontSizeDesktop={heading?.fontSizeDesktop}
                    bottomMargin={heading?.bottomMargin}
                    scaleBottomMargin={heading?.scaleBottomMargin}
                    scaledBottomMarginMobile={heading?.scaledBottomMarginMobile}
                    scaledBottomMarginTablet={heading?.scaledBottomMarginTablet}
                    scaledBottomMarginDesktop={
                      heading?.scaledBottomMarginDesktop
                    }
                    key={`${key}-container`}
                  >
                    <Text
                      key={key}
                      themeName={bannerTheme?.headline?.themeName}
                      modular={heading?.modularScaleSize}
                      {...heading}
                    />
                  </HeadlineTextContainer>
                );
              })}
              {richTextHeadings?.map((heading, idx) => {
                const key = `banner-headline-${idx}`;
                bannerURL &&
                  heading?.richText?.map((item) => {
                    if (item?.data?.src && item?.data?.url) {
                      delete item?.data["url"];
                    }
                  });
                return (
                  <HeadlineTextContainer
                    textWidth={textWidth}
                    textWidthForMobile={textWidthForMobile}
                    textWidthForTablet={textWidthForTablet}
                    bottomMargin={heading?.bottomMargin}
                    key={`${key}-container`}
                  >
                    <RichText
                      key={key}
                      themeName={bannerTheme?.headline?.themeName}
                      modular={heading?.modularScaleSize}
                      {...heading}
                    />
                  </HeadlineTextContainer>
                );
              })}
            </HeadlinesContainer>
            <CtaContainer
              textWidth={textWidth}
              textWidthForMobile={textWidthForMobile}
              textWidthForTablet={textWidthForTablet}
              mobileHorizontalAlignment={mobileHorizontalAlignment}
              desktopHorizontalAlignment={desktopHorizontalAlignment}
            >
              {ctas &&
                ctas?.map((ctaItem, idx) => {
                  const { cta } = ctaItem || {};
                  const key = `banner-cta-${idx}`;
                  return (
                    cta?.ctaText && (
                      <CTA
                        key={key}
                        cta={cta}
                        themeName={bannerTheme?.actionCta?.themeName}
                        isMockCTA={bannerURL ? true : false}
                        {...cta}
                      />
                    )
                  );
                })}
            </CtaContainer>
          </ContentContainer>
        </ContentWrapper>
      </BannerLink>
    </BannerContainer>
  );
};

export default Banner;

Banner.propTypes = {
  internalLinkID: PropTypes.string,
  addBorder: PropTypes.bool,
  bannerBackgroundImage: PropTypes.object,
  bannerHeadlinePositioning: PropTypes.object,
  HeadlinePosition: PropTypes.object,
  headingList: PropTypes.array,
  DOOHeadingList: PropTypes.object,
  ctaList: PropTypes.array,
  DOOCtaList: PropTypes.object,
  sizeDesktop: PropTypes.string,
  sizeMobile: PropTypes.string,
  bannerHeightSize: PropTypes.number,
  mobileBannerHeightSize: PropTypes.number,
  bannerBackgroundColor: PropTypes.string,
  backgroundImageAltText: PropTypes.string,
  bannerURL: PropTypes.string,
  mobileCta: PropTypes.object,
  overlayBannerImages: PropTypes.array,
  breakpointImage: PropTypes.object,
  theme: PropTypes.object,
  richTextHeadingList: PropTypes.array,
  richTextDOOHeadingList: PropTypes.array,
  contentItemIndex: PropTypes.number
};
