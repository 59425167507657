import styled from "styled-components";
import {
  Breakpoints,
  Colors,
  FontSizes,
  LetterSpacing,
  Typography
} from "../../../constants/constants";

export const InspirationLink = styled.a`
  display: none;
  text-decoration: none;
  color: ${Colors["Dark Primary"]};
  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    display: block;
  }

  :hover {
    opacity: 50%;
  }
`;

export const InspirationContainer = styled.div`
  display: flex;
`;

export const InspirationText = styled.div`
  display: flex;
  margin-left: 9px;
  font-size: ${FontSizes.Small};
  font-family: ${Typography.ProximaNova};
  align-items: center;
  letter-spacing: ${LetterSpacing.Regular};
`;
