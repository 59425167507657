import { Colors } from "@serenaandlily/constants/constants";

const defaultTheme = {
  listedPrice: {
    themeName: "headline5",
    marginBottom: 5
  },
  salesPrice: {
    themeName: "headline5"
  },
  tradePrice: {
    themeName: "headline5",
    fontColor: Colors["Light Sky"],
    fontWeight: "600",
    marginBottom: 5
  }
};

export default defaultTheme;
