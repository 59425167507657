/* global Sailthru */

import isEmpty from "../isEmptyObject/isEmptyObject";

export const USER_SIGNUP_EVENT = "userSignUp";

const isSailthruRunning = () => typeof Sailthru !== "undefined";

const buildListsObject = (data) => ({
  [data.list ? data.list : "Master"]: 1
});

const mapVariationIds = (data) => {
  const incomeIds = Object.keys(data);
  const mappedIds = {};

  for (let i = 0; i < Math.min(incomeIds.length, 5); i++) {
    const incomeId = incomeIds[i];
    const variationId = data[incomeId]?.variation?.id;

    if (variationId) {
      mappedIds[i === 0 ? 'sourceVariationId' : `${i + 1}_sourceVariationId`] = variationId;
    }
  }

  return mappedIds;
};

const buildVarsObject = (data) => {
  const { sourceType, sourceVariationId } = getOptimizelyData(data);

  return {
    ...(data.firstName && { first_name: data.firstName }),
    ...(data.weeklyNewsletter !== null && {
      weekly_newsletter: data.weeklyNewsletter
    }),
    ...(data.lastName && { last_name: data.lastName }),
    ...(data.birthDate && { birth_date: data.birthDate }),
    ...(data.birthdayMonth && { birth_date: `0000-${data.birthdayMonth}-01` }),
    p_ga_campaign: data?.utm_campaign ?? "(not%20set)",
    p_ga_medium: data?.utm_medium ?? "cpc",
    p_ga_source: data?.utm_source ?? "google",
    ...(sourceType && { sourceType }),
    ...(sourceVariationId &&
      !isEmpty(sourceVariationId) && { sourceVariationId })
  };
};

const getOptimizelyData = (data) => {
  if (
    data?.eventName !== USER_SIGNUP_EVENT ||
    typeof window.optimizely === "undefined"
  ) {
    return { sourceType: null, sourceVariationId: null };
  }

  const optimizeState = window.optimizely.get("state");
  const optimizelytype = optimizeState.getExperimentStates({ isActive: true });
  const sourceVariationId = mapVariationIds(optimizelytype);
  const sourceType = Object.values(optimizelytype).length ? "optimize" : null;

  return { sourceType, sourceVariationId };
};

export const fetchSailthruEvent = (data) => {
  if (!isSailthruRunning()) {
    return;
  }

  return new Promise((resolve, reject) => {
    Sailthru.integration(data.eventName, {
      id: data.email,
      lists: buildListsObject(data),
      vars: buildVarsObject(data),
      onSuccess: (response) => resolve(response),
      onError: (err) => reject(new Error(err)),
      source: data.source ?? "web"
    });
  });
};

export default fetchSailthruEvent;
