import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const AnalyticsData = ({
  id,
  className,
  requireAll,
  emitOnDataKey,
  emitOnDataDetail = {},
  data
}) => {
  const [isNull, setIsNull] = useState(true);

  useEffect(() => {
    setIsNull(
      !globalThis?.document ||
        (requireAll && Object.values(data)?.some((v) => v === undefined))
    );
  }, [requireAll, setIsNull, data]);

  useEffect(() => {
    if (emitOnDataKey && !isNull) {
      globalThis?.document?.body?.dispatchEvent(
        new CustomEvent(emitOnDataKey, { detail: emitOnDataDetail })
      );
    }
  }, [emitOnDataKey, isNull]);

  if (isNull) return <React.Fragment />;
  else {
    return (
      <script
        id={id}
        className={className}
        dangerouslySetInnerHTML={{
          __html: data ? JSON.stringify(data) : ""
        }}
      />
    );
  }
};
export default AnalyticsData;

AnalyticsData.propTypes = {
  id: PropTypes.string,
  requireAll: PropTypes.bool,
  data: PropTypes.object,
  emitOnDataKey: PropTypes.string,
  emitOnDataDetail: PropTypes.object,
  className: PropTypes.string
};
