import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  ColorTilesContainer,
  ColorTitle,
  Color,
  ColorTilesFieldset,
  ColorTilesWrapper,
  TileImage,
  DoubleTileContainer
} from "./ColorTilesStyled";
import { getVariationArray } from "../../utils";
import { useProductContext } from "../../contexts/ProductContext";
import uuid from "react-uuid";
import TileInput from "../FormComponents/TileInput";
import Text from "../Text";
import useComponentTheme from "../Theme/useComponentTheme";
import defaultTheme from "./ColorTilesTheme";
import getPossibleProductId from "../../utils/getPossibleProductId";
import getCurrentVariationChildIDs from "../../utils/getCurrentVariationChildIDs";

function getLabelName(labelColor, currentColor) {
  if (labelColor !== "") return labelColor;
  return currentColor;
}

const ColorTiles = ({
  name,
  isCuProduct,
  netsuiteId,
  currentVariations,
  setCurrentVariations,
  childProducts,
  options,
  mainProductColor,
  setUnavailableTileSelected,
  setChangeToNextAvailable,
  setTileClicked,
  theme = defaultTheme,
  pdpHasErrorMessage = false
}) => {
  const tileId = useMemo(() => uuid(), []);
  const [labelColor, setLabelColor] = useState("");
  const currentColor = currentVariations[name] || currentVariations["Color"];
  const fieldName = name;
  const { availability } = useProductContext();

  const handleChange = (event, value) => {
    if (isCuProduct) {
      const selectedColor = options.find((option) => option.value === value);
      setCurrentVariations((prev) => ({
        ...prev,
        [name]: {
          name: selectedColor.value,
          description: selectedColor?.rgbColor,
          optionName: name
        }
      }));
    } else {
      const selectedColor = options.find((option) => option.name === value);
      setCurrentVariations((prev) => ({
        ...prev,
        Color: selectedColor
      }));
    }
  };

  const handleSetLabelColor = (value) => {
    setLabelColor(value);
  };

  useEffect(() => {
    if (mainProductColor) {
      const hasMainProductColor = options?.some(
        (option) => option.name === mainProductColor.value
      );
      if (!hasMainProductColor) return;

      const colorDetails = {
        name: mainProductColor.value,
        description: mainProductColor.value,
        __typename: "VariationOption"
      };

      const { currentChildPID } = getCurrentVariationChildIDs(
        {
          ...currentVariations,
          Color: colorDetails
        },
        childProducts
      );
      if (!availability?.[currentChildPID]) return;

      setUnavailableTileSelected?.(null);

      setCurrentVariations((prev) => ({
        ...prev,
        Color: colorDetails
      }));
    }
  }, [mainProductColor, availability]);

  const colorTileTheme = useComponentTheme({ theme });

  const possibleProductId = useMemo(() => {
    return getPossibleProductId(childProducts, currentVariations, isCuProduct);
  }, [childProducts, currentVariations, isCuProduct]);

  if (isCuProduct) {
    return (
      <ColorTilesContainer pdpHasErrorMessage={pdpHasErrorMessage}>
        <ColorTilesFieldset name={fieldName}>
          <ColorTitle>
            <Text
              copy={`${name}`}
              themeName={colorTileTheme?.variationLabel?.themeName}
            />
            <Color>
              <Text
                copy={getLabelName(labelColor, currentColor?.name)}
                themeName={colorTileTheme?.variationInfo?.themeName}
                {...colorTileTheme?.variationInfo}
              />
            </Color>
          </ColorTitle>
          <ColorTilesWrapper>
            {options?.map((tile, ind) => {
              let { rgbColor, value } = tile;
              if (rgbColor.includes("/")) rgbColor = rgbColor.split("/");
              const activeTitle = value === currentColor?.name;
              const colorId = `color-${name}-${ind}-${tileId}`;
              const { ...otherVariations } = currentVariations;
              const variationArr = getVariationArray(otherVariations);
              variationArr.push(name);
              const available = true;
              return (
                <TileInput
                  key={`color-tile-${value}`}
                  name={fieldName}
                  id={colorId}
                  value={value}
                  checked={activeTitle}
                  onChange={handleChange}
                  disabled={!available}
                  onMouseOver={() => handleSetLabelColor(tile.value)}
                  onMouseLeave={() => handleSetLabelColor("")}
                  title={value}
                >
                  {typeof rgbColor === "string" ? (
                    <TileImage rgbColor={rgbColor} />
                  ) : (
                    <DoubleTileContainer>
                      <TileImage rgbColor={rgbColor[0].slice(1)} />
                      <TileImage rgbColor={rgbColor[1].slice(0, -1)} />
                    </DoubleTileContainer>
                  )}
                </TileInput>
              );
            })}
          </ColorTilesWrapper>
        </ColorTilesFieldset>
      </ColorTilesContainer>
    );
  }

  return (
    <ColorTilesContainer pdpHasErrorMessage={pdpHasErrorMessage}>
      <ColorTilesFieldset name={fieldName}>
        <ColorTitle>
          <Text
            copy={`Color`}
            themeName={colorTileTheme?.variationLabel?.themeName}
          />
          <Color>
            <Text
              copy={currentColor?.description}
              themeName={colorTileTheme?.variationInfo?.themeName}
              {...colorTileTheme?.variationInfo}
            />
          </Color>
        </ColorTitle>
        <ColorTilesWrapper>
          {options?.map((tile, ind) => {
            const { description, name } = tile;
            const sanitizedColorName = description
              .replaceAll("/", "_")
              .replaceAll(" ", "")
              .replaceAll("'", "");
            const activeTitle = description === currentColor?.description;
            const colorId = `color-${name}-${ind}-${tileId}`;
            const { Color: _, ...otherVariations } = currentVariations;
            const variationArr = getVariationArray(otherVariations);
            variationArr.push(name);

            let tileProductId = null;
            for (const childProduct of childProducts) {
              let hasColor = false;
              for (const variant of childProduct.variants) {
                if (variant?.value === description) {
                  hasColor = true;
                }
              }

              if (hasColor && possibleProductId.includes(childProduct?.id)) {
                tileProductId = childProduct?.id;
              }
            }

            const available =
              Object.values(availability).length === 0
                ? true
                : availability[tileProductId];

            const swatchURL = `swatch-${netsuiteId}-${sanitizedColorName}`;
            const imageSrc = `https://cdn.media.amplience.net/i/serenaandlily/${swatchURL}?fmt=auto&img404=noimagesmall&w=40&h=40`;
            return (
              <TileInput
                key={`color-tile-${ind}`}
                name={fieldName}
                id={colorId}
                value={name}
                checked={activeTitle}
                onChange={handleChange}
                disabled={!available}
                category={"COLOR"}
                setUnavailableTileSelected={setUnavailableTileSelected}
                setChangeToNextAvailable={setChangeToNextAvailable}
                setTileClicked={setTileClicked}
                title={description}
              >
                <TileImage imageSrc={imageSrc} />
              </TileInput>
            );
          })}
        </ColorTilesWrapper>
      </ColorTilesFieldset>
    </ColorTilesContainer>
  );
};

export default ColorTiles;

ColorTiles.propTypes = {
  name: PropTypes.string,
  sku: PropTypes.string,
  netsuiteId: PropTypes.string,
  options: PropTypes.array,
  childProducts: PropTypes.array,
  currentVariations: PropTypes.object,
  setCurrentVariations: PropTypes.func,
  mainProductColor: PropTypes.object,
  id: PropTypes.string,
  setUnavailableTileSelected: PropTypes.func,
  setChangeToNextAvailable: PropTypes.func,
  setTileClicked: PropTypes.func,
  theme: PropTypes.object,
  isCuProduct: PropTypes.bool,
  pdpHasErrorMessage: PropTypes.bool
};
