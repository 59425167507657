import styled from "styled-components";
import { Colors } from "../../constants/constants";

export const SubNavContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px 15px 28px;
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  background-color: ${Colors.White};
  border-top: 1px solid ${Colors["Light Primary"]};
  box-shadow: 6px 8px 5px -6px rgb(66 66 66 / 50%);
  outline: none;
`;
