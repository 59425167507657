import React from "react";

const DescriptionCloseIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
    >
      <g id="close">
        <path
          id="Vector"
          d="M6 6L18 18M18 6L6 18"
          stroke="#243953"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

export default DescriptionCloseIcon;
