import styled from "styled-components";
import {
  Colors,
  FontSizes,
  Typography,
  LetterSpacing
} from "../../constants/constants";

export const AccessibilityButton = styled.button`
  opacity: 0;
  position: fixed;
  top: 68px;
  pointer-events: none;
  &:focus {
    cursor: pointer;
    opacity: 100;
    background-color: ${({ modeEnabled }) =>
      modeEnabled ? Colors["Border Gray"] : Colors.Light};
    border: 1px solid ${Colors["Border Gray"]};
    border-radius: 6px;
    display: flex;
    padding: 10px 16px;
    z-index: 9999;
    outline: ${({ modeEnabled }) => (modeEnabled ? "" : 0)};
  }
`;

export const AccessibilityIcon = styled.div`
  background: url(https://serenaandlily.a.bigcontent.io/v1/static/KeyboardIcon)
    100% no-repeat;
  width: 30px;
  height: 14px;
  margin-right: 16px;
`;

export const AccessibilityMessage = styled.span`
  color: ${Colors["Dark Primary"]};
  font-family: ${Typography.ProximaNova};
  letter-spacing: ${LetterSpacing.XSmall};
  font-size: ${FontSizes.Base};
  text-align: center;
`;
