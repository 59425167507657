/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  ProductPriceWrapper,
  ListedPrice,
  SalesPrice,
  PriceLoaderWrapper,
  DiscountContainer
} from "./ProductPriceStyled";
import Text from "../Text";
import useComponentTheme from "../Theme/useComponentTheme";
import defaultTheme from "./ProductPriceTheme";
import { PointLoader } from "../Icons/Variants";
import { RETAIL_PRICE_LABEL, TRADE_PRICE_LABEL } from "../../constants/strings";
import useUser from "../../hooks/useUser";
import getPriceDiscount from "../../utils/getPriceDiscount/getPriceDiscount";
import { useDiscountContext } from "../../contexts/ProductDiscountContext";
import removeZeroDecimals from "@utils/removeZeroDecimals/removeZeroDecimals";

// eslint-disable-next-line complexity
const ProductPrice = ({
  formattedListPrice,
  formattedSalePrice,
  formattedDsoDesigner,
  salePriceColor,
  theme = defaultTheme,
  removeDecimalsIfZero = false,
  isLoading = false
}) => {
  const {
    discountText,
    uptoDiscountText,
    colorDiscountText,
    colorDiscountValue,
    enableShowDiscount,
    discountThreshold
  } = useDiscountContext();
  const productPriceTheme = useComponentTheme({ theme });
  const { user: { data: { isDOO } = {} } = {} } = useUser();
  const [currentDiscount, setCurrentDiscount] = useState({});
  const hasTradePrice = isDOO && formattedDsoDesigner;
  const isDiscount = useMemo(
    () =>
      currentDiscount?.value &&
      enableShowDiscount === "true" &&
      currentDiscount?.value >= discountThreshold,
    [currentDiscount, discountThreshold, enableShowDiscount]
  );

  useEffect(() => {
    if (formattedSalePrice) {
      const priceDiscount = getPriceDiscount({
        wasPriceRange: { min: formattedListPrice },
        nowPriceRange: { min: formattedDsoDesigner || formattedSalePrice }
      });
      if (priceDiscount?.value > 0) setCurrentDiscount(priceDiscount);
      return;
    }
    setCurrentDiscount(0);
  }, [formattedListPrice, formattedSalePrice]);

  if (!formattedListPrice && isLoading)
    return (
      <PriceLoaderWrapper>
        <PointLoader />
      </PriceLoaderWrapper>
    );

  const displayListPrice = removeDecimalsIfZero
    ? removeZeroDecimals({ price: formattedListPrice })
    : formattedListPrice;
  const displaySalePrice = removeDecimalsIfZero
    ? removeZeroDecimals({ price: formattedSalePrice })
    : formattedSalePrice;
  const displayDsoDesigner = removeDecimalsIfZero
    ? removeZeroDecimals({ price: formattedDsoDesigner })
    : formattedDsoDesigner;
  const displayDiscountValue =
    removeDecimalsIfZero && currentDiscount?.value
      ? removeZeroDecimals({ price: `${currentDiscount.value}` })
      : `${currentDiscount?.value}`;

  const discountFinalText = currentDiscount?.isUpTo
    ? uptoDiscountText
    : discountText;
  return (
    <ProductPriceWrapper hasDesignerPrice={hasTradePrice}>
      {hasTradePrice ? (
        <React.Fragment>
          <ListedPrice>
            <Text
              copy={`${displayListPrice} ${RETAIL_PRICE_LABEL}`}
              {...productPriceTheme?.listedPrice}
            />
          </ListedPrice>
          <ListedPrice isDiscount={isDiscount}>
            <Text
              copy={`${displayDsoDesigner} ${TRADE_PRICE_LABEL}`}
              {...productPriceTheme?.tradePrice}
            />
            {isDiscount && (
              <DiscountContainer noMarginLeft>
                <Text
                  copy={`(${discountFinalText}`}
                  themeName={productPriceTheme?.salesPrice?.themeName}
                  {...productPriceTheme?.salesPrice?.overrides}
                  fontColor={colorDiscountText}
                />
                <Text
                  copy={`${displayDiscountValue}%`}
                  themeName={productPriceTheme?.salesPrice?.themeName}
                  {...productPriceTheme?.salesPrice?.overrides}
                  fontColor={colorDiscountValue}
                />
                <Text
                  copy={`)`}
                  themeName={productPriceTheme?.salesPrice?.themeName}
                  {...productPriceTheme?.salesPrice?.overrides}
                  fontColor={colorDiscountText}
                />
              </DiscountContainer>
            )}
          </ListedPrice>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ListedPrice hasSalesPrice={formattedSalePrice}>
            <Text
              copy={displayListPrice}
              themeName={productPriceTheme?.listedPrice?.themeName}
              {...productPriceTheme?.listedPrice?.overrides}
              fontColor={formattedSalePrice ? "#667487" : ""}
            />
          </ListedPrice>
          {formattedSalePrice && (
            <SalesPrice>
              <Text
                copy={displaySalePrice}
                themeName={productPriceTheme?.salesPrice?.themeName}
                {...productPriceTheme?.salesPrice?.overrides}
                fontColor={salePriceColor ? salePriceColor : "#970013"}
              />
              {isDiscount && (
                <DiscountContainer>
                  <Text
                    copy={`(${discountFinalText}`}
                    themeName={productPriceTheme?.salesPrice?.themeName}
                    {...productPriceTheme?.salesPrice?.overrides}
                    fontColor={colorDiscountText}
                  />
                  <Text
                    copy={`${displayDiscountValue}%`}
                    themeName={productPriceTheme?.salesPrice?.themeName}
                    {...productPriceTheme?.salesPrice?.overrides}
                    fontColor={colorDiscountValue}
                  />
                  <Text
                    copy={`)`}
                    themeName={productPriceTheme?.salesPrice?.themeName}
                    {...productPriceTheme?.salesPrice?.overrides}
                    fontColor={colorDiscountText}
                  />
                </DiscountContainer>
              )}
            </SalesPrice>
          )}
        </React.Fragment>
      )}
    </ProductPriceWrapper>
  );
};

export default ProductPrice;

ProductPrice.propTypes = {
  formattedListPrice: PropTypes.string,
  salePriceColor: PropTypes.string,
  formattedSalePrice: PropTypes.string,
  formattedDsoDesigner: PropTypes.string,
  theme: PropTypes.object,
  isLoading: PropTypes.bool,
  removeDecimalsIfZero: PropTypes.bool
};
