import React from "react";
import PropTypes from "prop-types";
import { AccordionMenuWrapper } from "./AccordionMenuStyled";
import AccordionMenuItem from "./AccordionMenuItem";
import useComponentTheme from "../Theme/useComponentTheme";
import { useBreakpoint } from "../Breakpoints";

const AccordionMenu = ({
  slideOutMenu,
  hoverColor,
  selectedCategories,
  onClickFinalElement,
  setSelectedCategories
}) => {
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints["DesktopMobile"];
  const { categorySection } = slideOutMenu || {};
  const theme = isMobile
    ? slideOutMenu?.mobileCategoriesTheme || slideOutMenu?.categoriesTheme || {}
    : slideOutMenu?.categoriesTheme || {};
  const slideOutMenuTheme = useComponentTheme({ theme });

  return (
    <AccordionMenuWrapper>
      {categorySection &&
        categorySection?.map((section, idx) => {
          const { category, flyoutCategories } = section || {};
          const key = `accordion-menu-item-${idx}`;
          return (
            <AccordionMenuItem
              key={key}
              menuTitle={category}
              menuContent={flyoutCategories}
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
              hoverColor={hoverColor}
              categoriesTheme={slideOutMenuTheme}
              level={0}
              onClickFinalElement={onClickFinalElement}
            />
          );
        })}
    </AccordionMenuWrapper>
  );
};

export default AccordionMenu;

AccordionMenu.propTypes = {
  slideOutMenu: PropTypes.object,
  hoverColor: PropTypes.string,
  categoriesTheme: PropTypes.object,
  selectedCategories: PropTypes.object,
  setSelectedCategories: PropTypes.func,
  onClickMenuItemTile: PropTypes.func
};
