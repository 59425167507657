const defaultTheme = {
  headlines: {
    themeName: "headline3"
  },
  richText: {
    themeName: "text"
  },
  ctaList: {
    themeName: "Primary Button"
  }
};

export default defaultTheme;
