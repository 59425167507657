import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VideoPlayer, Video } from "./VideoPlayerStyled";
import { VideoPlayerWrapper } from "./VideoJSStyled";

const VideoJS = ({
  options,
  ccUrl,
  onReady,
  showVideoOnMobile,
  videoDescription,
  videoAspectRatio,
  isOnPdp = false
}) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);

  React.useEffect(() => {
    const getPlayer = async () => {
      if (!playerRef.current) {
        const videoElement = videoRef.current;
        if (!videoElement) return;
        const videojs = (await import("video.js")).default;

        const player = (playerRef.current = videojs(
          videoElement,
          options,
          () => {
            onReady && onReady(player);
          }
        ));
        videoDescription &&
          player.el().setAttribute("aria-label", videoDescription);
      } else {
        if (
          !options?.sources?.[0]?.src ||
          playerRef.current.src() === options.sources[0].src
        )
          return;
        playerRef.current.src(options.sources[0]);
      }
    };
    getPlayer();
  }, [options, videoRef, onReady]);

  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    const player = playerRef.current;

    const handleInteraction = () => {
      setInitialLoad(false);
    };

    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener("mouseenter", handleInteraction);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("mouseenter", handleInteraction);
      }
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <VideoPlayerWrapper
      showVideoOnMobile={showVideoOnMobile}
      initialLoad={initialLoad}
    >
      <VideoPlayer data-vjs-player={true}>
        <Video
          className="video-js"
          ref={videoRef}
          isOnPdp={isOnPdp}
          videoAspectRatio={videoAspectRatio}
        >
          {ccUrl && (
            <track kind="captions" srcLang="en" label="English" src={ccUrl} />
          )}
        </Video>
      </VideoPlayer>
    </VideoPlayerWrapper>
  );
};

export default VideoJS;

VideoJS.propTypes = {
  options: PropTypes.object,
  ccUrl: PropTypes.string,
  onReady: PropTypes.func,
  showVideoOnMobile: PropTypes.bool,
  videoDescription: PropTypes.string
};
