const {
  ImageContainer
} = require("@serenaandlily/components/FabricPanel/FabricPanelStyled");
const {
  default: SwatchImage
} = require("@serenaandlily/components/SwatchImage");

const renderFabrics = ({
  fabrics,
  currentFabricData,
  isDesktop,
  isMobile,
  isTablet,
  temporaryFabricMobile,
  selectedMadeToOrderSwatches,
  selectedQuickshipSwatches,
  handleSelectFabric,
  handleApplyFabric,
  handleToggleSwatch,
  handleHoverFabric,
  handleMouseLeaveFabric,
  setSelectedSwatch,
  isLoadingTemporaryProductMobile,
  multipleSelection = false
}) => {
  return fabrics.map((fabric, fabricIdx) => {
    const { name, swatchProduct, imageSet, colorName } = fabric;
    const imageId = swatchProduct?.id;
    const currentSwatch = currentFabricData?.selectedSwatch;

    let isSelected =
      !multipleSelection && currentSwatch?.swatchProduct?.id === imageId;

    if (
      temporaryFabricMobile?.selectedSwatch &&
      isMobile &&
      !isDesktop &&
      !isTablet
    ) {
      isSelected =
        temporaryFabricMobile?.selectedSwatch?.swatchProduct?.id === imageId;
    }

    const isSelectedSwatch =
      multipleSelection &&
      (selectedMadeToOrderSwatches?.includes(name) ||
        selectedQuickshipSwatches?.includes(name))
        ? fabric
        : null;

    const handleClick = (selectedFabric, e) => {
      if (multipleSelection) {
        handleToggleSwatch({
          swatch: name,
          type: "madeToOrder",
          swatchId: imageId,
          fabric
        });
      } else {
        if (isDesktop) {
          handleSelectFabric(selectedFabric);
          handleApplyFabric(e, {
            name: selectedFabric?.name,
            selectedSwatch: selectedFabric
          });
          return;
        }
        if (isLoadingTemporaryProductMobile) return;
        setSelectedSwatch(selectedFabric, true);
        handleHoverFabric(selectedFabric);
        handleApplyFabric(e, {
          name: selectedFabric?.name,
          selectedSwatch: selectedFabric
        });
      }
    };

    return (
      <ImageContainer
        key={`fabric-${fabricIdx}-${imageId}`}
        title={name}
        onClick={(e) => handleClick(fabric, e)}
        isSelected={isSelected || isSelectedSwatch}
      >
        <SwatchImage
          onMouseOver={() => {
            if (isDesktop) handleHoverFabric(fabric);
          }}
          onMouseLeave={() => {
            if (isDesktop) handleMouseLeaveFabric();
          }}
          imageId={imageId}
          ImageTitle={colorName ?? name}
          imageSet={imageSet}
          imageWidth={400}
          imageHeight={400}
          paddingOnSelected={!multipleSelection}
          initialSwatch={isSelected || isSelectedSwatch}
          temporaryData={isMobile && temporaryFabricMobile}
          showDescription
          isMultipleSelection={multipleSelection}
          selectorId="fabric-swatch-image"
        />
      </ImageContainer>
    );
  });
};

export default renderFabrics;
