const checkValues = (product, descriptions) => {
  let isVariantMatch = false;
  const { variants } = product || {};
  isVariantMatch = descriptions.every((description) =>
    variants?.some((variant) => description === variant.value)
  );
  return isVariantMatch;
};

const getCrossAvailability = ({
  tileAvailable,
  category,
  currentVariations,
  childProducts,
  listOfRelatedProductIds,
  hasCustomUpholstery
}) => {
  if (hasCustomUpholstery) return true;
  if (!currentVariations) return true;
  if (!tileAvailable) return false;
  const variationKeys = Object.keys(currentVariations);
  const descriptions = [];
  const productIds = [];
  for (const key of variationKeys) {
    const variation = currentVariations[key];
    if (key !== category) {
      descriptions.push(variation?.description);
    }
  }
  for (const product of childProducts) {
    const isVariantMatch = checkValues(product, descriptions);
    if (isVariantMatch) {
      productIds.push(product.id);
    }
  }
  const isAvailable = productIds?.some((id) =>
    listOfRelatedProductIds.includes(id)
  );
  return isAvailable;
};

export default getCrossAvailability;
