import styled from "styled-components";

export const FooterPrintWrapper = styled.footer``;

export const FooterContainer = styled.div`
  display: ${({ isLoaded }) => (isLoaded ? "block" : "none")};
  padding: ${({ noPadding }) => (noPadding ? "0 16px" : "0")};
`;

export const FooterCheckoutWrapper = styled.div`
  padding: 0 16px;
`;
