/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, {
  useState,
  useEffect,
  useRef,
  Suspense,
  lazy,
  useCallback,
  useMemo
} from "react";
import PropTypes from "prop-types";
import GlobalHeader from "../../components/GlobalHeader";
import useShowNewUserPopup from "@serenaandlily/hooks/useShowNewUserPopup";

import {
  ContentContainer,
  ContentContainerOverlay,
  ContentAndFooterContainer,
  ContentWrapper,
  SlideWrapper,
  MenuActivator,
  LoadingContainer,
  HorizontalNavPlaceholder,
  HorizontalMenuOverlay,
  HorizontalContainer
} from "./GenericPageStyled";
import { useBreakpoint } from "../../components/Breakpoints";
import usePageTotalHeight from "../../utils/usePageTotalHeight";
import SpecialMessagingBanner from "../../components/SpecialMessagingBanner";
import LoggedInBanner from "../../components/LoggedInBanner";
import { default as useUser } from "../../hooks/useUser";
import { useCartUpdate } from "../../contexts/CartContext";
import { useSlideOutMenuContext } from "../../contexts/SlideOutMenuContext";
import HorizontalNav from "@serenaandlily/components/HorizontalNav";
import { useRouter } from "next/router";
import { motion } from "framer-motion";
import { GRID_PAGE_ROUTES } from "@serenaandlily/constants/gridPageRoutes";
import { DONT_SHOW_HORIZONTAL_NAV_ROUTES } from "@serenaandlily/constants/pageRouteLists";

const SlideOutMenu = lazy(() => import("../../components/SlideOutMenu"));
const NewUserPopup = lazy(() => import("../../components/NewUserPopup"));
const NewUserModal = lazy(() => import("../../components/NewUserModal"));

const STL = "STL";

const GenericPage = ({
  header,
  smb,
  smbDoo,
  children,
  newUserPopup,
  newUserModal,
  fullWidth = false,
  noPadding = false,
  emptyBagMessage,
  dsoOnly,
  backgroundSettings,
  pageType
}) => {
  const { isOpen, setIsOpen, slideOutMenuContent, activeMobileSlideOutIdx } =
    useSlideOutMenuContext();
  const [headerHeight, setHeaderHeight] = useState();
  const isServer = typeof window === "undefined";
  const { showPopup, setShouldUserSeePopup } = useShowNewUserPopup({
    userPopUp: newUserPopup
  });

  const router = useRouter();
  const breakpoints = useBreakpoint();
  const isDesktopMobile = breakpoints["DesktopMobile"];
  const isMobile = breakpoints["IPad"];
  const { user, isUserLoading } = useUser();
  const contentContainerRef = useRef();
  const defaultWidth = 266;
  const [slideWidth, setSlideWidth] = useState(defaultWidth);
  const [currentHeight, setCurrentHeight] = useState();
  const [scrollHeight, setScrollHeight] = useState();
  const [selectedCategories, setSelectedCategories] = useState({});
  const [showFixedSlide, setShowFixedSlide] = useState();
  const slideWrapperRef = useRef();
  const { setEmptyBagMessage } = useCartUpdate();
  const horizontalNavMarginBottom = GRID_PAGE_ROUTES.includes(router.pathname)
    ? header?.horizontalNav?.marginBottom
    : 0;
  const shouldNotShowHorizontalNav = DONT_SHOW_HORIZONTAL_NAV_ROUTES.includes(
    router.asPath
  );

  if (dsoOnly && user && !user?.data?.isDSO) {
    window.location.href = "/";
  }

  useEffect(() => {
    const body = document.body;
    const scrollBarWidth = window.innerWidth - body.clientWidth;
    if (isOpen) {
      body.style.overflow = "hidden";
      body.style.paddingRight = scrollBarWidth + "px";
    } else {
      body.style.overflow = "auto";
      body.style.paddingRight = "";
    }
  }, [isOpen]);

  useEffect(() => {
    const hasScrolled = window.scrollY > headerHeight;
    hasScrolled !== showFixedSlide && setShowFixedSlide(hasScrolled);
  }, [scrollHeight, headerHeight]);

  const getHeaderHeight = (height) => setHeaderHeight(height);

  const totalHeight = usePageTotalHeight();

  useEffect(() => {
    emptyBagMessage &&
      setEmptyBagMessage(emptyBagMessage?.emptyBagMessage?.content);
  }, []);

  const HorizontalNavWrapper = useCallback(() => {
    if (shouldNotShowHorizontalNav) {
      return null;
    }

    const animationProps = {
      hidden: { opacity: 0, scale: 0.95 },
      visible: { opacity: 1, scale: 1 }
    };

    const shouldShowNav =
      slideOutMenuContent?.[activeMobileSlideOutIdx]?.categorySection?.length &&
      !isMobile;

    const renderNavigation = () => {
      if (!shouldShowNav && !isMobile) {
        return <HorizontalNavPlaceholder />;
      }

      if (shouldShowNav) {
        return (
          <HorizontalNav
            {...header?.horizontalNav}
            sticky={smbContent?.isSticky}
            setSlideWidth={setSlideWidth}
            setScrollHeight={setScrollHeight}
          />
        );
      }

      return null;
    };

    return (
      <motion.div
        initial="hidden"
        animate="visible"
        variants={animationProps}
        transition={{ duration: 0.3 }}
      >
        {renderNavigation()}
      </motion.div>
    );
  }, [slideOutMenuContent, isMobile]);

  const handleClose = () => {
    if (!isServer) {
      localStorage.setItem("newUser", 0);
    }
    fetch("/api/session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ newUserPopupShown: true })
    });
    setShouldUserSeePopup(false);
  };

  const smbContent = useMemo(() => {
    if (user?.data?.isDOO) return smbDoo;
    return smb;
  }, [smb, smbDoo, user?.data?.isDOO]);

  const handleOpenMenu = () => {
    setIsOpen(!isOpen);
    if (!smbContent?.isSticky) {
      setScrollHeight(window?.scrollY);
    }
    setSelectedCategories({});
  };

  const renderBanner = () => {
    if (!smbContent) return null;
    if (isUserLoading) return <LoadingContainer />;
    return <SpecialMessagingBanner {...smbContent} />;
  };

  const isSmbAbove =
    (user?.data?.isDSO && smbContent?.isAboveLoggedInDso) ||
    (user?.data?.isDOO && smbContent?.isAboveLoggedInDoo);

  return (
    <React.Fragment>
      <MenuActivator currentHeight={currentHeight} />
      {isSmbAbove && renderBanner()}
      {!isUserLoading && (user?.data?.isDSO || user?.data?.isDOO) && (
        <LoggedInBanner user={user?.data} />
      )}
      {!isSmbAbove && renderBanner()}
      {header && (
        <GlobalHeader
          {...header}
          openMenu={handleOpenMenu}
          isOpen={isOpen}
          setSlideWidth={setSlideWidth}
          getHeaderHeight={getHeaderHeight}
          contentToFocus={contentContainerRef}
          isSMBSticky={smbContent?.isSticky}
          setSelectedCategories={setSelectedCategories}
          setIsOpen={setIsOpen}
          pageType={pageType}
        />
      )}
      {!isMobile && (
        <HorizontalContainer>
          <HorizontalMenuOverlay className={isOpen ? "overlay-active" : ""} />
          {header?.horizontalNav?.showHorizontalNav && <HorizontalNavWrapper />}
        </HorizontalContainer>
      )}
      <ContentWrapper
        className={isOpen ? "menu-is-open" : "menu-is-closed"}
        slideWidth={slideWidth}
        fullWidth={fullWidth}
        noPadding={noPadding}
      >
        {header && (
          <SlideWrapper
            showFixedSlide={showFixedSlide}
            headerHeight={headerHeight}
            pushContent={header?.pushContent}
            isOpen={isOpen}
            horizontalNavMarginBottom={horizontalNavMarginBottom}
            shouldNotShowHorizontalNav={shouldNotShowHorizontalNav}
            ref={slideWrapperRef}
          >
            <Suspense fallback={<div>Loading...</div>}>
              <SlideOutMenu
                {...header}
                loggedIn={user?.data?.isLoggedIn}
                isSMBSticky={smbContent?.isSticky}
                isOpen={isOpen}
                headerHeight={headerHeight}
                scrollHeight={scrollHeight}
                setIsOpen={setIsOpen}
                setSlideWidth={setSlideWidth}
                setCurrentHeight={setCurrentHeight}
                currentHeight={currentHeight}
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
              />
            </Suspense>
          </SlideWrapper>
        )}
        <ContentAndFooterContainer isSTL={pageType === STL}>
          <ContentContainerOverlay
            className={isOpen ? "overlay-active" : ""}
            horizontalNavMarginBottom={horizontalNavMarginBottom}
            totalHeight={totalHeight}
          />
          <ContentContainer
            backgroundSettings={backgroundSettings}
            tabIndex="0"
            ref={contentContainerRef}
          >
            {children}
          </ContentContainer>
        </ContentAndFooterContainer>
      </ContentWrapper>
      {newUserPopup && showPopup && !isDesktopMobile && (
        <Suspense fallback={<div>Loading...</div>}>
          <NewUserPopup {...newUserPopup} handleClose={handleClose} />
        </Suspense>
      )}
      {newUserModal && !isUserLoading && !user?.data?.isDOO && (
        <Suspense fallback={<div>Loading...</div>}>
          <NewUserModal {...newUserModal} />
        </Suspense>
      )}
    </React.Fragment>
  );
};

export default GenericPage;

GenericPage.propTypes = {
  header: PropTypes.object,
  smb: PropTypes.object,
  smbDoo: PropTypes.object,
  children: PropTypes.any,
  newUserPopup: PropTypes.object,
  newUserModal: PropTypes.object,
  fullWidth: PropTypes.bool,
  emptyBagMessage: PropTypes.object
};
