import { Breakpoints, GridSpacing } from "../../constants/constants";
import { css } from "styled-components";

const MOBILE_STYLES = {
  "Wide Margin": css`
    @media screen and (max-width: ${Breakpoints.SmallMobile}) {
      justify-content: center;
      margin-left: calc(${GridSpacing.GridGutterMobile} * 0.5);
      margin-right: calc(${GridSpacing.GridGutterMobile} * 0.5);
    }
  `,
  "Full Width with Margin": css`
    @media screen and (max-width: ${Breakpoints.SmallMobile}) {
      margin-left: calc(${GridSpacing.GridGutterMobile} * 0.5);
      margin-right: calc(${GridSpacing.GridGutterMobile} * 0.5);
    }
  `,
  "Full Width without Margin": css`
    margin-left: 0;
    margin-right: 0;
  `
};

const DEFAULT_STYLE = css`
  @media screen and (max-width: ${Breakpoints.SmallMobile}) {
    overflow: hidden;
    max-width: inherit;
    margin-left: calc(${GridSpacing.GridGutterMobile} * -0.5);
    margin-right: calc(${GridSpacing.GridGutterMobile} * -0.5);
  }
`;

const getContainerSizeMobileStyles = (size) => {
  return MOBILE_STYLES[size] || DEFAULT_STYLE;
};

export default getContainerSizeMobileStyles;
