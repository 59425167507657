import styled from "styled-components";
import {
  Breakpoints,
  Colors,
  Typography,
  FontSizes
} from "../../constants/constants";

export const SearchForm = styled.form`
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 1024px;
`;

export const SearchBarLabel = styled.label`
  font-size: 0;
`;

export const SearchBar = styled.input`
  width: 100%;
  border: 1px solid ${Colors["Light Primary"]};
  font-family: ${Typography.ProximaNova};
  max-width: 1024px;

  margin: 0;
  float: left;
  color: ${Colors["Medium Primary"]};
  border-radius: 0;
  background-color: ${Colors.White};
  height: 35px;

  font-size: ${FontSizes.Xlarge};
  letter-spacing: 0.025em;
  padding: 0 0 0 10px;

  &:focus {
    outline: none !important;
  }

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    margin: 0;
    float: left;
    color: ${Colors["Medium Primary"]};
    border: 2px solid ${Colors["Dark Primary"]};
    border-radius: 0;
    background-color: ${Colors.White};
    max-height: 35px;
    height: 35px;
  }

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    width: 90%;
  }
`;

export const SearchButton = styled.button`
  background-color: ${Colors.White};
  position: absolute;
  border: none;
  right: 7%;
  float: left;
  cursor: pointer;
  height: 26px;
  width: 20px;
  margin-top: 5px;
  font-size: 0px;

  @media screen and (min-width: ${Breakpoints.IPad}) {
    right: 3%;
  }

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    position: initial;
    margin-top: 0;
    float: left;
    height: 39px;
    width: 40px;
    border: 2px solid  ${Colors["Dark Primary"]};
    border-left: none;
    background: url(https://cdn.media.amplience.net/i/serenaandlily/searchIcon-large) 50% no-repeat ${Colors.White};
`;
export const LargeSearchButton = styled.button`
  display: none;
`;

export const CloseIcon = styled.button`
  display: none;

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    display: block;
    padding: 0;
    cursor: pointer;
    float: left;
    height: 35px;
    width: 37px;
    border: 2px solid ${Colors["Dark Primary"]};
    border-left: none;
    background: url(https://cdn.media.amplience.net/i/serenaandlily/closeIcon)
      50% no-repeat ${Colors.White};
    box-sizing: initial;
  }
`;

export const LargeSearchForm = styled.form`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0;
`;

export const SearchBarWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 64px;
  border: 1px solid ${Colors["Midnight"]};
  margin: 0;
  background-color: ${Colors["White"]};
  border-radius: 4px;
  overflow: hidden;

  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    border: 1px solid ${Colors["Midnight 70%"]};
    height: 48px;
  }
`;
export const LargeSearchBar = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  font-family: ${Typography.BeatriceLight};

  margin: 0;
  float: left;
  color: ${Colors["Midnight"]};
  background-color: ${Colors["Transparent"]};

  font-size: ${FontSizes.Xlarge};
  letter-spacing: 0.025em;
  padding: 0 0 0 10px;

  &:focus {
    outline: none !important;
  }

  @media screen and (max-width: ${Breakpoints.IPad}) {
    left: 10px;
  }
`;
export const AutocompleteText = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  font-family: ${Typography.BeatriceLight};
  z-index: -1;
  cursor: none;

  margin: 0;
  float: left;
  color: ${Colors["Midnight 70%"]};

  font-size: ${FontSizes.Xlarge};
  letter-spacing: 0.025em;
  padding: 0 0 0 10px;

  &:focus {
    outline: none !important;
  }

  @media screen and (max-width: ${Breakpoints.IPad}) {
    padding: 0 0 0 20px;
  }
`;

export const ClearButton = styled.button`
  position: absolute;
  right: 0;
  height: 100%;
  line-height: 64px;
  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    line-height: 32px;
  }
  padding: 0 24px;
  margin: 0;
  border: none;
  background-color: ${Colors["Transparent"]};
  font-family: ${Typography.BeatriceLight};
  font-size: ${FontSizes.Large};
  color: ${Colors["Midnight"]};
  cursor: pointer;
`;
