import { useEffect, useRef, useState } from "react";
import { Backdrop, CloseButton, SlideInWrapper } from "./SlideInStyled";
import PropTypes from "prop-types";
import { SLIDE_IN_POSITION } from "./constants";
import Icons from "../Icons";
import { useSlideInContext } from "@serenaandlily/contexts/SlideInContext";
import { useSidePanelContext } from "@serenaandlily/contexts/SidePanelContext";
import { SMB_ID } from "../SpecialMessagingBanner";
import { LOGGED_IN_BANNER } from "../LoggedInBanner";
import { GLOBAL_HEADER_ID } from "../GlobalHeader";
import { HORIZONTAL_NAV_ID } from "../HorizontalNav/HorizontalNav";

const SlideIn = ({
  isOpen,
  onClose,
  position = SLIDE_IN_POSITION.RIGHT,
  maxWidth,
  maxHeight,
  backgroundColor,
  top,
  children,
  hideCloseButton = false,
  zIndexBase = 9999,
  visibleBackdrop = true,
  closeOnClickOutside = true,
  closeButtonAriaLabel = "Close",
  className = ""
}) => {
  const { closeAllSignal } = useSlideInContext();
  const [isVisible, setIsVisible] = useState(false);
  const [topOffset, setTopOffset] = useState("0px");
  const { content } = useSidePanelContext();
  const [backdropHeight, setBackdropHeight] = useState("100vh");
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (closeAllSignal && isOpen) {
      onClose();
    }
  }, [closeAllSignal, isOpen, onClose]);

  useEffect(() => {
    const calculateOffsets = () => {
      if (position === SLIDE_IN_POSITION.BOTTOM) {
        setTopOffset("0px");
        setBackdropHeight("100vh");
        return;
      }

      const smb = document.getElementById(SMB_ID);
      const loggedInBanner = document.getElementById(LOGGED_IN_BANNER);
      const globalHeader = document.getElementById(GLOBAL_HEADER_ID);
      const horizontalNav = document.getElementById(HORIZONTAL_NAV_ID);
      const smbRect = smb ? smb.getBoundingClientRect() : { top: 0, height: 0 };
      const loggedInBannerRect = loggedInBanner
        ? loggedInBanner.getBoundingClientRect()
        : { top: 0, height: 0 };
      const globalHeaderHeight = globalHeader ? globalHeader.offsetHeight : 0;
      const horizontalNavHeight = horizontalNav
        ? horizontalNav.offsetHeight
        : 0;

      let topOffsetValue;
      if (
        (smbRect.top >= 0 && smbRect.bottom <= window.innerHeight) ||
        (loggedInBannerRect.top >= 0 &&
          loggedInBannerRect.bottom <= window.innerHeight)
      ) {
        topOffsetValue =
          smbRect.top +
          smbRect.height +
          loggedInBannerRect.top +
          loggedInBannerRect.height +
          globalHeaderHeight +
          horizontalNavHeight -
          window.scrollY;
      } else {
        topOffsetValue = globalHeaderHeight;
      }

      setTopOffset(`${Math.max(0, topOffsetValue)}px`);
      setBackdropHeight(`calc(100vh - ${Math.max(0, globalHeaderHeight)}px)`);
    };

    calculateOffsets();

    if (isOpen) {
      setIsVisible(true);
      document.body.style.overflow = "hidden";
      document.addEventListener("mousedown", handleClickOutside);
      window.addEventListener("scroll", calculateOffsets);
      window.addEventListener("resize", calculateOffsets);
    } else {
      setIsVisible(false);
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", calculateOffsets);
      window.removeEventListener("resize", calculateOffsets);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", calculateOffsets);
      window.removeEventListener("resize", calculateOffsets);

      const openSlideIns = document.querySelectorAll(".slide-in.open");
      if (openSlideIns.length === 0) {
        document.body.style.overflow = "auto";
      }
    };
  }, [isOpen, position]);

  useEffect(() => {
    const openSlideIns = document.querySelectorAll(".slide-in.open");
    if (openSlideIns.length === 0) {
      document.body.style.overflow = "auto";
    }
  }, [isVisible]);

  const handleClickOutside = (event) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target) &&
      closeOnClickOutside &&
      !content
    ) {
      onClose();
    }
  };

  return (
    <>
      {isOpen && (
        <Backdrop
          top={top || topOffset}
          height={backdropHeight}
          onClick={() => {
            if (closeOnClickOutside) onClose();
          }}
          data-modal={isVisible ? "true" : undefined}
          style={{ zIndex: zIndexBase - 1 }}
          isVisible={visibleBackdrop}
        />
      )}
      <SlideInWrapper
        ref={wrapperRef}
        isVisible={isVisible}
        position={position}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        backgroundColor={backgroundColor}
        top={top || topOffset}
        data-modal={isVisible ? "true" : undefined}
        style={{ zIndex: zIndexBase + 1 }}
        className={`slide-in ${className} ${isOpen ? "open" : "closed"}`}
      >
        {isOpen && !hideCloseButton && (
          <CloseButton onClick={onClose} aria-label={closeButtonAriaLabel}>
            <Icons type="CloseNew" />
          </CloseButton>
        )}
        {children}
      </SlideInWrapper>
    </>
  );
};

SlideIn.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  position: PropTypes.oneOf(Object.values(SLIDE_IN_POSITION)),
  maxWidth: PropTypes.string,
  maxHeight: PropTypes.string,
  top: PropTypes.string,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  zIndexBase: PropTypes.number,
  visibleBackdrop: PropTypes.bool,
  closeOnClickOutside: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  closeButtonAriaLabel: PropTypes.string
};

export default SlideIn;
