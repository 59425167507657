import React from "react";
import { SearchCloseContainer, SearchCloseText } from "./UtilityMenuStyled";

const SearchLogo = () => {
  return (
    <SearchCloseContainer id="header-search">
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 1L13 13M13 1L1 13" stroke="#243953" strokeLinecap="round" />
      </svg>
      <SearchCloseText>Close</SearchCloseText>
    </SearchCloseContainer>
  );
};

export default SearchLogo;
