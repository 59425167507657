import renderFabrics from "@serenaandlily/utils/renderFabrics";
import {
  FabricTypeWrapper,
  SwatchSection
} from "../FabricPanel/FabricPanelStyled";
import { SwatchesContainer } from "../FabricPanel/FabricPanelStyled";
import Text from "../Text";

const SwatchSectionWrapper = ({
  groupedFilteredFabrics,
  filteredQuickShip,
  filteredMadeToOrder,
  subTypeOrder,
  renderFabricsProps,
  theme,
  isDesktop
}) => {
  return (
    <SwatchSection>
      {subTypeOrder?.length ? (
        groupedFilteredFabrics.map((group, groupIdx) => {
          const { subType, fabrics } = group;

          return (
            <div key={`group-${groupIdx}`}>
              <FabricTypeWrapper>
                <Text
                  copy={subType.toUpperCase()}
                  theme={theme?.fabricTypeTitle}
                />
              </FabricTypeWrapper>
              <SwatchesContainer>
                {renderFabrics({
                  fabrics,
                  ...renderFabricsProps,
                  isDesktop
                })}
              </SwatchesContainer>
            </div>
          );
        })
      ) : (
        <SwatchesContainer>
          {renderFabrics({
            fabrics: [...filteredQuickShip, ...filteredMadeToOrder],
            ...renderFabricsProps,
            isDesktop
          })}
        </SwatchesContainer>
      )}
    </SwatchSection>
  );
};

export default SwatchSectionWrapper;
