/* eslint-disable complexity */
import "regenerator-runtime/runtime";
import CONSTRUCTOR_CONSTANTS from "./constants";
import handleCookies from "../handleCookies";
import generateConstructorId from "../generateConstructorId";
import {
  fetchCategoryResults,
  fetchGetRecommender,
  fetchSearchResults,
  fetchSearchGetTypeaheadResults
} from "@serenaandlily/gql/utils";
import axios from "axios";
import { getValidFacets } from "../facets/getValidFacets";

const DEFAULT_VARIATIONS_MAP = {
  group_by: [
    {
      name: "variation_id",
      field: "data.variation_id"
    }
  ],
  values: {
    color: {
      aggregation: "first",
      field: "data.facets.color"
    },
    size: {
      aggregation: "first",
      field: "data.facets.size"
    },
    image_url: {
      aggregation: "first",
      field: "data.image_url"
    },
    variation_id: {
      aggregation: "first",
      field: "data.variation_id"
    }
  },
  dtype: "array"
};

const buildUserParameters = async () => {
  let constructorId = handleCookies(
    "ConstructorioID_client_id",
    ""
  ).getCookie();

  if (!constructorId) {
    const newCioId = generateConstructorId();
    constructorId = newCioId;
    handleCookies("ConstructorioID_client_id").updateCookie(newCioId);
  }

  const sessionId =
    localStorage.getItem("_constructorio_search_session_id") || 1;
  const response = await axios.get("/api/user-ip");
  const userIp = response.data.ip;
  return {
    clientId: constructorId,
    sessionId: parseInt(sessionId, 10),
    userIp,
    userAgent: window?.navigator?.userAgent
  };
};

const getSearchRecommendations = async ({
  phrase,
  requestParams,
  showRetailProducts,
  customerEmail,
  guestToken
}) => {
  const defaultRequestParams = {
    resultsPerSection: {
      Products: 5,
      "Search Suggestions": 50
    },
    filters: {
      isRetailExclusive: CONSTRUCTOR_CONSTANTS.IS_NOT_RETAIL_EXCLUSIVE
    },
    variationsMap: DEFAULT_VARIATIONS_MAP,
    ...requestParams
  };
  if (showRetailProducts) delete defaultRequestParams.filters;

  defaultRequestParams.hiddenFields = [
    "showRolloverImage",
    "facets.isCustomUpholstery"
  ];

  const userParameters = await buildUserParameters();

  const { data } = await fetchSearchGetTypeaheadResults({
    searchPhrase: phrase,
    maxPromoMessages: CONSTRUCTOR_CONSTANTS.MAX_PROMO_MESSAGES,
    searchParameters: JSON.stringify(defaultRequestParams),
    userParameters: JSON.stringify(userParameters),
    customerEmail,
    guestToken
  });

  if (data?.searchGetTypeaheadResults?.results) {
    const results = JSON.parse(data.searchGetTypeaheadResults.results);
    return results.sections;
  }

  return null;
};

const getRecommender = async ({
  pod,
  parameters,
  customerEmail,
  guestToken,
  itemIds,
  filters = {},
  resultsCount
}) => {
  const numResults = resultsCount || 4;
  const defaultParameters = {
    itemIds,
    numResults,
    variationsMap: DEFAULT_VARIATIONS_MAP,
    filters: {
      ...filters,
      isRetailExclusive: CONSTRUCTOR_CONSTANTS.IS_NOT_RETAIL_EXCLUSIVE
    },
    ...parameters
  };
  const userParameters = await buildUserParameters();
  defaultParameters.hiddenFields = [
    "showRolloverImage",
    "facets.isCustomUpholstery"
  ];
  const { data } = await fetchGetRecommender({
    podName: pod,
    maxPromoMessages: CONSTRUCTOR_CONSTANTS.MAX_PROMO_MESSAGES,
    searchParameters: JSON.stringify(defaultParameters),
    userParameters: JSON.stringify(userParameters),
    customerEmail,
    guestToken
  });
  let results = [];

  if (data?.searchGetRecommendations?.results)
    results = JSON.parse(data?.searchGetRecommendations?.results);

  return results?.response?.results;
};

// eslint-disable-next-line complexity
const getGridRequestParams = ({
  page = 0,
  // currentPage,
  resultsPerPage,
  filters,
  offset,
  ...rest
}) => {
  if (offset)
    return {
      page: null,
      offset,
      resultsPerPage,
      filters,
      fmtOptions: {
        variations_return_type: "matched"
      },
      ...rest
    };

  return {
    page,
    resultsPerPage,
    filters,
    fmtOptions: {
      variations_return_type: "matched"
    },
    ...rest
  };
};

const MAX_PAGE_SIZE = 100;

const reduceOverloadedRequests = (requestsResults = []) =>
  requestsResults.reduce((prev, current) => {
    const { request, response } = current;

    const { results } = response;
    const { results: prevResults } = prev.response;
    const accumResults = prev ? prevResults.concat(...results) : results;

    response.results = accumResults;

    return {
      request,
      response
    };
  });

const getNumberOfPages = (resultsPerPage = 0) =>
  Math.ceil(resultsPerPage / MAX_PAGE_SIZE);

const getCategory = async ({
  categoryID,
  page = 1,
  currentPage,
  resultsPerPage,
  filters = {},
  showRetailProducts,
  offset,
  isDOO,
  customerEmail,
  guestToken,
  ...rest
}) => {
  const filterOptions = filters;
  if (!showRetailProducts)
    filterOptions.isRetailExclusive =
      CONSTRUCTOR_CONSTANTS.IS_NOT_RETAIL_EXCLUSIVE;

  const requestParams = getGridRequestParams({
    page,
    currentPage,
    resultsPerPage,
    filters: filterOptions,
    offset,
    ...rest
  });

  let responseResult = {};
  requestParams.variationsMap = DEFAULT_VARIATIONS_MAP;
  requestParams.hiddenFields = [
    "showRolloverImage",
    "facets.isCustomUpholstery"
  ];
  const userParameters = await buildUserParameters();

  if (resultsPerPage > MAX_PAGE_SIZE) {
    const numberOfPages = getNumberOfPages(resultsPerPage);

    const requestPromises = [];

    let offsetCounter = offset;
    for (let index = 0; index < numberOfPages; index += 1) {
      const constructorRequest = fetchCategoryResults({
        categorySlug: categoryID,
        maxPromoMessages: CONSTRUCTOR_CONSTANTS.MAX_PROMO_MESSAGES,
        searchParameters: JSON.stringify({
          ...requestParams,
          resultsPerPage: MAX_PAGE_SIZE,
          offset: offsetCounter,
          fmtOptions: {
            variations_return_type: "matched"
          }
        }),
        userParameters: JSON.stringify(userParameters),
        customerEmail,
        guestToken
      });

      requestPromises.push(constructorRequest);
      offsetCounter = offsetCounter + MAX_PAGE_SIZE;
    }

    const requestsResults = await Promise.all(requestPromises);
    const parsedResults = requestsResults.map((result) => {
      return JSON.parse(result.data.searchGetCategoryResults.results);
    });

    responseResult = reduceOverloadedRequests(parsedResults);

    const facets = getValidFacets(responseResult.response.facets, isDOO);

    return { ...responseResult.response, facets };
  }
  responseResult = await fetchCategoryResults({
    categorySlug: categoryID,
    maxPromoMessages: CONSTRUCTOR_CONSTANTS.MAX_PROMO_MESSAGES,
    searchParameters: JSON.stringify(requestParams),
    userParameters: JSON.stringify(userParameters),
    customerEmail,
    guestToken
  });

  const resultsParsed = JSON.parse(
    responseResult.data.searchGetCategoryResults.results
  );

  const facets = getValidFacets(resultsParsed.response.facets, isDOO);

  return {
    ...resultsParsed.response,
    facets
  };
};

const getSearch = async ({
  page = 0,
  currentPage,
  size,
  filters = {},
  phrase,
  offset,
  isDOO,
  showRetailProducts,
  customerEmail,
  guestToken,
  ...rest
}) => {
  const filterOptions = filters;
  if (!showRetailProducts)
    filterOptions.isRetailExclusive =
      CONSTRUCTOR_CONSTANTS.IS_NOT_RETAIL_EXCLUSIVE;

  const requestParams = getGridRequestParams({
    page,
    currentPage,
    size,
    filters: filterOptions,
    offset,
    ...rest
  });

  const { resultsPerPage } = requestParams;

  requestParams.variationsMap = DEFAULT_VARIATIONS_MAP;
  requestParams.hiddenFields = [
    "showRolloverImage",
    "facets.isCustomUpholstery"
  ];

  let responseResult = {};

  const userParameters = await buildUserParameters();

  if (resultsPerPage > MAX_PAGE_SIZE) {
    const numberOfPages = getNumberOfPages(resultsPerPage);

    const requestPromises = [];

    let offsetCounter = offset;

    for (let index = 0; index < numberOfPages; index += 1) {
      const constructorRequest = fetchSearchResults({
        searchPhrase: phrase,
        customerEmail,
        guestToken,
        maxPromoMessages: CONSTRUCTOR_CONSTANTS.MAX_PROMO_MESSAGES,
        searchParameters: JSON.stringify({
          ...requestParams,
          resultsPerPage: MAX_PAGE_SIZE,
          offset: offsetCounter
        }),
        userParameters: JSON.stringify(userParameters)
      });

      requestPromises.push(constructorRequest);
      offsetCounter = offsetCounter + MAX_PAGE_SIZE;
    }

    const requestsResults = await Promise.all(requestPromises);

    const parsedResults = requestsResults.map((result) => {
      return JSON.parse(result.data.searchGetSearchResults.results);
    });

    responseResult = reduceOverloadedRequests(parsedResults);

    const facets = getValidFacets(responseResult.response.facets, isDOO);

    return { ...responseResult.response, facets };
  } else {
    responseResult = await fetchSearchResults({
      searchPhrase: phrase,
      maxPromoMessages: CONSTRUCTOR_CONSTANTS.MAX_PROMO_MESSAGES,
      searchParameters: JSON.stringify(requestParams),
      customerEmail,
      guestToken,
      userParameters: JSON.stringify(userParameters)
    });
  }

  const resultsParsed = JSON.parse(
    responseResult.data.searchGetSearchResults.results
  );

  const facets = getValidFacets(resultsParsed.response.facets, isDOO);

  return {
    ...resultsParsed.response,
    facets
  };
};

export default {
  getSearchRecommendations,
  getRecommender,
  getCategory,
  getSearch
};
