import { Breakpoints } from "@serenaandlily/constants/constants";
import styled from "styled-components";

export const CtaContainer = styled.div`
  position: absolute;
  left: 50%;
  bottom: 25px;
  transform: translateX(-50%);

  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    left: 15px;
    z-index: 10;
    transform: none;
  }
  @media screen and (min-width: ${Breakpoints.IPhone11ProMax}) {
    bottom: 10px;
    & > a {
      width: 188px;
      height: 40px;
      font-weight: 500;
      font-size: 15px;
      padding: 12px 29px;
      font-style: normal;
      letter-spacing: normal;
    }
  }
`;
