import styled from "styled-components";
import getVideoContainerSizeDesktopStyles from "./utils/getVideoContainerSizeDesktopStyles";
import getVideoContainerSizeMobileStyles from "./utils/getVideoContainerSizeMobileStyles";

import {
  Colors,
  FontSizes,
  LetterSpacing,
  Typography
} from "../../constants/constants";
import {
  ContentContainer,
  HeadlinesContainer,
  HeadlineTextContainer
} from "../Banner/BannerStyled";

export const VideoPlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  aspect-ratio: ${({ videoAspectRatio }) =>
    videoAspectRatio
      ? `${videoAspectRatio.width} / ${videoAspectRatio.height}`
      : "16/9"};
  ${({ sizeDesktop }) => getVideoContainerSizeDesktopStyles(sizeDesktop)};
  ${({ sizeMobile }) => getVideoContainerSizeMobileStyles(sizeMobile)};
`;

export const MobileImageWrapper = styled.div`
  position: relative;
  img {
    max-width: 100%;
  }
  aspect-ratio: ${({ imageAspectRatio }) =>
    `${imageAspectRatio?.width} / ${imageAspectRatio?.height}`};
  ${({ sizeDesktop }) => getVideoContainerSizeDesktopStyles(sizeDesktop)}
  ${({ sizeMobile }) => getVideoContainerSizeMobileStyles(sizeMobile)}
`;

export const VideoWrapper = styled.div`
  position: relative;
  aspect-ratio: ${({ videoAspectRatio }) =>
    videoAspectRatio
      ? `${videoAspectRatio.width} / ${videoAspectRatio.height}`
      : "16/9"};
  margin-bottom: ${({ bottomMargin }) => bottomMargin && `${bottomMargin}px`};
  ${({ isMobile }) => !isMobile && "width: 100%;"}
`;

export const VideoImage = styled.img`
  aspect-ratio: ${({ imageAspectRatio }) =>
    `${imageAspectRatio?.width} / ${imageAspectRatio?.height}`};
  height: ${({ fullHeight }) => (fullHeight ? "100%" : "")};
`;

export const HeadlineWrapper = styled.div`
  position: absolute;
  top: 10%;
  left: 10%;
  max-width: 356px;
  & * a {
    width: max-content;
  }
`;

export const VideoPlayer = styled.div``;

export const Video = styled.video`
  width: 100% !important;
  position: relative !important;
  height: auto !important;

  video {
    aspect-ratio: ${({ videoAspectRatio }) =>
      videoAspectRatio
        ? `${videoAspectRatio.width} / ${videoAspectRatio.height}`
        : ""};
    width: 100% !important;
    position: relative !important;
    height: ${({ isOnPdp }) => (isOnPdp ? "auto !important" : "100%")};
  }
`;

export const VideoHeadlinePositioning = styled(ContentContainer)``;

export const VideoHeadlinesContainer = styled(HeadlinesContainer)``;

export const VideoHeadlineTextContainer = styled(HeadlineTextContainer)``;

const snlCTA = ({ captionStyle }) =>
  captionStyle.includes("CTA") &&
  `
  color: ${Colors["Dark Primary"]};
  font-stretch: normal;
  font-style: normal;
  letter-spacing: ${LetterSpacing.XSmall};
  line-height: 1.43;
  margin: 0;
  text-align: center;
  text-transform: uppercase;

  &:hover {
    color: ${Colors["Gray Medium"]};
  }
`;

const snlCTAWhite = ({ captionStyle }) =>
  captionStyle === "CTA White" &&
  `
  color: ${Colors.White};
`;

const snlCTAUnderline = ({ captionStyle }) =>
  captionStyle &&
  captionStyle === "CTA Underline" &&
  `
  line-height: 1.67;
  border-bottom: 1px solid ${Colors["Dark Primary"]};
  display: inline;

  &:hover {
    border-bottom: 1px solid ${Colors["Gray Medium"]};
  }
`;

export const Caption = styled.a`
  font-family: ${Typography.ProximaNova};
  font-size: ${FontSizes.Base};
  text-decoration: none;
  font-weight: 600;
  ${snlCTA}
  ${snlCTAWhite}
  ${snlCTAUnderline}
`;
