// REACT IMPORTS //
import React, { useRef } from "react";

// 3RD PARTY IMPORTS //
import { motion } from "framer-motion";
import PropTypes from "prop-types";

// HOOK IMPORTS //
import { useBreakpoint } from "../Breakpoints";
import { useProductSwatchDescription } from "../../hooks/useProductSwatchDescription";

// COMPONENT IMPORTS //
import { PointLoader } from "../Icons/Variants";

import CTA from "../CTA";
import Text from "../Text";
import Icons from "../Icons";
import ProductEDD from "../ProductEDD";
import ProductPrice from "../ProductPrice";
import FabricFeatures from "../FabricPanel/components/FabricFeatures";
import FabricContent from "../FabricPanel/components/FabricContent";
import CareInstructions from "../FabricPanel/components/CareInstructions";

// STYLED IMPORTS //
import {
  DesktopContainer,
  DesktopContainerWrapper,
  DesktopImageContainer,
  FabricContentContainer,
  FabricName,
  FabricDescription,
  CloseButton,
  RoundedFabricImage,
  SwipeUp,
  SwipeUpContainer,
  PriceAndApplyPreview,
  PriceContainer,
  PriceTitle,
  Price,
  ButtonsContainer,
  MobileImageContainer,
  LoadingWrapper
} from "./FabricPreviewStyled";
import { FlexWrapper } from "../../../components/LayoutUtils/LayoutUtilsStyled";

// THEME IMPORTS
import defaultTheme from "./FabricPreviewTheme";

// eslint-disable-next-line max-lines-per-function, complexity
const FabricPreview = ({
  hoveredFabricData,
  handleMouseLeaveDesktopContainer,
  onClose,
  isTablet,
  isMobile,
  isDesktop,
  temporaryProductMobile,
  cancelMouseLeave,
  addFabricToCart,
  loadingFabricToCart,
  orderSwatchText,
  handleApplyFabric,
  eddData,
  theme = defaultTheme
}) => {
  // Props Destructuring //
  const { selectedSwatch } = hoveredFabricData || {};
  const { fabricFeatures, fabricContent, careInstructions } =
    selectedSwatch || {};

  // Refs //
  const imageParentRef = useRef(null);

  // Hooks //
  const breakpoints = useBreakpoint();

  const { data: dataHovered, isLoading: isLoadingDataHovered } =
    useProductSwatchDescription(
      hoveredFabricData?.selectedSwatch?.swatchProduct?.id || 0
    );

  // Variables //
  const isDesktopMobile = breakpoints["DesktopMobile"];

  const hasFabricContent = fabricContent?.length > 0;
  const hasCareInstructions = careInstructions?.length > 0;

  const imageName =
    hoveredFabricData?.name ?? hoveredFabricData?.selectedSwatch?.colorName;

  let slideVariants = {
    hidden: { x: "100%", opacity: 1, left: 0 },
    visible: {
      x: 0,
      opacity: 1,
      left: "-403px",
      transition: { duration: 0.4 }
    },
    exit: { x: "100%", opacity: 1, transition: { duration: 0.4 } }
  };

  if (isMobile) {
    slideVariants = {
      hidden: { y: "-100%", opacity: 1 },
      visible: {
        y: 0,
        opacity: 1,
        transition: { duration: 0.4 }
      },
      exit: { y: "-100%", opacity: 1, transition: { duration: 0.4 } }
    };
  }

  if (isTablet && !isMobile) {
    slideVariants = {
      hidden: { x: "100%", opacity: 1, left: "480px" },
      visible: {
        x: 0,
        opacity: 1,
        left: "0",
        transition: { duration: 0.4 }
      },
      exit: { x: "100%", opacity: 1, transition: { duration: 0.4 } }
    };
  }

  return (
    <DesktopContainer
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={slideVariants}
      onMouseEnter={cancelMouseLeave}
      onMouseLeave={handleMouseLeaveDesktopContainer}
    >
      <CloseButton onClick={onClose}>
        <Icons type="CloseNew" />
      </CloseButton>
      <DesktopContainerWrapper>
        <DesktopImageContainer ref={imageParentRef}>
          {isDesktop ? (
            <img
              src={
                hoveredFabricData?.selectedSwatch?.imageSet?.items[0]?.src +
                "?h=352&w=352&sm=s&fmt=auto&img404=noimagesmall"
              }
              alt={`${hoveredFabricData?.name}-product-img`}
            />
          ) : (
            <React.Fragment>
              <MobileImageContainer>
                <img
                  src={`${temporaryProductMobile?.scene7Url}&wid=800&hei=1000`}
                  alt={`${hoveredFabricData?.name}-product-img`}
                />
              </MobileImageContainer>
              <RoundedFabricImage
                src={
                  hoveredFabricData?.selectedSwatch?.imageSet?.items[0]?.src +
                  "?h=257&w=352&sm=s&fmt=auto&img404=noimagesmall"
                }
                isScene7Url={true}
                altText={`${hoveredFabricData?.name}-product-img`}
                isDesktopMobile={isDesktopMobile}
              />
            </React.Fragment>
          )}
        </DesktopImageContainer>

        <FabricContentContainer>
          {(imageName || dataHovered?.description?.data) && (
            <FlexWrapper flexDirection="column" gap="16px">
              <FabricName>
                <Text copy={imageName} themeName="headline5" />
              </FabricName>
              {dataHovered?.description?.data && (
                <FabricDescription>
                  <Text
                    copy={dataHovered?.description?.data}
                    {...theme?.fabricDescription}
                  />
                </FabricDescription>
              )}{" "}
              {!dataHovered?.description?.data && isLoadingDataHovered && (
                <LoadingWrapper>
                  <PointLoader />
                </LoadingWrapper>
              )}
            </FlexWrapper>
          )}

          <FabricFeatures fabricFeatures={fabricFeatures} />

          {(hasFabricContent || hasCareInstructions) && (
            <FlexWrapper flexDirection="column" gap="16px">
              <FabricContent fabricContent={fabricContent} />
              <CareInstructions careInstructions={careInstructions} />
            </FlexWrapper>
          )}

          <ProductEDD showEdd={!!eddData?.eddDate} eddData={eddData} />
        </FabricContentContainer>
      </DesktopContainerWrapper>
      {!isMobile && isTablet && (
        <PriceAndApplyPreview>
          <PriceContainer>
            <PriceTitle>
              <Text copy="Price with selected fabric:" themeName="headline5" />
            </PriceTitle>
            <Price>
              <ProductPrice
                {...temporaryProductMobile}
                removeDecimalsIfZero={true}
              />
            </Price>
          </PriceContainer>
          <ButtonsContainer>
            <CTA
              cta={{
                ctaText: "APPLY",
                onCtaClick: (e) => handleApplyFabric(e, null, true)
              }}
              useButtonTag={true}
              themeName="Primary Button"
            />
            <CTA
              disabled={loadingFabricToCart}
              cta={{
                ctaText: orderSwatchText,
                onCtaClick: addFabricToCart
              }}
              useButtonTag={true}
              themeName="Secondary Button"
            />
          </ButtonsContainer>
        </PriceAndApplyPreview>
      )}
      <motion.div
        drag="y"
        dragConstraints={{ top: 0, bottom: 0 }}
        dragElastic={0.2}
        onDragEnd={(_, info) => {
          if (info.offset.y < -50) {
            onClose();
          }
        }}
        style={{ touchAction: "none" }}
      >
        <SwipeUpContainer>
          <SwipeUp />
        </SwipeUpContainer>
      </motion.div>
    </DesktopContainer>
  );
};

FabricPreview.propTypes = {
  hoveredFabricData: PropTypes.object,
  handleMouseLeaveDesktopContainer: PropTypes.func,
  onClose: PropTypes.func,
  productId: PropTypes.string.isRequired,
  topLineVariation: PropTypes.string,
  cuOptions: PropTypes.array.isRequired,
  currentVariations: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  selectedSwatch: PropTypes.object.isRequired,
  addFabricToCart: PropTypes.func.isRequired,
  orderSwatchText: PropTypes.string.isRequired,
  loadingFabricToCart: PropTypes.bool,
  handleApplyFabric: PropTypes.func.isRequired
};

export default FabricPreview;
