import React from "react";
import PropTypes from "prop-types";
import Text from "../Text";
import useComponentTheme from "../Theme/useComponentTheme";
import getAssetUrl from "../../utils/getAssetUrl";
import {
  PromoContainer,
  ImageContainer,
  Image,
  ImageCaption
} from "./PromoCTAStyled";
import defaultTheme from "./PromoCTATheme";

const PromoCTA = ({
  image,
  altText,
  firstLine,
  secondLine,
  url,
  theme = defaultTheme
}) => {
  const imageSrc = getAssetUrl(image);
  const promoCTATheme = useComponentTheme({ theme });
  const target = "_self";

  return (
    <PromoContainer href={url} target={target}>
      <ImageContainer>
        <Image src={imageSrc} alt={altText} />
      </ImageContainer>
      <ImageCaption>
        {firstLine && (
          <Text
            copy={firstLine}
            tag="figcaption"
            themeName={promoCTATheme?.title?.themeName}
          />
        )}
        {secondLine && (
          <Text
            copy={secondLine}
            tag="figcaption"
            themeName={promoCTATheme?.subTitle?.themeName}
          />
        )}
      </ImageCaption>
    </PromoContainer>
  );
};

export default PromoCTA;

PromoCTA.propTypes = {
  image: PropTypes.object,
  altText: PropTypes.string,
  url: PropTypes.string,
  theme: PropTypes.object,
  firstLine: PropTypes.string,
  secondLine: PropTypes.string
};
