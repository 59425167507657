const defaultTheme = {
  title: {
    themeName: "headline3"
  },
  cta: {
    themeName: "CTA Midnight"
  }
};

export default defaultTheme;
