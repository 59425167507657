import styled from "styled-components";
import {
  Typography,
  Colors,
  LetterSpacing,
  Spacing,
  Breakpoints
} from "../../constants/constants";

export const VariationTilesContainer = styled.div`
  width: 100%;
  margin-bottom: ${Spacing.Base - 12}px;
  font-family: ${Typography.BeatriceRegular};
  color: ${Colors["Dark Primary"]};
  letter-spacing: ${LetterSpacing.XSmall};
  @media (max-width: ${Breakpoints.XSMobileMax}) {
    margin-bottom: ${Spacing.Base}px;
  }
`;

export const Category = styled.legend`
  padding: 0;
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    margin-bottom: ${Spacing.MediumSmall}px;
  }
`;

export const Variation = styled.span`
  font-weight: 400;
  text-transform: capitalize;
`;

export const VariationTilesFieldset = styled.fieldset`
  display: flex;
  height: auto;
  border: none;
  margin: 0;
  padding: 0;
`;

export const TilesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
`;

export const Tile = styled.div`
  position: relative;
  display: flex;
  background-color: ${Colors.Cloud};
  justify-content: center;
  align-items: center;
  margin: 0px 12px 0px 0px;
  cursor: pointer;
  border: ${({ activeTitle }) =>
    activeTitle
      ? `1px solid ${Colors["Midnight"]}`
      : `1px solid ${Colors["Midnight 40%"]}`};
  &:hover {
    border: 1px solid ${Colors["Midnight"]};
  }
  background: ${({ isAvailable }) =>
    isAvailable
      ? ""
      : `linear-gradient(
    to bottom right,
    hsla(0, 0%, 100%, 0.1) 47%,
    #fff,
    54%,
    hsla(0, 0%, 100%, 0.1) 0
  )`};
  background-color: ${({ isAvailable }) =>
    isAvailable ? "" : `hsla(0, 0%, 80%, 0.5)`};
  border-radius: 4px;
`;

export const TileLabel = styled.label`
  font-weight: 400;
  cursor: pointer;
  padding: 13px 14px 12px 14px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 60px;
  height: 100%;
  color: ${({ isAvailable }) =>
    isAvailable ? Colors["Midnight"] : Colors["Midnight 40%"]};
`;
