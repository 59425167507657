import { useBreakpoint } from "../../Breakpoints";

const GetViewportColumn = (column, columnTablet, columnMobile) => {
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints["DesktopMobile"];
  const isTablet = breakpoints["ScreenWidth"];

  if (isMobile) {
    return columnMobile;
  } else if (isTablet) {
    return columnTablet;
  } else {
    return column;
  }
};

export default GetViewportColumn;
