import styled from "styled-components";
import { Breakpoints } from "../../constants/constants";
import { CTATextWrapper } from "../CTA/CTAStyled";
import { CUFilterCTAWrapper } from "../CUFilterCTA/CUFilterCTAStyled";
import { CheckboxContainer } from "../FormComponents/CheckboxInput/CheckboxInputStyled";

export const CUFiltersWrapper = styled.div``;

export const FilterTitle = styled.div`
  ${CTATextWrapper} {
    color: #243953;
    font-weight: bold;
    margin-right: 10px;
    font-size: 11px;
  }

  display: flex;
  cursor: pointer;

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    display: ${({ showDesktopFilters }) =>
      showDesktopFilters ? "none" : "flex"};
  }
`;

export const AppliedDesktopFilters = styled.div`
  ${CUFilterCTAWrapper} {
    display: none;
  }
  display: flex;
  flex-wrap: wrap;
  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    ${CUFilterCTAWrapper} {
      display: flex;
      margin-right: 6px;
      margin-bottom: 5px;
    }
  }
`;

export const FilterTitleSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  @media screen and (min-width: ${Breakpoints.SmallMobile}) {
    margin-bottom: 16px;
  }
`;

export const DesktopFilters = styled.div`
  display: none;
  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    display: ${({ showDesktopFilters }) =>
      showDesktopFilters ? "flex" : "none"};
  }
`;

export const CloseButton = styled.button`
  background: #fff;
  border: 1px solid #a7b0ba;
  border-radius: 100%;
  padding: 10px;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 15px;
  top: 17px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${Breakpoints.ScreenWidth}) {
    top: 14px;
  }
`;

export const NumberOfFabrics = styled.div``;

export const AppliedFilters = styled.div`
  margin-bottom: 8px;
  display: flex;
  max-width: calc(100vw - 32px);
  @media screen and (min-width: ${Breakpoints.SmallMobile}) {
    margin-bottom: 30px;
  }
`;

export const ClearAll = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  min-width: 70px;
  cursor: pointer;
  text-transform: uppercase;
  p {
    white-space: nowrap;
  }
  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    margin-left: 10px;
  }
`;

export const CUFilterPanel = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 9999;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;

  &.slide-in {
    transform: translateX(0);
  }

  &.slide-out {
    transform: translateX(100%);
  }
`;

export const CUFilterPanelContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px 16px 16px 16px;
  height: calc(100% - 60px);
`;

export const FilterPanelTitle = styled.div`
  margin-bottom: 40px;
`;

export const ColorFilters = styled.div`
  padding-bottom: 32px;
  border-bottom: 1px solid #a7b0ba;
`;

export const ColorFiltersTitle = styled.div`
  margin-bottom: 19px;
  font-weight: bold;
`;

export const DesignFilters = styled.div`
  padding: 32px 0;
  border-bottom: 1px solid #a7b0ba;

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    ${CheckboxContainer} {
      padding-left: 2px;
    }
  }
`;

export const DesignFiltersTitle = styled.div`
  margin-bottom: 23px;
`;

export const FabricFilters = styled.div`
  padding: 32px 0;
  border-bottom: 1px solid #a7b0ba;
`;

export const QuickshipFilters = styled.div`
  padding: 32px 0;
  border-bottom: 1px solid #a7b0ba;
`;

export const FabricFiltersTitle = styled.div`
  margin-bottom: 23px;
`;

export const FilterInputs = styled.div`
  overflow: auto;
`;

export const FilterInputsOverflow = styled.div`
  overflow-y: auto;
  height: 100%;
  > div:last-of-type {
    border-bottom: none;
  }
`;

export const FilterApplyContainer = styled.div`
  padding-top: 16px;
  background: #fff;
  display: flex;
  align-items: center;

  a {
    width: 100%;
  }
`;
