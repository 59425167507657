import styled from "styled-components";
import { Colors } from "../../constants/constants";

export const MenuItemWrapper = styled.div`
  background-color: ${(props) => props.backgroundColor};
  padding: 0 15px;
`;

export const MenuItem = styled.a`
  background-color: ${(props) => props.backgroundColor};
  text-decoration: none;
  padding: 20px 0 15px;
  display: block;
  border-bottom: 1px solid ${Colors["Border Gray"]}
`;
