/* eslint-disable complexity */
import { Colors } from "@serenaandlily/constants/constants";
import React from "react";
import CTA from "../CTA";
import Icons from "../Icons";
import { ShowOptionContainer } from "./FilterByStyled";

export const FilterShowHideOptions = ({
  onClickShowLess,
  onClickShowMore,
  showOptionsTheme,
  isShowMoreVisible,
  isShowLessVisible
}) => {
  return (
    <React.Fragment>
      {isShowMoreVisible && !isShowLessVisible && (
        <ShowOptionContainer>
          <Icons width={24} height={24} fill={Colors["Midnight"]} type="FigmaPlus" />
          <CTA
            theme={showOptionsTheme}
            cta={{
              ctaText: "Show more",
              onCtaClick: onClickShowMore
            }}
            useButtonTag={true}
          />
        </ShowOptionContainer>
      )}
      {isShowLessVisible && (
        <ShowOptionContainer>
          <Icons
            width={24}
            height={24}
            fill={Colors["Midnight"]}
            type="FigmaMinus"
          />

          <CTA
            theme={showOptionsTheme}
            cta={{
              ctaText: "Show less",
              onCtaClick: onClickShowLess
            }}
            useButtonTag={true}
          />
        </ShowOptionContainer>
      )}
    </React.Fragment>
  );
};
