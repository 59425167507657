import styled from "styled-components";

export const Picture = styled.picture`
  width: 100%;
`;

export const PictureContainer = styled.div`
  display: flex;
  margin: auto;
`;
