import styled from "styled-components";
import MSFluidTypography from "../../utils/MSFluidTypography";
import getFontValue from "../../utils/getFontValue";

export const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.textOrientation === "Horizontal" ? "row" : "column"};
  ${(props) => {
    switch (props.textAlignment) {
      case "Right":
        return `
            text-align: right;
            `;
      case "Center":
        return `
            text-align: center;
            `;
      default:
        return `
            text-align: left;
            `;
    }
  }}
  height: ${(props) => `${props.height}px`};
  width: ${(props) => `${props.width}px`};
  background: ${({ backgroundColor }) => backgroundColor || "transparent"};
`;

export const HeadlineTextContainer = styled.div`
  text-align: ${({ textOrientation }) => textOrientation};
  margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
`;

export const HeadlineRichText = styled.p`
  color: ${({ richTextColor }) => richTextColor};
  ${({ richTextSize }) => MSFluidTypography.calculateFont(richTextSize)};
  margin-top: 0;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  font-family: ${({ richTextFont }) => getFontValue(richTextFont)};
`;
