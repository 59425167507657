import React, { useState, useEffect } from "react";
import {
  AccessibilityButton,
  AccessibilityIcon,
  AccessibilityMessage
} from "./AccessibilityToggleStyled";
import { getCookieValue } from "../../utils/getCookieValue";

const AccessibilityToggle = () => {
  const ENTER_KEY = 13;
  const SPACE_KEY = 32;
  const [focused, setFocused] = useState(false);
  const [modeEnabled, setModeEnabled] = useState(false);

  useEffect(() => {
    const isEnabled = getCookieValue("accessibleMode");
    if (isEnabled === "enabled") {
      setModeEnabled(true);
      document.body.classList.add("accessible-mode-enabled");
    } else {
      document.cookie = "accessibleMode=disabled";
    }
  }, []);

  const handleClick = (e) => {
    if (e.keyCode !== ENTER_KEY && e.keyCode !== SPACE_KEY) return;
    if (focused) {
      if (modeEnabled) {
        document.body.classList.remove("accessible-mode-enabled");
        document.cookie = "accessibleMode=disabled";
        setModeEnabled(false);
      } else {
        document.body.classList.add("accessible-mode-enabled");
        document.cookie = "accessibleMode=enabled";
        setModeEnabled(true);
      }
    }
  };

  return (
    <AccessibilityButton
      tabindex="0"
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onKeyDown={(e) => handleClick(e)}
      modeEnabled={modeEnabled}
    >
      <AccessibilityIcon />
      <AccessibilityMessage>
        {modeEnabled
          ? "KEYBOARD NAVIGATION & SCREEN-READER MODE ACTIVATED"
          : "USE KEYBOARD NAVIGATION & SCREEN-READER MODE"}
      </AccessibilityMessage>
    </AccessibilityButton>
  );
};

export default AccessibilityToggle;
