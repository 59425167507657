import styled from "styled-components";
import { Typography } from "../../constants/constants";
import { css } from "styled-components";
import { Breakpoints } from "../../constants/constants";
import {
  EmblaSlide,
  EmblaSlideInner,
  NextButtonElement,
  PrevButtonElement
} from "../GenericCarousel/GenericCarouselStyled";

const getVerticalText = (verticalText, textDistance) => {
  return css`
    :before {
      position: absolute;
      content: "${verticalText}";
      right: ${textDistance}px;
      top: 50%;
      transform: translateY(-50%);
      writing-mode: vertical-rl;
      text-transform: uppercase;
      white-space: nowrap;
      font-family: ${Typography.PPFragment};
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: 2px;
      line-height: 1.43;
    }
  `;
};

export const CarouselContainer = styled.div`
  ${({
    totalMargin,
    visibleSlides,
    slideMargin,
    verticalText,
    leftOffset,
    rightOffset,
    textDistance,
    marginDesktop,
    marginMobileStyles
  }) => css`
    padding: 0 75px;

    @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
      margin: ${marginDesktop};
    }

    @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
      padding: 0;
    }

    ${marginMobileStyles}

    ${EmblaSlide} {
      min-width: unset;
      flex: 0 0 calc((100% - ${totalMargin}px) / ${visibleSlides});
      margin-left: ${slideMargin}px;

      @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
        flex: 0 0 70%;
        max-width: 300px;
      }

      & > ${EmblaSlideInner} {
        height: unset;
        display: flex;
      }
    }

    ${PrevButtonElement} {
      left: ${leftOffset}px;
    }

    ${NextButtonElement} {
      right: ${rightOffset}px;

      ${verticalText && getVerticalText(verticalText, textDistance)}
    }
  `}
`;
