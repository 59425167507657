import React from "react";

const SearchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16px"
    height="17px"
  >
    <path
      fillRule="evenodd"
      fill="rgb(56, 56, 56)"
      d="M15.999,16.046 L11.170,10.977 C12.117,9.813 12.693,8.307 12.693,6.660 C12.693,2.987 9.846,0.000 6.347,0.000 C2.847,0.000 0.000,2.987 0.000,6.660 C0.000,10.332 2.847,13.320 6.347,13.320 C7.815,13.320 9.163,12.790 10.240,11.908 L15.090,17.000 L15.999,16.046 ZM6.347,11.971 C3.556,11.971 1.285,9.588 1.285,6.660 C1.285,3.731 3.556,1.349 6.347,1.349 C9.137,1.349 11.408,3.731 11.408,6.660 C11.408,9.588 9.137,11.971 6.347,11.971 Z"
    />
  </svg>
);

export default SearchIcon;
