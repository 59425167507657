import styled from "styled-components";

export const AccordionMenuWrapper = styled.div`
  width: 100%;
  a {
    :focus {
      outline: solid;
    }
  }
`;

export const AccordionMenuItemWrapper = styled.div``;

export const AccordionMenuItemTitle = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ level }) => `16px 24px 16px ${(level + 1) * 24}px`};
  background-color: ${({ isExpanded, hoverColor }) =>
    isExpanded ? hoverColor : ""};
  touch-action: manipulation;
`;

export const ExpandButton = styled.button`
  background: none;
  border: none;
  font-size: 0;
`;

export const AccordionMenuIconMenuContainer = styled.div``;

export const AccordionMenuItemContent = styled.div`
  max-height: ${({ isExpanded }) => (isExpanded ? `fit-content` : `0`)};
  overflow: ${({ isLastLevel, isExpanded }) =>
    isLastLevel && isExpanded ? "scroll" : "hidden"};
  -webkit-transition: max-height ease 0.2s;
  transition: max-height ease 0.2s;
`;
