import React from "react";
import PropTypes from "prop-types";
import { CUFilterCTAWrapper } from "./CUFilterCTAStyled";
import CTA from "../CTA";
import { useBreakpoint } from "../Breakpoints";
import useComponentTheme from "../Theme/useComponentTheme";
import defaultTheme from "./CUFilterCTATheme";

const CUFilterCTA = ({
  filterName,
  filterType,
  selectedColors,
  setSelectedColors,
  selectedDesigns,
  setSelectedDesigns,
  selectedFabrics,
  setSelectedFabrics,
  selectedFilters,
  setSelectedFilters,
  setIsQuickshipSelected,
  theme = defaultTheme
}) => {
  const filterBarTheme = useComponentTheme({ theme });
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints["DesktopMobile"];
  const isTablet = breakpoints["ScreenWidth"];
  const chipsMobileOverrides =
    (isMobile || isTablet) && filterBarTheme?.filterBarChips?.mobileOverrides;
  const handleRemoveFilter = (e) => {
    e.preventDefault();
    if (filterType === "colors") {
      const newSelectedColors = { ...selectedColors };
      const colors = Object.keys(newSelectedColors);
      newSelectedColors[filterName] = false;
      const newColorsArray = colors.filter((color) => newSelectedColors[color]);

      setSelectedColors(newSelectedColors);
      setSelectedFilters({
        ...selectedFilters,
        ["selectedColors"]: newColorsArray
      });
    } else if (filterType === "grades") {
      const newSelectedDesigns = { ...selectedDesigns };
      newSelectedDesigns[filterName] = false;
      const designs = Object.keys(newSelectedDesigns);
      const newDesignsArray = designs.filter(
        (design) => newSelectedDesigns[design]
      );
      setSelectedDesigns(newSelectedDesigns);
      setSelectedFilters({
        ...selectedFilters,
        ["selectedGrades"]: newDesignsArray
      });
    } else if (filterType === "types") {
      const newSelectedFabrics = { ...selectedFabrics };
      newSelectedFabrics[filterName] = false;
      const fabrics = Object.keys(newSelectedFabrics);
      const newFabricsArray = fabrics.filter(
        (fabric) => newSelectedFabrics[fabric]
      );
      setSelectedFabrics(newSelectedFabrics);
      setSelectedFilters({
        ...selectedFilters,
        ["selectedTypes"]: newFabricsArray
      });
    } else if (filterType === "isQuickshipSelected") {
      setIsQuickshipSelected(false);
      setSelectedFilters({
        ...selectedFilters,
        ["isQuickshipSelected"]: false
      });
    }
  };

  return (
    <CUFilterCTAWrapper>
      <CTA
        cta={{
          ctaText: filterName === "Coral" ? "Red" : filterName,
          onCtaClick: (v) => handleRemoveFilter(v)
        }}
        themeName={filterBarTheme?.filterBarChips?.themeName}
        {...chipsMobileOverrides}
      />
    </CUFilterCTAWrapper>
  );
};

export default CUFilterCTA;

CUFilterCTA.propTypes = {
  filterName: PropTypes.string,
  filterType: PropTypes.string,
  selectedColors: PropTypes.object,
  setSelectedColors: PropTypes.func,
  selectedDesigns: PropTypes.object,
  setSelectedDesigns: PropTypes.func,
  selectedFabrics: PropTypes.object,
  setSelectedFabrics: PropTypes.func,
  selectedFilters: PropTypes.object,
  setSelectedFilters: PropTypes.func,
  setIsQuickshipSelected: PropTypes.func
};
