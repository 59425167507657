import { gql } from "@apollo/client";

const buildGetProductPriceQuery = (variables) => {
  const idDef = variables?.productId ? "$id: String" : "";
  const id = variables?.productId ? "id: $id" : "";

  const customUpholsteryOptionsDef = variables?.customUpholsteryOptions
    ? "$customUpholsteryOptions: CustomUpholsteryOptions"
    : "";
  const customUpholsteryOptions = variables?.customUpholsteryOptions
    ? "customUpholsteryOptions: $customUpholsteryOptions"
    : "";

  const customerEmailDef = variables?.customerEmail
    ? "$customerEmail: String"
    : "";
  const customerEmail = variables?.customerEmail
    ? "customerEmail: $customerEmail"
    : "";

  const guestTokenDef = variables?.guestToken ? "$guestToken: String" : "";
  const guestToken = variables?.guestToken ? "guestToken: $guestToken" : "";

  const contextDef = variables?.context ? "$context: String" : "";
  const context = variables?.context ? "context: $context" : `context: "PDP"`;

  const maxPromoMessages = "maxPromoMessages: 3";

  return gql`
  query GetProductPrice(${idDef} ${customUpholsteryOptionsDef} ${contextDef} ${guestTokenDef} ${customerEmailDef}) {
    getPrice(${id} ${customUpholsteryOptions} ${context} ${maxPromoMessages} ${guestToken} ${customerEmail}) {
      isRetailAssortment
      isRetailExclusive
      promoMessages {
        message
        isBold
        fontColor
        backgroundColor
      }
      displayPrice {
        withoutTax {
          amount
          includes_tax
          currency
          formatted
        }
      }
      prices {
        type
        amount
        formatted
      }
      originalDisplayPrice {
        withoutTax {
          amount
        }
      }
      scene7Url
      wishlistHasProduct
    }
  }
`;
};

export default buildGetProductPriceQuery;
