const defaultTheme = {
  filterBarChips: {
    themeName: "CTA Chips",
    mobileOverrides: {
      fontSizeDesktop: 12,
      fontSizeTablet: 12
    }
  }
};

export default defaultTheme;
