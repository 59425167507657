import React from "react";
import PropTypes from "prop-types";
import {
  PDPPrintContainer,
  ImageContainer,
  Image,
  ProductName,
  ItemInfo,
  DimensionAndCare,
  TextContainer,
  Logo,
  LogoContainer
} from "./PDPPrintStyled";
import RichText from "../RichText";
import Text from "../Text";
import useComponentTheme from "../Theme/useComponentTheme";
import defaultTheme from "./PDPPrintTheme";

const PDPPrint = React.forwardRef(
  (
    {
      productName,
      price,
      description,
      specsAndCare,
      image,
      variations,
      theme = defaultTheme
    },
    ref
  ) => {
    const pdpPrintTheme = useComponentTheme({ theme });

    const productVariations = Object.keys(variations);

    return (
      <PDPPrintContainer ref={ref}>
        <LogoContainer>
          <Logo
            src="https://cdn.media.amplience.net/i/serenaandlily/logo-2x"
            alt="Serena and Lily"
          />
        </LogoContainer>
        <ImageContainer>
          <Image src={image} alt={productName} />
        </ImageContainer>
        <ProductName>
          <Text
            copy={productName}
            themeName={pdpPrintTheme?.productName?.themeName}
          />
        </ProductName>
        <ItemInfo>
          <Text
            copy={`PRICE: ${price?.formattedListPrice}`}
            themeName={pdpPrintTheme?.itemInfo?.themeName}
          />
        </ItemInfo>
        {productVariations &&
          productVariations.map((variation, idx) => {
            const variationName = variations[variation]?.name;
            return (
              <ItemInfo key={`${variation}-${idx}`}>
                <Text
                  copy={`${variation}: ${variationName}`}
                  themeName={pdpPrintTheme?.itemInfo?.themeName}
                />
              </ItemInfo>
            );
          })}
        <TextContainer>
          <RichText
            richText={description}
            themeName={pdpPrintTheme?.description?.themeName}
          />
        </TextContainer>
        <DimensionAndCare>
          <Text
            copy="DIMENSION AND CARE"
            themeName={pdpPrintTheme?.descriptionTitle.themeName}
          />
        </DimensionAndCare>
        <TextContainer>
          <RichText
            richText={specsAndCare}
            themeName={pdpPrintTheme?.description?.themeName}
          />
        </TextContainer>
      </PDPPrintContainer>
    );
  }
);

export default PDPPrint;

PDPPrint.displayName = "PDPPrint";

PDPPrint.propTypes = {
  image: PropTypes.string,
  price: PropTypes.object,
  productName: PropTypes.string,
  alternateColors: PropTypes.array,
  description: PropTypes.array,
  specsAndCare: PropTypes.array,
  theme: PropTypes.object,
  variations: PropTypes.object
};
