import styled from "styled-components";

export const IconLinksWrapper = styled.div`
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : "0"};
`;

export const LinksContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: ${({ orientation }) =>
    orientation === "Horizontal" ? "row" : "column"};
  margin-right: ${({ horizontalSpacing }) => `${horizontalSpacing}px`};
`;

export const Icon = styled.img`
  margin: ${({ orientation, iconSpacing }) =>
    orientation === "Horizontal"
      ? `0 ${iconSpacing}px 0 0`
      : `0 0 ${iconSpacing}px 0`};
`;
