import React from "react";
import PropTypes from "prop-types";
import AddNoteModalContent from "../AddNoteModalContent";
import WishlistModal from "../WishlistModal";

const AddNoteModal = ({
  isOpen,
  onClose,
  imageUrl,
  productName,
  maxNoteLength,
  netsuiteId,
  note,
  customUpholsteryOptions,
  ga4Props
}) => {
  return (
    <WishlistModal isOpen={isOpen} onClose={onClose}>
      <AddNoteModalContent
        imageUrl={imageUrl}
        productName={productName}
        customUpholsteryOptions={customUpholsteryOptions}
        maxNoteLength={maxNoteLength}
        netsuiteId={netsuiteId}
        note={note}
        ga4Props={ga4Props}
      />
    </WishlistModal>
  );
};

AddNoteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  imageUrl: PropTypes.string,
  productName: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
  maxNoteLength: PropTypes.number.isRequired,
  netsuiteId: PropTypes.string.isRequired,
  note: PropTypes.string,
  onSubmit: PropTypes.func.isRequired
};

export default AddNoteModal;
