import styled from "styled-components";
import { Colors } from "../../constants/constants";
import { CallToAction } from "../CTA/CTAStyled";

export const AddToCartWrapper = styled.div`
  > ${CallToAction} {
    width: 100%;
    text-align: center;
    cursor: ${({ isDisabled }) => (!isDisabled ? "pointer" : "default")};
    pointer-events: ${({ isDisabled }) => (!isDisabled ? "auto" : "none")};
    background-color: ${({ isDisabled }) =>
      !isDisabled ? "" : Colors["Medium Primary"]};
  }
`;
