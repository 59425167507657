import { Colors, Typography } from "@serenaandlily/constants/constants";

const defaultTheme = {
  fabricTitle: {
    tag: "h3",
    fontFamily: "PP Fragment",
    fontColor: "Midnight",
    fontSize: "20px",
    fontSizeTablet: "24px",
    fontSizeDesktop: "32px"
  },
  fabricDescription: {
    tag: "p",
    fontFamily: Typography.BeatriceLight,
    fontColor: "Midnight",
    fontSize: "14px",
    fontSizeTablet: "14px",
    fontSizeDesktop: "14px"
  },
  fabricTypeTitle: {
    tag: "h2",
    fontFamily: Typography.BeatriceRegular,
    fontColor: Colors["Midnight 70%"],
    letterSpacing: "0.04em",
    fontSize: "10px",
    fontSizeTablet: "12px",
    fontSizeDesktop: "12px",
    fontWeight: "600"
  }
};

export default defaultTheme;
