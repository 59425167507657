import getBaseUrl from "@lib/getBaseURL";

export default function stringToBaseUrl(url) {
  const urlObj = new URL(url);

  const pathname = urlObj.pathname;

  const search = urlObj.search;
  const hash = urlObj.hash;
  const pathWithQueryAndHash = `${pathname}${search}${hash}`;

  const baseUrl = getBaseUrl();

  const fullUrl = `${baseUrl}${pathWithQueryAndHash}`;

  return fullUrl;
}
