import styled from "styled-components";
import { Breakpoints } from "../../constants/constants";

export const Container = styled.div``;

export const SlideOutNav = styled.ul`
  list-style: none;
  padding: 0;
  width: 250px;
  overflow: scroll;
  margin-left: 8px;
  margin-right: 8px;
  
 
  @media screen and (min-width: ${Breakpoints.IPad}) {
    min-width: 250px;
    transition: 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 24px;
  }
`;

export const ItemsWrapper = styled.div``;

export const BlankSpace = styled.div`
  height: -webkit-fill-available;
`;

export const SlideOutListItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: ${({ hoverColor, isSelected }) =>
    isSelected ? `${hoverColor}` : "transparent"};
  border-radius: 24px;

  &.toggle {
    background-color: ${({ hoverColor }) => `${hoverColor}`};
  }

  @media screen and (min-width: ${Breakpoints.IPad}) {
    &:hover {
      background-color: ${({ hoverColor }) => `${hoverColor}`};
    }
  }
`;

export const SubNavItemButton = styled.button`
  border: none;
  background-color: transparent;
  text-align: end;
  width: 100%;

  & > a {
    width: 100%;
    & > span {
      width: 100%;
      display: flex;
      & > div {
        display: flex;
        align-self: center;
      }
    }
  }
`;
