import styled from "styled-components";
import { Breakpoints } from "../../constants/constants";
import {
  ArrowButtonNext,
  ArrowButtonPrev,
  EmblaCarousel
} from "../ProductImageCarousel/ProductImageCarouselStyled";

export const ProductDisplayContainer = styled.div`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
`;

export const ProductCarousel = styled.div`
  ${({ theme, isMobile, isQuickview, isDesktop }) => `
    position: sticky;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: ${isMobile ? "center" : "flex-start"};
    flex-basis: 100%;
    border-style: solid;
    border-color: ${theme?.productCarousel?.borderColor};
    border-width: ${isMobile ? 0 : `0 1px 0 0`};
    padding: ${isDesktop ? "0" : ""};
    border: 0;
    margin-bottom: 0px;
    
    @media screen and (min-width: ${Breakpoints.IPad}) {
      padding: 0 24px;
    }
    ${isQuickview ? "width: 329px" : ""};


  `}
  ${EmblaCarousel} {
    @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
      padding: 0 30px 20px 30px;
    }

    @media (min-width: ${Breakpoints.IPhone11ProMax}) {
      padding: 0;
    }
  }

  ${ArrowButtonNext} {
    position: absolute;
    right: 10px;
  }

  ${ArrowButtonPrev} {
    left: 10px;
  }
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  max-width: none;
  @media screen and (min-width: ${Breakpoints.IPhone11ProMax}) {
    max-width: 460px;
    width: 100%;
  }
`;

export const AccordionContainer = styled.div`
  margin-bottom: 24px;
`;

export const DisplayPrice = styled.div`
  display: flex;
  width: fit-content;
  margin: 0 0 12px;
  padding-top: 18px;
  border-top: 1px solid ${({ theme }) => theme?.price?.borderColor};
`;

export const ProductCalloutWrapper = styled.div`
  margin: 0;
  height: 16px;
  border-bottom: 1px solid ${({ theme }) => theme?.productCallout?.borderColor};
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 1.33;
  text-transform: uppercase;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 1px;
  margin-bottom: 8px;
`;

export const ProductDisplayPrintWrapper = styled.div`
  @media screen and (min-width: ${Breakpoints.IPhone11ProMax}) {
    width: 100%;
    max-width: 60%;
  }
`;

export const BannerWrapper = styled.div`
  margin-top: ${({ marginTop }) => marginTop || 0}px;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
`;
