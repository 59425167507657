
/**
 * 
 * @param {object} obj 
 * @param {Array} keysToRemove 
 * @returns a new object with the keys present is keysToRemove removed
 */
export const removeKeysInObject = (obj, keysToRemove) => {
  let result = {};
  for (let key in obj) {
    if (!keysToRemove.includes(key)) {
      result[key] = obj[key];
    }
  }
  return result;
}

/**
 * 
 * @param {object} obj 
 * @param {Array} keysToKeep 
 * @returns a new object with only the keys present in keysToKeep
 */
export const keepOnlyKeysInObject = (obj, keysToKeep) => {
  let result = {};
  for (let key in obj) {
    if (keysToKeep.includes(key)) {
      result[key] = obj[key];
    }
  }
  return result;
}

/**
 * 
 * @param {object} obj 
 * @param {object} keysToRename mapping of key name -> new key name
 * @returns a new object with some of its keys renamed (as per keysToRename)
 */
export const renameKeysInObject = (obj, keysToRename)  => {
  let result = {};
  for (let key in obj) {
    if (Object.keys(keysToRename).includes(key)) {
      const newKey = keysToRename[key]
      result[newKey] = obj[key];
    } else {
      result[key] = obj[key]
    }
  }
  return result;
}