import { Breakpoints, GridSpacing } from "../../../constants/constants";
import { css } from "styled-components";
import getTransition from "../../../utils/getTransition";

const DESKTOP_STYLES = {
  "Wide Margin": css`
    ${getTransition()}

    max-width: 100%;
    margin-left: calc(${GridSpacing.GridGutterMobile} * 0.5);
    margin-right: calc(${GridSpacing.GridGutterMobile} * 0.5);

    @media screen and (min-width: ${Breakpoints.IPad}) {
      margin: 0 calc(87px - calc(${GridSpacing.GridGutter} * 0.5));
    }

    @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
      margin: 0 calc(110px - calc(${GridSpacing.GridGutter} * 0.5));
    }

    @media screen and (min-width: ${Breakpoints.DesktopLarge}) {
      max-width: calc(${Breakpoints.DesktopLarge} - 288px);
      margin-left: auto;
      margin-right: auto;
    }
  `,
  "Full Width without Margin": css`
    margin-left: 0;
    margin-right: 0;
  `,
  "Full Width with Margin": css`
    margin-left: calc(${GridSpacing.GridGutterMobile} * 0.5);
    margin-right: calc(${GridSpacing.GridGutterMobile} * 0.5);

    @media screen and (min-width: ${Breakpoints.IPad}) {
      margin-left: calc(${GridSpacing.GridGutter} * 0.5);
      margin-right: calc(${GridSpacing.GridGutter} * 0.5);
    }
  `
};

const DEFAULT_STYLE = css``;

const getVideoContainerSizeDesktopStyles = (size) => {
  return DESKTOP_STYLES[size] || DEFAULT_STYLE;
};

export default getVideoContainerSizeDesktopStyles;
