import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import VideoJS from "./VideoJS";
import {
  VideoPlayerContainer,
  VideoWrapper,
  Caption,
  MobileImageWrapper,
  VideoHeadlinePositioning,
  VideoHeadlinesContainer,
  VideoHeadlineTextContainer,
  VideoImage
} from "./VideoPlayerStyled";
import getAssetUrl from "../../utils/getAssetUrl";
import getVideoAssetUrl from "../../utils/getVideoAssetUrl";
import Headline from "../Headline";
import { videoBreakpoints } from "./constants";
import { useBreakpoint } from "../Breakpoints";

//eslint-disable-next-line complexity
const VideoPlayer = ({
  internalLinkId,
  sizeDesktop,
  sizeMobile,
  video,
  videoQuality,
  mute,
  videoAspectRatio,
  autoplay,
  loopMode,
  userControls,
  playInLine,
  showOnMobile,
  previewImage,
  previewImageTablet,
  previewImageMobile,
  previewImageFullHeight = false,
  caption,
  captionStyle = "CTA Black",
  url,
  bottomMargin,
  headline,
  headlinePositioning,
  ccUrl,
  setResetCallback,
  isOnPdp = false
}) => {
  const playerRef = React.useRef(null);
  const videoSrc = getVideoAssetUrl(video, videoQuality);
  const breakpoints = useBreakpoint();
  const isTablet = breakpoints["IPad"];
  const isMobile = breakpoints["DesktopMobile"];
  const showVideoOnMobile = !isMobile || (isMobile && showOnMobile);
  const [imageAspectRatio, setImageAspectRatio] = useState();

  const handleRestart = useCallback(() => {
    playerRef?.current?.currentTime?.(0);
    playerRef?.current?.play?.();
  }, []);

  useEffect(() => {
    setResetCallback?.(() => handleRestart);
  }, []);

  const fetchPosterImg = () => {
    if (isTablet && !isMobile && previewImageTablet) {
      const { amplienceData } = previewImageTablet;
      setImageAspectRatio(amplienceData);
      return getAssetUrl(previewImageTablet);
    }

    if (isTablet && isMobile && previewImageMobile) {
      const { amplienceData } = previewImageMobile;
      setImageAspectRatio(amplienceData);
      return getAssetUrl(previewImageMobile);
    }

    const { amplienceData } = previewImage || {};
    setImageAspectRatio(amplienceData);
    return getAssetUrl(previewImage) || null;
  };

  const [posterImg, setPosterImg] = useState(fetchPosterImg);

  useEffect(() => {
    setPosterImg(fetchPosterImg);
  }, [isMobile, isTablet]);

  const options = {
    autoplay: autoplay,
    muted: mute,
    loop: loopMode,
    poster: posterImg,
    preload: "auto",
    controls: userControls,
    controlBar: {
      playToggle: userControls,
      captionsButton: false,
      chaptersButton: false,
      subtitlesButton: userControls,
      remainingTimeDisplay: false,
      progressControl: {
        seekBar: userControls
      },
      fullscreenToggle: userControls,
      playbackRateMenuButton: userControls,
      volumePanel: !mute
    },
    ...videoBreakpoints,
    sources: [
      {
        src: videoSrc,
        type: "video/mp4"
      }
    ]
  };

  const {
    breakTextOnMobile,
    xCoordinate,
    xCoordinateMobile,
    yCoordinate,
    yCoordinateMobile,
    textWidth,
    textWidthForMobile
  } = headlinePositioning || {};

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.ready(() => {
      player.playsinline(playInLine);
    });
  };

  if (!isMobile || (isMobile && showOnMobile)) {
    return (
      <VideoPlayerContainer
        id={internalLinkId}
        sizeDesktop={sizeDesktop}
        sizeMobile={sizeMobile || "Full Width"}
        videoAspectRatio={videoAspectRatio}
      >
        <VideoWrapper
          isMobile={isMobile}
          bottomMargin={bottomMargin}
          videoAspectRatio={videoAspectRatio}
        >
          <VideoJS
            options={options}
            ccUrl={ccUrl}
            onReady={handlePlayerReady}
            showVideoOnMobile={showVideoOnMobile}
            isOnPdp={isOnPdp}
            videoAspectRatio={videoAspectRatio}
          />
          <VideoHeadlinePositioning
            xCoordinate={xCoordinate}
            xCoordinateMobile={xCoordinateMobile}
            yCoordinate={yCoordinate}
            yCoordinateMobile={yCoordinateMobile}
          >
            <VideoHeadlinesContainer breakTextOnMobile={breakTextOnMobile}>
              <VideoHeadlineTextContainer
                textWidth={textWidth}
                textWidthForMobile={textWidthForMobile}
              >
                {headline && <Headline {...headline} />}
              </VideoHeadlineTextContainer>
            </VideoHeadlinesContainer>
          </VideoHeadlinePositioning>
        </VideoWrapper>
        {caption && (
          <Caption href={url} captionStyle={captionStyle}>
            {caption}
          </Caption>
        )}
      </VideoPlayerContainer>
    );
  } else {
    return (
      <MobileImageWrapper
        sizeDesktop={sizeDesktop}
        sizeMobile={sizeMobile || "Full Width"}
        imageAspectRatio={imageAspectRatio}
      >
        <VideoImage
          src={posterImg}
          alt={previewImage?.name}
          imageAspectRatio={imageAspectRatio}
          fullHeight={previewImageFullHeight}
        />
        <VideoHeadlinePositioning
          xCoordinate={xCoordinate}
          xCoordinateMobile={xCoordinateMobile}
          yCoordinate={yCoordinate}
          yCoordinateMobile={yCoordinateMobile}
        >
          <VideoHeadlinesContainer breakTextOnMobile={breakTextOnMobile}>
            <VideoHeadlineTextContainer
              textWidth={textWidth}
              textWidthForMobile={textWidthForMobile}
            >
              {headline && <Headline {...headline} />}
            </VideoHeadlineTextContainer>
          </VideoHeadlinesContainer>
        </VideoHeadlinePositioning>
      </MobileImageWrapper>
    );
  }
};

export default VideoPlayer;

VideoPlayer.propTypes = {
  internalLinkId: PropTypes.string,
  sizeDesktop: PropTypes.string,
  sizeMobile: PropTypes.string,
  video: PropTypes.object,
  mute: PropTypes.bool,
  autoplay: PropTypes.bool,
  loopMode: PropTypes.bool,
  userControls: PropTypes.bool,
  playInLine: PropTypes.bool,
  showOnMobile: PropTypes.bool,
  previewImage: PropTypes.object,
  caption: PropTypes.string,
  captionStyle: PropTypes.string,
  url: PropTypes.string,
  bottomMargin: PropTypes.number,
  videoQuality: PropTypes.string,
  headline: PropTypes.object,
  ccUrl: PropTypes.string
};
