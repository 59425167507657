import React from "react";
import { Colors } from "../../../constants/constants";

const InspirationLogo = () => {
  return (
    <svg
      height="24"
      width="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0V0Z"></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5625 5.625C5.1493 5.625 5.625 5.1493 5.625 4.5625C5.625 3.9757 5.1493 3.5 4.5625 3.5C3.9757 3.5 3.5 3.9757 3.5 4.5625C3.5 5.1493 3.9757 5.625 4.5625 5.625Z"
          fill={Colors["Dark Primary"]}
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 5.625C12.5868 5.625 13.0625 5.1493 13.0625 4.5625C13.0625 3.9757 12.5868 3.5 12 3.5C11.4132 3.5 10.9375 3.9757 10.9375 4.5625C10.9375 5.1493 11.4132 5.625 12 5.625Z"
          fill={Colors["Dark Primary"]}
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.4375 5.625C20.0243 5.625 20.5 5.1493 20.5 4.5625C20.5 3.9757 20.0243 3.5 19.4375 3.5C18.8507 3.5 18.375 3.9757 18.375 4.5625C18.375 5.1493 18.8507 5.625 19.4375 5.625Z"
          fill={Colors["Dark Primary"]}
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5625 13.0625C5.1493 13.0625 5.625 12.5868 5.625 12C5.625 11.4132 5.1493 10.9375 4.5625 10.9375C3.9757 10.9375 3.5 11.4132 3.5 12C3.5 12.5868 3.9757 13.0625 4.5625 13.0625Z"
          fill={Colors["Dark Primary"]}
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 13.0625C12.5868 13.0625 13.0625 12.5868 13.0625 12C13.0625 11.4132 12.5868 10.9375 12 10.9375C11.4132 10.9375 10.9375 11.4132 10.9375 12C10.9375 12.5868 11.4132 13.0625 12 13.0625Z"
          fill={Colors["Dark Primary"]}
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.4375 13.0625C20.0243 13.0625 20.5 12.5868 20.5 12C20.5 11.4132 20.0243 10.9375 19.4375 10.9375C18.8507 10.9375 18.375 11.4132 18.375 12C18.375 12.5868 18.8507 13.0625 19.4375 13.0625Z"
          fill={Colors["Dark Primary"]}
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5625 20.5C5.1493 20.5 5.625 20.0243 5.625 19.4375C5.625 18.8507 5.1493 18.375 4.5625 18.375C3.9757 18.375 3.5 18.8507 3.5 19.4375C3.5 20.0243 3.9757 20.5 4.5625 20.5Z"
          fill={Colors["Dark Primary"]}
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 20.5C12.5868 20.5 13.0625 20.0243 13.0625 19.4375C13.0625 18.8507 12.5868 18.375 12 18.375C11.4132 18.375 10.9375 18.8507 10.9375 19.4375C10.9375 20.0243 11.4132 20.5 12 20.5Z"
          fill={Colors["Dark Primary"]}
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.4375 20.5C20.0243 20.5 20.5 20.0243 20.5 19.4375C20.5 18.8507 20.0243 18.375 19.4375 18.375C18.8507 18.375 18.375 18.8507 18.375 19.4375C18.375 20.0243 18.8507 20.5 19.4375 20.5Z"
          fill={Colors["Dark Primary"]}
        ></path>
      </g>
    </svg>
  );
};

export default InspirationLogo;
