import React from "react";
import PropTypes from "prop-types";
import ProductImageCarousel from "../ProductImageCarousel";
import ProductImage from "../ProductImage";
import ProductOptions from "../ProductOptions";
// import Accordion from "../Accordion";
import MediaLinksSection from "../MediaLinksSection";
import ProductName from "../ProductName";
import {
  ProductDisplayContainer,
  ProductCarousel,
  ProductInfo,
  // AccordionContainer,
  ProductCalloutWrapper,
  ProductDisplayPrintWrapper,
  BannerWrapper
} from "./ProductDisplayStyled";
import { useBreakpoint } from "../Breakpoints";
import { useProductContext } from "../../contexts/ProductContext";
import { useGlobalProducts } from "../../contexts/GlobalProductsContext";
import useComponentTheme from "../Theme/useComponentTheme";
import { ThemeProvider } from "styled-components";
import defaultTheme from "./ProductDisplayTheme";
import Text from "../Text";
import formatProductAccordionData from "../../utils/formatProductAccordionData";
import getCurrentVariationChildIDs from "../../utils/getCurrentVariationChildIDs";
import Banner from "../Banner";
import WishlistHeart from "../WishlistHeart";

// eslint-disable-next-line max-lines-per-function, complexity
const ProductDisplay = ({
  cartItemId,
  cartPopupHeader,
  crossSellProducts,
  initialMpn,
  initialProductId,
  initialQty,
  initialSku,
  isEdit = false,
  isQuickview = false,
  pdpGeneralContent,
  mediaLinks,
  mobileBreakpoint,
  pdpProps,
  swatchHeadings = {},
  swatchImages,
  setCartItem,
  clickData,
  pdpSettings,
  theme = defaultTheme
}) => {
  const VideoSlideLazy = React.lazy(() => import("../VideoSlide"));
  const noimageMediumSrc = `${process.env.NEXT_PUBLIC_DYNAMIC_MEDIA_URL}/s/serenaandlily/10?fmt=auto&qlt=default&fmt.jp2.qlt=60&fmt.webp.qlt=70&img404=noimagemedium`;
  const noImageMediumAlt = "No available image";
  const productDisplayTheme = useComponentTheme({ theme });
  const {
    currentVariations,
    currentProductPrice,
    productId,
    scene7Url,
    wishlistItemData,
    wishlistStatus,
    wishlistGa4Props
  } = useProductContext();
  const { getProductById } = useGlobalProducts();
  const productProps = getProductById(productId) || {};
  const { interiorsBanner } = pdpGeneralContent || {};

  const {
    name,
    specsAndCare,
    shippingAndDeliveryInfo,
    description,
    childProducts,
    productCallout
  } = pdpProps;

  const accordionData = formatProductAccordionData([
    { name: "Description", summary: description || {} },
    {
      name: "Dimensions & Care",
      summary: specsAndCare || {}
    },
    {
      name: "Shipping & Delivery",
      summary: shippingAndDeliveryInfo || {}
    }
  ]);

  const { currentChildImageSet } = getCurrentVariationChildIDs(
    currentVariations,
    childProducts
  );

  const productImageCarouselData = Object.keys(currentChildImageSet || {})
    .length
    ? currentChildImageSet
    : productProps?.imageSet;

  const breakpoints = useBreakpoint();
  const isMobile = breakpoints[mobileBreakpoint || "IPhone11ProMax"];
  const isDesktop = breakpoints["ScreenWidth"];
  const imageParams = isMobile ? "w=900&h=1200" : "w=1600&h=2000";

  const imageUrl = ({ isForPrinting = false }) => {
    const ampFormatString = `?fmt=auto&qlt=default&fmt.jp2.qlt=${
      isForPrinting ? "100" : "60"
    }&fmt.webp.qlt=${
      isForPrinting ? "100" : "70"
    }&img404=noimagemedium&unsharp=0,0.3,10,0&${imageParams}`;

    return (
      scene7Url ||
      `${productImageCarouselData?.items?.[0]?.src}${ampFormatString}`
    );
  };

  const printData = {
    productName: name,
    variations: currentVariations,
    price: currentProductPrice || {},
    image: imageUrl({ isForPrinting: true }),
    description:
      accordionData?.accordionItems[0]?.accordionSummary?.richText?.richText,
    specsAndCare:
      accordionData?.accordionItems[1]?.accordionSummary?.richText?.richText
  };

  return (
    <ThemeProvider theme={productDisplayTheme}>
      <ProductDisplayContainer isMobile={isMobile}>
        <ProductDisplayPrintWrapper data-pdpprint="false">
          {productCallout && isMobile && (
            <ProductCalloutWrapper theme={productDisplayTheme}>
              <Text
                themeName={productDisplayTheme?.productCallout?.themeName}
                copy={productCallout}
              />
            </ProductCalloutWrapper>
          )}
          {isMobile && (
            <ProductName
              name={name}
              source="pdp-"
              wishlistComponent={
                <WishlistHeart
                  itemData={wishlistItemData}
                  wishlistStatus={wishlistStatus}
                  ga4Props={wishlistGa4Props}
                />
              }
            />
          )}
          <ProductCarousel
            isMobile={isMobile}
            isQuickview={isQuickview}
            isDesktop={isDesktop}
          >
            {productImageCarouselData?.items?.length ? (
              <ProductImageCarousel
                items={productImageCarouselData.items}
                mobileBreakpoint={mobileBreakpoint}
                isQuickview={isQuickview}
                VideoSlideLazy={VideoSlideLazy}
              ></ProductImageCarousel>
            ) : (
              <ProductImage src={noimageMediumSrc} alt={noImageMediumAlt} />
            )}
          </ProductCarousel>
        </ProductDisplayPrintWrapper>
        <ProductInfo isMobile={isMobile}>
          <ProductOptions
            swatchImages={swatchImages}
            cartItemId={cartItemId}
            isEdit={isEdit}
            mobileBreakpoint={mobileBreakpoint}
            initialQty={initialQty}
            initialSku={initialSku}
            initialMpn={initialMpn}
            initialProductId={initialProductId}
            crossSellProducts={crossSellProducts}
            cartPopupHeader={cartPopupHeader}
            swatchHeadings={swatchHeadings}
            imageUrl={imageUrl({ isForPrinting: false })}
            pdpSettings={pdpSettings}
            pdpProps={pdpProps}
            isQuickview={isQuickview}
            setCartItem={setCartItem}
            clickData={clickData}
          />
          {/* commenting out accordion instead of deleting because this might be brought back post launch to support both design */}
          {/* {accordionData && (
            <AccordionContainer data-pdpprint="false">
              <Accordion
                {...accordionData}
                theme={productDisplayTheme?.accordion}
              />
            </AccordionContainer>
          )} */}
          {interiorsBanner && !isQuickview && (
            <BannerWrapper
              marginTop={interiorsBanner?.marginTop}
              marginBottom={interiorsBanner?.marginBottom}
            >
              <Banner {...interiorsBanner?.banner} />
            </BannerWrapper>
          )}
          {mediaLinks && (
            <MediaLinksSection {...mediaLinks} pdpPrintData={printData} />
          )}
        </ProductInfo>
      </ProductDisplayContainer>
    </ThemeProvider>
  );
};

export default ProductDisplay;

ProductDisplay.propTypes = {
  mediaLinks: PropTypes.object,
  swatchImages: PropTypes.array,
  mobileBreakpoint: PropTypes.string,
  cartItemId: PropTypes.string,
  isEdit: PropTypes.bool,
  initialQty: PropTypes.number,
  initialSku: PropTypes.string,
  initialMpn: PropTypes.string,
  initialProductId: PropTypes.string,
  crossSellProducts: PropTypes.object,
  cartPopupHeader: PropTypes.object,
  swatchHeadings: PropTypes.object,
  theme: PropTypes.object,
  inventories: PropTypes.object,
  clickData: PropTypes.object,
  isQuickview: PropTypes.bool,
  setCartItem: PropTypes.func,
  pdpSettings: PropTypes.object,
  pdpProps: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    productCallout: PropTypes.string,
    childProducts: PropTypes.array,
    specsAndCare: PropTypes.shape({
      content: PropTypes.array
    }),
    description: PropTypes.shape({
      content: PropTypes.array
    }),
    shippingAndDeliveryInfo: PropTypes.shape({
      shippingAndDeliveryInfo: PropTypes.array
    })
  })
};
