import styled from "styled-components";
import {
  Breakpoints,
  Colors,
  Horizontal,
  Vertical
} from "../../constants/constants";
import getFontValue from "../../utils/getFontValue";
import getTextAlign from "../../utils/getTextAlign";
import MSFluidTypography from "../../utils/MSFluidTypography";
import SnlImage from "../SnlImage";

export const InspirationTileWrapper = styled.div`
  display: flex;
  flex-direction: ${({ position }) =>
    position === "Over Image" ? "column-reverse" : "column"};
  margin-bottom: ${({ tileMarginBottom }) => tileMarginBottom};
  width: 100%;
  height: 100%;
  justify-content: ${({ tileVerticalAlignment }) =>
    Vertical[tileVerticalAlignment]};
`;

export const ImageLink = styled.a`
  margin-bottom: ${({ imagePaddingBottom }) =>
    imagePaddingBottom !== undefined ? `${imagePaddingBottom}px` : "24px"};
  cursor: pointer;
  aspect-ratio: ${({ width, height }) => `${width} / ${height}`};
`;

export const InspirationImage = styled(SnlImage)`
  :hover {
    opacity: ${({ $disableRollover }) => ($disableRollover ? "1" : "50%")};
  }
`;

export const ContentContainer = styled.figcaption`
  display: flex;
  flex-direction: column;
  align-items: ${({ mobileHorizontalAlignment, desktopHorizontalAlignment }) =>
    mobileHorizontalAlignment
      ? Horizontal[mobileHorizontalAlignment]
      : Horizontal[desktopHorizontalAlignment]};
  justify-content: ${({ mobileVerticalAlignment }) =>
    Vertical[mobileVerticalAlignment]};
  text-align: ${({ mobileHorizontalAlignment, desktopHorizontalAlignment }) =>
    mobileHorizontalAlignment
      ? mobileHorizontalAlignment
      : desktopHorizontalAlignment};

  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    align-items: ${({ desktopHorizontalAlignment }) =>
      Horizontal[desktopHorizontalAlignment]};
    justify-content: ${({ desktopVerticalAlignment }) =>
      Vertical[desktopVerticalAlignment]};
    text-align: ${({ desktopHorizontalAlignment }) =>
      desktopHorizontalAlignment};
  }
`;

export const HeadlinesContainer = styled.div`
  display: flex;
  padding: ${({ hidePadding }) => (hidePadding ? "0px" : "0 32px")};
  align-self: ${({ mobileHorizontalAlignment, desktopHorizontalAlignment }) =>
    mobileHorizontalAlignment
      ? Horizontal[mobileHorizontalAlignment]
      : Horizontal[desktopHorizontalAlignment]};

  flex-direction: ${({ mobileHorizontalOrientation }) =>
    mobileHorizontalOrientation === "Vertical" ? "column" : "row"};
  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    flex-direction: ${({ desktopHorizontalOrientation }) =>
      desktopHorizontalOrientation === "Vertical" ? "column" : "row"};

    align-self: ${({ desktopHorizontalAlignment }) =>
      Horizontal[desktopHorizontalAlignment]};
  }
`;

export const Headline = styled.span`
  font-family: ${({ fontFamily }) => getFontValue(fontFamily)};
  ${({ fontSize }) => MSFluidTypography.calculateFont(fontSize)}
  margin-bottom: ${({ bottomMargin }) => `${bottomMargin}px`};
  color: ${Colors["Dark Primary"]};
  letter-spacing: 0.0628em;
  text-align: ${({ mobileHorizontalAlignment, desktopHorizontalAlignment }) =>
    mobileHorizontalAlignment
      ? getTextAlign(mobileHorizontalAlignment)
      : getTextAlign(desktopHorizontalAlignment)};
  @media screen and (max-width: ${Breakpoints.SmallMobile}) {
    content: ${({ mobileCopy }) => `"${mobileCopy}"`};
    text-align: ${({ desktopHorizontalAlignment }) =>
      getTextAlign(desktopHorizontalAlignment)};
  }
`;

export const ParagraphContainer = styled.div`
  padding: 0 32px;
  margin-bottom: ${({ bottomMargin }) => `${bottomMargin}px`};
  p {
    margin: 0;
  }
`;

export const CTAContainer = styled.div`
  width: 100%;
  span {
    a {
      white-space: normal;
      text-align: center;
    }
  }
`;

export const ContentWrapper = styled.div``;
