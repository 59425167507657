import { Breakpoints } from "@serenaandlily/constants/constants";
import styled from "styled-components";

export const Container = styled.div`
  width: 80vw;
  padding: 32px 24px;
  @media screen and (min-width: ${Breakpoints.SMTabletMin}) {
    width: unset;
    max-width: 488px;
  }
`;
export const HeadingWrapper = styled.div`
  margin-bottom: 16px;
  margin-right: 90px;
`;
export const Heading = styled.div``;
export const ContentContainer = styled.div``;
export const Content = styled.div`
  margin-bottom: 32px;
  padding-right: 8px;
`;
export const CtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
  @media screen and (min-width: ${Breakpoints.SMTabletMin}) {
    flex-direction: row;
    align-items: center;
  }
`;
