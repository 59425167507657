import React from "react";
import { SubmenuLink, SubmenuText } from "./UtilityMenuStyled";
import USER_TYPES from "../../../constants/userTypes";
import useUser from "../../../hooks/useUser";
import { useAccount } from "../../../contexts/AccountContext";

const getGreeting = ({ firstName, name }) => {
  let greeting = "Hi!";
  let userName = firstName || name;
  if (userName) {
    greeting = `Hi, ${userName}!`;
  }
  return greeting;
};

const ProfileItems = () => {
  const { logoutCustomer } = useAccount();
  const { user: { data: { userType, firstName = "", name = "" } = {} } = {} } =
    useUser();
  const greeting = getGreeting({ firstName, name });

  switch (userType) {
    case USER_TYPES.customer:
    case USER_TYPES.trade:
      return (
        <React.Fragment>
          <SubmenuText>{greeting}</SubmenuText>
          <SubmenuLink href="/account">My Account</SubmenuLink>
          <SubmenuLink onClick={logoutCustomer} decoration="underline" href="#">
            Logout
          </SubmenuLink>
        </React.Fragment>
      );
    case USER_TYPES.dso:
      return (
        <React.Fragment>
          <SubmenuText>{greeting}</SubmenuText>
          <SubmenuLink href="/trackmyorder">Order Tracking</SubmenuLink>
          <SubmenuLink href="/estimate-search">My Estimates</SubmenuLink>
          <SubmenuLink href="/customer-search">Select Customer</SubmenuLink>
          <SubmenuLink onClick={logoutCustomer} decoration="underline" href="#">
            Logout
          </SubmenuLink>
        </React.Fragment>
      );
    case USER_TYPES.employee:
      return (
        <React.Fragment>
          <SubmenuText>{greeting}</SubmenuText>
          <SubmenuLink href="/trackmyorder">Order Tracking</SubmenuLink>
          <SubmenuLink onClick={logoutCustomer} decoration="underline" href="#">
            Logout
          </SubmenuLink>
        </React.Fragment>
      );
    default:
      return (
        <React.Fragment>
          <SubmenuLink href="/account">Log in</SubmenuLink>
          <SubmenuLink href="/trackmyorder">Order Tracking</SubmenuLink>
        </React.Fragment>
      );
  }
};

export default ProfileItems;
