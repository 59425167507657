import styled, { css } from "styled-components";

export const TextInputWrapper = styled.div`
  ${({ theme, fullWidth, marginBottom }) => `
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0;
    min-width: 0;
    width: ${fullWidth ? "100%" : "auto"};
    margin: ${theme?.forms?.fieldMargin};
    border: 0;
    margin-bottom: ${marginBottom};
    vertical-align: top;
    gap: ${theme?.forms?.fieldGap};
  `}
`;

//by default displays at the top of the form field
export const Label = styled.label`
  ${({ theme }) => `
    position: absolute;
    top: ${theme?.forms?.fieldLabelTopFocus};
    left: ${theme?.forms?.fieldPadding - 2}px;
    padding: 0 2px;
    display: block;
    transition: 0.2s;
    font-size: ${theme?.forms?.fieldLabelFontSizeFocus};
    font-family: ${theme?.forms?.fieldLabelFontFamily};
    color: ${theme?.forms?.fieldLabelColor};
    z-index: 1;
    &::after {
      position: absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      margin: auto;
      background-color: ${theme?.forms?.fieldBackgroundColor};
      height: 6px;
      width: 100%;
      transition: width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      content: "";
      z-index: -1;
    }
  `}
`;

export const InputWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: inline-flex;
  align-items: center;
  position: relative;
`;

export const EndIconWrapper = styled.div`
  position: absolute;
  top: 9px;
  right: 9px;
`;

export const inputMixin = ({ theme, hasError, showPlaceholder }) => css`
  background: ${theme?.forms?.fieldBackgroundColor};
  border-radius: 4px;
  border-style: solid;
  border-width: ${theme?.forms?.fieldBorderWidth}px;
  border-color: ${hasError
    ? theme?.forms?.fieldBorderColorError
    : theme?.forms?.fieldBorderColor};
  box-sizing: border-box;
  color: ${theme?.forms?.fieldFontColor};
  font-family: ${theme?.forms?.fieldFontFamily};
  font-size: ${theme?.forms?.fieldFontSize};
  height: ${theme?.forms?.fieldHeight};
  outline: 0;
  padding: 0 ${theme?.forms?.fieldPadding - theme?.forms?.fieldBorderWidth}px;
  transition: border-color 0.2s;
  width: 100%;

  &::placeholder {
    color: ${showPlaceholder ? "inherit" : "transparent"};
  }

  &:-webkit-autofill ~ label {
    color: “transparent”;
  }

  &:placeholder-shown ~ label {
    font-size: ${theme?.forms?.fieldLabelFontSize};
    cursor: text;
    top: ${theme?.forms?.fieldLabelTop};
  }

  &:placeholder-shown ~ label::after {
    width: 0.01px;
    transition: width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }

  &:focus ~ label,
  &.focus ~ label {
    top: ${theme?.forms?.fieldLabelTopFocus};
    transition: 0.2s;
    font-size: ${theme?.forms?.fieldLabelFontSizeFocus};
  }

  &:focus ~ label::after,
  &.focus ~ label::after {
    width: 100%;
    transition: width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }

  &.flex-microform-focused,
  &:focus,
  &.focus {
    border-color: ${theme?.forms?.fieldBorderColorFocus};
    box-shadow: 0 0 0 2px ${theme?.forms?.controlFocusColor};
  }

  &:hover {
    border-color: ${theme?.forms?.fieldBorderColorFocus};
  }

  &.flex-microform-disabled,
  &:disabled,
  &read-only {
    background: ${theme?.forms?.fieldBackgroundColorDisabled};
    color: ${theme?.forms?.fieldFontColorDisabled};
    -webkit-text-fill-color: ${theme?.forms?.fieldFontColorDisabled};
    border-color: ${theme?.forms?.fieldBorderColorDisabled};
  }

  &disabled ~ label::after,
  &read-only ~ label::after {
    background-color: ${theme?.forms?.fieldBackgroundColorDisabled};
  }

  &.flex-microform-invalid,
  .invalid & {
    border-color: ${theme?.forms?.fieldBorderColorError};
  }
`;

export const Input = styled.input`
  ${inputMixin}
`;

export const ErrorMessage = styled.div`
  ${({ theme }) => `
    color: ${theme?.forms?.fieldErrorColor};
    font-size: ${theme?.forms?.fieldErrorFontSize};
    font-family: ${theme?.forms?.fieldErrorFontFamily};
    padding: 0 0 0 ${theme?.forms?.fieldPadding}px;
  `}
`;

export const InfoMessage = styled.div`
  ${({ theme }) => `
    color: ${theme?.forms?.fieldMessageColor};
    font-size: ${theme?.forms?.fieldMessageFontSize};
    font-family: ${theme?.forms?.fieldMessageFontFamily};
    padding: 0 0 0 ${theme?.forms?.fieldPadding}px;
  `}
`;

export const RequiredIndicator = styled.span`
  color: #1b7eac;
  padding-right: 3px;
  &:after {
    content: "•";
  }
`;
