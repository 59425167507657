const defaultTheme = {
  productName: {
    themeName: "headline3",
    overrides: {
      fontSizeDesktop: "32px"
    }
  },
  productNameStlTheme: {
    fontSizeDesktop: "34px"
  }
};

export default defaultTheme;
