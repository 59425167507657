import { Breakpoints } from "@serenaandlily/constants/constants";
import styled from "styled-components";
import SnlImage from "../SnlImage";

export const RemoveFromWishlistContainer = styled.div`
  padding: 32px;
  width: 460px;
  box-sizing: border-box;

  @media screen and (max-width: ${Breakpoints.XSMobileMax}) {
    width: 350px;
  }
`;

export const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 9px;
  text-align: center;

  h3 {
    margin-bottom: 0;
  }
`;

export const Image = styled(SnlImage)`
  width: 59px;
`;

export const ConfirmationWrapper = styled.div`
  text-align: center;

  h3 {
    margin-bottom: 0;
  }

  button {
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-top: 24px;

  button {
    width: 100%;
  }
`;

export const TextWrapper = styled.div`
  width: 300px;
  margin: auto;
`;
