import styled, { css } from "styled-components";
import { typographyCss } from "../../Typography/TypographyStyled";

export const Label = styled.label`
  ${({
    theme,
    styles: { fontSize, color, fontFamily, fontWeight, style, lineHeight } = {}
  }) => css`
    ${typographyCss({ theme, themeName: "label" })}
    margin: ${theme?.forms?.radioLabelMargin};
    ${fontSize && `font-size: ${fontSize} !important`};
    ${color && `color: ${color}`};
    ${fontFamily && `font-family: ${fontFamily}`};
    ${fontWeight && `font-weight: ${fontWeight}`};
    ${style && `font-style: ${style}`};
    ${lineHeight && `line-height: ${lineHeight}`};
  `}
`;

export const CheckboxContainer = styled.div`
  height: auto;
  margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
`;

export const CheckboxInputWrapper = styled.div`
  margin-top: ${({ marginTop }) => marginTop}px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

export const Checkbox = styled.input`
  ${({ theme }) => css`
    appearance: none;
    box-sizing: border-box;
    background-color: ${theme?.forms?.controlBackgroundColor};
    border-width: ${theme?.forms?.checkboxBorderWidth};
    border-style: solid;
    border-color: ${theme?.forms?.controlBorderColor};
    border-radius: ${theme?.forms?.checkboxBorderRadius};
    height: ${theme?.forms?.checkboxSize};
    margin: 0;
    position: relative;
    width: ${theme?.forms?.checkboxSize};
    min-width: ${theme?.forms?.checkboxSize};

    & + label svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: calc(
        (${theme?.forms?.checkboxSize} - ${theme?.forms?.checkMarkSize}) / 2
      );
      width: ${theme?.forms?.checkMarkSize};
      height: ${theme?.forms?.checkMarkSize};
      stroke: none;
    }

    &:checked,
    .checked {
      background-color: ${theme?.forms?.controlBackgroundColorChecked};
      border-color: ${theme?.forms?.controlBorderColorChecked};
    }

    &:checked + label svg,
    &.checked + label svg {
      stroke: ${theme?.forms?.controlIndicatorColorChecked};
    }

    // disable browser outline on focus and use box shadow
    // workaround for an issue in safari with the outline not honoring border-radius

    @media (hover: hover) and (pointer: fine) {
      &:focus,
      &.focus {
        box-shadow: 0 0 0 ${theme?.forms?.checkboxFocusWidth}
          ${theme?.forms?.controlFocusColor};
      }

      &:hover,
      &.hover {
        background: ${theme?.forms?.controlBackgroundColorHover};
        border-color: ${theme?.forms?.controlBorderColorHover};
      }

      &:hover + label svg,
      &.hover + label svg {
        stroke: ${theme?.forms?.controlIndicatorColorHover};
      }

      &:hover:checked,
      &.hover:checked {
        background: ${theme?.forms?.controlBackgroundColorCheckedHover};
        border-color: ${theme?.forms?.controlBorderColorCheckedHover};
      }

      &:hover:checked + label svg,
      &.hover:checked + label svg {
        stroke: ${theme?.forms?.controlIndicatorColorCheckedHover};
      }
    }

    &:disabled + label {
      color: ${theme?.forms?.checkboxLabelColorDisabled};
    }

    &:disabled {
      background: ${theme?.forms?.controlIndicatorColorDisabled};
      border-color: ${theme?.forms?.controlBackgroundColorDisabled};
    }

    &:disabled:checked {
      background: ${theme?.forms?.controlBackgroundColorCheckedDisabled};
      border-color: ${theme?.forms?.controlBorderColorCheckedDisabled};
    }
  `}
`;

export const ErrorMessage = styled.div`
  ${({ theme }) => `
    color: ${theme?.forms?.fieldErrorColor};
    font-size: ${theme?.forms?.fieldErrorFontSize};
    font-family: ${theme?.forms?.fieldErrorFontFamily};
    padding: 0 0 0 ${theme?.forms?.fieldPadding}px;
  `}
`;

export const CheckboxLarge = styled(Checkbox)`
  ${({ theme }) => `
    width: ${theme?.forms?.checkboxSizeLarge};
    height: ${theme?.forms?.checkboxSizeLarge};
    
    & + label svg {
      top: calc((${theme?.forms?.checkboxSizeLarge} - ${theme?.forms?.checkMarkSizeLarge})/2);
      left: calc((${theme?.forms?.checkboxSizeLarge} - ${theme?.forms?.checkMarkSizeLarge})/2);
      width: ${theme?.forms?.checkMarkSizeLarge};
      height: ${theme?.forms?.checkMarkSizeLarge};
    }
    
        
    &:focus::before,
    &.focus::before {
      width: ${theme?.forms?.checkboxSizeLarge};
      height: ${theme?.forms?.checkboxSizeLarge};
    }
  `}
`;
