import styled from "styled-components";
import { Colors, Breakpoints } from "../../constants/constants";

export const Container = styled.div``;

export const SearchLogoWrapper = styled.button`
  background-color: ${Colors["White"]};
  border: 0;
  padding: 0;

  @media screen and (min-width: ${Breakpoints.SmallMobile}) {
    margin-right: 25px;
  }
`;

export const Wrapper = styled.div`
  display: contents;
`;

export const IconsWrapper = styled.div`
  display: flex;
  justify-content: end;

  @media screen and (min-width: ${Breakpoints.IPad}) {
    align-items: center;
    display: flex;
    ${({ isRightSide }) =>
      isRightSide ? "justify-content: flex-end; margin-left: 25px;" : ""};
    & > div {
      margin-right: 25px;
      &:last-child {
        margin-right: 11px;
      }
    }
  }
  @media screen and (max-width: ${Breakpoints.IPad}) {
    & > div {
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;
