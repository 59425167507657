import { forwardRef } from "react";
import {
  DropdownAction,
  DropdownList,
  StyledDropdownMenu
} from "./DropdownMenuStyled";

const DropdownMenu = forwardRef(({ onAddNote, onRemove, hasNote }, ref) => {
  const noteOptionText = hasNote ? "Edit Note" : "Add a Note";

  return (
    <StyledDropdownMenu ref={ref}>
      <DropdownList>
        <li>
          <DropdownAction onClick={onAddNote}>{noteOptionText}</DropdownAction>
        </li>
        <li>
          <DropdownAction onClick={onRemove}>Remove</DropdownAction>
        </li>
      </DropdownList>
    </StyledDropdownMenu>
  );
});

DropdownMenu.displayName = "DropdownMenu";

export default DropdownMenu;
