import { Spacing } from "../constants";
import { Breakpoints } from "../../../constants/constants";
import { css } from "styled-components";

const getGridContainerDesktopStyles = (size) => {
  switch (size) {
    case "Wide Margin":
      return css`
        justify-content: center;
        margin-left: calc(${Spacing.GridGutterMobile} * 0.5);
        margin-right: calc(${Spacing.GridGutterMobile} * 0.5);

        @media screen and (min-width: ${Breakpoints.IPad}) {
          margin: 0 calc(87px - calc(${Spacing.GridGutter} * 0.5));
        }

        @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
          margin: 0 calc(110px - calc(${Spacing.GridGutter} * 0.5));
        }

        @media screen and (min-width: ${Breakpoints.DesktopLarge}) {
          margin: 0 auto;
          max-width: calc(${Breakpoints.DesktopLarge} - 288px);
        }
      `;
    case "Full Width with Margin":
      return css`
        margin-left: calc(${Spacing.GridGutterMobile} * 0.5);
        margin-right: calc(${Spacing.GridGutterMobile} * 0.5);

        @media screen and (min-width: ${Breakpoints.IPad}) {
          margin-left: calc(${Spacing.GridGutter} * 0.5);
          margin-right: calc(${Spacing.GridGutter} * 0.5);
        }
      `;
    default:
      return css`
        overflow: hidden;
        margin-left: 0;
        margin-right: 0;

        @media screen and (min-width: ${Breakpoints.IPad}) {
          margin-left: 0;
          margin-right: 0;
        }
      `;
  }
};

export default getGridContainerDesktopStyles;
