import { Colors } from "@serenaandlily/constants/constants";
import {
  PlaceholderContainer,
  SquareLoader
} from "./FabricsLoadingPlaceholdersStyled";

const FabricsLoadingPlaceholders = ({ count = 5 }) => {
  return (
    <PlaceholderContainer>
      {Array.from({ length: count }).map((_, index) => (
        <SquareLoader
          key={index}
          viewBox="0 0 38 38"
          backgroundColor={Colors.Cloud}
        >
          <rect x="0" y="0" rx="4" ry="4" width="38" height="38" />
        </SquareLoader>
      ))}
    </PlaceholderContainer>
  );
};

export default FabricsLoadingPlaceholders;
