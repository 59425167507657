import { gql } from "@apollo/client";

const buildGetProductDescriptionQuery = (variables) => {
  const pidDef = variables?.productId ? "$productId: String" : "";
  const pid = variables?.productId ? "id: $productId" : "";
  return gql`
    query GetProduct(${pidDef}) {
      getProduct(${pid}) {
        description {
          type
          data
        },
        id
      }
    }
  `;
};

export default buildGetProductDescriptionQuery;
