import { removeKeysInObject, renameKeysInObject, keepOnlyKeysInObject } from "@utils/object"

const userDataKeys = ['firstName', 'lastName', 'email', 'phone', 'fax']
const userAddressKeys = ['addressLine1', 'addressLine2', 'city', 'state', 'zipCode', 'country']
const knownNeedMappingKeys = {'address1': 'addressLine1', 'address': 'addressLine1', 'address2': 'addressLine2', 'stateProvince': 'state', 'phoneNumber':'phone'}
const sensitiveFormKeys = ['password', 'confirmPassword']

export const formatNonBuyersData = (rawData, formName) => {
  let data = renameKeysInObject(rawData, knownNeedMappingKeys) 
  data = removeKeysInObject(data, sensitiveFormKeys)
  const userData = keepOnlyKeysInObject(data, userDataKeys)
  const userAddress = keepOnlyKeysInObject(data, userAddressKeys)
  const extraData = JSON.stringify(removeKeysInObject(data, [...userDataKeys, ...userAddressKeys]))
  return {
    userData,
    userAddress,
    extraData,
    formName
  }
}