//eslint-disable-next-line max-lines-per-function
const getLayoutProps = (layout) => {
  const {
    orientation,
    tabletOrientation,
    mobileOrientation,
    alignment,
    tabletAlignment,
    mobileAlignment
  } = layout || {};

  const Alignments = {
    Left: "flex-start",
    Center: "center",
    Right: "flex-end"
  };

  let desktopCSS;
  let tabletCSS;
  let mobileCSS;

  if (orientation === "row") {
    desktopCSS = `
        display: flex;
        flex-direction: ${orientation};
        justify-content: ${Alignments[alignment] || "center"};
        align-items: center;
    `;
  } else {
    desktopCSS = `
        display: flex;
        flex-direction: column;
        align-items: ${Alignments[alignment] || "center"};
        justify-content: center;
    `;
  }

  if (tabletOrientation === "row") {
    tabletCSS = `
        display: flex;
        flex-direction: row;
        justify-content: ${Alignments[tabletAlignment] || "center"};
        align-items: center;

    `;
  } else {
    tabletCSS = `
        display: flex;
        flex-direction: column;
        align-items: ${Alignments[tabletAlignment] || "center"};
        justify-content: center;

    `;
  }

  if (mobileOrientation === "row") {
    mobileCSS = `
        display: flex;
        flex-direction: row;
        justify-content: ${Alignments[mobileAlignment] || "center"};
        align-items: center;

    `;
  } else {
    mobileCSS = `
        display: flex;
        flex-direction: column;
        align-items: ${Alignments[mobileAlignment] || "center"};
        justify-content: center;

    `;
  }

  return { desktopCSS, tabletCSS, mobileCSS };
};

export default getLayoutProps;
