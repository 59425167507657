import React from "react";
import {
  EmblaSlideThumb,
  EmblaSlideInnerThumb,
  EmblaSlideThumbnail
} from "./ProductImageCarouselStyled";
import PropTypes from "prop-types";
import Icons from "../Icons";
import { executeOnEnterKey } from "@utils/keyboard/executeOnEnterKey";

export const Thumb = ({
  onClick,
  imgSrc,
  altText,
  onMouseOver,
  isVideo = false,
  isSelected = false
}) => (
  <EmblaSlideThumb tabIndex={-1}>
    <EmblaSlideInnerThumb
      onClick={onClick}
      onMouseOver={onMouseOver}
      isVideo={isVideo}
      isSelected={isSelected}
      onKeyDown={(e) => executeOnEnterKey(e, () => onMouseOver())}
      tabIndex={0}
    >
      <EmblaSlideThumbnail src={imgSrc} alt={altText} />
      {isVideo && <Icons type="Play" />}
    </EmblaSlideInnerThumb>
  </EmblaSlideThumb>
);

Thumb.propTypes = {
  onClick: PropTypes.func,
  imgSrc: PropTypes.string,
  altText: PropTypes.string,
  onMouseOver: PropTypes.func,
  isVideo: PropTypes.bool
};
