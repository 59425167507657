import { CallToAction, CTATextWrapper } from "../../CTA/CTAStyled";
import styled, { css } from "styled-components";
import { Colors, Breakpoints } from "../../../constants/constants";

const oneColStyles = css`
  align-self: center;
  margin-top: 10px;
`;

export const SwatchesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  ${({ tileWidth }) => (tileWidth === "1 Column" ? oneColStyles : null)}

  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    justify-content: center;
  }

  & > ${CallToAction} {
    font-weight: 400;
    line-height: 2;
    margin-right: 10px;

    @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
      width: 100%;
      margin-top: 5px;
      margin-right: 5px;
    }

    > ${CTATextWrapper} {
      padding: 0;
    }
  }
`;

export const SwatchThumbnailWrapper = styled.button`
  padding: 0;
  border: none;
  background: none;
  margin-right: 10px;

  @media screen and (min-width: ${Breakpoints.SMTabletMin}) {
    margin-right: 5px;
  }

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    margin-top: 10px;
    margin-right: 5px;
  }
  @media screen and (min-width: ${Breakpoints.SMTabletMax}) {
    height: 30px;
  }
`;

export const SwatchThumbnail = styled.img`
  display: block;
  cursor: pointer;
  width: 24px;
  height: 24px;
  padding: 2px;
  border-radius: ${({ swatchType }) => (swatchType === "circle" ? "50%" : "")};
  border: 1px solid
    ${({ isSelected }) => (isSelected ? Colors.Midnight : Colors.Transparent)};

  @media screen and (min-width: ${Breakpoints.MDLaptopMin}) {
    &:hover {
      ${({ isClicked }) =>
        !isClicked ? `border-color: ${Colors["Light Primary"]};` : null}
    }
  }
`;
