import { Breakpoints } from "../../constants/constants";


export const videoBreakpoints = {
    responsive: true,
    breakpoints: {
        tiny: Breakpoints.IPhone11,
        xsmall: Breakpoints.SmallMobile,
        small: Breakpoints.DesktopMobile,
        medium: Breakpoints.IPad,
        large: Breakpoints.IPhone11ProMax,
        xlarge: Breakpoints.ScreenWidth,
        huge: Breakpoints.DesktopLarge
    },
};