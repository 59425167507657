import React from "react";
import PropTypes from "prop-types";
import {
  DefaultFlyoutContainer,
  TopLeftSection,
  BottomRightSection,
  SearchSuggestions,
  SuggestionsWrapper,
  SuggestionTextWrapper,
  ImageLinksSection,
  ImageLinksWrapper,
  InspirationTileWrapper
} from "./DefaultFlyoutStyled";
import CTA from "../../CTA";
import defaultTheme from "./defaultTheme";
import Typography from "../../Typography";
import InspirationTile from "../../InspirationTile";
import GenericCarousel from "../../GenericCarousel";
import { useBreakpoint } from "../../Breakpoints";
import LinksSectionFlyout from "../../LinksSectionFlyout";
import useComponentTheme from "../../Theme/useComponentTheme";

const DefaultFlyout = ({
  suggestionInfo,
  imageLinksInfo,
  textLinksInfo,
  theme = defaultTheme
}) => {
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints["DesktopMobile"];
  const { suggestionTitle, suggestions } = suggestionInfo || {};
  const { imageLinkTitle, imageLinks } = imageLinksInfo || {};

  const defaultFlyoutTheme = useComponentTheme({ theme, themeOverride: {} });

  const carouselConfigs = {
    arrows: false,
    autoplay: false,
    loop: false,
    align: "start",
    height: !isMobile ? 255 : 350,
    width: "164px",
    progressBar: {
      useProgressBar: !isMobile,
      progressBarPosition: "left"
    }
  };

  return (
    <DefaultFlyoutContainer>
      <TopLeftSection>
        <SearchSuggestions>
          <Typography
            tag="h2"
            themeOverrides={defaultFlyoutTheme?.suggestionTitle}
          >
            {suggestionTitle.copy}
          </Typography>
          <SuggestionsWrapper>
            {suggestions &&
              suggestions.map((suggestion, idx) => (
                <CTA
                  themeName={suggestion?.cta?.ctaStyle}
                  {...suggestion}
                  key={`suggestion-${idx}`}
                />
              ))}
          </SuggestionsWrapper>
        </SearchSuggestions>
        <ImageLinksSection>
          <SuggestionTextWrapper>
            <Typography
              tag="h2"
              themeOverrides={defaultFlyoutTheme?.imageLinkTitle}
            >
              {imageLinkTitle.copy}
            </Typography>
          </SuggestionTextWrapper>
          {isMobile ? (
            <GenericCarousel
              carouselContent={imageLinks}
              carouselConfigs={carouselConfigs}
            />
          ) : (
            <ImageLinksWrapper>
              {imageLinks &&
                imageLinks.map((link, idx) => (
                  <InspirationTileWrapper key={`image-${idx}`}>
                    <InspirationTile {...link} />
                  </InspirationTileWrapper>
                ))}
            </ImageLinksWrapper>
          )}
        </ImageLinksSection>
      </TopLeftSection>
      <BottomRightSection>
        <LinksSectionFlyout {...textLinksInfo} />
      </BottomRightSection>
    </DefaultFlyoutContainer>
  );
};

export default DefaultFlyout;

DefaultFlyout.propTypes = {
  suggestions: PropTypes.array,
  imageLinks: PropTypes.array,
  textLinks: PropTypes.array,
  theme: PropTypes.object,
  suggestionInfo: PropTypes.object,
  imageLinksInfo: PropTypes.object,
  textLinksInfo: PropTypes.object
};
