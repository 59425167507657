// REACT IMPORTS //
import {
  useRef,
  forwardRef,
  useState,
  useEffect,
  useImperativeHandle
} from "react";

// 3RD PART IMPORTS //
import { preload } from "swr";
import { useAtomValue } from "jotai";
import { AnimatePresence } from "framer-motion";

import PropTypes from "prop-types";
import debounce from "lodash.debounce";

// HOOK IMPORTS //
import { useBreakpoint } from "../Breakpoints";
import { useProductEdd } from "@serenaandlily/hooks";
import { useProductPrice } from "../../hooks/useProductPrice";
import { useUserData } from "@serenaandlily/contexts/UserContext";
import { useContent } from "@serenaandlily/contexts/ContentContext";
import { useProductContext } from "../../contexts/ProductContext";
import { useCartUpdate } from "@serenaandlily/contexts/CartContext";
import { useSidePanelContext } from "../../contexts/SidePanelContext";
import { useSlideInContext } from "@serenaandlily/contexts/SlideInContext";
import { productSwatchDescriptionFetcher } from "../../hooks/useProductSwatchDescription";

import useUser from "@serenaandlily/hooks/useUser";
import useComponentTheme from "../Theme/useComponentTheme";

// UTIL IMPORTS //
import { cuProductAtom } from "../../state/atoms/cuProductAtomStore";
import groupFabricsBySubType from "@utils/groupFabricsBySubType/groupFabricsBySubType";

// COMPONENT IMPORTS //
import CTA from "../CTA";
import Text from "../Text";
import SlideIn from "../SlideIn";
import CUFilters from "../CUFilters";
import ProductPrice from "../ProductPrice";
import AddedBagPanel from "../AddedBagPanel";
import FabricPreview from "../FabricPreview";
import SwatchSectionWrapper from "../SwatchSectionWrapper";

// STYLED IMPORTS //
import {
  FabricPanelWrapper,
  FabricPanelTitle,
  PriceAndApply,
  PriceContainer,
  PriceTitle,
  Price,
  FilteredSwatchesContainer,
  TabletContainer,
  Backdrop,
  ButtonsContainer
} from "./FabricPanelStyled";

// CONSTANT IMPORTS //
import {
  MAGIC_NUMBERS_0,
  MAGIC_NUMBERS_6,
  MAGIC_NUMBERS_300,
  MAGIC_NUMBERS_1000
} from "@serenaandlily/constants/constants";
import { GLOBAL_HEADER_ID } from "../GlobalHeader";
import { SLIDE_IN_POSITION } from "../SlideIn/constants";

// THEME IMPORTS //
import defaultTheme from "./FabricPanelTheme";

/* eslint-disable max-lines-per-function, react/jsx-no-bind, react-hooks/exhaustive-deps */
const FabricPanel = forwardRef(
  (
    {
      selectedFabric,
      headerOffset,
      topLineVariation,
      cuOptions,
      currentVariations,
      productId,
      setSelectedSwatch,
      visibleQuickShip,
      cartPopupHeader,
      setVisibleQuickShip,
      visibleMadeToOrder,
      setVisibleMadeToOrder,
      filters,
      theme = defaultTheme
    },
    ref
  ) => {
    // Refs //
    const debounceRef = useRef(null);
    const leaveTimeoutRef = useRef(null);

    // States //
    const cuAtom = useAtomValue(cuProductAtom);
    const { quickshipFabrics, madeToOrderFabrics, subTypeOrder } =
      cuAtom.fabrics;

    const [filteredQuickShip, setFilteredQuickShip] =
      useState(quickshipFabrics);
    const [filteredMadeToOrder, setFilteredMadeToOrder] =
      useState(madeToOrderFabrics);

    const [totalFabricAmt, setTotalFabricAmt] = useState(0);
    const [totalFilteredFabricAmt, setTotalFilteredFabricAmt] = useState(0);
    const [
      isLoadingTemporaryProductMobile,
      setIsLoadingTemporaryProductMobile
    ] = useState(false);
    const [temporaryProductMobile, setTemporaryProductMobile] = useState({});
    const [temporaryFabricMobile, setTemporaryFabricMobile] = useState({});
    const [groupedFilteredFabrics, setGroupedFilteredFabrics] = useState([]);
    const [addToCartProduct, setAddToCartProduct] = useState(null);
    const [visibleAddToCart, setVisibleAddToCart] = useState(false);
    const [loadingFabricToCart, setLoadingFabricToCart] = useState(false);
    const [currentFabricData, setCurrentFabricData] = useState({
      name: selectedFabric?.name,
      selectedSwatch: selectedFabric
    });

    const [hoveredFabricData, setHoveredFabricData] = useState();

    // Hooks //
    const breakpoints = useBreakpoint();

    const fabricPanelTheme = useComponentTheme({ theme });

    const userData = useUserData();
    const { user: { data: { isDOO, isDSO } = {} } = {} } = useUser();

    const { pdpSettings } = useContent();

    const { handleCartAdd } = useCartUpdate();
    const { selectedFilters } = useProductContext();

    const { closeAllSlideIns } = useSlideInContext();
    const { handleClosePanel, isFilterOpen } = useSidePanelContext();

    const { data: productPriceData, fetchProductOnDemand } = useProductPrice({
      productId: productId,
      isCuProduct: true,
      currentVariations,
      customUpholsteryOptions: {
        topLineVariation,
        fabricCode: currentFabricData?.selectedSwatch?.code,
        otherOptions: cuOptions
      }
    });

    const { data: eddData } = useProductEdd({
      productId: productId,
      currentVariations,
      isCuProduct: true,
      customUpholsteryOptions: {
        topLineVariation,
        fabricCode: currentFabricData?.selectedSwatch?.code,
        otherOptions: cuOptions
      }
    });

    // Constants //
    const isMobile = breakpoints["DesktopMobile"];
    const isTablet = breakpoints["LgTablet"];
    const isDesktop = !breakpoints["LgTablet"];

    const subTypeOrderLowerCase = subTypeOrder.map((subType) =>
      subType.toLowerCase()
    );

    let fabricPrice = pdpSettings?.fabricDefaultPrice;

    if (isDOO || isDSO) {
      fabricPrice = 0;
    }

    const orderSwatchText = `ORDER FABRIC SWATCH ${
      !fabricPrice && fabricPrice !== 0 ? "" : `| $${fabricPrice}`
    }`;

    // Functions //
    const onParentWillClose = () => {
      setTemporaryFabricMobile({});
      setTemporaryProductMobile({});
    };

    useImperativeHandle(ref, () => ({
      onParentWillClose
    }));

    const filterFabrics = (selectedFilters, fabrics) => {
      const colors = selectedFilters["selectedColors"];
      const grades = selectedFilters["selectedGrades"];
      const types = selectedFilters["selectedTypes"];

      const colorsHash = {};
      for (const color of colors) colorsHash[color] = true;

      const filteredFabrics = [];

      fabrics.forEach((fabric) => {
        const { color = "", grade = "", type = "" } = fabric;
        const isMatchedColor = colors.length ? colorsHash[color] : true;
        const isMatchedGrades = grades.length ? grades.includes(grade) : true;
        const isMatchedTypes = types.length ? types.includes(type) : true;

        if (isMatchedColor && isMatchedGrades && isMatchedTypes)
          filteredFabrics.push(fabric);
      });

      return filteredFabrics;
    };

    const handleSelectFabric = (fabric) => {
      setSelectedSwatch(fabric);
      setCurrentFabricData({
        name: fabric?.name,
        selectedSwatch: fabric
      });
    };

    const handleHoverFabric = async (fabric) => {
      const debounceTime = !isDesktop ? MAGIC_NUMBERS_0 : MAGIC_NUMBERS_1000;
      if (isLoadingTemporaryProductMobile) return;
      if (!isDesktop) {
        try {
          handleSelectFabric(fabric);
          setIsLoadingTemporaryProductMobile(true);
          const fetchSelectedProductMobile = await fetchProductOnDemand({
            productId: productId,
            isCuProduct: true,
            currentVariations,
            customUpholsteryOptions: {
              topLineVariation,
              fabricCode: fabric?.code,
              otherOptions: cuOptions
            }
          });
          setTemporaryProductMobile(fetchSelectedProductMobile);
          setIsLoadingTemporaryProductMobile(false);
        } catch (e) {
          setIsLoadingTemporaryProductMobile(false);
        }
      }
      if (leaveTimeoutRef.current) {
        clearTimeout(leaveTimeoutRef.current);
      }

      if (debounceRef.current) {
        debounceRef.current.cancel();
      }

      debounceRef.current = debounce(() => {
        setHoveredFabricData({
          name: fabric?.name,
          selectedSwatch: fabric
        });

        if (isMobile || isTablet) {
          setTemporaryFabricMobile({
            name: fabric?.name,
            selectedSwatch: fabric
          });
        }
        preload(`swatch-product-${fabric?.swatchProduct?.id || 0}`, () =>
          productSwatchDescriptionFetcher(fabric?.swatchProduct?.id || 0)
        );
      }, debounceTime);

      debounceRef.current();
    };

    const handleMouseLeaveFabric = () => {
      if (!isDesktop) return;
      if (debounceRef.current) {
        debounceRef.current.cancel();
      }

      leaveTimeoutRef.current = setTimeout(() => {
        setHoveredFabricData({});
      }, MAGIC_NUMBERS_300);
    };

    const updateVisibleFabrics = ({
      fabricToUse,
      visibleFabrics,
      setVisibleFabrics,
      close
    }) => {
      const isVisible = visibleFabrics.some(
        (swatch) => swatch.id === fabricToUse?.selectedSwatch?.id
      );

      if (isVisible) {
        if (close) {
          closeAllSlideIns();
          handleClosePanel();
        }
        return false;
      }

      const newFabrics = visibleFabrics.slice();
      newFabrics[0] = fabricToUse?.selectedSwatch;
      setVisibleFabrics(newFabrics);

      return true;
    };

    const handleApplyFabric = (e, fabric, close) => {
      e.preventDefault();
      const fabricToUse = fabric || currentFabricData;

      setSelectedSwatch(fabricToUse?.selectedSwatch);

      const isQuickShipFabric = quickshipFabrics.some(
        (swatch) => swatch.id === fabricToUse?.selectedSwatch?.id
      );

      if (isQuickShipFabric && quickshipFabrics.length > MAGIC_NUMBERS_6) {
        const proceed = updateVisibleFabrics({
          fabricToUse,
          visibleFabrics: visibleQuickShip,
          setVisibleFabrics: setVisibleQuickShip,
          close
        });
        if (!proceed) return;
      } else if (
        !isQuickShipFabric &&
        madeToOrderFabrics.length > MAGIC_NUMBERS_6
      ) {
        const proceed = updateVisibleFabrics({
          fabricToUse,
          visibleFabrics: visibleMadeToOrder,
          setVisibleFabrics: setVisibleMadeToOrder,
          close
        });
        if (!proceed) return;
      }

      if (close) {
        setHoveredFabricData({});
        setTemporaryFabricMobile({});
        setTemporaryProductMobile({});
        closeAllSlideIns();
        handleClosePanel();
      }
    };

    const onClose = () => {
      setHoveredFabricData({});
    };

    const cancelMouseLeave = () => {
      if (leaveTimeoutRef.current) {
        clearTimeout(leaveTimeoutRef.current);
      }
    };

    const handleMouseLeaveDesktopContainer = () => {
      handleMouseLeaveFabric();
    };

    const AddedBagPanelSlide = () => {
      const header = document?.getElementById(GLOBAL_HEADER_ID);
      const headerValues = header?.getBoundingClientRect();
      const isInView = headerValues?.top !== 0;
      return (
        <AddedBagPanel
          isInView={isInView}
          panelTitle={cartPopupHeader}
          masterProductId={productId}
          forSwatches={true}
          price={addToCartProduct?.formattedListPrice}
          quantity={1}
          swatches={[selectedFabric]}
        />
      );
    };

    const closeSlideIn = () => {
      setVisibleAddToCart(false);
    };

    const addFabricToCart = async () => {
      try {
        setLoadingFabricToCart(true);
        const fetchFabricPrice = await fetchProductOnDemand({
          productId: selectedFabric?.swatchProduct?.id
        });
        setAddToCartProduct(fetchFabricPrice);
        await handleCartAdd({
          items: [{ quantity: 1, id: selectedFabric?.swatchProduct?.id }],
          closePopup: null,
          customerEmail: userData?.user?.email
        });

        setVisibleAddToCart(true);
        setLoadingFabricToCart(false);
      } catch (err) {
        setLoadingFabricToCart(false);
      }
    };

    // Effects //
    useEffect(() => {
      const quickShip = filterFabrics(selectedFilters, quickshipFabrics);
      const madeToOrder = filterFabrics(selectedFilters, madeToOrderFabrics);

      if (!subTypeOrder?.length) {
        const { quickshipFabrics, madeToOrderFabrics } = cuAtom.fabrics;
        setFilteredMadeToOrder(madeToOrderFabrics);
        setFilteredQuickShip(quickshipFabrics);

        const totalFabricAmt =
          quickshipFabrics.length + madeToOrderFabrics.length;
        const totalFilteredFabricAmt =
          filteredQuickShip.length + filteredMadeToOrder.length;

        setTotalFabricAmt(totalFabricAmt);
        setTotalFilteredFabricAmt(totalFilteredFabricAmt);
        return;
      }

      const combinedFabrics = [...quickShip, ...madeToOrder];

      const filteredFabrics = combinedFabrics.filter((fabric) =>
        subTypeOrderLowerCase.includes(fabric.subType?.toLowerCase())
      );

      const totalFabricAmt = filteredFabrics.length;

      const groupedFabrics = groupFabricsBySubType(
        filteredFabrics,
        subTypeOrderLowerCase
      );

      const totalFilteredFabricAmt = groupedFabrics.reduce(
        (total, group) => total + group.fabrics.length,
        0
      );

      setGroupedFilteredFabrics(groupedFabrics);
      setTotalFabricAmt(totalFabricAmt);
      setTotalFilteredFabricAmt(totalFilteredFabricAmt);
    }, [selectedFilters]);

    return (
      <FabricPanelWrapper headerOffset={headerOffset}>
        {(isLoadingTemporaryProductMobile ||
          hoveredFabricData?.selectedSwatch) &&
          isMobile &&
          !isDesktop && <Backdrop />}
        <AnimatePresence>
          {hoveredFabricData?.selectedSwatch && (
            <FabricPreview
              hoveredFabricData={hoveredFabricData}
              handleMouseLeaveDesktopContainer={
                handleMouseLeaveDesktopContainer
              }
              onClose={onClose}
              isTablet={isTablet}
              isMobile={isMobile}
              isDesktop={isDesktop}
              temporaryProductMobile={temporaryProductMobile}
              loadingFabricToCart={loadingFabricToCart}
              cancelMouseLeave={cancelMouseLeave}
              addFabricToCart={addFabricToCart}
              handleApplyFabric={handleApplyFabric}
              orderSwatchText={orderSwatchText}
              eddData={eddData}
            />
          )}
        </AnimatePresence>
        <TabletContainer showShadow={!hoveredFabricData?.selectedSwatch}>
          <FabricPanelTitle>
            <Text
              copy={`Fabrics (${totalFabricAmt})`}
              theme={fabricPanelTheme?.fabricTitle}
            />
          </FabricPanelTitle>

          <CUFilters
            filters={filters}
            headerOffset={headerOffset}
            totalFabricAmt={totalFabricAmt}
            filteredFabricAmt={totalFilteredFabricAmt}
            showDesktopFilters={false}
            showClearAllAtTheEnd={isDesktop}
          />

          <FilteredSwatchesContainer>
            <SwatchSectionWrapper
              groupedFilteredFabrics={groupedFilteredFabrics}
              filteredQuickShip={filteredQuickShip}
              filteredMadeToOrder={filteredMadeToOrder}
              subTypeOrder={subTypeOrder}
              renderFabricsProps={{
                currentFabricData,
                isMobile,
                isTablet,
                temporaryFabricMobile,
                handleSelectFabric,
                handleApplyFabric,
                setSelectedSwatch,
                handleHoverFabric,
                handleMouseLeaveFabric,
                isLoadingTemporaryProductMobile,
                multipleSelection: false
              }}
              theme={fabricPanelTheme}
              isDesktop={isDesktop}
            />
          </FilteredSwatchesContainer>

          {!isFilterOpen && (
            <PriceAndApply>
              <PriceContainer>
                <PriceTitle>
                  <Text
                    copy="Price with selected fabric:"
                    themeName="headline5"
                  />
                </PriceTitle>
                <Price>
                  <ProductPrice
                    removeDecimalsIfZero={true}
                    {...(isMobile &&
                    !isDesktop &&
                    temporaryProductMobile?.formattedListPrice
                      ? temporaryProductMobile
                      : productPriceData)}
                  />
                </Price>
              </PriceContainer>
              <ButtonsContainer>
                <CTA
                  cta={{
                    ctaText: "APPLY",
                    onCtaClick: (e) => handleApplyFabric(e, null, true)
                  }}
                  useButtonTag={true}
                  themeName="Primary Button"
                />
                <CTA
                  disabled={loadingFabricToCart}
                  cta={{
                    ctaText: orderSwatchText,
                    onCtaClick: addFabricToCart
                  }}
                  useButtonTag={true}
                  themeName="Secondary Button"
                />
              </ButtonsContainer>
            </PriceAndApply>
          )}
        </TabletContainer>
        <SlideIn
          isOpen={visibleAddToCart}
          closeButtonAriaLabel="Close added to bag panel"
          onClose={closeSlideIn}
          position={
            isMobile ? SLIDE_IN_POSITION.BOTTOM : SLIDE_IN_POSITION.RIGHT
          }
          key="added-bag-slide-in-fabric-panel"
          backgroundColor="#fff"
          visibleBackdrop={true}
          zIndexBase={99999999}
        >
          <AddedBagPanelSlide />
        </SlideIn>
      </FabricPanelWrapper>
    );
  }
);

FabricPanel.displayName = "FabricPanel";

export default FabricPanel;

FabricPanel.propTypes = {
  selectedFabric: PropTypes.object,
  swatchHeadings: PropTypes.object,
  madeToOrderFabrics: PropTypes.array,
  quickshipFabrics: PropTypes.array,
  topLineVariation: PropTypes.string,
  cuOptions: PropTypes.array,
  currentVariations: PropTypes.object,
  productId: PropTypes.string,
  setSelectedSwatch: PropTypes.func,
  visibleQuickShip: PropTypes.array,
  setVisibleQuickShip: PropTypes.func,
  visibleMadeToOrder: PropTypes.array,
  setVisibleMadeToOrder: PropTypes.func,
  onParentWillClose: PropTypes.func,
  headerOffset: PropTypes.number,
  filters: PropTypes.object,
  eddData: PropTypes.object,
  showEdd: PropTypes.bool
};
