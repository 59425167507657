import ContentLoader from "react-content-loader";
import styled from "styled-components";

export const PlaceholderContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const SquareLoader = styled(ContentLoader)`
  width: 40px;
  height: 40px;
  border-radius: 0;
`;
