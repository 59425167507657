import React, { useState, useRef, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import {
  AccordionMenuItemWrapper,
  AccordionMenuItemTitle,
  AccordionMenuItemContent,
  ExpandButton
} from "./AccordionMenuStyled";
import CTA from "../CTA";
import Icons from "../Icons";
import defaultTheme from "./AccordionMenuTheme";
import useComponentTheme from "../Theme/useComponentTheme";
import { useSlideOutMenuContext } from "../../contexts/SlideOutMenuContext";

const SLIDE_OUT_MENU_CATEGORY_SCHEMA =
  "https://raw.githubusercontent.com/newelevation/sl-ui-toolkit/main/packages/sl-ui/src/components/SlideOutMenuCategory/schema/SlideOutMenuCategory.json";

const AccordionMenuItem = ({
  menuTitle,
  menuContent,
  selectedCategories,
  setSelectedCategories,
  categoriesTheme,
  isFinalLevel,
  onClickFinalElement,
  hoverColor = "#F5F3EE",
  level = 0
}) => {
  const selectedCategoryAtLevel =
    selectedCategories && selectedCategories[level];
  const isExpanded = selectedCategoryAtLevel === menuTitle?.ctaText;
  const [scrollHeight, setScrollHeight] = useState("");
  const [touchStartY, setTouchStartY] = useState(null);
  const [isSwiping, setIsSwiping] = useState(false);
  const detailsRef = useRef();
  const itemRef = useRef();
  const { setAccordionScrollValue } = useSlideOutMenuContext();
  const [itemOffsetHeight, setItemOffsetHeight] = useState();

  // TODO: Might be useful elsewhere. Fixing ECP-9390 for now.
  const isIOSSafari = () => {
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const webkit = !!ua.match(/WebKit/i);
    return iOS && webkit && !ua.match(/CriOS/i);
  }

  const handleToggleAccordionMenu = useCallback(() => {
    setScrollHeight(detailsRef?.current?.scrollHeight);
    !isExpanded && setAccordionScrollValue(itemOffsetHeight);

    if (isFinalLevel) {
      if (onClickFinalElement) {
        onClickFinalElement(menuTitle?.ctaText);
      }
      return;
    }

    const currentItem = menuTitle?.ctaText;
    if (isExpanded) {
      const newSelectedCategories = { ...selectedCategories };
      for (let i = level; i <= 10; i++) {
        delete newSelectedCategories[level];
      }
      setSelectedCategories(newSelectedCategories);
    } else if (!level) {
      setSelectedCategories({
        [level]: currentItem
      });
    } else {
      setSelectedCategories({
        ...selectedCategories,
        [level]: currentItem
      });
    }
  }, [selectedCategories, onClickFinalElement]);

  /* 
    TODO: Not sure if I like this enough to refactor as a hook.
    The use of anchors when the href is "#" instead of a button is iffy imo.Let's revisit this later after ECP-9390 is fixed.
  */
  const handleTouchStart = useCallback((e) => {
    setTouchStartY(e.touches[0].clientY);
    setIsSwiping(false);
  }, []);

  const handleTouchMove = useCallback((e) => {
    if (touchStartY === null) return;

    const moveDistance = Math.abs(e.touches[0].clientY - touchStartY);
    if (moveDistance > 10) {
      setIsSwiping(true);
    }
  }, [touchStartY]);

  const handleTouchEnd = useCallback((event) => {
    const wasSwiping = isSwiping;
    setTouchStartY(null);
    setIsSwiping(false);

    if (wasSwiping) {
      return;
    }

    if (isIOSSafari()) {
      const link = event.target.closest("a[href]");

      if (link) {
        const iOSDelay = 300;
        const href = link.getAttribute("href");
        event.preventDefault();

        const now = Date.now();
        if (link.lastClickTime && (now - link.lastClickTime < iOSDelay)) {
          return;
        }
        link.lastClickTime = now;

        if (href !== "#") {
          window.location.href = href;
        } else {
          handleToggleAccordionMenu();
        }
      } else {
        event.preventDefault();
        handleToggleAccordionMenu();
      }
    }
  }, [handleToggleAccordionMenu, isSwiping, isIOSSafari]);

  const theme = defaultTheme;
  const accordionMenuTheme = useComponentTheme({ theme });

  useEffect(() => {
    setItemOffsetHeight(itemRef?.current?.offsetTop);
  }, []);

  return (
    <AccordionMenuItemWrapper ref={itemRef}>
      <AccordionMenuItemTitle
        level={level}
        onClick={handleToggleAccordionMenu}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        isExpanded={isExpanded}
        hoverColor={hoverColor}
      >
        <CTA
          themeName={accordionMenuTheme?.slideOutCtas?.themeName}
          theme={categoriesTheme}
          cta={menuTitle}
        />
        {!isFinalLevel &&
          (isExpanded ? (
            <Icons type="CaretUpLight" />
          ) : (
            <ExpandButton onClick={handleToggleAccordionMenu}>
              Expand
              <Icons type="CaretDownLight" />
            </ExpandButton>
          ))}
      </AccordionMenuItemTitle>
      {
        <AccordionMenuItemContent
          ref={detailsRef}
          scrollHeight={scrollHeight}
          isExpanded={isExpanded}
          isFinalLevel={isFinalLevel}
        >
          {menuContent &&
            menuContent.map((content, idx) => {
              const {
                _meta: meta,
                secondaryFlyout,
                category,
                categoryName
              } = content || {};

              const key = `AccordionMenuItem-${idx}`;

              const isFinalLevel =
                meta?.schema === SLIDE_OUT_MENU_CATEGORY_SCHEMA ||
                !secondaryFlyout;

              if (isFinalLevel) {
                return (
                  <React.Fragment key={key}>
                    <AccordionMenuItem
                      menuTitle={categoryName || category?.categoryName}
                      level={level + 1}
                      isFinalLevel={isFinalLevel}
                      categoriesTheme={categoriesTheme}
                      onClickFinalElement={onClickFinalElement}
                    />
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment key={key}>
                    <AccordionMenuItem
                      menuTitle={category?.categoryName}
                      menuContent={secondaryFlyout}
                      selectedCategories={selectedCategories}
                      setSelectedCategories={setSelectedCategories}
                      categoriesTheme={categoriesTheme}
                      hoverColor={hoverColor}
                      onClickFinalElement={onClickFinalElement}
                      level={level + 1}
                    />
                  </React.Fragment>
                );
              }
            })}
        </AccordionMenuItemContent>
      }
    </AccordionMenuItemWrapper>
  );
};

export default AccordionMenuItem;

AccordionMenuItem.propTypes = {
  menuTitle: PropTypes.object,
  menuContent: PropTypes.object,
  selectedCategories: PropTypes.object,
  setSelectedCategories: PropTypes.func,
  onClickFinalElement: PropTypes.func,
  isFinalLevel: PropTypes.bool,
  hoverColor: PropTypes.string,
  level: PropTypes.number,
  categoriesTheme: PropTypes.object
};
