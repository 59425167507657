import styled from "styled-components";
import {
  Breakpoints,
  Colors,
  Spacing,
  Typography
} from "../../constants/constants";

import { CTATextWrapper } from "../CTA/CTAStyled";
import { CloseButton } from "../SlideIn/SlideInStyled";

export const FabricSelectorWrapper = styled.div`
  .order-swatches-slide {
    ${CloseButton} {
      top: 16px;
    }
  }
`;

export const FabricSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    margin-bottom: ${Spacing.MediumSmall}px;
  }
`;

export const FabricSelectorTitle = styled.div`
  margin-right: ${Spacing.Xsmall}px;

  > h5 {
    line-height: 16px;
  }
`;

export const SelectedFabric = styled.div`
  margin-right: 12px;
  cursor: pointer;
`;

export const SwatchesContainer = styled.div`
  margin-bottom: ${Spacing.Base}px;
`;

export const SwatchesContainerTitle = styled.div`
  margin-bottom: ${Spacing.MediumSmall}px;
  text-transform: uppercase;
  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    margin-bottom: 12px;
  }
  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    span {
      padding: 16px 16px 16px 24px;
      width: 133px;
    }

    svg {
      margin-left: 11px;
    }
  }
`;
export const TileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1px;
`;
export const Swatches = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const ImageContainer = styled.button`
  cursor: pointer;
  color: none;
  border: none;
  background-color: ${Colors.White};
  padding: 0;
  position: relative;
`;

export const RemainingOrder = styled.div`
  margin-left: 5px;

  span {
    border: 1px solid #a7b0ba;
    border-radius: 4px;
    background: #f9f9f9;
    padding: 15px 16px 13px 16px;
    color: #243953;
    text-transform: uppercase;
    font-family: ${Typography.BeatriceRegular};
    width: 142px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    line-height: 12px;
  }

  svg {
    margin-left: 4px;
  }
  @media screen and (max-width: ${Breakpoints.IPhoneSE}) {
    span {
      padding: 15px 5px 13px 5px;
    }
  }
  @media screen and (max-width: ${Breakpoints.SmallMobile}) {
    span {
      width: 100%;
    }
  }
  @media screen and (min-width: ${Breakpoints.IPhone11ProMax}) {
    width: 100%;
    span {
      width: 100%;
    }
    a {
      width: 100%;
    }
  }

  @media screen and (min-width: ${Breakpoints.DesktopLarge}) {
    span {
      padding: 14px 22px 14px 22px;
    }

    svg {
      margin-left: 11px;
    }
  }
`;

export const SelectorCTAContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${Spacing.Base}px;

  ${CTATextWrapper} {
    margin-right: 24px !important;
  }
`;
