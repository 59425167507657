import React, { useEffect } from "react";
import {
  SidePanelWrapper,
  SidePanelBackdrop,
  SidePanelContainer,
  CloseButton
} from "./SidePanelStyled";
import { useSidePanelContext } from "../../contexts/SidePanelContext";
import Icons from "../Icons";

const SidePanel = () => {
  const {
    isOpen,
    setIsOpen,
    handleClosePanel,
    isVisible,
    setIsVisible,
    content,
    closeOnHover
  } = useSidePanelContext();

  useEffect(() => {
    if (isOpen) setIsVisible(true);
  }, [isOpen]);

  useEffect(() => {
    if (!isVisible && isOpen) {
      setTimeout(() => {
        setIsOpen(false);
      }, 1000);
    }
  }, [isVisible]);

  const handleBackdropClose = () => {
    if (!closeOnHover) return;
    handleClosePanel();
  };
  if (!isOpen) return null;

  return (
    <SidePanelWrapper
      id="side-panel-wrapper"
      data-testid="side-panel-wrapper"
      isOpen={isOpen}
    >
      <SidePanelBackdrop
        onClick={handleClosePanel}
        onMouseEnter={handleBackdropClose}
        isOpen={isOpen}
      />
      <SidePanelContainer isVisible={isVisible} isBagPanel={closeOnHover}>
        <CloseButton
          onClick={handleClosePanel}
          aria-label="Close added to your bag panel button"
        >
          <Icons type="Close" />
        </CloseButton>
        {content}
      </SidePanelContainer>
    </SidePanelWrapper>
  );
};

export default SidePanel;
