const defaultTheme = {
  selectedFabricName: {
    fontFamily: "Beatrice Light",
    fontSize: "14px",
    fontSizeTablet: "15px",
    fontSizeDesktop: "16px",
    lineHeight: "1.2"
  }
};

export default defaultTheme;
