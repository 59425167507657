import styled from "styled-components";
import { Typography, Breakpoints, FontSizes } from "../../constants/constants";

export const BagWrapper = styled.div`
  position: relative;
`;

export const BagLink = styled.a`
  font-family: ${Typography.ProximaNova};
  font-weight: 600;
  font-style: normal;
  font-size: ${FontSizes.Base};
  text-align: center;
`;

export const Bag = styled.svg`
  cursor: pointer;
  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    margin-left: 0;
  }
  @media screen and (max-width: ${Breakpoints.SmallMobile}) {
    margin-left: 5px;
  }
  @media screen and (min-width: ${Breakpoints.IPad}) {
    :hover {
      opacity: 50%;
    }
  }
`;
