import { Typography } from "@serenaandlily/constants/constants";

const defaultTheme = {
  fabricDescription: {
    tag: "p",
    fontFamily: Typography.BeatriceLight,
    fontColor: "Midnight",
    fontSize: "14px",
    fontSizeTablet: "14px",
    fontSizeDesktop: "14px"
  }
};

export default defaultTheme;
