const basePixels = 12;
const fullPercentage = 100;
const numDecimals = 5;
const getColumnWidth = (width) => {
  const widthInteger = parseInt(width);
  const widthPercentage = (widthInteger / basePixels) * fullPercentage;
  return `${widthPercentage.toFixed(numDecimals)}%`;
};

export default getColumnWidth;
