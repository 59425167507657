import React from "react";
import CTA from "../CTA";
import PropTypes from "prop-types";
import { LinksSectionContainer } from "./LinksSectionStyled";
import Text from "../Text";
import defaultTheme from "./LinksSectionTheme";
import useComponentTheme from "../Theme/useComponentTheme";

const LinksSection = ({ linksSectionHeading, ctas, theme = defaultTheme }) => {
  const linksTheme = useComponentTheme({ theme });
  return (
    <React.Fragment>
      <LinksSectionContainer>
        {linksSectionHeading && (
          <Text
            {...linksSectionHeading.text}
            themeName={linksTheme?.heading?.themeName}
          />
        )}
        {ctas?.map((cta, idx) => {
          const key = `links-section-cta-${idx}`;
          return (
            <CTA key={key} {...cta} themeName={linksTheme?.cta?.themeName} />
          );
        })}
      </LinksSectionContainer>
    </React.Fragment>
  );
};

export default LinksSection;

LinksSection.propTypes = {
  linksSectionHeading: PropTypes.object,
  showBorder: PropTypes.bool,
  ctas: PropTypes.array,
  theme: PropTypes.object
};
