import { QuanitySpinnerContainer } from "../QuantitySpinner/QuantitySpinnerStyled";
import { ProductErrorMessageContainer } from "../ProductErrorMessage/ProductErrorMessageStyled";
import styled from "styled-components";
import {
  Colors,
  FontSizes,
  Spacing,
  Typography
} from "../../constants/constants";
import { AffirmMessage } from "../AffirmComponents/AffirmPromoMessage/AffirmPromoMessageStyled";
import { Breakpoints } from "@serenaandlily/constants/constants";

export const ProductOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddToCartContainer = styled.div`
  margin-top: 0px;
  flex: 1;
  margin-bottom: ${Spacing.Small};
`;

export const CTACalloutLink = styled.a`
  text-decoration: none;
  font-size: ${FontSizes.Base};
  font-family: ${Typography.BeatriceMedium};
  text-transform: uppercase;
  text-decoration-line: underline;
  text-decoration-color: ${Colors["Midnight 70%"]};
  text-decoration-thickness: 2px;
  text-underline-offset: 0.25em;
  line-height: 1.5em;
  color: ${Colors["Midnight"]};
  font-weight: 500;
  cursor: pointer;
  padding-top: 6px;
  padding-bottom: 8px;
  margin-bottom: 16px;
`;

export const PdpQuantitySpinnerContainer = styled.div`
  margin-top: 0px;
  margin-bottom: ${Spacing.Base}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 16px;

  > ${ProductErrorMessageContainer} {
    max-width: 190px;
    position: relative;
    top: 25px;
    margin-left: 15px;
    font-size: ${FontSizes.Small}px;
    letter-spacing: 0.02em;
  }

  > ${QuanitySpinnerContainer} {
    width: unset;
  }

  @media (max-width: ${Breakpoints.XSMobileMax}) {
    margin-bottom: 32px;
  }
`;

export const ProductPriceContainer = styled.div`
  ${({ isMobile }) => {
    const margin = "0 0 6px";

    return isMobile
      ? `
        display: flex;
        width: fit-content;
        margin: ${margin};
        padding-top: 16px;
        margin-bottom: 16px;
      `
      : `
        display: flex;
        margin: ${margin};
      `;
  }};
`;

export const ProductDescription = styled.div`
  max-width: 385px;
  margin-bottom: 35px;
`;

export const AffirmWrapper = styled.div`
  height: 18px;
  margin-bottom: ${Spacing.Small}px;

  ${AffirmMessage} {
    height: auto;
    margin: 0;
    width: auto;
    color: ${Colors["Medium Primary"]};

    span,
    > span:after {
      color: ${Colors["Dove Gray"]} !important;
    }

    .affirm-modal-trigger {
      color: ${Colors["Medium Primary"]};
      margin-left: 5px;
    }
  }
`;

export const ProductOptionBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  > div:first-of-type {
    display: none;
  }
  @media screen and (max-width: ${Breakpoints.LgTablet}) {
    background-color: ${Colors.White};
  }
`;

export const QtyAndAddContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-right: 2px;
`;

export const ProductCalloutWrapper = styled.div`
  margin: 0;
  height: 16px;
  border-bottom: 1px solid ${({ theme }) => theme?.productCallout?.borderColor};
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 1.33;
  text-transform: uppercase;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 1px;
  margin-bottom: ${Spacing.Small}px;
`;

export const ProductEddWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  & label {
    margin-left: 17px;
  }
`;

export const ProductEdd = styled.div``;
export const ProductPromoMessages = styled.div`
  margin-bottom: ${({ pdpHasErrorMessage }) =>
    pdpHasErrorMessage ? `${Spacing.Small}px` : `${Spacing.Large - 12}px`};

  @media (max-width: ${Breakpoints.XSMobileMax}) {
    margin-bottom: 16px;
  }
`;
