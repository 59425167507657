import styled from "styled-components";
import { Breakpoints } from "../../../constants/constants";

export const LocationLink = styled.a`
  :hover {
    opacity: 50%;
  }

  cursor: pointer;

  display: ${({ hide }) => (hide ? "none" : "flex")};
  align-items: center;

  @media screen and (min-width: ${Breakpoints.Large}) {
    display: flex;
  }
`;
