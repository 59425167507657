const capitalizeAllWords = (string, lowercase = true) => {
  if (!string) return "";
  const lowercaseString = lowercase ? string.toLowerCase() : string;
  const words = lowercaseString.split(" ");
  const capitalizedWords = words.map((word) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    return capitalized;
  });
  const capitalizedString = capitalizedWords.join(" ");
  return capitalizedString;
};

export const camelCaseToWords = (str) => {
  const res = `${str[0].toUpperCase()}${str.slice(1)}`;
  return res.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
}

export default capitalizeAllWords;
