import React from "react";

import CTA from "../../CTA";
import Divider from "../../Divider";

export const ALLOWED_LEFT_NAVIGATION_CONTENT = {
  CTA: {
    type: 'CTA',
    component: CTA
  },
  Accordion: {
    type: 'Accordion',
    component: React.lazy(() => import('../../Accordion'))
  },
  BackgroundColor: {
    type: 'BackgroundColor',
    component: React.lazy(() => import('../../BackgroundColor'))
  },
  Divider: {
    type: 'Divider',
    component: Divider
  },
};

export const getLeftNavigationContent = (contentType) => {
  return ALLOWED_LEFT_NAVIGATION_CONTENT[contentType]?.component;
};

export default getLeftNavigationContent;
