import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { BreakpointImageWrapper, Image } from "./BreakpointImageStyled";
import { useBreakpoint } from "../Breakpoints";
import getAssetUrl from "../../utils/getAssetUrl";

const BreakpointImage = ({
  desktopImage,
  tabletImage,
  mobileImage,
  altText,
  isBanner = false,
  setImageRatio,
  contentItemIndex
}) => {
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints["DesktopMobile"];
  const isDesktopMed = breakpoints["ScreenWidth"];
  const desktopImageProps = desktopImage?.src || desktopImage;
  const tabletImageProps = tabletImage?.src || tabletImage;
  const mobileImageProps = mobileImage?.src || mobileImage;
  const desktopImageSrc = getAssetUrl(desktopImageProps);
  const tabletImageSrc = getAssetUrl(tabletImageProps);
  const mobileImageSrc = getAssetUrl(mobileImageProps);
  const { image: desktopImageData } = desktopImageProps?.diImage || {};
  const { image: tabletImageData } = tabletImageProps?.diImage || {};
  const { image: mobileImageData } = mobileImageProps?.diImage || {};
  const hasImagePriority = contentItemIndex === 0 ? true : false;

  useEffect(() => {
    window.addEventListener("resize", imageRatio);
    return () => {
      window.removeEventListener("resize", imageRatio);
    };
  });

  useEffect(() => {
    imageRatio();
  }, [isMobile, isDesktopMed]);

  const imageRatio = () => {
    isMobile &&
      mobileImageSrc &&
      isBanner &&
      setImageRatio({
        w: mobileImageData?.amplienceData?.width,
        h: mobileImageData?.amplienceData?.height
      });
    isDesktopMed &&
      tabletImageSrc &&
      !isMobile &&
      isBanner &&
      setImageRatio({
        w: tabletImageData?.amplienceData?.width,
        h: tabletImageData?.amplienceData?.height
      });
    !isMobile &&
      desktopImageSrc &&
      !isDesktopMed &&
      isBanner &&
      setImageRatio({
        w: desktopImageData?.amplienceData?.width,
        h: desktopImageData?.amplienceData?.height
      });
  };

  return (
    <Fragment>
      {isMobile && mobileImageSrc && (
        <BreakpointImageWrapper
          width={mobileImageData?.amplienceData?.width}
          height={mobileImageData?.amplienceData?.height}
        >
          <Image
            src={mobileImageSrc}
            alt={altText}
            layout={"responsive"}
            imageData={mobileImageData}
            priority={hasImagePriority}
          />
        </BreakpointImageWrapper>
      )}
      {isDesktopMed && tabletImageSrc && !isMobile && (
        <BreakpointImageWrapper
          width={tabletImageData?.amplienceData?.width}
          height={tabletImageData?.amplienceData?.height}
        >
          <Image
            src={tabletImageSrc}
            alt={altText}
            layout={"responsive"}
            imageData={tabletImageData}
            priority={hasImagePriority}
          />
        </BreakpointImageWrapper>
      )}
      {!isMobile && desktopImageSrc && !isDesktopMed && (
        <BreakpointImageWrapper
          width={desktopImageData?.amplienceData?.width}
          height={desktopImageData?.amplienceData?.height}
        >
          <Image
            src={desktopImageSrc}
            alt={altText}
            layout={"responsive"}
            imageData={desktopImageData}
            priority={hasImagePriority}
          />
        </BreakpointImageWrapper>
      )}
    </Fragment>
  );
};

export default BreakpointImage;

BreakpointImage.propTypes = {
  desktopImage: PropTypes.object,
  tabletImage: PropTypes.object,
  mobileImage: PropTypes.object,
  altText: PropTypes.string
};
