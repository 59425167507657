import React from "react";
import PropTypes from "prop-types";
import { Container, InputBox } from "./TextBoxStyled";
import Text from "../Text";

const TextBoxClassic = ({
  height,
  tabletHeight,
  desktopHeight,
  fontFamily,
  countLimit = 500,
  placeholderText,
  resizeType,
  backgroundColor,
  textBoxWidth,
  fontColor,
  fontSize,
  fontSizeTablet,
  fontSizeDesktop,
  lineHeight,
  textBoxFocusOutline,
  boxHelperTextTheme,
  value,
  setValue,
  disabled = false,
  borderRadius,
  borderColor,
  padding,
  error
}) => {
  const remainingCharacters = countLimit - value?.length;

  const handleChange = (e) => {
    const inputText = e?.target?.value;
    if (inputText?.length <= countLimit) {
      setValue(inputText);
    }
  };

  return (
    <Container>
      <InputBox
        placeholder={placeholderText}
        value={value}
        height={height || tabletHeight || desktopHeight}
        tabletHeight={tabletHeight || desktopHeight}
        desktopHeight={desktopHeight}
        fontFamily={fontFamily}
        resize={resizeType !== "Both" && resizeType}
        textBoxWidth={textBoxWidth}
        onChange={handleChange}
        fontColor={fontColor}
        backgroundColor={backgroundColor}
        fontSize={fontSize || fontSizeTablet || fontSizeDesktop}
        fontSizeTablet={fontSizeTablet || fontSizeDesktop}
        fontSizeDesktop={fontSizeDesktop}
        lineHeight={lineHeight}
        textBoxFocusOutline={textBoxFocusOutline}
        disabled={disabled}
        borderRadius={borderRadius}
        borderColor={borderColor}
        padding={padding}
        hasError={error}
      />
      <Text
        copy={`${remainingCharacters} characters left out of ${countLimit}`}
        theme={boxHelperTextTheme}
      />
    </Container>
  );
};

export default TextBoxClassic;

TextBoxClassic.propTypes = {
  height: PropTypes.number,
  tabletHeight: PropTypes.number,
  desktopHeight: PropTypes.number,
  fontFamily: PropTypes.string,
  countLimit: PropTypes.number,
  placeholderText: PropTypes.string,
  resizeType: PropTypes.string,
  backgroundColor: PropTypes.string,
  textBoxWidth: PropTypes.number,
  fontColor: PropTypes.string,
  fontSize: PropTypes.string,
  fontSizeTablet: PropTypes.string,
  fontSizeDesktop: PropTypes.string,
  lineHeight: PropTypes.string,
  textBoxFocusOutline: PropTypes.string,
  boxHelperTextTheme: PropTypes.object,
  value: PropTypes.string,
  setValue: PropTypes.func,
  disabled: PropTypes.bool,
  borderRadius: PropTypes.string,
  borderColor: PropTypes.string,
  padding: PropTypes.string,
  error: PropTypes.object
};
