import { useCallback, useState } from "react";
import { axiosPostFetcher } from "@serenaandlily/utils";
import useSWRImmutable from "swr/immutable";
import { useSWRConfig } from "swr";

async function checkEmailFetcher(data) {
  const [email, source] = data;
  return axiosPostFetcher("/api/email/validate", { arg: { email, source } });
}

const useEmailValidation = () => {
  const [key] = useState(null);
  const { cache, mutate } = useSWRConfig();
  const [isLoading, setIsLoading] = useState(false);

  const { data, error, isValidating } = useSWRImmutable(
    key,
    {},
    {
      revalidateOnFocus: false
    }
  );

  const fetchSendGridEnabledFromEdge = async (key) => {
    try {
      const res = await fetch(`/api/edge/getEdgeConfigValue?key=${key}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      });
      const useSendGridValidation = await res.json();
      return useSendGridValidation.value;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error fetching from edge config: ${error.message}`);
    }
  };

  const validate = async ({
    email,
    source,
    invalidEmailError,
    defaultErrorFallback
  }) => {
    if (
      (await fetchSendGridEnabledFromEdge(
        process.env.NEXT_PUBLIC_SENDGRID_EMAIL_VALIDATION_ENABLED_KEY
      )) === false
    ) {
      return true;
    }
    if (!email || email === "") return;
    email = (email + "").trim();
    const newKey = `${email}|${source}`;
    setIsLoading(true);
    try {
      if (email.includes("triggernetworkfail")) {
        throw new Error("Forced error with email triggernetworkfail");
      }
      let cachedData = cache.get(newKey);
      if (!cachedData) {
        const response = await checkEmailFetcher([email, source]);
        cachedData = response;
        await mutate(newKey, cachedData.data, false);
      }
      if (cachedData?.data?.isValid === false) {
        return invalidEmailError;
      }
      return;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    } finally {
      setIsLoading(false);
    }
    return defaultErrorFallback;
  };

  const factorySource = (source) => {
    return (source + "").toLocaleLowerCase().replaceAll(" ", "");
  };

  const validateFormattedEmail = useCallback(
    async (email, source) => {
      return validate({
        email,
        source: `emailsubscriptionform${factorySource(source)}`,
        invalidEmailError: "Invalid email address.",
        defaultErrorFallback: "Email validation failed. Please try again."
      });
    },
    [validate]
  );

  return {
    data: data?.data,
    error,
    validate,
    validateFormattedEmail,
    isValidating: isValidating || isLoading,
    key
  };
};

export default useEmailValidation;
