import React from "react";
import PropTypes from "prop-types";
import {
  RadioInputWrapper,
  RadioInputContainer,
  RadioInput,
  RadioInputLabel
} from "../../EmailSubscriptionStyled";

const Radio = ({ handleTab, isDaily }) => {
  return (
    <RadioInputWrapper>
      <RadioInputContainer>
        <RadioInput
          name="email-freq"
          id="daily"
          type="radio"
          value="daily"
          checked={isDaily}
          onChange={() => handleTab(null, "daily")}
        />
        <RadioInputLabel htmlFor="daily">DAILY</RadioInputLabel>
      </RadioInputContainer>
      <RadioInputContainer>
        <RadioInput
          name="email-freq"
          id="weekly"
          type="radio"
          value="weekly"
          checked={!isDaily}
          onChange={() => handleTab(null, "weekly")}
        />
        <RadioInputLabel htmlFor="weekly">WEEKLY</RadioInputLabel>
      </RadioInputContainer>
    </RadioInputWrapper>
  );
};

export default Radio;

Radio.propTypes = {
  handleTab: PropTypes.func,
  isDaily: PropTypes.bool
};
