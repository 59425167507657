import zIndex from "@serenaandlily/constants/zIndex";

export const AVOID_LOCK_MODAL_TIME = 4000;

export const MODAL_DISPLAY_DELAY = 3000;

export const MODAL_Z_INDEX = zIndex.NEW_USER_MODAL;

export const NEW_USERS = "New Users";
export const ALL_USERS = "All Users";
export const RETURNING_USERS = "Returning Users";
