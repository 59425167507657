import React from "react";

const ArrowIcon = () => (
  <svg width="10px" height="8px" viewBox="0 0 37 18" version="1.1">
    <title>Arrow 1</title>
    <g
      id="Full-Bleed---Grid-(24px-margin)"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <g
        id="desktop_1024px-copy"
        transform="translate(-793.000000, -3499.000000)"
        fillRule="nonzero"
        stroke="#383838"
      >
        <g
          id="Arrow-1"
          transform="translate(811.500000, 3508.000000) rotate(-270.000000) translate(-811.500000, -3508.000000) translate(802.500000, 3490.000000)"
        >
          <polyline
            id="Path-2-Copy"
            transform="translate(9.000000, 18.000000) rotate(-90.000000) translate(-9.000000, -18.000000) "
            points="-9 9 9 27 27 9"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ArrowIcon;
