import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Tile,
  Input,
  Label,
  DisabledScreen,
  VariationInput,
  DisabledScreenWrapper
} from "./TileInputStyled";

const TileInput = ({
  checked,
  children,
  disabled,
  category,
  id,
  name,
  setUnavailableTileSelected,
  setChangeToNextAvailable,
  setTileClicked,
  onChange,
  onMouseLeave,
  onMouseOver,
  size = "medium",
  title,
  isColorTile = true,
  value
}) => {
  useEffect(() => {
    if (disabled && checked)
      setUnavailableTileSelected?.((prev) => (prev ? prev : category));
    if (!disabled)
      setChangeToNextAvailable?.((prev) => {
        if (prev?.[category]) return prev;
        else
          return { ...(prev || {}), [category]: () => onChange?.({}, value) };
      });
  }, [
    disabled,
    category,
    checked,
    setUnavailableTileSelected,
    onChange,
    setChangeToNextAvailable,
    isColorTile,
    value
  ]);

  // return event and value to callbacks.
  const handleChange = (event) => {
    if (onChange) {
      onChange(event, value);
      setTileClicked?.(true);
    }
  };

  const handleMouseOver = (event) => {
    if (onMouseOver) {
      onMouseOver(event, value);
    }
  };

  const handleMouseLeave = (event) => {
    if (onMouseLeave) {
      onMouseLeave(event, value);
    }
  };

  if (isColorTile) {
    return (
      <Tile
        onMouseLeave={handleMouseLeave}
        onMouseEnter={handleMouseOver}
        className={`tile-${size}`}
      >
        <Input
          type="checkbox"
          id={id}
          name={name}
          value={value}
          checked={checked}
          onChange={handleChange}
        />
        <Label htmlFor={id} aria-label={title} title={title}>
          {disabled && (
            <DisabledScreenWrapper>
              <DisabledScreen />
            </DisabledScreenWrapper>
          )}
          {children}
        </Label>
      </Tile>
    );
  } else {
    return (
      <VariationInput
        type="radio"
        name={name}
        id={id}
        value={value}
        checked={checked}
        onChange={handleChange}
      />
    );
  }
};

export default TileInput;
TileInput.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  category: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  setUnavailableTileSelected: PropTypes.func,
  setChangeToNextAvailable: PropTypes.func,
  setTileClicked: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseOver: PropTypes.func,
  name: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  title: PropTypes.string,
  isColorTile: PropTypes.bool,
  value: PropTypes.string
};
