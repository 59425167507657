// REACT IMPORTS //
import { useState, useEffect, useRef } from "react";

// 3RD PARTY IMPORTS //
import { useAtomValue } from "jotai";
import { AnimatePresence, motion } from "framer-motion";

import PropTypes from "prop-types";
import currency from "currency.js";
import debounce from "lodash.debounce";

// HOOK IMPORTS //
import { useBreakpoint } from "../Breakpoints";
import { useUserData } from "../../contexts/UserContext";
import { useCartUpdate } from "../../contexts/CartContext";
import { useProductContext } from "../../contexts/ProductContext";
import { cuProductAtom } from "../../state/atoms/cuProductAtomStore";
import { useSidePanelContext } from "../../contexts/SidePanelContext";
import { useProductSwatchDescription } from "@serenaandlily/hooks/useProductSwatchDescription";

import useComponentTheme from "../Theme/useComponentTheme";

// UTIL IMPORTS //
import groupFabricsBySubType from "@utils/groupFabricsBySubType/groupFabricsBySubType";

// COMPONENT IMPORTS //
import { PointLoader } from "../Icons/Variants";

import CTA from "../CTA";
import Text from "../Text";
import CUFilters from "../CUFilters";
import FabricPreview from "../FabricPreview";
import AddedBagPanel from "../AddedBagPanel";
import SwipeLine from "../Icons/Variants/SwipeLine";
import DescriptionCloseIcon from "../DescriptionCloseIcon";
import SwatchSectionWrapper from "../SwatchSectionWrapper";
import FabricFeatures from "../FabricPanel/components/FabricFeatures";
import FabricContent from "../FabricPanel/components/FabricContent";
import CareInstructions from "../FabricPanel/components/CareInstructions";

// STYLED IMPORTS //
import {
  OrderSwatchesPanelWrapper,
  OrderSwatchPanelTitle,
  OrderSwatchPanelSubtitle,
  OrderSwatchesContainer,
  PriceAndApply,
  PriceContainer,
  PriceTitle,
  Price,
  SwatchWrapper,
  FabricDescriptionWrapper,
  FabricDescription,
  DescriptionTextWrapper,
  FabricDetailsHeader,
  TitleTextWrapper
} from "./OrderSwatchesPanelStyled";
import { FlexWrapper } from "../../../components/LayoutUtils/LayoutUtilsStyled";

// CONSTANT IMPORTS //
import { GLOBAL_HEADER_ID } from "../GlobalHeader";
import {
  MAGIC_NUMBERS_0,
  MAGIC_NUMBERS_1000
} from "@serenaandlily/constants/constants";

// THEME IMPORTS //
import defaultTheme from "./OrderSwatchesPanelTheme";

// eslint-disable-next-line max-lines-per-function
const OrderSwatchesPanel = ({
  filters,
  price,
  isInView,
  productId,
  cartPopupHeader,
  orderSwatchesMessage,
  headerOffset,
  theme = defaultTheme
}) => {
  // Hooks //
  const orderSwatchesTheme = useComponentTheme({ theme });

  const cuAtom = useAtomValue(cuProductAtom);
  const { quickshipFabrics, madeToOrderFabrics, subTypeOrder } = cuAtom.fabrics;

  const breakpoints = useBreakpoint();
  const isMobile = breakpoints["DesktopMobile"];
  const isTablet = breakpoints["LgTablet"];
  const isDesktop = !breakpoints["LgTablet"];

  const userData = useUserData();
  const { handleCartAdd } = useCartUpdate();
  const { selectedFilters } = useProductContext();
  const { handleOpenPanel, setContent, isFilterOpen } = useSidePanelContext();

  // Refs //
  const debounceRef = useRef(null);
  const leaveTimeoutRef = useRef(null);
  const fabricDescriptionRef = useRef(null);
  const modalRef = useRef(null);

  // States //
  const [hoveredFabricData, setHoveredFabricData] = useState(null);
  const [fabricDetailsExpanded, setFabricDetailsExpanded] = useState(false);

  const [totalFabricAmt, setTotalFabricAmt] = useState(0);
  const [totalFilteredFabricAmt, setTotalFilteredFabricAmt] = useState(0);

  const [filteredQuickShip, setFilteredQuickShip] = useState(quickshipFabrics);
  const [filteredMadeToOrder, setFilteredMadeToOrder] =
    useState(madeToOrderFabrics);

  const [groupedFilteredFabrics, setGroupedFilteredFabrics] = useState([]);

  const [selectedQuickshipSwatches, setSelectedQuickshipSwatches] = useState(
    []
  );
  const [selectedMadeToOrderSwatches, setSelectedMadeToOrderSwatches] =
    useState([]);

  const [currentSwatchList, setCurrentSwatchList] = useState([]);

  const [descriptionHeight, setDescriptionHeight] = useState(0);

  //
  const subTypeOrderLowerCase = subTypeOrder.map((subType) =>
    subType.toLowerCase()
  );

  const totalSelectedSwatches =
    selectedQuickshipSwatches.length + selectedMadeToOrderSwatches.length;

  const totalPrice = currency?.(price)
    ?.multiply?.(totalSelectedSwatches)
    ?.format?.();

  let slideVariants = {
    hidden: { y: 500, opacity: 0 },
    visible: {
      y: fabricDetailsExpanded ? -87 : 87,
      opacity: 1,
      transition: { duration: 0.4 }
    },
    exit: { y: 500, opacity: 1, transition: { duration: 0.4 } }
  };

  if (isMobile) {
    slideVariants = {
      hidden: { y: 500, opacity: 0 },
      visible: {
        y: fabricDetailsExpanded ? -92 : 92,
        opacity: 1,
        transition: { duration: 0.4 }
      },
      exit: { y: 500, opacity: 1, transition: { duration: 0.4 } }
    };
  }

  // Functions //
  const handleHoverFabric = async (fabric, fromClick = false) => {
    const debounceTime = fromClick ? MAGIC_NUMBERS_0 : MAGIC_NUMBERS_1000;
    if (leaveTimeoutRef.current) {
      clearTimeout(leaveTimeoutRef.current);
    }

    if (debounceRef.current) {
      debounceRef.current.cancel();
    }

    debounceRef.current = debounce(() => {
      setHoveredFabricData({
        name: fabric?.name,
        selectedSwatch: fabric
      });
    }, debounceTime);

    debounceRef.current();
  };

  const cancelMouseLeave = () => {
    if (leaveTimeoutRef.current) {
      clearTimeout(leaveTimeoutRef.current);
    }
  };

  const handleMouseLeaveFabric = () => {
    if (debounceRef.current) {
      debounceRef.current.cancel();
    }
    leaveTimeoutRef.current = setTimeout(() => {
      setHoveredFabricData(null);
    }, 300);
  };

  const filterFabrics = (selectedFilters, fabrics) => {
    const colors = selectedFilters["selectedColors"];
    const grades = selectedFilters["selectedGrades"];
    const types = selectedFilters["selectedTypes"];

    const colorsHash = {};
    for (const color of colors) colorsHash[color] = true;

    const filteredFabrics = [];

    fabrics.forEach((fabric) => {
      const { color = "", grade = "", type = "" } = fabric;
      const isMatchedColor = colors.length ? colorsHash[color] : true;
      const isMatchedGrades = grades.length ? grades.includes(grade) : true;
      const isMatchedTypes = types.length ? types.includes(type) : true;

      if (isMatchedColor && isMatchedGrades && isMatchedTypes)
        filteredFabrics.push(fabric);
    });

    return filteredFabrics;
  };

  // Effects //
  useEffect(() => {
    const isOpen = hoveredFabricData !== null;

    const handleClickOutside = (event) => {
      const clickedInsideModal = modalRef.current?.contains(event.target);
      const swatches = document.querySelectorAll("[id='fabric-swatch-image']");
      const clickedInsideExempt = [...swatches].some((el) =>
        el.contains(event.target)
      );

      if (!clickedInsideModal && !clickedInsideExempt) {
        setHoveredFabricData(null);
        setFabricDetailsExpanded(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hoveredFabricData, setHoveredFabricData]);

  useEffect(() => {
    if (!isDesktop && isFilterOpen) setHoveredFabricData(null);
  }, [isFilterOpen]);

  useEffect(() => {
    if (!hoveredFabricData) {
      setDescriptionHeight(0);
      return;
    }

    const calculateHeight = () => {
      if (fabricDescriptionRef.current) {
        const rect = fabricDescriptionRef.current.getBoundingClientRect();
        setDescriptionHeight(rect.height);
      }
    };

    calculateHeight();

    const observer = new MutationObserver(() => {
      calculateHeight();
    });

    if (fabricDescriptionRef.current) {
      observer.observe(fabricDescriptionRef.current, {
        childList: true,
        subtree: true
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [hoveredFabricData]);

  useEffect(() => {
    const quickShip = filterFabrics(selectedFilters, quickshipFabrics);
    const madeToOrder = selectedFilters?.isQuickshipSelected
      ? []
      : filterFabrics(selectedFilters, madeToOrderFabrics);

    if (!subTypeOrder?.length) {
      const { quickshipFabrics, madeToOrderFabrics } = cuAtom.fabrics;
      setFilteredMadeToOrder(madeToOrderFabrics);
      setFilteredQuickShip(quickshipFabrics);

      const totalFabricAmt =
        quickshipFabrics.length + madeToOrderFabrics.length;
      const totalFilteredFabricAmt = quickShip.length + madeToOrder.length;

      setTotalFabricAmt(totalFabricAmt);
      setTotalFilteredFabricAmt(totalFilteredFabricAmt);
      return;
    }

    const combinedFabrics = [...quickShip, ...madeToOrder];

    const filteredFabrics = combinedFabrics.filter((fabric) =>
      subTypeOrderLowerCase.includes(fabric.subType?.toLowerCase())
    );
    const totalFabricAmt = filteredFabrics.length;

    const groupedFabrics = groupFabricsBySubType(
      filteredFabrics,
      subTypeOrderLowerCase
    );

    const totalFilteredFabricAmt = groupedFabrics.reduce(
      (total, group) => total + group.fabrics.length,
      0
    );

    setGroupedFilteredFabrics(groupedFabrics);
    setTotalFabricAmt(totalFabricAmt);
    setTotalFilteredFabricAmt(totalFilteredFabricAmt);
  }, [selectedFilters]);

  const updateSwatchSelection = ({
    swatch,
    newSelected,
    selectedSwatches,
    setSwatches,
    fabric,
    totalSelectedSwatches
  }) => {
    if (selectedSwatches.includes(swatch)) {
      if (!isDesktop) setHoveredFabricData(null);
      return selectedSwatches.filter((swatchName) => swatchName !== swatch);
    } else if (totalSelectedSwatches !== 10) {
      const swatchArr = newSelected.concat([]);
      handleHoverFabric(fabric, true);
      swatchArr.push(swatch);
      setSwatches(swatchArr);
    }
  };

  const handleToggleSwatch = ({ swatch, type, swatchId, fabric }) => {
    const totalSelectedSwatches =
      selectedQuickshipSwatches.length + selectedMadeToOrderSwatches.length;

    let newSwatchList = currentSwatchList.concat([]);
    if (newSwatchList.includes(swatchId)) {
      newSwatchList = newSwatchList.filter(
        (currSwatchId) => currSwatchId !== swatchId
      );
    } else {
      newSwatchList.push(swatchId);
    }

    const swatchDetails = {
      swatch,
      newSelected:
        type === "quickship"
          ? selectedQuickshipSwatches
          : selectedMadeToOrderSwatches,
      selectedSwatches:
        type === "quickship"
          ? selectedQuickshipSwatches
          : selectedMadeToOrderSwatches,
      type,
      fabric,
      totalSelectedSwatches
    };

    if (type === "quickship") {
      const newSelected = updateSwatchSelection({
        ...swatchDetails,
        setSwatches: setSelectedQuickshipSwatches
      });
      if (newSelected) setSelectedQuickshipSwatches(newSelected);
    } else if (type === "madeToOrder") {
      const newSelected = updateSwatchSelection({
        ...swatchDetails,
        setSwatches: setSelectedMadeToOrderSwatches
      });
      if (newSelected) setSelectedMadeToOrderSwatches(newSelected);
    }

    setCurrentSwatchList(newSwatchList);
  };

  const handleOpenSwatchesModal = (swatches) => {
    const header = document?.getElementById(GLOBAL_HEADER_ID);
    const headerValues = header?.getBoundingClientRect();
    const isInView = headerValues?.top !== 0;
    handleOpenPanel(true);
    setContent(
      <AddedBagPanel
        isInView={isInView}
        panelTitle={cartPopupHeader}
        masterProductId={productId}
        forSwatches={true}
        price={price}
        quantity={1}
        swatches={swatches}
      />
    );
  };

  const getSwatchesFromList = () => {
    const allSwatches = [...madeToOrderFabrics, ...quickshipFabrics];
    const newSwatchList = [];
    allSwatches.forEach((swatch) => {
      const isSelected = currentSwatchList.includes(swatch?.swatchProduct?.id);
      if (isSelected) {
        newSwatchList.push(swatch);
      }
    });
    return newSwatchList;
  };

  const handleAddSwatches = async (e) => {
    e.preventDefault();
    const newSwatchList = getSwatchesFromList();
    const swatchItems = [];
    currentSwatchList.forEach((swatchId) => {
      swatchItems.push({
        quantity: 1,
        id: swatchId
      });
    });
    handleCartAdd({
      items: swatchItems,
      closePopup: null,
      customerEmail: userData?.user?.email
    });
    handleOpenSwatchesModal(newSwatchList);
  };

  const { data: dataHovered, isLoading: isLoadingDataHovered } =
    useProductSwatchDescription(
      hoveredFabricData?.selectedSwatch?.swatchProduct?.id || 0
    );

  // Variables //
  const hasFabricContent =
    hoveredFabricData?.selectedSwatch?.fabricContent?.length > 0;
  const hasCareInstructions =
    hoveredFabricData?.selectedSwatch?.careInstructions?.length > 0;

  return (
    <SwatchWrapper>
      <AnimatePresence>
        {hoveredFabricData && isDesktop && (
          <FabricPreview
            hoveredFabricData={hoveredFabricData}
            isTablet={isTablet}
            isMobile={isMobile}
            isDesktop={isDesktop}
            cancelMouseLeave={cancelMouseLeave}
            onClose={() => setHoveredFabricData(null)}
            onMouseEnter={() => {
              if (leaveTimeoutRef.current) {
                clearTimeout(leaveTimeoutRef.current);
              }
            }}
            handleMouseLeaveDesktopContainer={() =>
              isDesktop && handleMouseLeaveFabric()
            }
          />
        )}
      </AnimatePresence>
      <OrderSwatchesPanelWrapper
        headerOffset={headerOffset}
        showShadow={!hoveredFabricData?.selectedSwatch}
      >
        <OrderSwatchPanelTitle>
          <Text
            copy={`Order Swatches (${totalFabricAmt})`}
            theme={orderSwatchesTheme?.fabricTitle}
          />
        </OrderSwatchPanelTitle>
        <OrderSwatchPanelSubtitle>
          <Text {...orderSwatchesMessage?.text} />
        </OrderSwatchPanelSubtitle>

        <CUFilters
          filters={filters}
          isInView={isInView}
          totalFabricAmt={totalFabricAmt}
          filteredFabricAmt={totalFilteredFabricAmt}
        />

        <OrderSwatchesContainer descriptionHeight={descriptionHeight}>
          <SwatchSectionWrapper
            groupedFilteredFabrics={groupedFilteredFabrics}
            filteredQuickShip={filteredQuickShip}
            filteredMadeToOrder={filteredMadeToOrder}
            subTypeOrder={subTypeOrder}
            renderFabricsProps={{
              selectedMadeToOrderSwatches,
              selectedQuickshipSwatches,
              handleHoverFabric,
              handleMouseLeaveFabric,
              handleToggleSwatch,
              multipleSelection: true
            }}
            theme={orderSwatchesTheme}
            isDesktop={isDesktop}
          />
        </OrderSwatchesContainer>

        <AnimatePresence>
          {hoveredFabricData && !isDesktop && (
            <motion.div
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={slideVariants}
              style={{ zIndex: "99999999999", position: "relative" }}
              ref={modalRef}
            >
              <FabricDescription>
                <motion.div
                  onPan={(_, info) => {
                    if (info.offset.y < -50) {
                      setFabricDetailsExpanded(true);
                    }
                    if (info.offset.y > -50) {
                      setFabricDetailsExpanded(false);
                    }
                  }}
                  style={{ touchAction: "none" }}
                >
                  <FabricDetailsHeader
                    onClick={() => {
                      setHoveredFabricData(null);
                      setFabricDetailsExpanded(false);
                    }}
                  >
                    <SwipeLine />
                    <DescriptionCloseIcon />
                  </FabricDetailsHeader>
                </motion.div>
                <FabricDescriptionWrapper
                  fabricDetailsExpanded={fabricDetailsExpanded}
                  ref={fabricDescriptionRef}
                >
                  <TitleTextWrapper>
                    <Text
                      copy={hoveredFabricData?.name}
                      {...orderSwatchesTheme.fabricTitle}
                    />
                  </TitleTextWrapper>

                  {dataHovered?.description?.data && (
                    <DescriptionTextWrapper>
                      <Text
                        copy={dataHovered?.description?.data}
                        {...orderSwatchesTheme.fabricDescription}
                      />
                    </DescriptionTextWrapper>
                  )}

                  {!dataHovered?.description?.data && isLoadingDataHovered && (
                    <div
                      style={{
                        height: "16px",
                        paddingLeft: "2px",
                        marginBottom: "8px"
                      }}
                    >
                      <PointLoader />
                    </div>
                  )}

                  <FabricFeatures
                    fabricFeatures={
                      hoveredFabricData?.selectedSwatch?.fabricFeatures
                    }
                  />

                  {(hasFabricContent || hasCareInstructions) && (
                    <FlexWrapper flexDirection="column" gap="16px">
                      <FabricContent
                        fabricContent={
                          hoveredFabricData?.selectedSwatch?.fabricContent
                        }
                      />
                      <CareInstructions
                        careInstructions={
                          hoveredFabricData?.selectedSwatch?.careInstructions
                        }
                      />
                    </FlexWrapper>
                  )}
                </FabricDescriptionWrapper>
              </FabricDescription>
            </motion.div>
          )}
        </AnimatePresence>
        {!isFilterOpen && (
          <PriceAndApply showBoxShadow={true}>
            <PriceContainer>
              <PriceTitle>
                <Text
                  copy={`Total: ${totalSelectedSwatches} Swatches`}
                  themeName="headline5"
                />
              </PriceTitle>
              <Price>
                <Text copy={totalPrice} themeName="headline5" />
              </Price>
            </PriceContainer>
            <CTA
              cta={{ ctaText: "ADD TO BAG", onCtaClick: handleAddSwatches }}
              themeName="Primary Button"
            />
          </PriceAndApply>
        )}
      </OrderSwatchesPanelWrapper>
    </SwatchWrapper>
  );
};

export default OrderSwatchesPanel;

OrderSwatchesPanel.propTypes = {
  filters: PropTypes.array,
  price: PropTypes.string,
  isInView: PropTypes.bool,
  quickshipFabrics: PropTypes.array,
  madeToOrderFabrics: PropTypes.array,
  swatchHeadings: PropTypes.object,
  productId: PropTypes.string,
  cartPopupHeader: PropTypes.object
};
