const defaultTheme = {
  productErrorMessage: {
    tag: "p",
    fontFamily: "Beatrice Medium",
    fontColor: "#970013",
    fontSize: "12px",
    fontSizeTablet: "12px",
    fontSizeDesktop: "12px",
    lineHeight: "1.2",
    letterSpacing: "0.01em"
  }
};

export default defaultTheme;
