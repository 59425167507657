import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  LoaderContainer,
  AddedBagPanelTitle,
  AddedBagPanelBottomContent,
  SubtotalContainer,
  SubtotalLabel,
  Subtotal,
  AddedBagPanelCTAContainer,
  PanelContent,
  RelatedProducts,
  RelatedProductsTitle
} from "./AddedBagPanelStyled";
import Text from "../Text";
import Icons from "../Icons";
import { useCartUpdate } from "../../contexts/CartContext";
import ICON_TYPES from "../Icons/constants";
import useCrossSellProducts from "../../hooks/useCrossSellProducts";
import CrossSellTile from "../CrossSell/CrossSellTile";
import AddedItemTile from "./AddedItemTile";
import { ErrorWrapper } from "../CartPopUp/CartPopUpStyled";
import currency from "currency.js";
import useUser from "../../hooks/useUser";
import { useSidePanelContext } from "@serenaandlily/contexts/SidePanelContext";
import { useBreakpoint } from "../Breakpoints";
import SharedAddedToBagCTAs from "../SharedAddedToBagCTAs";
import { BagPanelWrapper } from "../BagPanel/BagPanelStyled";

const AddedBagPanel = ({
  panelTitle,
  crossSell,
  masterProductId,
  currentVariations,
  isInView,
  name,
  price,
  quantity,
  fabric,
  imageUrl,
  isCU,
  forSwatches,
  swatches
}) => {
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints["XSMobileMax"];
  const { isLoading, cartUpdateError } = useCartUpdate();
  const iconType = ICON_TYPES["CircularLoader"];
  const [crossellsPrice, setCrossellsPrice] = useState();
  const { user: { data: { isDSO, isDOO } = {} } = {} } = useUser();

  const { data: crossSellProducts } = useCrossSellProducts(
    masterProductId,
    crossSell?.crossSellProducts || crossSell
  );

  const { handleClosePanel } = useSidePanelContext();
  const hasCrossSellProducts = crossSellProducts?.length > 0;

  let totalPrice = currency?.(
    price?.formattedSalePrice || price?.formattedListPrice
  )
    ?.multiply(quantity)
    ?.format();

  let itemPrice = currency?.(
    price?.formattedSalePrice || price?.formattedListPrice
  )?.multiply(quantity);

  if (forSwatches)
    totalPrice = currency?.(price)?.multiply(swatches?.length).format();

  if (crossellsPrice)
    totalPrice = currency?.(itemPrice)?.add(crossellsPrice).format();

  return (
    <BagPanelWrapper isInView={isInView} isDSO={isDSO} isDOO={isDOO}>
      {(isLoading || cartUpdateError) && (
        <LoaderContainer>
          {isLoading && <Icons type={iconType} />}
          {cartUpdateError && (
            <ErrorWrapper>
              <span>{cartUpdateError}</span>
            </ErrorWrapper>
          )}
        </LoaderContainer>
      )}
      {!isLoading && !cartUpdateError && (
        <React.Fragment>
          <AddedBagPanelTitle>
            <Text {...panelTitle} themeName="headline3" />
          </AddedBagPanelTitle>
          <PanelContent>
            {!forSwatches && (
              <AddedItemTile
                imageUrl={imageUrl}
                name={name}
                fabric={fabric}
                quantity={quantity}
                price={price}
                currentVariations={currentVariations}
                isCU={isCU}
              />
            )}
            {forSwatches &&
              swatches?.map((swatch) => {
                const { name, swatchProduct } = swatch || {};
                const swatchSlug = swatchProduct?.slug;
                const swatchImageUrl = `https://cdn.media.amplience.net/s/serenaandlily/${swatchProduct?.netsuiteId}_SET`;
                return (
                  <AddedItemTile
                    key={`order-swatch-${swatchSlug}`}
                    imageUrl={swatchImageUrl}
                    name={name}
                    quantity={1}
                    price={{ formattedListPrice: price }}
                  />
                );
              })}
            {hasCrossSellProducts && (
              <RelatedProducts>
                <RelatedProductsTitle>
                  <Text {...crossSell?.crossSellHeading} />
                </RelatedProductsTitle>
                {crossSellProducts?.map((crossSellProduct, idx) => {
                  return (
                    <CrossSellTile
                      {...crossSellProduct}
                      key={idx}
                      parentVariations={currentVariations}
                      isInBagPanel={true}
                      setCrossellsPrice={setCrossellsPrice}
                    />
                  );
                })}
              </RelatedProducts>
            )}
          </PanelContent>
          <AddedBagPanelBottomContent>
            <SubtotalContainer>
              <SubtotalLabel>
                <Text copy="Subtotal" themeName="headline5" />
              </SubtotalLabel>
              <Subtotal>
                <Text copy={totalPrice} />
              </Subtotal>
            </SubtotalContainer>
            <AddedBagPanelCTAContainer fullWidth={!isDSO && isMobile}>
              <SharedAddedToBagCTAs
                isDSO={isDSO}
                isMobile={isMobile}
                handleClosePanel={handleClosePanel}
                isAddedBagPanel={true}
              />
            </AddedBagPanelCTAContainer>
          </AddedBagPanelBottomContent>
        </React.Fragment>
      )}
    </BagPanelWrapper>
  );
};

export default AddedBagPanel;

AddedBagPanel.propTypes = {
  panelTitle: PropTypes.object,
  crossSell: PropTypes.object,
  masterProductId: PropTypes.string,
  currentVariations: PropTypes.object,
  isInView: PropTypes.bool,
  name: PropTypes.string,
  price: PropTypes.any,
  quantity: PropTypes.number,
  fabric: PropTypes.string,
  imageUrl: PropTypes.string,
  isCU: PropTypes.bool,
  forSwatches: PropTypes.bool,
  swatches: PropTypes.array
};
