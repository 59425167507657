import styled from "styled-components";
import { Colors } from "../../constants/constants";

export const ScrollerProgressContainer = styled.div`
  position: relative;
  overflow: visible;
  height: 1px;
  width: 100%;
  border-radius: 100px;
  background: ${({ bgColor }) => bgColor || Colors["Midnight 40%"]};
`;

export const ScrollerProgressBar = styled.div`
  top: -0.5px;
  position: absolute;
  left: 0;
  height: ${({ height }) => height}px;
  width: ${({ scrollBarWidth }) => `${scrollBarWidth}px`};
  border-radius: 100px;
  background: ${({ bgColor }) => bgColor || Colors["Primary Midnight"]};

  &:hover {
    cursor: grab;
  }
`;
