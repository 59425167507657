const defaultTheme = {
  shopTheLookCta: {
    themeName: "CTA White",
    fontFamily: "Beatrice Semibold",
    fontWeight: "600",
    fontSize: "12px",
    backgroundColor: "rgba(255, 255, 255, 0.40);",
    backgroundColorActive: "#FFFF",
    backgroundColorDisabled: "rgba(255, 255, 255, 0.40);",
    backgroundColorHover: "#FFFF",
    borderWidth: "1px",
    borderColor: "#FFF",
    borderColorActive: "#FFF",
    borderColorDisabled: "#FFF",
    borderColorHover: "#FFF",
    borderRadius: "borderRadiusPill",
    padding: "16px 24px;",
    color: "#243953",
    colorActive: "#243953",
    colorDisabled: "#243953",
    colorHover: "#243953"
  }
};

export default defaultTheme;
