import styled from "styled-components";
import getGridContainerDesktopStyles from "./utils/getGridContainerDesktopStyles";
import getGridContainerMobileStyles from "./utils/getGridContainerMobileStyles";
import { Breakpoints, ModularOrder } from "../../constants/constants";

export const ModularGridContainer = styled.div`
  display: block;
  background-color: ${({ bgColor }) => bgColor};

  // Tablet and Up
  ${({ mobileSize }) => getGridContainerMobileStyles(mobileSize)}

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    ${({ desktopSize }) => getGridContainerDesktopStyles(desktopSize)}
  }
`;

export const ModularRow = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 24px;
`;

export const ModularColumn = styled.div`
  grid-column-end: ${({ width }) => `span ${width}`};
  order: ${({ order }) => ModularOrder[order]};

  @media screen and (min-width: ${Breakpoints.IPad}) {
    order: 0 !important;
  }
`;
