import styled from "styled-components";

export const WishlistModalWrapper = styled.div`
  position: relative;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 9px;
  right: 9px;
  padding: 3px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
`;
