import React from "react";
import { Colors } from "../../constants/constants";

const PrintIconV2 = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.66157 0.00550471C3.40675 0.00550471 3.1741 0.236724 3.1741 0.489963V5.34556H1.95542C0.88077 5.34556 0 6.22089 0 7.2889V14.5668C0 15.6348 0.88077 16.5101 1.95542 16.5101H3.1741V19.9068C3.1741 20.1601 3.40675 20.3913 3.66157 20.3913H17.3384C17.5932 20.3913 17.8259 20.1601 17.8259 19.9068V16.5101H19.0446C20.1192 16.5101 21 15.6348 21 14.5668V7.28339C21 6.21538 20.1192 5.34005 19.0446 5.34005H17.8259V0.484459C17.8259 0.231219 17.5932 0 17.3384 0L3.66157 0.00550471ZM4.14904 0.974422H16.8454V5.34556H4.14904V0.974422ZM1.94988 6.31448H19.0446C19.593 6.31448 20.0195 6.73838 20.0195 7.28339V14.5613C20.0195 15.1063 19.593 15.5302 19.0446 15.5302H17.8259V12.1335C17.8259 11.8802 17.5932 11.649 17.3384 11.649H3.66157C3.40675 11.649 3.1741 11.8802 3.1741 12.1335V15.5357H1.95542C1.40702 15.5357 0.98048 15.1118 0.98048 14.5668V7.28339C0.974941 6.73838 1.40148 6.31448 1.94988 6.31448ZM4.14904 12.6234H16.8454V19.4169H4.14904V12.6234Z"
        fill={Colors.Midnight}
      />
    </svg>
  );
};

export default PrintIconV2;
