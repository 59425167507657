import React from "react";
import GenericPage from "@serenaandlily/layouts/GenericPage";

import useUser from "@lib/useUser";

const GenericPageWrapper = ({ children, ...rest }) => {
  return (
    <GenericPage useUser={useUser} {...rest}>
      {children}
    </GenericPage>
  );
};

export default GenericPageWrapper;
