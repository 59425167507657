import styled from "styled-components";
import {
  Breakpoints,
  FontSizes,
  Typography,
  Colors
} from "../../../constants/constants";

export const BagLink = styled.a`
  font-family: ${Typography.ProximaNova};
  font-weight: 600;
  font-style: normal;
  font-size: ${FontSizes.Base};
  text-align: center;
`;

export const Bag = styled.svg`
  cursor: pointer;
  opacity: ${({ isOpen }) => (isOpen ? "50%" : "100%")};

  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    margin-left: 0;
  }

  @media screen and (max-width: ${Breakpoints.SmallMobile}) {
    margin-left: 5px;
  }

  @media screen and (min-width: ${Breakpoints.IPad}) {
    :hover {
      opacity: 50%;
    }
  }
`;

export const SearchLogoContainer = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    opacity: 50%;
  }
  flex-direction: ${({ textPosition }) =>
    textPosition === "Left" ? "row" : "row-reverse"};
`;

export const SearchLogoText = styled.span`
  display: none;
  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    display: block;
  }

  padding: ${({ textPosition }) =>
    textPosition === "Left" ? "0 6px 0 0" : "0 0 0 6px"};
`;

export const SearchCloseContainer = styled.div`
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 12px 18px;
  @media screen and (max-width: ${Breakpoints.ScreenWidth}) {
    padding: 10px;
  }
  border-radius: 30px;
  background-color: ${Colors["Natural"]};
  :hover {
    opacity: 50%;
  }
`;

export const SearchCloseText = styled.span`
  display: none;
  text-align: center;
  font-size: ${FontSizes.Large};
  font-family: ${Typography.BeatriceLight};
  color: ${Colors["Midnight"]};
  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    display: block;
  }
  padding-left: 14px;
`;

export const LocationContainer = styled.div`
  margin-top: 4px;
  display: ${({ hide }) => (hide ? "none" : "flex")};
  @media only screen and (min-width: ${Breakpoints.ScreenWidth}) {
    display: flex;
  }
`;

export const WishlistContainer = styled.div`
  @media only screen and (max-width: ${Breakpoints.XSMobileMax}) {
    display: none;
  }
`;

export const ProfileLink = styled.a`
  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    display: flex;
    :hover {
      opacity: 50%;
    }
  }
`;

export const DropdownContainer = styled.div`
  position: relative;

  @media only screen and (max-width: ${Breakpoints.IPad}) {
    ${({ dropdownClass }) =>
      dropdownClass === "profile" ? "display: none;" : null}
  }

  .dropdown-container {
    max-height: 0;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    transition-delay: 0s;

    > div {
      border: 1px solid ${Colors["Light Primary"]};
      border-top: 3px solid ${Colors["Dark Primary"]};
      padding: 10px 20px 20px;
    }
  }

  .active.dropdown-container {
    max-height: 40em;
    -webkit-transition: all 0.7s ease-in;
    -moz-transition: all 0.7s ease-in;
    -o-transition: all 0.7s ease-in;
    -ms-transition: all 0.7s ease-in;
    transition: all 0.7s ease-in;
  }
`;

export const UtilityDropdownContainer = styled.div`
  visibility: ${({ active }) => (active ? "visibile" : "hidden")};
  width: ${({ width }) => width || "fit-content"};
  position: absolute;
  overflow: hidden;
  background-color: ${Colors.White};
  left: ${({ position }) => (position === "center" ? "50%" : "auto")};
  right: ${({ position }) => (position === "center" ? "auto" : "0")};
  transform: ${({ position }) =>
    position === "center" ? "translateX(-50%)" : ""};
  box-sizing: unset;
  top: calc(100% + 12px);
  z-index: 150;

  &.profile {
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
  }
`;

export const NoItemText = styled.p`
  margin: 0;
  padding: 10px 20px;
  font-size: 12px;
  font-family: ${Typography.ProximaNova};
  font-style: normal;
  letter-spacing: 0.025em;
  color: ${Colors["Dark Primary"]};
  font-weight: 400;
  line-height: 1.5rem;
  cursor: pointer;
  text-align: center;
`;

export const ArrowButton = styled.button`
  position: absolute;
  background-color: transparent;
  border: none;
  bottom: -10px;
  left: 0;
  width: 100%;
  opacity: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  overflow: hidden;

  &:focus {
    opacity: 1;
  }
  > div {
    ${(props) => {
      switch (props.rotate) {
        case 0:
          return `
        transform: rotate(0deg);
        transition: 0.3s;
            `;
        case 1:
          return `
        transform: rotate(-180deg);
        transition: 0.3s;
            `;
        default:
          return `
        transform: rotate(0deg);
            `;
      }
    }}
  }
`;

export const SubmenuLink = styled.a`
  display: block;
  text-align: center;
  white-space: nowrap;
  padding: 7px 0;
  width: auto;
  letter-spacing: 1px;
  color: ${Colors["Medium Primary"]};
  font-stretch: normal;
  line-height: 1.5;
  font-family: ${Typography.ProximaNova};
  font-size: ${FontSizes.Base};
  text-decoration: ${({ decoration }) => decoration || "none"};
  cursor: pointer;
`;

export const SubmenuText = styled.span`
  display: block;
  text-align: center;
  white-space: nowrap;
  padding: 7px 0;
  width: auto;
  letter-spacing: 1px;
  color: ${Colors["Dark Primary"]};
  font-stretch: normal;
  line-height: 1.5;
  font-family: ${Typography.ProximaNova};
  font-weight: 600;
  font-size: ${FontSizes.Base};
`;

export const SearchLogoWrapper = styled.button`
  background-color: ${Colors["White"]};
  border: 0;
  padding: 0;
`;
