const defaultTheme = {
  title: {
    themeName: "headline5"
  },
  subTitle: {
    themeName: "headline6"
  }
};

export default defaultTheme;
