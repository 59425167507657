const defaultTheme = {
  categoryName: {
    themeName: "headline5Medium",
    overrides: {
      lineHeight: "1"
    }
  },
  categoryType: {
    themeName: "textDescription"
  },
  variationTile: {
    themeName: "beatriceTile"
  }
};

export default defaultTheme;
