import React from "react";
import PropTypes from "prop-types";
import { Image, ImageBorder, ImageWrapper } from "./SwatchImageStyled";
import { useProductContext } from "../../contexts/ProductContext";
import Text from "../Text";
import capitalizeAllWords from "@serenaandlily/utils/capitalizeAllWords";

const SwatchImage = ({
  ImageTitle,
  imageId,
  imageSet,
  imageWidth = 51,
  imageHeight = 51,
  initialSwatch,
  onMouseOver = () => {},
  onMouseLeave = () => {},
  showDescription = false,
  isMultipleSelection = false,
  selectorId
}) => {
  
  const imageSrc = imageSet
  ? imageSet?.items[0]?.src +
  `?h=${imageHeight}&w=${imageWidth}&sm=s&fmt=auto&img404=noimagesmall`
  : "";
  const { selectedSwatch } = useProductContext() || {};
  let isSelected =
    selectedSwatch && !isMultipleSelection
      ? selectedSwatch?.swatchProduct?.id === imageId
      : initialSwatch?.swatchProduct?.id === imageId;
  return (
    <ImageBorder
      isSelected={isSelected}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      <ImageWrapper
        isMultipleSelection={isMultipleSelection}
        isSelected={isSelected}
      >
        <Image
          src={imageSrc}
          alt={ImageTitle}
          className={isSelected ? "selected" : ""}
          id={selectorId}
        />
      </ImageWrapper>
      {showDescription && (
        <Text themeName="fabricsDescription" copy={capitalizeAllWords(ImageTitle, false)} />
      )}
    </ImageBorder>
  );
};

export default SwatchImage;

SwatchImage.propTypes = {
  imageSet: PropTypes.shape({
    items: PropTypes.array
  }),
  ImageTitle: PropTypes.string,
  imageId: PropTypes.string,
  initialSwatch: PropTypes.object,
  onMouseOver: PropTypes.func,
  onMouseLeave: PropTypes.func,
  temporaryData: PropTypes.object,
  showDescription: PropTypes.bool,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  isMultipleSelection: PropTypes.bool
};
