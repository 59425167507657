import React from "react";
import PropTypes from "prop-types";
import {
  ModularGridContainer,
  ModularRow,
  ModularColumn
} from "./ModularGridStyled";
import { useBreakpoint } from "../Breakpoints";
import ModularContent from "./ModularContent";

const ModularGrid = ({ gridSize, contentItems, bgColor, contentItemIndex }) => {
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints["IPad"];

  return (
    <ModularGridContainer {...gridSize} bgColor={bgColor}>
      <ModularRow>
        {contentItems.map((columnData, idx) => {
          const key = `modular-grid-content-item-${idx}`;
          const { column, columnMobile, content, mobilePosition } = columnData;
          const width = isMobile ? columnMobile : column;

          return (
            <ModularColumn key={key} width={width} order={mobilePosition}>
              <ModularContent
                {...content}
                contentItemIndex={contentItemIndex}
              />
            </ModularColumn>
          );
        })}
      </ModularRow>
    </ModularGridContainer>
  );
};

export default ModularGrid;

ModularGrid.propTypes = {
  gridSize: PropTypes.object,
  contentItems: PropTypes.array,
  bgColor: PropTypes.string,
  contentItemIndex: PropTypes.number
};
