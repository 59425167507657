import { gql } from "@apollo/client";

const buildGetSearchGetTypeaheadResults = () => {
  return gql`
    query SearchGetTypeaheadResults(
      $searchPhrase: String
      $maxPromoMessages: Int
      $searchParameters: String
      $userParameters: String
      $customerEmail: String
      $guestToken: String
    ) {
      searchGetTypeaheadResults(
        searchPhrase: $searchPhrase
        maxPromoMessages: $maxPromoMessages
        searchParameters: $searchParameters
        userParameters: $userParameters
        customerEmail: $customerEmail
        guestToken: $guestToken
      ) {
        results
      }
    }
  `;
};

export default buildGetSearchGetTypeaheadResults;
