import React from "react";
import PropTypes from "prop-types";
import { Picture, PictureContainer } from "./ProductImageStyled";
import { Breakpoints } from "../../constants/constants";
import getAltFromSrc from "../../utils/getAltFromSrc";

const ProductImage = ({ src }) => {
  const alt = getAltFromSrc(src);
  return (
    <PictureContainer>
      <Picture>
        <source
          media={`(min-width: ${Breakpoints.ScreenWidth})`}
          srcSet={src}
          width="477px"
          height="100%"
        />
        <source
          media={`(max-width: ${Breakpoints.DesktopMobile})`}
          srcSet={src}
          width="308px"
          height="100%"
        />
        <img src={src} width="477px" height="100%" alt={alt} loading="lazy" />
      </Picture>
    </PictureContainer>
  );
};

export default ProductImage;

ProductImage.propTypes = {
  src: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};
