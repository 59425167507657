import {
  ALL_USERS,
  NEW_USERS,
  RETURNING_USERS
} from "@serenaandlily/components/NewUserPopup/constants";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import useSWR from "swr";

const useShowNewUserPopup = (
  { userPopUp } = {
    pageDelay: 5,
    visibilityOption: "None",
    specificSlugs: [],
    selectedPages: {}
  }
) => {
  const router = useRouter();
  const isGridPage =
    router.pathname.includes("/category") ||
    router.pathname.includes("/search");
  const { pageDelay, visibilityOption, specificSlugs, selectedPages } =
    userPopUp || {};
  const [showPopup, setShowPopup] = useState(false);
  const [shouldUserSeePopup, setShouldUserSeePopup] = useState(false);
  const [isPageCountFinished] = useState(
    typeof window !== "undefined" &&
      localStorage.getItem("pageCount") &&
      parseInt(JSON.parse(localStorage.getItem("pageCount"))) >= pageDelay
  );
  const fetcher = (url) => fetch(url).then((res) => res.json());

  const { data } = useSWR("/api/session", fetcher);

  const handleShowPage = () => {
    switch (visibilityOption) {
      case "All":
        return true;
      case "Select Page Types":
        if (router.pathname === "/" && selectedPages?.Homepage) return true;
        if (isGridPage && selectedPages?.GridPages) return true;
        if (router.pathname.startsWith("/products/") && selectedPages?.PDPs)
          return true;
        return false;
      case "Specific Slug":
        return specificSlugs.includes(router.asPath);
      default:
        return false;
    }
  };
  const [isInShowPage] = useState(handleShowPage());

  useEffect(() => {
    if (data) {
      const hasVisitedBefore = localStorage.getItem(
        "hasSeenNewUserPopupBefore"
      );

      if (userPopUp?.showFor === ALL_USERS) {
        handleAllUsersCase(data, setShouldUserSeePopup);
      } else {
        handleOtherUsersCase(
          userPopUp?.showFor,
          hasVisitedBefore,
          setShouldUserSeePopup,
          data
        );
      }

      if (!hasVisitedBefore) {
        localStorage.setItem("hasSeenNewUserPopupBefore", "true");
      }
    }
  }, [data]);

  useEffect(() => {
    setShowPopup(isPageCountFinished && isInShowPage && shouldUserSeePopup);
  }, [isPageCountFinished, isInShowPage, shouldUserSeePopup]);

  return { showPopup, setShouldUserSeePopup };
};
function handleAllUsersCase(data, setPopupDisplay) {
  if (data && !data.newUserPopupShown) {
    setPopupDisplay(true);
  }
}

function handleOtherUsersCase(
  showFor,
  hasVisitedBefore,
  setPopupDisplay,
  data
) {
  const modalAlreadyShown = sessionStorage.getItem("newUserPopupShown");
  if (!modalAlreadyShown) {
    if (
      (showFor === NEW_USERS && !hasVisitedBefore) ||
      (showFor === RETURNING_USERS &&
        hasVisitedBefore &&
        !data.newUserPopupShown)
    ) {
      setPopupDisplay(true);
    }

    sessionStorage.setItem("newUserPopupShown", "true");
  }
}
export default useShowNewUserPopup;
