/* eslint-disable complexity */
import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import InspirationCTA from "./InspirationCTA";
import LocationCTA from "./LocationCTA";
import TopNav from "../TopNav";
import AccessibilityToggle from "../../components/AccessibilityToggle";
import {
  GlobalHeaderContainer,
  HeaderContentContainer,
  TabletMenuLeftContainer,
  LogoContainer,
  LogoLabel,
  LogoLink,
  Logo,
  MenuButton,
  MenuButtonLabel,
  MenuButtonContent,
  MenuButtonPart,
  StickyHeaderContainer,
  SearchBarClickWrapper,
  StickySearchWrapper,
  FlyoutWrapper,
  LogoWrapper,
  InspirationCTAWrapper,
  ShopLabel,
  Container,
  LocationWrapper,
  FlyoutBackground,
  BackgroundWrapper,
  RightContent,
  LeftContent
} from "./GlobalHeaderStyled";
import GlobalSearchBar from "../GlobalSearchBar";
import ResultsFlyout from "../ResultsFlyout";
import GlobalHeaderIcons from "../GlobalHeaderIcons";
import SkipToMainAccessibility from "../SkipToMainAccessibility";
import defaultTheme from "./GlobalHeaderTheme";
import Text from "../Text";
import Image from "../Image";
import { useSidePanelContext } from "../../contexts/SidePanelContext";

import { default as useComponentTheme } from "../Theme/useComponentTheme";
import { default as useOutsideClick } from "../../utils/useOutsideClick";
import { useBreakpoint } from "../Breakpoints";
import { useCart } from "../../contexts/CartContext";
import { default as useUser } from "../../hooks/useUser";
import { useSearch } from "../../contexts/SearchContext";
import { useRouter } from "next/router";
import AnalyticsData from "../AnalyticsData";
import { useSlideOutMenuContext } from "../../contexts/SlideOutMenuContext";
import SidePanel from "../SidePanel";
import { trackPinterestEvent } from "@utils/pinterest";

const defaultTradeLogo = {
  diImage: {
    image: {
      id: "64717894-a0f4-40d2-b507-5f337aa435c8",
      name: "SVGtradelogo",
      endpoint: "serenaandlily",
      defaultHost: "cdn.media.amplience.net"
    }
  }
};

export const GLOBAL_HEADER_ID = "global-header";

// eslint-disable-next-line max-lines-per-function, complexity
const GlobalHeader = ({
  inspirationsCta,
  logo,
  tradeLogo,
  topNavContent,
  useSlideoutOnDesktop,
  openMenu,
  isSticky = false,
  animate = false,
  headerIcons,
  textIcons,
  searchGlobalIcon,
  searchPlaceholderText,
  searchDefault,
  isOpen,
  hoverColor,
  menuButton,
  flyoutType,
  contentToFocus,
  setSlideWidth,
  isSMBSticky,
  theme = defaultTheme,
  getHeaderHeight = () => {},
  setIsOpen,
  pageType
}) => {
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints["ScreenWidth"];
  const isSmallMobile = breakpoints["IPad"];
  const isDesktopMobile = breakpoints["DesktopMobile"];

  const router = useRouter();

  const cart = useCart();
  const { itemCount } = cart;

  const user = useUser();
  const { user: { data: { isDOO } = {} } = {} } = user;

  const search = useSearch();

  const {
    showSearchBar,
    autocompleteResults,
    setShowSearchBar,
    setSearchBarValue,
    setSearchBarChanged,
    setAutocompleteString,
    setAutocompleteText
  } = search;

  const globalHeaderTheme = useComponentTheme({ theme });
  const [showResults, setShowResults] = useState(false);
  const [wrapperHeight, setWrapperHeight] = useState(500);
  const [flyoutHeight, setFlyoutHeight] = useState(0);
  const SMBHeightInitialValue = isDesktopMobile ? 72 : 40;
  const [hasScrolled, setHasScrolled] = useState(false);
  const headerRef = useRef(null);
  const hasFlyoutBackground = showSearchBar && flyoutType === "Large";
  let additionalHeight = 0;
  if ((isSMBSticky && isSticky) || !hasScrolled) {
    additionalHeight = 72 + 45 + (!isMobile ? 40 : 0);
  } else {
    additionalHeight = 45;
  }

  const fixedElementsTotalHeight = additionalHeight;

  useEffect(() => {
    const headerHeight = headerRef?.current?.clientHeight;
    getHeaderHeight(headerHeight);
  }, []);

  useEffect(() => {
    setFixedElemententsHeight(fixedElementsTotalHeight);
    isSmallMobile && getHeaderHeight(fixedElementsTotalHeight);
  }, [fixedElementsTotalHeight]);

  useEffect(() => {
    const scroll = window.scrollY > 40;
    scroll !== hasScrolled && setHasScrolled(scroll);
    window.addEventListener("scroll", handleHeight);
    return () => {
      window.removeEventListener("scroll", handleHeight);
    };
  });

  const handleHeight = () => {
    const scroll = window.scrollY > 20;
    const SMBHeight = isDesktopMobile ? 72 : 40;
    const baseHeight = flyoutHeight + headerRef?.current?.offsetHeight;
    const fullHeight = baseHeight + SMBHeight;
    scroll && isSMBSticky && setWrapperHeight(fullHeight);
    !isSMBSticky && scroll && setWrapperHeight(baseHeight);
    !scroll && setWrapperHeight(fullHeight);
  };

  const handleClose = useCallback(() => {
    setSearchBarChanged(false);
    setShowSearchBar(false);
    setSearchBarValue("");
    setAutocompleteText("");
    setAutocompleteString("");
  }, [
    setSearchBarChanged,
    setShowSearchBar,
    setSearchBarValue,
    setAutocompleteText,
    setAutocompleteString
  ]);

  const handleRouteChange = () => {
    handleClose();
    const body = document.querySelector("body");
    body.style.overflow = "auto";
  };

  useOutsideClick(headerRef, handleClose);

  useEffect(() => {
    router?.events?.on?.("routeChangeComplete", handleRouteChange);
    return () => {
      router?.events?.off?.("routeChangeComplete", handleRouteChange);
    };
  }, [router?.events, handleClose]);

  const menuButtonProps = {
    fontFamily: menuButton?.fontFamily,
    fontColor: menuButton?.fontColor,
    fontSize: menuButton?.fontSize,
    fontSizeTablet: menuButton?.fontSize,
    fontSizeDesktop: menuButton?.fontSize
  };

  const {
    setIsVisible,
    setSecondaryFlyout,
    setActiveMobileSlideOutIdx,
    setFixedElemententsHeight
  } = useSlideOutMenuContext();

  const handleSelect = () => {
    openMenu();

    if (isOpen) {
      setIsVisible(false);
      setSecondaryFlyout();
      setSlideWidth(250);
      setActiveMobileSlideOutIdx(0);
      const slideOutContainer = document.querySelector(".slide-out-container");
      slideOutContainer.scrollTop = 0;
      if (isSmallMobile) {
        const mobileContainer = document.querySelector(
          ".mobile-menu-container"
        );
        mobileContainer.scrollTop = 0;
      }
    }
    showSearchBar && setShowSearchBar(false);
  };

  const getWrapperHeight = (height) => {
    const headerHeight = headerRef?.current?.offsetHeight;
    const wrapperTop = height + headerHeight + SMBHeightInitialValue;
    setFlyoutHeight(height);
    setWrapperHeight(wrapperTop);
  };

  useEffect(() => {
    if (router?.isReady && user?.isUserLoading === false) {
      const pagePath = router?.asPath?.split("/")[1].split("?")[0];
      if (pagePath !== "checkout") {
        const pageViewDetail =
          pageType === "404" ? { pageType: "404" } : { user };
        globalThis?.document?.body?.dispatchEvent(
          new CustomEvent("a-page-view", { detail: pageViewDetail })
        );
      }
      globalThis?.document?.body?.dispatchEvent(
        new CustomEvent("a-custom-attributes", { detail: { user } })
      );

      trackPinterestEvent("pinterestPageViewID", "PageVisit", {
        eventSourceUrl: window.location.href
      });
    }
  }, [router?.isReady, user?.isUserLoading]);

  const menuIconValues = (valueOpen, valueClosed) => {
    return isOpen ? valueOpen : valueClosed;
  };

  const { handleClosePanel } = useSidePanelContext();

  return (
    <Container
      ref={headerRef}
      id={GLOBAL_HEADER_ID}
      data-pdpprint="false"
      isSticky={isSticky}
      isSMBSticky={isSMBSticky}
      isOpen={isOpen}
    >
      <AnalyticsData
        id="a-global"
        requireAll={true}
        data={{
          router: { pathname: router?.pathname, asPath: router?.asPath },
          cart: cart?.isLoaded ? cart : undefined
        }}
      />
      <BackgroundWrapper>
        <StickyHeaderContainer>
          <GlobalHeaderContainer animate={animate}>
            <HeaderContentContainer onClick={handleClosePanel}>
              <LeftContent>
                <AccessibilityToggle />
                <TabletMenuLeftContainer
                  useSlideoutOnDesktop={useSlideoutOnDesktop}
                  hoverColor={hoverColor}
                  onClick={handleSelect}
                  className="menu-button"
                >
                  <MenuButton
                    aria-label="mobile-slide-out-menu-button"
                    isOpen={isOpen}
                  >
                    <MenuButtonLabel>
                      Button to open mobile slide-out menu
                    </MenuButtonLabel>
                    <MenuButtonContent>
                      <MenuButtonPart
                        top={menuIconValues(4, 0)}
                        left={menuIconValues(4, "")}
                        transform={menuIconValues(45, "")}
                      />
                      <MenuButtonPart
                        top={7}
                        isOpen={isOpen}
                        transform={menuIconValues(0, "")}
                      />
                      <MenuButtonPart
                        top={menuIconValues(16, 14)}
                        left={menuIconValues(4, "")}
                        transform={menuIconValues(-45, "")}
                      />
                    </MenuButtonContent>
                  </MenuButton>
                  {!isSmallMobile && (
                    <ShopLabel>
                      <Text
                        copy={menuButton?.text}
                        {...menuButtonProps}
                        themeName={globalHeaderTheme?.menuLeftLabel?.themeName}
                      />
                    </ShopLabel>
                  )}
                </TabletMenuLeftContainer>
                {isMobile && (
                  <LocationWrapper>
                    <LocationCTA />
                  </LocationWrapper>
                )}

                {!useSlideoutOnDesktop && (
                  <InspirationCTAWrapper animate={animate}>
                    <InspirationCTA {...inspirationsCta?.cta} />
                  </InspirationCTAWrapper>
                )}
                <GlobalHeaderIcons
                  type={"Left"}
                  textIcons={textIcons}
                  headerIcons={headerIcons}
                  itemCount={itemCount}
                  searchGlobalIcon={searchGlobalIcon}
                  flyoutType={flyoutType}
                  theme={globalHeaderTheme}
                  setIsOpen={setIsOpen}
                  isOpen={isOpen}
                />
              </LeftContent>
              <LogoContainer>
                <LogoLabel>Serena and Lily Logo</LogoLabel>
                <LogoWrapper animate={animate}>
                  <LogoLink href="/" aria-label="Serena and Lily">
                    <Logo animate={animate}>
                      {isDOO ? (
                        <Image
                          src={
                            tradeLogo?.logoImage?.src?.diImage?.image
                              ? tradeLogo?.logoImage?.src
                              : defaultTradeLogo
                          }
                          objectFit="contain"
                          altText={"Serena and Lily"}
                        />
                      ) : (
                        <React.Fragment>
                          {logo?.logoImage && (
                            <Image
                              src={logo?.logoImage?.src}
                              objectFit="contain"
                              altText={"Serena and Lily"}
                            />
                          )}
                          {!logo?.logoImage && (
                            <Text
                              copy={logo?.logoText}
                              themeName={
                                globalHeaderTheme?.logoTitle?.themeName
                              }
                            />
                          )}
                        </React.Fragment>
                      )}
                    </Logo>
                  </LogoLink>
                </LogoWrapper>
                <SkipToMainAccessibility
                  onClick={() => contentToFocus?.current?.focus()}
                />
              </LogoContainer>
              <RightContent>
                <GlobalHeaderIcons
                  type={"Right"}
                  textIcons={textIcons}
                  headerIcons={headerIcons}
                  itemCount={itemCount}
                  searchGlobalIcon={searchGlobalIcon}
                  flyoutType={flyoutType}
                  theme={globalHeaderTheme}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  user={user}
                />
              </RightContent>
            </HeaderContentContainer>
          </GlobalHeaderContainer>
          {topNavContent && !isMobile && !useSlideoutOnDesktop && (
            <TopNav topNavContent={topNavContent} />
          )}
        </StickyHeaderContainer>
        {showSearchBar && (
          <SearchBarClickWrapper>
            <StickySearchWrapper
              showSearchBar={showSearchBar}
              isLargeFlyout={flyoutType === "Large"}
            >
              <GlobalSearchBar
                setShowResults={setShowResults}
                placeholderText={searchPlaceholderText}
                flyoutType={flyoutType}
              />
            </StickySearchWrapper>
            <FlyoutWrapper showSearchBar={showSearchBar}>
              <ResultsFlyout
                searchResults={autocompleteResults.Products}
                popularQueries={autocompleteResults["Search Suggestions"]}
                showResults={showResults}
                searchDefault={searchDefault}
                flyoutType={flyoutType}
                getWrapperHeight={getWrapperHeight}
                handleHeight={handleHeight}
              />
            </FlyoutWrapper>
          </SearchBarClickWrapper>
        )}
      </BackgroundWrapper>
      {hasFlyoutBackground && (
        <FlyoutBackground
          wrapperHeight={wrapperHeight}
          onClick={() => handleClose()}
        />
      )}
      <SidePanel />
    </Container>
  );
};

export default GlobalHeader;

GlobalHeader.propTypes = {
  inspirationsCta: PropTypes.object,
  logo: PropTypes.object,
  tradeLogo: PropTypes.object,
  isSMBSticky: PropTypes.bool,
  topNavContent: PropTypes.array,
  useSlideoutOnDesktop: PropTypes.bool,
  openMenu: PropTypes.func,
  isSticky: PropTypes.bool,
  animate: PropTypes.bool,
  headerIcons: PropTypes.array,
  textIcons: PropTypes.array,
  searchGlobalIcon: PropTypes.object,
  searchPlaceholderText: PropTypes.string,
  searchDefault: PropTypes.object,
  getHeaderHeight: PropTypes.func,
  menuButton: PropTypes.object,
  hoverColor: PropTypes.string,
  isOpen: PropTypes.bool,
  flyoutType: PropTypes.string,
  contentToFocus: PropTypes.object,
  theme: PropTypes.object,
  setIsOpen: PropTypes.func,
  setSlideWidth: PropTypes.func
};
