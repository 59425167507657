import styled from "styled-components";
import { Breakpoints } from "../../constants/constants";

export const CarouselContainer = styled.div`
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    display: none;
  }
`;

export const ScrollableContainer = styled.div`
  display: flex;
  width: max-content;

  .carousel__slide {
    flex: 0 0 auto;
    scroll-snap-align: start;
    margin-right: 4px;
    width: auto;
  }
`;
