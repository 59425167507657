import styled from "styled-components";
import { Breakpoints, GlobalCssValues } from "../../constants/constants";

export const Container = styled.div`
  max-width: ${GlobalCssValues.MaxWidth};
  padding: 0 16px;
  margin: 0 auto;
`;

export const ContentContainer = styled.div`
  margin: 0;
  position: relative;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  flex-direction: column;
  min-height: 600px;
  > :first-child {
    background: ${({ backgroundSettings }) => {
      const { enableGradient, gradient, backgroundColor } =
        backgroundSettings || {};

      if (
        enableGradient &&
        gradient?.direction &&
        gradient?.colorStart &&
        gradient?.colorEnd
      ) {
        const gradientDirection =
          gradient.direction === "From top to bottom"
            ? "to bottom"
            : "to right";
        return `linear-gradient(${gradientDirection}, ${gradient.colorStart}, ${gradient.colorEnd})`;
      }

      return backgroundColor;
    }};
  }
`;

export const ContentAndFooterContainer = styled.div`
  ${({ isSTL }) =>
    isSTL
      ? ""
      : `
    @media screen and (min-width: ${Breakpoints.IPhone11}) {
      // overflow-x: hidden; ask the team about this line
    }
  `}
`;

export const ContentContainerOverlay = styled.div`
  display: none;

  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    &.overlay-active {
      background: hsla(0, 0%, 83.1%, 0.5);
      display: block;
      margin-top: ${({ horizontalNavMarginBottom }) =>
        horizontalNavMarginBottom ? -horizontalNavMarginBottom : 0}px;
      z-index: 1999;
      position: absolute;
      height: ${({ totalHeight }) => `${totalHeight}px`};
      width: 100%;
    }
  }
`;
export const SlideWrapper = styled.div`
  width: 0;
  position: absolute;
  left: 0;
  z-index: 3000;
  margin-top: ${({ horizontalNavMarginBottom, shouldNotShowHorizontalNav }) =>
    shouldNotShowHorizontalNav ? "-40px" : -40 - horizontalNavMarginBottom}px;
  @media screen and (min-width: ${Breakpoints.IPad}) {
    display: table-column;
  }
  @media screen and (max-width: ${Breakpoints.IPad}) {
    margin-top: 0;
    width: ${({ isOpen }) => (isOpen ? "100%" : "0")};
    position: ${({ showFixedSlide }) =>
      showFixedSlide ? "fixed" : "absolute"};
    top: ${({ showFixedSlide, headerHeight }) =>
      showFixedSlide ? `${headerHeight}px` : "inherit"};
  }

  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    top: ${({ showFixedSlide, headerHeight }) =>
      showFixedSlide ? `${headerHeight}px` : "inherit"};
  }
`;

export const ContentWrapper = styled.div`
  max-width: ${(props) =>
    props.fullWidth ? "100%" : GlobalCssValues.MaxWidth};
 margin: 0 auto;
 display: block;
 padding: ${({ noPadding }) => (noPadding ? 0 : "0 16px")};

 @media screen and (max-width: ${Breakpoints.IPad}) {
  &.menu-is-open {
    ${SlideWrapper} {
      width: 100%;
      transform: translateX(0);
      transition: 1s ease-in-out;
    }
  }
}

  &.menu-is-closed {
    ${SlideWrapper} {
      width: 0;
      transform: translateX(-300px);
      transition: 1s ease-in-out;
      height: 0;

    }
  }

  @media screen and (min-width: ${Breakpoints.IPad}) {
    padding: ${({ noPadding }) => (noPadding ? 0 : "0 16px")};

    &.menu-is-open {
      ${SlideWrapper} {
        z-index: 2000;
        width: ${({ slideWidth }) => `${slideWidth}px`};
      transition: 1s ease-in-out;
      }
    }

    &.menu-is-closed {
      ${SlideWrapper} {
        width: 0;
       transition: 1s ease-in-out;
       height: 0;
  }
`;

export const MenuActivator = styled.span`
  position: fixed;
  top: 0;
  left: 0;
  width: 25px;
  z-index: 2;
  display: block;
  height: ${({ currentHeight }) => `${currentHeight}px`};
`;

export const HorizontalContainer = styled.div``;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 72px;

  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    height: 40px;
  }
`;

export const HorizontalNavPlaceholder = styled.div`
  height: 40px;
`;

export const HorizontalMenuOverlay = styled.div`
  @media screen and (min-width: ${Breakpoints.IPad}) {
    &.overlay-active {
      background: hsla(0, 0%, 83.1%, 0.5);
      display: block;
      z-index: 1999;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
`;
