import styled, { css } from "styled-components";
import { Breakpoints, Horizontal } from "../../constants/constants";
import SnlImage from "../SnlImage";
import getDynamicSizeValue from "@serenaandlily/utils/getDynamicSizeValue";
import { TypographyWrapper } from "../Typography/TypographyStyled";

const getWidth = (centerX) => {
  return centerX ? "fit-content" : "auto";
};

export const BackgroundImage = styled.div`
  height: ${({ mobileBannerHeightSize }) => `${mobileBannerHeightSize}px`};
  width: 100%;
  float: left;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  text-align: center;
  background-image: ${({ image }) => `url(${image})`};
  display: inline-grid;
  justify-content: center;
  position: relative;
  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    height: ${({ bannerHeightSize }) => `${bannerHeightSize}px`};
  }
`;

export const BannerImageWrapper = styled.div`
  height: ${({ mobileBannerHeightSize }) => `${mobileBannerHeightSize}px`};
  position: relative;
  width: fit-content;
  min-width: 100%;
  min-height: 100%;

  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    height: ${({ bannerHeightSize }) => `${bannerHeightSize}px`};
  }
`;

export const BannerImage = styled(SnlImage)`
  object-fit: cover;
`;

export const BreakpointImageWrapper = styled.div`
  aspect-ratio: ${({ aspectRatio }) => aspectRatio};
  width: 100%;
  height: auto;
`;

export const CtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: ${({ mobileHorizontalAlignment }) =>
    Horizontal[mobileHorizontalAlignment]};

  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    align-items: ${({ desktopHorizontalAlignment }) =>
      Horizontal[desktopHorizontalAlignment]};
  }

  ${({ textWidthForMobile }) =>
    textWidthForMobile &&
    css`
      width: ${({ textWidthForMobile }) =>
        textWidthForMobile ? `${textWidthForMobile}% !important` : ""};
      margin: auto;
    `};

  @media screen and (min-width: ${Breakpoints.IPad}) {
    ${({ textWidthForTablet }) =>
      textWidthForTablet &&
      css`
        width: ${({ textWidthForTablet }) =>
          textWidthForTablet ? `${textWidthForTablet}% !important` : ""};
        margin: auto;
      `};
  }

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    ${({ textWidth }) =>
      textWidth &&
      css`
        width: ${({ textWidth }) =>
          textWidth ? `${textWidth}% !important` : ""};
      `};
  }
`;

export const ContentContainer = styled.div`
  position: absolute;
  background-color: ${({ backgroundColor }) => `${backgroundColor}`};

  @media screen and (min-width: ${Breakpoints.XSMobileMin}) and (max-width: ${Breakpoints.XSMobileMax}) {
    top: ${({ yCoordinateMobile, centerYMobile }) =>
      centerYMobile ? "auto" : `${yCoordinateMobile}%`};
    left: ${({ xCoordinateMobile, centerXMobile }) =>
      centerXMobile ? "auto" : `${xCoordinateMobile}%`};
    padding: ${({ paddingMobile }) => paddingMobile || "0 15px"};

    ${({ centerXMobile }) => {
      const width = getWidth(centerXMobile);
      return `width: ${width};`;
    }};
  }

  @media screen and (min-width: ${Breakpoints.SMTabletMin}) and (max-width: ${Breakpoints.SMTabletMax}) {
    top: ${({ yCoordinateMobile, centerYTablet }) =>
      centerYTablet ? "auto" : `${yCoordinateMobile}%`};
    left: ${({ xCoordinateMobile, centerXTablet }) =>
      centerXTablet ? "auto" : `${xCoordinateMobile}%`};
    padding: ${({ paddingTablet }) => paddingTablet};

    ${({ centerXTablet }) => {
      const width = getWidth(centerXTablet);
      return `width: ${width};`;
    }};
  }

  @media screen and (min-width: ${Breakpoints.MDLaptopMin}) {
    top: ${({ yCoordinate, centerYDesktop }) =>
      centerYDesktop ? "auto" : `${yCoordinate}%`};
    left: ${({ xCoordinate, centerXDesktop }) =>
      centerXDesktop ? "auto" : `${xCoordinate}%`};
    padding: ${({ paddingDesktop }) => paddingDesktop};

    ${({ centerXDesktop }) => {
      const width = getWidth(centerXDesktop);
      return `width: ${width};`;
    }};
  }
`;

export const HeadlinesContainer = styled.div`
  justify-content: ${({ mobileHorizontalAlignment }) =>
    `${mobileHorizontalAlignment}`};
  display: flex;
  overflow-wrap: ${({ breakTextOnMobile }) =>
    breakTextOnMobile === "No" ? "none" : "break-word"};

  flex-direction: ${({ mobileOrientation }) =>
    mobileOrientation === "Vertical" ? "column" : "row"};

  text-align: ${({ mobileHorizontalAlignment }) => mobileHorizontalAlignment};

  > div {
    width: 100%;
  }

  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    justify-content: ${({ desktopHorizontalAlignment }) =>
      `${desktopHorizontalAlignment}`};
    flex-direction: ${({ desktopOrientation }) =>
      desktopOrientation === "Vertical" ? "column" : "row"};
    text-align: ${({ desktopHorizontalAlignment }) =>
      desktopHorizontalAlignment};

    > div {
      width: 100%;
    }
  }
`;
export const HeadlineTextContainer = styled.div`
  ${({ textWidthForMobile }) =>
    textWidthForMobile &&
    css`
      width: ${({ textWidthForMobile }) =>
        textWidthForMobile ? `${textWidthForMobile}% !important` : ""};
      margin: auto;
    `};

  @media screen and (min-width: ${Breakpoints.IPad}) {
    ${({ textWidthForTablet }) =>
      textWidthForTablet &&
      css`
        width: ${({ textWidthForTablet }) =>
          textWidthForTablet ? `${textWidthForTablet}% !important` : ""};
        margin: auto;
      `};
  }

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    ${({ textWidth }) =>
      textWidth &&
      css`
        width: ${({ textWidth }) =>
          textWidth ? `${textWidth}% !important` : ""};
      `};
  }

  ${({
    scaledBottomMarginMobile,
    scaledBottomMarginTablet,
    scaledBottomMarginDesktop,
    scaleBottomMargin,
    bottomMargin
  }) =>
    scaleBottomMargin
      ? `${getDynamicSizeValue({
          attr: "margin-bottom",
          size: scaledBottomMarginMobile,
          sizeTablet: scaledBottomMarginTablet,
          sizeDesktop: scaledBottomMarginDesktop
        })};
      `
      : `margin-bottom: ${bottomMargin}px`};

  ${TypographyWrapper} {
    ${({ fontSize, fontSizeTablet, fontSizeDesktop, scaleFontSize }) =>
      scaleFontSize &&
      `${getDynamicSizeValue({
        attr: "font-size",
        size: fontSize,
        sizeTablet: fontSizeTablet,
        sizeDesktop: fontSizeDesktop
      })};
      `};
  }
`;

export const BannerLink = styled.div`
  width: 100%;
  min-width: 100%;
  min-height: 100%;
`;

export const BannerWrapper = styled.div`
  display: contents;
`;

export const BannerContainer = styled.div`
  height: ${({ mobileBannerHeightSize }) => `${mobileBannerHeightSize}px`};
  justify-content: center;
  align-items: center;
  position: relative;

  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    height: ${({ bannerHeightSize }) => `${bannerHeightSize}px`};
  }
  width: 100%;
  height: auto;
`;

export const ContentWrapper = styled.div`
  height: ${({ mobileBannerHeightSize }) => `${mobileBannerHeightSize}px`};
  position: relative;
  border: ${({ addBorder }) => (addBorder ? "1px solid" : "none")};
  background-color: ${({ bannerBackgroundColor }) =>
    `${bannerBackgroundColor}`};
  margin: ${({ marginDesktop }) => `${marginDesktop}`};
  overflow: hidden;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-content: center;

  @media screen and (max-width: ${Breakpoints.IPhone11ProMax}) {
    margin: ${({ marginMobile }) => `${marginMobile}`};
  }

  @media screen and (max-width: ${Breakpoints.XSMobileMax}) {
    margin: ${({ marginMobile }) => `${marginMobile}`};
    justify-content: ${({ centerXMobile }) =>
      centerXMobile ? "center" : "initial"};
    align-items: ${({ centerYMobile }) =>
      centerYMobile ? "center" : "initial"};
  }

  @media screen and (min-width: ${Breakpoints.SMTabletMin}) and (max-width: ${Breakpoints.SMTabletMax}) {
    justify-content: ${({ centerXTablet }) =>
      centerXTablet ? "center" : "initial"};
    align-items: ${({ centerYTablet }) =>
      centerYTablet ? "center" : "initial"};
  }

  @media screen and (min-width: ${Breakpoints.MDLaptopMin}) {
    height: ${({ bannerHeightSize }) => `${bannerHeightSize}px`};
    justify-content: ${({ centerXDesktop }) =>
      centerXDesktop ? "center" : "initial"};
    align-items: ${({ centerYDesktop }) =>
      centerYDesktop ? "center" : "initial"};
  }
`;

export const OverlayImage = styled.img`
  position: absolute;
  top: ${({ bottom }) => `${bottom}%`};
  left: ${({ right }) => `${right}%`};
  height: ${({ mobileHeight }) => `${mobileHeight}px`};
  position: ${({ position }) => `${position}`};
  transform: ${({ mirrorImage }) => (mirrorImage ? `scaleX(-1)` : `scaleX(1)`)};

  @media screen and (min-width: ${Breakpoints.IPhone11ProMax}) {
    height: ${({ height }) => `${height}px`};
  }

  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    top: 0;
    left: 0;
  }
`;

export const OverlayImagesWrapper = styled.div`
  width: fit-content;
  top: ${({ xCoordinateMobile }) => `${xCoordinateMobile}%`};
  left: ${({ yCoordinateMobile }) => `${yCoordinateMobile}%`};
  position: relative;

  @media screen and (min-width: ${Breakpoints.IPhone11ProMax}) {
    top: ${({ xCoordinate }) => `${xCoordinate}%`};
    left: ${({ yCoordinate }) => `${yCoordinate}%`};
  }
`;
