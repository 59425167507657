const isWishlistActive = (wishlistConfigs, email) => {
  const { wishlistActive, wishlistWhitelistActive, wishlistWhitelist } =
    wishlistConfigs;

  if (!wishlistActive) return false;

  if (!wishlistWhitelistActive) return true;

  return wishlistWhitelist.includes(email);
};

export default isWishlistActive;
