import styled from "styled-components";

export const ImageWrapper = styled.div`
  padding: 0 24px;
  margin-bottom: 24px;
`;

export const FlyoutImage = styled.img`
  width: 100%;
  max-width: 198px;
`;
