const getSecondarySelected = (secondaryFlyout, tertiraryFlyout) => {
  if (!tertiraryFlyout || !secondaryFlyout) return "";

  const secondaryCategories = secondaryFlyout?.flyoutCategories;
  for (const category of secondaryCategories) {
    const tertiaryCategories = category?.secondaryFlyout;
    const isSelected =
      JSON.stringify(tertiaryCategories) === JSON.stringify(tertiraryFlyout);
    if (isSelected) return category?.category?.categoryName?.ctaText;
  }
  return "";
};

export default getSecondarySelected;
