import fetchProductById from "../gql/utils/fetchProductById";
import useSWRImmutable from "swr/immutable";
export async function crossSellFetcher(crossSellProductsId) {
  if (!crossSellProductsId || crossSellProductsId.length === 0) return [];
  const crossSellsProductPromises = crossSellProductsId?.map?.((productId) =>
    fetchProductById(productId)
  );
  if (!crossSellsProductPromises) return [];
  const allProducts = await Promise.all(crossSellsProductPromises);
  return allProducts
    ?.filter?.((product) => product?.getProduct?.id)
    ?.map?.((product) => ({
      product: {
        marketingName: product?.getProduct?.name,
        productId: product?.getProduct?.id,
        sku: product?.getProduct?.sku,
        variations: product?.getProduct?.variations,
        childProducts: product?.getProduct?.childProducts,
        netsuiteId: product?.getProduct?.netsuiteId,
        overRideMediaSet: product?.getProduct?.overRideMediaSet,
        productCallout: product?.getProduct?.productCallout
      },
      productName: product?.getProduct?.name
    }));
}

export const crossSellQueryKey = (productId) => {
  return `crossSellsProducts-${productId}`;
};

const useCrossSellProducts = (productId, crossSellProductsId) => {
  const { data, error } = useSWRImmutable(
    crossSellQueryKey(productId),
    async () => crossSellFetcher(crossSellProductsId)
  );

  return { data, error };
};
export default useCrossSellProducts;
