import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  CheckboxContainer,
  CheckboxInputWrapper,
  CheckboxLarge,
  ErrorMessage,
  Label
} from "./CheckboxInputStyled";
import CheckboxCheck from "../../Icons/Variants/CheckboxCheck";

const CheckboxInput = forwardRef(
  (
    {
      name,
      id,
      value,
      label,
      handleChange,
      error,
      defaultChecked,
      wrapperMarginTop = 7,
      readOnly,
      size,
      containerMarginBottom = 15,
      ...props
    },
    ref
  ) => {
    const CheckboxComponent = size === "large" ? CheckboxLarge : Checkbox;

    return (
      <CheckboxContainer marginBottom={containerMarginBottom}>
        <CheckboxInputWrapper marginTop={wrapperMarginTop}>
          <CheckboxComponent
            ref={ref}
            value={value}
            id={id || name}
            name={name}
            type="checkbox"
            onChange={handleChange}
            defaultChecked={defaultChecked}
            readOnly={readOnly}
            disabled={readOnly}
            {...props}
          />
          <Label htmlFor={id || name} styles={props?.styles}>
            <CheckboxCheck />
            {label}
          </Label>
        </CheckboxInputWrapper>
        {error && !readOnly && <ErrorMessage>{error}</ErrorMessage>}
      </CheckboxContainer>
    );
  }
);

export default CheckboxInput;

CheckboxInput.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  handleChange: PropTypes.func,
  containerMarginBottom: PropTypes.number,
  defaultChecked: PropTypes.bool,
  readOnly: PropTypes.string,
  size: PropTypes.oneOf(["default", "large"]),
  styles: PropTypes.object
};

CheckboxInput.displayName = "CheckboxInput";
