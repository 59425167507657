import { useState } from "react";
import { constructorHelpers } from "../../utils";
import { useWishlistUserCreds } from "@serenaandlily/hooks/useWishlistUserCreds";

export const useActiveSearch = () => {
  const [activeSearchResults, setActiveSearchResults] = useState({});
  const { wishlistUserCreds } = useWishlistUserCreds();

  const fetchActiveSearchResults = async ({
    phrase,
    showRetailProducts = false
  }) => {
    const results = await constructorHelpers.getSearch({
      phrase,
      showRetailProducts,
      page: 1,
      ...wishlistUserCreds
    });
    setActiveSearchResults(results);
  };

  return {
    activeSearchResults,
    fetchActiveSearchResults
  };
};
