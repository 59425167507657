/**
 * Given an array of child products and an array of desired variations, will return a child product with the best match
 * to the variations. So, for example, if no match is found for 3 variations, but there is a match for 2 of the
 * variations, then the product matching 2 would be returned. If we cannot find any matches will return undefined.
 * @param childProducts
 * @param variations
 * @returns {*}
 */
export default function getBestMatchForVariations({
  childProducts = [],
  variations = []
}) {
  const sortedProducts = getSortedChildProducts(childProducts, variations);
  if (sortedProducts?.length > 0) return sortedProducts[0];
}

const getMatchCount = (product, variations) => {
  let count = 0;
  for (let variation of variations) {
    if (
      product.variants?.some(
        (variant) =>
          variant.name === variation.name && variant.value === variation.value
      )
    ) {
      count++;
    }
  }
  return count;
};

// see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#sorting_with_map
const getSortedChildProducts = (childProducts, variations) => {
  // temporary array holds objects with position and sort-value
  const mapped = childProducts.map((childProduct, index) => {
    return {
      index,
      matchCount: getMatchCount(childProduct, variations)
    };
  });

  // sorting the mapped array containing the reduced values
  mapped.sort((a, b) => b.matchCount - a.matchCount);

  return mapped.map((m) => childProducts[m.index]);
};
