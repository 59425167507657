import styled from "styled-components";
import { Breakpoints } from "../../constants/constants";

export const Container = styled.figure`
  position: relative;
  background-repeat: no-repeat;
  margin: 0px;
  width: ${({ imageWidth }) => `${imageWidth}px`};

  &:hover {
    background-image: ${({ imageSrc }) => `url(${imageSrc})`};
  }

  @media screen and (min-width: ${Breakpoints.IPhone11ProMax}) {
    span {
      width: 100% !important;
    }
  }

  img {
    transition: opacity 0s;
    display: block;
    width: 100%;
    height: 100%;
    &:hover {
      opacity: 0;
    }
  }
`;
