import React from "react";
import PropTypes from "prop-types";
import Image from "next/image";
import { getPlaceholderUrl } from "../../utils";

const layoutStyles = {
  responsive: {
    width: "100%",
    height: "auto"
  },
  intrinsic: {
    maxWidth: "100%",
    height: "auto"
  },
  fill: {
    width: "100%",
    height: "100%",
    position: "absolute",
    inset: "0px"
  }
};

//eslint-disable-next-line complexity
const SnlImage = ({
  imageData,
  layout,
  customWidth,
  customHeight,
  loading = "lazy",
  priority = false,
  shouldAvoidLayoutStyles = false,
  ...props
}) => {
  const defaultWidth = 477;
  const defaultHeight = 600;
  const isFill = layout === "fill";
  const { amplienceData } = imageData || {};
  const { width, height } =
    amplienceData || imageData?.diImage?.image?.amplienceData || {};
  return (
    <Image
      width={!isFill ? customWidth || width || defaultWidth : null}
      height={!isFill ? customHeight || height || defaultHeight : null}
      blurDataURL={getPlaceholderUrl(width, height)}
      placeholder="blur"
      style={
        layoutStyles?.[shouldAvoidLayoutStyles ? {} : layout || "intrinsic"]
      }
      unoptimized={true}
      {...(!priority && { loading })}
      {...(priority && { priority })}
      {...props}
    />
  );
};

export default SnlImage;

SnlImage.propTypes = {
  imageData: PropTypes.object,
  customWidth: PropTypes.number,
  customHeight: PropTypes.number,
  layout: PropTypes.string,
  loading: PropTypes.string,
  priority: PropTypes.bool,
  shouldAvoidLayoutStyles: PropTypes.bool
};
