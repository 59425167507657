import React from "react";
import PropTypes from "prop-types";
import { Colors } from "../../../constants/constants";
import { LocationLink } from "./LocationCTAStyled";

const LocationCTA = ({ hide }) => {
  return (
    <LocationLink
      aria-label="Our Stores"
      href="/stores"
      hide={hide}
      id="header-location"
    >
      <svg
        height="21"
        viewBox="0 0 14 21"
        width="14"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="Our Stores"
      >
        <title id="iconTitle">Our stores</title>
        <g
          fill="none"
          fillRule="evenodd"
          stroke={Colors["Dark Primary"]}
          strokeWidth=".9"
          transform="translate(1 1)"
        >
          <path
            strokeLinejoin="round"
            d="m5.84375 0c3.22741401 0 5.84375 2.61633599 5.84375 5.84375 0 1.08047491-.2932335 2.09246148-.8044601 2.96071945l.0000301-.00025879-4.93443 8.19137954-5.15264047-8.19137954c-.50276606-.86799918-.79599953-1.87998575-.79599953-2.96046066 0-3.22741401 2.61633599-5.84375 5.84375-5.84375z"
          ></path>
          <circle cx="5.84375" cy="5.84375" r="2.65625"></circle>
        </g>
      </svg>
    </LocationLink>
  );
};

export default LocationCTA;

LocationCTA.propTypes = {
  hide: PropTypes.bool
};
