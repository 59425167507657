import { parseUserAgent } from "@serenaandlily/utils";
import axios from "axios";
import { hashValue } from "./analytics/_utils";
import handleCookies from "@serenaandlily/utils/handleCookies";

const EN_LANG = "en";
const SOURCE = "web";
const PARTNER_NAME = "Serena & Lily";

const getCurrentTime = () => Math.floor(Date.now() / 1000);

export default async function postToPinterestApi({
  eventName,
  actionSource,
  eventId,
  eventSourceUrl,
  partnerName,
  eventUserData,
  eventCustomData,
  userEmail,
  ...rest
}) {
  const ipResponse = await axios.get("/api/user-ip");
  const userData = {
    ...(userEmail && { em: [hashValue(userEmail)] }),
    external_id:
      [hashValue(handleCookies("sl_session_id", "").getCookie())] || "",
    client_ip_address: ipResponse.data.ip,
    client_user_agent: navigator.userAgent
  };

  const createPinterestPayload = ({
    eventName,
    eventId,
    eventSourceUrl,
    ...rest
  }) => {
    const currentTime = getCurrentTime();
    const deviceDetails = parseUserAgent(navigator.userAgent);

    return {
      data: [
        {
          event_name: eventName,
          action_source: SOURCE,
          event_time: currentTime,
          event_id: eventId,
          event_source_url: eventSourceUrl,
          partner_name: PARTNER_NAME,
          user_data: userData,
          device_brand: deviceDetails.brand,
          device_model: deviceDetails.model,
          device_type: deviceDetails.type,
          os_version: deviceDetails.osVersion,
          language: EN_LANG,
          custom_data: { ...rest }
        }
      ]
    };
  };

  const payload = createPinterestPayload({
    eventName,
    actionSource,
    eventId,
    eventSourceUrl,
    partnerName,
    eventUserData,
    eventCustomData,
    ...rest
  });

  try {
    await axios.post("/api/pinterest", { data: payload });
  } catch (error) {
    console.error(
      "Error posting data:",
      error.response ? error.response.data : error.message
    );
  }
}
