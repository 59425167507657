import { Colors } from "@serenaandlily/constants/constants";

const defaultTheme = {
  specialMessage: {
    themeName: "textSmall",
    fontSizeDesktop: 16,
    fontSize: 12,
    fontSizeTablet: 14,
    fontColor: Colors.Midnight,
    fontFamily: "Beatrice Regular"
  }
};

export default defaultTheme;
