import {
  CrossSellTileWrapper,
  QTYContainer
} from "../CrossSell/CrossSellStyled";
import { CallToAction, CTATextWrapper } from "../CTA/CTAStyled";
import {
  QuantityButton,
  QuantityInput
} from "../QuantitySpinner/QuantitySpinnerStyled";
import styled from "styled-components";
import { Horizontal } from "../../constants/constants";

export const LoaderContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AddedItemTileWrapper = styled.div`
  min-height: 161px;
`;

export const AddedItemTileImageContainer = styled.div`
  width: 100px;
  height: 100px;
  margin-right: 8px;
  float: left;
  img {
    width: 100px;
    height: 100px;
  }
`;

export const AddedItemTileInfoContainer = styled.div`
  padding-left: 108px;
`;

export const ProductName = styled.div`
  margin-bottom: 8px;
`;

export const VariationAttribute = styled.div`
  display: flex;
  margin-bottom: 4px;
`;

export const VariationLabel = styled.span``;

export const VariationValue = styled.span``;

export const AddedBagPanelTitle = styled.div`
  margin-bottom: 32px;
`;

export const BagPromoMessages = styled.div`
  margin-top: 4px;
`;

export const PriceContainer = styled.div`
  margin-top: ${({ removeMargin }) => (removeMargin ? 0 : "16px")};
`;

export const SpinnerContainer = styled.div`
  margin-top: 16px;
  padding-left: 108px;
  min-height: 34px;
  display: flex;
  align-items: center;
`;

export const PanelContent = styled.div`
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }

  ${CrossSellTileWrapper} {
    max-width: calc(480px - 64px);
  }
  ${QTYContainer} {
    min-height: unset;
  }
  ${QuantityButton} {
    width: 32px;
    height: 32px;
  }
  ${QuantityInput} {
    height: 32px;
  }
`;

export const RelatedProducts = styled.div`
  ${CTATextWrapper} {
    font-family: "BEATRICE";
    font-weight: normal;
    font-size: 15px;
    letter-spacing: 0.02em;
    color: #243953;
  }
`;

export const RelatedProductsTitle = styled.div``;

export const AddedBagPanelBottomContent = styled.div`
  margin-top: auto;
`;

export const SubtotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
  padding-top: 16px;
`;

export const SubtotalLabel = styled.div``;

export const Subtotal = styled.div``;

export const AddedBagPanelCTAContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ alignment }) => Horizontal[alignment]};
  gap: 10px;
  ${CallToAction} {
    width: ${({ fullWidth }) => (fullWidth ? "100%" : "50%")};
  }
  margin-top: ${({ hasMarginTop }) => (hasMarginTop ? "10px" : "0")};
`;
