import { useMemo, useCallback } from "react";
import useUser from "@lib/useUser";
import getCustomerType from "@utils/analytics/utils/getCustomerType";

export const useWishlistGa4 = ({ ga4ItemProps }) => {
  const { user } = useUser();

  const wishlistGa4User = useMemo(() => {
    return {
      customerData: {
        user_logged_in: user?.data?.isLoggedIn ? "Logged In" : "Not Logged In",
        user_type: user?.data?.userType || "guest",
        customer_type: getCustomerType(user)
      }
    };
  }, [user]);

  const wishlistGa4Item = useMemo(() => {
    return {
      ecommerce: {
        ...wishlistGa4User,
        favorites_item: {
          ...ga4ItemProps
        }
      }
    };
  }, [ga4ItemProps, wishlistGa4User]);

  const wishlistGa4ItemMoveToBag = useCallback(
    ({ viewType }) => {
      return {
        ecommerce: {
          ...wishlistGa4User,
          view_type: viewType,
          favorites_item: {
            ...ga4ItemProps
          }
        }
      };
    },
    [ga4ItemProps, wishlistGa4User]
  );

  return {
    wishlistGa4User,
    wishlistGa4Item,
    wishlistGa4ItemMoveToBag
  };
};
