import apolloClient from "@serenaandlily/gql/client";
import { buildGetSearchGetTypeaheadResults } from "../queries";

const fetchSearchGetTypeaheadResults = async ({
  searchPhrase,
  maxPromoMessages,
  searchParameters,
  userParameters,
  customerEmail,
  guestToken
}) => {
  if (!apolloClient) return;
  try {
    const GET_SEARCH_TYPEAHEAD_RESULTS = buildGetSearchGetTypeaheadResults();

    const response = await apolloClient.query({
      query: GET_SEARCH_TYPEAHEAD_RESULTS,
      variables: {
        searchPhrase,
        maxPromoMessages,
        searchParameters,
        userParameters,
        customerEmail,
        guestToken
      },
      context: { clientName: "endpoint2" }
    });

    return response;
  } catch (error) {
    return { data: null, errors: error };
  }
};

export default fetchSearchGetTypeaheadResults;