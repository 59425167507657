import { gql } from "@apollo/client";

const buildGetProductEddQuery = (variables) => {
  const productIdDef = variables?.productId ? "$productId: String" : "";
  const productId = variables?.productId ? "productId: $productId" : "";

  const customUpholsteryOptionsDef = variables?.customUpholsteryOptions
    ? "$customUpholsteryOptions: CustomUpholsteryOptions"
    : "";
  const customUpholsteryOptions = variables?.customUpholsteryOptions
    ? "customUpholsteryOptions: $customUpholsteryOptions"
    : "";
  return gql`
    query GetProductEdd(${productIdDef} ${customUpholsteryOptionsDef}) {
      getProductEdd(${productId} ${customUpholsteryOptions}) {
        productId
        prependMessage
        eddDate
        deliveryMethod
        deliveryServiceLevel
        availability
      }
    }
  `;
};

export default buildGetProductEddQuery;
