import useSWRImmutable from "swr/immutable";
import fetchInventoriesById from "../gql/utils/fetchInventoriesById";
import { CUSTOM_UPHOLSTERY } from "../constants/productTypes";
import { useMemo } from "react";

export async function inventoriesFetcher(
  masterProductId,
  isRetailExclusive = false
) {
  const data = await fetchInventoriesById(masterProductId);

  const inventories =
    data?.getInventories?.map((v) => {
      return {
        ...v,
        ...(v?.perpetual && { total: 999, available: 999, inStock: 999 }),
        ...(isRetailExclusive && {
          total: 999,
          available: 999,
          inStock: 999,
          perpetual: true
        })
      };
    }) || [];
  return { getInventories: inventories };
}
export function getInvetoriesQueryKey(id, isCustomUpholsteryProduct) {
  return !isCustomUpholsteryProduct ? `inventories-${id}` : null;
}

function checkProductSoldout(inventories) {
  return inventories?.filter((v) => v?.available > 0).length === 0;
}

export const useProductInventories = (
  masterProductId,
  productType,
  isRetailExclusive = false
) => {
  const isCustomUpholsteryProduct = CUSTOM_UPHOLSTERY.includes(productType);

  const { data, error } = useSWRImmutable(
    getInvetoriesQueryKey(masterProductId, isCustomUpholsteryProduct),
    () =>
      !isCustomUpholsteryProduct?.verified
        ? inventoriesFetcher(masterProductId, isRetailExclusive)
        : []
  );
  const isSoldOut = useMemo(
    () => checkProductSoldout(data?.getInventories),
    [data?.getInventories]
  );
  const getInventoryById = (productId) => {
    return data?.getInventories?.find?.(
      (inventory) => inventory?.productId === productId
    );
  };
  const getInventoryTotalAvailable = (productId) => {
    return (
      data?.getInventories?.find?.(
        (inventory) => inventory?.productId === productId
      )?.available || 0
    );
  };

  const availableInventories = useMemo(() => {
    return data?.getInventories?.filter(
      (inventory) => inventory?.available > 0
    );
  }, [data?.getInventories]);
  return {
    availableInventories,
    data: data?.getInventories || [],
    error,
    getInventoryById,
    getInventoryTotalAvailable,
    isSoldOut
  };
};
