/**
 *
 * @param {string} stlLink
 * @returns {string}
 */
const formatStlLink = (stlLink) => {
  if (typeof stlLink !== "string") return "";

  const slink = stlLink.split("shopthelook/");

  const formattedLink = slink.length > 1 ? slink[1] : "";

  return formattedLink;
};

export default formatStlLink;
