import apolloClient from "../client";
import buildGetProductPriceQuery from "../queries/buildProductPriceQuery";

const fetchProductPrice = async (variables) => {
  if (!apolloClient) return;

  const IDmatcher = variables?.productId?.match(/-/g)?.length || 0;

  const invalidID = IDmatcher < 2;

  if (invalidID) return; // ensure product id is not mpn

  const GET_PRODUCT_PRICE = buildGetProductPriceQuery(variables);

  const { data } = await apolloClient.query({
    query: GET_PRODUCT_PRICE,
    variables: {
      id: variables?.productId,
      ...variables
    },
    context: { clientName: "endpoint2" }
  });
  return data?.getPrice;
};

export default fetchProductPrice;
