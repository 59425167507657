import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  SearchLogoWrapper,
  Wrapper,
  IconsWrapper
} from "./GlobalHeaderIconsStyled";
import {
  ProfileIcon,
  BagIcon,
  LocationIcon,
  WishlistIcon
} from "./UtilityMenu";
import HeaderIcon from "../HeaderIcon";
import SearchLogo from "./UtilityMenu/SearchLogo";
import SearchClose from "./UtilityMenu/SearchClose";
import { useSearch } from "../../contexts/SearchContext";
import { useBreakpoint } from "../Breakpoints";
import { useWishlistEnabled } from "@serenaandlily/hooks/useWishlistEnabled";
import { useContent } from "@serenaandlily/contexts/ContentContext";

const GlobalHeaderIcons = ({
  type,
  headerIcons,
  textIcons,
  searchGlobalIcon,
  itemCount,
  flyoutType,
  theme,
  isOpen = false,
  setIsOpen,
  user
}) => {
  const initialState = {};
  const [allHeaderIcons, setAllHeaderIcons] = useState(initialState);
  const rightSide = "Right";
  const { positionInHeader, iconOrder, mobilePositionInHeader } =
    searchGlobalIcon || {};
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints["IPad"];
  const hasSearchIcon = isMobile
    ? type == mobilePositionInHeader
    : type == positionInHeader;
  const iconArray = `${type}Icons`;
  const { showSearchBar, setShowSearchBar } = useSearch();
  const { wishlistConfigs } = useContent();
  const { isWishlistEnabled, isUserLoading } =
    useWishlistEnabled(wishlistConfigs);

  useEffect(() => {
    (headerIcons || textIcons) && orderIcons();
  }, []);

  const orderIcons = () => {
    const allIcons =
      headerIcons && textIcons
        ? [...headerIcons, ...textIcons]
        : headerIcons
        ? headerIcons
        : textIcons;

    const sortedIcons = {
      LeftIcons: allIcons
        .filter((i) => i.positionInHeader == "Left")
        .sort((a, b) => a.iconOrder - b.iconOrder),
      RightIcons: allIcons
        .filter((i) => i.positionInHeader == rightSide)
        .sort((a, b) => a.iconOrder - b.iconOrder)
    };
    setAllHeaderIcons(sortedIcons);
  };

  const handleClick = () => {
    const body = document.querySelector("body");
    if (showSearchBar) {
      body.style.overflow = "auto";
    } else {
      body.style.overflow = "hidden";
    }
    if (isOpen) {
      setIsOpen(false);
      setShowSearchBar(true);
    } else {
      setShowSearchBar((prevValue) => !prevValue);
    }
  };

  const SearchIcon = () => (
    <SearchLogoWrapper onClick={() => handleClick()} aria-label="Open search">
      {showSearchBar && flyoutType === "Large" ? (
        <SearchClose />
      ) : (
        <SearchLogo theme={theme} {...searchGlobalIcon} />
      )}
    </SearchLogoWrapper>
  );

  if ((allHeaderIcons?.[iconArray] === [] || isMobile) && hasSearchIcon) {
    return (
      <IconsWrapper>
        <SearchIcon />
        {type == rightSide && <LocationIcon />}
        {type == rightSide && isWishlistEnabled && !isUserLoading && (
          <WishlistIcon user={user} />
        )}
        {type == rightSide && <ProfileIcon />}
        {type == rightSide && <BagIcon itemCount={itemCount} />}
      </IconsWrapper>
    );
  } else if (allHeaderIcons?.[iconArray] !== [] && hasSearchIcon) {
    const isFirst = iconOrder === 1;
    let searchPositionRendered = false;

    return (
      <IconsWrapper isRightSide={type === rightSide}>
        {isFirst && <SearchIcon />}

        {allHeaderIcons?.[iconArray]?.map((icon, idx) => {
          const showSearchAfter =
            iconOrder === icon.iconOrder + 1 && !searchPositionRendered;
          const showSearchBefore =
            iconOrder === icon.iconOrder - 1 && !searchPositionRendered;
          const key = `header-${iconArray}-icon-${idx}`;
          const wrapperKey = `header-${iconArray}-wrapper`;

          return (
            <Wrapper key={wrapperKey}>
              {showSearchBefore && <SearchIcon />}
              <HeaderIcon theme={theme} icon={icon} key={key} />
              {showSearchAfter && <SearchIcon />}
              {showSearchBefore || showSearchAfter
                ? (searchPositionRendered = true)
                : null}
            </Wrapper>
          );
        })}

        {type == rightSide && <LocationIcon />}
        {type == rightSide && isWishlistEnabled && !isUserLoading && (
          <WishlistIcon user={user} />
        )}
        {type == rightSide && <ProfileIcon />}
        {type == rightSide && <BagIcon itemCount={itemCount} />}
      </IconsWrapper>
    );
  } else {
    return (
      <IconsWrapper isRightSide={type == rightSide}>
        {allHeaderIcons?.[iconArray]?.map((icon, idx) => {
          const key = `header-${iconArray}-icon-${idx}`;
          return <HeaderIcon theme={theme} icon={icon} key={key} />;
        })}
        {type == rightSide && <LocationIcon />}
        {type == rightSide && isWishlistEnabled && !isUserLoading && (
          <WishlistIcon user={user} />
        )}
        {type == rightSide && <ProfileIcon />}
        {type == rightSide && <BagIcon itemCount={itemCount} />}
      </IconsWrapper>
    );
  }
};

export default GlobalHeaderIcons;

GlobalHeaderIcons.propTypes = {
  type: PropTypes.string,
  headerIcons: PropTypes.array,
  textIcons: PropTypes.array,
  searchGlobalIcon: PropTypes.object,
  itemCount: PropTypes.number,
  flyoutType: PropTypes.string,
  theme: PropTypes.object,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  user: PropTypes.object
};
