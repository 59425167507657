import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  CUFilterDropdownWrapper,
  CUFilterDropdownTitle,
  CUFilterDropdownIcon,
  CUFilterDropdownsWrapper,
  FilterDropdownContent,
  ColorFilterInputContainer,
  ColorFilterInput,
  ColorFilterCheckbox,
  ColorFilterCheckboxName,
  FilterColor,
  ColorDropdownContent
} from "./CUFilterDropdownsStyled";
import Text from "../Text";
import Icons from "../Icons";
import { colorOptions } from "../../constants/filterOptions";
import { CheckboxInput } from "../FormComponents";

const CUFilterDropdowns = ({
  filters,
  selectedFilters,
  setSelectedFilters,
  setSelectedColors,
  setSelectedDesigns,
  setSelectedFabrics
}) => {
  const [openDropdowns, setOpenDropdowns] = useState({
    color: false,
    design: false,
    fabric: false
  });

  useEffect(() => {
    const handleClickOutside = (e) => {
      const cuDropdowns = document.querySelector(".cu-dropdowns");

      if (cuDropdowns && !cuDropdowns.contains(e.target)) {
        if (e.target.nodeName === "svg" || e.target.nodeName === "path") return;

        setOpenDropdowns({
          color: false,
          design: false,
          fabric: false
        });
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => window.removeEventListener("click", handleClickOutside);
  }, []);

  const handleToggleDropdown = (filter) => {
    const newDropdowns = { ...openDropdowns };
    if (filter === "color") {
      newDropdowns["design"] = false;
      newDropdowns["fabric"] = false;
      newDropdowns["color"] = !newDropdowns["color"];
    } else if (filter === "design") {
      newDropdowns["color"] = false;
      newDropdowns["design"] = !newDropdowns["design"];
      newDropdowns["fabric"] = false;
    } else if (filter === "fabric") {
      newDropdowns["color"] = false;
      newDropdowns["design"] = false;
      newDropdowns["fabric"] = !newDropdowns["fabric"];
    }
    setOpenDropdowns(newDropdowns);
  };

  const colorCaret = openDropdowns["color"] ? "CaretUpLight" : "CaretDownLight";
  const designCaret = openDropdowns["design"]
    ? "CaretUpLight"
    : "CaretDownLight";
  const fabricCaret = openDropdowns["fabric"]
    ? "CaretUpLight"
    : "CaretDownLight";

  const handleSelectFilter = (filter, filterType) => {
    const filtersHash = {};
    for (const currFilter of selectedFilters[filterType])
      filtersHash[currFilter] = true;
    if (!filtersHash[filter]) {
      filtersHash[filter] = true;
    } else {
      filtersHash[filter] = false;
    }

    const filters = [];
    for (const color in filtersHash) {
      if (filtersHash[color]) filters.push(color);
    }

    if (filterType === "selectedColors") setSelectedColors(filtersHash);
    if (filterType === "selectedGrades") setSelectedDesigns(filtersHash);
    if (filterType === "selectedTypes") setSelectedFabrics(filtersHash);

    setSelectedFilters({
      ...selectedFilters,
      [filterType]: filters
    });
  };

  return (
    <CUFilterDropdownsWrapper className="cu-dropdowns">
      <CUFilterDropdownWrapper>
        <CUFilterDropdownTitle onClick={() => handleToggleDropdown("color")}>
          <Text copy="COLOR" fontFamily="BeatriceRegular" />
          <CUFilterDropdownIcon className="dropdown-icon">
            <Icons type={colorCaret} />
          </CUFilterDropdownIcon>
        </CUFilterDropdownTitle>
        {openDropdowns["color"] && (
          <ColorDropdownContent>
            <ColorFilterInputContainer>
              {filters?.colors.map((name, idx) => {
                const key = `color-filter-option-${idx}`;
                const hasColor = colorOptions.some(
                  (color) => color.name === name
                );
                const colorCode = colorOptions.filter(
                  (color) => color.name === name
                )[0]?.colorCode;

                const isSelected =
                  selectedFilters?.selectedColors.includes(name);

                if (hasColor) {
                  return (
                    <ColorFilterInput
                      key={key}
                      onClick={() => handleSelectFilter(name, "selectedColors")}
                    >
                      <ColorFilterCheckbox isSelected={isSelected}>
                        <FilterColor
                          isWhite={name.toLowerCase() === "white"}
                          color={colorCode}
                        />
                      </ColorFilterCheckbox>
                      {
                        // hack because red is called coral in the backend for some reason
                      }
                      <ColorFilterCheckboxName>
                        <Text copy={name === "Coral" ? "Red" : name} />
                      </ColorFilterCheckboxName>
                    </ColorFilterInput>
                  );
                }
              })}
            </ColorFilterInputContainer>
          </ColorDropdownContent>
        )}
      </CUFilterDropdownWrapper>

      <CUFilterDropdownWrapper>
        <CUFilterDropdownTitle onClick={() => handleToggleDropdown("design")}>
          <Text copy="DESIGN" fontFamily="BeatriceRegular" />
          <CUFilterDropdownIcon>
            <Icons type={designCaret} />
          </CUFilterDropdownIcon>
        </CUFilterDropdownTitle>
        {openDropdowns["design"] && (
          <FilterDropdownContent>
            {filters?.grades?.length &&
              filters?.grades.map((option, idx) => {
                const key = `design-filter-option-${idx}`;
                const isChecked =
                  selectedFilters?.selectedGrades.includes(option);
                return (
                  <CheckboxInput
                    key={key}
                    label={option}
                    name={option}
                    checked={isChecked}
                    handleChange={() =>
                      handleSelectFilter(option, "selectedGrades")
                    }
                  />
                );
              })}
          </FilterDropdownContent>
        )}
      </CUFilterDropdownWrapper>

      <CUFilterDropdownWrapper>
        <CUFilterDropdownTitle onClick={() => handleToggleDropdown("fabric")}>
          <Text copy="FABRIC" fontFamily="BeatriceRegular" />
          <CUFilterDropdownIcon>
            <Icons type={fabricCaret} />
          </CUFilterDropdownIcon>
        </CUFilterDropdownTitle>
        {openDropdowns["fabric"] && (
          <FilterDropdownContent>
            {filters?.types?.length &&
              filters?.types.map((option, idx) => {
                const key = `fabric-filter-option-${idx}`;
                const isChecked =
                  selectedFilters?.selectedTypes.includes(option);
                return (
                  <CheckboxInput
                    key={key}
                    label={option}
                    name={option}
                    checked={isChecked}
                    handleChange={() =>
                      handleSelectFilter(option, "selectedTypes")
                    }
                  />
                );
              })}
          </FilterDropdownContent>
        )}
      </CUFilterDropdownWrapper>
    </CUFilterDropdownsWrapper>
  );
};

export default CUFilterDropdowns;

CUFilterDropdowns.propTypes = {
  filters: PropTypes.array,
  selectedFilters: PropTypes.func,
  setSelectedFilters: PropTypes.func,
  setSelectedColors: PropTypes.func,
  setSelectedDesigns: PropTypes.func,
  setSelectedFabrics: PropTypes.func
};
