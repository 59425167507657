import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTrackGA4ViewItemList } from "@serenaandlily/hooks/analytics/useGA4";
import {
  LargeFlyoutContainer,
  AllResultsContainer,
  AllResultsWrapper,
  ProductResultsWrapper,
  PopularSearchWrapper,
  ResultItem,
  QueryText,
  QueryWrapper,
  Query,
  PaddingWrapper,
  ProductsHeadingWrapper,
  Heading,
  DividerContainer,
  DividerInner,
  Divider,
  CTAWrapper
} from "./LargeFlyoutStyled";
import ProductTile from "../../ProductTile";
import CTA from "../../CTA";
import { useBreakpoint } from "../../Breakpoints";
import { useSearch } from "../../../contexts/SearchContext";
import capitalizeAllWords from "../../../utils/capitalizeAllWords";

const LargeFlyout = ({ popularQueries, searchBarValue, tiles }) => {
  const flyoutRef = useRef(null);
  const [flyoutOffset, setFlyoutOffset] = useState(500);
  const hasPopQueries = popularQueries?.length > 0;
  const popQueriesList =
    popularQueries?.length > 5 ? popularQueries?.slice(0, 5) : popularQueries;
  const viewAllLink = `/search?q=${searchBarValue}`;
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints["ScreenWidth"];
  const isSmallMobile = breakpoints["DesktopMobile"];
  const { activeSearch } = useSearch();
  const productsCount = activeSearch?.total_num_results;
  const maxNumItems = isSmallMobile ? 2 : 3;
  const items =
    tiles.length > maxNumItems ? tiles.slice(0, maxNumItems) : tiles;

  useEffect(() => {
    const getFlyoutOffset = () => {
      setFlyoutOffset(flyoutRef?.current?.getBoundingClientRect?.()?.top);
    };
    getFlyoutOffset();
    window.addEventListener("resize", getFlyoutOffset);
    window.addEventListener("scroll", getFlyoutOffset);
    return () => {
      window.removeEventListener("resize", getFlyoutOffset);
      window.removeEventListener("scroll", getFlyoutOffset);
    };
  }, [setFlyoutOffset, tiles]);

  useTrackGA4ViewItemList(items, {
    itemListName: "Search Flyout Results",
    itemListId: "search_flyout_results"
  });

  const tileConfigs = {
    productTitleConfigs: {
      fontColor: "#243953",
      fontSize: 18,
      fontFamily: "Beatrice Light"
    },
    priceConfigs: {
      fontColor: "#667487",
      fontSize: 12,
      fontSizeTablet: 14,
      fontSizeDesktop: 16,
      fontFamily: "Beatrice Light"
    }
  };

  const ctaInfo = {
    ctaText: "VIEW ALL",
    url: viewAllLink,
    ctaStyle: "Secondary Button",
    ctaAlignment: "Center"
  };

  const squareImageConfigs = {
    squareUrlSize: "&w=290&h=290",
    squareSizeObj: {
      width: 290,
      height: 290
    }
  };

  const boldString = (str, substr) =>
    str.replace(RegExp(substr, "g"), `<b>${substr}</b>`);

  return (
    <LargeFlyoutContainer ref={flyoutRef} flyoutOffset={flyoutOffset}>
      <PaddingWrapper>
        {hasPopQueries && (
          <PopularSearchWrapper>
            {!isMobile && <QueryText>Suggestions</QueryText>}
            {popQueriesList.map((query, idx) => {
              const { value } = query;
              const capitalizedValue = capitalizeAllWords(value);
              const capitalizedSearchTerm = capitalizeAllWords(searchBarValue);
              const boldValue = boldString(
                capitalizedValue,
                capitalizedSearchTerm
              );
              return (
                <QueryWrapper key={`pop-query-${idx}`}>
                  <Query
                    href={`/search?q=${value}`}
                    dangerouslySetInnerHTML={{ __html: boldValue }}
                  />
                </QueryWrapper>
              );
            })}
          </PopularSearchWrapper>
        )}
        <AllResultsContainer>
          <AllResultsWrapper>
            {!isSmallMobile && (
              <ProductsHeadingWrapper>
                <Heading>
                  PRODUCTS <sup>{productsCount}</sup>
                </Heading>
              </ProductsHeadingWrapper>
            )}
            {!isSmallMobile && (
              <DividerContainer>
                <DividerInner />
                <Divider />
              </DividerContainer>
            )}
            <ProductResultsWrapper>
              {items?.map((item, idx) => {
                const key = `result-tile-item-${idx}`;
                return (
                  <ResultItem key={key}>
                    <ProductTile
                      {...item}
                      hideCta={true}
                      removeDecimalsIfZero
                      largeFlyout={true}
                      source={"search_flyout"}
                      squareImageConfigs={squareImageConfigs}
                      tileConfigs={tileConfigs}
                      position={idx}
                    />
                  </ResultItem>
                );
              })}
            </ProductResultsWrapper>
            <CTAWrapper>
              <CTA themeName={ctaInfo?.ctaStyle} cta={ctaInfo} />
            </CTAWrapper>
          </AllResultsWrapper>
        </AllResultsContainer>
      </PaddingWrapper>
    </LargeFlyoutContainer>
  );
};

export default LargeFlyout;

LargeFlyout.propTypes = {
  popularQueries: PropTypes.array,
  searchBarValue: PropTypes.string,
  tiles: PropTypes.array
};
