import React, { useMemo } from "react";
import { CtaContainer } from "./StlCtaStyled";
import CTA from "@serenaandlily/components/CTA";
import PropTypes from "prop-types";
import useComponentTheme from "../Theme/useComponentTheme";
import defaultTheme from "./StlCtaTheme";
import formatStlLink from "@serenaandlily/utils/shopthelook/formatStlLink";

export default function StlCta({ theme = defaultTheme, stlLink }) {
  const imageZoomTheme = useComponentTheme({ theme });
  const slink = useMemo(() => formatStlLink(stlLink), [stlLink]);
  return (
    <CtaContainer>
      <CTA
        cta={{
          ctaText: "SHOP THE LOOK",
          url: `/${slink}`
        }}
        {...imageZoomTheme?.shopTheLookCta}
      />
    </CtaContainer>
  );
}
StlCta.propTypes = {
  theme: PropTypes.object,
  stlLink: PropTypes.string
};
