import React from "react";
import PropTypes from "prop-types";
import { SearchLogoContainer, SearchLogoText } from "./UtilityMenuStyled";
import Text from "../../Text";

const SearchLogo = ({
  theme,
  textPosition,
  fontFamily,
  fontColor,
  fontSize,
  mobileFontSize
}) => {
  const searchTextProps = {
    fontFamily: fontFamily,
    fontColor: fontColor,
    fontSize: mobileFontSize,
    fontSizeTablet: mobileFontSize,
    fontSizeDesktop: fontSize
  };

  return (
    <SearchLogoContainer id="header-search" textPosition={textPosition}>
      <SearchLogoText>
        <Text
          copy="Search"
          themeName={theme?.searchLogoText?.themeName}
          {...searchTextProps}
        />
      </SearchLogoText>
      <svg
        width="22"
        height="22"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Search icon</title>
        <path
          d="M15 15L21 21M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
          stroke="#243953"
          strokeLinecap="round"
        />
      </svg>
    </SearchLogoContainer>
  );
};

export default SearchLogo;

SearchLogo.propTypes = {
  theme: PropTypes.object,
  textPosition: PropTypes.string,
  fontFamily: PropTypes.string,
  fontColor: PropTypes.string,
  fontSize: PropTypes.number,
  mobileFontSize: PropTypes.number
};
