import styled from "styled-components";
import {
  Colors,
  FontSizes,
  Typography,
  LetterSpacing,
  Breakpoints
} from "../../../constants/constants";

export const SmallFlyoutContainer = styled.div`
  max-width: 1024px;
  width: 100%;
  background-color: ${Colors.White};
  box-shadow: 0 0 1px ${Colors["Dark Primary"]};
  border: 1px solid ${Colors["Dark Primary"]};
  border-top: none;
  font-weight: 400;
  position: absolute;
  z-index: 3000;
  left: calc(((100% - 1024px) / 2) + 5px);
  @media screen and (max-width: ${Breakpoints.ScreenWidth}) {
    max-width: fit-content;
    left: 15px;
    max-width: calc(100% - 30px);
  }
`;

export const PaddingWrapper = styled.div`
  padding: 1.2em 2em 2em;
`;

export const AllResultsWrapper = styled.div``;

export const SearchFlyoutText = styled.span`
  text-transform: uppercase;
  color: ${Colors["Dark Primary"]};
  font-family: ${Typography.ProximaNova};
  font-size: ${FontSizes.Base};
`;

export const QueryText = styled.span`
  text-transform: uppercase;
  color: ${Colors["Dark Primary"]};
  font-family: ${Typography.ProximaNova};
  font-size: ${FontSizes.Small};
  letter-spacing: ${LetterSpacing.XSmall};
`;

export const SearchTermLink = styled.a`
  color: ${Colors["Accent Color"]};
  word-break: break-word;
`;

export const ProductResultsWrapper = styled.div`
  display: flex;
  margin: 2em 0;
`;

export const PopularSearchWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const QueryWrapper = styled.div`
  height: min-content;
  display: flex;
`;

export const Query = styled.a`
  color: ${Colors["Accent Color"]};
  font-family: ${Typography.ProximaNova};
  font-size: ${FontSizes.Base};
  cursor: pointer;
  text-transform: uppercase;
  &:hover {
    text-decoration: underline;
  }
`;

export const Separator = styled.span`
  color: ${Colors["Dark Primary"]};
  font-size: ${FontSizes.Base};
`;

export const ResultItem = styled.div`
  margin-right: 2em;
  width: fit-content;
`;
