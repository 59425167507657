import { css } from "styled-components";

const getTransition = (transition = "0.3s") =>
  css`
    transition: ${transition};
    -o-transition: ${transition};
    -webkit-transition: ${transition};
  `;

export default getTransition;
