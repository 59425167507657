import { TypographyWrapper } from "../../Typography/TypographyStyled";
import styled from "styled-components";
import { Breakpoints } from "../../../constants/constants";

export const DefaultFlyoutContainer = styled.div`
  display: flex;
  width: 100%;
  @media screen and (max-width: ${Breakpoints.ScreenWidth}) {
    flex-direction: column;
  }
`;
export const TopLeftSection = styled.div`
  width: 100%;
  @media screen and (max-width: ${Breakpoints.ScreenWidth}) {
    margin-bottom: 40px;
  }
`;
export const BottomRightSection = styled.div``;
export const SearchSuggestions = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  width: 100%;
  ${TypographyWrapper} {
    margin-right: 10px;
  }
  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;
  }
`;
export const SuggestionsWrapper = styled.div`
  height: 38px;
  padding: 1px 0;
  display: flex;
  & > span,
  a {
    margin: 0 3px;
  }
  margin: 0 5px;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    & > span,
    a {
      margin: 0 10px 0 0;
    }
    margin: 16px 0 0;
  }
`;
export const SuggestionTextWrapper = styled.div`
  margin-bottom: 32px;
`;
export const ImageLinksSection = styled.div`
  width: 100%;
`;
export const ImageLinksWrapper = styled.div`
  display: flex;
  width: 100%;
`;
export const InspirationTileWrapper = styled.div`
  &:not(:last-child) {
    margin-right: 40px;
    @media screen and (max-width: ${Breakpoints.ScreenWidth}) {
      margin-right: 16px;
    }
  }
  width: 100%;
  max-width: 290px;
`;
