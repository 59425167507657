import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo
} from "react";
import PropTypes from "prop-types";
import useEmblaCarousel from "embla-carousel-react";
import AutoHeight from "embla-carousel-auto-height";
import { useRouter } from "next/router";
import Autoplay from "embla-carousel-autoplay";
import GenericCarouselContent from "./GenericCarouselContent";
import { PrevButton, NextButton, DotButton } from "./GenericCarouselButtons";
import {
  EmblaCarousel,
  EmblaViewport,
  EmblaContainer,
  EmblaDotNavContainer,
  EmblaSlide,
  EmblaSlideInner,
  ProgressBarContainer
} from "./GenericCarouselStyled";
import ScrollerProgress from "../ScrollerProgress/ScrollerProgress";
import GetViewportColumn from "../ModularColumn/utils/GetViewportColumn";
import getColumnWidth from "../ModularColumn/utils/getColumnWidth";

const oneSecond = 1000;
const fiveSeconds = 5000;

const EmblaSlideComponent = React.memo(
  ({
    slide,
    idx,
    selectedIndex,
    slideAnimation,
    height,
    width,
    id,
    list,
    contentThemeName,
    isMobile,
    isTablet
  }) => {
    const { column, columnTablet, columnMobile } = slide || {};
    const slideWidth =
      column && GetViewportColumn(column, columnTablet, columnMobile);
    const widthPercentage = slideWidth && getColumnWidth(slideWidth);
    const slideContent = widthPercentage ? slide?.content : slide;

    return (
      <EmblaSlide
        isFirstItem={idx === 0}
        width={width}
        widthPercentage={widthPercentage}
        className={
          idx === selectedIndex && slideAnimation === "Fade"
            ? "embla-slide-fade-active"
            : ""
        }
      >
        <EmblaSlideInner height={height}>
          <GenericCarouselContent
            {...slideContent}
            removeDecimalsIfZero
            source={id}
            list={list}
            themeName={contentThemeName}
            position={idx}
            isMobile={isMobile}
            isTablet={isTablet}
          />
        </EmblaSlideInner>
      </EmblaSlide>
    );
  }
);

EmblaSlideComponent.displayName = "EmblaSlideComponent";

const GenericCarousel = ({
  carouselContent,
  carouselConfigs,
  id,
  list,
  contentThemeName,
  isMobile,
  isTablet
}) => {
  const {
    loop,
    autoplay,
    arrows,
    height,
    align,
    width,
    hideLeftArrow,
    dots,
    slideAnimation,
    interval,
    useProgressBar,
    arrowType = "carrot"
  } = carouselConfigs;

  const [isDraggable, setIsDraggable] = useState(false);
  const router = useRouter();

  const options = useMemo(
    () => ({
      loop: loop,
      align: align || "center",
      containScroll: "trimSnaps",
      draggable: isDraggable
    }),
    [loop, align, isDraggable]
  );

  const autoheightRef = useRef(AutoHeight());

  const autoplayPlugin = useMemo(
    () =>
      Autoplay(
        {
          delay: interval ? interval * oneSecond : fiveSeconds,
          stopOnInteraction: true,
          stopOnLastSnap: !loop
        },
        (emblaRoot) => emblaRoot.parentElement
      ),
    [interval, loop]
  );
  const autoplayRef = useRef(autoplayPlugin);

  const emblaArgs = useMemo(() => {
    const args = [options];
    if (autoplay) {
      args.push([autoplayRef.current, autoheightRef.current]);
    }
    return args;
  }, [options, autoplay]);

  const [viewportRef, embla] = useEmblaCarousel(...emblaArgs);

  useEffect(() => {
    if (!embla) return;
    const obscuredSlides = embla.slidesNotInView(true);
    setIsDraggable(obscuredSlides.length > 0);
  }, [embla]);

  useEffect(() => {
    const handleRouteChange = () => {
      if (embla) {
        embla.scrollTo(0);
      }
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events, embla]);

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);
  const [scrollProgress, setScrollProgress] = useState(0);

  const scrollPrev = useCallback(() => embla?.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla?.scrollNext(), [embla]);
  const scrollTo = useCallback((index) => embla?.scrollTo(index), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  const onScroll = useCallback(() => {
    if (!embla) return;
    const progress = Math.max(0, Math.min(1, embla.scrollProgress()));
    setScrollProgress(progress);
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    setScrollSnaps(embla.scrollSnapList());
    embla.on("select", onSelect);
    embla.on("scroll", onScroll);
    onSelect();
    onScroll();

    return () => {
      embla.off("select", onSelect);
      embla.off("scroll", onScroll);
    };
  }, [embla, onSelect, onScroll]);

  const stopAutoplay = useCallback(() => {
    autoplayRef.current.stop();
  }, []);

  useEffect(() => {
    if (interval === 0) {
      stopAutoplay();
    }
  }, [interval, stopAutoplay]);

  const slides = useMemo(
    () =>
      carouselContent.map((slide, idx) => (
        <EmblaSlideComponent
          key={idx}
          slide={slide}
          idx={idx}
          selectedIndex={selectedIndex}
          slideAnimation={slideAnimation}
          height={height}
          width={width}
          id={id}
          list={list}
          contentThemeName={contentThemeName}
          isMobile={isMobile}
          isTablet={isTablet}
        />
      )),
    [
      carouselContent,
      selectedIndex,
      slideAnimation,
      height,
      width,
      id,
      list,
      contentThemeName,
      isTablet,
      isMobile
    ]
  );

  return (
    <EmblaCarousel tabIndex="0" onFocus={stopAutoplay}>
      <EmblaViewport ref={viewportRef}>
        <EmblaContainer>{slides}</EmblaContainer>
      </EmblaViewport>
      {arrows && (
        <>
          {!hideLeftArrow && (
            <PrevButton
              tabIndex="0"
              onClick={scrollPrev}
              enabled={prevBtnEnabled}
              arrowType={arrowType}
            />
          )}
          <NextButton
            tabIndex="0"
            onClick={scrollNext}
            enabled={nextBtnEnabled}
            arrowType={arrowType}
          />
        </>
      )}
      {dots && (
        <EmblaDotNavContainer>
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              selected={index === selectedIndex}
              onClick={() => scrollTo(index)}
            />
          ))}
        </EmblaDotNavContainer>
      )}
      {(isMobile || isTablet || useProgressBar) && (
        <ProgressBarContainer position="Left">
          <ScrollerProgress
            carouselProgress={scrollProgress}
            isTablet={isTablet}
          />
        </ProgressBarContainer>
      )}
    </EmblaCarousel>
  );
};

GenericCarousel.propTypes = {
  carouselContent: PropTypes.array,
  list: PropTypes.string,
  carouselConfigs: PropTypes.shape({
    height: PropTypes.number,
    arrows: PropTypes.bool,
    dots: PropTypes.bool,
    autoplay: PropTypes.bool,
    loop: PropTypes.bool,
    align: PropTypes.string,
    width: PropTypes.string,
    hideLeftArrow: PropTypes.bool,
    slideAnimation: PropTypes.string,
    interval: PropTypes.number,
    arrowType: PropTypes.string,
    useProgressBar: PropTypes.bool
  }),
  contentThemeName: PropTypes.string,
  id: PropTypes.string,
  isMobile: PropTypes.bool,
  isTablet: PropTypes.bool
};

export default React.memo(GenericCarousel);
