import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  CUFiltersWrapper,
  FilterTitle,
  FilterTitleSection,
  CloseButton,
  NumberOfFabrics,
  AppliedFilters,
  ClearAll,
  CUFilterPanel,
  CUFilterPanelContent,
  ColorFilters,
  ColorFiltersTitle,
  FilterPanelTitle,
  DesignFilters,
  DesignFiltersTitle,
  FabricFilters,
  FabricFiltersTitle,
  FilterInputs,
  FilterApplyContainer,
  FilterInputsOverflow,
  AppliedDesktopFilters,
  DesktopFilters
} from "./CUFiltersStyled";
import CTA from "../CTA";
import Icons from "../Icons";
import Text from "../Text";
import { useSidePanelContext } from "../../contexts/SidePanelContext";
import { colorOptions } from "../../constants/filterOptions";
import { CheckboxInput } from "../FormComponents";
import { useBreakpoint } from "../Breakpoints";
import { useProductContext } from "../../contexts/ProductContext";
import getInitialFilters from "./utils/getInitialFilters";
import CUFilterCTACarousel from "../CUFilterCTACarousel";
import CUFilterCTA from "../CUFilterCTA";
import {
  ColorFilterInputContainer,
  ColorFilterInput,
  ColorFilterCheckbox,
  ColorFilterCheckboxName,
  FilterColor
} from "../CUFilterDropdowns/CUFilterDropdownsStyled";
import CUFilterDropdowns from "../CUFilterDropdowns";
import defaultTheme from "./CuFiltersTheme";
import useComponentTheme from "../Theme/useComponentTheme";
import { FilterShowHideOptions } from "../FilterBy/FilterShowHideOptions";
import { executeOnEnterKey } from "@utils/keyboard/executeOnEnterKey";

const CUFilters = ({
  filters,
  headerOffset,
  totalFabricAmt,
  filteredFabricAmt,
  showDesktopFilters = true,
  showClearAllAtTheEnd = false,
  theme = defaultTheme
}) => {
  const filterTheme = useComponentTheme({ theme });
  const { setIsFilterOpen, isFilterOpen } = useSidePanelContext();
  const [isAnimating, setIsAnimating] = useState(false);
  const { selectedFilters, setSelectedFilters } = useProductContext();
  const breakpoints = useBreakpoint();
  const isDesktopMobile = breakpoints["DesktopMobile"];
  const isMobile = breakpoints["ScreenWidth"];

  const colors = filters?.colors.filter((color) =>
    colorOptions.some((co) => co.name === color)
  );

  const initialSelectedColors = getInitialFilters(
    selectedFilters,
    "colors",
    filters
  );

  const initialSelectedDesigns = getInitialFilters(
    selectedFilters,
    "grades",
    filters
  );

  const initialSelectedFabrics = getInitialFilters(
    selectedFilters,
    "types",
    filters
  );

  const [selectedColors, setSelectedColors] = useState(initialSelectedColors);
  const [selectedDesigns, setSelectedDesigns] = useState(
    initialSelectedDesigns
  );
  const [selectedFabrics, setSelectedFabrics] = useState(
    initialSelectedFabrics
  );

  useEffect(() => {
    if (!isDesktopMobile) {
      handleCloseFilter();
      setSelectedColors(initialSelectedColors);
    }
  }, [isDesktopMobile]);

  useEffect(() => {
    handleApplyFilters();
  }, [selectedColors, selectedDesigns, selectedFabrics]);

  const handleOpenFilter = (e) => {
    e.preventDefault();
    setIsFilterOpen(true);
    setTimeout(() => {
      setIsAnimating(true);
    }, 10);
  };

  const handleCloseFilter = () => {
    setIsAnimating(false);
    setTimeout(() => {
      setIsFilterOpen(false);
    }, 300);
  };

  const filterType = {
    color: "selectedColors",
    grade: "selectedGrades",
    type: "selectedTypes"
  };

  const handleSelectColor = (color) => {
    setSelectedColors({ ...selectedColors, [color]: !selectedColors[color] });
  };

  const handleSelectDesign = (design) => {
    setSelectedDesigns({
      ...selectedDesigns,
      [design]: !selectedDesigns[design]
    });
  };

  const handleSelectFabric = (fabric) => {
    setSelectedFabrics({
      ...selectedFabrics,
      [fabric]: !selectedFabrics[fabric]
    });
  };

  const handleApplyFilters = (e) => {
    e?.preventDefault();

    const colors = Object.keys(selectedColors).filter(
      (color) => selectedColors[color] === true
    );

    const designs = Object.keys(selectedDesigns).filter(
      (design) => selectedDesigns[design] === true
    );

    const fabrics = Object.keys(selectedFabrics).filter(
      (fabric) => selectedFabrics[fabric] === true
    );

    setSelectedFilters({
      ...selectedFilters,
      [filterType.color]: colors,
      [filterType.grade]: designs,
      [filterType.type]: fabrics
    });
    if (e) handleCloseFilter();
  };

  const handleClearFilters = () => {
    setSelectedFilters({
      ...selectedFilters,
      [filterType.color]: [],
      [filterType.grade]: [],
      [filterType.type]: []
    });
    const unfilteredColors = {};
    Object.keys(selectedColors).forEach(
      (color) => (unfilteredColors[color] = false)
    );
    const unfilteredDesigns = {};
    Object.keys(selectedDesigns).forEach(
      (design) => (unfilteredDesigns[design] = false)
    );
    const unfilteredFabrics = {};
    Object.keys(selectedFabrics).forEach(
      (fabric) => (unfilteredFabrics[fabric] = false)
    );
    setSelectedColors(unfilteredColors);
    setSelectedDesigns(unfilteredDesigns);
    setSelectedFabrics(unfilteredFabrics);
  };

  const totalFilters =
    selectedFilters.selectedColors.length +
    selectedFilters.selectedGrades.length +
    selectedFilters.selectedTypes.length;

  const numberOfFabrics = totalFilters
    ? `${filteredFabricAmt}/${totalFabricAmt}`
    : "";

  const filterCarouselData = [];
  for (const color of Object.keys(selectedColors)) {
    const filter = {};
    if (selectedColors[color]) {
      filter["_meta"] = {
        schema:
          "https://raw.githubusercontent.com/newelevation/sl-ui-toolkit/main/packages/sl-ui/src/components/CUFilterCTA/schema/CUFilterCTA.json"
      };
      filter["filterName"] = color;
      filter["filterType"] = "colors";
      filterCarouselData.push(filter);
    }
  }

  for (const design of Object.keys(selectedDesigns)) {
    const filter = {};
    if (selectedDesigns[design]) {
      filter["_meta"] = {
        schema:
          "https://raw.githubusercontent.com/newelevation/sl-ui-toolkit/main/packages/sl-ui/src/components/CUFilterCTA/schema/CUFilterCTA.json"
      };
      filter["filterName"] = design;
      filter["filterType"] = "grades";
      filterCarouselData.push(filter);
    }
  }

  for (const fabric of Object.keys(selectedFabrics)) {
    const filter = {};
    if (selectedFabrics[fabric]) {
      filter["_meta"] = {
        schema:
          "https://raw.githubusercontent.com/newelevation/sl-ui-toolkit/main/packages/sl-ui/src/components/CUFilterCTA/schema/CUFilterCTA.json"
      };
      filter["filterName"] = fabric;
      filter["filterType"] = "types";
      filterCarouselData.push(filter);
    }
  }

  const initialVisibleCount = isMobile ? 8 : 12;

  const [visibleColors, setVisibleColors] = useState(initialVisibleCount);
  const [visibleDesigns, setVisibleDesigns] = useState(initialVisibleCount);
  const [visibleFabrics, setVisibleFabrics] = useState(initialVisibleCount);

  const [expandedColors, setExpandedColors] = useState(false);
  const [expandedDesigns, setExpandedDesigns] = useState(false);
  const [expandedFabrics, setExpandedFabrics] = useState(false);

  const toggleShowMoreColors = () => {
    setExpandedColors(!expandedColors);
    setVisibleColors(expandedColors ? initialVisibleCount : colors?.length);
  };

  const toggleShowMoreDesigns = () => {
    setExpandedDesigns(!expandedDesigns);
    setVisibleDesigns(
      expandedDesigns ? initialVisibleCount : filters?.grades?.length
    );
  };

  const toggleShowMoreFabrics = () => {
    setExpandedFabrics(!expandedFabrics);
    setVisibleFabrics(
      expandedFabrics ? initialVisibleCount : filters?.types?.length
    );
  };

  return (
    <CUFiltersWrapper>
      <FilterTitleSection>
        <FilterTitle
          showDesktopFilters={showDesktopFilters}
          onClick={handleOpenFilter}
        >
          <CTA
            cta={{ ctaText: "FILTERS" }}
            themeName="CTA Midnight"
            fontColor="#243953"
            fontFamily="Beatrice"
          />
          <Icons type="Customize" notification={totalFilters} />
        </FilterTitle>
        <DesktopFilters showDesktopFilters={showDesktopFilters}>
          <CUFilterDropdowns
            filters={filters}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            setSelectedColors={setSelectedColors}
            setSelectedDesigns={setSelectedDesigns}
            setSelectedFabrics={setSelectedFabrics}
          />
        </DesktopFilters>
        {filteredFabricAmt > 0 && (
          <NumberOfFabrics>
            <Text copy={numberOfFabrics} fontColor="#667487" />
          </NumberOfFabrics>
        )}
      </FilterTitleSection>
      {totalFilters !== 0 && (
        <AppliedFilters>
          <AppliedDesktopFilters>
            {!showClearAllAtTheEnd && (
              <ClearAll onClick={handleClearFilters}>
                <Text copy="Clear All" themeName="textSmall" />
              </ClearAll>
            )}
            {filterCarouselData.map((filter, idx) => {
              return (
                <CUFilterCTA
                  {...filter}
                  key={`cu-filters-${idx}`}
                  selectedColors={selectedColors}
                  setSelectedColors={setSelectedColors}
                  selectedDesigns={selectedDesigns}
                  setSelectedDesigns={setSelectedDesigns}
                  selectedFabrics={selectedFabrics}
                  setSelectedFabrics={setSelectedFabrics}
                  setSelectedFilters={setSelectedFilters}
                  selectedFilters={selectedFilters}
                />
              );
            })}
            {showClearAllAtTheEnd && (
              <ClearAll onClick={handleClearFilters}>
                <Text copy="Clear All" themeName="textSmall" />
              </ClearAll>
            )}
          </AppliedDesktopFilters>
          <CUFilterCTACarousel
            filters={filterCarouselData}
            selectedColors={selectedColors}
            setSelectedColors={setSelectedColors}
            selectedDesigns={selectedDesigns}
            setSelectedDesigns={setSelectedDesigns}
            selectedFabrics={selectedFabrics}
            setSelectedFabrics={setSelectedFabrics}
            setSelectedFilters={setSelectedFilters}
            selectedFilters={selectedFilters}
          />
        </AppliedFilters>
      )}
      {isFilterOpen && (
        <CUFilterPanel className={isAnimating ? "slide-in" : "slide-out"}>
          <CUFilterPanelContent headerOffset={headerOffset}>
            <CloseButton
              onClick={handleCloseFilter}
              aria-label="Close custom filters panel"
            >
              <Icons type="Close" />
            </CloseButton>
            <FilterPanelTitle>
              <Text
                copy="Filters"
                themeName={filterTheme?.filtersTitle?.themeName}
                {...filterTheme?.filtersTitle}
              />
            </FilterPanelTitle>

            <FilterInputs>
              <FilterInputsOverflow>
                <ColorFilters>
                  <ColorFiltersTitle>
                    <Text
                      copy="Color"
                      themeName="headline5"
                      fontFamily="Beatrice"
                      fontWeight="bold"
                      fontSize={14}
                    />
                  </ColorFiltersTitle>
                  <ColorFilterInputContainer>
                    {colors.slice(0, visibleColors).map((name, idx) => {
                      const key = `color-filter-option-${idx}`;
                      const colorOption = colorOptions.find(
                        (color) => color.name === name
                      );
                      const isSelected = selectedColors[name];

                      if (colorOption) {
                        return (
                          <ColorFilterInput
                            key={key}
                            onClick={() => handleSelectColor(name)}
                            onKeyDown={(e) =>
                              executeOnEnterKey(e, () =>
                                handleSelectColor(name)
                              )
                            }
                            tabIndex={0}
                          >
                            <ColorFilterCheckbox isSelected={isSelected}>
                              <FilterColor
                                isWhite={name.toLowerCase() === "white"}
                                color={colorOption.colorCode}
                              />
                            </ColorFilterCheckbox>
                            <ColorFilterCheckboxName>
                              <Text copy={name} />
                            </ColorFilterCheckboxName>
                          </ColorFilterInput>
                        );
                      }
                      return null;
                    })}
                  </ColorFilterInputContainer>
                  <FilterShowHideOptions
                    isShowMoreVisible={
                      colors.length > initialVisibleCount && !expandedColors
                    }
                    isShowLessVisible={expandedColors}
                    onClickShowMore={toggleShowMoreColors}
                    onClickShowLess={toggleShowMoreColors}
                    showOptionsTheme={filterTheme.showOptions}
                  />
                </ColorFilters>
                <DesignFilters>
                  <DesignFiltersTitle>
                    <Text
                      copy="Design"
                      themeName="headline5"
                      fontFamily="Beatrice"
                      fontWeight="bold"
                      fontSize={14}
                    />
                  </DesignFiltersTitle>
                  <ColorFilterInputContainer>
                    {filters?.grades
                      .slice(0, visibleDesigns)
                      .map((option, idx) => (
                        <CheckboxInput
                          key={`design-filter-option-${idx}`}
                          label={option}
                          name={option}
                          defaultChecked={selectedDesigns[option]}
                          handleChange={() => handleSelectDesign(option)}
                        />
                      ))}
                  </ColorFilterInputContainer>
                  <FilterShowHideOptions
                    isShowMoreVisible={
                      filters?.grades.length > initialVisibleCount &&
                      !expandedDesigns
                    }
                    isShowLessVisible={expandedDesigns}
                    onClickShowMore={toggleShowMoreDesigns}
                    onClickShowLess={toggleShowMoreDesigns}
                    showOptionsTheme={filterTheme.showOptions}
                  />
                </DesignFilters>
                <FabricFilters>
                  <FabricFiltersTitle>
                    <Text
                      copy="Fabric"
                      themeName="headline5"
                      fontFamily="Beatrice"
                      fontWeight="bold"
                      fontSize={14}
                    />
                  </FabricFiltersTitle>
                  <ColorFilterInputContainer>
                    {filters?.types
                      .slice(0, visibleFabrics)
                      .map((option, idx) => (
                        <CheckboxInput
                          key={`fabric-filter-option-${idx}`}
                          label={option}
                          name={option}
                          defaultChecked={selectedFabrics[option]}
                          handleChange={() => handleSelectFabric(option)}
                        />
                      ))}
                  </ColorFilterInputContainer>
                  <FilterShowHideOptions
                    isShowMoreVisible={
                      filters?.types.length > initialVisibleCount &&
                      !expandedFabrics
                    }
                    isShowLessVisible={expandedFabrics}
                    onClickShowMore={toggleShowMoreFabrics}
                    onClickShowLess={toggleShowMoreFabrics}
                    showOptionsTheme={filterTheme.showOptions}
                  />
                </FabricFilters>
              </FilterInputsOverflow>
            </FilterInputs>

            <FilterApplyContainer>
              <CTA
                cta={{
                  ctaText: "APPLY FILTERS",
                  onCtaClick: handleApplyFilters
                }}
                themeName="Primary Button"
              />
            </FilterApplyContainer>
          </CUFilterPanelContent>
        </CUFilterPanel>
      )}
    </CUFiltersWrapper>
  );
};

export default CUFilters;

CUFilters.propTypes = {
  filters: PropTypes.array,
  isInView: PropTypes.bool,
  showDesktopFilters: PropTypes.bool,
  showClearAllAtTheEnd: PropTypes.bool,
  totalFabricAmt: PropTypes.number,
  filteredFabricAmt: PropTypes.number
};
