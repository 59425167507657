import { Breakpoints, Colors } from "@serenaandlily/constants/constants";
import styled from "styled-components";

export const AddNoteContainer = styled.div`
  width: 460px;
  box-sizing: border-box;
  background-color: ${Colors.Cloud};
  padding: 24px 24px 40px;

  @media screen and (max-width: ${Breakpoints.XSMobileMax}) {
    width: 350px;
  }
`;

export const NoteFormWrapper = styled.div`
  margin-top: 24px;

  textarea {
    margin-bottom: 0px;

    ::placeholder {
      color: ${Colors.Midnight};
      opacity: 0.4;
    }
  }

  button {
    width: 100%;
    margin-top: 20px;
  }
`;

export const AddNoteTitle = styled.div`
  text-align: center;
`;

export const Image = styled.img`
  max-width: 100%;
`;

export const ProductInfoRow = styled.div`
  display: flex;
  gap: 21px;
`;

export const ProductImageWrapper = styled.div`
  width: 115px;
`;

export const ProductTitleWrapper = styled.div`
  margin-top: 16px;
`;

export const SubmittedMessageWrapper = styled.div`
  text-align: center;
`;

export const CenterDiv = styled.div`
  text-align: center;
  margin-top: 16px;
  margin-bottom: 24px;

  p {
    margin-bottom: 0;
  }
`;
