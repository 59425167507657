import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { AffirmMessage } from "./AffirmPromoMessageStyled";
import { useContent } from "../../../contexts/ContentContext";
import currency from "currency.js";
import {
  DEFAULT_AFFIRM_PAYMENT_MAX_TOTAL,
  DEFAULT_AFFIRM_PAYMENT_MIN_TOTAL
} from "../../../constants/constants";

const AffirmPromoMessage = ({
  amount,
  affirmColor = "black",
  affirmType = "logo",
  pageType = "cart",
  setAffirmValid,
  ...props
}) => {
  const { siteConfigs: { configAffirm } = {} } = useContent();
  const [promoRendered, setPromoRendered] = useState(false);

  const promoRef = useRef(null);

  useEffect(() => {
    const price = currency(amount)?.intValue;
    const min = currency(
      configAffirm?.affirmPaymentMinTotal || DEFAULT_AFFIRM_PAYMENT_MIN_TOTAL
    )?.intValue;
    const max = currency(
      configAffirm?.affirmPaymentMaxTotal || DEFAULT_AFFIRM_PAYMENT_MAX_TOTAL
    )?.intValue;

    const isInPriceRange = price >= min && price <= max;

    setPromoRendered?.(isInPriceRange);
    setAffirmValid?.(isInPriceRange);
  }, [amount, configAffirm, setAffirmValid, setPromoRendered]);

  useEffect(() => {
    window?.affirm?.ui?.refresh?.();
  }, [amount]);

  return (
    <AffirmMessage
      ref={promoRef}
      promoRendered={promoRendered}
      className="affirm-as-low-as"
      data-testid="affirm-promo-message"
      data-amount={currency(amount).intValue}
      data-affirm-type={affirmType}
      data-affirm-color={affirmColor}
      data-page-type={pageType}
      {...props}
    />
  );
};

export default AffirmPromoMessage;

AffirmPromoMessage.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  affirmColor: PropTypes.string,
  affirmType: PropTypes.string,
  pageType: PropTypes.string,
  setAffirmValid: PropTypes.func
};
