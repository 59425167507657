import React from "react";
import PropTypes from "prop-types";
import {
  SmallFlyoutContainer,
  AllResultsWrapper,
  SearchFlyoutText,
  SearchTermLink,
  ProductResultsWrapper,
  PopularSearchWrapper,
  ResultItem,
  QueryText,
  QueryWrapper,
  Query,
  Separator,
  PaddingWrapper
} from "./SmallFlyoutStyled";
import ProductTile from "../../ProductTile";

const SmallFlyout = ({ popularQueries, searchBarValue, tiles }) => {
  const items = tiles.length > 5 ? tiles.slice(0, 5) : tiles;
  const hasPopQueries = popularQueries?.length > 0;

  return (
    <SmallFlyoutContainer>
      <PaddingWrapper>
        <AllResultsWrapper>
          <SearchFlyoutText>
            SEE ALL SEARCH RESULTS:{" "}
            {
              <SearchTermLink href={`/search?q=${searchBarValue}`}>
                {searchBarValue}
              </SearchTermLink>
            }{" "}
            ?
          </SearchFlyoutText>
        </AllResultsWrapper>
        <ProductResultsWrapper>
          {items?.map((item, idx) => {
            const key = `result-tile-item-${idx}`;
            return (
              <ResultItem key={key}>
                <ProductTile
                  {...item}
                  hideCta={true}
                  flyout={true}
                  source={"search_flyout"}
                  position={idx}
                />
              </ResultItem>
            );
          })}
        </ProductResultsWrapper>
        {hasPopQueries && (
          <PopularSearchWrapper>
            <QueryText>OTHER POPULAR SEARCH QUERIES:&nbsp;</QueryText>
            {popularQueries.map((query, idx) => {
              const { value } = query;
              return (
                <QueryWrapper key={`pop-query-${idx}`}>
                  {idx > 0 && <Separator>&nbsp;,&nbsp;</Separator>}
                  <Query href={`/search?q=${value}`}>{value}</Query>
                </QueryWrapper>
              );
            })}
          </PopularSearchWrapper>
        )}
      </PaddingWrapper>
    </SmallFlyoutContainer>
  );
};

export default SmallFlyout;

SmallFlyout.propTypes = {
  popularQueries: PropTypes.array,
  searchBarValue: PropTypes.string,
  tiles: PropTypes.array
};
