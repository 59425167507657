import React from "react";
import PropTypes from "prop-types";
import { DividerContainer, DividerLine } from "./DividerStyled";

const Divider = ({ size, lineStyle, height, marginBottom }) => {
  const { strokeWeight, strokeColor } = lineStyle;
  return (
    <DividerContainer {...size}>
      <DividerLine
        height={height}
        marginBottom={marginBottom}
        strokeWeight={strokeWeight}
        strokeColor={strokeColor}
      />
    </DividerContainer>
  );
};

export default Divider;

Divider.propTypes = {
  size: PropTypes.shape({
    widthSize: PropTypes.string,
    gridSizeMobile: PropTypes.string
  }),
  lineStyle: PropTypes.shape({
    strokeWeight: PropTypes.string,
    strokeColor: PropTypes.string
  }),
  height: PropTypes.number,
  marginBottom: PropTypes.number
};
