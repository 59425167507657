import React from "react";
import PropTypes from "prop-types";
import CTA from "../CTA";
import { AddToCartWrapper } from "./AddToCartStyled";
import defaultTheme from "./AddToCartTheme";
import useComponentTheme from "../Theme/useComponentTheme";
import { YMAN } from "@constants/pdp/sources";

const AddToCart = ({
  available,
  addToCart,
  isEdit,
  isLoading,
  hasBeenAdded,
  isSwatchOrder = false,
  theme = defaultTheme,
  currentCount,
  isLoadingPrice = false,
  source = "",
  disableAdd = false,
  isCUP = false
}) => {
  const ADD_TO_BAG = isCUP && source === YMAN ? "CUSTOMIZE" : "ADD TO BAG";
  let availableText = !isEdit ? ADD_TO_BAG : "UPDATE";
  if (hasBeenAdded) availableText = "ADDED TO BAG";
  const zeroCount = currentCount === 0;
  let ctaText = "";

  if (isLoadingPrice || isSwatchOrder) {
    ctaText = ADD_TO_BAG;
  } else if (available) {
    ctaText = availableText;
  } else {
    ctaText = "NOT AVAILABLE";
  }

  const isDisabled =
    !available || isLoading || hasBeenAdded || zeroCount || disableAdd;

  const ctaTheme = useComponentTheme({ theme });

  return (
    <AddToCartWrapper
      onClick={isDisabled ? () => {} : addToCart}
      isDisabled={isDisabled}
    >
      <CTA
        cta={{ ctaText: ctaText, isLoading }}
        themeName={ctaTheme?.cta?.themeName}
        useButtonTag={true}
        elementName={`${source}cta`}
      />
    </AddToCartWrapper>
  );
};

export default AddToCart;

AddToCart.propTypes = {
  available: PropTypes.bool,
  addToCart: PropTypes.func,
  isEdit: PropTypes.bool,
  isLoading: PropTypes.bool,
  hasBeenAdded: PropTypes.bool,
  isSwatchOrder: PropTypes.bool,
  isLoadingPrice: PropTypes.bool,
  theme: PropTypes.object,
  currentCount: PropTypes.number,
  disableAdd: PropTypes.bool,
  isCUP: PropTypes.bool
};
