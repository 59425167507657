import {
  Breakpoints,
  Colors,
  Spacing
} from "@serenaandlily/constants/constants";
import styled from "styled-components";

export const PDPSpecialMessageRoot = styled.div`
  border-radius: 8px;
  margin-bottom: ${Spacing.Base}px;
  background-color: ${Colors["Special Message Grey"]};
  padding: 12px 16px;
  width: fit-content;

  @media (max-width: ${Breakpoints.XSMobileMax}) {
    margin-bottom: ${Spacing.Small}px;
  }
`;
