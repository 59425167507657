import { Colors, Typography } from "../../constants/constants";

const defaultTheme = {
  productName: {
    themeName: "headline5",
    overrides: {
      fontSize: "14px",
      fontSizeTablet: "14px",
      fontSizeDesktop: "16px",
      fontFamily: Typography.BeatriceRegular,
      fontColor: Colors.Midnight,
      lineHeight: "1.2",
      letterSpacing: "0.28px",
      marginBottom: "20px",
      backgroundColor: "green",
      padding: "20px"
    }
  },
  standardPriceRange: {
    themeName: "textSmall",
    fontFamily: Typography.BeatriceLight,
    fontWeight: 300,
    fontSize: "12px",
    fontSizeTablet: "12px",
    fontSizeDesktop: "14px",
    letterSpacing: "0.12px",
    mobileFontColor: Colors.Midnight
  },
  standardRange: {
    themeName: "textSmall",
    fontFamily: Typography.BeatriceLight,
    fontSize: "12px",
    fontSizeTablet: "12px",
    fontSizeDesktop: "14px"
  },
  priceRangeText: {
    themeName: "textSmall",
    fontSize: "12px",
    fontSizeTablet: "12px",
    fontSizeDesktop: "14px",
    letterSpacing: "0.12px",
    fontWeight: 500,
    fontFamily: Typography.BeatriceMedium
  },
  tradeRangeText: {
    themeName: "textSmall",
    fontColor: "#2074C3",
    fontWeight: 500,
    fontSize: "12px",
    letterSpacing: "0.12px",
    lineHeight: "120%",
    fontSizeTablet: "12px",
    fontSizeDesktop: "14px",
    fontFamily: Typography.BeatriceMedium
  },
  discountedPriceRangeText: {
    themeName: "textRedSmall",
    fontSize: "12px",
    fontSizeTablet: "12px",
    fontSizeDesktop: "14px",
    letterSpacing: "0.12px",
    fontWeight: 500,
    fontFamily: Typography.BeatriceMedium
  },
  productTileCTA: {
    themeName: "Secondary Button",
    themeOverrides: {
      fontFamily: Typography.BeatriceMedium,
      lineHeight: "15.6px",
      letterSpacing: "0.3px",
      padding: "16px 40px"
    }
  }
};

export default defaultTheme;
