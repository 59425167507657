const getTileAvailability = (listOfRelatedProductIds, availability) => {
  let isAvailable = false;
  if (Object.values(availability).length === 0) return true;
  for (const productId of listOfRelatedProductIds) {
    if (availability[productId]) {
      isAvailable = true;
      break;
    }
  }

  return isAvailable;
};

export default getTileAvailability;
