import { Colors, Typography } from "@serenaandlily/constants/constants";

const defaultTheme = {
  filtersTitle: {
    themeName: "filtersTitle",
    tag: "h3",
    fontSize: "20px",
    fontSizeTablet: "24x",
    fontSizeDesktop: "24px",
    fontFamily: "PP Fragment"
  },
  showOptions: {
    fontFamily: Typography.BeatriceLight,
    fontWeight: 300,
    fontSize: "15px",
    fontSizeTablet: "15px",
    fontSizeDesktop: "15px",
    color: Colors["Midnight"],
    showUnderline: true,
    showUnderlineAnimation: true,
    backgroundColor: "transparent",
    backgroundColorActive: "transparent",
    backgroundColorDisabled: "transparent",
    backgroundColorHover: "transparent",
    borderColor: "transparent",
    borderColorActive: "transparent",
    borderColorDisabled: "transparent",
    borderColorHover: "transparent"
  }
};

export default defaultTheme;
