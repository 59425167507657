import React from "react";

import Banner from "../../Banner";
import CTA from "../../CTA";
import ContactInfo from "../../ContactInfo";
import EmailIconLinks from "../../EmailIconLinks";
import Headline from "../../Headline";
import IconLinks from "../../IconLinks";
import Image from "../../Image";
import ImageAndCaptionTile from "../../ImageAndCaptionTile";
import InspirationTile from "../../InspirationTile";
import LinksColumn from "../../LinksColumn";
import SecondaryFooter from "../../SecondaryFooter";
import ContentWrapper from "../../ContentWrapper";
import EmailSubscription from "../../EmailSubscription";
import LeftNavigation from "../../LeftNavigation";
import VideoPlayer from "../../VideoPlayer";

export const COMPONENT_CONFIG = {
  Accordion: {
    type: "Accordion",
    component: React.lazy(() => import("../../Accordion"))
  },
  BackgroundColor: {
    type: "BackgroundColor",
    component: React.lazy(() => import("../../BackgroundColor"))
  },
  Banner: {
    type: "Banner",
    component: Banner
  },
  CTA: {
    type: "CTA",
    component: CTA
  },
  ContactInfo: {
    type: "ContactInfo",
    component: ContactInfo
  },
  EmailIconLinks: {
    type: "EmailIconLinks",
    component: EmailIconLinks
  },
  Headline: {
    type: "Headline",
    component: Headline
  },
  IconLinks: {
    type: "IconLinks",
    component: IconLinks
  },
  Image: {
    type: "Image",
    component: Image
  },
  ImageAndCaptionTile: {
    type: "ImageAndCaptionTile",
    component: ImageAndCaptionTile
  },
  InspirationTile: {
    type: "InspirationTile",
    component: InspirationTile
  },
  LinksColumn: {
    type: "LinksColumn",
    component: LinksColumn
  },
  RecommendationCarousel: {
    type: "RecommendationCarousel",
    component: React.lazy(() => import("../../RecommendationCarousel"))
  },
  SecondaryFooter: {
    type: "SecondaryFooter",
    component: SecondaryFooter
  },
  ContentWrapper: {
    type: "ContentWrapper",
    component: ContentWrapper
  },
  EmailSubscription: {
    type: "EmailSubscription",
    component: EmailSubscription
  },
  LeftNavigation: {
    type: "LeftNavigation",
    component: LeftNavigation
  },
  VideoPlayer: {
    type: "VideoPlayer",
    component: VideoPlayer
  }
};

export const getColumnContent = (contentType) => {
  return COMPONENT_CONFIG[contentType]?.component;
};

export default getColumnContent;

// const accordionComponent = () => Accordion;

// const ALLOWED_COLUMN_CONTENT = {
//   BackgroundColor,
//   Banner,
//   CTA,
//   ContactInfo,
//   EmailIconLinks,
//   Headline,
//   IconLinks,
//   Image,
//   ImageAndCaptionTile,
//   InspirationTile,
//   LinksColumn,
//   RecommendationCarousel,
//   SecondaryFooter,
//   ContentWrapper,
//   EmailSubscription,
//   LeftNavigation
// };

// export const getColumnContent = (contentType) => {

//   const component =
//     contentType === "Accordion"
//       ? accordionComponent()
//       : ALLOWED_COLUMN_CONTENT[contentType];
//   return component;
// };
