import styled from "styled-components";

export const AccordionWrapper = styled.div`
  ${({ theme }) => `
    width: 100%;
    border-bottom: ${theme?.borderOptions?.borderThickness}px solid ${theme?.borderOptions?.borderColor};
  `}
`;

export const AccordionItemContainer = styled.div`
  ${({ theme }) => `
    width: 100%;
    border-top: ${theme?.borderOptions?.borderThickness}px solid ${theme?.borderOptions?.borderColor};
  `}
`;

export const AccordionTitleContainer = styled.button`
  ${({ theme }) => `
    padding: ${theme?.spacing?.verticalSpacing}px ${theme?.spacing?.horizontalSpacing}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 100%;
    background-color: transparent;
    border: 0;
  `}
`;

export const AccordionTitle = styled.span``;

export const AccordionIconContainer = styled.div`
  svg {
    fill: ${({ iconFill }) => iconFill || "#383838"};
  }
`;

export const AccordionDetail = styled.div`
  ${({ theme, isOpen, scrollHeight }) => `
    height: ${isOpen ? `${scrollHeight}px` : "0px"};
    visibility: ${isOpen ? "visible" : "hidden"};
    overflow: hidden;
    -webkit-transition: height ease 0.2s;
    transition: height ease 0.2s;
    padding: 0 ${theme?.spacing?.horizontalSpacing}px;
  `}
`;

export const Img = styled.div``;
