import styled from "styled-components";
import {
  Colors,
  Typography,
  FontSizes,
  Breakpoints
} from "../../constants/constants";
import SnlImage from "../SnlImage";

export const EmailSubscriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : "0"};
    @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
      flex-direction: row;
      img {
        width: 477px !important;
      }
    }
    @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
      height: fit-content !important;
      padding: 0 !important;
    }
`;

export const SubscriptionTitle = styled.div``;

export const SubscriptionSubText = styled.div``;

export const Image = styled(SnlImage)`
  max-width: 100%;
  width: 100%;
  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    width: 45%;
    object-fit: cover;
  }
`;

export const ContentContainer = styled.div`
  background-color: ${({ mobileBackgroundColor }) => mobileBackgroundColor};
  padding: 32px 16px 40px 16px;
  input {
    width: 100%;
    padding: 16px;
    border-radius: 4px;
    margin-bottom: 8px;
    max-width: calc(100% - 11px);
  }
  label {
    padding: 0;
  }

  a {
    color: ${Colors["Primary Midnight"]};
  }

  ${({ mobileCSS }) => mobileCSS}

  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    width: ${({ bannerImageSrc }) => (bannerImageSrc ? "55%" : "100%")};
    padding: 32px 34px 33px 60px;
    background-color: ${({ tabletBackgroundColor, backgroundColor }) =>
      tabletBackgroundColor || backgroundColor || "transparent"};
    ${({ tabletCSS }) => tabletCSS}
  }

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    ${({ desktopCSS }) => desktopCSS}
    background-color: ${({ backgroundColor }) =>
      backgroundColor || "transparent"};
    padding: ${({ disableContentPadding }) =>
      disableContentPadding ? "0" : "56px 180px 50px 95px"};
  }
`;

export const EmailSubscriptionForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const RadioButtonsWrapper = styled.div`
  order: ${({ radioButtonPlacement }) =>
    radioButtonPlacement === "Top" ? 0 : 1};
`;

export const OptionsContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
  }
`;

export const FreqContainer = styled.div`
  cursor: pointer;
`;

export const FreqTab = styled.div`
  font-family: ${Typography.BeatriceRegular};
  color: ${Colors["Primary Midnight"]};
  font-size: ${FontSizes.Large};
  font-weight: 700;
  letter-spacing: 0.02em;
  text-align: ${({ textAlign }) => textAlign || "left"};
  width: 81px;
  padding-bottom: 7px;
`;

export const FreqBar = styled.div`
  width: 100%;
  height: 1px;
  background-color: #a7b0ba;
  position: relative;
`;

export const ProgressBar = styled.div`
  width: 50%;
  height: 2px;
  position: absolute;
  background-color: ${Colors["Primary Midnight"]};
  transition: all 0.2s ease-in;
  transform: ${({ isDaily }) =>
    isDaily ? "translateX(0)" : "translateX(100%)"};
`;

export const RadioInputWrapper = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

export const RadioInputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
  cursor: pointer;
`;

export const RadioInput = styled.input`
  width: fit-content !important;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  font: inherit;
  color: #000;
  width: 18px !important;
  height: 18px !important;
  border: 1px solid #667487;
  border-radius: 50% !important;
  margin: 0 10px 0 0 !important;
  padding: 0 !important;
  position: relative;
  background-color: ${Colors["Primary Midnight"]};
  cursor: pointer;
  display: grid;
  place-content: center;

  ::before {
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em ${Colors.White};
  }

  :checked::before {
    transform: scale(1);
    position: absolute;
    // 2.5px is half of the width ^^
    top: calc(50% - 2.5px);
    left: calc(50% - 2.5px);
  }
`;

export const RadioInputLabel = styled.label`
  font-family: ${Typography.ProximaNova};
  font-size: ${FontSizes.Large};
  line-height: 120%;
  cursor: pointer;
`;

export const EmailInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  > div {
    width: 100%;
  }
`;

export const SquaredEmailInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  > div {
    width: 252px;
    margin: 0;
    margin-bottom: 10px;
    margin-right: 12px;
  }
  input {
    border-radius: 0;
    height: 38px;
    width: 239px;
    letter-spacing: 2.5px;
    padding: 0 0 0 10px;
    text-transform: uppercase;
  }

  a {
    max-height: 38px;
    width: 125px;
    border-width: 2px;
    border-style: solid;
    font-weight: bold;
  }
`;

export const SubmitEmail = styled.button`
  position: absolute;
  right: 26px;
  border: none;
  background: none;
  top: ${({ hasError }) =>
    hasError ? "calc(50% - 27px)" : "calc(50% - 12px)"};
`;

export const SubmittedMessageContainer = styled.div`
  display: flex;
  align-items: center;
  p {
    margin: 0;
  }
  svg {
    fill: ${({ svgFill }) => svgFill || "#000"};
    margin-right: 10px;
  }
`;
