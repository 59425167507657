import { Breakpoints, GridSpacing } from "../../../constants/constants";
import { css } from "styled-components";

const MOBILE_STYLES = {
  "Wide Margin": css`
    @media screen and (max-width: ${Breakpoints.SmallMobile}) {
      justify-content: center;
      margin-left: calc(${GridSpacing.GridGutterMobile} * 0.5);
      margin-right: calc(${GridSpacing.GridGutterMobile} * 0.5);
    }
  `,
  "Full Width with Margin": css`
    @media screen and (max-width: ${Breakpoints.SmallMobile}) {
      margin-left: calc(${GridSpacing.GridGutterMobile} * 0.5);
      margin-right: calc(${GridSpacing.GridGutterMobile} * 0.5);
    }
  `
};

const DEFAULT_STYLE = css`
  @media screen and (max-width: ${Breakpoints.SmallMobile}) {
    overflow: hidden;
`;

const getVideoContainerSizeMobileStyles = (size) => {
  return MOBILE_STYLES[size] || DEFAULT_STYLE;
};

export default getVideoContainerSizeMobileStyles;
