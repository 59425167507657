import styled from "styled-components";
import { Typography, Breakpoints, Colors } from "../../constants/constants";
import { CTATextWrapper } from "../CTA/CTAStyled";

export const CrossSellWrapper = styled.div``;

export const CrossSellContainer = styled.div`
  margin: 14px 0;
  padding: 15px 3px;
  border-top: 1px solid #cbcaca;
  border-bottom: 1px solid #cbcaca;
  max-height: 333px;
  overflow: auto;
`;

export const CrossSellSubheading = styled.div`
  margin-bottom: 15px;
  color: ${Colors["Dark Primary"]};
  font-weight: 300;
  font-size: 13px;
  letter-spacing: 1px;
  font-family: ${Typography.ProximaNova};
`;

export const CrossSellTileWrapper = styled.div`
  border: none;
  min-height: 101px;
  margin-bottom: 30px;
  position: relative;
  display: flex;
`;

export const ImageContainer = styled.div`
  width: 100px;
  height: 100px;
  margin-right: 8px;
  float: left;
  img {
    width: 100px;
    height: 100px;
  }
`;

export const ProductImg = styled.img`
  max-width: 100%;
`;

export const InfoContainer = styled.div`
  width: 70%;
  min-height: 161px;
  display: flex;
  flex-direction: column;
`;

export const ProductDescriptions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProductTileName = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

export const ProductTileFlag = styled.span`
  font-weight: 600;
  margin-right: 5px;

  @media screen and (max-width: ${Breakpoints.SmallMobile}) {
    display: block;
    color: ${Colors["Medium Primary"]};
    font-size: 8px;
    margin-bottom: 5px;
  }
`;

export const VariationAttribute = styled.div`
  display: flex;
`;

export const VariationLabel = styled.span``;

export const VariationValue = styled.span``;

export const Price = styled.div`
  margin-top: 16px;
`;

export const QTYContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 68px;
  > div {
    margin: 0px;
  }
`;

export const SpinnerContainer = styled.div`
  margin-top: 29px;
  @media screen and (min-width: ${Breakpoints.SmallMobile}) {
    margin-top: 0;
  }
  label {
    color: ${Colors["Dark Primary"]};
    display: block;
    font-size: 11px !important;
    font-family: ${Typography.ProximaNova};
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0.025em;
    width: auto;
    padding: 10px 0 5px;
    margin: 0;
    text-align: left;
  }
  margin-bottom: 12px;
`;

export const AddToCartContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  > div {
    width: min-content;
  }

  button {
    background-color: transparent !important;
  }

  button:focus {
    box-shadow: none !important;
  }

  span:before {
    background-color: transparent !important;
    height: 0;
  }

  ${CTATextWrapper} {
    border-bottom: 1px solid ${Colors["Midnight"]};
  }
`;

export const AddWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 16px;

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    align-items: center;
    flex-direction: row;
  }
`;

export const CrossSellPromoMessages = styled.div`
  margin-top: 4px;
`;
