const defaultTheme = {
  heading: {
    themeName: "headline6"
  },
  cta: {
    themeName: "CTA Midnight"
  }
};

export default defaultTheme;
