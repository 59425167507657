import { Spacing } from "../constants";
import { Breakpoints } from "../../../constants/constants";
import { css } from "styled-components";

const getGridContainerMobileStyles = (size) => {
  switch (size) {
    case "Wide Margin":
      return css`
        @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
          justify-content: center;
          margin-left: calc(${Spacing.GridGutterMobile} * 0.5);
          margin-right: calc(${Spacing.GridGutterMobile} * 0.5);
        }
      `;
    case "Full Width with Margin":
      return css`
        @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
          margin-left: calc(${Spacing.GridGutterMobile} * 0.5);
          margin-right: calc(${Spacing.GridGutterMobile} * 0.5);
        }
      `;
    default:
      return css`
        @media screen and (min-width: ${Breakpoints.IPhone11}) {
          overflow-x: hidden;
        }
        margin-left: 0;
        margin-right: 0;
      `;
  }
};

export default getGridContainerMobileStyles;
