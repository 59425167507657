import styled from "styled-components";
import SnlImage from "../SnlImage";

export const Image = styled(SnlImage)`
  object-fit: cover;
  :hover {
    opacity: ${({ $disableRollover }) => ($disableRollover ? "1" : "50%")};
  }
`;

export const ImageAndCaptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ tileWidth }) => (tileWidth === "2 Column" ? "50%" : "100%")};
  align-items: center;
  border: ${({ addBorder }) => (addBorder ? "1px solid" : "none")};
  margin-bottom: ${({ bottomMargin }) => `${bottomMargin}px`};
  padding-left: 12px;
  padding-right: 12px;
`;

export const ImageCaption = styled.figcaption`
  height: fit-content;
`;

export const ImageContainer = styled.div`
  padding: 0 12px;
  margin: 0 0 26px 0;
`;

export const ImageWrapper = styled.a`
  cursor: pointer;
`;
