import React, { useState, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import AddToCart from "../AddToCart";
import ColorTiles from "../ColorTiles";
import ProductName from "../ProductName";
import QuantitySpinner from "../QuantitySpinner";
import VariationTiles from "../VariationTiles";
import FabricSelector from "../FabricSelector";
import {
  ProductOptionsContainer,
  AddToCartContainer,
  ProductPriceContainer,
  ProductDescription,
  AffirmWrapper,
  PdpQuantitySpinnerContainer,
  ProductOptionBottomContainer,
  QtyAndAddContainer,
  ProductCalloutWrapper,
  CTACalloutLink,
  ProductPromoMessages
} from "./ProductOptionsStyled";
import { useBreakpoint } from "../Breakpoints";
import { useProductContext } from "../../contexts/ProductContext";
import { useCart, useCartUpdate } from "../../contexts/CartContext";
import { useGlobalPopupUpdate } from "../GlobalPopup/GlobalPopupContext";
import ProductErrorMessage from "../ProductErrorMessage";
import { useUserData } from "../../contexts/UserContext";
import { useMarketing } from "../../contexts/MarketingContext";
import reorderVariations from "./utils/reorderVariations";
import getInventory from "./utils/getInventory";
import { useProductPrice } from "../../hooks/useProductPrice";
import { useProductEdd } from "../../hooks/useProductEdd";
import getCurrentVariationChildIDs from "../../utils/getCurrentVariationChildIDs";
import buildCUOptions from "../../utils/buildCUOptions";
import checkAddToCartEnabled from "../../utils/checkAddToCartEnabled";
import { useProductSwatchDescription } from "../../hooks/useProductSwatchDescription";
import AffirmPromoMessage from "../AffirmComponents/AffirmPromoMessage";
import currency from "currency.js";
import defaultTheme from "./ProductOptionTheme";
import useComponentTheme from "../Theme/useComponentTheme";
import RichText from "../RichText";
import CTA from "../CTA";
import ProductPrice from "../ProductPrice";
import PDPSpecialMessage from "../PDPSpecialMessage";
import Text from "../Text";
import useUser from "../../hooks/useUser";
import findSelectedFabric from "./utils/findSelectedFabric";
import AnalyticsData from "../AnalyticsData";
import ProductEDD from "../ProductEDD";
import { useSidePanelContext } from "../../contexts/SidePanelContext";
import AddedBagPanel from "../AddedBagPanel";
import { useProductDataContext } from "../../contexts/ProductDataContext";
import PromoMessages from "../../../ui-toolkit/components/Promos/PromoMessages";
import { PAGE_TYPE } from "@serenaandlily/constants/analytics/pageType";
import { GLOBAL_HEADER_ID } from "../GlobalHeader";
import { trackPinterestEvent } from "@utils/pinterest";
import WishlistHeart from "../WishlistHeart";

// TODO: Refactor complexity once support for all types of variation is implemented

function getProductId(childProducts, currentChildPID, id) {
  return Array.isArray(childProducts) && childProducts.length > 0
    ? currentChildPID
    : id;
}

function handleEmptyCuOptions(cuOptions) {
  return cuOptions?.length > 0 ? cuOptions : null;
}

const isQtyExceedingTotalAvailable = (
  itemsAddedToCart,
  currentCount,
  totalAvailable,
  hasCustomUpholstery
) => {
  return (
    !itemsAddedToCart && currentCount > totalAvailable && !hasCustomUpholstery
  );
};

const isQtyExceedingWithAddedItems = (
  itemsAddedToCart,
  currentCount,
  totalAvailable,
  hasCustomUpholstery
) => {
  return (
    !!itemsAddedToCart &&
    currentCount + itemsAddedToCart > totalAvailable &&
    !hasCustomUpholstery
  );
};

const isEditError = (isEdit, cartUpdateError) => {
  return isEdit && cartUpdateError;
};

const getCuOptions = (hasCustomUpholstery, currentVariations) => {
  if (!hasCustomUpholstery) return [];
  return buildCUOptions(currentVariations);
};

// eslint-disable-next-line max-lines-per-function, complexity
const ProductOptions = ({
  cartItemId,
  cartPopupHeader,
  crossSellProducts,
  imageUrl,
  initialProductId,
  initialQty,
  isEdit,
  mobileBreakpoint,
  pdpProps,
  swatchHeadings,
  pdpSettings,
  imgUrl,
  setCartItem = () => {},
  isQuickview = false,
  clickData,
  theme = defaultTheme
}) => {
  const userData = useUserData();

  const {
    id,
    name,
    sku,
    inventory,
    inventories,
    customUpholstery,
    variations,
    slug,
    netsuiteId,
    productType,
    department,
    description,
    childProducts,
    productCallout,
    specialProductMessage,
    subDepartment,
    division,
    ctaCalloutText,
    ctaCalloutLink,
    isRetailExclusive,
    collection
  } = pdpProps;

  const [selectedSwatchList, setSelectedSwatchList] = useState([]);

  const {
    hasCustomUpholsteryProductTypes,
    selectedSwatch,
    currentVariations,
    setCurrentVariations,
    scene7Url,
    setScene7Url,
    setCuImageIsLoading,
    currentProductPrice,
    setCurrentProductPrice,
    setChildProduct,
    initialMpn,
    setSelectedSwatch,
    variationsHaveChanged,
    setVariationsHaveChanged,
    wishlistItemData,
    setWishlistItemData,
    wishlistStatus,
    setWishlistStatus,
    wishlistGa4Props
  } = useProductContext();
  const { completedCuData, handleLoadAllFabricImages } =
    useProductDataContext();

  const { user: { data: { isDOO, isDSO, email } = {} } = {} } = useUser();
  const { agiloneLoaded, handleTrackProductBrowsed } = useMarketing();
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints[mobileBreakpoint || "IPhone11ProMax"];

  const madeToOrderFabrics = useMemo(
    () =>
      completedCuData?.fabrics?.madeToOrderFabrics.map((fabric) => ({
        ...fabric,
        subType: "Cotton"
      })) || [],
    [completedCuData?.fabrics?.madeToOrderFabrics]
  );

  const quickshipFabrics = useMemo(
    () =>
      completedCuData?.fabrics?.quickshipFabrics.map((fabric) => ({
        ...fabric,
        subType: "Performance"
      })) || [],
    [completedCuData?.fabrics?.quickshipFabrics]
  );

  const hasCustomUpholstery = customUpholstery?.fabrics;
  const {
    currentChildNetsuiteId,
    currentChildPID,
    currentChildSku,
    currentChildProduct
  } = getCurrentVariationChildIDs(currentVariations, childProducts);

  useEffect(() => {
    currentChildProduct && setChildProduct(currentChildProduct);
  }, [currentChildProduct]);

  const productId = useMemo(
    () => getProductId(childProducts, currentChildPID, id),
    [childProducts, currentChildPID, id]
  );

  const totalAvailable = getInventory(inventory, inventories, productId);
  useEffect(() => {
    hasCustomUpholstery && setCuImageIsLoading(true);
  }, [selectedSwatch, currentVariations, hasCustomUpholstery]);

  const isCuUber = productType === "cuUber";
  const orderedVariations = reorderVariations(
    isCuUber ? customUpholstery?.variations : variations
  );

  useEffect(() => {
    if (agiloneLoaded) {
      handleTrackProductBrowsed(initialMpn || "", email);
    }
  }, [agiloneLoaded]);

  const quantitySpinnerProps = {
    spinnerID: `${name}QuantitySpinner`,
    min: 0,
    max: 999,
    start: initialQty || 1
  };

  const [currentCount, setCurrentCount] = useState(quantitySpinnerProps.start);

  useEffect(() => {
    if (hasCustomUpholstery && !initialMpn) {
      setSelectedSwatch(
        customUpholstery?.fabrics?.madeToOrderFabrics[0] ||
          customUpholstery?.fabrics?.quickshipFabrics[0]
      );
    } else if (hasCustomUpholstery && initialMpn) {
      let fabricFound = findSelectedFabric(
        setSelectedSwatch,
        [
          customUpholstery?.fabrics?.madeToOrderFabrics,
          customUpholstery?.fabrics?.quickshipFabrics
        ],
        initialMpn
      );
      if (!fabricFound)
        setSelectedSwatch(
          customUpholstery?.fabrics?.madeToOrderFabrics[0] ||
            customUpholstery?.fabrics?.quickshipFabrics[0]
        );
    }
  }, [customUpholstery, initialMpn, setSelectedSwatch]);

  const { data: initialSwatchData } = useProductSwatchDescription(
    selectedSwatch?.swatchProduct?.id || 0
  );

  const initialSwatchDescription = initialSwatchData?.description?.data || "";

  const { cartItems } = useCart();

  const {
    cartUpdateError,
    handleCartAdd,
    handleCartItemEdit,
    isLoading,
    setIsAddLoading
  } = useCartUpdate();

  const itemsAddedToCart =
    cartItems.find((item) => {
      return item.netsuiteId === netsuiteId || item.masterId === netsuiteId;
    })?.quantity || 0;

  const { closePopup } = useGlobalPopupUpdate();

  const { handleOpenPanel, setContent } = useSidePanelContext();

  const { data: swatchPriceData } = useProductPrice({
    productId:
      customUpholstery?.fabrics?.madeToOrderFabrics[0]?.swatchProduct?.id || ""
  });

  const swatchPrice = swatchPriceData?.formattedListPrice;
  const fabricCode = selectedSwatch?.code || "";
  const topLineVariation = currentVariations?.["Size"]?.description || "";

  const cuOptions = useMemo(
    () => getCuOptions(hasCustomUpholstery, currentVariations),
    [hasCustomUpholstery, currentVariations]
  );

  const {
    data: productPriceData,
    isLoadingPrice,
    isLoadingPriceAlt
  } = useProductPrice({
    productId: currentChildPID || id,
    isCuProduct: hasCustomUpholsteryProductTypes,
    currentVariations,
    customUpholsteryOptions: {
      topLineVariation,
      fabricCode: fabricCode,
      otherOptions: handleEmptyCuOptions(cuOptions)
    }
  });

  const { data: eddData } = useProductEdd(
    {
      productId: currentChildPID || id,
      currentVariations,
      ...(hasCustomUpholsteryProductTypes && {
        isCuProduct: hasCustomUpholsteryProductTypes,
        customUpholsteryOptions: {
          topLineVariation,
          fabricCode,
          otherOptions: cuOptions
        }
      })
    },
    !isRetailExclusive
  );

  useEffect(() => {
    const newScene7Url = productPriceData?.scene7Url;
    if (!hasCustomUpholstery) {
      setScene7Url(null);
      setCuImageIsLoading(false);
      return;
    }
    if (newScene7Url && newScene7Url !== scene7Url) {
      setScene7Url(newScene7Url);
      hasCustomUpholstery && setCuImageIsLoading(false);
    } else if (newScene7Url && variationsHaveChanged) {
      setScene7Url(newScene7Url);
      hasCustomUpholstery && setCuImageIsLoading(false);
      setVariationsHaveChanged(false);
    } else if (newScene7Url === scene7Url) {
      hasCustomUpholstery && setCuImageIsLoading(false);
    }
  }, [productPriceData, hasCustomUpholstery]);

  const currentPrice = productPriceData || {};

  useEffect(() => {
    const hasNewPrice =
      currentPrice?.formattedListPrice !==
      currentProductPrice?.formattedListPrice;
    if (hasNewPrice) {
      setCurrentProductPrice(currentPrice);
    }
  }, [currentVariations, currentPrice]);

  const handleOpenModal = () => {
    const header = document?.getElementById(GLOBAL_HEADER_ID);
    const headerValues = header?.getBoundingClientRect();
    const isInView = headerValues?.top !== 0;
    if (isQuickview) closePopup();
    handleOpenPanel(true);
    setContent(
      <AddedBagPanel
        isInView={isInView}
        panelTitle={cartPopupHeader}
        crossSell={crossSellProducts}
        masterProductId={id}
        currentChildPID={currentChildPID}
        name={name}
        price={currentPrice}
        quantity={currentCount}
        fabric={selectedSwatch?.name}
        currentVariations={currentVariations}
        imageUrl={hasCustomUpholsteryProductTypes ? scene7Url : imageUrl}
        isCU={hasCustomUpholsteryProductTypes}
      />
    );
  };

  const hasVariations = orderedVariations && orderedVariations?.length !== 0;

  const productOptionTheme = useComponentTheme({ theme });

  const listedPrice = currentPrice?.formattedListPrice;

  const isAddToCartEnabled = checkAddToCartEnabled({
    totalAvailable,
    currentCount: currentCount + itemsAddedToCart,
    hasCustomUpholstery,
    listedPrice
  });
  const multipleVariations = orderedVariations?.length > 1;

  const cartItem = {
    netsuiteId,
    quantity: currentCount,
    name,
    displayPrice: productPriceData,
    variant: currentChildNetsuiteId || null,
    brand: "Serena And Lily", //static ?
    category: subDepartment,
    variations: currentVariations
      ? Object.keys(currentVariations).map((key) => {
          return { name: key, value: currentVariations[key].description };
        })
      : null,
    productCallout,
    division,
    department,
    collection
  };

  const pdpHasErrorMessage =
    isQtyExceedingTotalAvailable(
      itemsAddedToCart,
      currentCount,
      totalAvailable,
      hasCustomUpholstery
    ) ||
    isQtyExceedingWithAddedItems(
      itemsAddedToCart,
      currentCount,
      totalAvailable,
      hasCustomUpholstery
    ) ||
    isEditError(isEdit, cartUpdateError);

  const handleAddToCart = async (e) => {
    setIsAddLoading(true);
    e.preventDefault();
    if (isEdit) {
      await handleCartItemEdit({
        cartItemId,
        quantity: currentCount,
        productId,
        closePopup,
        customerEmail: userData?.user?.email,
        initialProductId
      });
    } else {
      setCartItem(cartItem);
      let customUpholsteryOptions = {
        fabricCode: fabricCode,
        otherOptions: cuOptions
      };
      if (isCuUber) {
        customUpholsteryOptions = {
          ...customUpholsteryOptions,
          topLineVariation
        };
      }
      await handleCartAdd({
        quantity: currentCount,
        productId,
        closePopup: null,
        customerEmail: userData?.user?.email,
        customUpholsteryOptions: hasCustomUpholsteryProductTypes
          ? customUpholsteryOptions
          : null,
        price: currentPrice?.formattedListPrice,
        productName: name,
        netsuiteId,
        source: isQuickview ? PAGE_TYPE.quickshop : PAGE_TYPE.PDP
      });

      trackPinterestEvent("pinterestAddToCartID", "AddToCart", {
        value: currentPrice?.formattedListPrice,
        currency: "USD",
        userEmail: userData?.user?.email,
        contents: [
          {
            item_name: name,
            id: productId,
            item_price: currentPrice?.formattedListPrice,
            quantity: currentCount
          }
        ]
      });

      setIsAddLoading(false);
      handleOpenModal();
    }
  };

  const setClickData = () => {
    localStorage.setItem(
      `pdpClickData${netsuiteId}`,
      JSON.stringify({
        ...clickData
      })
    );
  };

  const showEdd = true;

  useEffect(() => {
    const listName = localStorage?.getItem("productDetailList");
    globalThis?.document?.body?.dispatchEvent(
      new CustomEvent("a-pdp", {
        detail: {
          netsuiteId,
          action: {
            list: listName || "",
            source: isQuickview ? PAGE_TYPE.quickshop : PAGE_TYPE.PDP
          }
        }
      })
    );
    localStorage.removeItem("productDetailList");

    setCurrentCount(initialQty || 1);
  }, [id]);

  const addToCartRef = useRef(null);
  const [addToCartWidth, setAddToCartWidth] = useState(0);

  useEffect(() => {
    if (addToCartRef.current) {
      const width = `${addToCartRef.current.getBoundingClientRect().width}px`;
      setAddToCartWidth(width);
    }
  }, [addToCartRef, addToCartRef?.current?.getBoundingClientRect()?.width]);

  useEffect(() => {
    setWishlistItemData({
      id: productId,
      netsuiteId: currentChildNetsuiteId || netsuiteId,
      ...(hasCustomUpholsteryProductTypes
        ? {
            customUpholsteryOptions: {
              ...(isCuUber ? { topLineVariation } : {}),
              fabricCode: fabricCode,
              otherOptions: cuOptions
            }
          }
        : {})
    });
  }, [
    productId,
    hasCustomUpholsteryProductTypes,
    isCuUber,
    fabricCode,
    cuOptions,
    topLineVariation,
    setWishlistItemData
  ]);

  useEffect(() => {
    setWishlistStatus({
      wishlistHasProduct: productPriceData?.wishlistHasProduct
    });
  }, [productPriceData?.wishlistHasProduct]);

  return (
    <ProductOptionsContainer
      data-cnstrc-product-detail={""} // no value needed
      data-cnstrc-variation-id={currentChildNetsuiteId || ""} // net sui  te id of current variation
      data-cnstrc-item-id={netsuiteId || ""} // master netsuiteid
      data-pdpprint="false"
    >
      <AnalyticsData
        id="a-product"
        data={{ ...pdpProps, ...cartItem, isQuickview, list: "" }}
      />
      <AnalyticsData id="a-price" data={currentPrice} />
      <AnalyticsData id={`a-product-${cartItem.netsuiteId}`} data={cartItem} />
      {productCallout && !isMobile && (
        <ProductCalloutWrapper theme={productOptionTheme}>
          <Text
            themeName={productOptionTheme?.productCallout?.themeName}
            copy={productCallout}
          />
        </ProductCalloutWrapper>
      )}
      {!isMobile && (
        <ProductName
          name={name}
          source="pdp-"
          wishlistComponent={
            <WishlistHeart
              itemData={wishlistItemData}
              isLoading={
                isLoadingPrice ||
                isLoadingPriceAlt ||
                productPriceData?.wishlistHasProduct === undefined
              }
              wishlistStatus={wishlistStatus}
              ga4Props={wishlistGa4Props}
              isDisabled={!isAddToCartEnabled}
            />
          }
        />
      )}
      <ProductPriceContainer
        isMobile={isMobile}
        isAffirm={!hasCustomUpholstery && !isDSO && !isDOO}
      >
        <ProductPrice
          {...currentPrice}
          salePriceColor={pdpSettings?.salePriceColor}
          removeDecimalsIfZero={true}
        />
      </ProductPriceContainer>
      {!isDSO && !isDOO && (
        <AffirmWrapper>
          <AffirmPromoMessage
            amount={currency?.(currentPrice?.formattedListPrice)
              ?.multiply?.(currentCount)
              ?.format?.()}
            pageType="product"
            data-sku={currentChildSku || sku}
            data-category={department}
          />
        </AffirmWrapper>
      )}
      {!!productPriceData?.promoMessages?.length && (
        <ProductPromoMessages
          pdpHasErrorMessage={
            (pdpHasErrorMessage &&
              (!hasCustomUpholsteryProductTypes ||
                customUpholstery?.otherOptions.length === 0)) ||
            !orderedVariations ||
            orderedVariations.length === 0
          }
        >
          {productPriceData.promoMessages.map((promoMessage, id) => (
            <PromoMessages
              key={id}
              displayBlock={true}
              {...promoMessage}
              width={addToCartWidth}
              maxWidth={addToCartWidth}
            />
          ))}
        </ProductPromoMessages>
      )}
      {description && isQuickview && (
        <ProductDescription>
          <RichText
            richText={[description]}
            themeName={productOptionTheme?.productDescription?.themeName}
          />
        </ProductDescription>
      )}

      {specialProductMessage && (
        <PDPSpecialMessage specialProductMessage={specialProductMessage} />
      )}

      {hasVariations &&
        orderedVariations.map((variation, ind) => {
          if (variation?.name !== "Color") {
            return (
              <VariationTiles
                key={`variation-tile-${variation?.name}-${ind}`}
                {...variation}
                productType={productType}
                productInventories={inventories}
                childProducts={childProducts}
                currentVariations={currentVariations}
                setCurrentVariations={setCurrentVariations}
                name={variation?.name}
                hasCustomUpholstery={hasCustomUpholstery}
                multipleVariations={multipleVariations}
                pdpHasErrorMessage={
                  pdpHasErrorMessage &&
                  (!hasCustomUpholsteryProductTypes ||
                    customUpholstery?.otherOptions.length === 0)
                }
              />
            );
          } else {
            return (
              <ColorTiles
                key={`color-tile-${variation?.name}-${ind}`}
                {...variation}
                netsuiteId={netsuiteId}
                childProducts={childProducts}
                currentVariations={currentVariations}
                setCurrentVariations={setCurrentVariations}
                name={variation?.name}
                pdpHasErrorMessage={pdpHasErrorMessage}
              />
            );
          }
        })}

      {madeToOrderFabrics?.length > 0 && (
        <FabricSelector
          madeToOrderFabrics={madeToOrderFabrics}
          quickshipFabrics={quickshipFabrics}
          selectedSwatch={selectedSwatch}
          selectedSwatchList={selectedSwatchList}
          initialSwatchDescription={initialSwatchDescription}
          setSelectedSwatchList={setSelectedSwatchList}
          swatchHeadings={swatchHeadings}
          imgUrl={imgUrl}
          currentProductPrice={currentProductPrice}
          topLineVariation={topLineVariation}
          cuOptions={cuOptions}
          currentVariations={currentVariations}
          productId={id}
          filters={customUpholstery?.filters}
          eddData={eddData}
          showEdd={showEdd}
          price={swatchPrice}
          isEdit={isEdit}
          isLoading={isLoading}
          cartPopupHeader={cartPopupHeader}
          fabricGuideCta={pdpSettings?.fabricGuideCta}
          orderSwatchesMessage={pdpSettings?.orderSwatchesMessage}
          handleLoadAllFabricImages={handleLoadAllFabricImages}
        />
      )}
      {hasCustomUpholsteryProductTypes &&
        customUpholstery?.otherOptions?.map(({ name, values }) => {
          return (
            <ColorTiles
              key={name}
              isCuProduct={true}
              name={name}
              options={values}
              netsuiteId={netsuiteId}
              pdpHasErrorMessage={pdpHasErrorMessage}
              currentVariations={currentVariations}
              setCurrentVariations={setCurrentVariations}
            />
          );
        })}
      <React.Fragment>
        {!itemsAddedToCart &&
          currentCount > totalAvailable &&
          !hasCustomUpholstery && (
            <ProductErrorMessage
              errorMessage={`Oops. We only have ${totalAvailable} of those available. Please adjust those quantities.`}
            />
          )}
        {!!itemsAddedToCart &&
          currentCount + itemsAddedToCart > totalAvailable &&
          !hasCustomUpholstery && (
            <ProductErrorMessage
              errorMessage={`Oops. You already added ${itemsAddedToCart} and we only have ${
                totalAvailable - itemsAddedToCart
              } more of those available. Please adjust those quantities.`}
            />
          )}
        {isEdit && cartUpdateError && (
          <ProductErrorMessage errorMessage={cartUpdateError} />
        )}
      </React.Fragment>

      <ProductEDD showEdd={showEdd} eddData={eddData} />
      <ProductOptionBottomContainer>
        <div>
          <CTA
            cta={{
              onCtaClick: setClickData,
              ctaText: "View Full Details",
              url: `/products/${slug}/${
                currentChildNetsuiteId || netsuiteId || initialMpn
              }`
            }}
            themeName="Secondary Button"
          />
        </div>

        <QtyAndAddContainer ref={addToCartRef}>
          <PdpQuantitySpinnerContainer>
            <QuantitySpinner
              spinner={quantitySpinnerProps}
              totalAvailable={totalAvailable}
              value={currentCount}
              setValue={setCurrentCount}
            />
          </PdpQuantitySpinnerContainer>

          <AddToCartContainer>
            <AddToCart
              available={isAddToCartEnabled}
              isLoadingPrice={isLoadingPrice}
              addToCart={handleAddToCart}
              isEdit={isEdit}
              isLoading={isLoading}
              currentCount={currentCount}
              source="pdp-addtocart-"
            />
          </AddToCartContainer>
        </QtyAndAddContainer>
        {ctaCalloutLink && (
          <CTACalloutLink href={ctaCalloutLink} target="_blank">
            {ctaCalloutText}
          </CTACalloutLink>
        )}
      </ProductOptionBottomContainer>
    </ProductOptionsContainer>
  );
};

export default ProductOptions;

ProductOptions.propTypes = {
  cartItemId: PropTypes.string,
  cartPopupHeader: PropTypes.object,
  crossSellProducts: PropTypes.object,
  imageUrl: PropTypes.string,
  initialMpn: PropTypes.string,
  initialProductId: PropTypes.string,
  initialQty: PropTypes.number,
  initialSku: PropTypes.string,
  inventories: PropTypes.object,
  isEdit: PropTypes.bool,
  mobileBreakpoint: PropTypes.string,
  openModal: PropTypes.func,
  pdpProps: PropTypes.object,
  swatchHeadings: PropTypes.object,
  isQuickview: PropTypes.bool,
  theme: PropTypes.object,
  clickData: PropTypes.object,
  setCartItem: PropTypes.func,
  pdpSettings: PropTypes.object,
  imgUrl: PropTypes.string
};
