import React from "react";
import PropTypes from "prop-types";
import {
  OptionsContainer,
  TabContainer,
  FreqContainer,
  FreqTab,
  FreqBar,
  ProgressBar
} from "../../EmailSubscriptionStyled";

const Bar = ({ handleTab, isDaily }) => {
  return (
    <OptionsContainer>
      <TabContainer>
        <FreqContainer>
          <FreqTab onClick={(e) => handleTab(e, "daily")}>DAILY</FreqTab>
          <FreqTab onClick={(e) => handleTab(e, "weekly")} textAlign="center">
            WEEKLY
          </FreqTab>
        </FreqContainer>
        <FreqBar>
          <ProgressBar isDaily={isDaily} />
        </FreqBar>
      </TabContainer>
    </OptionsContainer>
  );
};

export default Bar;

Bar.propTypes = {
  handleTab: PropTypes.func,
  isDaily: PropTypes.bool
};
