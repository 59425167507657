import styled from "styled-components";
import {
  Colors,
  FontSizes,
  FontWeights,
  Typography
} from "../../../constants/constants";

export const AffirmMessage = styled.p`
  ${({ promoRendered }) => !promoRendered && "display: none;"}
  margin-top: 0.5em;
  margin-bottom: 2em;
  width: 80%;
  color: ${Colors["Midnight"]};
  font-size: 12px;
  letter-spacing: 0.5px;
  font-style: normal;
  line-height: 18px;
  margin: 1em 0;

  .affirm-ala-price {
    font-weight: ${FontWeights["bold"]};
  }

  .affirm-modal-trigger {
    font-size: ${FontSizes["Base"]};
    text-decoration: underline;
    font-family: ${Typography.Beatrice};
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0.025em;
    box-sizing: unset;
    color: ${Colors["Midnight"]};

    :hover {
      color: ${Colors["Medium Primary"]};
      outline: ${Colors["Dark Primary"]} solid 1px;
    }
  }
  font-family: ${Typography.BeatriceLight};
  ${({ messageCustomStyles }) => messageCustomStyles}
`;
