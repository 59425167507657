import React from "react";
import ModularColumn from "../ModularColumn";
import ContentWrapper from "../ContentWrapper";

const extraChars = 5;

const ModularContent = (content) => {
  const { _meta: meta } = content || {};
  const { schema } = meta || {};
  const schemaParts = schema?.split?.("/");
  const componentNameExtra = schemaParts?.[schemaParts.length - 1];
  const contentType = componentNameExtra?.slice?.(
    0,
    componentNameExtra.length - extraChars
  );

  switch (contentType) {
    case "ModularColumn":
      return <ModularColumn {...content} />;
    case "ContentWrapper":
      return (
        <ContentWrapper
          marginTop={content?.marginTop}
          marginBottom={content?.marginBottom}
          marginLeft={content?.marginLeft}
          marginRight={content?.marginRight}
          {...content}
        >
          <ModularColumn {...content} />
        </ContentWrapper>
      );
    default:
      return null;
  }
};

export default ModularContent;
