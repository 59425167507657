import React from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import { MODAL_Z_INDEX } from "../NewUserModal/constants";
import { Colors } from "@serenaandlily/constants/constants";
import { CloseNew } from "../Icons/Variants";
import { WishlistModalWrapper, CloseButton } from "./WishlistModalStyled";
import { AnimatePresence, motion } from "framer-motion";

const WishlistModal = ({ isOpen, onClose, children }) => {
  const modalVariants = {
    hidden: { opacity: 0, y: "-10%" },
    visible: { opacity: 1, y: "0%" },
    exit: { opacity: 0, y: "-10%" }
  };
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      style={{
        overlay: {
          backgroundColor: Colors["Transparent Gray"],
          inset: "0%",
          zIndex: MODAL_Z_INDEX
        },
        content: {
          top: "50%",
          left: "50%",
          right: "unset",
          height: "fit-content",
          border: 0,
          borderRadius: 20,
          padding: 0,
          backgroundColor: Colors.Cloud,
          transform: "translate(-50%, -50%)"
        }
      }}
    >
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={modalVariants}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <WishlistModalWrapper>
              <CloseButton
                type="button"
                onClick={onClose}
                data-testid="close-modal"
              >
                <CloseNew />
              </CloseButton>
              {children}
            </WishlistModalWrapper>
          </motion.div>
        )}
      </AnimatePresence>
    </ReactModal>
  );
};

WishlistModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default WishlistModal;
