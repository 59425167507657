import apolloClient from "../client";
import { buildGetProductEddQuery } from "../queries";

const fetchProductEddById = async (variables) => {
  if (!apolloClient) return;
  const GET_PRODUCT_EDD = buildGetProductEddQuery(variables);
  const { data } = await apolloClient.query({
    query: GET_PRODUCT_EDD,
    variables,
    context: { clientName: "endpoint2" }
  });
  return data;
};

export default fetchProductEddById;
