const findSelectedFabric = (setSelectedSwatch, fabricLists = [], mpn = "") => {
  for (const list of fabricLists) {
    if (list?.length) {
      for (const currentFabric of list) {
        if (currentFabric?.netsuiteId === mpn) {
          setSelectedSwatch(currentFabric);
          return true;
        }
      }
    }
  }
};

export default findSelectedFabric;
