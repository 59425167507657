const defaultTheme = {
  productTileFlag: {
    themeName: "headline6"
  },
  productTileName: {
    themeName: "textSmall"
  },
  variationLabel: {
    themeName: "textSmall"
  },
  variationValue: {
    themeName: "textSmall"
  },
  price: {
    themeName: "textSmall"
  },
  tradePrice: {
    themeName: "textSmall",
    fontColor: "#527296",
    fontWeight: "600"
  },
  productPriceTheme: {
    listedPrice: {
      themeName: "textSmall",
      marginBottom: 5
    },
    salesPrice: {
      themeName: "textSmall"
    },
    tradePrice: {
      themeName: "textSmall",
      fontColor: "#527296",
      fontWeight: "600"
    },
    inventoryErrorMessage: {
      typography: "textSmall",
      fontColor: "Crimson",
      fontSize: "11px",
      fontSizeTablet: "11px",
      fontSizeDesktop: "11px"
    }
  }
};

export default defaultTheme;
