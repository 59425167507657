import { Colors, Typography } from "@serenaandlily/constants/constants";

const defaultTheme = {
  fabricTitle: {
    tag: "h3",
    fontFamily: "PP Fragment",
    fontColor: "Midnight",
    fontSize: "18px",
    fontSizeTablet: "18px",
    fontSizeDesktop: "24px"
  },
  fabricDescription: {
    themeName: "fabricDescription",
    fontSizeDesktop: 14,
    fontSize: 14,
    fontSizeTablet: 14,
    fontColor: Colors.Midnight,
    fontFamily: Typography.BeatriceLight
  },
  fabricTypeTitle: {
    tag: "h2",
    fontFamily: Typography.BeatriceRegular,
    fontColor: Colors["Midnight 70%"],
    letterSpacing: "0.04em",
    fontSize: "10px",
    fontSizeTablet: "12px",
    fontSizeDesktop: "12px",
    fontWeight: "600"
  }
};

export default defaultTheme;
