import { useCallback } from "react";
import useSWRMutation from "swr/mutation";
import apiClient from "../utils/api/apiClient";
import { useWishlistContext } from "@serenaandlily/contexts/WishlistContext";
import { isItemInWishlist } from "@serenaandlily/components/WishlistHeart/utils/isItemInWishlist";
import { mutate } from "swr";
import { constructWishlistQueryKey } from "./useWishlist";
import { GA4Events } from "@serenaandlily/hooks/analytics/GA4Events";
import useGA4 from "@serenaandlily/hooks/analytics/useGA4";
import useUser from "@lib/useUser";
import { useCart } from "@serenaandlily/contexts/CartContext";
import { useWishlistGa4 } from "@serenaandlily/hooks/useWishlistGa4";
import { useWishlistUserCreds } from "@serenaandlily/hooks/useWishlistUserCreds";

// eslint-disable-next-line max-lines-per-function
const useAddToWishlistMutation = ({ itemData, ga4Props }) => {
  const { trackGA4Event } = useGA4();
  const { setOptimisticAddedItems, setOptimisticRemovedItems } =
    useWishlistContext();

  const addOptimisticItem = useCallback(() => {
    setOptimisticAddedItems?.((prev) => [...prev, itemData]);
    setOptimisticRemovedItems?.((prev) =>
      prev?.filter?.(
        (item) => !isItemInWishlist({ item: itemData, wishlistItems: [item] })
      )
    );
  }, [itemData]);

  const removeOptimisticItem = useCallback(() => {
    setOptimisticRemovedItems?.((prev) => [...prev, itemData]);
    setOptimisticAddedItems?.((prev) =>
      prev?.filter?.(
        (item) => !isItemInWishlist({ item: itemData, wishlistItems: [item] })
      )
    );
  }, [itemData]);

  const setOptimisticItems = useCallback(() => {
    if (itemData?.quantity >= 1) {
      addOptimisticItem();
    } else {
      removeOptimisticItem();
    }
  }, [addOptimisticItem, removeOptimisticItem]);

  const revertOptimisticItems = useCallback(() => {
    if (itemData?.quantity >= 1) {
      removeOptimisticItem();
    } else {
      addOptimisticItem();
    }
  }, [addOptimisticItem, removeOptimisticItem]);

  const { user } = useUser();
  const cart = useCart();
  const { wishlistUserCreds } = useWishlistUserCreds();
  const queryKey = constructWishlistQueryKey({
    ...wishlistUserCreds
  });

  const { wishlistGa4Item } = useWishlistGa4({
    ga4ItemProps: ga4Props
  });

  const updateWishlistNote = useCallback(
    async (_url, { arg }) => {
      const items = itemData;
      const { noteValue, isEditing, isRemoving, isAdding } = arg;
      items[0].note = noteValue ? noteValue.trim() : "";
      const data = await apiClient.post("/api/wishlist/addNote", {
        items,
        ...wishlistUserCreds
      });
      if (data?.errors?.length) throw new Error(error);
      const updatedItems = data?.data?.wishlist?.folders?.[0]?.items;
      if (isAdding) {
        trackGA4Event(GA4Events.favorites_add_a_note, wishlistGa4Item);
      } else if (isEditing) {
        trackGA4Event(GA4Events.favorites_edit_a_note, wishlistGa4Item);
      } else if (isRemoving) {
        trackGA4Event(GA4Events.favorites_remove_a_note, wishlistGa4Item);
      }
      mutate(
        queryKey,
        (currentData) => {
          if (!currentData?.getWishlist?.folders?.length) {
            return currentData;
          }

          const currentFolder = currentData.getWishlist.folders?.[0];
          return {
            ...currentData,
            getWishlist: {
              ...currentData.getWishlist,
              folders: [
                {
                  ...currentFolder,
                  items: updatedItems
                }
              ]
            }
          };
        },
        false
      );
      return data;
    },
    [itemData, wishlistUserCreds]
  );

  const updateWishlist = useCallback(async () => {
    setOptimisticItems();
    const { data, error } = await apiClient.post(
      itemData?.quantity >= 1
        ? "/api/wishlist/addItemsToWishlist"
        : "/api/wishlist/removeItemsFromWishlist",
      {
        items: itemData,
        location: window.location.pathname,
        ...wishlistUserCreds
      }
    );
    if (error) {
      revertOptimisticItems();
      throw new Error(error);
    }
    const methodName =
      itemData?.quantity >= 1 ? "addItemsToWishlist" : "updateItemsInWishlist";
    const updatedItems = data?.wishlist?.[methodName]?.folders?.[0]?.items;
    if (
      !(
        data?.wishlist?.[methodName]?.customerEmail ||
        data?.wishlist?.[methodName]?.guestToken
      )
    ) {
      revertOptimisticItems();
      return;
    }

    // indicates that folders were not requested in gql call
    if (!data?.wishlist?.[methodName]?.folders) return data;

    mutate(
      queryKey,
      (currentData) => {
        if (!currentData?.getWishlist?.folders?.length) {
          return currentData;
        }

        const currentFolder = currentData.getWishlist.folders?.[0];
        return {
          ...currentData,
          getWishlist: {
            ...currentData.getWishlist,
            folders: [
              {
                ...currentFolder,
                items: updatedItems
              }
            ]
          }
        };
      },
      false
    );
    return data;
  }, [itemData, cart?.cartId, wishlistUserCreds, queryKey]);

  const onSuccessCallback = useCallback(
    (data) => {
      const methodName =
        itemData?.quantity >= 1
          ? "addItemsToWishlist"
          : "updateItemsInWishlist";
      if (
        !(
          data?.wishlist?.[methodName]?.customerEmail ||
          data?.wishlist?.[methodName]?.guestToken
        )
      )
        return;
      if (itemData?.quantity >= 1) {
        trackGA4Event(GA4Events.item_favorited, wishlistGa4Item);
      } else {
        trackGA4Event(GA4Events.item_un_favorited, wishlistGa4Item);
      }
    },
    [
      setOptimisticAddedItems,
      setOptimisticRemovedItems,
      itemData,
      trackGA4Event,
      ga4Props,
      user?.data?.userType,
      user?.data?.isLoggedIn
    ]
  );

  const { data, error, isMutating, trigger } = useSWRMutation(
    "/api/wishlist/addItemsToWishlist" + wishlistUserCreds?.customerEmail,
    updateWishlist,
    {
      optimisticData: (newData) => {
        return newData;
      },
      onSuccess: onSuccessCallback
    }
  );

  const {
    data: noteData,
    error: errorNote,
    isMutating: isMutatingNote,
    trigger: triggerNoteUpdate
  } = useSWRMutation("/api/wishlist/addnotes", updateWishlistNote);

  return {
    data: data?.wishlist,
    error,
    isMutating,
    trigger,
    noteData,
    errorNote,
    isMutatingNote,
    triggerNoteUpdate
  };
};

export default useAddToWishlistMutation;
