import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  SkipToMainAccessibilityButton,
  SkipToMainAccessibilityMessage
} from "./SkipToMainAccessibilityStyled";

const SkipToMainAccessibility = ({onClick}) => {
  const ENTER_KEY = 13;
  const SPACE_KEY = 32;
  const [focused, setFocused] = useState(false);

  const handleClick = (e) => {
    if (e.keyCode !== ENTER_KEY && e.keyCode !== SPACE_KEY) return;
    if (focused) {
      onClick();
    }
  };

  return (
    <SkipToMainAccessibilityButton
      tabindex="0"
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onKeyDown={handleClick}
    >
      <SkipToMainAccessibilityMessage>
        SKIP TO MAIN
      </SkipToMainAccessibilityMessage>
    </SkipToMainAccessibilityButton>
  );
};

export default SkipToMainAccessibility;
SkipToMainAccessibility.propTypes = {
  onClick: PropTypes.func
}
