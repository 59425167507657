import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Image, Wrapper } from "./CarouselImageStyled";
import getAssetUrl from "../../utils/getAssetUrl";

const CarouselImage = ({ image, altText, layout, url }) => {
  const imageSrc = getAssetUrl(image);
  const Container = url ? Wrapper : Fragment;
  const imageProps = url ? { href: url } : null;
  return (
    <Container {...imageProps}>
      <Image
        src={imageSrc}
        alt={altText}
        layout={layout || "fill"}
        imageData={image}
      />
    </Container>
  );
};

export default CarouselImage;

CarouselImage.propTypes = {
  image: PropTypes.object,
  layout: PropTypes.string,
  altText: PropTypes.string,
  url: PropTypes.string
};
