import React, { useEffect, useState } from "react";
import ProductDisplay from "../ProductDisplay";
import PropTypes from "prop-types";
import { ProductDataProvider } from "../../contexts/ProductDataContext";
import {
  ProductDisplayLoading,
  ProductDisplayWrapper
} from "./MiniPdpPopupStyled";
import { useCartUpdate } from "../../contexts/CartContext";
import Icons from "../Icons";
import ICON_TYPES from "../Icons/constants";
import { useGlobalProducts } from "../../contexts/GlobalProductsContext";
import { ProductProvider } from "../../contexts/ProductContext";
import { useProductInventories } from "../../hooks/useProductInventories";
import AnalyticsData from "../AnalyticsData";
import useUser from "../../hooks/useUser";

const MiniPdpPopup = ({
  cartItemId,
  initialProductId,
  initialQty,
  isEdit,
  productId,
  slug,
  useMpn,
  list,
  tileId,
  productSpecification,
  productTileData,
  preSelectedSwatch,
  setCartItem
}) => {
  const { isLoading, addToBagHeader, addToBagCrossellHeader, isAddLoading } =
    useCartUpdate();
  const [realProductId, setRealProductId] = useState();
  const {
    loadingProducts,
    fetchNewProductById,
    fetchNewProductBySlug,
    getProductById,
    getProductBySlug
  } = useGlobalProducts();
  const user = useUser();

  useEffect(() => {
    globalThis?.document?.body?.dispatchEvent(
      new CustomEvent("a-page-view", { detail: { user } })
    );
  }, []);

  useEffect(() => {
    (async () => {
      if (slug) {
        const productBySlug = await fetchNewProductBySlug(slug);
        setRealProductId(productBySlug);
      } else {
        const productById = await fetchNewProductById(productId, useMpn);
        setRealProductId(productById);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, slug]);

  let productIsLoading = false;
  let currentProduct = {};
  if (slug) {
    productIsLoading = loadingProducts[slug];
    currentProduct = getProductBySlug(slug);
  } else {
    productIsLoading = loadingProducts[realProductId];
    currentProduct = getProductById(realProductId);
  }

  const iconType = ICON_TYPES["CircularLoader"];
  const { data: inventories } = useProductInventories(realProductId);

  return (!isLoading || isAddLoading) &&
    !productIsLoading &&
    currentProduct?.id ? (
    <ProductDisplayWrapper>
      <AnalyticsData
        className={`a-product-click-${tileId}`}
        data={{ currentProduct, list, productSpecification, productTileData }}
      />
      <ProductDataProvider>
        <ProductProvider
          productId={currentProduct?.id}
          initialMpn={productId}
          pdpProps={currentProduct}
          productInventories={inventories}
          preSelectedSwatch={preSelectedSwatch}
        >
          <ProductDisplay
            {...ProductDisplay}
            pdpProps={{ ...currentProduct, inventories }}
            initialMpn={productId}
            mobileBreakpoint={"DesktopMobile"}
            isQuickview={true}
            isEdit={isEdit}
            cartItemId={cartItemId}
            initialQty={initialQty}
            initialProductId={initialProductId}
            setCartItem={setCartItem}
            cartPopupHeader={addToBagHeader}
            clickData={{
              currentProduct,
              list,
              productSpecification,
              productTileData
            }}
            crossSellProducts={{
              crossSellProducts: currentProduct?.crossSellProducts,
              crossSellHeading: addToBagCrossellHeader
            }}
          />
        </ProductProvider>
      </ProductDataProvider>
    </ProductDisplayWrapper>
  ) : (
    <ProductDisplayLoading>
      <Icons type={iconType} />
    </ProductDisplayLoading>
  );
};

export default MiniPdpPopup;

MiniPdpPopup.propTypes = {
  cartItemId: PropTypes.string,
  initialProductId: PropTypes.string,
  initialQty: PropTypes.string,
  isEdit: PropTypes.bool,
  productId: PropTypes.string,
  props: PropTypes.object,
  slug: PropTypes.string,
  useMpn: PropTypes.bool,
  list: PropTypes.string,
  tileId: PropTypes.string,
  productSpecification: PropTypes.object,
  productTileData: PropTypes.object,
  setCartItem: PropTypes.func
};
