const defaultMax = 999;
//eslint-disable-next-line complexity
const validateSpinnerAmount = (amount, min = 0, max = defaultMax) => {
  if (amount === "") return true;
  const regexp = /^\d*$/;
  return (
    regexp.test(amount) && parseInt(amount) <= max && parseInt(amount) >= min
  );
};

export default validateSpinnerAmount;
