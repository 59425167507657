export default {
  themeName: "default",
  accordion: {
    accordionTitle: {
      typography: "headline5",
      tag: "span"
    },
    accordionSummary: {
      typography: "textSmall",
      marginBottom: 20
    },
    spacing: {
      verticalSpacing: 13,
      horizontalSpacing: 0
    },
    borderOptions: {
      borderThickness: 1,
      borderColor: "Midnight 40%"
    },
    icons: {
      expandIcon: "PlusLight",
      collapseIcon: "MinusLight"
    }
  },
  price: {
    themeName: "headline6",
    tag: "p",
    borderColor: "Light Primary"
  },
  productCarousel: {
    borderColor: "Light Primary"
  },
  productCallout: {
    themeName: "headline6",
    borderColor: "black"
  }
};
