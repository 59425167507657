import { useRef } from "react";
import PropTypes from "prop-types";
import {
  MediaLinksSectionContainer,
  IconsWrapper,
  PrintWrapper,
  PrintButton,
  PrintIconWrap,
  PrintText
} from "./MediaLinksSectionStyled";
import ReactToPrint from "react-to-print";
import PDPPrint from "../PDPPrint";
import PrintIconV2 from "./PrintIconV2";

const MediaLinksSection = ({ pdpPrintData }) => {
  const printRef = useRef();

  return (
    <MediaLinksSectionContainer>
      <IconsWrapper>
        <PrintWrapper>
          <ReactToPrint
            trigger={() => (
              <PrintButton data-pdpprint="false">
                <PrintText>PRINT</PrintText>
                <PrintIconWrap>
                  <PrintIconV2 />
                </PrintIconWrap>
              </PrintButton>
            )}
            content={() => printRef.current}
          />
          <PDPPrint ref={printRef} {...pdpPrintData} />
        </PrintWrapper>
      </IconsWrapper>
    </MediaLinksSectionContainer>
  );
};

export default MediaLinksSection;

MediaLinksSection.propTypes = {
  mediaSectionCtas: PropTypes.array,
  image: PropTypes.object,
  pageUrl: PropTypes.string,
  pdpPrintData: PropTypes.object
};
