const buildCUOptions = (currentVariations) => {
  const cuOptions = [];
  const cuVariations = Object.values(currentVariations);
  cuVariations.forEach((variation) => {
    if (variation.optionName) {
      const option = {
        name: variation.optionName,
        value: variation.name
      };
      cuOptions.push(option);
    }
  });
  return cuOptions;
};

export default buildCUOptions;
