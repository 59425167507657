const getVariationObj = (currentVariations, variationKeys) => {
  const variationObj = {};
  for (const key of variationKeys) {
    variationObj[key] = currentVariations[key]?.description;
  }
  return variationObj;
};

const getCurrentVariationChildIDs = (currentVariations, childProducts) => {
  if (currentVariations && childProducts) {
    const variationKeys = Object.keys(currentVariations);
    const variationObj = getVariationObj(currentVariations, variationKeys);

    for (const childProduct of childProducts) {
      let numToMatch = Object.keys(currentVariations)?.length;

      const childProductVariants = childProduct?.variants;
      for (const childProductVariant of childProductVariants) {
        const { name, value } = childProductVariant;
        if (variationObj[name] === value) numToMatch--;
      }

      if (numToMatch === 0) {
        return {
          currentChildImageSet: childProduct?.imageSet,
          currentChildNetsuiteId: childProduct?.netsuiteId,
          currentChildPID: childProduct?.id,
          currentChildSku: childProduct?.sku,
          currentChildProduct: childProduct
        };
      }
    }
  }

  return {};
};

export default getCurrentVariationChildIDs;
