import styled from "styled-components";
import { Colors, Breakpoints, Shadows } from "../../constants/constants";
import { ImageBorder } from "../SwatchImage/SwatchImageStyled";
import {
  CUFiltersWrapper,
  FilterTitleSection
} from "../CUFilters/CUFiltersStyled";

export const OrderSwatchesPanelWrapper = styled.div`
  max-width: 100%;
  width: auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: ${({ showShadow }) => (showShadow ? Shadows.FabricSlideIn : "0")};
  height: 100%;
  background-color: ${Colors.White};
  box-sizing: border-box;
  z-index: 9999999999;

  ${CUFiltersWrapper} {
    ${FilterTitleSection} {
      margin-bottom: 16px;

      @media screen and (min-width: ${Breakpoints.IPad}) and (max-width: ${Breakpoints.ScreenWidth}) {
        margin-bottom: 24px;
      }
    }
  }

  @media screen and (min-width: ${Breakpoints.XSMobileMin}) {
    height: ${({ headerOffset }) =>
      headerOffset ? `calc(100dvh - ${headerOffset}px)` : `100dvh`};
  }

  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    height: 100%;
  }

  @media screen and (max-width: ${Breakpoints.IPad}) {
    padding: 16px;
  }

  @media screen and (min-width: ${Breakpoints.IPad}) and (max-width: ${Breakpoints.ScreenWidth}) {
    max-width: 480px;
    width: 480px;
  }

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    max-width: 480px;
    width: 480px;
  }

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    max-width: 584px;
    min-width: 584px;
    height: 100%;
  }
`;

export const SwatchWrapper = styled.div`
  background-color: ${Colors.White};
  height: 100%;
  z-index: ${({ zIndex }) => zIndex || "999"};
  position: relative;
`;

export const OrderSwatchPanelTitle = styled.div`
  margin-bottom: 19px;

  @media screen and (max-width: ${Breakpoints.IPad}) {
    margin-bottom: 8px;
  }

  @media screen and (min-width: ${Breakpoints.IPad}) and (max-width: ${Breakpoints.ScreenWidth}) {
    margin-bottom: 23px;
  }
`;

export const OrderSwatchPanelSubtitle = styled.div`
  @media screen and (min-width: ${Breakpoints.IPad}) {
    display: none;
  }
`;

export const OrderSwatchesContainer = styled.div`
  flex-basis: 100%;

  overflow: auto;

  margin-bottom: 50px;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.Fog};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors["Midnight 70%"]};
    border-radius: 5px;
    border: 1px solid ${Colors.White};
  }
`;

export const SwatchSection = styled.div`
  margin-bottom: 12px;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.Fog};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors["Midnight 70%"]};
    border-radius: 5px;
    border: 1px solid ${Colors.White};
  }
`;

export const SwatchSetionTitle = styled.div`
  margin-bottom: 12px;
`;

export const SwatchesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

export const ImageContainer = styled.button`
  cursor: pointer;
  color: none;
  border: none;
  background-color: ${Colors.White};
  padding: 0;

  ${ImageBorder} {
    width: 74px;
    height: 74px;
  }
`;

export const PriceAndApply = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;

  display: block;
  z-index: 9999999999;

  background-color: ${Colors.White};

  padding: 16px;

  box-shadow: ${({ showBoxShadow }) =>
    showBoxShadow ? ` ${Shadows.PriceAndApply}` : "none"};

  @media screen and (min-width: ${Breakpoints.SMTabletMin}) and (max-width: ${Breakpoints.SMTabletMax}) {
    width: ${480 - 32 * 2}px;
    padding: 16px 32px;
  }

  @media screen and (min-width: ${Breakpoints.MDLaptopMin}) {
    width: ${584 - 32 * 2}px;
    padding: 16px 32px;
  }

  a {
    width: 100%;
  }
`;

export const FabricDescription = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  box-sizing: border-box;

  max-height: 374px;

  padding: 8px 16px 16px 16px;

  background-color: ${Colors.Cloud};

  border-top: 1px solid ${Colors["Fabric Flyout Border Grey"]};
  box-shadow: ${Shadows.FabricDetails};

  @media screen and (min-width: ${Breakpoints.SMTabletMin}) {
    padding: 8px 24px 24px 24px;

    min-width: 480px;
    max-height: 424px;
    height: 424px;
  }

  /* 
  Overflow anomaly for FabricDescription happening at 768px (and only 768px) when the below margins are set. 
  Out of time for a thorough fix. Hack solution below - for now.
  TODO - Fix this CSS margin overflow issue.
  */
  @media screen and (min-width: 769px) {
    margin: 0 -24px;
  }

  @media screen and (max-width: ${Breakpoints.SMTabletMin}) {
    right: -16px;
    left: -16px;
  }
`;

export const FabricDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 100%;
  height: 312px;

  overflow-y: ${({ fabricDetailsExpanded }) =>
    fabricDetailsExpanded ? "auto" : "hidden"};

  box-sizing: border-box;
  z-index: 99999;

  @media screen and (min-width: ${Breakpoints.SMTabletMin}) {
    height: 345px;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.Fog};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors["Midnight 70%"]};
    border-radius: 5px;
    border: 1px solid ${Colors.White};
  }

  > * {
    &:last-child {
      margin-bottom: 16px;
    }
  }
`;

export const DescriptionTextWrapper = styled.div`
  flex-grow: 1;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
`;

export const TitleTextWrapper = styled.div``;

export const FabricDetailsHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-bottom: 16px;

  > svg:first-of-type {
    flex-basis: 100%;
  }
`;

export const ApplyCTAContainer = styled.div`
  a {
    width: 100%;
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const PriceTitle = styled.div``;

export const Price = styled.div``;

export const FabricTypeWrapper = styled.div`
  margin-bottom: 24px;

  @media screen and (max-width: ${Breakpoints.XSMobileMax}) {
    margin-bottom: 18px;
  }
`;
