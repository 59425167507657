/* eslint-disable complexity */
import React, { useState, useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  SlideOutContainer,
  SlideOutNav,
  SubNavItemButton,
  SlideOutListItem,
  ItemsWrapper,
  BlankSpace,
  MobileMenuContainer
} from "./SlideOutMenuStyled";
import { useBreakpoint } from "../Breakpoints";
import CTA from "../CTA";
import defaultTheme from "./SlideOutMenuTheme";
import useComponentTheme from "../Theme/useComponentTheme";
import SlideMenuItem from "../SlideMenuItem";
import FlyoutMenu from "../FlyoutMenu";
import getPrimarySelected from "./utils/getPrimarySelected";
import getSecondarySelected from "./utils/getSecondarySelected";
import AccordionMenu from "../AccordionMenu";
import MobileSlideOutCTAs from "../MobileSlideOutCTAs";
import SlideOutMenuTabs from "./SlideOutMenuTabs";
import debounce from "lodash.debounce";
import { SLIDE_OUT_MENU_DEBOUNCE_TIME } from "../../constants/constants";
import { useSlideOutMenuContext } from "../../contexts/SlideOutMenuContext";
import { fetchByIds } from "../../utils/amplience";
import { useMarketing } from "../../contexts/MarketingContext";
import { executeOnEnterKey } from "@utils/keyboard/executeOnEnterKey";

export const flyoutWidth = {
  main: 266,
  secondary: 532,
  tertiary: 832
};

// eslint-disable-next-line max-lines-per-function
const SlideOutMenu = ({
  useSlideoutOnDesktop,
  isOpen,
  headerHeight,
  hoverColor,
  slideOutMenuRefs,
  slideOutMenuItems,
  loggedIn,
  setSlideWidth,
  setIsOpen,
  setCurrentHeight,
  currentHeight,
  scrollHeight,
  isSMBSticky,
  selectedCategories,
  setSelectedCategories
}) => {
  const {
    activeMobileSlideOutIdx,
    setActiveMobileSlideOutIdx,
    secondaryFlyout,
    setSecondaryFlyout,
    isVisible,
    setIsVisible,
    tertiaryFlyout,
    setTertiaryFlyout,
    expandedItems,
    setExpandedItems,
    expandedSubItems,
    setExpandedSubItems,
    secondaryFlyoutSrc,
    setSecondaryFlyoutSrc,
    tertiaryyFlyoutSrc,
    setTertiaryyFlyoutSrc,
    showFixedSlide,
    setShowFixedSlide,
    accordionScrollValue,
    fixedElemententsHeight,
    slideOutMenuContent,
    setSlideOutMenuContent,
    hoverEnabled,
    setHoverEnabled
  } = useSlideOutMenuContext();
  const { handleLogSlideoutClick } = useMarketing();
  const slideOutMenu =
    (slideOutMenuContent && slideOutMenuContent[activeMobileSlideOutIdx]) ||
    null;
  const [ariaLabelBtn, setAriaLabelBtn] = useState("");
  const [ariaExpandedBtn, setAriaExpandedBtn] = useState(false);
  const theme = slideOutMenu?.categoriesTheme || defaultTheme;
  const slideOutMenuTheme = useComponentTheme({ theme });
  const ref = useRef(null);
  const slideoutContainerRef = useRef(null);
  const breakpoints = useBreakpoint();
  const wrapperRef = useRef();
  const flyoutLevel = {
    main: "main",
    secondary: "secondary",
    tertiary: "tertiary"
  };
  const isSmallMobile = breakpoints["IPad"];

  useEffect(() => {
    async function getSlideOutMenuContent() {
      const slideOfMenuIds = slideOutMenuRefs?.map((ref) => ref?.id);
      const slideOutMenuContent = await fetchByIds(slideOfMenuIds);
      setSlideOutMenuContent(slideOutMenuContent);
    }
    getSlideOutMenuContent();
  }, []);

  const closeSlide = useCallback(() => {
    setIsVisible(false);
    setIsOpen(false);
    setSecondaryFlyout();
    setSlideWidth(flyoutWidth.main);
    setActiveMobileSlideOutIdx(0);
    setHoverEnabled(false);
  }, []);

  const handleClickOutside = (event) => {
    const isHorizontalNav =
      event.target.className.includes("HorizontalNavItem");
    if (
      isSmallMobile &&
      ref.current &&
      !ref.current.contains(event.target) &&
      !isHorizontalNav
    ) {
      setExpandedItems([]);
      setExpandedSubItems([]);
    }

    const slideOutContainer = document.querySelector(".menu-open-mobile");
    const menuButton = document.querySelector(".menu-button");
    const isClickedInside = slideOutContainer.contains(event.target);
    const isMenuButtonClicked = menuButton.contains(event.target);
    const slideout = document.querySelector(".slide-out-container");

    if (
      !isMenuButtonClicked &&
      !isClickedInside &&
      !isSmallMobile &&
      !isHorizontalNav
    ) {
      slideout.scrollTop = 0;
      closeSlide();
    } else {
      document.body.style.overflow = "hidden";
    }
  };

  useEffect(() => {
    const handleResize = () => {
      updateCurrentHeight();
    };

    const updateCurrentHeight = () => {
      const { innerHeight } = window;
      setCurrentHeight(innerHeight);
    };

    updateCurrentHeight();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const hasScrolled = window.scrollY > headerHeight;
    hasScrolled !== showFixedSlide && setShowFixedSlide(hasScrolled);
  });

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("click", handleClickOutside);
    }
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  // Focus Trapping
  useEffect(() => {
    if (isOpen) {
      const modalElement = slideoutContainerRef?.current;
      const focusableElements = modalElement.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      const menuButtonEl = document.querySelectorAll(
        ".menu-button > button"
      )[0];
      const firstElement = focusableElements?.[0];
      const lastElement = focusableElements?.[focusableElements.length - 1];

      const handleTabKeyPress = (event) => {
        if (event.key === "Tab") {
          if (document?.activeElement === menuButtonEl) {
            event.preventDefault();
            firstElement?.focus?.();
          } else if (
            event.shiftKey &&
            document.activeElement === firstElement
          ) {
            event.preventDefault();
            lastElement?.focus?.();
          } else if (
            !event.shiftKey &&
            document.activeElement === lastElement
          ) {
            event.preventDefault();
            menuButtonEl?.focus?.();
          }
        }
      };

      const handleEscapeKeyPress = (event) => {
        if (event.key === "Escape") {
          setIsOpen(false);
          event.preventDefault();
          menuButtonEl?.focus?.();
        }
      };

      document.addEventListener("keydown", handleTabKeyPress);
      document.addEventListener("keydown", handleEscapeKeyPress);

      return () => {
        document.removeEventListener("keydown", handleTabKeyPress);
        document.removeEventListener("keydown", handleEscapeKeyPress);
      };
    }
  }, [isOpen, setIsOpen, secondaryFlyout, tertiaryFlyout, isVisible]);

  const flyoutItem = { secondary: "secondaryItems", tertiary: "tertiaryItems" };

  const addDefaultFlyoutImage = (item, level) => {
    const isSecondaryFlyout = level === flyoutLevel.main;
    const flyoutItems = isSecondaryFlyout
      ? item?.flyoutCategories
      : item?.secondaryFlyout;
    const itemImage = isSecondaryFlyout
      ? flyoutItems.find((e) => e?.category?.categoryImage)
      : flyoutItems.find((e) => e?.categoryImage);
    isSecondaryFlyout &&
      setSecondaryFlyoutSrc(itemImage?.category?.categoryImage);
    !isSecondaryFlyout && setTertiaryyFlyoutSrc(itemImage?.categoryImage);
  };

  const addFlyoutImage = (item, level) => {
    const isSecondaryFlyout = level === flyoutLevel.secondary;
    const flyoutSrc = isSecondaryFlyout
      ? item?.category?.categoryImage
      : item?.categoryImage;
    isSecondaryFlyout && setSecondaryFlyoutSrc(flyoutSrc);
    !isSecondaryFlyout && setTertiaryyFlyoutSrc(flyoutSrc);
  };

  const mouseOverMainSlide = debounce((item) => {
    setSecondaryFlyout(item);
    setTertiaryFlyout();
    const visibility = item?.flyoutCategories;
    setIsVisible(visibility);
    visibility && addDefaultFlyoutImage(item, flyoutLevel.main);
    setAriaExpandedBtn(true);
    if (visibility) {
      setSlideWidth(flyoutWidth.secondary);
    } else {
      setSlideWidth(flyoutWidth.main);
    }
  }, SLIDE_OUT_MENU_DEBOUNCE_TIME);

  const mouseOverSecondarySlide = (item) => {
    addFlyoutImage(item, flyoutLevel.secondary);
    const hasTertiary = item?.secondaryFlyout;
    setTertiaryFlyout(item?.secondaryFlyout);
    hasTertiary && addDefaultFlyoutImage(item, flyoutLevel.secondary);
    if (hasTertiary) {
      setSlideWidth(flyoutWidth.tertiary);
    } else {
      setSlideWidth(flyoutWidth.secondary);
    }
  };

  const activateSlide = (item, level) => {
    switch (level) {
      case flyoutLevel.main:
        mouseOverMainSlide(item);
        break;
      case flyoutLevel.secondary:
        mouseOverSecondarySlide(item);
        break;
      case flyoutLevel.tertiary:
        addFlyoutImage(item, level);
        break;
      default:
        return;
    }
  };

  const handleOnMouseOver = (item, level, skipValidation) => {
    if ((isSmallMobile || !hoverEnabled) && !skipValidation) {
      return;
    }
    activateSlide(item, level);
  };

  const handleKeyDownOpenSlide = (e, item, level) => {
    if (isSmallMobile) {
      return;
    }
    if (e.key === "Enter") {
      activateSlide(item, level);
    }
  };

  const primarySelected = getPrimarySelected(secondaryFlyout);
  const secondarySelected = getSecondarySelected(
    secondaryFlyout,
    tertiaryFlyout
  );

  const handleClickFinalDesktopElement = (item) => {
    if (
      !(
        (item.secondaryFlyout && item.secondaryFlyout.length > 0) ||
        (item.flyoutCategories && item.flyoutCategories.length > 0)
      )
    ) {
      handleLogSlideoutClick({
        category: slideOutMenu?.categoriesSectionName,
        subCategory: primarySelected,
        subcategoryTwo:
          secondarySelected && secondarySelected !== ""
            ? secondarySelected
            : item?.category?.categoryName?.ctaText,
        subcategoryThree:
          secondarySelected || secondarySelected !== ""
            ? item?.categoryName?.ctaText
            : null
      });
    }
  };

  const handleOnCLickMobile = (item, idx, type) => {
    handleClickFinalDesktopElement(item);
    if (type === flyoutItem.secondary) {
      setSecondaryFlyout(item);
      const categoriesIdx = expandedItems?.includes(idx)
        ? removeIdx(expandedItems, idx)
        : [...expandedItems, idx];
      setExpandedItems([...new Set(categoriesIdx)]);
    } else {
      setTertiaryFlyout(item?.secondaryFlyout);
      const categoriesIdx = expandedSubItems?.includes(idx)
        ? removeIdx(expandedSubItems, idx)
        : [...expandedSubItems, idx];
      setExpandedSubItems([...new Set(categoriesIdx)]);
    }
  };

  const handleClickFinalMobileElement = (finalItem) => {
    const category = slideOutMenu?.categoriesSectionName;
    const subCategory = selectedCategories[0];
    const subcategoryTwo = selectedCategories[1]
      ? selectedCategories[1]
      : finalItem;
    const subcategoryThree = selectedCategories[1] ? finalItem : null;
    handleLogSlideoutClick({
      category,
      subCategory,
      subcategoryTwo,
      subcategoryThree
    });
  };

  const removeIdx = (idxList, idx) => {
    return idxList.filter((i) => i !== idx);
  };

  const handleAriaButton = (item) => {
    setAriaLabelBtn(item?.SectionTitle?.ctaText);
  };

  const closeSubNav = (level) => {
    switch (level) {
      case flyoutLevel.main:
        setIsVisible(false);
        setAriaExpandedBtn(false);
        setSlideWidth(flyoutWidth.main);
        break;
      case flyoutLevel.secondary:
        setTertiaryFlyout();
        setSlideWidth(flyoutWidth.secondary);
        break;
      default:
        return;
    }
  };

  const tabbable = isOpen ? 0 : -1;

  const mobileArrow = breakpoints["ScreenWidth"];

  const setCtaIcon = (hasCategories) => {
    if (mobileArrow && isSmallMobile) {
      return hasCategories ? "CaretDownLight" : "";
    } else if (mobileArrow && !isSmallMobile) {
      return hasCategories ? "CaretRightLight" : "";
    } else {
      return hasCategories ? "ArrowRight" : "";
    }
  };

  useEffect(() => {
    wrapperRef?.current?.scrollTo({
      top: accordionScrollValue,
      left: 0,
      behavior: "smooth"
    });
  }, [accordionScrollValue]);

  const ToggleMenu = () => {
    return (
      <React.Fragment>
        {secondaryFlyout?.flyoutCategories?.map((subItem, idx) => {
          const isExpanded =
            expandedSubItems?.includes(idx) &&
            tertiaryFlyout === subItem?.secondaryFlyout;
          const subItemName = subItem?.category?.categoryName?.ctaText;
          const selected = isExpanded ? "toggle" : "";
          return (
            <React.Fragment key={`slide-item-${subItemName}`}>
              <SlideOutListItem
                hoverColor={hoverColor}
                hoverEnabled={hoverEnabled}
                onClick={() =>
                  handleOnCLickMobile(subItem, idx, flyoutItem.tertiary)
                }
                className={selected}
              >
                <SubNavItemButton
                  aria-label={`show menu for ${ariaLabelBtn} category`}
                  aria-expanded={ariaExpandedBtn}
                  onFocus={() => handleAriaButton(subItem)}
                  tabIndex={tabbable}
                >
                  <CTA
                    key={`slide-item-cta-${subItemName}`}
                    themeName={slideOutMenuTheme?.themeName}
                    theme={slideOutMenuTheme}
                    cta={{
                      ctaText: subItem?.category?.categoryName?.ctaText,
                      url: subItem?.secondaryFlyout
                        ? "#"
                        : subItem?.category?.categoryName?.url
                    }}
                    iconOnHover={true}
                    ctaIcon={setCtaIcon(subItem?.secondaryFlyout || false)}
                  />
                </SubNavItemButton>
              </SlideOutListItem>
              {isExpanded && (
                <React.Fragment>
                  {subItem?.secondaryFlyout?.map((cta) => {
                    const ctaName = cta?.categoryName?.ctaText;

                    return (
                      <SlideOutListItem
                        hoverColor={hoverColor}
                        key={`subItem-toggle-mobile-${ctaName}`}
                      >
                        <CTA
                          key={`slide-tertiary-cta-${ctaName}`}
                          themeName={slideOutMenuTheme?.themeName}
                          theme={slideOutMenuTheme}
                          cta={{
                            ctaText: cta?.categoryName?.ctaText,
                            url: cta?.categoryName?.url
                          }}
                          iconOnHover={true}
                        />
                      </SlideOutListItem>
                    );
                  })}
                </React.Fragment>
              )}
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };

  let menuOffset = 117;
  let stickyHeight = 117;
  if (!isSMBSticky) {
    menuOffset = 45;
    if (scrollHeight < 75) menuOffset = 117;
    stickyHeight = 45;
  }

  return (
    <React.Fragment>
      <SlideOutContainer
        onMouseMove={() => setHoverEnabled(true)}
        className={
          isOpen
            ? "menu-open-mobile slide-out-container"
            : "slide-out-container"
        }
        useSlideoutOnDesktop={useSlideoutOnDesktop}
        headerHeight={headerHeight}
        slideOutMenuHeight={currentHeight - Number(headerHeight)}
        slideOutMenuHeightMobile={currentHeight - fixedElemententsHeight}
        isOpen={isOpen}
        showFixedSlide={showFixedSlide}
        isSmallMobile={isSmallMobile}
        menuOffset={menuOffset}
        stickyHeight={stickyHeight}
        ref={slideoutContainerRef}
      >
        {isSmallMobile && (
          <MobileMenuContainer
            ref={wrapperRef}
            className={"mobile-menu-container"}
          >
            <AccordionMenu
              slideOutMenu={slideOutMenu}
              hoverColor={hoverColor}
              onClickFinalElement={handleClickFinalMobileElement}
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
            />
            <div>
              <MobileSlideOutCTAs
                loggedIn={loggedIn}
                showFixedSlide={showFixedSlide}
                {...slideOutMenu?.mobileSlideOutCtas}
              />
            </div>
          </MobileMenuContainer>
        )}

        {!isSmallMobile && (
          <React.Fragment>
            <SlideOutNav>
              <ItemsWrapper ref={ref}>
                {slideOutMenu?.categorySection?.map((item, idx) => {
                  const isExpanded =
                    expandedItems?.includes(idx) &&
                    secondaryFlyout === item &&
                    isSmallMobile
                      ? "toggle"
                      : "";
                  const itemName = item?.category?.ctaText;
                  const isSelected = itemName === primarySelected;
                  const hasUrl = item?.category?.url;

                  return (
                    <React.Fragment key={`slide-item-${itemName}`}>
                      <SlideOutListItem
                        hoverColor={hoverColor}
                        hoverEnabled={hoverEnabled}
                        onMouseOver={() =>
                          handleOnMouseOver(item, flyoutLevel.main)
                        }
                        onMouseLeave={() => mouseOverMainSlide.cancel()}
                        onClick={() =>
                          handleOnCLickMobile(item, idx, flyoutItem.secondary)
                        }
                        onKeyDown={(e) =>
                          executeOnEnterKey(e, () =>
                            handleOnMouseOver(item, flyoutLevel.main, true)
                          )
                        }
                        isSelected={isSelected}
                        className={isExpanded}
                      >
                        <SubNavItemButton
                          aria-label={`show menu for ${ariaLabelBtn} category`}
                          aria-expanded={ariaExpandedBtn}
                          onFocus={() => handleAriaButton(item)}
                          tabIndex={hasUrl ? -1 : tabbable}
                        >
                          <CTA
                            themeName={slideOutMenuTheme?.themeName}
                            theme={slideOutMenuTheme}
                            cta={{
                              ctaText: item?.category?.ctaText,
                              url: item?.flyoutCategories
                                ? "#"
                                : item?.category?.url,
                              onCtaClick: item?.flyoutCategories
                                ? (e) => {
                                    e.preventDefault();
                                    document.body.style.overflow = "hidden";
                                  }
                                : () => {},
                              tabIndex: hasUrl ? tabbable : -1
                            }}
                            iconOnHover={true}
                            selectedCategory={primarySelected}
                            ctaIcon={setCtaIcon(
                              item?.flyoutCategories || false
                            )}
                          />
                        </SubNavItemButton>
                      </SlideOutListItem>
                      {expandedItems?.includes(idx) &&
                        secondaryFlyout === item &&
                        isSmallMobile && <ToggleMenu />}
                    </React.Fragment>
                  );
                })}
                {!loggedIn && slideOutMenuItems && (
                  <SlideMenuItem {...slideOutMenuItems} />
                )}
              </ItemsWrapper>
              <BlankSpace onMouseEnter={() => closeSubNav(flyoutLevel.main)} />
            </SlideOutNav>
          </React.Fragment>
        )}

        {!isSmallMobile && (
          <React.Fragment>
            <FlyoutMenu
              key={`secondary-slide`}
              flyoutCategories={secondaryFlyout?.flyoutCategories}
              hoverColor={hoverColor}
              handleOnMouseOver={handleOnMouseOver}
              handleKeyDownOpenSlide={handleKeyDownOpenSlide}
              handleOnCLickMobile={handleOnCLickMobile}
              handleAriaButton={handleAriaButton}
              setCtaIcon={setCtaIcon}
              closeSubNav={closeSubNav}
              isVisible={secondaryFlyout?.flyoutCategories && isVisible}
              level={flyoutLevel.secondary}
              flyoutLevel={flyoutLevel}
              ariaExpandedBtn={ariaExpandedBtn}
              tabbable={tabbable}
              slideOutMenuTheme={slideOutMenuTheme}
              secondaryFlyoutSrc={secondaryFlyoutSrc}
              tertiaryyFlyoutSrc={tertiaryyFlyoutSrc}
              secondarySelected={secondarySelected}
              closeSlide={closeSlide}
            />
            <FlyoutMenu
              key={`tertiary-slide`}
              flyoutCategories={tertiaryFlyout}
              hoverColor={hoverColor}
              handleOnMouseOver={addFlyoutImage}
              handleKeyDownOpenSlide={handleKeyDownOpenSlide}
              handleOnCLickMobile={handleOnCLickMobile}
              handleAriaButton={handleAriaButton}
              setCtaIcon={setCtaIcon}
              closeSubNav={closeSubNav}
              isVisible={isVisible && tertiaryFlyout}
              level={flyoutLevel.tertiary}
              flyoutLevel={flyoutLevel}
              ariaExpandedBtn={ariaExpandedBtn}
              tabbable={tabbable}
              slideOutMenuTheme={slideOutMenuTheme}
              secondaryFlyoutSrc={secondaryFlyoutSrc}
              tertiaryyFlyoutSrc={tertiaryyFlyoutSrc}
              closeSlide={closeSlide}
            />
          </React.Fragment>
        )}
      </SlideOutContainer>
      <SlideOutMenuTabs
        slideOutMenus={slideOutMenuContent}
        isOpen={isOpen}
        setSelectedCategories={setSelectedCategories}
        setActiveMobileSlideOutIdx={setActiveMobileSlideOutIdx}
        activeMobileSlideOutIdx={activeMobileSlideOutIdx}
        slideOutMenuTheme={slideOutMenuTheme}
        menuTabsTop={currentHeight}
      />
    </React.Fragment>
  );
};

export default SlideOutMenu;

SlideOutMenu.propTypes = {
  topNavContent: PropTypes.array,
  inspirationsCta: PropTypes.object,
  useSlideoutOnDesktop: PropTypes.bool,
  isOpen: PropTypes.bool,
  headerHeight: PropTypes.number,
  hoverColor: PropTypes.string,
  menuButton: PropTypes.object,
  slideOutMenu: PropTypes.object,
  slideOutMenuRefs: PropTypes.array,
  scrollHeight: PropTypes.number,
  isSMBSticky: PropTypes.bool,
  selectedCategories: PropTypes.object,
  setSelectedCategories: PropTypes.func,
  theme: PropTypes.object,
  setSlideWidth: PropTypes.func,
  setIsOpen: PropTypes.func,
  setCurrentHeight: PropTypes.func,
  currentHeight: PropTypes.number,
  slideOutMenuItems: PropTypes.object,
  loggedIn: PropTypes.bool,
  debounceTime: PropTypes.number
};
