import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  VariationTilesContainer,
  VariationTilesFieldset,
  TilesWrapper,
  Tile,
  TileLabel,
  Category,
  Variation
} from "./VariationTilesStyled";
import { useProductContext } from "../../contexts/ProductContext";
import uuid from "react-uuid";
import Text from "../Text";
import defaultTheme from "./VariationTilesTheme";
import useComponentTheme from "../Theme/useComponentTheme";
import getListOfRelatedProductIds from "./utils/getListOfRelatedProductIds";
import getTileAvailability from "./utils/getTileAvailability";
import getCrossAvailability from "./utils/getCrossAvailability";
import { TileInput } from "../FormComponents";

const VariationTiles = ({
  name,
  options,
  childProducts,
  currentVariations,
  setCurrentVariations,
  hasCustomUpholstery,
  multipleVariations,
  setUnavailableTileSelected,
  setChangeToNextAvailable,
  setTileClicked,
  theme = defaultTheme
}) => {
  const fieldName = `dwvar_${name}`;
  const category = name;
  const tileId = useMemo(() => uuid(), []);
  const currentVariation = currentVariations && currentVariations[name];
  const { availability, setVariationsHaveChanged } = useProductContext();

  const variationTilesTheme = useComponentTheme({ theme });
  const variationName = name;

  const handleChange = (description, name) => {
    setCurrentVariations((prev) => ({
      ...prev,
      [name]: { name, description }
    }));
    setVariationsHaveChanged(true);
  };
  return (
    <VariationTilesContainer>
      <VariationTilesFieldset>
        <Category>
          <Text
            copy={`${category} `}
            themeName={variationTilesTheme?.categoryName?.themeName}
            theme={variationTilesTheme?.categoryName?.overrides}
          />
          &nbsp;
          <Variation>
            <Text
              copy={currentVariation?.description}
              themeName={variationTilesTheme?.categoryType?.themeName}
            />
          </Variation>
        </Category>
        <TilesWrapper>
          {options.map((tile, ind) => {
            const { description, name } = tile;
            const activeTitle = description === currentVariation?.description;
            const variationId = `${category?.toLowerCase()}-${name}-${tileId}`;
            const listOfRelatedProductIds = getListOfRelatedProductIds(
              childProducts,
              description,
              variationName
            );
            let isAvailable = true;

            const tileAvailable = getTileAvailability(
              listOfRelatedProductIds,
              availability
            );

            const crossTileAvailability = getCrossAvailability({
              tileAvailable,
              category,
              currentVariations,
              childProducts,
              listOfRelatedProductIds,
              hasCustomUpholstery
            });

            if (
              !tileAvailable ||
              (!crossTileAvailability && multipleVariations)
            ) {
              isAvailable = false;
            }

            const tileFontFamily = "BeatriceRegular";

            return (
              <Tile
                key={`variation-tile-${name}-${ind}`}
                activeTitle={activeTitle}
                title={description}
                isAvailable={isAvailable}
              >
                <TileInput
                  name={fieldName}
                  id={variationId}
                  value={description}
                  checked={activeTitle}
                  category={category}
                  disabled={!isAvailable}
                  setUnavailableTileSelected={setUnavailableTileSelected}
                  setChangeToNextAvailable={setChangeToNextAvailable}
                  setTileClicked={setTileClicked}
                  isColorTile={false}
                  onChange={() => handleChange(description, variationName)}
                />
                <TileLabel htmlFor={variationId}>
                  <Text
                    copy={description}
                    themeName={variationTilesTheme?.variationTile?.themeName}
                    fontFamily={tileFontFamily}
                    lineHeight="18px"
                  />
                </TileLabel>
              </Tile>
            );
          })}
        </TilesWrapper>
      </VariationTilesFieldset>
    </VariationTilesContainer>
  );
};

export default VariationTiles;

VariationTiles.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  options: PropTypes.array,
  productType: PropTypes.string,
  currentVariations: PropTypes.object,
  setCurrentVariations: PropTypes.func,
  theme: PropTypes.object,
  childProducts: PropTypes.array,
  hasCustomUpholstery: PropTypes.object,
  setUnavailableTileSelected: PropTypes.func,
  setChangeToNextAvailable: PropTypes.func,
  setTileClicked: PropTypes.func,
  multipleVariations: PropTypes.bool
};
