// COMPONENT IMPORTS //
import Text from "../../../Text";

// STYLED IMPORTS //
import {
  FabricAttribute,
  FabricAttributesWrapper
} from "./FabricFeaturesStyled";

// THEME IMPORTS //
import { attributeText } from "../../../FabricPanel/typography/themes";

const FabricFeatures = ({ fabricFeatures = [] }) => {
  // Variables //
  const hasFabricFeatures = fabricFeatures?.length > 0;

  if (!hasFabricFeatures) {
    return null;
  }

  return (
    <FabricAttributesWrapper>
      {fabricFeatures?.map((feature, idx) => (
        <FabricAttribute key={`${feature?.displayName}-${idx}`}>
          <img
            src={feature?.imageUrl}
            alt={`icon for ${feature?.displayName} fabric attribute`}
          />
          <Text copy={feature?.displayName} theme={attributeText} />
        </FabricAttribute>
      ))}
    </FabricAttributesWrapper>
  );
};

export default FabricFeatures;
