import styled from "styled-components";
import { Breakpoints } from "../../constants/constants";

export const LinksSectionFlyoutContainer = styled.div`
  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    padding: 0 120px;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  @media screen and (max-width: ${Breakpoints.ScreenWidth}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    flex-direction: column;
  }
`;

export const TextWrapper = styled.div`
  & * div {
    white-space: nowrap;
  }
  & > h3 {
    white-space: nowrap;
  }
`;

export const CTAWrapper = styled.div`
  @media screen and (max-width: ${Breakpoints.ScreenWidth}) {
    flex-basis: calc((1 / 3) * 100%);
  }
`;
