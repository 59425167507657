import styled from "styled-components";
import { Breakpoints, Colors, Typography } from "../../constants/constants";

export const Container = styled.div``;

export const InputBox = styled.textarea`
  height: ${({ height }) =>  `${height}px`};
  resize: ${({ resize }) => resize};
  font-family: ${({fontFamily}) => fontFamily};
  width: ${({textBoxWidth})=> textBoxWidth === 100 ? `-webkit-fill-available` : `${textBoxWidth}%`};
  background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : "transparent" };
  color: ${({fontColor}) => fontColor};
  fontSize: ${({fontSize}) => fontSize};
  line-height: ${({lineHeight}) => lineHeight};
  disabled: ${({disabled}) => disabled};
  border-radius: ${({borderRadius}) => `${borderRadius}px`};
  border-width: 1px;
  border-color: ${({borderColor, hasError}) => hasError ? Colors["Error Sale"] : `${borderColor}` };
  padding: ${({padding}) => padding};
  box-sizing: border-box;

  &::placeholder {
    color: ${Colors.Cream};
    font-family: ${Typography.BeatriceLight};
  }

  &:focus-visible {
    outline: ${({ textBoxFocusOutline }) => textBoxFocusOutline};
  }

  @media screen and (min-width: ${Breakpoints.IPad}) {
    height: ${({ tabletHeight }) =>  `${tabletHeight}px`};
    fontSize: ${({fontSizeTablet}) => fontSizeTablet};

  }

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    height: ${({ desktopHeight }) =>  `${desktopHeight}px`};
    fontSize: ${({fontSizeDesktop}) => fontSizeDesktop};
  }
`;
