import styled from "styled-components";

export const Tile = styled.div`
  ${({ theme }) => `
    display: block;
    width: ${theme?.forms?.tileSizeMedium};
    height: ${theme?.forms?.tileSizeMedium};
    &.tile-small {
      width: ${theme?.forms?.tileSizeSmall};
      height: ${theme?.forms?.tileSizeSmall};
    }
    &.tile-large {
      width: ${theme?.forms?.tileSizeLarge};
      height: ${theme?.forms?.tileSizeLarge};
    }
  `}
`;

export const Label = styled.label`
  ${({ theme }) => `
    box-sizing: border-box;
    border-width: ${theme?.forms?.tileBorderWidth};
    border-style: solid;
    border-color: transparent;
    display: block;
    padding: ${theme?.forms?.tilePadding};
    width: 100%;
    height: 100%;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    > * {
      overflow: hidden;
      background-size: contain;
      background-repeat: no-repeat;
    }
  `}
`;

// to get padding from parent element applied to absolute element
export const DisabledScreenWrapper = styled.div`
  position: absolute;
  padding: inherit;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;
`;

export const DisabledScreen = styled.div`
  ${({ theme }) => `
    background: ${theme?.forms?.tileDisabledBackground};  
    cursor: pointer;
    height: 100%;
    width: 100%;
    color: transparent;
  `}
`;

export const Input = styled.input`
  ${({ theme }) => `
    cursor: pointer;
    position:absolute;
    opacity: 0;
    &:checked + ${Label} {
      border-color: ${theme?.forms?.tileBorderColorSelected}
    }
    &:focus + ${Label},
    &.focus + ${Label}  {
      outline: 2px solid ${theme?.forms?.controlFocusColor}
    }
    &:hover + ${Label},
    &:hover + ${Label} {
      border-color: ${theme?.forms?.tileBorderColorHover}
    }
  `}
`;

export const VariationInput = styled.input`
  border: 0;
  clip: rect(0, 0, 0, 0);
  margin: 0px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  height: 100%;
  width: 100%;
`;
