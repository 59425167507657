import "react-tooltip/dist/react-tooltip.css";

import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef
} from "react";
import PropTypes from "prop-types";
import uuid from "react-uuid";
import { useGlobalPopupUpdate } from "../GlobalPopup/GlobalPopupContext";
import { useGlobalProducts } from "../../contexts/GlobalProductsContext";
import CTA from "../CTA";
import TileSwatches from "./TileSwatches";
import MiniPdpPopup from "../MiniPdpPopup/MiniPdpPopup";
import { getProcessedImageSrc } from "./getProcessedImageSrc";
import Text from "../Text";
import {
  ProductTileContainer,
  ProductTileImg,
  WishlistButtonContainer,
  ImageCtaWrapper,
  ProductTileImgCallout,
  ProductTileHeading,
  ProductTileName,
  ProductTileNewFlag,
  ProductTileImgWrapper,
  ProductTilePrice,
  ProductDiscountPrice,
  PromoContainer,
  NoteIconContainer,
  EditContainer,
  EditButton,
  NoteTooltipWrapper,
  PriceWrapper,
  TradePriceWrapper
} from "./ProductTileStyled";
import defaultTheme from "./ProductTileTheme";
import useComponentTheme from "../Theme/useComponentTheme";
import { useProductPrice } from "../../hooks/useProductPrice";
import useUser from "../../hooks/useUser";
import { RETAIL_PRICE_LABEL, TRADE_PRICE_LABEL } from "../../constants/strings";
import Link from "next/link";
import getPriceDiscount from "../../utils/getPriceDiscount/getPriceDiscount";
import { useDiscountContext } from "../../contexts/ProductDiscountContext";
import PromoMessages from "../Promos/PromoMessages";
import stringToBaseUrl from "../../../utils/stringToBaseUrl";
import removeZeroDecimals from "@utils/removeZeroDecimals/removeZeroDecimals";
import { useGA4 } from "@serenaandlily/hooks/analytics/useGA4";
import {
  getDiscountValue,
  selectViewItemProduct,
  getWishlistItemFromTile
} from "@serenaandlily/hooks/analytics/GA4Helpers";
import { GA4Events } from "@serenaandlily/hooks/analytics/GA4Events";
import NoteIcon from "../NoteIcon";
import { Tooltip } from "react-tooltip";
import EditProductTileIcon from "../EditProductTileIcon";
import DropdownMenu from "../DropdownMenu";
import AddNoteModal from "../AddNoteModal";
import ProductTileDiscounts from "./ProductTileDiscounts";
import WishlistHeart from "../WishlistHeart";
import RemoveFromWishlistModal from "../RemoveFromWishlistModal";
import { useContent } from "@serenaandlily/contexts/ContentContext";
import { useWishlistEnabled } from "@serenaandlily/hooks/useWishlistEnabled";
import ContentLoader from "react-content-loader";
import { Colors } from "@serenaandlily/constants/constants";
import { useWishlistGa4 } from "@serenaandlily/hooks/useWishlistGa4";

const CTA_QUICK_SHOP = "QUICK SHOP";

const imageSizes = {
  mobile: {
    width: 253,
    height: 316
  },
  tablet: {
    width: 284,
    height: 356
  },
  desktop: {
    width: 290,
    height: 364
  }
};

const getImageSize = ({ isMobile, isTablet }) => {
  if (isMobile) {
    return imageSizes.mobile;
  }
  if (isTablet) {
    return imageSizes.tablet;
  }
  return imageSizes.desktop;
};

const productTileDefaults = {
  mobile: {
    productNameMarginBottom: 8,
    promoMessageDefaultTextSize: 12
  },
  tablet: {
    productNameMarginBottom: 8,
    promoMessageDefaultTextSize: 12
  },
  desktop: {
    productNameMarginBottom: 8,
    promoMessageDefaultTextSize: 14
  }
};
const getProductTileDefaults = ({ isMobile, isTablet }) => {
  if (isMobile) {
    return productTileDefaults.mobile;
  }
  if (isTablet) {
    return productTileDefaults.tablet;
  }
  return productTileDefaults.desktop;
};
//eslint-disable-next-line complexity, max-lines-per-function
const ProductTile = ({
  dynamicProductTile,
  productSpecification,
  variations,
  productCta,
  removeDecimalsIfZero,
  productDetails,
  productTileData,
  hideCta,
  flyout = false,
  tileConfigs,
  squareImageConfigs,
  largeFlyout = false,
  source,
  list = null,
  addToCartCallback,
  detailList,
  isWishlist,
  theme = defaultTheme,
  maxNoteLength,
  setModalComponent,
  customUpholsteryOptions,
  position,
  disableWishlist = false,
  isSortClp = false,
  isMobile = false,
  isTablet = false
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const wishlistHeartRef = useRef(null);
  const { trackGA4Event } = useGA4();
  const buttonRef = useRef(null);
  const { getProductTileById } = useGlobalProducts();
  const { wishlistConfigs } = useContent();
  const { isWishlistEnabled, isUserLoading } =
    useWishlistEnabled(wishlistConfigs);
  const isWishlistAndUnavailable = useMemo(
    () =>
      isWishlist &&
      productSpecification?.product?.isAvailableForPurchase === false,
    [isWishlist, productSpecification?.product?.isAvailableForPurchase]
  );
  const wishlistFlagEnabled = isWishlistEnabled && !isUserLoading;

  const tileId = useMemo(() => uuid(), []);
  const [currentDiscount, setCurrentDiscount] = useState({});
  const visibleSwatchLimit = 4;
  const { tileWidth = "2 Column", internalLinkID } = dynamicProductTile || {};
  const {
    discountText,
    uptoDiscountText,
    colorDiscountText,
    colorDiscountValue,
    discountThreshold,
    enableShowDiscount
  } = useDiscountContext();

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [buttonRef, dropdownRef]);

  const isDiscount = useMemo(
    () =>
      currentDiscount?.value &&
      enableShowDiscount === "true" &&
      currentDiscount?.value >= discountThreshold,
    [currentDiscount, discountThreshold, enableShowDiscount]
  );

  const {
    product,
    disableRollover,
    showRolloverImage,
    promoMessages: specificiationPromoMessages
  } = productSpecification || {};
  const showRolloverImageActive = showRolloverImage && !flyout && !largeFlyout;
  const { ctaCopy, url } = productCta || {};
  const {
    showPrice = true,
    showNewFlags = true,
    showColorBuckets = true
  } = productDetails || {};
  const {
    productCallout: amplienceCallout,
    marketingName,
    productId,
    variationId
  } = product || {};

  const {
    moreTextConfigs,
    priceConfigs,
    productTitleConfigs,
    imageProductCallout,
    swatchType,
    imageCta
  } = tileConfigs || {};
  const tileProps = getProductTileById(productId) || {};

  const defaultChild = tileProps?.attributes?.childProducts?.find(
    (item, idx) => {
      if (idx === 0) {
        return item;
      }
    }
  );

  const { openPopup } = useGlobalPopupUpdate();
  const { user: { data: { isDOO } = {} } = {} } = useUser();
  const { data: productPriceData } = useProductPrice({
    productId: defaultChild?.id || productId,
    shouldFetch: !isWishlist
  });

  const promoMessages = useMemo(() => {
    return specificiationPromoMessages || productPriceData?.promoMessages;
  }, [productPriceData?.promoMessages, specificiationPromoMessages]);

  const { squareUrlSize, squareSizeObj } = squareImageConfigs || {};
  const cropImageParams =
    "?fmt=auto&qlt=default&fmt.jp2.qlt=60&fmt.webp.qlt=70&unsharp=0,0.3,10,0&img404=noimagemedium";
  const hasTradePrice = isDOO;

  const tilePriceRange = {
    min: productPriceData?.formattedListPrice
  };

  const tileDesignerPriceRange = {
    min: productPriceData?.formattedDsoDesigner
  };

  const {
    priceRange = tilePriceRange,
    designerPriceRange = tileDesignerPriceRange,
    salePriceRange,
    nowPriceRange,
    wasPriceRange,
    attributes,
    tileData,
    showWasNow,
    isCustomUpholstery,
    seoUrl,
    note,
    productCallout: tileDataCallout
  } = productTileData || tileProps || {};

  const productCallout = amplienceCallout || tileDataCallout;

  const { name } = attributes || {};

  const productName =
    productSpecification?.productName || marketingName || name;
  const [currentSwatchSelected, setCurrentSwatchSelected] = useState(null);
  const productUrl = seoUrl
    ? stringToBaseUrl(seoUrl)
    : `/${variationId || attributes?.netsuiteId || productId}.html`;

  const imageSize = getImageSize({ isMobile, isTablet });
  const tileDefaults = getProductTileDefaults({ isMobile, isTablet });
  const imageUrlSize =
    tileWidth === "1 Column"
      ? "&w=725&h=300&sm=c&upscale=padd"
      : `&w=${imageSize.width}&h=${imageSize.height}`;
  const imageSizeObj =
    tileWidth === "1 Column"
      ? {
          width: 725,
          height: 300
        }
      : imageSize;

  const formatPrice = (price, removeDecimalsIfZero) => {
    return removeDecimalsIfZero ? removeZeroDecimals({ price }) : price;
  };

  const formatPriceRange = (priceRange, removeDecimalsIfZero) => {
    if (!priceRange) return "";
    const minPrice = formatPrice(priceRange.min, removeDecimalsIfZero);
    const maxPrice = priceRange.max
      ? formatPrice(priceRange.max, removeDecimalsIfZero)
      : null;
    return maxPrice ? `${minPrice} - ${maxPrice}` : `${minPrice}`;
  };

  const standardPriceRange = formatPriceRange(
    isWishlist ? nowPriceRange : priceRange,
    removeDecimalsIfZero
  );
  const standardSalePriceRange = formatPriceRange(
    salePriceRange,
    removeDecimalsIfZero
  );
  const designerPriceDisplay = formatPriceRange(
    designerPriceRange,
    removeDecimalsIfZero
  );
  const wasPriceDisplay = formatPriceRange(wasPriceRange, removeDecimalsIfZero);
  const nowPriceDisplay = formatPriceRange(nowPriceRange, removeDecimalsIfZero);

  useEffect(() => {
    const { formattedSalePrice, formattedListPrice, formattedDsoDesigner } =
      productPriceData || {};

    if (showWasNow) {
      const priceDiscount = getPriceDiscount({
        wasPriceRange,
        nowPriceRange: hasTradePrice ? designerPriceRange : nowPriceRange
      });
      if (priceDiscount?.value > 0) {
        setCurrentDiscount(priceDiscount);
        return;
      }
    }

    const basePrice = hasTradePrice ? formattedDsoDesigner : formattedListPrice;
    if (formattedSalePrice && formattedSalePrice !== basePrice) {
      const salePriceNumber = parseFloat(
        formattedSalePrice.replace(/^\$/, "").replace(/,/g, "")
      );
      const basePriceNumber = parseFloat(
        basePrice.replace(/^\$/, "").replace(/,/g, "")
      );

      const discountValue = Math.round(
        ((basePriceNumber - salePriceNumber) / basePriceNumber) * 100
      );

      setCurrentDiscount({
        value: discountValue,
        isUpTo: false
      });
      return;
    }

    setCurrentDiscount(0);
  }, [
    showWasNow,
    wasPriceRange,
    nowPriceRange,
    hasTradePrice,
    productPriceData?.formattedSalePrice,
    productPriceData?.formattedListPrice,
    productPriceData?.formattedDsoDesigner
  ]);

  const [currentImageIdx, setCurrentImageIdx] = useState(0);
  const [initialImageIdx, setInitialImageIdx] = useState(0);

  const productData = { ...productSpecification, ...productTileData };
  const ga4SelectItemPayload = () => {
    return selectViewItemProduct({
      currentDiscount: getDiscountValue(
        productTileData?.priceRange?.min,
        productTileData?.nowPriceRange?.min ||
          productTileData?.salePriceRange?.min
      ),
      productSpecification,
      productData,
      itemListName: list,
      itemListId: detailList,
      position
    });
  };

  const ga4IWishlistItemPayload = useCallback(() => {
    return getWishlistItemFromTile({
      productSpecification,
      productData,
      location: tileConfigs?.source || source
    });
  }, [productSpecification, productData, tileConfigs?.source, source]);

  const onClickPopup = (e) => {
    e.preventDefault();
    localStorage.setItem("productDetailList", detailList);

    trackGA4Event(GA4Events.select_item, ga4SelectItemPayload());
    openPopup(
      <MiniPdpPopup
        productId={variationId || defaultChild?.netsuiteId || productId}
        useMpn={true}
        list={list}
        tileId={tileId}
        productSpecification={productSpecification}
        productTileData={productTileData || tileProps}
        preSelectedSwatch={currentSwatchSelected}
      />
    );
  };

  const [isHovered, setIsHovered] = useState(false);

  const tileTheme = useComponentTheme({ theme });

  const ga4ItemProps = useMemo(() => {
    return ga4IWishlistItemPayload();
  }, [ga4IWishlistItemPayload]);

  const { wishlistGa4Item, wishlistGa4ItemMoveToBag } = useWishlistGa4({
    ga4ItemProps: ga4ItemProps
  });

  const onCtaClick = (...args) => {
    if (addToCartCallback) {
      addToCartCallback(
        ...args,
        productSpecification,
        productTileData,
        customUpholsteryOptions,
        wishlistGa4ItemMoveToBag?.({
          viewType: "gallery_view",
          quantity: 1
        })
      );
    } else if (!isCustomUpholstery) {
      onClickPopup(...args);
    }
  };

  const ctaProps = {
    ctaText: ctaCopy || (isCustomUpholstery && "CUSTOMIZE") || CTA_QUICK_SHOP,
    url: url || productUrl,
    ctaAlignment: "center",
    onCtaClick
  };

  const priceRangeText = `${wasPriceDisplay || standardPriceRange}`;

  const discountedPriceRangeText = `${
    nowPriceDisplay || standardSalePriceRange
  }`;

  const clickData = {
    list,
    productSpecification,
    productTileData,
    currentProduct: {
      name: productSpecification?.product?.marketingName,
      netsuiteId: productSpecification?.product?.productId,
      productCallout: productSpecification?.product?.productCallout
    }
  };

  const [addingNote, setAddingNote] = useState(false);

  const addNote = () => {
    setAddingNote(true);
  };

  const removeFromWishlist = () => {
    // Render removeItem modal outside of product tile so it doesn't unmount when the product tile is removed
    setModalComponent?.(
      <RemoveFromWishlistModal
        key={uuid()}
        isOpen={true}
        netsuiteId={variationId || attributes?.netsuiteId || productId}
        customUpholsteryOptions={customUpholsteryOptions}
        ga4Props={ga4ItemProps}
      />
    );
    trackGA4Event(GA4Events.item_considered_un_favorited, wishlistGa4Item);
  };

  const setClickData = () => {
    const netsuiteId = productSpecification?.product?.productId;
    localStorage.setItem(
      `pdpClickData${netsuiteId}`,
      JSON.stringify({
        ...clickData
      })
    );
    localStorage.setItem("productDetailList", detailList);
    sessionStorage?.setItem("searchPrevNetsuiteId", netsuiteId);
    sessionStorage?.setItem("searchPageScroll", window.scrollY);

    trackGA4Event(GA4Events.select_item, ga4SelectItemPayload());
  };

  useEffect(() => {
    const currentVariationIdx = variations?.findIndex(
      (variation) => variation?.data?.variation_id === variationId
    );

    setInitialImageIdx(currentVariationIdx >= 0 ? currentVariationIdx : 0);
  }, [variationId, variations]);

  const discountFinalText = currentDiscount?.isUpTo
    ? uptoDiscountText
    : discountText;

  let rolloverImageSrc = productTileData?.altImage;

  const renderTilePromoMessage = (promo, idx) => {
    return (
      <PromoMessages
        key={`${promo?.message}-${promo?.fontColor}-${idx}`}
        fontSize={tileDefaults.promoMessageDefaultTextSize}
        {...promo}
      />
    );
  };

  const renderTileImage = (data, idx) => {
    let { color, imageUrl, masterImageUrl } = data;

    const imageSizeParam = squareUrlSize || imageUrlSize;

    const imageSrc = getProcessedImageSrc({
      imageUrl,
      rolloverImageSrc,
      masterImageUrl,
      isHovered,
      showRolloverImageActive,
      source,
      cropImageParams,
      imageSizeParam
    });

    return (
      <ProductTileImgWrapper
        href={productUrl}
        key={`${productName}-${color}-${idx}-${tileId}`}
        as={Link}
        isWishlist={isWishlist}
        isFirst={idx === 0}
        disableRollover={
          showRolloverImageActive && isSortClp && rolloverImageSrc
            ? true
            : disableRollover
        }
        isSelected={idx === currentImageIdx}
        tileWidth={tileWidth}
        flyout={flyout}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => setClickData()}
      >
        <ProductTileImg
          src={imageSrc}
          customWidth={imageSizeObj?.width}
          customHeight={imageSizeObj?.height}
          alt={`${productName}-${color}-image`}
          imageData={{ amplienceData: squareSizeObj || imageSizeObj }}
          layout={"intrinsic"}
        />
        {imageCta && isHovered && (
          <ImageCtaWrapper>
            <CTA cta={imageCta} useNextLink={true} />
          </ImageCtaWrapper>
        )}
        {showNewFlags && productCallout && imageProductCallout && (
          <ProductTileImgCallout>{productCallout}</ProductTileImgCallout>
        )}
        {!disableWishlist && wishlistFlagEnabled && (
          <WishlistButtonContainer>
            <WishlistHeart
              ref={wishlistHeartRef}
              itemData={{
                netsuiteId:
                  currentSwatchSelected?.id || variationId || product?.productId
              }}
              wishlistStatus={productSpecification?.wishlistStatus}
              ga4Props={ga4IWishlistItemPayload()}
              onClickOverride={isWishlist ? removeFromWishlist : null}
            />
          </WishlistButtonContainer>
        )}
      </ProductTileImgWrapper>
    );
  };

  const renderTradePrice = () => {
    const { formattedSalePrice } = productPriceData || {};

    if (designerPriceDisplay === "undefined") {
      return (
        <ContentLoader
          height={19}
          width="35%"
          backgroundColor={Colors.Cloud}
          foregroundColor={Colors.Fog}
        >
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="19" />
        </ContentLoader>
      );
    }

    return (
      <TradePriceWrapper>
        <Text
          copy={`${designerPriceDisplay} ${TRADE_PRICE_LABEL}`}
          {...tileTheme?.tradeRangeText}
          {...priceConfigs}
          fontColor={tileTheme?.tradeRangeText?.fontColor}
        />
        {formattedSalePrice && (
          <ProductTileDiscounts
            noMarginLeft={false}
            discountFinalText={discountFinalText}
            currentDiscount={currentDiscount}
            priceConfigs={priceConfigs}
            colorDiscountText={
              tileTheme?.tradeRangeText?.fontColor || colorDiscountText
            }
            theme={{ priceRangeText: theme?.tradeRangeText }}
            largeFlyout={largeFlyout}
            colorDiscountValue={
              tileTheme?.tradeRangeText?.fontColor || colorDiscountValue
            }
          />
        )}
      </TradePriceWrapper>
    );
  };

  const renderPriceWrapper = () => {
    const hasSalePrice = productPriceData?.formattedSalePrice;

    // Current discounted prices (Was/Now)
    if (showWasNow && (nowPriceDisplay || salePriceRange)) {
      return (
        <TradePriceWrapper>
          <Text
            copy={discountedPriceRangeText}
            {...theme?.discountedPriceRangeText}
            {...priceConfigs}
            fontColor={theme?.discountedPriceRangeText?.fontColor}
          />
          {isDiscount && (
            <ProductTileDiscounts
              noMarginLeft={false}
              discountFinalText={discountFinalText}
              currentDiscount={currentDiscount}
              priceConfigs={priceConfigs}
              colorDiscountText={colorDiscountText}
              theme={theme}
              largeFlyout={largeFlyout}
              colorDiscountValue={colorDiscountValue}
            />
          )}
        </TradePriceWrapper>
      );
    }

    // New render formatted sale price
    if (hasSalePrice) {
      return (
        <TradePriceWrapper>
          <Text
            copy={`${productPriceData.formattedSalePrice}`}
            {...theme?.discountedPriceRangeText}
            {...priceConfigs}
            fontColor={theme?.discountedPriceRangeText?.fontColor}
          />
          <ProductTileDiscounts
            noMarginLeft={false}
            discountFinalText={discountFinalText}
            currentDiscount={currentDiscount}
            priceConfigs={priceConfigs}
            colorDiscountText={colorDiscountText}
            theme={theme}
            largeFlyout={largeFlyout}
            colorDiscountValue={colorDiscountValue}
          />
        </TradePriceWrapper>
      );
    }

    return null;
  };

  return (
    <ProductTileContainer
      id={internalLinkID}
      tileWidth={tileWidth}
      isWishlistAndUnavailable={isWishlistAndUnavailable}
      flyout={flyout}
      data-cnstrc-item="Recommendation"
      data-cnstrc-item-id={productId}
      data-cnstrc-variation-id={variationId}
      data-cnstrc-item-name={productName}
    >
      {isWishlist && (
        <EditContainer>
          <EditButton
            type="button"
            disabled={wishlistHeartRef?.current?.isMutating}
            ref={buttonRef}
            onClick={toggleDropdown}
            aria-label="Product tile options"
          >
            <EditProductTileIcon />
          </EditButton>
          {isDropdownOpen && (
            <DropdownMenu
              ref={dropdownRef}
              hasNote={!!productTileData.note}
              onAddNote={() => {
                addNote();
                setIsDropdownOpen(false);
              }}
              onRemove={() => {
                removeFromWishlist();
                setIsDropdownOpen(false);
              }}
            />
          )}
        </EditContainer>
      )}
      {tileData?.slice(0, visibleSwatchLimit)?.map(renderTileImage)}
      {!hideCta &&
        (productCta ? (
          <CTA
            cta={ctaProps}
            theme={productCta?.theme}
            themeOverrides={productCta?.themeOverrides}
            className="product-tile-cta"
            useNextLink={true}
          />
        ) : (
          <CTA
            cta={ctaProps}
            themeName={theme?.productTileCTA?.themeName}
            themeOverrides={theme?.productTileCTA?.themeOverrides}
            className="product-tile-cta"
            useNextLink={true}
          />
        ))}

      <ProductTileHeading>
        <ProductTileName
          flyout={flyout}
          as={Link}
          href={productUrl}
          onClick={() => setClickData()}
        >
          {showNewFlags && productCallout && !imageProductCallout && (
            <ProductTileNewFlag>{productCallout}</ProductTileNewFlag>
          )}

          <Text
            copy={productName}
            themeName={tileTheme?.productName?.themeName}
            {...tileTheme?.productName?.overrides}
            {...productTitleConfigs}
            marginBottom={
              productTitleConfigs?.marginBottom ||
              tileDefaults.productNameMarginBottom
            }
          />
        </ProductTileName>
        <PriceWrapper>
          {showPrice && !showWasNow && (
            <ProductTilePrice>
              <Text
                copy={`${standardPriceRange} ${
                  hasTradePrice ? RETAIL_PRICE_LABEL : ""
                }`}
                {...tileTheme?.standardPriceRange}
                {...priceConfigs}
              />
            </ProductTilePrice>
          )}
          {showWasNow && (wasPriceDisplay || salePriceRange) && (
            <ProductDiscountPrice>
              <Text
                copy={`${priceRangeText} ${
                  hasTradePrice ? RETAIL_PRICE_LABEL : ""
                }`}
                {...theme?.priceRangeText}
                {...priceConfigs}
                largeFlyout={largeFlyout}
                fontFamily={"BeatriceLight"}
              />
            </ProductDiscountPrice>
          )}
          {hasTradePrice ? (
            renderTradePrice()
          ) : (
            <React.Fragment>{renderPriceWrapper()}</React.Fragment>
          )}
        </PriceWrapper>
      </ProductTileHeading>
      {showColorBuckets && !largeFlyout && (
        <TileSwatches
          swatchData={tileData}
          initialSelectedSwatch={initialImageIdx}
          setCurrentImageIdx={setCurrentImageIdx}
          url={ctaProps.url}
          productName={productName}
          visibleSwatchLimit={visibleSwatchLimit}
          tileId={tileId}
          tileWidth={tileWidth}
          moreTextConfigs={moreTextConfigs}
          swatchType={swatchType}
          onSwatchClick={setCurrentSwatchSelected}
        />
      )}
      {promoMessages?.length > 0 && (
        <PromoContainer visibleTiles={showColorBuckets && !largeFlyout}>
          {promoMessages.map(renderTilePromoMessage)}
        </PromoContainer>
      )}
      {note && (
        <NoteTooltipWrapper
          adjustedTopMargin={{
            hasColorTiles: showColorBuckets && !largeFlyout,
            hasPromoMessages: promoMessages?.length > 0
          }}
        >
          <NoteIconContainer id={`noteContainer-${tileId}`}>
            <NoteIcon />
          </NoteIconContainer>
          <Tooltip
            noArrow={true}
            anchorSelect={`#noteContainer-${tileId}`}
            place="right-start"
            className="product-tile-note"
            content={note}
          />
        </NoteTooltipWrapper>
      )}
      {addingNote && (
        <AddNoteModal
          isOpen={addingNote}
          onClose={() => setAddingNote(false)}
          ga4Props={ga4IWishlistItemPayload()}
          imageUrl={
            tileData?.[0]?.imageUrl ||
            "https://cdn.media.amplience.net/i/serenaandlily/noimagemedium"
          }
          productName={productName}
          maxNoteLength={maxNoteLength}
          netsuiteId={variationId || attributes?.netsuiteId || productId}
          note={note}
          customUpholsteryOptions={customUpholsteryOptions}
        />
      )}
    </ProductTileContainer>
  );
};

export default ProductTile;

ProductTile.propTypes = {
  dynamicProductTile: PropTypes.shape({
    internalLinkID: PropTypes.string,
    tileWidth: PropTypes.string
  }),
  productSpecification: PropTypes.shape({
    product: PropTypes.object,
    disableRollover: PropTypes.bool,
    productName: PropTypes.string,
    showRolloverImage: PropTypes.bool
  }),
  productCta: PropTypes.object,
  productDetails: PropTypes.shape({
    showPrice: PropTypes.bool,
    showNewFlags: PropTypes.bool,
    showColorBuckets: PropTypes.bool
  }),
  productTileData: PropTypes.shape({
    showWasNow: PropTypes.bool,
    priceRange: PropTypes.object,
    salePriceRange: PropTypes.object,
    nowPriceRange: PropTypes.object,
    wasPriceRange: PropTypes.object,
    tileData: PropTypes.array,
    attributes: PropTypes.object,
    isCustomUpholstery: PropTypes.bool
  }),
  tileConfigs: PropTypes.shape({
    moreTextConfigs: PropTypes.object,
    priceConfigs: PropTypes.shape({
      fontColor: PropTypes.string,
      fontSize: PropTypes.number,
      modularScaleSize: PropTypes.string,
      fontFamily: PropTypes.string,
      bottomMargin: PropTypes.number,
      fontWeight: PropTypes.string
    }),
    productTitleConfigs: PropTypes.shape({
      fontColor: PropTypes.string,
      fontSize: PropTypes.number,
      modularScaleSize: PropTypes.string,
      fontFamily: PropTypes.string,
      bottomMargin: PropTypes.number,
      fontWeight: PropTypes.string
    }),
    imageCta: PropTypes.object,
    imageProductCallout: PropTypes.bool,
    swatchType: PropTypes.string,
    source: PropTypes.string
  }),
  variations: PropTypes.array,
  hideCta: PropTypes.bool,
  flyout: PropTypes.bool,
  squareImageConfigs: PropTypes.object,
  largeFlyout: PropTypes.bool,
  theme: PropTypes.object,
  list: PropTypes.string,
  source: PropTypes.string,
  position: PropTypes.number,
  detailList: PropTypes.string,
  addToCartCallback: PropTypes.func,
  isSortClp: PropTypes.bool,
  removeDecimalsIfZero: PropTypes.bool,
  isMobile: PropTypes.bool,
  isTablet: PropTypes.bool
};
