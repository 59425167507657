import { useMemo } from "react";
import isWishlistActive from "@serenaandlily/utils/isWishlistActive";
import useUser from "@serenaandlily/hooks/useUser";

export const useWishlistEnabled = (wishlistConfigs = {}) => {
  const { user, isUserLoading } = useUser();

  const isWishlistEnabled = useMemo(() => {
    if (isUserLoading) {
      return null;
    }

    const email = user?.data?.email;
    return isWishlistActive(wishlistConfigs, email);
  }, [wishlistConfigs, user, isUserLoading]);

  return { isWishlistEnabled, isUserLoading };
};
