import styled, { css } from "styled-components";
import {
  Breakpoints,
  Typography,
  Colors,
  FontSizes
} from "../../constants/constants";
import getFontValue from "../../utils/getFontValue";
import SnlImage from "../SnlImage";
import getFontSize from "../../utils/getFontSize";
import { SwatchesContainer } from "./TileSwatches/TileSwatchesStyled";
import { PromoMessagesWrapper } from "../Promos/PromoMessages/PromoMessagesStyled";

const oneColStyles = css`
  padding-right: 50px;
  padding-left: 50px;
  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    padding-right: 10%;
    padding-left: 10%;
  }
`;

export const ProductTileImagesContainer = styled.div`
  position: relative;
`;

export const DiscountContainer = styled.span`
  line-height: 0;
  & p:first-child {
    margin-right: 4px;
  }

  & > * {
    display: inline-block !important;
  }
`;

export const ProductTileImgWrapper = styled.a`
  position: ${({ isFirst }) => (isFirst ? "relative" : "absolute")};
  visibility: ${({ isSelected }) => (isSelected ? "visible" : "hidden")};
  ${({ tileWidth }) => (tileWidth === "1 Column" ? oneColStyles : null)}
  width: 100%;
  padding-bottom: 12px;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    opacity: ${({ disableRollover, flyout, isWishlist }) =>
      disableRollover || flyout || isWishlist ? "1" : "50%"};
  }
  margin: ${({ flyout }) => (flyout ? "auto" : "")};
  aspect-ratio: ${({ width, height }) =>
    width ? `${width} / ${height}` : "477 / 600;"};

  @media screen and (min-width: ${Breakpoints.MDLaptopMin}) {
    padding-bottom: 16px;
  }
`;

export const ProductTileImg = styled(SnlImage)`
  width: 100%;
  object-fit: cover;
`;

export const ImageCtaWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 23px;
`;

export const ProductTileImgCallout = styled.p`
  position: absolute;
  margin: 0;
  padding: 4px 12px;
  background: #f5f3ee;
  border-radius: 14px;
  color: #243953;
  font-weight: 300;
  font-size: ${FontSizes.Large};
  left: 4px;
  font-family: ${Typography.ProximaNova};
  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    bottom: 18px;
  }
  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    top: 4px;
  }
`;

export const ProductTileHeading = styled.div`

    @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
      text-align: left;
    }

    & > span {
      @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
`;

export const ProductTilePrice = styled.div`
  text-decoration: ${({ largeFlyout }) => (largeFlyout ? "line-through" : "")};
  display: flex;
  width: fit-content;
  & > p {
    @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
      letter-spacing: 0.14px;
    }
  }
`;

export const ProductDiscountPrice = styled(ProductTilePrice)`
  color: ${Colors["Midnight 70%"]};
  text-decoration: line-through;
`;

export const ProductDiscountContainer = styled(ProductTilePrice)``;

export const ProductTileName = styled.a`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  color: ${({ fontColor }) => fontColor || `${Colors.Midnight}`};
  ${({ textSize, modular }) => getFontSize(textSize, modular)};
  font-family: ${({ fontFamily }) =>
    `${getFontValue(fontFamily)}` || `${Typography.ProximaNova}`};
  margin-bottom: ${({ bottomMargin }) =>
    bottomMargin ? `${bottomMargin}px` : 0};
  text-decoration: none;

  font-weight: ${({ fontWeight }) => fontWeight || 400};

  &:hover {
    text-decoration: ${({ flyout }) => (flyout ? "underline" : "none")};
    color: ${({ flyout }) =>
      flyout ? `${Colors["Medium Primary"]}` : `${Colors["Dark Primary"]}`};
  }
  & > h5 {
    letter-spacing: 0.28px;
    @media screen and (min-width: ${Breakpoints.MDLaptopMin}) {
      letter-spacing: 0.32px;
    }
  }
`;

export const ProductTileNewFlag = styled.span`
  color: ${Colors.Midnight};
  font-family: ${Typography.BeatriceMedium};
  font-size: 10px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.4px;
  text-transform: uppercase;
`;

export const Wrapper = styled.div`
  height: ${({ wrapperHeight }) =>
    wrapperHeight ? `${wrapperHeight}px` : "auto"};
`;

export const ImageWrapper = styled.div`
  height: auto;
`;

export const PromoContainer = styled.div`
  margin-top: ${({ visibleTiles }) => (visibleTiles ? "12" : "0")}px;

  @media screen and (min-width: ${Breakpoints.SMTabletMin}) and (max-width: ${Breakpoints.SMTabletMax}) {
    margin-top: ${({ visibleTiles }) => (visibleTiles ? "16" : "0")}px;
    ${PromoMessagesWrapper} {
      margin-bottom: 12px;
    }
  }
  @media screen and (min-width: ${Breakpoints.MDLaptopMin}) {
    ${PromoMessagesWrapper} {
      margin-bottom: 8px;
    }
    margin-top: ${({ visibleTiles }) => (visibleTiles ? "16" : "0")}px;
  }
`;
export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  margin-bottom: 12px;

  @media screen and (min-width: ${Breakpoints.SMTabletMin}) and (max-width: ${Breakpoints.SMTabletMax}) {
    margin-bottom: 16px;
    gap: 8px;
  }
  @media screen and (min-width: ${Breakpoints.MDLaptopMin}) {
    margin-bottom: 16px;
    gap: 4px;
  }
`;
export const TradePriceWrapper = styled.div`
  display: flex;
  column-gap: 3px;
  align-items: center;
  row-gap: 3px;
  flex-wrap: wrap;

  > p,
  span * {
    @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
      line-height: 100%; /* 14px */
      letter-spacing: 0.28px;
    }
  }
`;

export const NoteTooltipWrapper = styled.div`
  ${({ adjustedTopMargin }) => {
    const { hasColorTiles, hasPromoMessages } = adjustedTopMargin;

    const withPromoMessagesTopMargin = `
      margin-top: 16px;
      @media screen and (min-width: ${Breakpoints.SMTabletMin}) and (max-width: ${Breakpoints.SMTabletMax}) {
      margin-top: 4px;
      }
      @media screen and (min-width: ${Breakpoints.MDLaptopMin}) {
      margin-top: 8px;
      }`;

    const withColorTilesTopMargin = `
      margin-top: 12px;
      @media screen and (min-width: ${Breakpoints.LGDesktopMin}) {
      margin-top: 16px;
      }`;

    if (hasPromoMessages) {
      return withPromoMessagesTopMargin;
    }

    if (hasColorTiles && !hasPromoMessages) {
      return withColorTilesTopMargin;
    }

    return "0";
  }};

  .product-tile-note {
    background: ${Colors.Fog};
    color: ${Colors.Midnight};
    font-family: ${Typography.BeatriceLight};
    font-size: ${FontSizes.Base};
    max-width: 236px;
    opacity: 1;
    z-index: 10000;
    word-wrap: break-word;
  }
`;

export const NoteIconContainer = styled.div`
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid ${Colors["Midnight 40%"]};
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EditContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
`;

export const EditButton = styled.button`
  padding: 8px;
  border: 0;
  background: transparent;
  cursor: pointer;

  :disabled {
    opacity: 0.5;
  }
`;

export const WishlistButtonContainer = styled.span`
  position: absolute;
  width: fit-content;
  bottom: 20px;
  right: 7px;
`;

export const ProductTileContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    align-items: flex-start;
  }

  ${({ isWishlistAndUnavailable }) =>
    isWishlistAndUnavailable &&
    css`
      & > *:not(${ProductTileImgWrapper}, ${EditContainer}) {
        filter: opacity(40%);
      }
      & ${ProductTileImg} {
        filter: opacity(40%);
      }
      & ${ProductTileImgWrapper} {
        &:hover {
          opacity: 1;
        }
      }
      .product-tile-cta {
        pointer-events: none;
        background: ${Colors["Midnight"]};
        color: ${Colors["White"]};
      }
    `}

  > a {
    width: ${({ flyout }) => (flyout ? "100px" : "100%")};
    text-align: center;
  }

  .product-tile-cta {
    margin-bottom: 15px;
    display: none;
    @media screen and (min-width: ${Breakpoints.SMTabletMin}) {
      display: flex;
      margin-bottom: 8px;
    }
    @media screen and (min-width: ${Breakpoints.MDLaptopMin}) {
      margin-bottom: 16px;
    }
  }

  ${SwatchesContainer} {
    width: 100%;
    justify-content: flex-start;

    button {
      margin-top: 0;
    }

    a {
      width: auto;
    }
  }
`;
