import React from "react";
import PropTypes from "prop-types";
import CTA from "../CTA";
import { HeadlineContainer, HeadlineTextContainer } from "./HeadlineStyled";
import RichText from "../RichText";
import Text from "../Text";
import defaultTheme from "./HeadlineTheme";
import useComponentTheme from "../Theme/useComponentTheme";
import useUser from "../../hooks/useUser";

const Headline = ({
  headlineTexts,
  DOOHeadlineTexts,
  textOrientation,
  textAlignment,
  width,
  height,
  headlineCta,
  DOOHeadlineCta,
  headlineRichText,
  DOOHeadlineRichText,
  backgroundColor,
  theme = defaultTheme,
  headlineRichTextFields
}) => {
  const tileTheme = useComponentTheme({ theme });
  const { user: { data: { isDOO } = {} } = {} } = useUser();

  const getActiveHeadlineRichText = () => {
    const isValidRichTextHeadingList =
      headlineRichTextFields?.richTextHeadingList?.[0]?.richText?.[0]?.data;

    const isValidRichTextDOOHeadingList =
      headlineRichTextFields?.richTextDOOHeadingList?.content?.[0]
        ?.richText?.[0]?.data;

    const isValidDOOHeadlineRichText =
      DOOHeadlineRichText?.content?.[0]?.richText?.[0]?.data;

    if (isDOO) {
      if (isValidRichTextDOOHeadingList) {
        return headlineRichTextFields.richTextDOOHeadingList.content;
      }
      if (isValidDOOHeadlineRichText) {
        return DOOHeadlineRichText.content;
      }
      if (isValidRichTextHeadingList) {
        return headlineRichTextFields.richTextHeadingList;
      }
      return headlineRichText;
    }

    if (isValidRichTextHeadingList) {
      return headlineRichTextFields.richTextHeadingList;
    }

    return headlineRichText;
  };

  const activeHeadlineRichText = getActiveHeadlineRichText();

  headlineTexts =
    isDOO && DOOHeadlineTexts?.content[0]?.copy
      ? DOOHeadlineTexts?.content
      : headlineTexts;

  headlineCta =
    isDOO && DOOHeadlineCta?.content[0]?.ctaText
      ? DOOHeadlineCta?.content
      : headlineCta;

  headlineRichText = activeHeadlineRichText;

  return (
    <HeadlineContainer
      textOrientation={textOrientation}
      textAlignment={textAlignment}
      width={width}
      height={height}
      backgroundColor={backgroundColor}
    >
      {headlineTexts?.map((text, idx) => {
        const key = `headline-${idx}`;
        const headlineTextMarginBottom =
          textOrientation === "Horizontal" ? 0 : text.marginBottom;
        return (
          <HeadlineTextContainer
            key={key}
            textOrientation={textOrientation}
          >
            <Text
              themeName={tileTheme?.headlineTexts?.themeName}
              {...text}
              marginBottom={headlineTextMarginBottom}
            />
          </HeadlineTextContainer>
        );
      })}

      {activeHeadlineRichText && Array.isArray(activeHeadlineRichText)
        ? activeHeadlineRichText.map((richTextGroup, idx) => (
            <RichText
              key={`headline-rich-text-${idx}`}
              themeName={tileTheme?.headlineRichText?.themeName}
              {...richTextGroup}
            />
          ))
        : activeHeadlineRichText && (
            <RichText
              themeName={tileTheme?.headlineRichText?.themeName}
              {...activeHeadlineRichText}
            />
          )}

      {headlineCta && headlineCta?.cta?.ctaText && (
        <CTA themeName={tileTheme?.headlineCta?.themeName} {...headlineCta} />
      )}
    </HeadlineContainer>
  );
};

export default Headline;

Headline.propTypes = {
  textOrientation: PropTypes.string,
  textAlignment: PropTypes.string,
  backgroundColor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  headlineTexts: PropTypes.array,
  DOOHeadlineTexts: PropTypes.object,
  headlineCta: PropTypes.object,
  DOOHeadlineCta: PropTypes.object,
  headlineRichText: PropTypes.object,
  DOOHeadlineRichText: PropTypes.object,
  theme: PropTypes.object,
  headlineRichTextFields: PropTypes.object
};
