import React, { useState } from "react";
import WishlistModal from "../WishlistModal";
import RemoveFromWishlistModalContent from "../RemoveFromWishlistModalContent";

const RemoveFromWishlistModal = ({
  onClose,
  netsuiteId,
  customUpholsteryOptions,
  ga4Props
}) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <WishlistModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      width={480}
      showCloseButton={true}
    >
      <RemoveFromWishlistModalContent
        netsuiteId={netsuiteId}
        onClose={onClose}
        customUpholsteryOptions={customUpholsteryOptions}
        ga4Props={ga4Props}
      />
    </WishlistModal>
  );
};

export default RemoveFromWishlistModal;
