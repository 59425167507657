import React from "react";
import getContentFromSchema from "../../utils/getContentFromSchema/getContentFromSchema";
import getCarouselContent from "./constants/AllowedCarouselContent";

const GenericCarouselContent = (content) => {
  const { _meta: meta } = content;
  const { schema } = meta;
  const contentType = getContentFromSchema(schema);

  const Component = getCarouselContent(contentType);

  if (Component) return <Component {...content} />;

  return null;
};

export default GenericCarouselContent;
