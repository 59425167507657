import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  SlideOutNav,
  ItemsWrapper,
  SlideOutListItem,
  SubNavItemButton
} from "./FlyoutMenuStyled";
import CTA from "../CTA";
import FlyoutMenuImage from "../FlyoutMenuImage";
import { useBreakpoint } from "../Breakpoints";
import debounce from "lodash.debounce";
import { SLIDE_OUT_MENU_DEBOUNCE_TIME } from "../../constants/constants";
import { executeOnEnterKey } from "@utils/keyboard/executeOnEnterKey";

const FlyoutMenu = ({
  flyoutCategories,
  hoverColor,
  handleOnMouseOver,
  handleOnCLickMobile,
  handleAriaButton,
  setCtaIcon,
  closeSubNav,
  level,
  isVisible,
  flyoutLevel,
  ariaExpandedBtn,
  tabbable,
  slideOutMenuTheme,
  secondaryFlyoutSrc,
  tertiaryyFlyoutSrc,
  secondarySelected
}) => {
  const isSecondary = level === "secondary";
  const image = isSecondary ? secondaryFlyoutSrc : tertiaryyFlyoutSrc;
  const breakpoints = useBreakpoint();
  const isTablet = breakpoints["ScreenWidth"];
  const flyoutRef = useRef(null);

  const setCtaOverrides = (item) => {
    const itemColor = isTablet ? item?.tabletColor : item?.desktopColor;
    const overrideValues = {
      color: itemColor,
      fontFamily: item?.fontFamily
    };
    return overrideValues;
  };

  const handleCloseNav = debounce(() => {
    closeSubNav(level);
  }, SLIDE_OUT_MENU_DEBOUNCE_TIME);

  useEffect(() => {
    if (isVisible) {
      const focusableElements = flyoutRef?.current?.querySelectorAll(
        '[tabindex]:not([tabindex="-1"])'
      );
      focusableElements?.[0]?.focus?.({ preventScroll: true });
    }
  }, [flyoutCategories, isVisible]);

  return isVisible ? (
    <SlideOutNav onMouseEnter={handleCloseNav} ref={flyoutRef}>
      <ItemsWrapper>
        {flyoutCategories.map((item, idx) => {
          const categoryItem =
            level === flyoutLevel.secondary ? item?.category : item;
          const themeOverrides = setCtaOverrides(categoryItem?.categoryName);
          const isSelected =
            isSecondary &&
            categoryItem?.categoryName?.ctaText === secondarySelected;

          const ctaText = categoryItem.categoryName?.ctaText;
          const url = categoryItem?.secondaryFlyout
            ? "#"
            : categoryItem?.categoryName?.url;

          const hasUrl = categoryItem?.categoryName?.url;

          const handleMouseOver = debounce(
            (skipValidation) => handleOnMouseOver(item, level, skipValidation),
            SLIDE_OUT_MENU_DEBOUNCE_TIME
          );

          return (
            <React.Fragment key={`slide-item-${idx}`}>
              <SlideOutListItem
                hoverColor={hoverColor}
                onMouseEnter={handleMouseOver}
                onMouseLeave={() => handleMouseOver.cancel()}
                onClick={() => handleOnCLickMobile(item, idx, level)}
                onKeyDown={(e) =>
                  executeOnEnterKey(e, () => handleMouseOver(true))
                }
                isSelected={isSelected}
              >
                <SubNavItemButton
                  aria-label={`show menu for ${idx} category`}
                  aria-expanded={ariaExpandedBtn}
                  onFocus={() => handleAriaButton(item)}
                  tabIndex={hasUrl ? -1 : tabbable}
                >
                  <CTA
                    themeName={slideOutMenuTheme?.themeName}
                    theme={slideOutMenuTheme}
                    cta={{
                      ctaText: ctaText,
                      url: url
                    }}
                    iconOnHover={true}
                    selectedCategory={isSecondary ? secondarySelected : ""}
                    ctaIcon={setCtaIcon(item?.secondaryFlyout || false)}
                    tabIndex={hasUrl ? tabbable : -1}
                    {...themeOverrides}
                  />
                </SubNavItemButton>
              </SlideOutListItem>
            </React.Fragment>
          );
        })}
      </ItemsWrapper>
      <FlyoutMenuImage imageSrc={image} />
    </SlideOutNav>
  ) : null;
};

export default FlyoutMenu;

FlyoutMenu.propTypes = {
  flyoutCategories: PropTypes.array,
  secondarySelected: PropTypes.string,
  hoverColor: PropTypes.string,
  handleOnMouseOver: PropTypes.func,
  handleOnCLickMobile: PropTypes.func,
  handleKeyDownOpenSlide: PropTypes.func,
  handleAriaButton: PropTypes.func,
  setCtaIcon: PropTypes.func,
  closeSubNav: PropTypes.func,
  level: PropTypes.string,
  isVisible: PropTypes.bool,
  flyoutLevel: PropTypes.object,
  ariaExpandedBtn: PropTypes.bool,
  tabbable: PropTypes.number,
  slideOutMenuTheme: PropTypes.object,
  secondaryFlyoutSrc: PropTypes.object,
  tertiaryyFlyoutSrc: PropTypes.object,
  closeSlide: PropTypes.func
};
