import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import TextBoxWithController from "./TextBoxWithController";
import TextBoxClassic from "./TextBoxClassic";

const TextBox = forwardRef((props, ref) => {
  return (
    <React.Fragment>
      {props.control ? (
        <TextBoxWithController {...props} ref={ref} />
      ) : (
        <TextBoxClassic {...props} ref={ref} />
      )}
    </React.Fragment>
  );
});

TextBox.displayName = "TextBox";
TextBox.propTypes = {
  control: PropTypes.object
};

export default TextBox;
