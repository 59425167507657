import styled from "styled-components";
import getContainerSizeDesktopStyles from "../../utils/getContainerSizeDesktopStyles";
import getContainerSizeMobileStyles from "../../utils/getContainerSizeMobileStyles";

export const DividerContainer = styled.div`
  ${({ widthSize }) => getContainerSizeDesktopStyles(widthSize || "Full Width")}
  ${({ gridSizeMobile }) =>
    gridSizeMobile ? getContainerSizeMobileStyles(gridSizeMobile) : null}
`;

export const DividerLine = styled.div`
  border-bottom: ${({ strokeWeight }) => strokeWeight} solid
    ${({ strokeColor }) => strokeColor};
  margin: 0;
  margin: ${({ height }) => `${height || 8}px 0`};
  margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
  width: 100%;
`;
