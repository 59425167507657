import styled from "styled-components";
import { Colors, FontSizes, Typography } from "../../constants/constants";
import { StyledSpinner } from "../Icons/IconsStyled";

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 365px;
  height: 300px;

  ${StyledSpinner} {
    margin: 0;
  }
`;

export const PopUpContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const PopUpWrapper = styled.div`
  width: auto;
  min-width: 365px;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  font-family: ${Typography.ProximaNova};
  background-color: ${Colors.White};
`;

export const ErrorWrapper = styled(PopUpWrapper)`
  min-height: 300px;
  justify-content: center;
  align-items: center;
  color: ${Colors["Crimson"]};
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: max-content;
  color: ${Colors["Dark Primary"]};
`;

export const HeadingText = styled.p`
  text-align: center;
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 1px;
  color: ${Colors["Dark Primary"]};
  margin: 0 0 6px 0;
`;

export const AddedItemName = styled(HeadingText)`
  font-weight: 600;
  text-transform: uppercase;
`;

export const CloseButton = styled.img`
  cursor: pointer;
`;

export const InfoWrapper = styled.div`
  display: flex;
  border-top: 1px solid ${Colors["Light Primary"]};
  border-bottom: 1px solid ${Colors["Light Primary"]};
  margin: 14px 0px;
  padding: 15px 0px;
  color: ${Colors["Dark Primary"]};
`;

export const SwatchInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${Colors["Light Primary"]};
  border-bottom: 1px solid ${Colors["Light Primary"]};
  margin: 14px 0px;
  padding: 15px 0px;
`;

export const SwatchInfoWrapper = styled.div`
  display: flex;
`;

export const ImageWrapper = styled.div`
  width: 30%;
  height: 101px;
  margin-bottom: 15px;
`;

export const ProductImage = styled.img`
  height: 100%;
  width: auto;
`;

export const SwatchImage = styled.img`
  aspect-ratio: 1 / 1;
  width: 75%;
`;

export const InfoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  font-size: ${FontSizes.Small};
  margin-bottom: 15px;
`;

export const ProductNameText = styled.p`
  margin-top: 0px;
  margin-bottom: 8px;
  font-weight: 600;
`;

export const ProductInfo = styled.div`
  display: inline-flex;
  gap: 4px;
`;

export const ProductPrice = styled.p`
  margin-top: 8px;
  margin-bottom: 0px;
  font-weight: 600;
`;

export const ProductDesignerPrice = styled.p`
  margin-top: 3px;
  margin-bottom: 0px;
  font-weight: 600;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${FontSizes.Base};
  font-weight: 600;
`;

export const ViewBagButton = styled.a`
  width: fit-content;
  min-width: 140px;
  padding: 10px;
  text-align: center;
  background-color: ${Colors["Dark Primary"]};
  color: ${Colors.White};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background-color: ${Colors["Medium Primary"]};
  }
`;

export const ContinueShoppingButton = styled.span`
  cursor: pointer;
  margin: auto 0;
  color: ${Colors["Dark Primary"]};
  background: url(https://cdn.media.amplience.net/i/serenaandlily/ContinueShoppingModalArrow)
    100% no-repeat;
  padding-right: 10px;
`;
