import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import {
  DropdownContainer,
  UtilityDropdownContainer,
  ArrowButton
} from "./UtilityMenuStyled";
import Icons from "../../Icons";
import ICON_TYPES from "../../Icons/constants";

const UtilityDropdown = ({
  children,
  width,
  logo,
  ariaLabel,
  position,
  dropdownClass
}) => {
  const iconType = ICON_TYPES["ArrowIcon"];

  const [active, setActive] = useState(false);

  const onHover = useCallback(() => {
    if (active) return;
    setActive(true);
  }, [active]);

  const onUnhover = useCallback(() => {
    setActive(!active);
  }, [active]);

  const onClickBag = useCallback(() => {
    setActive(!active);
  }, [active]);

  const arrowClass = active === true ? "mini-cart-dropdown-active" : "";

  return (
    <DropdownContainer
      onMouseEnter={onHover}
      onMouseLeave={onUnhover}
      dropdownClass={dropdownClass}
    >
      {logo}
      <ArrowButton
        arrowClass={arrowClass}
        aria-expanded={active ? true : false}
        aria-label={ariaLabel}
        rotate={active ? 1 : 0}
        type="button"
        onClick={onClickBag}
      >
        <div>
          <Icons type={iconType} />
        </div>
      </ArrowButton>
      <UtilityDropdownContainer
        className={`dropdown-container ${dropdownClass || ""} ${
          active ? "active" : ""
        }`}
        width={width}
        active={active}
        position={position}
      >
        <div>{children}</div>
      </UtilityDropdownContainer>
    </DropdownContainer>
  );
};

export default UtilityDropdown;

UtilityDropdown.propTypes = {
  children: PropTypes.any,
  width: PropTypes.string,
  logo: PropTypes.element,
  ariaLabel: PropTypes.string,
  position: PropTypes.string,
  dropdownClass: PropTypes.string
};
