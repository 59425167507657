const formatProductAccordionData = (data) => {
  let accordionItems = [];
  data.forEach((content) => {
    const { name, summary } = content;

    const accordionTitle = {
      text: {
        copy: name
      }
    };

    const accordionSummary = {
      richText: {
        richText: new Array(summary)
      }
    };

    const accordionItem = {
      accordionTitle: accordionTitle,
      accordionSummary: accordionSummary
    };

    if (name && summary) accordionItems.push(accordionItem);
  });

  return {
    accordionItems,
    spacing: {
      verticalSpacing: 20,
      horizontalSpacing: 18
    },
    borderOptions: {
      borderThickness: 1,
      borderColor: "#cbcaca"
    },
    icons: {
      expandIcon: "Plus",
      collapseIcon: "Minus"
    }
  };
};

export default formatProductAccordionData;
