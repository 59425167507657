import React from "react";
import PropTypes from "prop-types";
import {
  HeaderIconWrapper,
  HeaderImage,
  HeaderLink,
  TextWrapper,
  ImageWrapper,
  ImageTextWrapper
} from "./HeaderIconStyled";
import getAssetUrl from "../../utils/getAssetUrl";
import Text from "../Text";

const HeaderIcon = ({ icon, theme }) => {
  const type = icon?.iconImage ? "image" : "text";
  const imageSrc = type == "image" && getAssetUrl(icon.iconImage);
  const hasText = icon?.iconText;
  const iconTextProps = {
    fontFamily: icon?.iconText?.fontFamily,
    fontColor: icon?.iconText?.fontColor,
    fontSize: icon?.iconText?.fontSize,
    fontSizeTablet: icon?.iconText?.fontSize,
    fontSizeDesktop: icon?.iconText?.fontSize
  };

  return (
    <HeaderIconWrapper {...icon?.display}>
      {type == "image" ? (
        <ImageWrapper>
          <HeaderLink
            href={icon?.iconLink ? icon.iconLink : "#"}
            hasText={hasText}
            iconTextPosition={
              icon?.iconText?.iconTextPosition
                ? icon.iconText.iconTextPosition
                : "Right"
            }
          >
            <HeaderImage src={imageSrc} alt={icon?.altText} />
            {icon?.iconText && (
              <ImageTextWrapper
                marginBottom={
                  icon?.iconText.marginBottom ? icon?.iconText.marginBottom : 0
                }
                iconTextPosition={
                  icon.iconText.iconTextPosition
                    ? icon.iconText.iconTextPosition
                    : "Right"
                }
              >
                <Text
                  copy={icon?.iconText?.text}
                  {...iconTextProps}
                  themeName={theme?.headerIconText?.themeName}
                />
              </ImageTextWrapper>
            )}
          </HeaderLink>
        </ImageWrapper>
      ) : (
        <HeaderLink href={icon?.iconLink ? icon.iconLink : "#"}>
          {icon?.textIcon && (
            <TextWrapper
              marginBottom={icon.marginBottom ? icon.marginBottom : 0}
            >
              <Text
                copy={icon?.textIcon}
                themeName={theme?.headerIconText?.themeName}
              />
            </TextWrapper>
          )}
        </HeaderLink>
      )}
    </HeaderIconWrapper>
  );
};

export default HeaderIcon;

HeaderIcon.propTypes = {
  icon: PropTypes.object,
  theme: PropTypes.object
};
