import React from "react";
import PropTypes from "prop-types";
import { NextSeo } from "next-seo";

const defaultPageTitle = "Serena and Lily | A Fresh Approach to Home";

const Metadata = ({ pageTitle, pageDescription, noIndex = false }) => {
  return (
    <NextSeo
      title={pageTitle || defaultPageTitle}
      description={pageDescription}
      noindex={noIndex}
    />
  );
};

export default Metadata;

Metadata.propTypes = {
  pageTitle: PropTypes.string,
  pageDescription: PropTypes.string
};
