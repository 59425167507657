import React from "react";
import PropTypes from "prop-types";
import InspirationLogo from "./InspirationLogo";
import {
  InspirationLink,
  InspirationContainer,
  InspirationText
} from "./InspirationCTAStyled";

const InspirationCTA = ({ ctaText, url, openNewTab }) => {
  const target = openNewTab ? "_blank" : "_self";
  return (
    <InspirationLink href={url} target={target} id="header-inspiration">
      <InspirationContainer>
        <InspirationLogo />
        <InspirationText>{ctaText}</InspirationText>
      </InspirationContainer>
    </InspirationLink>
  );
};

export default InspirationCTA;

InspirationCTA.propTypes = {
  ctaText: PropTypes.string,
  url: PropTypes.string,
  openNewTab: PropTypes.bool
};
