import React from "react";
import { useRouter } from "next/router";
import { WishlistLink } from "./WishlistCTAStyled";
import { trackGA4Event } from "@serenaandlily/hooks/analytics/GA4Helpers";
import { GA4Events } from "@serenaandlily/hooks/analytics/GA4Events";
import { useWishlistGa4 } from "@serenaandlily/hooks/useWishlistGa4";

const WishlistCTA = ({ hide, user }) => {
  const router = useRouter();
  const { wishlistGa4User } = useWishlistGa4({});
  const handleWishlistCTAClick = () => {
    trackGA4Event(GA4Events.accessed_favorites_global_header_heart, {
      ecommerce: {
        path: window?.location?.pathname,
        ...wishlistGa4User
      }
    });

    router.push("/favorites");
  };

  return (
    <WishlistLink
      aria-label="Wishlist"
      onClick={handleWishlistCTAClick}
      hide={hide}
      id="header-wishlist"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M11.9992 7.7837C10.8624 4.79459 6.79598 2.25703 3.87602 5.35709C-0.965989 10.4979 5.51393 18.3012 11.9992 20.6833C18.4844 18.3012 24.9643 10.4979 20.1223 5.35709C17.2024 2.25703 13.1359 4.79459 11.9992 7.7837Z"
          stroke="#243953"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </WishlistLink>
  );
};

export default WishlistCTA;
