import useSWRImmutable from "swr/immutable";
import fetchProductPrice from "../gql/utils/fetchProductPrice";
import { useWishlistUserCreds } from "@serenaandlily/hooks/useWishlistUserCreds";

export function getProductPriceQueryKey({
  isCuProduct,
  productId,
  currentVariations,
  customUpholsteryOptions,
  customerEmail,
  guestToken
}) {
  const wishlistKey = customerEmail
    ? `customerEmail-${customerEmail}`
    : `guestToken-${guestToken}`;
  if (!isCuProduct) {
    return `productPrice-${productId}-${wishlistKey}`;
  }
  if (!customUpholsteryOptions?.fabricCode) {
    return null;
  }
  let key = `productPrice-${productId}-${wishlistKey}-${customUpholsteryOptions?.fabricCode}-${customUpholsteryOptions?.topLineVariation}`;

  if (!currentVariations) return key;
  const productVariations = Object.values(currentVariations);
  for (const variation of productVariations) {
    const name = variation.name?.replaceAll(" ", "-");
    key = key.concat(`-${name}`);
  }
  return key;
}

export async function productPriceFetcher({
  isCuProduct,
  productId,
  customerEmail,
  guestToken,
  customUpholsteryOptions,
  context
}) {
  if (!productId) return false;
  const baseVariables = {
    productId,
    context,
    customerEmail,
    guestToken
  };
  const variables = isCuProduct
    ? {
        ...baseVariables,
        customUpholsteryOptions
      }
    : { ...baseVariables };

  return fetchProductPrice(variables);
}

function formatPrices(data) {
  if (!data) return data;

  let formattedListPrice = "";
  let formattedSalePrice = "";
  let formattedDsoPrice = "";
  let formattedDsoDesigner = "";
  let formattedDsoDiscounted = "";

  data.prices?.forEach((price) => {
    const formattedPrice = price.formatted;
    switch (price.type) {
      case "list":
        formattedListPrice = formattedPrice;
        break;
      case "sale":
        formattedSalePrice = formattedPrice;
        break;
      case "dso":
        formattedDsoPrice = formattedPrice;
        break;
      case "designer":
        formattedDsoDesigner = formattedPrice;
        break;
      case "discounted":
        formattedDsoDiscounted = formattedPrice;
        break;
    }
  });

  return {
    ...data,
    formattedListPrice,
    formattedSalePrice,
    formattedDsoPrice,
    formattedDsoDesigner,
    formattedDsoDiscounted
  };
}

export const useProductPrice = (
  priceValues,
  shouldFetch = true,
  onSuccessCb
) => {
  const { wishlistUserCreds } = useWishlistUserCreds();
  const priceValuesWithUserInfo = {
    ...priceValues,
    ...wishlistUserCreds
  };
  const {
    data,
    error,
    isValidating: isLoadingPrice,
    isLoading: isLoadingPriceAlt
  } = useSWRImmutable(
    getProductPriceQueryKey(priceValuesWithUserInfo),
    () => {
      if (!shouldFetch) return;
      return productPriceFetcher(priceValuesWithUserInfo);
    },
    {
      onSuccess: (results) => {
        if (onSuccessCb) {
          const formattedSWRData = formatPrices(results);
          onSuccessCb(formattedSWRData);
        }
      }
    }
  );

  const formattedSWRData = formatPrices(data);

  const fetchProductOnDemand = async (customPriceValues = priceValues) => {
    const rawData = await productPriceFetcher(customPriceValues);
    return formatPrices(rawData);
  };

  return {
    data: formattedSWRData,
    error,
    isLoadingPrice,
    isLoadingPriceAlt,
    fetchProductOnDemand
  };
};
