function getProductQueryKey({
  isCuProduct,
  productId,
  currentVariations,
  customUpholsteryOptions,
  queryName = "product"
}) {
  if (!isCuProduct) {
    return `${queryName}-${productId}`;
  }
  if (!customUpholsteryOptions?.fabricCode) {
    return null;
  }
  let key = `${queryName}-${productId}-${customUpholsteryOptions?.fabricCode}-${customUpholsteryOptions?.topLineVariation}`;
  if (!currentVariations) return key;
  const productVariations = Object.values(currentVariations);
  for (const variation of productVariations) {
    const name = variation.name?.replaceAll(" ", "-");
    key = key.concat(`-${name}`);
  }
  return key;
}
export default getProductQueryKey;
