import styled from "styled-components";
import { Breakpoints, Colors } from "@serenaandlily/constants/constants";
import hexToRgba from "@serenaandlily/utils/colorManagement/hexToRgba";

export const HorizontalNavWrapper = styled.div`
  width: 100%;
  padding: 8px 20px 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  background: ${(props) => {
    if (props?.backgroundType === "Gradient") {
      const { top, bottom } = props.gradientColors || {};
      return `linear-gradient(
        0deg, 
        ${hexToRgba(bottom) || "#FFFFFF"} 75%, ${
        hexToRgba(top) || "#FFFFFF"
      } 100%
      )`;
    }
    return props?.solidColor || "transparent";
  }}};

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    padding: 8px 30px 8px;
  }

  @media screen and (min-width: ${Breakpoints.DesktopXL}) {
    padding: 8px 40px 8px;
  }
`;

export const HorizontalNavItem = styled.button`
  padding: 0 5px;
  background-color: transparent;
  color: ${Colors.Midnight};
  font-family: "BeatriceLight";
  font-size: 14px;
  letter-spacing: 0.5px;
  border: 0;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  &:hover {
    cursor: pointer;
    text-shadow: 0.25px 0 currentColor, -0.25px 0 currentColor,
      0 0.25px currentColor, 0 -0.25px currentColor;
  }

  @media screen and (max-width: ${Breakpoints.LgTablet}) {
    font-size: 10px;
  }
`;
