import React from "react";
import PropTypes from "prop-types";
import ModularGrid from "../ModularGrid";
import { FooterPrintWrapper } from "./GlobalFooterStyled";

const GlobalFooter = ({ content }) => {
  return (
    <React.Fragment>
      <FooterPrintWrapper data-pdpprint="false">
        {content?.map((contentItem, idx) => {
          const key = `Footer-Modular-Grid-${idx}`;
          return <ModularGrid key={key} {...contentItem} />;
        })}
      </FooterPrintWrapper>
    </React.Fragment>
  );
};

export default GlobalFooter;

GlobalFooter.propTypes = {
  content: PropTypes.array
};
