import React from "react";
import ProfileIcon from "../../Icons/Variants/ProfileIcon";
import { ProfileLink } from "./UtilityMenuStyled";

const ProfileLogo = () => {
  return (
    <ProfileLink href="/account" aria-label="My Account" id="header-profile">
      <ProfileIcon title="My Account" />
    </ProfileLink>
  );
};

export default ProfileLogo;
