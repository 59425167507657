const getInventory = (inventory, inventories, currentChildPID) => {
  if (!currentChildPID) return 0;
  let availableInventory = inventory?.getInventory?.available;
  if (availableInventory) return availableInventory;

  const currentItem = inventories?.find?.(
    (item) => item.productId === currentChildPID
  );
  if (currentItem) {
    return currentItem?.available;
  }
  return inventories?.[0]?.available;
};

export default getInventory;
