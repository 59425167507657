import styled from "styled-components";
import SnlImage from "../SnlImage";

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Image = styled(SnlImage)`
  object-fit: cover;
`;

export const Wrapper = styled.a`
  cursor: pointer;
`;
