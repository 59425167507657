import React, { Fragment } from "react";
import getAssetUrl from "../../utils/getAssetUrl";
import {
  Image,
  ImageAndCaptionContainer,
  ImageCaption,
  ImageContainer,
  ImageWrapper
} from "./ImageAndCaptionTileStyled";
import CTA from "../CTA";
import PropTypes from "prop-types";
import defaultTheme from "./ImageAndCaptionTileTheme";
import useComponentTheme from "../Theme/useComponentTheme";

const ImageAndCaptionTile = ({
  image,
  altText,
  addBorder,
  disableRollover,
  cta,
  bottomMargin,
  tileWidth,
  internalLinkID,
  theme = defaultTheme,
  contentItemIndex
}) => {
  const imageSrc = image && getAssetUrl(image);
  const tileTheme = useComponentTheme({ theme });
  const Wrapper = cta ? ImageWrapper : Fragment;
  const imageProps = cta ? { href: cta?.url } : null;
  const hasPriority = contentItemIndex === 0 ? true : false;

  return (
    <ImageAndCaptionContainer
      id={internalLinkID ? internalLinkID : "defaultImageAndCaptionTile"}
      tileWidth={tileWidth}
      addBorder={addBorder}
      bottomMargin={bottomMargin}
    >
      <Wrapper {...imageProps}>
        <ImageContainer>
          <Image
            src={imageSrc}
            alt={altText}
            imageData={image?.diImage}
            $disableRollover={disableRollover}
            priority={hasPriority}
          />
        </ImageContainer>
      </Wrapper>
      <ImageCaption>
        {cta && <CTA themeName={tileTheme?.cta?.themeName} {...cta} />}
      </ImageCaption>
    </ImageAndCaptionContainer>
  );
};

export default ImageAndCaptionTile;

ImageAndCaptionTile.propTypes = {
  image: PropTypes.object,
  altText: PropTypes.string,
  addBorder: PropTypes.bool,
  disableRollover: PropTypes.bool,
  cta: PropTypes.object,
  bottomMargin: PropTypes.number,
  tileWidth: PropTypes.string,
  internalLinkID: PropTypes.string,
  theme: PropTypes.object,
  contentItemIndex: PropTypes.number
};
