const getInitialFilters = (selectedFilters, type, filters) => {
  const initialFilters = {};
  const typeArr = type.split("");
  typeArr[0] = typeArr[0].toUpperCase();
  const capitalized = typeArr.join("");
  filters[type].forEach((color) => {
    initialFilters[color] = false;
  });

  if (selectedFilters[`selected${capitalized}`].length) {
    selectedFilters[`selected${capitalized}`].forEach((filter) => {
      initialFilters[filter] = true;
    });
  }

  return initialFilters;
};
export default getInitialFilters;
