import styled from "styled-components";
import {
  Colors,
  Typography,
  FontSizes,
  LetterSpacing,
  Spacing
} from "../../constants/constants";

export const MediaLinksSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: ${Typography.ProximaNova};
  font-size: ${FontSizes.Large};
  color: ${Colors["Dark Primary"]};
  font-weight: 400;
  letter-spacing: ${LetterSpacing.XSmall};
`;

export const CTAContainer = styled.ul`
  display: flex;
  align-items: center;
  margin-bottom: ${Spacing.Base}px;
  margin-top: 0px;
  padding-left: 0px;
`;

export const CTAWrapper = styled.li`
  display: flex;
  align-items: center;
  &:not(:first-of-type) {
    border-left: 1px solid ${Colors["Dark Primary"]};
    padding-left: 10px;
    margin-left: 10px;
  }
`;

export const CTAImage = styled.img`
  width: 17px;
  height: 15px;
  margin-right: 9px;
`;

export const MediaLinksSectionCTA = styled.a`
  text-decoration: none;
  font-size: ${FontSizes.Base};
  font-family: ${Typography.BeatriceRegular};
  border-bottom: 1px solid ${Colors["Midnight 70%"]};
  color: ${Colors["Midnight"]};
  font-weight: 400;
`;

export const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 15px;
`;

export const PrintWrapper = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const PrintButton = styled.button`
  display: flex;
  align-items: center;
  border: 1px solid ${Colors["Midnight 70%"]};
  border-radius: 48px;
  padding: 12px 24px;
  background: none;
  color: unset;
  &:hover {
    cursor: pointer;
  }
`;

export const PrintIconWrap = styled.span`
  width: 21px;
  height: 18px;
  display: inline-block;
`;

export const PrintText = styled.span`
  font-size: ${FontSizes.Base};
  font-family: ${Typography.BeatriceRegular};
  color: ${Colors.Midnight};
  margin-right: 8px;
`;

export const MediaLinksWrapper = styled.div`
  display: flex;
  align-items: center;
`;
