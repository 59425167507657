const getListOfRelatedProductIds = (
  childProducts,
  tileValue,
  variationName
) => {
  const relatedIds = [];
  if (!childProducts || childProducts.length === 0) return relatedIds;
  for (const childProduct of childProducts) {
    for (const variant of childProduct.variants) {
      const { name, value } = variant;
      if (name === variationName && tileValue === value) {
        relatedIds.push(childProduct.id);
        break;
      }
    }
  }

  return relatedIds;
};

export default getListOfRelatedProductIds;
