import React from "react";
import PropTypes from "prop-types";
import { HorizontalNavItem, HorizontalNavWrapper } from "./HorizontalNavStyled";
import { useSlideOutMenuContext } from "@serenaandlily/contexts/SlideOutMenuContext";
import { flyoutWidth } from "../SlideOutMenu";
import { useRouter } from "next/router";
import { GRID_PAGE_ROUTES } from "@serenaandlily/constants/gridPageRoutes";

export const HORIZONTAL_NAV_ID = "horizontal-nav";

const HorizontalNav = ({
  backgroundType,
  gradientColors,
  solidColor,
  marginBottom,
  setSlideWidth,
  sticky,
  setScrollHeight
}) => {
  const {
    setSecondaryFlyout,
    setIsVisible,
    setTertiaryFlyout,
    slideOutMenuContent,
    setIsOpen,
    setHoverEnabled,
    activeMobileSlideOutIdx
  } = useSlideOutMenuContext();
  const router = useRouter();
  const categories =
    (slideOutMenuContent && slideOutMenuContent[activeMobileSlideOutIdx]) ||
    null;
  const customMarginBottom = GRID_PAGE_ROUTES.includes(router.pathname) ? marginBottom : 0;

  return (
    <HorizontalNavWrapper
      id={HORIZONTAL_NAV_ID}
      {...{ backgroundType, gradientColors, solidColor, marginBottom: customMarginBottom }}
    >
      {categories?.categorySection?.map((item, idx) => (
        <HorizontalNavItem
          key={`horizontal-nav-${idx}`}
          onClick={() => {
            const visibility = item?.flyoutCategories;
            setHoverEnabled(false);
            setIsOpen(true);
            if (sticky) {
              setScrollHeight(window?.scrollY);
            }
            setSecondaryFlyout(item);
            setTertiaryFlyout();
            setIsVisible(visibility);
            setSlideWidth(flyoutWidth.secondary);
          }}
        >
          {item?.category?.ctaText}
        </HorizontalNavItem>
      ))}
    </HorizontalNavWrapper>
  );
};

HorizontalNav.propTypes = {
  backgroundType: PropTypes.string,
  gradientColors: PropTypes.object,
  solidColor: PropTypes.string,
  marginBottom: PropTypes.string,
  sticky: PropTypes.bool,
  setSlideWidth: PropTypes.func,
  setScrollHeight: PropTypes.func
};

export default HorizontalNav;
