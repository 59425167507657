import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Suspense
} from "react";

import PropTypes from "prop-types";
import useEmblaCarousel from "embla-carousel-react";
import { Thumb } from "./CarouselThumb";
import {
  EmblaCarousel,
  EmblaContainer,
  EmblaSlide,
  EmblaInnerSlide,
  EmblaThumb,
  EmblaContainerThumb,
  EmblaViewport,
  CarouselContainer,
  ImageWrapper,
  EmblaThumbViewport,
  ThumbCarousel,
  EmblaDotNavContainer,
  DotWrapper,
  DotButton,
  IconContainer,
  ThumbWrapper,
  VideoWrapper,
  VideoEmblaInnerSlide
} from "./ProductImageCarouselStyled";
import ImageZoom from "./../ImageZoom";
import ImageZoomMobile from "./../ImageZoomMobile";
import { useBreakpoint } from "../Breakpoints";
import getAltFromSrc from "../../utils/getAltFromSrc";
import { useProductContext } from "../../contexts/ProductContext";
import ICON_TYPES from "../Icons/constants";
import Icons from "../Icons";
import { pushToDatalayer } from "../../utils/analyticEvents";

const invalidIdx = -1;
const imageWidth = "600";

const VideoSlideLazyComponent = React.lazy(() => import("../VideoSlide"));

//eslint-disable-next-line max-lines-per-function, complexity
const ProductImageCarousel = ({
  items,
  mobileBreakpoint,
  isQuickview = false,
  VideoSlideLazy = VideoSlideLazyComponent
}) => {
  const videoRefs = useRef(items.map(() => React.createRef()));
  const [slidesInView, setSlidesInView] = useState([]);
  const [slideInView, setSlideInView] = useState([]);
  const multipleImages = items.length > 1;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const breakpoints = useBreakpoint();
  const showDots = breakpoints[mobileBreakpoint || "IPhone11ProMax"];
  const isDesktopMobile = breakpoints["DesktopMobile"];
  const showThumbnails =
    isDesktopMobile === false && !isQuickview && showDots === false;
  const { scene7Url, cuImageIsLoading } = useProductContext();
  const iconType = ICON_TYPES["CircularLoader"];

  const [mainViewportRef, embla] = useEmblaCarousel({
    skipSnaps: false,
    loop: true,
    speed: 5,
    containScroll: "trimSnaps"
  });

  const [thumbViewportRef, emblaThumbs] = useEmblaCarousel({
    speed: 5,
    inViewThreshold: 0.8,
    loop: false,
    axis: "y",
    containScroll: "trimSnaps"
  });

  useEffect(() => {
    setSelectedIndex(0);
    if (embla) {
      embla.scrollTo(0);
      embla.reInit({ loop: true });
    }
    if (emblaThumbs) {
      emblaThumbs.scrollTo(0);
      emblaThumbs.reInit();
    }
    onSelect();
  }, [items, embla, emblaThumbs, scene7Url]);

  const findSlidesInView = useCallback(() => {
    if (!embla) return;

    setSlidesInView((slidesInView) => {
      if (slidesInView.length === embla.slideNodes().length) {
        embla.off("select", findSlidesInView);
      }
      const inView = embla
        .slidesInView(true)
        .filter((index) => slidesInView.indexOf(index) === invalidIdx);
      return slidesInView.concat(inView);
    });
  }, [embla, setSlidesInView]);

  const onThumbClick = useCallback(
    (index) => {
      if (!embla || !emblaThumbs) return;
      if (emblaThumbs.clickAllowed()) embla.scrollTo(index);
    },
    [embla, emblaThumbs]
  );

  const onZoom = useCallback(
    (isDraggable) => {
      embla.reInit({ draggable: isDraggable });
    },
    [embla]
  );

  const onSelect = useCallback(() => {
    if (!embla) return;
    const PDPSlide = embla
      .slideNodes()
      [embla.selectedScrollSnap()].querySelector(".PDPImageContainer"); // eslint-disable-line no-unexpected-multiline
    setSelectedIndex(embla.selectedScrollSnap());
    embla.scrollTo(embla.selectedScrollSnap());
    if (PDPSlide) {
      embla
        .slideNodes()
        [embla.selectedScrollSnap()].querySelector(".PDPImageContainer") // eslint-disable-line no-unexpected-multiline
        .classList.add("active");
    }
    if (!emblaThumbs) return;
  }, [embla, emblaThumbs, setSelectedIndex]);


  const findSingleSlideInView = useCallback(() => {
    if (!embla) return;
    const inViewSlides = embla.slidesInView(true);
    setSlideInView(inViewSlides);
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    embla.on("select", onSelect);
    onSelect();
    findSlidesInView();
    findSingleSlideInView();
    embla.on("select", findSlidesInView);
    embla.on("select", findSingleSlideInView);
  }, [embla, onSelect, findSlidesInView, findSingleSlideInView]);

  useEffect(() => {
    const itemInView = items[slideInView];
    if (itemInView?.type === "video") {
      pushToDatalayer({
        event: "video-view",
        payload: {
          videoType: itemInView?.description,
          videoId: itemInView?.name
        }
      });
    }
  }, [slideInView]);

  return (
    <CarouselContainer isMobile={showDots}>
      {showThumbnails && (
        <ThumbCarousel>
          <EmblaThumb>
            <EmblaThumbViewport ref={thumbViewportRef}>
              <EmblaContainerThumb>
                {items?.map(({ src, type, mainThumb, description }, index) => {
                  const isSelected = index === selectedIndex;
                  const handleMouseOver = () => onThumbClick(index);

                  if (type === "img") {
                    let imageUrl = index === 0 ? scene7Url || src : src;
                    const isScene7 = imageUrl.includes("scene7.com");
                    imageUrl += isScene7
                      ? "&wid=76&hei=95"
                      : "?fmt=auto&w=76&h=95&unsharp=0,0.4,10,0";

                    const altText = getAltFromSrc(src);
                    return (
                      <Thumb
                        key={index}
                        onMouseOver={handleMouseOver}
                        imgSrc={imageUrl}
                        isSelected={isSelected}
                        altText={altText}
                      />
                    );
                  }

                  if (type === "video") {
                    return (
                      <ThumbWrapper
                        key={index}
                        onMouseOver={() => {
                          if (slideInView[0] !== index) {
                            videoRefs?.current[index]?.current?.restartVideo();
                          }
                        }}
                      >
                        <Thumb
                          imgSrc={mainThumb?.src}
                          altText={description}
                          isSelected={isSelected}
                          isVideo={true}
                          onMouseOver={handleMouseOver}
                        />
                      </ThumbWrapper>
                    );
                  }
                })}
              </EmblaContainerThumb>
            </EmblaThumbViewport>
          </EmblaThumb>
        </ThumbCarousel>
      )}
      <EmblaCarousel isMobile={showDots}>
        <EmblaViewport ref={mainViewportRef}>
          <EmblaContainer>
            {items?.map(
              ({ src, type, videoSrc, description, metadata }, index) => {
                const altText = getAltFromSrc(src);
                const imageUrl = index === 0 ? scene7Url || src : src;
                const isScene7Url = scene7Url && index === 0;
                const stlLink = metadata?.exif?.description || null;
                switch (type) {
                  case "img":
                    return (
                      <EmblaSlide key={index}>
                        <EmblaInnerSlide>
                          {cuImageIsLoading ? (
                            <ImageWrapper>
                              <IconContainer isMobile={showDots}>
                                <Icons type={iconType} />
                              </IconContainer>
                            </ImageWrapper>
                          ) : (
                            <ImageWrapper isQuickview={isQuickview}>
                              {showDots ? (
                                <ImageZoomMobile
                                  imageUrl={`${imageUrl}`}
                                  key={index}
                                  isQuickview={isQuickview}
                                  altText={altText}
                                  onZoom={onZoom}
                                  stlLink={stlLink}
                                  inView={
                                    slidesInView.indexOf(index) > invalidIdx
                                  }
                                  isScene7Url={isScene7Url}
                                  isDesktopMobile={isDesktopMobile}
                                />
                              ) : (
                                <ImageZoom
                                  imageUrl={imageUrl}
                                  key={index}
                                  imageWidth={imageWidth}
                                  isQuickview={isQuickview}
                                  altText={altText}
                                  stlLink={stlLink}
                                  inView={
                                    slidesInView.indexOf(index) > invalidIdx
                                  }
                                  isScene7Url={isScene7Url}
                                />
                              )}
                            </ImageWrapper>
                          )}
                        </EmblaInnerSlide>
                      </EmblaSlide>
                    );
                  case "video":
                    return (
                      <EmblaSlide key={index}>
                        <VideoEmblaInnerSlide>
                          <VideoWrapper>
                            <Suspense fallback={<div>Loading Video...</div>}>
                              <VideoSlideLazy
                                ref={videoRefs.current[index]}
                                videoSrc={videoSrc}
                                key={index}
                                isDesktopMobile={isDesktopMobile}
                                isMobile={showDots}
                                inView={slideInView[0] === index}
                                videoDescription={description}
                                isOnPdp={true}
                              />
                            </Suspense>
                          </VideoWrapper>
                        </VideoEmblaInnerSlide>
                      </EmblaSlide>
                    );
                }
              }
            )}
          </EmblaContainer>
        </EmblaViewport>
      </EmblaCarousel>
      {showDots && multipleImages && (
        <EmblaDotNavContainer>
          {items.map((_, index) => (
            <DotWrapper key={index} onClick={() => embla.scrollTo(index)}>
              <DotButton isSelected={index === selectedIndex} />
            </DotWrapper>
          ))}
        </EmblaDotNavContainer>
      )}
    </CarouselContainer>
  );
};

export default ProductImageCarousel;

ProductImageCarousel.propTypes = {
  items: PropTypes.array,
  mobileBreakpoint: PropTypes.string,
  isQuickview: PropTypes.bool
};
