import React from "react";
import PropTypes from "prop-types";
import CTA from "../CTA";
import {
  DividerContainer,
  SecondaryFooterContainer,
  SecondaryFooterLinksContainer,
  SpacingContainer
} from "./SecondaryStyled";
import Text from "../Text";
import GetPosition from "./utils/GetPosition";
import getLayoutProps from "./utils/getLayoutProps";

const SecondaryFooter = ({ copyright, ctas, spacing, layout }) => {
  const { horizontalSpacing } = spacing || {};
  const copyrightPosition = GetPosition(copyright?.position);
  const ctasPosition = GetPosition(ctas?.position);
  const enableDividers = ctas?.enableDividers;
  const cssProps = getLayoutProps(layout);
  return (
    <SecondaryFooterContainer {...cssProps}>
      {copyright && (
        <SpacingContainer
          horizontalSpacing={horizontalSpacing}
          order={copyrightPosition}
        >
          <Text {...copyright?.text} />
        </SpacingContainer>
      )}

      <SecondaryFooterLinksContainer order={ctasPosition}>
        {ctas?.ctaList?.map((cta, idx) => {
          const showDivider =
            enableDividers && idx !== ctas?.ctaList.length - 1;
          const key = `Secondary-Footer-Links-${idx}`;
          return (
            <React.Fragment key={key}>
              <SpacingContainer horizontalSpacing={horizontalSpacing}>
                <CTA {...cta} />
              </SpacingContainer>
              {showDivider && <DividerContainer>|</DividerContainer>}
            </React.Fragment>
          );
        })}
      </SecondaryFooterLinksContainer>
    </SecondaryFooterContainer>
  );
};

export default SecondaryFooter;

SecondaryFooter.propTypes = {
  copyright: PropTypes.object,
  ctas: PropTypes.any,
  spacing: PropTypes.object,
  layout: PropTypes.object
};
