import { gql } from "@apollo/client";

const buildSendNonBuyersDataMutation = () => {
  return gql`
    mutation SendNonBuyersData(
      $formName: String!
      $userAddress: InputNonBuyerDataUserAddress
      $userData: InputNonBuyerDataUserData
      $extraData: String!
    ) {
      sendNonBuyerFormData(
        formName: $formName
        userAddress: $userAddress
        userData: $userData
        extraData: $extraData
      ) {
        result
      }
    }
  `;
};

export default buildSendNonBuyersDataMutation;
