const defaultTheme = {
  productName: {
    themeName: "headline4"
  },
  itemInfo: {
    themeName: "textSmall"
  },
  descriptionTitle: {
    themeName: "headline6"
  },
  description: {
    themeName: "textSmall"
  }
};

export default defaultTheme;
