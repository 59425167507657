import apolloClient from "@serenaandlily/gql/client";
import { buildSendNonBuyersData } from "@serenaandlily/gql/mutations";

import { formatNonBuyersData } from "./formatNonBuyersData"

const sendNonBuyersData = async (
  formData,
  formName
) => {
  if (!apolloClient) return;

  try {
    const { userData, userAddress, extraData } = formatNonBuyersData(formData, formName)
    const NON_BUYERS_DATA = buildSendNonBuyersData();
    const mutationResponse = await apolloClient.mutate({
      mutation: NON_BUYERS_DATA,
      variables: {
        userData,
        userAddress,
        extraData,
        formName
      },
      context: { clientName: "endpoint2" }
    });

    return mutationResponse
  } catch (err) {
    /* eslint-disable-next-line */
    console.error(`Unhandled exception in sendNonBuyersData.js: ${err.message}`);
  }
};



export default sendNonBuyersData;
