import apolloClient from "../client";
import { buildGetProductDescriptionQuery } from "../queries";

const fetchProductDescriptionById = async (productId) => {
  if (!apolloClient) return;
  if (!productId) return;
  const GET_PRODUCT_DESCRIPTION = buildGetProductDescriptionQuery({
    productId: productId
  });
  const { data } = await apolloClient.query({
    query: GET_PRODUCT_DESCRIPTION,
    variables: {
      productId: productId
    },
    context: { clientName: "endpoint2" }
  });

  return data?.getProduct;
};

export default fetchProductDescriptionById;
