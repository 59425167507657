import React from "react";
import PropTypes from "prop-types";
import {
  InspirationTileWrapper,
  InspirationImage,
  ImageLink,
  ContentContainer,
  HeadlinesContainer,
  CTAContainer,
  ContentWrapper
} from "./InspirationTileStyled";
import getAssetUrl from "../../utils/getAssetUrl";
import CTA from "../CTA";
import defaultTheme from "./InspirationTileTheme";
import Text from "../Text";
import RichText from "../RichText";
import useComponentTheme from "../Theme/useComponentTheme";

//eslint-disable-next-line complexity
const InspirationTile = ({
  imageAndCaption,
  headlinePositioning,
  headlines,
  richText,
  ctaList,
  tileConfigs,
  theme = defaultTheme,
  contentItemIndex
}) => {
  const {
    internalLinkId,
    image,
    imageAltText,
    disableRollover,
    imagePaddingBottom
  } = imageAndCaption;
  const hasImagePriority = contentItemIndex === 0 ? true : false;

  const {
    position,
    desktopHorizontalOrientation,
    mobileHorizontalOrientation,
    hidePadding
  } = headlinePositioning;
  const { tileVerticalAlignment = "Middle", tileMarginBottom = 32 } =
    tileConfigs || {};
  const imageSrc = getAssetUrl(image);

  const mainCta = ctaList && ctaList[0];
  const imgLinkTarget = mainCta?.openNewTab ? "_blank" : "_self";

  const tileTheme = useComponentTheme({ theme });

  const { amplienceData } = image?.diImage?.image;

  return (
    <InspirationTileWrapper
      id={internalLinkId}
      position={position}
      tileVerticalAlignment={tileVerticalAlignment}
      marginBottom={tileMarginBottom}
    >
      <ImageLink
        href={mainCta?.cta?.url}
        target={imgLinkTarget}
        width={amplienceData?.width}
        height={amplienceData?.height}
        imagePaddingBottom={imagePaddingBottom}
      >
        <InspirationImage
          src={imageSrc}
          alt={imageAltText}
          imageData={{ ...image }}
          layout={"responsive"}
          $disableRollover={disableRollover}
          priority={hasImagePriority}
        />
      </ImageLink>
      <ContentWrapper>
        <ContentContainer {...headlinePositioning}>
          <HeadlinesContainer
            desktopHorizontalOrientation={desktopHorizontalOrientation}
            mobileHorizontalOrientation={
              mobileHorizontalOrientation
                ? mobileHorizontalOrientation
                : desktopHorizontalOrientation
            }
            hidePadding={headlinePositioning?.hidePadding}
            {...headlinePositioning}
          >
            {headlines?.map((headline, idx) => {
              const key = `headline-${idx}`;
              return (
                <Text
                  key={key}
                  themeName={tileTheme?.headlines?.themeName}
                  {...headline}
                />
              );
            })}
            {richText && (
              <RichText
                themeName={tileTheme?.richText?.themeName}
                {...richText}
                t={true}
              />
            )}
          </HeadlinesContainer>
        </ContentContainer>
        <CTAContainer hidePadding={hidePadding}>
          {ctaList?.map((cta, idx) => {
            const key = `inspiration-tile-cta-${idx}`;
            return (
              <CTA
                key={key}
                themeName={tileTheme?.ctaList?.theme?.themeName}
                {...cta}
                {...(cta?.themeOverrides?.showUnderlineAnimation && {
                  useMultiUnderline: true
                })}
                themeOverrides={{
                  ...(cta?.themeOverrides || {}),
                  ...(cta?.themeOverrides?.showUnderlineAnimation && {
                    useMultiUnderline: true
                  })
                }}
              />
            );
          })}
        </CTAContainer>
      </ContentWrapper>
    </InspirationTileWrapper>
  );
};

export default InspirationTile;

InspirationTile.propTypes = {
  imageAndCaption: PropTypes.object,
  headlinePositioning: PropTypes.object,
  headlines: PropTypes.array,
  richText: PropTypes.object,
  ctaList: PropTypes.array,
  tileConfigs: PropTypes.object,
  theme: PropTypes.object,
  contentItemIndex: PropTypes.number
};
