import styled from "styled-components";

export const CUFilterCTAWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 6px;
    height: 6px;
    margin-left: 6px;
  }
`;

export const RemoveFilter = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
