/* eslint-disable complexity */
const getTileVariations = (variations, parentVariations) => {
  const parentSizeName = parentVariations?.Size?.description;
  const parentStyleName = parentVariations?.Style?.description;
  const parentColorName = parentVariations?.Color?.description;
  const sizeOptions = variations?.find(
    (variation) => variation.name?.toLowerCase() === "size"
  )?.options;
  const styleOptions = variations?.find(
    (variation) => variation.name?.toLowerCase() === "style"
  )?.options;
  const colorOptions = variations?.find(
    (variation) => variation.name?.toLowerCase() === "color"
  )?.options;
  const size = sizeOptions?.find(
    (option) => option.description === parentSizeName
  );
  const style = styleOptions?.find(
    (option) => option.description === parentStyleName
  );
  const color = colorOptions?.find(
    (option) => option.description === parentColorName
  );

  const currentVariations = [];
  if (size) currentVariations.push({ name: "Size", value: size.description });
  if (style)
    currentVariations.push({ name: "Style", value: style.description });
  if (color)
    currentVariations.push({ name: "Color", value: color.description });

  return currentVariations;
};

export default getTileVariations;
