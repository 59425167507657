import React from "react";
import PropTypes from "prop-types";
import {
  AddedItemTileWrapper,
  AddedItemTileImageContainer,
  AddedItemTileInfoContainer,
  ProductName,
  VariationAttribute,
  VariationLabel,
  VariationValue,
  PriceContainer
} from "./AddedBagPanelStyled";
import SnlImage from "../SnlImage";
import Text from "../Text";
import ProductPrice from "../ProductPrice";

const AddedItemTile = ({
  imageUrl,
  name,
  fabric,
  quantity,
  price,
  currentVariations,
  isCU
}) => {
  const variationNames = currentVariations && Object.keys(currentVariations);
  const hasVariations = variationNames?.length > 0;

  const productPriceTheme = {
    listedPrice: {
      themeName: "textSmall",
      marginBottom: 5
    },
    salesPrice: {
      themeName: "textSmall"
    },
    tradePrice: {
      themeName: "textSmall",
      fontColor: "#527296",
      fontWeight: "600"
    }
  };

  return (
    <AddedItemTileWrapper>
      <AddedItemTileImageContainer>
        <SnlImage src={imageUrl} />
      </AddedItemTileImageContainer>
      <AddedItemTileInfoContainer>
        <ProductName>
          <Text
            copy={name}
            themeName="textSmall"
            fontFamily="BeatriceRegular"
            fontSizeDesktop={16}
            fontSizeTablet={14}
            fontSize={14}
          />
        </ProductName>
        {hasVariations &&
          variationNames.map((variation) => {
            const value =
              variation === "Size" || variation === "Color"
                ? currentVariations[variation]?.description
                : currentVariations[variation]?.name;
            return (
              <VariationAttribute key={`${variation} ${value}`}>
                <VariationLabel>
                  <Text
                    copy={`${variation}:`}
                    themeName="textSmall"
                    fontColor="#667487"
                  />
                </VariationLabel>
                &nbsp;
                <VariationValue>
                  <Text
                    copy={value}
                    themeName="textSmall"
                    fontColor="#667487"
                  />
                </VariationValue>
              </VariationAttribute>
            );
          })}
        {fabric && isCU && (
          <VariationAttribute>
            <VariationLabel>
              <Text
                copy={`Fabric:`}
                themeName="textSmall"
                fontColor="#667487"
              />
            </VariationLabel>
            &nbsp;
            <VariationValue>
              <Text copy={fabric} themeName="textSmall" fontColor="#667487" />
            </VariationValue>
          </VariationAttribute>
        )}
        {quantity && (
          <VariationAttribute>
            <VariationLabel>
              <Text
                copy={`Quantity:`}
                themeName="textSmall"
                fontColor="#667487"
              />
            </VariationLabel>
            &nbsp;
            <VariationValue>
              <Text copy={quantity} themeName="textSmall" fontColor="#667487" />
            </VariationValue>
          </VariationAttribute>
        )}
        {price && (
          <PriceContainer>
            <ProductPrice {...price} theme={productPriceTheme} />
          </PriceContainer>
        )}
      </AddedItemTileInfoContainer>
    </AddedItemTileWrapper>
  );
};

export default AddedItemTile;

AddedItemTile.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  fabric: PropTypes.string,
  quantity: PropTypes.number,
  price: PropTypes.any,
  currentVariations: PropTypes.object,
  isCU: PropTypes.bool
};
