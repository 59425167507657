import styled from "styled-components";

export const QuanitySpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const QuantityLabelContainer = styled.div`
  width: 35px;
  margin-bottom: 3px;
  text-align: center;
  display: none;
`;

export const QuantitySpin = styled.div`
  display: flex;
  height: ${({ height }) => (height ? `${height}px` : "auto")};
`;

export const QuantityButton = styled.button`
  background-color: unset;
  border: 1px solid #8f99a6;
  box-sizing: content-box;
  height: 46px;
  width: 46px;
  padding: 0;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    & > svg {
      stroke-width: 2px;
    }
  }
`;

export const QuantityInput = styled.input`
  background-color: unset;
  appearance: none;
  border: none;
  cursor: auto;
  padding: 0;
  text-align: center;
  top: auto;
  height: 46px;
  width: 40px;
  border-radius: 0;
  -webkit-appearance: none;
  z-index: 19;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const QuantityContainer = styled.div`
  width: max-content;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;
