import getBestMatchForVariations from "./getBestMatchForVariations";

function getProductForTile({ product, tileVariations, availableInventories }) {
  const { childProducts } = product;

  // products without child products do not need to find a variation match
  if (!Array.isArray(childProducts) || childProducts.length === 0) {
    return {
      id: product?.productId,
      netsuiteId: product?.netsuiteId,
      sku: product?.sku
    };
  }

  let tileProduct = childProducts[0];

  // if there are no tileVariations, then we don't need to look for a match
  if (!Array.isArray(tileVariations) || tileVariations.length === 0) {
    return tileProduct;
  }

  // look for matches within available child products
  const filteredChildProducts = childProducts.filter((p) =>
    availableInventories?.some((i) => i.productId === p.id)
  );

  // Try to find a product that matches the tile variations.
  const productMatch = getBestMatchForVariations({
    childProducts: filteredChildProducts,
    variations: tileVariations
  });

  if (productMatch) {
    tileProduct = productMatch;
  }

  return tileProduct;
}
export default getProductForTile;
