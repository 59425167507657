export const validateEmail = (email) => {
  const regexp =
    /^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
  return regexp.test(email);
};

export const validateEmailDomain = (email) => {
  const regexpA = /^[A-Za-z0-9._%+-]+@serenaandlily\.com$/;
  const regexpB = /^[A-Za-z0-9._%+-]+@serenaandlilydesign\.com$/;

  return regexpA.test(email) || regexpB.test(email);
};
