// CONSTANT IMPORTS //
import { Typography } from "../../../constants/constants";

export const attributeText = {
  themeName: "fabricAttributeText",
  tag: "h5",
  fontFamily: Typography.BeatriceMedium,
  fontColor: "Midnight",
  fontSize: "11px",
  fontSizeTablet: "11px",
  fontSizeDesktop: "11px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "110%",
  letterSpacing: "0.11px"
};

export const contentTitle = {
  themeName: "contentTitle",
  tag: "h5",
  fontFamily: Typography.BeatriceMedium,
  fontColor: "Midnight",
  fontSize: "14px",
  fontSizeTablet: "14px",
  fontSizeDesktop: "14px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "120%",
  letterSpacing: "0.28px"
};

export const contentText = {
  themeName: "contentText",
  tag: "p",
  fontFamily: Typography.BeatriceLight,
  fontColor: "Midnight",
  fontSize: "14px",
  fontSizeTablet: "14px",
  fontSizeDesktop: "14px",
  fontStyle: "normal",
  fontWeight: "300",
  lineHeight: "120%",
  letterSpacing: "0.14px"
};
