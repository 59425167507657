import useSWRImmutable from "swr/immutable";
import fetchProductDescriptionById from "../gql/utils/fetchProductDescriptionById";

export async function productSwatchDescriptionFetcher(productId) {
  if (!productId) return null;
  return fetchProductDescriptionById(productId);
}

export const useProductSwatchDescription = (productId) => {
  const {
    data,
    error,
    isValidating: isLoading
  } = useSWRImmutable(`swatch-product-${productId}`, async () =>
    productSwatchDescriptionFetcher(productId)
  );
  return {
    data,
    error,
    isLoading
  };
};
