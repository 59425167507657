const reorderVariations = (variations) => {
  if (!variations) return [];
  let colorArr = [];
  let sizeArr = [];
  let restArr = [];
  for (let i = 0; i < variations.length; i++) {
    const variation = variations[i];
    if (variation?.name === "Size") {
      sizeArr.push(variation);
    } else if (variation?.id === "Size") {
      sizeArr.push({ name: "Size", options: variation?.options });
    } else if (variation?.name === "Color") {
      colorArr.push(variation);
    } else {
      restArr.push(variation);
    }
  }

  const merged = sizeArr.concat(restArr).concat(colorArr);

  return merged;
};

export default reorderVariations;
