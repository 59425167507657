import React, { useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { SubNavContainer } from "./SubNavStyled";
import LinksSection from "../LinksSection";
import PromoCTA from "../PromoCTA";

const tabKeyCode = 9;

const SubNav = ({
  linksSections,
  promoCTA,
  isVisible,
  setIsVisible,
  setRotate,
  previousElement
}) => {
  const ref = useRef(null);

  const handleKeyDown = useCallback(
    (event) => {
      let topNavMenu = ref.current;
      let firstElement = topNavMenu.getElementsByTagName("a")[0];

      if (
        topNavMenu.lastElementChild === document.activeElement ||
        (event.shiftKey &&
          event.keyCode == tabKeyCode &&
          firstElement === document.activeElement)
      ) {
        setIsVisible(false);
        setRotate(true);
        previousElement.focus();
      }
    },
    [previousElement, setIsVisible, setRotate]
  );

  useEffect(() => {
    ref.current.focus();
  }, []);

  return (
    <SubNavContainer
      isVisible={isVisible}
      ref={ref}
      tabIndex={-1}
      onKeyDown={handleKeyDown}
    >
      {linksSections.map((content, idx) => {
        return <LinksSection {...content} key={`links-section-${idx}`} />;
      })}
      {promoCTA && <PromoCTA {...promoCTA} />}
    </SubNavContainer>
  );
};

export default SubNav;

SubNav.propTypes = {
  linksSections: PropTypes.array,
  promoCTA: PropTypes.object,
  isVisible: PropTypes.bool,
  setIsVisible: PropTypes.func,
  rotate: PropTypes.bool,
  setRotate: PropTypes.func,
  previousElement: PropTypes.any
};
