import { useCallback, useRef } from "react";
import useSWRMutation from "swr/mutation";
import fetchSailthruEvent from "../../utils/fetchSailthruEvent";
import { emailSignUpFacebook } from "../../utils/facebookCAPI/libFacebook";
import { useMarketing } from "../../contexts/MarketingContext";
import { useGAData } from "../../hooks/useGAData";

async function fetcher(url, { arg }) {
  return fetchSailthruEvent({
    ...arg,
    eventName: "userSignUp"
  });
}

const useEmailSubscription = () => {
  const { handleTrackEmailSubscription } = useMarketing();
  const gaData = useGAData();
  const callbackRef = useRef(null);

  const handleSuccess = useCallback(
    (data, source) => {
      emailSignUpFacebook({ email: data?.id });
      handleTrackEmailSubscription(data?.id, source);

      if (callbackRef.current) {
        callbackRef.current();
        callbackRef.current = null;
      }
    },
    [handleTrackEmailSubscription]
  );

  const { trigger, isMutating } = useSWRMutation(
    "sailthruEmailRegistration",
    fetcher,
    {
      onSuccess: (data, key, config) => {
        const source = config?.arg?.source || "Unknown Source";

        handleSuccess(data, source);
      }
    }
  );

  const submitEmail = useCallback(
    (email, options, onSuccessCallback) => {
      const { source, isDaily } = options || {};

      const formData = {
        email,
        ...(!isDaily && { weeklyNewsletter: 0 }),
        source,
        ...gaData
      };

      callbackRef.current = onSuccessCallback;

      trigger(formData, { arg: formData });
    },
    [trigger, gaData]
  );

  return { isMutating, submitEmail };
};

export default useEmailSubscription;
