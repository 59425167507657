import React, { createContext, useContext, useMemo, useEffect } from "react";
import { useAtomValue, useSetAtom } from "jotai";

import { cuProductAtom } from "../../state/atoms/cuProductAtomStore";

import PropType from "prop-types";
import { CUSTOM_UPHOLSTERY } from "../../constants/productTypes";
import addCustomUpholsteryImageData from "../../utils/productsPreprocessing/addCustomUpholsteryImageData/addCustomUpholsteryImageData";

const ProductDataContext = createContext();

const useProductDataContext = () => {
  return useContext(ProductDataContext);
};
ProductDataContext.displayName = "ProductDataProvider";
// eslint-disable-next-line max-lines-per-function
const ProductDataProvider = ({ children, pdpProps }) => {
  const { productType, customUpholstery } = pdpProps || {};
  const visibleFabrics = 6;

  const hasCustomUpholsteryProductTypes = useMemo(() => {
    return CUSTOM_UPHOLSTERY.includes(productType);
  }, [productType]);
  const setCompleteCUData = useSetAtom(cuProductAtom);
  const completedCuData = useAtomValue(cuProductAtom);

  useEffect(() => {
    setCompleteCUData(customUpholstery);
  }, [customUpholstery, setCompleteCUData]);

  useEffect(() => {
    (async () => {
      if (hasCustomUpholsteryProductTypes) {
        const cUData = await addCustomUpholsteryImageData(
          customUpholstery,
          visibleFabrics
        );
        setCompleteCUData(cUData);
      }
    })();
  }, [customUpholstery, hasCustomUpholsteryProductTypes, setCompleteCUData]);

  const handleLoadAllFabricImages = async () => {
    if (!completedCuData.allImagesLoaded) {
      const cUData = await addCustomUpholsteryImageData(completedCuData);
      setCompleteCUData({ ...cUData, allImagesLoaded: true });
    }
  };

  useEffect(() => {
    if (completedCuData?.fabrics) handleLoadAllFabricImages();
  }, [handleLoadAllFabricImages, completedCuData]);

  return (
    <ProductDataContext.Provider
      value={{
        completedCuData,
        handleLoadAllFabricImages
      }}
    >
      {children}
    </ProductDataContext.Provider>
  );
};

export { useProductDataContext, ProductDataProvider };

ProductDataProvider.propTypes = {
  children: PropType.any,
  pdpProps: PropType.object
};
