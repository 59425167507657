import createUUID from "@serenaandlily/utils/createUUID/createUUID";
import postToPinterestApi from "@utils/postToPinterestApi";
import { pushToDataLayer } from "@serenaandlily/hooks/analytics/GA4Helpers";

export function trackPinterestEvent(
  key,
  eventName,
  additionalParams = {},
  prefix = ""
) {
  const eventId = `${prefix || key}_${createUUID()}`;

  pushToDataLayer({
    [key]: eventId
  });

  postToPinterestApi({
    eventId,
    eventName,
    ...additionalParams
  });
}
