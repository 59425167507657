import SnlImage from "../SnlImage";
import styled from "styled-components";

export const BreakpointImageWrapper = styled.div`
`;

export const Image = styled(SnlImage)`
  max-width: 100%;
  height: 100% !important;
`;
