const defaultTheme = {
  variationLabel: {
    themeName: "headline5Medium"
  },
  variationInfo: {
    themeName: "text",
    fontSize: "14px",
    fontSizeTablet: "15px",
    fontSizeDesktop: "16px"
  }
};

export default defaultTheme;
