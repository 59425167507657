import React from "react";
import PropTypes from "prop-types";
import CTA from "../CTA";
import Text from "../Text";
import defaultTheme from "./LinksSectionFlyoutTheme";
import {
  LinksSectionFlyoutContainer,
  TextWrapper,
  LinksWrapper,
  CTAWrapper
} from "./LinksSectionFlyoutStyled";
import useComponentTheme from "../Theme/useComponentTheme";

const LinksSectionFlyout = ({
  textLinkTitle,
  textLinks,
  theme = defaultTheme
}) => {
  const linksTheme = useComponentTheme({ theme });

  return (
    <LinksSectionFlyoutContainer>
      <TextWrapper>
        <Text {...textLinkTitle} themeName={linksTheme?.title?.themeName} />
      </TextWrapper>
      <LinksWrapper>
        {textLinks &&
          textLinks.map((link, idx) => (
            <CTAWrapper key={`link-${idx}`}>
              <CTA {...link} themeName={linksTheme?.cta?.themeName} />
            </CTAWrapper>
          ))}
      </LinksWrapper>
    </LinksSectionFlyoutContainer>
  );
};

export default LinksSectionFlyout;

LinksSectionFlyout.propTypes = {
  textLinkTitle: PropTypes.object,
  textLinks: PropTypes.array,
  theme: PropTypes.object
};
