export const generateGap = ({ gap, tabletGap, desktopGap }) => {
  if (tabletGap) {
    return `${tabletGap}`;
  }

  if (desktopGap) {
    return `${desktopGap}`;
  }

  if (gap) {
    return `${gap}`;
  }

  return "0px";
};

export const generateMargin = ({ margin, tabletMargin, desktopMargin }) => {
  if (tabletMargin) {
    return `${tabletMargin}`;
  }

  if (desktopMargin) {
    return `${desktopMargin}`;
  }

  if (margin) {
    return `${margin}`;
  }

  return "0px";
};

export const generatePadding = ({ padding, paddingTablet, paddingDesktop }) => {
  if (paddingTablet) {
    return `${paddingTablet}`;
  }

  if (paddingDesktop) {
    return `${paddingDesktop}`;
  }

  if (padding) {
    return `${padding}`;
  }

  return "0px";
};
