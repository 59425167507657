import useSWRImmutable from "swr/immutable";
import fetchProductEddById from "../gql/utils/fetchProductEddById";
import { getProductQueryKey } from "../utils/swr";

export async function eddFetcher({
  isCuProduct,
  productId,
  customUpholsteryOptions
}) {
  if (!productId) return false;
  const variables = isCuProduct
    ? {
        productId,
        customUpholsteryOptions
      }
    : { productId };

  return fetchProductEddById(variables);
}

export const useProductEdd = (productParams, shouldUseProductEdd = true) => {
  const {
    data,
    error,
    isValidating: isLoadingEdd
  } = useSWRImmutable(
    shouldUseProductEdd
      ? getProductQueryKey({ ...productParams, queryName: "productEdd" })
      : null,
    async () => {
      if (shouldUseProductEdd) {
        return eddFetcher(productParams);
      }
      return null; // Return null when not using the hook
    }
  );

  return {
    data: data?.getProductEdd,
    error,
    isLoadingEdd
  };
};
