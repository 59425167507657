/**
 *
 * @param {string} stlLink - The link to check.
 * @returns {boolean} True if the link is a Shop The Look link, otherwise false.
 */
const isShopTheLook = (stlLink) => {
  if (!stlLink) return false;
  if (typeof stlLink !== "string") return false;
  const stlLinkSplit = stlLink.split("/") || [];
  const [stlLinkShopTheLook] = stlLinkSplit.length > 1 ? stlLinkSplit : [""];
  return stlLinkShopTheLook === "shopthelook";
};

export default isShopTheLook;
