// COMPONENT IMPORTS //
import Text from "../../../Text";

// STYLED IMPORTS //
import { FlexWrapper } from "../../../../../components/LayoutUtils/LayoutUtilsStyled";

// THEME IMPORTS //
import { contentTitle, contentText } from "../../typography/themes";

const CareInstructions = ({ careInstructions = [] }) => {
  // Variables //
  const hasCareInstructions = careInstructions?.length > 0;

  if (!hasCareInstructions) {
    return null;
  }

  return (
    <FlexWrapper flexDirection="column" gap="4px">
      <Text copy="Care Instructions" theme={contentTitle} />
      {careInstructions?.map((content, idx) => (
        <Text key={`${content}-${idx}`} copy={content} theme={contentText} />
      ))}
    </FlexWrapper>
  );
};

export default CareInstructions;
