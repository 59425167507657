import { NO_IMAGE_AMPLIENCE_URL } from "@serenaandlily/constants/constants";

export function getProcessedImageSrc({
  imageUrl,
  rolloverImageSrc,
  masterImageUrl,
  isHovered,
  showRolloverImageActive,
  source,
  cropImageParams,
  imageSizeParam
}) {
  const defaultImageUrl = NO_IMAGE_AMPLIENCE_URL;

  const finalImageUrl = imageUrl || defaultImageUrl;
  const isScene7 = finalImageUrl.includes("s7d4");
  const isScene7Rollover = rolloverImageSrc?.includes("s7d4");

  const imageParams = cropImageParams + imageSizeParam;

  const processedImageUrl = !isScene7
    ? finalImageUrl + imageParams
    : finalImageUrl;

  const processedRolloverImageSrc =
    rolloverImageSrc && !isScene7Rollover
      ? rolloverImageSrc + imageParams
      : rolloverImageSrc;

  let selectedImageSrc = processedImageUrl;

  if (isHovered && showRolloverImageActive && processedRolloverImageSrc) {
    selectedImageSrc = processedRolloverImageSrc;
  } else if (source === "clp" && masterImageUrl) {
    selectedImageSrc = masterImageUrl;
  }

  return selectedImageSrc;
}
