import styled from "styled-components";
import { Breakpoints } from "../../constants/constants";

export const EmailIconLinksWrapper = styled.div``;

export const IconLinksContainer = styled.div`
  display: ${({ disableIconLinks }) => (disableIconLinks ? "none" : "block")};

  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    display: ${({ disableIconLinks }) => (disableIconLinks ? "none" : "block")};
  }

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    display: block;
  }
`;
