import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  BagPanelImageContainer,
  BagPanelTileWrapper,
  BagPanelInfo,
  DiscountMessageWrapper,
  InventoryErrorMessage
} from "./BagPanelStyled";
import SnlImage from "../SnlImage";
import Text from "../Text";
import {
  ProductName,
  VariationAttribute,
  VariationLabel,
  VariationValue,
  PriceContainer,
  BagPromoMessages
} from "../AddedBagPanel/AddedBagPanelStyled";
import { RETAIL_PRICE_LABEL } from "../../constants/strings";
import ProductPrice from "../ProductPrice";
import currency from "currency.js";
import Headline from "../Headline";
import { useCart, useCartUpdate } from "../../contexts/CartContext";
import { useDiscountContext } from "../../contexts/ProductDiscountContext";
import { useContent } from "../../contexts/ContentContext";
import {
  CART_WARNINGS,
  CART_WARNINGS_MESSAGES
} from "../../contexts/CartContext/constants";
import Typography from "../Typography";
import PromoMessages from "../Promos/PromoMessages";
import { Colors } from "@serenaandlily/constants/constants";
import AnalyticsData from "../AnalyticsData";
import { removeDuplicateWarnings } from "@utils/removeDuplicateWarnings";

const BagPanelTile = ({ item, isDOO }) => {
  const itemUrl = `/${item?.netsuiteId}.html`;
  const cart = useCart();
  const { isLoading } = useCartUpdate();
  const { messages } = useContent();
  const imageURLParams = item?.imageUrl?.includes("s7d4")
    ? ""
    : "?fmt=auto&w=126&h=160&img404=noimagesmall";
  const { designerPrice, priceWithoutTax, unitPrice, prices } =
    item?.displayPrice || {};
  const hasTradePrice = isDOO && designerPrice;

  const price = `${hasTradePrice ? unitPrice : priceWithoutTax} ${
    hasTradePrice ? RETAIL_PRICE_LABEL : ""
  }`;

  const warningsForProduct = useMemo(() => {
    return cart?.warnings?.filter(
      (warning) =>
        warning?.payload?.itemId === item?.id &&
        warning?.code !== CART_WARNINGS.CART_ITEM_REPRICED
    );
  }, [cart?.warnings, item?.productId, item?.id]);

  const formattedPrices = {
    formattedListPrice:
      prices?.find((price) => price.type === "list")?.formatted || null,
    formattedSalePrice:
      prices?.find((price) => price.type === "sale")?.formatted || null
  };

  const { requiresDepositSettings } = useDiscountContext();

  const showSaleTotalPrice =
    item?.quantity > 1 && formattedPrices.formattedSalePrice;
  const totalSalePrice = showSaleTotalPrice
    ? currency?.(formattedPrices?.formattedSalePrice)
        ?.multiply(Number(item?.quantity))
        ?.format()
    : null;
  const totalOrignalPrice = showSaleTotalPrice
    ? currency?.(formattedPrices?.formattedListPrice)
        ?.multiply(Number(item?.quantity))
        ?.format()
    : null;
  const isPluralBuy = item?.quantity > 1;

  const setClickData = (clickData) => {
    localStorage.setItem(
      `pdpClickData${clickData?.currentProduct?.netsuiteId}`,
      JSON.stringify({
        ...clickData
      })
    );
  };

  const clickData = {
    list: "",
    productSpecification: {},
    productTileData: {},
    currentProduct: {
      name: item?.name,
      netsuiteId: item?.netsuiteId,
      displayPrice: {
        formattedListPrice: item?.displayPrice?.totalPrice
      }
    }
  };

  const productPriceTheme = {
    listedPrice: {
      themeName: "textSmall",
      marginBottom: 5
    },
    salesPrice: {
      themeName: "textSmall"
    },
    tradePrice: {
      themeName: "textSmall",
      fontColor: "#527296",
      fontWeight: "600"
    },
    inventoryErrorMessage: {
      typography: "textSmall",
      fontColor: "Crimson",
      fontSize: "11px",
      fontSizeTablet: "11px",
      fontSizeDesktop: "11px"
    }
  };

  return (
    <BagPanelTileWrapper href={itemUrl} onClick={() => setClickData(clickData)}>
      <AnalyticsData
        id={`a-cart-product-${item.netsuiteId}`}
        data={{
          ...item,
          category: item.subDepartment || "",
          variant: item.netsuiteId,
          id: item.masterId,
          list: ""
        }}
      />
      <BagPanelImageContainer>
        <SnlImage
          src={`${item?.imageUrl}${imageURLParams}`}
          customHeight={100}
          customWidth={100}
        />
      </BagPanelImageContainer>
      <BagPanelInfo>
        <ProductName>
          <Text
            copy={item?.name}
            themeName="textSmall"
            fontFamily="BeatriceRegular"
            fontSizeDesktop={16}
            fontSizeTablet={14}
            fontSize={14}
          />
        </ProductName>
        {item?.variations &&
          item?.variations?.map((variation, idx) => {
            return (
              <VariationAttribute
                key={variation?.name + variation?.value + idx}
              >
                <VariationLabel>
                  <Text
                    copy={`${variation?.name}:`}
                    themeName="textSmall"
                    fontColor="#667487"
                  />
                </VariationLabel>
                &nbsp;
                <VariationValue>
                  <Text
                    copy={variation?.value}
                    themeName="textSmall"
                    fontColor="#667487"
                  />
                </VariationValue>
              </VariationAttribute>
            );
          })}
        {item?.customUpholstery && (
          <React.Fragment>
            {item?.customUpholstery?.topLineVariation && (
              <VariationAttribute>
                <VariationLabel>
                  <Text
                    copy={`Size:`}
                    themeName="textSmall"
                    fontColor="#667487"
                  />
                </VariationLabel>
                &nbsp;
                <VariationValue>
                  <Text
                    copy={item?.customUpholstery?.topLineVariation}
                    themeName="textSmall"
                    fontColor="#667487"
                  />
                </VariationValue>
              </VariationAttribute>
            )}
            <VariationAttribute>
              <VariationLabel>
                <Text
                  copy={`Fabric:`}
                  themeName="textSmall"
                  fontColor="#667487"
                />
              </VariationLabel>
              &nbsp;
              <VariationValue>
                <Text
                  copy={item?.customUpholstery?.fabricName}
                  themeName="textSmall"
                  fontColor="#667487"
                />
              </VariationValue>
            </VariationAttribute>
            {item?.customUpholstery?.otherOptions.map((option, idx) => {
              return (
                <VariationAttribute key={option?.name + option?.value + idx}>
                  <VariationLabel>
                    <Text
                      copy={`${option?.name}:`}
                      themeName="textSmall"
                      fontColor="#667487"
                    />
                  </VariationLabel>
                  &nbsp;
                  <VariationValue>
                    <Text
                      copy={option.value}
                      themeName="textSmall"
                      fontColor="#667487"
                    />
                  </VariationValue>
                </VariationAttribute>
              );
            })}
          </React.Fragment>
        )}
        <VariationAttribute>
          <VariationLabel>
            <Text
              copy={`Quantity:`}
              themeName="textSmall"
              fontColor="#667487"
            />
          </VariationLabel>
          &nbsp;
          <VariationValue>
            <Text
              copy={item?.quantity}
              themeName="textSmall"
              fontColor="#667487"
            />
          </VariationValue>
        </VariationAttribute>
        {item?.requiresDeposit && (
          <DiscountMessageWrapper>
            <Headline {...requiresDepositSettings} />
          </DiscountMessageWrapper>
        )}
        <PriceContainer removeMargin={!isLoading && !totalOrignalPrice}>
          {showSaleTotalPrice ? (
            <ProductPrice
              formattedListPrice={totalOrignalPrice}
              formattedSalePrice={totalSalePrice}
              formattedDsoDesigner={designerPrice}
              theme={productPriceTheme}
              isLoading={isLoading}
            />
          ) : (
            <ProductPrice
              formattedListPrice={
                isPluralBuy ? price : formattedPrices?.formattedListPrice
              }
              formattedSalePrice={formattedPrices?.formattedSalePrice}
              formattedDsoDesigner={designerPrice}
              theme={productPriceTheme}
              isLoading={isLoading}
            />
          )}
        </PriceContainer>
        {!!item?.appliedPromotions && (
          <BagPromoMessages>
            {item.appliedPromotions.map((appliedPromotion, id) => {
              return (
                <PromoMessages
                  key={id}
                  displayBlock={true}
                  message={appliedPromotion.description}
                  fontColor={Colors["Tooltip Link"]}
                  {...appliedPromotion}
                />
              );
            })}
          </BagPromoMessages>
        )}
        {warningsForProduct?.length > 0 &&
          !item?.unavailable &&
          removeDuplicateWarnings?.(warningsForProduct)?.map?.(
            (warning, idx) => {
              return (
                <InventoryErrorMessage
                  key={`warning-${warning?.code}-${warning?.payload?.id}-bag-panel-tile-${idx}`}
                >
                  <Typography theme={productPriceTheme?.inventoryErrorMessage}>
                    {CART_WARNINGS[warning?.code] &&
                      CART_WARNINGS_MESSAGES[warning?.code]({
                        from: warning?.payload?.fromFormatted,
                        to: warning?.payload?.toFormatted,
                        total: warning?.payload?.total,
                        messages
                      })}
                  </Typography>
                </InventoryErrorMessage>
              );
            }
          )}
        {item?.unavailable && (
          <InventoryErrorMessage>
            <Typography theme={productPriceTheme?.inventoryErrorMessage}>
              {CART_WARNINGS_MESSAGES.UNAVAILABLE()}
            </Typography>
          </InventoryErrorMessage>
        )}
      </BagPanelInfo>
    </BagPanelTileWrapper>
  );
};

export default BagPanelTile;

BagPanelTile.propTypes = {
  item: PropTypes.object,
  isDOO: PropTypes.bool
};
