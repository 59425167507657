import UAParser from "ua-parser-js";

export default function parseUserAgent(userAgent) {
  const parser = new UAParser(userAgent);
  const result = parser.getResult();

  const deviceDetails = {
    brand: result.device.vendor,
    model: result.device.model,
    type: result.device.type || "desktop",
    osVersion: result.os.version
  };

  return deviceDetails;
}
