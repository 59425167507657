import React from "react";
import PropTypes from "prop-types";
import GenericCarousel from "../GenericCarousel";
import { CarouselContainer } from "./CarouselStyled";
import { useBreakpoint } from "../Breakpoints";
import { getDesktopMarginValue } from "../../utils/getMarginValue";
import { getMobileCarouselMargins } from "./utils/getMobileCarouselMargins";
import getContentFromSchema from "../../utils/getContentFromSchema/getContentFromSchema";

//eslint-disable-next-line complexity
const Carousel = ({
  size,
  sizeMobile,
  displayArrows,
  displayVerticalCopy,
  verticalCopy,
  autoplay,
  slides,
  carouselHeight,
  progressBar
}) => {
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints["DesktopMobile"];
  const visibleSlides = 3;
  const slideMargin = 20;
  const totalMargin = slideMargin * (visibleSlides - 1);
  const arrowOffset = -40;
  const textDistance = 35;
  const verticalText = displayVerticalCopy ? verticalCopy : null;
  const rightOffset = verticalText ? arrowOffset - textDistance : arrowOffset;

  for (const slide of slides) {
    const { column } = slide || {};
    const slideContent = column ? slide?.content : slide;
    if (getContentFromSchema(slideContent?._meta?.schema) === "CarouselImage") {
      slide.layout = "intrinsic";
    }
  }

  const loopLowerLimit = 2;
  const loopUpperLimit = 5;
  if (slides?.length > loopLowerLimit && slides?.length < loopUpperLimit) {
    slides.push(...slides);
  }

  const desktopCarouselConfigs = {
    arrows: true,
    hideLeftArrow: !displayArrows,
    autoplay: autoplay,
    loop: true,
    align: "start",
    height: carouselHeight
  };

  const mobileCarouselConfigs = {
    arrows: false,
    autoplay: autoplay,
    loop: true,
    align: "start",
    height: carouselHeight,
    progressBar: progressBar
  };

  const carouselConfigs = isMobile
    ? mobileCarouselConfigs
    : desktopCarouselConfigs;

  const marginDesktop = getDesktopMarginValue(size || "Full Width");
  const marginMobileStyles = getMobileCarouselMargins(sizeMobile);

  return (
    <CarouselContainer
      visibleSlides={visibleSlides}
      slideMargin={slideMargin}
      totalMargin={totalMargin}
      verticalText={verticalText}
      leftOffset={arrowOffset}
      rightOffset={rightOffset}
      textDistance={textDistance}
      marginDesktop={marginDesktop}
      marginMobileStyles={marginMobileStyles}
    >
      <GenericCarousel
        carouselContent={slides}
        carouselConfigs={carouselConfigs}
      />
    </CarouselContainer>
  );
};

export default Carousel;

Carousel.propTypes = {
  size: PropTypes.string,
  sizeMobile: PropTypes.string,
  displayArrows: PropTypes.bool,
  displayVerticalCopy: PropTypes.bool,
  verticalCopy: PropTypes.string,
  autoplay: PropTypes.bool,
  slides: PropTypes.array,
  carouselHeight: PropTypes.number,
  progressBar: PropTypes.object
};
